export type Maybe<T> = T | null
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  LocalDate: any
  Long: any
  Instant: any
  LocalTime: any
  LocalDateTime: any
}

export type Absenteismo = {
  __typename?: 'Absenteismo'
  id: Scalars['ID']
  municipio?: Maybe<Scalars['String']>
  unidadeSaude?: Maybe<Scalars['String']>
  equipe?: Maybe<Scalars['String']>
  nomeProfissional?: Maybe<Scalars['String']>
  nomeSituacaoAgendamento: Scalars['String']
  categoriaProfissional?: Maybe<Scalars['String']>
  turno: Scalars['String']
  intervaloAgendamento: Scalars['String']
  faixaEtaria: Scalars['String']
  cbo?: Maybe<Scalars['String']>
  producoes?: Maybe<Scalars['String']>
}

export type Acesso = {
  id: Scalars['ID']
  perfis?: Maybe<Array<Maybe<Perfil>>>
  tipo: TipoAcesso
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  municipio?: Maybe<Municipio>
  actions: BaseAcessoActions
}

export type AcessoActions = BaseAcessoActions & {
  __typename?: 'AcessoActions'
  permissaoEdicao: Action
  permissaoExclusao: Action
  permissaoAtivacaoInativacao: Action
}

export enum AcessoCbo {
  AUXILIAR_OU_TECNICO_ENFERMAGEM = 'AUXILIAR_OU_TECNICO_ENFERMAGEM',
  CIRURGIAO_DENTISTA = 'CIRURGIAO_DENTISTA',
  ENFERMEIRO = 'ENFERMEIRO',
  MEDICO = 'MEDICO',
  OUTRO_PROF_NIVEL_SUPERIOR = 'OUTRO_PROF_NIVEL_SUPERIOR',
  TSB = 'TSB',
  PSICOLOGO = 'PSICOLOGO',
  FARMACEUTICO = 'FARMACEUTICO',
  NIVEL_MEDIO = 'NIVEL_MEDIO',
  NUTRICIONISTA = 'NUTRICIONISTA',
  ACS = 'ACS',
  GERENTE_SERVICOS = 'GERENTE_SERVICOS',
}

export type AcessoConecteSusInput = {
  cnsCidadao: Scalars['String']
  cnsProfissional: Scalars['String']
  cnes: Scalars['String']
  municipio: Scalars['Long']
}

export type AcessosConnectionInput = {
  mostrarInativos?: Maybe<Scalars['Boolean']>
}

export type AcompanhamentoCidadaosVinculados = {
  __typename?: 'AcompanhamentoCidadaosVinculados'
  id: Scalars['ID']
  nomeCidadao: Scalars['String']
  dataNascimentoCidadao?: Maybe<Scalars['LocalDate']>
  sexoIdentidadeGeneroCidadao: Scalars['String']
  idade: Scalars['String']
  cpfCidadao?: Maybe<Scalars['String']>
  cnsCidadao?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  telefoneResidencial?: Maybe<Scalars['String']>
  dataUltimaAtualizacaoCidadao?: Maybe<Scalars['LocalDate']>
  endereco: Scalars['String']
  equipe: Scalars['String']
  microarea: Scalars['String']
  origem: Scalars['String']
}

export type AcompanhamentoCidadaosVinculadosFilterInput = {
  equipeResponsavelId?: Maybe<Scalars['ID']>
  microareas?: Maybe<Array<Scalars['String']>>
  pageParams?: Maybe<PageParams>
}

export type AcompanhamentoCidadaosVinculadosPayload = {
  __typename?: 'AcompanhamentoCidadaosVinculadosPayload'
  content: Array<AcompanhamentoCidadaosVinculados>
  pageInfo: PageInfo
}

export type AcompanhamentoCondicaoSaudeDto = {
  __typename?: 'AcompanhamentoCondicaoSaudeDto'
  idCidadao: Scalars['ID']
  nomeCidadao?: Maybe<Scalars['String']>
  nomeSocialCidadao?: Maybe<Scalars['String']>
  cnsCidadao?: Maybe<Scalars['String']>
  cpfCidadao?: Maybe<Scalars['String']>
  dataNascimentoCidadao?: Maybe<Scalars['LocalDate']>
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  dataUltimoAtendimento?: Maybe<Scalars['LocalDate']>
  endereco?: Maybe<Scalars['String']>
}

export type AcompanhamentoCondicaoSaudeQueryInput = {
  pageParams?: Maybe<PageParams>
  ciaps?: Maybe<Array<Maybe<Scalars['String']>>>
  cid10?: Maybe<Array<Maybe<Scalars['String']>>>
  grupoCondicao?: Maybe<Array<Maybe<Scalars['ID']>>>
  somenteProblemasCondicoesAtivas?: Maybe<Scalars['Boolean']>
  unidadeResponsavelId?: Maybe<Scalars['ID']>
  equipeResponsavelId?: Maybe<Scalars['ID']>
  sexo?: Maybe<Array<Maybe<SexoEnum>>>
  identidadeGenero?: Maybe<Array<Maybe<IdentidadeGeneroEnum>>>
  faixaEtaria?: Maybe<FaixaEtariaInput>
  periodoUltimoAtendimento?: Maybe<Period>
  microareas?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type AcompanhamentoCondicaoSaudeQueryPayload = {
  __typename?: 'AcompanhamentoCondicaoSaudeQueryPayload'
  content: Array<Maybe<AcompanhamentoCondicaoSaudeDto>>
  pageInfo: PageInfo
}

export type AcompanhamentoIdosoQueryInput = {
  prontuarioId: Scalars['ID']
  cidadaoId: Scalars['ID']
}

export type Action = {
  __typename?: 'Action'
  enabled: Scalars['Boolean']
  hint?: Maybe<Scalars['String']>
}

export type AdministradorGeral = Acesso & {
  __typename?: 'AdministradorGeral'
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  municipio?: Maybe<Municipio>
  ativo: Scalars['Boolean']
  actions: AcessoActions
}

export type AdministradorMunicipal = Acesso & {
  __typename?: 'AdministradorMunicipal'
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  municipio: Municipio
  dataAdicao?: Maybe<Scalars['Instant']>
  dataInativacao?: Maybe<Scalars['Instant']>
  profissional: Profissional
  habilitado: Scalars['Boolean']
  moduloInicial?: Maybe<Scalars['String']>
  ativo: Scalars['Boolean']
  autorizado: Scalars['Boolean']
  actions: AcessoActions
  chaveMunicipio: Scalars['String']
}

export type Agendado = {
  __typename?: 'Agendado'
  id: Scalars['ID']
  origem?: Maybe<OrigemAgendamentoEnum>
  horarioInicial?: Maybe<Scalars['Instant']>
  horarioFinal?: Maybe<Scalars['Instant']>
  cidadao?: Maybe<CidadaoBasico>
  prontuarioId?: Maybe<Scalars['ID']>
  lotacaoAgendada: Lotacao
  responsavelCriador?: Maybe<LotacaoOuEstagio>
  isForaUbs: Scalars['Boolean']
  localAtendimento?: Maybe<LocalAtendimento>
  situacao: SituacaoAgendadoEnum
  situacaoSincronizacao: SituacaoSincronizacaoAgendaOnlineEnum
  tipoAgendamento: TipoAgendamentoEnum
  observacao?: Maybe<Scalars['String']>
  motivoReserva?: Maybe<MotivoAgendamentoReservaEnum>
  outroMotivoReserva?: Maybe<Scalars['String']>
  dataCriacao?: Maybe<Scalars['Instant']>
  isAtencaoDomiciliar: Scalars['Boolean']
  atendimento?: Maybe<Atendimento>
  justificativaCancelamento?: Maybe<JustificativaCancelamentoAgendado>
  lotacaoParticipante?: Maybe<Lotacao>
  videochamada?: Maybe<Videochamada>
  agendamentoPrincipal?: Maybe<Agendado>
}

export type AgendadoQueryPayload = {
  __typename?: 'AgendadoQueryPayload'
  content: Array<Agendado>
  pageInfo: PageInfo
}

export type AgendadosQueryInput = {
  prontuarioId?: Maybe<Scalars['ID']>
  cidadaoId?: Maybe<Scalars['ID']>
  unidadeSaudeId?: Maybe<Scalars['ID']>
  lotacaoId?: Maybe<Scalars['ID']>
  dataAgendadoInicio?: Maybe<Scalars['LocalDate']>
  dataAgendadoFim?: Maybe<Scalars['LocalDate']>
  situacao?: Maybe<Array<SituacaoAgendadoEnum>>
  isForaDaUbs?: Maybe<Scalars['Boolean']>
  isOrigemAtencaoDomiciliar?: Maybe<Scalars['Boolean']>
  nomeProfissional?: Maybe<Scalars['String']>
  profissionalId?: Maybe<Scalars['ID']>
  pageParams?: Maybe<PageParams>
  maisNovosPrimeiro?: Maybe<Scalars['Boolean']>
}

export type AgendamentoConflitanteInput = {
  cidadaoId: Scalars['ID']
  agendamentos: Array<LotacaoAgendamentoConflitanteInput>
}

export type AgendamentoConsultaAtendimentoInput = {
  lotacao: Scalars['ID']
  horario: Scalars['Instant']
  isForaUbs: Scalars['Boolean']
  localAtendimento?: Maybe<Scalars['ID']>
  observacoes?: Maybe<Scalars['String']>
}

export type AgendamentoConsultaCompartilhadaInput = {
  lotacaoId: Scalars['ID']
  horarioInicial: Scalars['Instant']
  horarioFinal: Scalars['Instant']
  lotacaoParticipanteId: Scalars['ID']
  observacoes?: Maybe<Scalars['String']>
  cidadao?: Maybe<Scalars['ID']>
  videochamadaUuid?: Maybe<Scalars['String']>
}

export type AgendamentoConsultaInput = {
  lotacao: Scalars['ID']
  cidadao: Scalars['ID']
  horario: Scalars['Instant']
  isForaUbs: Scalars['Boolean']
  localAtendimento?: Maybe<Scalars['ID']>
  observacoes?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
}

export type AgendamentoConsultasAtendimentoInput = {
  avulsa?: Maybe<AgendamentoConsultaAtendimentoInput>
  planejadas?: Maybe<AgendamentoConsultasPlanejadasAtendimentoInput>
}

export type AgendamentoConsultasPlanejadasAtendimentoInput = {
  preNatal?: Maybe<AgendamentoConsultaAtendimentoInput>
  odontologica?: Maybe<AgendamentoConsultaAtendimentoInput>
}

export type AgendamentoCuidadoCompartilhadoInput = {
  horario: Scalars['Instant']
  observacoes?: Maybe<Scalars['String']>
}

export type AgendamentoMesmoProfissionalDataFutura = {
  __typename?: 'AgendamentoMesmoProfissionalDataFutura'
  dataFutura: Scalars['Instant']
  profissionalId: Scalars['ID']
}

export type AgendamentoReservaInput = {
  lotacaoAgendada: Scalars['ID']
  horarioInicial: Scalars['Instant']
  horarioFinal: Scalars['Instant']
  motivoReserva: MotivoAgendamentoReservaEnum
  especificacaoMotivoReserva?: Maybe<Scalars['String']>
  observacoes?: Maybe<Scalars['String']>
}

export type AgendaOnline = {
  __typename?: 'AgendaOnline'
  ativa: Scalars['Boolean']
  detalhesAtivacao?: Maybe<DetalhesAtivacao>
  testeConexao?: Maybe<TesteConexao>
}

export enum AgeRangeType {
  ANOS = 'ANOS',
  MESES = 'MESES',
  SEMANAS = 'SEMANAS',
  DIAS = 'DIAS',
}

export enum AggregatorRelatorioGerencialEnum {
  PERCENTUAL = 'PERCENTUAL',
  CONTAGEM = 'CONTAGEM',
  MEDIA = 'MEDIA',
  MAXIMO = 'MAXIMO',
  MINIMO = 'MINIMO',
}

export type Aldeia = {
  __typename?: 'Aldeia'
  id: Scalars['ID']
  nome: Scalars['String']
  poloBase: PoloBase
  municipio: Array<Municipio>
}

export type AldeiaInput = {
  query?: Maybe<Scalars['String']>
  dseiId?: Maybe<Scalars['ID']>
  poloBaseId?: Maybe<Scalars['ID']>
  pageParams?: Maybe<PageParams>
}

export type AldeiaQueryPayload = {
  __typename?: 'AldeiaQueryPayload'
  content: Array<Aldeia>
  pageInfo: PageInfo
}

export type AleitamentoMaterno = {
  __typename?: 'AleitamentoMaterno'
  id?: Maybe<Scalars['ID']>
  tipo?: Maybe<TipoAleitamentoMaternoEnum>
  dataInicioAtendimento?: Maybe<Scalars['Instant']>
}

export type Alergia = {
  __typename?: 'Alergia'
  id: Scalars['ID']
  tipoReacao?: Maybe<TipoReacaoAlergia>
  categoriaSubstanciaEspecificaLegado?: Maybe<CategoriaSubstanciaAlergiaLegado>
  substanciaEspecifica?: Maybe<SubstanciaEspecificaAlergia>
  substanciaEspecificaLegado?: Maybe<Scalars['String']>
  evolucoes: Array<EvolucaoAlergia>
  ultimaEvolucao: EvolucaoAlergia
  criticidade: CriticidadeAlergia
  grauCerteza: GrauCertezaAlergia
}

export type AlergiaQueryPayload = {
  __typename?: 'AlergiaQueryPayload'
  content: Array<Alergia>
  pageInfo: PageInfo
}

export type AlergiasQueryInput = {
  prontuarioId: Scalars['ID']
  grausCerteza?: Maybe<Array<GrauCertezaAlergia>>
  pageParams?: Maybe<PageParams>
}

export type AlertaSdc = {
  __typename?: 'AlertaSdc'
  id: Scalars['ID']
  titulo: Scalars['String']
  subtitulo?: Maybe<Scalars['String']>
  descricao?: Maybe<Scalars['String']>
}

export type AlertasSdcInput = {
  cids10ProblemasCondicoes?: Maybe<Array<Scalars['String']>>
  ciaps2ProblemasCondicoes?: Maybe<Array<Scalars['String']>>
  prontuarioId: Scalars['ID']
  atendProfData?: Maybe<Scalars['LocalDate']>
}

export type AlteracaoFenotipica = AvaliacaoDesenvolvimento & {
  __typename?: 'AlteracaoFenotipica'
  id: Scalars['ID']
  descricao: Scalars['String']
  status: StatusAvaliadoEnum
  dataAvaliacao?: Maybe<Scalars['LocalDate']>
}

export type AlterarCadsusInput = {
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type AlterarConfiguracaoPrescricaoDigitalInput = {
  habilitado: Scalars['Boolean']
  usuario: Scalars['String']
  senha: Scalars['String']
}

export type AlterarConfiguracaoVideochamadasInput = {
  habilitado: Scalars['Boolean']
}

export type AlterarHorusInput = {
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type AlterarLotacaoInput = {
  id?: Maybe<Scalars['ID']>
  ativo: Scalars['Boolean']
  perfis: Array<Maybe<Scalars['ID']>>
  atualizaPerfil?: Maybe<Scalars['Boolean']>
}

export type AlterarSenhaAtualInput = {
  senhaAtual?: Maybe<Scalars['String']>
  novaSenha: Scalars['String']
}

export enum AnimaisDomicilioEnum {
  CACHORRO = 'CACHORRO',
  GALINHA = 'GALINHA',
  GATO = 'GATO',
  MACACO = 'MACACO',
  PASSARO = 'PASSARO',
  PORCO = 'PORCO',
  REPTEIS = 'REPTEIS',
  OUTRO = 'OUTRO',
}

export type AnimaisImovel = {
  __typename?: 'AnimaisImovel'
  id?: Maybe<Scalars['Long']>
  possuiAnimais?: Maybe<Scalars['Boolean']>
  possuiGato?: Maybe<Scalars['Boolean']>
  possuiCachorro?: Maybe<Scalars['Boolean']>
  possuiPassaro?: Maybe<Scalars['Boolean']>
  possuiMacaco?: Maybe<Scalars['Boolean']>
  possuiGalinha?: Maybe<Scalars['Boolean']>
  possuiPorco?: Maybe<Scalars['Boolean']>
  possuiRepteis?: Maybe<Scalars['Boolean']>
  possuiOutros?: Maybe<Scalars['Boolean']>
  quantidadeAnimais?: Maybe<Scalars['Int']>
}

export type AnimaisImovelInput = {
  possuiAnimais: Scalars['Boolean']
  possuiGato?: Maybe<Scalars['Boolean']>
  possuiCachorro?: Maybe<Scalars['Boolean']>
  possuiPassaro?: Maybe<Scalars['Boolean']>
  possuiMacaco?: Maybe<Scalars['Boolean']>
  possuiGalinha?: Maybe<Scalars['Boolean']>
  possuiPorco?: Maybe<Scalars['Boolean']>
  possuiRepteis?: Maybe<Scalars['Boolean']>
  possuiOutros?: Maybe<Scalars['Boolean']>
  quantidadeAnimais?: Maybe<Scalars['Int']>
}

export type Antecedente = {
  __typename?: 'Antecedente'
  id: Scalars['ID']
  observacoes?: Maybe<Scalars['String']>
  dataUltimaAtualizacaoPessoal?: Maybe<Scalars['Instant']>
  gestacoesPrevias?: Maybe<Scalars['String']>
  qtPartos?: Maybe<Scalars['String']>
  qtAbortos?: Maybe<Scalars['String']>
  qtCesareas?: Maybe<Scalars['String']>
  partosVaginais?: Maybe<Scalars['String']>
  partosDomiciliares?: Maybe<Scalars['String']>
  nascidosVivos?: Maybe<Scalars['String']>
  natimortos?: Maybe<Scalars['String']>
  obitoAntesPrimeiraSemana?: Maybe<Scalars['String']>
  obitoAposPrimeiraSemana?: Maybe<Scalars['String']>
  filhosVivos?: Maybe<Scalars['String']>
  recemNascidosAcima?: Maybe<Scalars['String']>
  recemNascidosAbaixo?: Maybe<Scalars['String']>
  dataUltimoParto?: Maybe<Scalars['Instant']>
  partoMenosDeUmAno?: Maybe<Scalars['Boolean']>
  dataUltimaAtualizacaoGestacional?: Maybe<Scalars['Instant']>
  peso?: Maybe<Scalars['String']>
  altura?: Maybe<Scalars['String']>
  perimetroCefalico?: Maybe<Scalars['String']>
  apgarUm?: Maybe<Scalars['String']>
  apgarCinco?: Maybe<Scalars['String']>
  apgarDez?: Maybe<Scalars['String']>
  tipoGravidez?: Maybe<TipoGravidezEnum>
  tipoParto?: Maybe<TipoPartoEnum>
  idadeGestacionalSemanas?: Maybe<Scalars['String']>
  idadeGestacionalDias?: Maybe<Scalars['String']>
  dataUltimaAtualizacaoPuericultura?: Maybe<Scalars['Instant']>
  antecedentesFamiliaresCiap?: Maybe<Array<Maybe<AntecedentesFamiliaresCiap>>>
  cirurgiasInternacoes?: Maybe<Array<Maybe<AntecedenteCirurgiasInternacoes>>>
}

export type AntecedenteCirurgiaInternacaoInput = {
  idCirurgiaInternacao?: Maybe<Scalars['ID']>
  descricao: Scalars['String']
  dataRealizada?: Maybe<Scalars['LocalDate']>
  observacao?: Maybe<Scalars['String']>
}

export type AntecedenteCirurgiasInternacoes = {
  __typename?: 'AntecedenteCirurgiasInternacoes'
  id: Scalars['ID']
  descricao: Scalars['String']
  observacoes?: Maybe<Scalars['String']>
  dataCirurgiaInternacao?: Maybe<Scalars['LocalDate']>
}

export type AntecedenteFamiliarCiapInput = {
  ciapId: Scalars['ID']
  observacao?: Maybe<Scalars['String']>
}

export type AntecedenteInformacoesObstetricasDto = {
  __typename?: 'AntecedenteInformacoesObstetricasDto'
  gestaPrevias?: Maybe<Scalars['String']>
  abortos?: Maybe<Scalars['String']>
  partoMenosDeUmAno?: Maybe<Scalars['Boolean']>
  partos?: Maybe<Scalars['String']>
  partosVaginais?: Maybe<Scalars['String']>
  partosCesareas?: Maybe<Scalars['String']>
  partosDomiciliares?: Maybe<Scalars['String']>
  nascidosVivos?: Maybe<Scalars['String']>
  natimortos?: Maybe<Scalars['String']>
  recemNascidoAbaixo?: Maybe<Scalars['String']>
  recemNascidoAcima?: Maybe<Scalars['String']>
  filhosVivos?: Maybe<Scalars['String']>
  obitoAntesPrimeiraSemana?: Maybe<Scalars['String']>
  obitoAposPrimeiraSemana?: Maybe<Scalars['String']>
}

export type AntecedenteItemDto = {
  __typename?: 'AntecedenteItemDto'
  id: Scalars['ID']
  observacoes?: Maybe<Scalars['String']>
  puericultura?: Maybe<AntecedentePuericulturaDto>
  informacoesObstetricas?: Maybe<AntecedenteInformacoesObstetricasDto>
}

export type AntecedentePuericulturaDto = {
  __typename?: 'AntecedentePuericulturaDto'
  tipoGravidez?: Maybe<Scalars['String']>
  tipoParto?: Maybe<Scalars['String']>
  idadeGestacionalDias?: Maybe<Scalars['String']>
  idadeGestacionalSemanas?: Maybe<Scalars['String']>
  apgarUm?: Maybe<Scalars['String']>
  apgarCinco?: Maybe<Scalars['String']>
  apgarDez?: Maybe<Scalars['String']>
  peso?: Maybe<Scalars['String']>
  comprimento?: Maybe<Scalars['String']>
  perimetroCefalico?: Maybe<Scalars['String']>
}

export type AntecedentesFamiliaresCiap = {
  __typename?: 'AntecedentesFamiliaresCiap'
  idCiap: Scalars['ID']
  codigoCiap: Scalars['String']
  descricao: Scalars['String']
  observacao?: Maybe<Scalars['String']>
}

export type AntecedentesInput = {
  pessoal?: Maybe<PessoalAntecedentesInput>
  familiar?: Maybe<FamiliarAntecedentesInput>
}

export type AplicacaoMedicamento = {
  __typename?: 'AplicacaoMedicamento'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type AplicacaoMedicamentoPayload = {
  __typename?: 'AplicacaoMedicamentoPayload'
  content: Array<Maybe<AplicacaoMedicamento>>
  pageInfo: PageInfo
}

export type AplicacaoMedicamentoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export enum ArquivoFormatoEnum {
  PDF = 'PDF',
  CSV = 'CSV',
  ZIP = 'ZIP',
}

export enum ArquivoIdentificadorEnum {
  RELATORIO_ACOMPANHAMENTO_CIDADAOS_VINCULADOS = 'RELATORIO_ACOMPANHAMENTO_CIDADAOS_VINCULADOS',
  RELATORIO_ACOMPANHAMENTO_CONDICAO_SAUDE = 'RELATORIO_ACOMPANHAMENTO_CONDICAO_SAUDE',
  RELATORIO_BUSCA_ATIVA_VACINACAO = 'RELATORIO_BUSCA_ATIVA_VACINACAO',
  RELATORIO_CADASTROS_DUPLICADOS = 'RELATORIO_CADASTROS_DUPLICADOS',
  RELATORIO_IMPORTACAO_CIDADAOS = 'RELATORIO_IMPORTACAO_CIDADAOS',
}

export type ArquivoQueryInput = {
  arquivoIdentificadorEnum: ArquivoIdentificadorEnum
  apenasUltimosSeteDias?: Maybe<Scalars['Boolean']>
}

export enum ArquivoStatusEnum {
  EM_PROCESSAMENTO = 'EM_PROCESSAMENTO',
  PRONTO = 'PRONTO',
  ERRO = 'ERRO',
}

export type AssinaturaEletronica = {
  __typename?: 'AssinaturaEletronica'
  id: Scalars['ID']
  dtAssinatura: Scalars['Instant']
  status?: Maybe<StatusAssinatura>
}

export enum AsyncProcessingNameEnum {
  RELATORIO_GERENCIAL_ATENDIMENTO = 'RELATORIO_GERENCIAL_ATENDIMENTO',
  RELATORIO_GERENCIAL_VACINACAO = 'RELATORIO_GERENCIAL_VACINACAO',
}

export type AsyncProcessingStatus = {
  __typename?: 'AsyncProcessingStatus'
  isStarted: Scalars['Boolean']
  isFinished: Scalars['Boolean']
  errorMessage?: Maybe<Scalars['String']>
}

export type AtencaoDomiciliarCidadao = {
  __typename?: 'AtencaoDomiciliarCidadao'
  id: Scalars['ID']
  cidadao?: Maybe<Cidadao>
  cidadaosAdEmPosObito?: Maybe<Array<Maybe<AtencaoDomiciliarCidadao>>>
  equipe?: Maybe<Equipe>
  dataAdmissao?: Maybe<Scalars['LocalDate']>
  modalidade?: Maybe<AtencaoDomiciliarModalidade>
  procedencia?: Maybe<AtencaoDomiciliarOrigem>
  procedenciaEspecificacao?: Maybe<Scalars['String']>
  cid10Principal?: Maybe<Cid10>
  cid10Secundario1?: Maybe<Cid10>
  cid10Secundario2?: Maybe<Cid10>
  cidadaoAdEmObito?: Maybe<AtencaoDomiciliarCidadao>
  condutaDesfecho?: Maybe<AtencaoDomiciliarCondutaDesfecho>
  dataSaida?: Maybe<Scalars['LocalDate']>
  dataObito?: Maybe<Scalars['LocalDate']>
  dataUltimaVisita?: Maybe<Scalars['LocalDate']>
  numeroDocumentoObito?: Maybe<Scalars['String']>
  dataAgendado?: Maybe<Scalars['Instant']>
  actions: AtencaoDomiciliarCidadaoActions
}

export type AtencaoDomiciliarCidadaoActions = {
  __typename?: 'AtencaoDomiciliarCidadaoActions'
  permissaoEditarAD: Action
  adEncerrado: Action
  permissaoExcluirAD: Action
}

export type AtencaoDomiciliarCidadaoInput = {
  id?: Maybe<Scalars['ID']>
  cidadaoId: Scalars['ID']
  dataAdmissao: Scalars['LocalDate']
  modalidade: AtencaoDomiciliarModalidade
  procedencia?: Maybe<AtencaoDomiciliarOrigem>
  procedenciaEspecificacao?: Maybe<Scalars['String']>
  cid10PrincipalId: Scalars['ID']
  cid10Secundario1Id?: Maybe<Scalars['ID']>
  cid10Secundario2Id?: Maybe<Scalars['ID']>
  condutaDesfecho?: Maybe<AtencaoDomiciliarCondutaDesfecho>
  dataSaida?: Maybe<Scalars['LocalDate']>
  dataObito?: Maybe<Scalars['LocalDate']>
  numeroDO?: Maybe<Scalars['String']>
  cidadaoAdEmObitoId?: Maybe<Scalars['ID']>
  nomeResponsavel?: Maybe<Scalars['String']>
  dataNascimentoResponsavel?: Maybe<Scalars['LocalDate']>
  cpfCnsResponsavel?: Maybe<Scalars['String']>
  naoPossuiCuidador?: Maybe<Scalars['Boolean']>
  nomeCuidador?: Maybe<Scalars['String']>
  dataNascimentoCuidador?: Maybe<Scalars['LocalDate']>
  cpfCnsCuidador?: Maybe<Scalars['String']>
  relacaoCidadaoCuidador?: Maybe<RelacaoCidadaoCuidador>
}

export enum AtencaoDomiciliarCondutaDesfecho {
  PERMANENCIA = 'PERMANENCIA',
  ALTA_ADMINISTRATIVA = 'ALTA_ADMINISTRATIVA',
  ALTA_CLINICA = 'ALTA_CLINICA',
  OBITO = 'OBITO',
  ATENCAO_BASICA_AD1 = 'ATENCAO_BASICA_AD1',
  SERVICO_URGENCIA_EMERGENCIA = 'SERVICO_URGENCIA_EMERGENCIA',
  SERVICO_INTERNACAO_HOSPITALAR = 'SERVICO_INTERNACAO_HOSPITALAR',
  SAIDA_POR_OBITO = 'SAIDA_POR_OBITO',
  ACOMPANHAMENTO_POS_OBITO = 'ACOMPANHAMENTO_POS_OBITO',
}

export enum AtencaoDomiciliarModalidade {
  MODALIDADE_AD_1 = 'MODALIDADE_AD_1',
  MODALIDADE_AD_2 = 'MODALIDADE_AD_2',
  MODALIDADE_AD_3 = 'MODALIDADE_AD_3',
  INELEGIVEL = 'INELEGIVEL',
  POS_OBITO = 'POS_OBITO',
}

export enum AtencaoDomiciliarOrigem {
  UBS = 'UBS',
  HOSPITAL = 'HOSPITAL',
  UNIDADE_PRONTO_ATENDIMENTO = 'UNIDADE_PRONTO_ATENDIMENTO',
  CACON_UNACON = 'CACON_UNACON',
  HOSPITAL_URGENCIA_EMERGENCIA = 'HOSPITAL_URGENCIA_EMERGENCIA',
  HOSPITAL_DEMAIS_SETORES = 'HOSPITAL_DEMAIS_SETORES',
  ATENCAO_BASICA = 'ATENCAO_BASICA',
  INTERNACAO_HOSPITALAR = 'INTERNACAO_HOSPITALAR',
  URGENCIA_EMERGENCIA = 'URGENCIA_EMERGENCIA',
  INICIATIVA_PACIENTE_TERCEIROS = 'INICIATIVA_PACIENTE_TERCEIROS',
  OUTROS = 'OUTROS',
}

export type AtencaoDomiciliarQuestao = {
  __typename?: 'AtencaoDomiciliarQuestao'
  id: Scalars['ID']
  descricao: Scalars['String']
}

export type AtencaoDomiciliarResposta = {
  __typename?: 'AtencaoDomiciliarResposta'
  id: Scalars['Int']
  questao?: Maybe<AtencaoDomiciliarQuestao>
  situacaoAtiva?: Maybe<Scalars['Boolean']>
}

export type Atendimento = {
  __typename?: 'Atendimento'
  id: Scalars['ID']
  prontuarioId: Scalars['ID']
  cidadao: CidadaoBasico
  iniciadoEm: Scalars['Instant']
  statusAtendimento: StatusAtendimento
  statusRevisaoAtendimento?: Maybe<StatusRevisaoAtendimento>
  tiposServico?: Maybe<Array<Maybe<TipoServico>>>
  classificacaoRisco?: Maybe<ClassificacaoRisco>
  isAgendado: Scalars['Boolean']
  agendado?: Maybe<Agendado>
  equipe?: Maybe<Equipe>
  responsavel?: Maybe<LotacaoOuEstagio>
  encaminhadoEm: Scalars['Instant']
  hasTagNaoFinalizado: Scalars['Boolean']
  atendimentoProfissional?: Maybe<AtendimentoProfissional>
  unidadeSaude: UnidadeSaude
  actions: AtendimentoActions
  ultimaAlteracaoStatusEm: Scalars['Instant']
  prontuario: Prontuario
  isRegistroTardio: Scalars['Boolean']
  dataCriacaoRegistro?: Maybe<Scalars['Instant']>
  possuiAtendimentosProfissionais: Scalars['Boolean']
  localAtendimento?: Maybe<LocalAtendimento>
  escutaInicialAtendProf?: Maybe<AtendimentoProfissional>
  justificativa?: Maybe<RegistroTardioJustificativaEnum>
  ultimoResponsavelObservacao?: Maybe<Lotacao>
}

export type AtendimentoActions = {
  __typename?: 'AtendimentoActions'
  escutaInicial?: Maybe<Action>
  atender?: Maybe<Action>
  revisarRetificar?: Maybe<Action>
  vacinacao?: Maybe<Action>
  informarAusencia?: Maybe<Action>
  cancelarAusencia?: Maybe<Action>
  visualizarProntuario?: Maybe<Action>
  visualizarEscutaInicial?: Maybe<Action>
  editar?: Maybe<Action>
  excluir?: Maybe<Action>
  atendimentosDia?: Maybe<Action>
  declaracaoComparecimento?: Maybe<Action>
  cancelarRegistro?: Maybe<Action>
  tornarResponsavelObservacao?: Maybe<Action>
}

export type AtendimentoEditInput = {
  atendimento: Scalars['ID']
  equipe?: Maybe<Scalars['ID']>
  responsavel?: Maybe<Scalars['ID']>
  tiposServico?: Maybe<Array<Scalars['ID']>>
}

export type AtendimentoIndividualAprovacaoInput = {
  atendimentoInput: AtendimentoIndividualInput
  rascunho: Scalars['String']
}

export type AtendimentoIndividualInput = {
  id: Scalars['ID']
  antecedentes?: Maybe<AntecedentesInput>
  subjetivo?: Maybe<SubjetivoInput>
  objetivo?: Maybe<ObjetivoInput>
  avaliacao?: Maybe<AvaliacaoInput>
  plano?: Maybe<PlanoInput>
  finalizacao?: Maybe<FinalizacaoAtendimentoInput>
  medicoesAnteriores?: Maybe<Array<Maybe<MedicoesInput>>>
  medicamentoInterromper?: Maybe<Array<Maybe<MedicamentoInterromperInput>>>
  lembretes?: Maybe<Array<Maybe<LembreteSaveInput>>>
  preNatal?: Maybe<PreNatalInput>
  imprimirAtendimento?: Maybe<Scalars['Boolean']>
  registrosVacinacao?: Maybe<Array<Maybe<RegistroVacinacaoInput>>>
  problemasECondicoes?: Maybe<Array<ProblemasECondicoesInput>>
}

export type AtendimentoInput = {
  agendado?: Maybe<Scalars['ID']>
  cidadao: Scalars['ID']
  equipe?: Maybe<Scalars['ID']>
  responsavel?: Maybe<Scalars['ID']>
  tiposServico?: Maybe<Array<Scalars['ID']>>
}

export type AtendimentoObservacao = {
  __typename?: 'AtendimentoObservacao'
  id: Scalars['ID']
  atendimentoProfissionalIniciador: AtendimentoProfissional
  atendimentoProfissionalFinalizador?: Maybe<AtendimentoProfissional>
  responsaveis: Array<AtendimentoObservacaoResponsavel>
  ultimoResponsavelObservacao: Lotacao
  isFinalizado: Scalars['Boolean']
}

export type AtendimentoObservacaoPlanoCuidado = {
  __typename?: 'AtendimentoObservacaoPlanoCuidado'
  id: Scalars['ID']
  atendimentoProfissionalObservacao: AtendimentoProfissionalObservacao
  descricao: Scalars['String']
  dataDescricao: Scalars['Instant']
}

export type AtendimentoObservacaoResponsavel = {
  __typename?: 'AtendimentoObservacaoResponsavel'
  id: Scalars['ID']
  atendimentoObservacao: AtendimentoObservacao
  responsavel: Lotacao
  atendeuComoResponsavel: Scalars['Boolean']
}

export type AtendimentoProcedimento = {
  __typename?: 'AtendimentoProcedimento'
  id: Scalars['ID']
  procedimento: Procedimento
  atendimentoProfissional: AtendimentoProfissional
  obrigatorio?: Maybe<Scalars['Boolean']>
  automatico?: Maybe<Scalars['Boolean']>
  cid10Principal?: Maybe<Cid10>
}

export type AtendimentoProfissional = {
  __typename?: 'AtendimentoProfissional'
  id: Scalars['ID']
  atendimento?: Maybe<Atendimento>
  iniciadoEm: Scalars['Instant']
  finalizadoEm?: Maybe<Scalars['Instant']>
  lotacao: Lotacao
  estagio?: Maybe<Estagio>
  tipo: TipoAtendimentoProfissional
  versao?: Maybe<Scalars['String']>
  versaoAnterior?: Maybe<Scalars['Boolean']>
  medicoes?: Maybe<Array<Maybe<Medicao>>>
  marcadorConsumoAlimentar?: Maybe<MarcadorConsumoAlimentar>
  receitaMedicamento?: Maybe<Array<Maybe<ReceitaMedicamento>>>
  classificacaoRisco?: Maybe<ClassificacaoRisco>
  evolucaoSubjetivo?: Maybe<EvolucaoSubjetivo>
  evolucaoObjetivo?: Maybe<EvolucaoObjetivo>
  evolucaoAvaliacao?: Maybe<EvolucaoAvaliacao>
  evolucaoPlano?: Maybe<EvolucaoPlano>
  quantidadeAtestados?: Maybe<Scalars['Int']>
  quantidadeOrientacoes?: Maybe<Scalars['Int']>
  examesResultados?: Maybe<Array<Maybe<ResultadoExame>>>
  atendimentoProfissionalPreNatal?: Maybe<AtendimentoProfissionalPreNatal>
  atendimentoProfissionalPuericultura?: Maybe<AtendimentoProfissionalPuericultura>
  atendimentoProfissionalOdonto?: Maybe<AtendimentoProfissionalOdonto>
  atendimentoProfissionalObservacao?: Maybe<AtendimentoProfissionalObservacao>
  atendimentoProfissionalAD?: Maybe<AtendimentoProfissionalAtencaoDomiciliar>
  cidadaoAD?: Maybe<AtencaoDomiciliarCidadao>
  alergiaEvolucao?: Maybe<Array<Maybe<EvolucaoAlergia>>>
  lotacaoAtendimentoCompartilhado?: Maybe<Lotacao>
  evolucaoProblema?: Maybe<Array<Maybe<EvolucaoProblema>>>
  encaminhamentosEspecializados?: Maybe<Array<Maybe<EncaminhamentoExternoEspecializado>>>
  encaminhamentos?: Maybe<Array<TipoEncaminhamentoExternoEnum>>
  orientacoes?: Maybe<Array<Maybe<Orientacao>>>
  requisicoesExames?: Maybe<Array<Maybe<SolicitacaoExame>>>
  procedimentosAtendimento?: Maybe<Array<Maybe<AtendimentoProcedimento>>>
  notificacoesCasoSuspeito?: Maybe<Array<Maybe<SinanNotificacaoEvolucao>>>
  racionalidadeSaude?: Maybe<RacionalidadeEmSaudeEnum>
  condutas?: Maybe<Array<Maybe<CondutaEnum>>>
  antecedentes?: Maybe<AntecedenteItemDto>
  stCancelado?: Maybe<Scalars['Boolean']>
  cirurgiasInternacoes?: Maybe<Array<Maybe<AntecedenteCirurgiasInternacoes>>>
  vacinacao?: Maybe<AtendimentoVacinacao>
  rascunho?: Maybe<Scalars['String']>
  tipoAtendimento?: Maybe<TipoAtendimentoEnum>
  atestados?: Maybe<Array<Atestado>>
  assinaturaEletronicaAtend?: Maybe<AssinaturaEletronica>
  isAtendRecente: Scalars['Boolean']
  codigoUnicoRegistro: Scalars['String']
  planosDeCuidado?: Maybe<Array<AtendimentoObservacaoPlanoCuidado>>
  cuidadosCompartilhados?: Maybe<Array<CuidadoCompartilhado>>
  guiasEncaminhamento?: Maybe<Array<GuiaEncaminhamento>>
  tipoParticipacaoCidadao?: Maybe<TipoParticipacaoAtendimentoEnum>
  tipoParticipacaoProfissionalConvidado?: Maybe<TipoParticipacaoAtendimentoEnum>
}

export type AtendimentoProfissionalAtencaoDomiciliar = {
  __typename?: 'AtendimentoProfissionalAtencaoDomiciliar'
  id: Scalars['ID']
  origem?: Maybe<AtencaoDomiciliarOrigem>
  modalidade: AtencaoDomiciliarModalidade
  cid10Principal?: Maybe<Cid10>
  cid10Secundario1?: Maybe<Cid10>
  cid10Secundario2?: Maybe<Cid10>
  conduta?: Maybe<AtencaoDomiciliarCondutaDesfecho>
  condicoesAvaliadasAD?: Maybe<Array<Maybe<AtencaoDomiciliarResposta>>>
  elegivel?: Maybe<Scalars['Boolean']>
  conclusao?: Maybe<Array<Maybe<Scalars['String']>>>
  condicoesAvaliadasElegibilidade?: Maybe<Array<Maybe<AtendimentoProfissionalAtencaoDomiciliarQuestao>>>
}

export type AtendimentoProfissionalAtencaoDomiciliarQuestao = {
  __typename?: 'AtendimentoProfissionalAtencaoDomiciliarQuestao'
  id: Scalars['ID']
  situacaoAtiva?: Maybe<Scalars['Boolean']>
  descricao?: Maybe<Scalars['String']>
}

export type AtendimentoProfissionalObservacao = {
  __typename?: 'AtendimentoProfissionalObservacao'
  id: Scalars['ID']
  atendimentoProfissional: AtendimentoProfissional
  atendimentoObservacao: AtendimentoObservacao
}

export type AtendimentoProfissionalOdonto = {
  __typename?: 'AtendimentoProfissionalOdonto'
  id: Scalars['ID']
  atendimentoProfissional: AtendimentoProfissional
  cdsTipoFornecimOdonto?: Maybe<Array<Maybe<FornecimentoOdontoEnum>>>
  tipoConsultaOdonto?: Maybe<TipoConsultaOdontoEnum>
  tipoConduta?: Maybe<Array<Maybe<TipoEncaminhamentoOdontoEnum>>>
  observacaoRPC?: Maybe<Scalars['String']>
  evolucoes?: Maybe<Array<Maybe<EvolucaoProcedimentoOdonto>>>
  tecidosMoles?: Maybe<Array<Maybe<TecidoMole>>>
  periogramaSimplificado?: Maybe<PeriogramaSimplificado>
  periogramaCompleto?: Maybe<Array<Maybe<PeriogramaCompleto>>>
  odontograma?: Maybe<Odontograma>
}

export type AtendimentoProfissionalPreNatal = {
  __typename?: 'AtendimentoProfissionalPreNatal'
  id: Scalars['ID']
  gravidezPlanejada?: Maybe<Scalars['Boolean']>
  movimentacaoFetal?: Maybe<Scalars['Boolean']>
  tipoEdema?: Maybe<TipoEdemaEnum>
  preNatal?: Maybe<PreNatal>
}

export type AtendimentoProfissionalPuericultura = {
  __typename?: 'AtendimentoProfissionalPuericultura'
  id: Scalars['ID']
  neuroAlteracaoFenotipica?: Maybe<Array<Maybe<NeuroAlteracaoFenotipicaEvolucao>>>
  neuroFatorRisco?: Maybe<Array<Maybe<NeuroFatorRiscoEvolucao>>>
  neuroMarco?: Maybe<Array<Maybe<NeuroMarcoEvolucao>>>
  aleitamentoMaterno?: Maybe<TipoAleitamentoMaternoEnum>
}

export type AtendimentoPuericultura = {
  __typename?: 'AtendimentoPuericultura'
  id?: Maybe<Scalars['ID']>
  dataInicio?: Maybe<Scalars['Instant']>
  nomeProfissional?: Maybe<Scalars['String']>
}

export type AtendimentosFinalizadosInput = {
  periodo?: Maybe<Period>
  equipeId?: Maybe<Scalars['ID']>
  lotacaoId?: Maybe<Scalars['ID']>
  hasAssinatura?: Maybe<Scalars['Boolean']>
  pageParams: PageParams
}

export type AtendimentosPayload = {
  __typename?: 'AtendimentosPayload'
  content: Array<Maybe<Atendimento>>
  pageInfo: PageInfo
}

export type AtendimentosProfissionalPayload = {
  __typename?: 'AtendimentosProfissionalPayload'
  content: Array<Maybe<AtendimentoProfissional>>
  pageInfo: PageInfo
}

export type AtendimentosProfissionalQueryInput = {
  cidadao?: Maybe<Scalars['ID']>
  dataInicioAfter?: Maybe<Scalars['LocalDate']>
  dataInicioBefore?: Maybe<Scalars['LocalDate']>
  status?: Maybe<StatusAtendimentoProfissional>
  pageParams?: Maybe<PageParams>
}

export type AtendimentosQueryInput = {
  statusAtendimento?: Maybe<Array<StatusAtendimento>>
  dataInicio?: Maybe<Scalars['Instant']>
  dataFim?: Maybe<Scalars['Instant']>
  tiposServicoIds?: Maybe<Array<Scalars['ID']>>
  equipesIds?: Maybe<Array<Scalars['ID']>>
  responsaveisIds?: Maybe<Array<Scalars['ID']>>
  somenteMeusAtendimentos?: Maybe<Scalars['Boolean']>
  isRegistroTardio?: Maybe<Scalars['Boolean']>
  query?: Maybe<Scalars['String']>
  ordem?: Maybe<OrdemListaAtendimentoEnum>
  pageParams?: Maybe<PageParams>
}

export type AtendimentoVacinacao = {
  __typename?: 'AtendimentoVacinacao'
  id: Scalars['ID']
  atendimentoProfissional: AtendimentoProfissional
  prontuario: Prontuario
  isGestante?: Maybe<Scalars['Boolean']>
  isPuerpera?: Maybe<Scalars['Boolean']>
  isViajante?: Maybe<Scalars['Boolean']>
  isComunicanteHanseniase?: Maybe<Scalars['Boolean']>
  registrosVacinacao?: Maybe<Array<RegistroVacinacao>>
}

export type AtendimentoVacinacaoAprovacaoInput = {
  atendimentoInput: AtendimentoVacinacaoInput
  rascunho: Scalars['String']
}

export type AtendimentoVacinacaoInput = {
  id: Scalars['ID']
  condicoesVacinacao: CondicoesInput
  finalizacaoVacinacao: FinalizacaoAtendimentoInput
  registroVacinacao: Array<RegistroVacinacaoInput>
  imprimirAtendimento?: Maybe<Scalars['Boolean']>
}

export type Atestado = {
  __typename?: 'Atestado'
  id: Scalars['ID']
  atendimentoProfissional: AtendimentoProfissional
  descricao?: Maybe<Scalars['String']>
  tipo?: Maybe<TipoAtestadoEnum>
  assinadoDigitalmente: Scalars['Boolean']
}

export type AtestadoEmBrancoPlanoInput = {
  id?: Maybe<Scalars['ID']>
  descricao: Scalars['String']
  assinadoDigitalmente: Scalars['Boolean']
}

export type AtestadoModelo = {
  __typename?: 'AtestadoModelo'
  id: Scalars['ID']
  nome: Scalars['String']
  descricao: Scalars['String']
  profissional: Profissional
}

export type AtestadoModeloInput = {
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
  descricao: Scalars['String']
}

export type AtestadoPadraoPlanoInput = {
  id?: Maybe<Scalars['ID']>
  descricao: Scalars['String']
  cpfCns?: Maybe<Scalars['String']>
  naoPossui?: Maybe<Scalars['Boolean']>
  cid10Id?: Maybe<Scalars['ID']>
  date: Scalars['LocalDate']
  hora: Scalars['String']
  dias: Scalars['Int']
  assinadoDigitalmente: Scalars['Boolean']
}

export type AtestadoPlanoInput = {
  emBranco?: Maybe<Array<Maybe<AtestadoEmBrancoPlanoInput>>>
  padrao?: Maybe<Array<Maybe<AtestadoPadraoPlanoInput>>>
  licencaMaternidade?: Maybe<Array<Maybe<LicencaMaternidadePlanoInput>>>
}

export type AtestadoQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  somenteMeusAtestados?: Maybe<Scalars['Boolean']>
  cboId?: Maybe<Scalars['ID']>
  periodo?: Maybe<Period>
  pageParams?: Maybe<PageParams>
}

export type AtestadoQueryPayload = {
  __typename?: 'AtestadoQueryPayload'
  content: Array<Maybe<Atestado>>
}

export type AtivacaoAgendaOnlineInput = {
  chave: Scalars['String']
  contraChave: Scalars['String']
}

export type AtualizarContatoCidadaoInput = {
  cidadaoId: Scalars['Long']
  telefoneCelular?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type AtualizarEmailCidadaoInput = {
  cidadaoId: Scalars['Long']
  email?: Maybe<Scalars['String']>
}

export type AtualizarEnderecoTerritorioInput = {
  filter: TerritorioLogradouroQueryInput
  cep: Scalars['String']
  bairro: Scalars['String']
  tipoLogradouroNuDne: Scalars['String']
  logradouro: Scalars['String']
  progressToken: Scalars['String']
}

export type AuditoriaHistorico = {
  __typename?: 'AuditoriaHistorico'
  id: Scalars['ID']
  dataInicio: Scalars['LocalDate']
  dataFim: Scalars['LocalDate']
  dataSolicitacao: Scalars['Instant']
  status: StatusProcessoAuditoria
  descricaoErro?: Maybe<Scalars['String']>
  csv?: Maybe<Scalars['Boolean']>
}

export type AuditoriaHistoricoPayload = {
  __typename?: 'AuditoriaHistoricoPayload'
  content: Array<Maybe<AuditoriaHistorico>>
  pageInfo: PageInfo
}

export type AuditoriaHistoricoQueryInput = {
  pageParams?: Maybe<PageParams>
}

export type AuditoriaInput = {
  dataInicio: Scalars['LocalDate']
  dataFim: Scalars['LocalDate']
  tiposEvento?: Maybe<Array<Maybe<Scalars['ID']>>>
  detalheEvento?: Maybe<Scalars['String']>
  usuariosGerador?: Maybe<Array<Maybe<Scalars['ID']>>>
  tiposRegistroAfetado?: Maybe<Array<Maybe<Scalars['ID']>>>
  idRegistroAfetado?: Maybe<Scalars['String']>
  componenteGerador?: Maybe<Scalars['String']>
  tipoArquivo: TipoArquivoEnum
}

export type AuditoriaTipoEvento = {
  __typename?: 'AuditoriaTipoEvento'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type AuditoriaTipoRegistroAfetado = {
  __typename?: 'AuditoriaTipoRegistroAfetado'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type AutorizarMunicipioInput = {
  contraChave: Scalars['String']
}

export type AvaliacaoAlergiaInput = {
  alergiaId?: Maybe<Scalars['ID']>
  tipoReacao?: Maybe<TipoReacaoAlergia>
  categoriaSubstancia: CategoriaSubstanciaAlergia
  substanciaId: Scalars['ID']
  criticidade?: Maybe<CriticidadeAlergia>
  dataInicio?: Maybe<Scalars['LocalDate']>
  observacao?: Maybe<Scalars['String']>
  grauCerteza?: Maybe<GrauCertezaAlergia>
  manifestacoesIds?: Maybe<Array<Scalars['ID']>>
  isAvaliacao?: Maybe<Scalars['Boolean']>
}

export type AvaliacaoDesenvolvimento = {
  id: Scalars['ID']
  descricao: Scalars['String']
  status: StatusAvaliadoEnum
  dataAvaliacao?: Maybe<Scalars['LocalDate']>
}

export type AvaliacaoDesenvolvimentoInput = {
  id: Scalars['ID']
  status?: Maybe<StatusAvaliadoEnum>
  alcancadoComMeses?: Maybe<Scalars['Int']>
  alcancadoComAnos?: Maybe<Scalars['Int']>
}

export type AvaliacaoInput = {
  texto?: Maybe<Scalars['String']>
  problemasCondicoesAvaliadas?: Maybe<Array<AvaliacaoProblemaInput>>
  alergiasAvaliadas?: Maybe<Array<AvaliacaoAlergiaInput>>
  vigilanciaSaudeBucal?: Maybe<VigilanciaSaudeBucalInput>
  encerrarGestacao?: Maybe<EncerrarGestacaoInput>
}

export type AvaliacaoProblemaInput = {
  id?: Maybe<Scalars['ID']>
  problemaId?: Maybe<Scalars['ID']>
  incluirListaProblemas?: Maybe<Scalars['Boolean']>
  ciapId?: Maybe<Scalars['ID']>
  cidId?: Maybe<Scalars['ID']>
  situacao?: Maybe<SituacaoProblema>
  dataInicio?: Maybe<Scalars['LocalDate']>
  dataFim?: Maybe<Scalars['LocalDate']>
  observacao?: Maybe<Scalars['String']>
}

export type Bairro = {
  __typename?: 'Bairro'
  id: Scalars['ID']
  nome: Scalars['String']
  dne: Scalars['String']
  municipio: Municipio
}

export type BairroInput = {
  query?: Maybe<Scalars['String']>
  municipioId?: Maybe<Scalars['ID']>
  ufId?: Maybe<Scalars['ID']>
  pageParams?: Maybe<PageParams>
}

export type BairroQueryPayload = {
  __typename?: 'BairroQueryPayload'
  content: Array<Maybe<Bairro>>
  pageInfo: PageInfo
}

export type BaseAcessoActions = {
  permissaoEdicao: Action
  permissaoExclusao: Action
  permissaoAtivacaoInativacao: Action
}

export type BaseCalendarioVacinal = {
  __typename?: 'BaseCalendarioVacinal'
  id: Scalars['ID']
  descricaoImunobiologico: Scalars['String']
  mostrarNoCalendarioDoGrupo: Scalars['Boolean']
  idadeRecomendada?: Maybe<Scalars['String']>
  imunobiologico: Imunobiologico
  dose: DoseImunobiologico
  faixaEtaria: FaixaEtariaVacinacao
  ordemImuno: Scalars['Int']
}

export enum BeneficioDbEnum {
  BOLSA_FAMILIA = 'BOLSA_FAMILIA',
  CESTA_DE_ALIMENTO = 'CESTA_DE_ALIMENTO',
  LEITE_NAO_HUMANO = 'LEITE_NAO_HUMANO',
  NAO_RECEBE_BENEFICIO_SOCIAL = 'NAO_RECEBE_BENEFICIO_SOCIAL',
  OUTROS_BENEFICIOS = 'OUTROS_BENEFICIOS',
  APOSENTADO = 'APOSENTADO',
  BENEFICIO_DE_PRESTACAO_CONTINUADA = 'BENEFICIO_DE_PRESTACAO_CONTINUADA',
}

export type BuscaAtivaVacinacaoDto = {
  __typename?: 'BuscaAtivaVacinacaoDto'
  idCidadao: Scalars['ID']
  nomeSocialOuNomeCidadao: Scalars['String']
  cnsCidadao?: Maybe<Scalars['String']>
  cpfCidadao?: Maybe<Scalars['String']>
  dataNascimentoCidadao?: Maybe<Scalars['LocalDate']>
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  endereco?: Maybe<Scalars['String']>
  vacinasAtrasadas?: Maybe<Array<Maybe<BuscaAtivaVacinaImuno>>>
  vacinasNoPrazo?: Maybe<Array<Maybe<BuscaAtivaVacinaImuno>>>
  vacinasAplicadas?: Maybe<Array<Maybe<BuscaAtivaVacinaImuno>>>
}

export type BuscaAtivaVacinacaoFiltrosAvancadosInput = {
  imunobiologicoIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  doseIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  grupoAtendimentoIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  bairro?: Maybe<Scalars['String']>
  nomeTipoLogradouro?: Maybe<Scalars['String']>
  logradouro?: Maybe<Scalars['String']>
}

export type BuscaAtivaVacinacaoInput = {
  grupoAlvo: GrupoAlvoBuscaAtivaVacinacaoEnum
  faixaEtaria?: Maybe<FaixaEtariaInput>
  status?: Maybe<StatusVacinacaoEnum>
  equipeVinculadaIne: Scalars['String']
  microareas?: Maybe<Array<Maybe<Scalars['String']>>>
  filtrosAvancados?: Maybe<BuscaAtivaVacinacaoFiltrosAvancadosInput>
  tipoVisualizacao: TipoVisualizacaoBuscaAtivaVacinacaoEnum
  pageParams?: Maybe<PageParams>
}

export type BuscaAtivaVacinacaoPayload = {
  __typename?: 'BuscaAtivaVacinacaoPayload'
  content: Array<BuscaAtivaVacinacaoDto>
  pageInfo: PageInfo
}

export type BuscaAtivaVacinaImuno = {
  __typename?: 'BuscaAtivaVacinaImuno'
  cidadaoId: Scalars['ID']
  nome?: Maybe<Scalars['String']>
  siglaDose?: Maybe<Scalars['String']>
  dataAplicacaoVacina?: Maybe<Scalars['LocalDate']>
}

export type BuscaCidadaoCadsusInput = {
  cidadao: Scalars['ID']
  uuid: Scalars['String']
}

export type BuscaCidadaosCadsusResultDto = {
  __typename?: 'BuscaCidadaosCadsusResultDto'
  usuario: Scalars['ID']
  uuid: Scalars['String']
  cidadaos?: Maybe<Array<Maybe<CidadaoCadsus>>>
  resultCadsus?: Maybe<SincronizacaoCadsusResultEnum>
}

export type BuscaListaCidadaoCadsusInput = {
  query: CidadaosQueryInput
  uuid: Scalars['String']
}

export enum BuscaPeriodoEnum {
  PERIODO_ENTROU_EM_AD = 'PERIODO_ENTROU_EM_AD',
  PERIODO_ULTIMA_VISITA = 'PERIODO_ULTIMA_VISITA',
  PERIODO_PROXIMA_VISITA = 'PERIODO_PROXIMA_VISITA',
  PERIODO_REGISTRO_OBITO = 'PERIODO_REGISTRO_OBITO',
  PERIODO_NENHUM = 'PERIODO_NENHUM',
}

export type CadastroEnderecoEnvioDto = {
  __typename?: 'CadastroEnderecoEnvioDto'
  pedidoEnvioBase?: Maybe<PedidoEnvioBase>
  errorLogin?: Maybe<Array<Maybe<Scalars['String']>>>
  errorImportacaoCnes?: Maybe<LocalidadeCnesDesatualizadoDto>
}

export type CampoAlteradoCadsus = {
  __typename?: 'CampoAlteradoCadsus'
  campo?: Maybe<Scalars['String']>
  conteudo?: Maybe<Scalars['String']>
}

export type CampoSalvoRelatorioGerencial = {
  __typename?: 'CampoSalvoRelatorioGerencial'
  key: Scalars['String']
  posicao: PosicaoCampoRelatorioGerencialEnum
  items: Array<Scalars['String']>
}

export type CampoSalvoRelatorioGerencialInput = {
  key: Scalars['String']
  posicao: PosicaoCampoRelatorioGerencialEnum
  items: Array<Scalars['String']>
}

export type CancelarAgendamentoInput = {
  id: Scalars['ID']
  justificativa: JustificativaCancelarAgendamentoEnum
  justificativaOutra?: Maybe<Scalars['String']>
}

export type CancelarSolicitacaoEntradaVideochamadaInput = {
  videochamadaUuid: Scalars['String']
  participanteId: Scalars['ID']
}

export type CancelarSolicitacaoEnvioInput = {
  solicitacaoId: Scalars['ID']
}

export type CaracteristicaLesao = {
  __typename?: 'CaracteristicaLesao'
  id: Scalars['ID']
  caracteristicaLesao: CaracteristicaLesaoEnum
  descricao: Scalars['String']
}

export enum CaracteristicaLesaoEnum {
  LESAO_PLANA_MACULA = 'LESAO_PLANA_MACULA',
  LESAO_PLANA_PLACA = 'LESAO_PLANA_PLACA',
  LESAO_PLANA_PAPULA = 'LESAO_PLANA_PAPULA',
  LESAO_PLANA_TUMEFACAO = 'LESAO_PLANA_TUMEFACAO',
  LESAO_PLANA_PSEUDOMEMBRANA = 'LESAO_PLANA_PSEUDOMEMBRANA',
  LESAO_LIQUIDA_VESICULA = 'LESAO_LIQUIDA_VESICULA',
  LESAO_LIQUIDA_PUSTULA = 'LESAO_LIQUIDA_PUSTULA',
  LESAO_TECIDUAL_EROSAO = 'LESAO_TECIDUAL_EROSAO',
  LESAO_TECIDUAL_ULCERA = 'LESAO_TECIDUAL_ULCERA',
  LESAO_TECIDUAL_FISSURA = 'LESAO_TECIDUAL_FISSURA',
  QUANTIDADE_UNICA = 'QUANTIDADE_UNICA',
  QUANTIDADE_BILATERAL = 'QUANTIDADE_BILATERAL',
  QUANTIDADE_MULTIPLAS = 'QUANTIDADE_MULTIPLAS',
  BASE_SESSIL = 'BASE_SESSIL',
  BASE_PEDICULADA = 'BASE_PEDICULADA',
  TAMANHO_ATE_4 = 'TAMANHO_ATE_4',
  TAMANHO_ENTRE_5_E_10 = 'TAMANHO_ENTRE_5_E_10',
  TAMANHO_ACIMA_DE_10 = 'TAMANHO_ACIMA_DE_10',
  COR_BRANCA = 'COR_BRANCA',
  COR_VIOLACEA = 'COR_VIOLACEA',
  COR_VERMELHA = 'COR_VERMELHA',
  COR_ACASTANHADA = 'COR_ACASTANHADA',
  SUPERFICIE_LISA = 'SUPERFICIE_LISA',
  SUPERFICIE_RUGOSA = 'SUPERFICIE_RUGOSA',
  SUPERFICIE_ULCERADA = 'SUPERFICIE_ULCERADA',
  SUPERFICIE_GLANULOMATOSA = 'SUPERFICIE_GLANULOMATOSA',
  SUPERFICIE_NECROSADA = 'SUPERFICIE_NECROSADA',
  CONSISTENCIA_MOLE = 'CONSISTENCIA_MOLE',
  CONSISTENCIA_FIRME = 'CONSISTENCIA_FIRME',
  CONSISTENCIA_DURA = 'CONSISTENCIA_DURA',
  SINTOMATOLOGIA_AUSENTE = 'SINTOMATOLOGIA_AUSENTE',
  SINTOMATOLOGIA_DOR = 'SINTOMATOLOGIA_DOR',
  SINTOMATOLOGIA_PARESTESIA = 'SINTOMATOLOGIA_PARESTESIA',
  SINTOMATOLOGIA_ARDENCIA = 'SINTOMATOLOGIA_ARDENCIA',
}

export enum CategoriaProblemaEnum {
  CIAP = 'CIAP',
  CID10 = 'CID10',
  OUTRO = 'OUTRO',
  EXAME = 'EXAME',
  CID10_PRINCIPAL = 'CID10_PRINCIPAL',
  CID10_SECUNDARIO_UM = 'CID10_SECUNDARIO_UM',
  CID10_SECUNDARIO_DOIS = 'CID10_SECUNDARIO_DOIS',
}

export enum CategoriaSubstanciaAlergia {
  MEDICAMENTO = 'MEDICAMENTO',
  ALIMENTO = 'ALIMENTO',
  AMBIENTE = 'AMBIENTE',
  BIOLOGICO = 'BIOLOGICO',
}

export enum CategoriaSubstanciaAlergiaLegado {
  ALIMENTO = 'ALIMENTO',
  ANIMAL = 'ANIMAL',
  INGREDIENTE_NAO_ATIVO = 'INGREDIENTE_NAO_ATIVO',
  FARMACO_PRESENTE = 'FARMACO_PRESENTE',
  OUTRAS_SUBSTANCIAS = 'OUTRAS_SUBSTANCIAS',
  OUTROS = 'OUTROS',
  PRODUTO_AMBIENTAL = 'PRODUTO_AMBIENTAL',
}

export type Cbo = {
  __typename?: 'Cbo'
  id: Scalars['ID']
  nome: Scalars['String']
  cbo2002: Scalars['String']
}

export type CboDisponibilizarFormInput = {
  id: Scalars['ID']
  disponivelLotacao: Scalars['Boolean']
}

export type CboDuracaoFormInput = {
  id: Scalars['ID']
  duracao?: Maybe<Scalars['Int']>
}

export type CboGrupo = {
  __typename?: 'CboGrupo'
  grupo: AcessoCbo
  cbos?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CboMunicipio = {
  __typename?: 'CboMunicipio'
  cbo: Cbo
  disponivelLotacao?: Maybe<Scalars['Boolean']>
  duracao?: Maybe<Scalars['Int']>
  duracaoPadrao?: Maybe<Scalars['Int']>
  actions: CboMunicipioActions
  possuiLotacaoComAgendaOnline?: Maybe<Scalars['Boolean']>
}

export type CboMunicipioActions = {
  __typename?: 'CboMunicipioActions'
  inativar: Action
}

export type CboPayload = {
  __typename?: 'CboPayload'
  content: Array<Cbo>
  pageInfo: PageInfo
}

export type CbosCuidadoCompartilhadoDto = {
  __typename?: 'CbosCuidadoCompartilhadoDto'
  cbo: Cbo
  isVinculado: Scalars['Boolean']
}

export type CbosCuidadoCompartilhadoPayload = {
  __typename?: 'CbosCuidadoCompartilhadoPayload'
  content: Array<CbosCuidadoCompartilhadoDto>
  pageInfo: PageInfo
}

export type CbosCuidadoCompartilhadoQueryInput = {
  query?: Maybe<Scalars['String']>
  onlyEmulti?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type CbosQueryInput = {
  query?: Maybe<Scalars['String']>
  mostrarIndisponivelLotacao?: Maybe<Scalars['Boolean']>
  apenasEquipeCuidadoCompartilhado?: Maybe<Scalars['Boolean']>
  equipeVinculadaId?: Maybe<Scalars['ID']>
  nivelSuperior?: Maybe<Scalars['Boolean']>
  excludeProfissionaisIds?: Maybe<Array<Scalars['ID']>>
  pageParams?: Maybe<PageParams>
  cboSupervisor?: Maybe<Scalars['String']>
}

export type CbosQueryPayload = {
  __typename?: 'CbosQueryPayload'
  content: Array<Maybe<CboMunicipio>>
  pageInfo: PageInfo
}

export type CbosTrocaExecutanteCuidadoCompartilhadoQueryInput = {
  query?: Maybe<Scalars['String']>
  estabelecimentoReferenciaId?: Maybe<Scalars['ID']>
  equipeVinculadaId?: Maybe<Scalars['ID']>
  excludeProfissionaisIds?: Maybe<Array<Scalars['ID']>>
  onlyVinculacaoEquipe: Scalars['Boolean']
  pageParams?: Maybe<PageParams>
}

export enum CdsOrigemEnum {
  TODAS = 'TODAS',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  PEC = 'PEC',
  EXTERNO = 'EXTERNO',
  ANDROID_ACS = 'ANDROID_ACS',
  ANDROID_AC = 'ANDROID_AC',
  APP_VACINACAO = 'APP_VACINACAO',
}

export type Certificado = {
  __typename?: 'Certificado'
  path: Scalars['ID']
  nome: Scalars['String']
}

export type CertificadoPayload = {
  __typename?: 'CertificadoPayload'
  content: Array<Maybe<Certificado>>
}

export type CertificadoQueryInput = {
  query?: Maybe<Scalars['String']>
}

export type Ciap = {
  __typename?: 'Ciap'
  id: Scalars['ID']
  codigo: Scalars['String']
  descricao: Scalars['String']
  inclui?: Maybe<Scalars['String']>
  exclui?: Maybe<Scalars['String']>
  cid10Encaminhamento?: Maybe<Cid10>
}

export enum CiapCapituloEnum {
  PROCEDIMENTOS = 'PROCEDIMENTOS',
  GERAL_INESPECIFICO = 'GERAL_INESPECIFICO',
  SANGUE_SISTEMA_HEMATOPOIETICO_LINFATICO_BACO = 'SANGUE_SISTEMA_HEMATOPOIETICO_LINFATICO_BACO',
  DIGESTIVO = 'DIGESTIVO',
  OLHO = 'OLHO',
  OUVIDO = 'OUVIDO',
  CIRCULATORIO = 'CIRCULATORIO',
  MUSCULO_ESQUELETICO = 'MUSCULO_ESQUELETICO',
  NEUROLOGICO = 'NEUROLOGICO',
  PSICOLOGICO = 'PSICOLOGICO',
  RESPIRATORIO = 'RESPIRATORIO',
  PELE = 'PELE',
  ENDOCRINO_METABOLICO_NUTRICIONAL = 'ENDOCRINO_METABOLICO_NUTRICIONAL',
  URINARIO = 'URINARIO',
  GRAVIDEZ_PARTO_PLANEJAMENTO_FAMILIAR = 'GRAVIDEZ_PARTO_PLANEJAMENTO_FAMILIAR',
  GENITAL_FEMININO = 'GENITAL_FEMININO',
  GENITAL_MASCULINO = 'GENITAL_MASCULINO',
  PROBLEMAS_SOCIAIS = 'PROBLEMAS_SOCIAIS',
}

export type CiapPlanoInput = {
  id?: Maybe<Scalars['ID']>
  ciapId: Scalars['ID']
  observacao?: Maybe<Scalars['String']>
}

export type CiapQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  sexo?: Maybe<SexoEnum>
  capitulosIncluidos?: Maybe<Array<Maybe<CiapCapituloEnum>>>
  capitulosExcluidos?: Maybe<Array<Maybe<CiapCapituloEnum>>>
  excluirCIAPsDAB?: Maybe<Scalars['Boolean']>
  ciapComCid10Encaminhamento?: Maybe<Scalars['Boolean']>
  idadeCidadaoEmAnos?: Maybe<Scalars['Int']>
}

export type CiapQueryPayLoad = {
  __typename?: 'CiapQueryPayLoad'
  content: Array<Maybe<Ciap>>
  pageInfo: PageInfo
}

export type Cid10 = {
  __typename?: 'Cid10'
  id: Scalars['ID']
  codigo: Scalars['String']
  nome: Scalars['String']
  sexo?: Maybe<Scalars['String']>
  ciapsRelacionadas?: Maybe<Array<Maybe<Ciap>>>
}

export type Cid10QueryInput = {
  query?: Maybe<Scalars['String']>
  sexo?: Maybe<SexoEnum>
  ciapRelacionada?: Maybe<Scalars['ID']>
  parteBucal?: Maybe<ParteBucalEnum>
  idadeCidadaoEmAnos?: Maybe<Scalars['Int']>
  somenteRelacionados?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type Cid10QueryPayLoad = {
  __typename?: 'Cid10QueryPayLoad'
  content: Array<Maybe<Cid10>>
  pageInfo: PageInfo
}

export type Cidadao = {
  __typename?: 'Cidadao'
  id: Scalars['ID']
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  nisPisPasep?: Maybe<Scalars['String']>
  nome: Scalars['String']
  nomeSocial?: Maybe<Scalars['String']>
  nomeTradicional?: Maybe<Scalars['String']>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  dataAtualizado?: Maybe<Scalars['Instant']>
  dataObito?: Maybe<Scalars['LocalDate']>
  numeroDocumentoObito?: Maybe<Scalars['String']>
  sexo: SexoEnum
  desconheceNomeMae?: Maybe<Scalars['Boolean']>
  nomeMae?: Maybe<Scalars['String']>
  desconheceNomePai?: Maybe<Scalars['Boolean']>
  nomePai?: Maybe<Scalars['String']>
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  endereco?: Maybe<Endereco>
  desejaInformarIdentidadeGenero?: Maybe<Scalars['Boolean']>
  identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
  etnia?: Maybe<Etnia>
  racaCor: RacaCor
  area?: Maybe<Scalars['String']>
  microArea?: Maybe<Scalars['String']>
  statusForaArea?: Maybe<Scalars['Boolean']>
  cbo?: Maybe<Cbo>
  escolaridade?: Maybe<Escolaridade>
  ativo?: Maybe<Scalars['Boolean']>
  localidadeNascimento?: Maybe<Municipio>
  dataUltimoAtendimento?: Maybe<Scalars['LocalDate']>
  faleceu?: Maybe<Scalars['Boolean']>
  prontuario?: Maybe<Prontuario>
  unificado?: Maybe<Scalars['Boolean']>
  possuiAgendamento?: Maybe<Scalars['Boolean']>
  tipoSanguineo?: Maybe<TipoSanguineoEnum>
  nomeResponsavel?: Maybe<Scalars['String']>
  dtNascimentoResponsavel?: Maybe<Scalars['LocalDate']>
  cpfCnsResponsavel?: Maybe<Scalars['String']>
  naoPossuiCuidador?: Maybe<Scalars['Boolean']>
  cpfCnsCuidador?: Maybe<Scalars['String']>
  nomeCuidador?: Maybe<Scalars['String']>
  dtNascimentoCuidador?: Maybe<Scalars['LocalDate']>
  tipoCuidador?: Maybe<RelacaoCidadaoCuidador>
  desejaInformarOrientacaoSexual?: Maybe<Scalars['Boolean']>
  orientacaoSexualDbEnum?: Maybe<OrientacaoSexualEnum>
  estadoCivil?: Maybe<EstadoCivil>
  paisExterior?: Maybe<Pais>
  localidadeExterior?: Maybe<Scalars['String']>
  nacionalidade?: Maybe<Nacionalidade>
  portariaNaturalizacao?: Maybe<Scalars['String']>
  dataNaturalizacao?: Maybe<Scalars['LocalDate']>
  paisNascimento?: Maybe<Pais>
  dataEntradaBrasil?: Maybe<Scalars['LocalDate']>
  stCompartilhaProntuario?: Maybe<Scalars['Boolean']>
  cidadaoVinculacaoEquipe?: Maybe<CidadaoVinculacaoEquipe>
  uuidUltimaFicha?: Maybe<Scalars['String']>
  presenteListaAtendimento?: Maybe<Scalars['Boolean']>
  equipeAtendimentoAd?: Maybe<Equipe>
  ultimaDum?: Maybe<Dum>
  unificacaoBase?: Maybe<Scalars['String']>
  periodoAusenciaList?: Maybe<Array<Maybe<PeriodoAusencia>>>
  cidadaoAldeado?: Maybe<CidadaoAldeado>
  enderecoIndigena?: Maybe<EnderecoIndigena>
  numeroFamilia?: Maybe<Scalars['String']>
  tipoEndereco?: Maybe<TipoEnderecoEnum>
  tipoLocalNascimento?: Maybe<TipoLocalNascimentoEnum>
  isCidadaoAusente?: Maybe<Scalars['Boolean']>
}

export type CidadaoAldeado = {
  __typename?: 'CidadaoAldeado'
  id: Scalars['ID']
  aldeiaNascimento?: Maybe<Aldeia>
  funcaoSocial?: Maybe<FuncaoSocial>
  localOcorrencia?: Maybe<LocalOcorrencia>
  nomeTradicional?: Maybe<Scalars['String']>
  responsavelLegal?: Maybe<Scalars['String']>
  stChefeFamilia: Scalars['Boolean']
  beneficios?: Maybe<Array<Maybe<BeneficioDbEnum>>>
  unidadeFunai?: Maybe<Scalars['String']>
  livro?: Maybe<Scalars['Int']>
  folha?: Maybe<Scalars['Int']>
  cadastroUnico?: Maybe<Scalars['String']>
  ufNascimento?: Maybe<Uf>
  dtEmissao?: Maybe<Scalars['LocalDate']>
  dtReconhecimento?: Maybe<Scalars['LocalDate']>
  dtAusencia?: Maybe<Scalars['LocalDate']>
  dtRetorno?: Maybe<Scalars['LocalDate']>
}

export type CidadaoAldeadoInput = {
  aldeiaNascimento?: Maybe<Scalars['String']>
  funcaoSocial?: Maybe<Scalars['String']>
  localOcorrencia?: Maybe<Scalars['String']>
  nomeTradicional?: Maybe<Scalars['String']>
  responsavelLegal?: Maybe<Scalars['String']>
  stChefeFamilia: Scalars['Boolean']
  beneficios: Array<Maybe<BeneficioDbEnum>>
  unidadeFunai?: Maybe<Scalars['String']>
  livro?: Maybe<Scalars['Int']>
  folha?: Maybe<Scalars['Int']>
  cadastroUnico?: Maybe<Scalars['String']>
  ufNascimento?: Maybe<Scalars['String']>
  dtEmissao?: Maybe<Scalars['LocalDate']>
  dtReconhecimento?: Maybe<Scalars['LocalDate']>
  dtAusencia?: Maybe<Scalars['LocalDate']>
  dtRetorno?: Maybe<Scalars['LocalDate']>
}

export type CidadaoAldeadoQueryInput = {
  naoIndigena?: Maybe<Scalars['Boolean']>
  etnia?: Maybe<Scalars['String']>
  dsei?: Maybe<Scalars['String']>
  poloBase?: Maybe<Scalars['String']>
  aldeia?: Maybe<Scalars['String']>
}

export type CidadaoBasico = {
  __typename?: 'CidadaoBasico'
  id: Scalars['ID']
  nome: Scalars['String']
  nomeSocial?: Maybe<Scalars['String']>
  nomeTradicional?: Maybe<Scalars['String']>
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  nomeMae?: Maybe<Scalars['String']>
  contato?: Maybe<ContatoCidadao>
  sexo: SexoEnum
  racaCor: RacaCor
  dataNascimento?: Maybe<Scalars['LocalDate']>
  presenteListaAtendimento?: Maybe<Scalars['Boolean']>
  area?: Maybe<Scalars['String']>
  microArea?: Maybe<Scalars['String']>
  desejaInformarIdentidadeGenero?: Maybe<Scalars['Boolean']>
  identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
  stCompartilhaProntuario?: Maybe<Scalars['Boolean']>
  ultimaDum?: Maybe<Dum>
  equipeAtendimentoAd?: Maybe<Equipe>
  isCidadaoAusente?: Maybe<Scalars['Boolean']>
}

export type CidadaoCadsus = {
  __typename?: 'CidadaoCadsus'
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  nome: Scalars['String']
  nomeSocial?: Maybe<Scalars['String']>
  nomeMae?: Maybe<Scalars['String']>
  nomePai?: Maybe<Scalars['String']>
  racaCor?: Maybe<RacaCor>
  etnia?: Maybe<Etnia>
  nacionalidade?: Maybe<Nacionalidade>
  telefoneContato?: Maybe<Array<Maybe<Scalars['String']>>>
  telefoneResidencial?: Maybe<Array<Maybe<Scalars['String']>>>
  telefoneCelular?: Maybe<Array<Maybe<Scalars['String']>>>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  dataObito?: Maybe<Scalars['LocalDate']>
  obito?: Maybe<Scalars['String']>
  sexo?: Maybe<SexoEnum>
  email?: Maybe<Scalars['String']>
  municipioNascimento?: Maybe<Municipio>
  paisNascimento?: Maybe<Pais>
  portariaNaturalizacao?: Maybe<Scalars['String']>
  dataEntradaBrasil?: Maybe<Scalars['LocalDate']>
  dataNaturalizacao?: Maybe<Scalars['LocalDate']>
  endereco?: Maybe<Endereco>
  paisResidencia?: Maybe<Pais>
  municipioResidenciaExterior?: Maybe<Scalars['String']>
  numeroPisPasep?: Maybe<Scalars['String']>
}

export type CidadaoDuplicadoDto = {
  __typename?: 'CidadaoDuplicadoDto'
  id?: Maybe<Scalars['ID']>
  ativo?: Maybe<Scalars['Boolean']>
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  nome?: Maybe<Scalars['String']>
}

export type CidadaoInput = {
  id?: Maybe<Scalars['ID']>
  cns?: Maybe<Scalars['String']>
  cpf?: Maybe<Scalars['String']>
  nome: Scalars['String']
  nomeSocial?: Maybe<Scalars['String']>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  sexo?: Maybe<SexoEnum>
  racaCor?: Maybe<RacaCorDbEnum>
  etnia?: Maybe<Scalars['String']>
  nomeMae?: Maybe<Scalars['String']>
  desconheceNomeMae?: Maybe<Scalars['Boolean']>
  nomePai?: Maybe<Scalars['String']>
  desconheceNomePai?: Maybe<Scalars['Boolean']>
  nacionalidade?: Maybe<NacionalidadeEnum>
  municipioNascimento?: Maybe<Scalars['String']>
  portariaNaturalizacao?: Maybe<Scalars['String']>
  dataNaturalizacao?: Maybe<Scalars['LocalDate']>
  paisNascimento?: Maybe<Scalars['String']>
  dataEntradaPais?: Maybe<Scalars['LocalDate']>
  cidadaoFaleceu?: Maybe<Scalars['Boolean']>
  dataObito?: Maybe<Scalars['LocalDate']>
  numeroDocumentoObito?: Maybe<Scalars['String']>
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  endereco?: Maybe<EnderecoInput>
  paisResidencia?: Maybe<Scalars['String']>
  municipioResidenciaExterior?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  microArea?: Maybe<Scalars['String']>
  statusForaArea?: Maybe<Scalars['Boolean']>
  nisPisPasep?: Maybe<Scalars['String']>
  estadoCivil?: Maybe<Scalars['String']>
  tipoSanguineo?: Maybe<TipoSanguineoEnum>
  ocupacao?: Maybe<Scalars['String']>
  escolaridade?: Maybe<Scalars['String']>
  desejaInformarOrientacaoSexual: Scalars['Boolean']
  orientacaoSexualDbEnum?: Maybe<OrientacaoSexualEnum>
  desejaInformarIdentidadeGenero: Scalars['Boolean']
  identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
  desativarCompartilhamentoProntuario?: Maybe<Scalars['Boolean']>
  vinculacao?: Maybe<CidadaoVinculacaoInput>
  uuidUltimaFicha?: Maybe<Scalars['String']>
  isCidadaoAldeado: Scalars['Boolean']
  cidadaoAldeadoInput?: Maybe<CidadaoAldeadoInput>
  enderecoIndigenaInput?: Maybe<EnderecoIndigenaInput>
  numeroFamilia?: Maybe<Scalars['String']>
  tipoEndereco: TipoEnderecoEnum
  tipoLocalNascimento?: Maybe<TipoLocalNascimentoEnum>
}

export type CidadaoPossuiAgendamentoFuturoQueryInput = {
  id: Scalars['ID']
  dataReferencia: Scalars['LocalDate']
}

export type CidadaosAdQueryInput = {
  query?: Maybe<Scalars['String']>
  tipoBuscaPeriodo?: Maybe<Scalars['String']>
  periodoBusca?: Maybe<Period>
  condutaDesfecho?: Maybe<AtencaoDomiciliarCondutaDesfecho>
  modalidade?: Maybe<AtencaoDomiciliarModalidade>
  equipeId?: Maybe<Scalars['ID']>
  pageParams?: Maybe<PageParams>
}

export type CidadaosAdQueryPayload = {
  __typename?: 'CidadaosAdQueryPayload'
  content: Array<Maybe<AtencaoDomiciliarCidadao>>
  pageInfo: PageInfo
}

export type CidadaosComboQueryInput = {
  query?: Maybe<Scalars['String']>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  nomeMae?: Maybe<Scalars['String']>
  municipioNascimento?: Maybe<Scalars['String']>
  obito?: Maybe<Scalars['Boolean']>
  emAtencaoDomiciliar?: Maybe<Scalars['Boolean']>
  ativo?: Maybe<Scalars['Boolean']>
  limit?: Maybe<Scalars['Int']>
}

export type CidadaosFamiliaInput = {
  mostrarInconsistentes: Scalars['Boolean']
}

export type CidadaosPayload = {
  __typename?: 'CidadaosPayload'
  content: Array<Maybe<Cidadao>>
  pageInfo: PageInfo
}

export type CidadaosQueryInput = {
  pageParams?: Maybe<PageParams>
  query?: Maybe<Scalars['String']>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  nomeMae?: Maybe<Scalars['String']>
  municipioNascimento?: Maybe<Scalars['String']>
  cidadaoAldeado?: Maybe<CidadaoAldeadoQueryInput>
  ibgeMunicipioNascimento?: Maybe<Scalars['String']>
  idCidadaoList?: Maybe<Array<Scalars['ID']>>
  obito?: Maybe<Scalars['Boolean']>
  emAtencaoDomiciliar?: Maybe<Scalars['Boolean']>
  ativo?: Maybe<Scalars['Boolean']>
  idCidadaoUnificacao?: Maybe<Scalars['Long']>
}

export type CidadaoTerritorio = {
  __typename?: 'CidadaoTerritorio'
  id: Scalars['ID']
  uuidCidadao?: Maybe<Scalars['ID']>
  nome?: Maybe<Scalars['String']>
  nomeSocial?: Maybe<Scalars['String']>
  sexo?: Maybe<SexoEnum>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  isFaleceu?: Maybe<Scalars['Boolean']>
  permissaoReterritorializar?: Maybe<Action>
  ultimaFichaCadastrada?: Maybe<UltimaFichaCadastradaDto>
}

export type CidadaoTerritorioInput = {
  id: Scalars['ID']
  mudouSe?: Maybe<Scalars['Boolean']>
  registroObito?: Maybe<RegistroObitoInput>
}

export type CidadaoUnificacaoBase = {
  __typename?: 'CidadaoUnificacaoBase'
  id: Scalars['Long']
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  cidadao: Cidadao
}

export type CidadaoUnificacaoBaseInput = {
  pageParams: PageParams
}

export type CidadaoUnificacaoBasePayload = {
  __typename?: 'CidadaoUnificacaoBasePayload'
  content: Array<Maybe<CidadaoUnificacaoBase>>
  pageInfo: PageInfo
}

export type CidadaoVinculacaoEquipe = {
  __typename?: 'CidadaoVinculacaoEquipe'
  id?: Maybe<Scalars['ID']>
  tpCdsOrigem?: Maybe<CdsOrigemEnum>
  dataAtualizacaoCadastro?: Maybe<Scalars['Instant']>
  equipe?: Maybe<Equipe>
  unidadeSaude?: Maybe<UnidadeSaude>
  utilizarCadastroIndividual?: Maybe<Scalars['Boolean']>
}

export type CidadaoVinculacaoFichaDto = {
  __typename?: 'CidadaoVinculacaoFichaDto'
  perguntaSaidaCadastroEnum?: Maybe<PerguntaSaidaCadastroEnum>
  unidadeSaude?: Maybe<UnidadeSaude>
  equipe?: Maybe<Equipe>
}

export type CidadaoVinculacaoFichaQueryInput = {
  cpfCidadao?: Maybe<Scalars['String']>
  cnsCidadao?: Maybe<Scalars['String']>
  uuidFicha?: Maybe<Scalars['String']>
}

export type CidadaoVinculacaoInput = {
  ine?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  utilizarFichaCadastroIndividual?: Maybe<Scalars['Boolean']>
  cnes?: Maybe<Scalars['String']>
}

export enum ClassificacaoPrioridadeCuidadoEnum {
  BAIXA = 'BAIXA',
  MEDIA = 'MEDIA',
  ALTA = 'ALTA',
  MUITO_ALTA = 'MUITO_ALTA',
}

export enum ClassificacaoRisco {
  NAO_CLASSIFICADO = 'NAO_CLASSIFICADO',
  PEC_ALTA = 'PEC_ALTA',
  PEC_INTERMEDIARIA = 'PEC_INTERMEDIARIA',
  PEC_BAIXA = 'PEC_BAIXA',
  PEC_NAO_AGUDA = 'PEC_NAO_AGUDA',
}

export enum ClassificacaoRiscoEncaminhamentoEnum {
  EMERGENCIA = 'EMERGENCIA',
  URGENCIA = 'URGENCIA',
  PRIORITARIO = 'PRIORITARIO',
  ELETIVO = 'ELETIVO',
}

export type CompartilhamentoCuidadoPlanoDto = {
  __typename?: 'CompartilhamentoCuidadoPlanoDto'
  id: Scalars['ID']
  lotacaoSolicitante: Lotacao
  lotacaoExecutanteAtual?: Maybe<Lotacao>
  cbo: Cbo
  unidadeSaude?: Maybe<UnidadeSaude>
  dataInicio: Scalars['Instant']
  cid10?: Maybe<Cid10>
  ciap?: Maybe<Ciap>
  classificacaoPrioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
  discussao: Scalars['String']
  cidadaoAceitaAtendTic?: Maybe<Scalars['Boolean']>
  isEncaminhamento: Scalars['Boolean']
}

export type CompartilhamentoCuidadoPlanoInput = {
  lotacaoExecutanteId?: Maybe<Scalars['ID']>
  cboId: Scalars['ID']
  unidadeSaudeId?: Maybe<Scalars['ID']>
  cid10?: Maybe<Scalars['ID']>
  ciap?: Maybe<Scalars['ID']>
  classificacaoPrioridade: ClassificacaoPrioridadeCuidadoEnum
  discussao: Scalars['String']
  cidadaoAceitaAtendTic?: Maybe<Scalars['Boolean']>
  isEncaminhamento: Scalars['Boolean']
}

export type CompartilhamentosCuidadoPlanoQueryInput = {
  prontuarioId: Scalars['ID']
  profissionalExecutanteId?: Maybe<Scalars['Long']>
  profissionalSolicitanteId?: Maybe<Scalars['Long']>
  unidadeSaudeId?: Maybe<Scalars['Long']>
  cboId?: Maybe<Scalars['Long']>
  dataInicio?: Maybe<Scalars['LocalDate']>
  dataFim?: Maybe<Scalars['LocalDate']>
  tipoPrioridades?: Maybe<Array<ClassificacaoPrioridadeCuidadoEnum>>
  isEncaminhamento?: Maybe<Scalars['Boolean']>
}

export type CompartilharVideochamadaInput = {
  videochamadaUuid: Scalars['String']
  emailDestinatario: Scalars['String']
}

export type Complexidade = {
  __typename?: 'Complexidade'
  id: Scalars['ID']
  nome: Scalars['String']
  sigla: Scalars['String']
}

export type CondicaoAutorreferida = {
  __typename?: 'CondicaoAutorreferida'
  descricao: Scalars['String']
}

export type CondicoesInput = {
  isGestante?: Maybe<Scalars['Boolean']>
  isPuerpera?: Maybe<Scalars['Boolean']>
  isViajante?: Maybe<Scalars['Boolean']>
  isComunicanteHanseniase?: Maybe<Scalars['Boolean']>
}

export type CondicoesMoradia = {
  __typename?: 'CondicoesMoradia'
  id: Scalars['ID']
  tipoAbastecimentoAgua?: Maybe<TipoAbastecimentoAguaEnum>
  tipoSituacaoMoradia?: Maybe<TipoSituacaoMoradiaEnum>
  tipoAcessoDomicilio?: Maybe<TipoAcessoDomicilioEnum>
  tipoDestinoLixo?: Maybe<TipoDestinoLixoEnum>
  tipoDomicilio?: Maybe<TipoDomicilioEnum>
  tipoEscoamentoSanitario?: Maybe<TipoEscoamentoSanitarioEnum>
  tipoLocalizacao?: Maybe<TipoLocalizacaoEnum>
  tipoMaterialParede?: Maybe<TipoMaterialParedeEnum>
  tipoPosseTerra?: Maybe<TipoPosseTerraEnum>
  tipoTratamentoAgua?: Maybe<TipoTratamentoAguaEnum>
  isEnergiaEletricaDisponivel?: Maybe<Scalars['Boolean']>
  tipoOrigemEnergiaEletrica?: Maybe<TipoOrigemEnergiaEletricaEnum>
  numeroComodos?: Maybe<Scalars['Int']>
}

export type CondicoesMoradiaInput = {
  tipoAbastecimentoAgua?: Maybe<TipoAbastecimentoAguaEnum>
  tipoSituacaoMoradia?: Maybe<TipoSituacaoMoradiaEnum>
  tipoAcessoDomicilio?: Maybe<TipoAcessoDomicilioEnum>
  tipoDestinoLixo?: Maybe<TipoDestinoLixoEnum>
  tipoDomicilio?: Maybe<TipoDomicilioEnum>
  tipoEscoamentoSanitario?: Maybe<TipoEscoamentoSanitarioEnum>
  tipoLocalizacao?: Maybe<TipoLocalizacaoEnum>
  tipoMaterialParede?: Maybe<TipoMaterialParedeEnum>
  tipoPosseTerra?: Maybe<TipoPosseTerraEnum>
  tipoTratamentoAgua?: Maybe<TipoTratamentoAguaEnum>
  isEnergiaEletricaDisponivel?: Maybe<Scalars['Boolean']>
  tipoOrigemEnergiaEletrica?: Maybe<TipoOrigemEnergiaEletricaEnum>
  numeroComodos?: Maybe<Scalars['Int']>
}

export type CondicoesVacinacao = {
  __typename?: 'CondicoesVacinacao'
  idAtend: Scalars['ID']
  isViajante?: Maybe<Scalars['Boolean']>
  isGestante?: Maybe<Scalars['Boolean']>
  isPuerpera?: Maybe<Scalars['Boolean']>
  isComunicanteHanseniase?: Maybe<Scalars['Boolean']>
}

export enum CondutaCuidadoCompartilhadoEnum {
  NOVA_PERGUNTA = 'NOVA_PERGUNTA',
  PEDIDO_ESCLARECIMENTO = 'PEDIDO_ESCLARECIMENTO',
  TELECONSULTORIA = 'TELECONSULTORIA',
  PARECER_TECNICO_CONCLUSIVO = 'PARECER_TECNICO_CONCLUSIVO',
  AGENDADO_INTERCONSULTA_SEM_CIDADAO = 'AGENDADO_INTERCONSULTA_SEM_CIDADAO',
  AGENDADO_CONSULTA_CIDADAO_PRESENCIAL = 'AGENDADO_CONSULTA_CIDADAO_PRESENCIAL',
  TROCA_PROFISSIONAL_REFERENCIA = 'TROCA_PROFISSIONAL_REFERENCIA',
  RECONDUCAO_CUIDADO = 'RECONDUCAO_CUIDADO',
  AGENDADO_TELEINTERCONSULTA_SEM_CIDADAO = 'AGENDADO_TELEINTERCONSULTA_SEM_CIDADAO',
  AGENDADO_INTERCONSULTA_COM_CIDADAO = 'AGENDADO_INTERCONSULTA_COM_CIDADAO',
  AGENDADO_TELEINTERCONSULTA_COM_CIDADAO = 'AGENDADO_TELEINTERCONSULTA_COM_CIDADAO',
  SUGESTAO_AGENDAMENTO_GRUPO = 'SUGESTAO_AGENDAMENTO_GRUPO',
  ENVIAR_GARANTIA_ACESSO = 'ENVIAR_GARANTIA_ACESSO',
}

export enum CondutaEnum {
  AGENDAMENTO_PARA_GRUPOS = 'AGENDAMENTO_PARA_GRUPOS',
  AGENDAMENTO_PARA_NASF = 'AGENDAMENTO_PARA_NASF',
  AGENDAMENTO_PARA_EMULTI = 'AGENDAMENTO_PARA_EMULTI',
  ALTA_DO_EPISODIO = 'ALTA_DO_EPISODIO',
  RETORNO_PARA_CONSULTA_AGENDADA = 'RETORNO_PARA_CONSULTA_AGENDADA',
  RETORNO_PARA_CUIDADO_CONTINUADO_PROGRAMADO = 'RETORNO_PARA_CUIDADO_CONTINUADO_PROGRAMADO',
  MANTER_EM_OBSERVACAO = 'MANTER_EM_OBSERVACAO',
}

export type ConfigAtendimentoDomInput = {
  idEquipePai: Scalars['ID']
  idEquipeFilho: Scalars['ID']
}

export type ConfiguracaoAgendaLotacao = {
  __typename?: 'ConfiguracaoAgendaLotacao'
  configuracaoHorarioAgenda: ConfiguracaoHorarioAgenda
  duracaoAgendamento?: Maybe<Scalars['Int']>
}

export type ConfiguracaoAgendaLotacaoDuracaoAgendamentoArgs = {
  isAtencaoDomiciliar?: Maybe<Scalars['Boolean']>
}

export type ConfiguracaoAgendaLotacaoInput = {
  lotacaoId: Scalars['ID']
  configuracaoHorarioAgenda: ConfiguracaoHorarioAgendaInput
}

export type ConfiguracaoAgendaMunicipal = {
  __typename?: 'ConfiguracaoAgendaMunicipal'
  id: Scalars['ID']
  configuracaoHorarioAgenda: ConfiguracaoHorarioAgenda
}

export type ConfiguracaoAgendaMunicipalInput = {
  id: Scalars['ID']
  configuracaoHorarioAgendaInput: ConfiguracaoHorarioAgendaInput
}

export type ConfiguracaoAgendaOnlineLotacao = {
  __typename?: 'ConfiguracaoAgendaOnlineLotacao'
  horariosConsolidados: Array<Maybe<Scalars['String']>>
  diasLiberacaoReserva: Scalars['Long']
  configuracoesSemana?: Maybe<Array<ConfiguracaoSemanaAgendaOnline>>
}

export type ConfiguracaoAgendaOnlineLotacaoInput = {
  lotacaoId: Scalars['ID']
  configuracoesSemana?: Maybe<Array<ConfiguracaoSemanaAgendaOnlineInput>>
}

export type ConfiguracaoAvancada = {
  __typename?: 'ConfiguracaoAvancada'
  performance: ConfiguracaoPerformance
}

export type ConfiguracaoBuscaCidadaoPorPropriedades = {
  __typename?: 'ConfiguracaoBuscaCidadaoPorPropriedades'
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
}

export type ConfiguracaoCadsus = {
  __typename?: 'ConfiguracaoCadsus'
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type ConfiguracaoCertificado = {
  __typename?: 'ConfiguracaoCertificado'
  id: Scalars['ID']
  municipio: Municipio
  admMunicipal: AdministradorMunicipal
  nomeCertificado: Scalars['String']
  senhaCertificado: Scalars['String']
  idSolicitante: Scalars['String']
  dataInclusao: Scalars['Instant']
  dataHabilitacao: Scalars['Instant']
  dataValidadeCertificado: Scalars['Instant']
  rndsHabilitado: Scalars['Boolean']
  cadsusHabilitado: Scalars['Boolean']
  error?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ConfiguracaoCertificadoInput = {
  servicos: Array<Maybe<ServicosCertificadoEnum>>
  pathCertificado: Scalars['String']
  senhaCertificado: Scalars['String']
  idSolicitante?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
}

export type ConfiguracaoConexao = {
  __typename?: 'ConfiguracaoConexao'
  internet: ConfiguracaoInternet
  cadsus: ConfiguracaoCadsus
  horus: ConfiguracaoHorus
  prescricaoDigital: ConfiguracaoPrescricaoDigital
  videochamadas: ConfiguracaoVideochamadas
  agendaOnline?: Maybe<AgendaOnline>
  hasLotacoesConfiguradasOnline?: Maybe<Scalars['Boolean']>
  lotacoesAgendaOnlineComDuracaoPadrao?: Maybe<Scalars['Int']>
}

export type ConfiguracaoDadosServidorFormDto = {
  __typename?: 'ConfiguracaoDadosServidorFormDto'
  nomeInstalacao?: Maybe<Scalars['String']>
  linkInstalacao?: Maybe<Scalars['String']>
}

export type ConfiguracaoDadosServidorInput = {
  nomeInstalacao?: Maybe<Scalars['String']>
  linkInstalacao?: Maybe<Scalars['String']>
}

export type ConfiguracaoDiaSemana = {
  __typename?: 'ConfiguracaoDiaSemana'
  diaSemana: DiaSemanaEnum
  periodos?: Maybe<Array<ConfiguracaoPeriodo>>
}

export type ConfiguracaoDiaSemanaInput = {
  diaSemana: DiaSemanaEnum
  periodos?: Maybe<Array<ConfiguracaoPeriodoInput>>
}

export type ConfiguracaoHorarioAgenda = {
  __typename?: 'ConfiguracaoHorarioAgenda'
  configuracaoDiasSemana?: Maybe<Array<ConfiguracaoDiaSemana>>
}

export type ConfiguracaoHorarioAgendaInput = {
  configuracaoDiasSemana?: Maybe<Array<ConfiguracaoDiaSemanaInput>>
}

export type ConfiguracaoHorarioAgendaOnline = {
  __typename?: 'ConfiguracaoHorarioAgendaOnline'
  horario: Scalars['String']
  ativo: Scalars['Boolean']
}

export type ConfiguracaoHorarioAgendaOnlineInput = {
  horario: Scalars['String']
  ativo: Scalars['Boolean']
}

export type ConfiguracaoHorus = {
  __typename?: 'ConfiguracaoHorus'
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type ConfiguracaoInternet = {
  __typename?: 'ConfiguracaoInternet'
  habilitado: Scalars['Boolean']
  dataUltimoTeste?: Maybe<Scalars['Instant']>
  resultadoUltimoTeste?: Maybe<Scalars['Boolean']>
}

export type ConfiguracaoPerformance = {
  __typename?: 'ConfiguracaoPerformance'
  qtdRequisicoesSimultaneas?: Maybe<Scalars['Int']>
  buscaCidadaoPorPropriedades: ConfiguracaoBuscaCidadaoPorPropriedades
}

export type ConfiguracaoPeriodo = {
  __typename?: 'ConfiguracaoPeriodo'
  periodo: PeriodoEnum
  horarioInicial?: Maybe<Scalars['String']>
  horarioFinal?: Maybe<Scalars['String']>
}

export type ConfiguracaoPeriodoInatividade = {
  __typename?: 'ConfiguracaoPeriodoInatividade'
  periodoInatividade?: Maybe<Scalars['Int']>
}

export type ConfiguracaoPeriodoInput = {
  periodo: PeriodoEnum
  horarioInicial?: Maybe<Scalars['String']>
  horarioFinal?: Maybe<Scalars['String']>
}

export type ConfiguracaoPrescricaoDigital = {
  __typename?: 'ConfiguracaoPrescricaoDigital'
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  usuario?: Maybe<Scalars['String']>
  senha?: Maybe<Scalars['String']>
}

export type ConfiguracaoRedefinicaoSenha = {
  __typename?: 'ConfiguracaoRedefinicaoSenha'
  redefinicaoSenha?: Maybe<Scalars['Int']>
}

export type ConfiguracaoSeguranca = {
  __typename?: 'ConfiguracaoSeguranca'
  periodoEmMesesParaRedefinir: ConfiguracaoRedefinicaoSenha
  periodoEmMinutosInatividade: ConfiguracaoPeriodoInatividade
  loginNumeroTentativas: ConfiguracaoTentativasLogin
}

export type ConfiguracaoSemanaAgendaOnline = {
  __typename?: 'ConfiguracaoSemanaAgendaOnline'
  diaSemana: DiaSemanaEnum
  configuracoes: Array<Maybe<ConfiguracaoHorarioAgendaOnline>>
}

export type ConfiguracaoSemanaAgendaOnlineInput = {
  diaSemana: DiaSemanaEnum
  configuracoes: Array<Maybe<ConfiguracaoHorarioAgendaOnlineInput>>
}

export type ConfiguracaoServidoresFormDto = {
  __typename?: 'ConfiguracaoServidoresFormDto'
  internet: ConfiguracaoInternet
  dadosServidor?: Maybe<ConfiguracaoDadosServidorFormDto>
  servidorSMTP?: Maybe<ServidorSmtp>
}

export type ConfiguracaoSmtpInput = {
  email?: Maybe<Scalars['String']>
  senha?: Maybe<Scalars['String']>
  usuario?: Maybe<Scalars['String']>
  endereco?: Maybe<Scalars['String']>
  porta?: Maybe<Scalars['String']>
}

export type ConfiguracaoTentativasLogin = {
  __typename?: 'ConfiguracaoTentativasLogin'
  tentativasLogin?: Maybe<Scalars['Int']>
}

export type ConfiguracaoVideochamadas = {
  __typename?: 'ConfiguracaoVideochamadas'
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
}

export type ConfiguracoesAtencaoDom = {
  __typename?: 'ConfiguracoesAtencaoDom'
  duracao: Scalars['Int']
  emapsApoiamEmads: ConfiguracoesAtendimentosDomPayload
  emadsAtendemEmads: ConfiguracoesAtendimentosDomPayload
}

export type ConfiguracoesAtencaoDomEmapsApoiamEmadsArgs = {
  input?: Maybe<ConfiguracoesAtendimentosDomInput>
}

export type ConfiguracoesAtencaoDomEmadsAtendemEmadsArgs = {
  input?: Maybe<ConfiguracoesAtendimentosDomInput>
}

export type ConfiguracoesAtendimentosDomiciliares = {
  __typename?: 'ConfiguracoesAtendimentosDomiciliares'
  id: Scalars['ID']
  identificadorNacionalEquipePai: Equipe
  identificadorNacionalEquipeFilho: Equipe
}

export type ConfiguracoesAtendimentosDomInput = {
  pageParams?: Maybe<PageParams>
}

export type ConfiguracoesAtendimentosDomPayload = {
  __typename?: 'ConfiguracoesAtendimentosDomPayload'
  content: Array<Maybe<ConfiguracoesAtendimentosDomiciliares>>
  pageInfo: PageInfo
}

export type ConselhoClasse = {
  __typename?: 'ConselhoClasse'
  id: Scalars['ID']
  sigla: Scalars['String']
  descricao?: Maybe<Scalars['String']>
}

export type ConselhoClassePayload = {
  __typename?: 'ConselhoClassePayload'
  content: Array<Maybe<ConselhoClasse>>
  pageInfo: PageInfo
}

export type ConselhoClasseQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type ConsultaEstoqueHorusQueryInput = {
  principioAtivo: Scalars['String']
  codCatMat: Scalars['String']
  uuid: Scalars['String']
}

export type ContatoCidadao = {
  __typename?: 'ContatoCidadao'
  id: Scalars['ID']
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type CpfContraChaveInput = {
  cpf: Scalars['String']
  contraChave: Scalars['String']
}

export type CredencialRecebimentoExterno = {
  __typename?: 'CredencialRecebimentoExterno'
  id: Scalars['ID']
  nomeResponsavel: Scalars['String']
  nomeCredencial: Scalars['String']
  dataCriacao: Scalars['Instant']
  email: Scalars['String']
  ativo: Scalars['Boolean']
  tipoPessoa: TipoPessoaEnum
  cpfCnpj: Scalars['String']
}

export type CredencialRecebimentoExternoInput = {
  nomeResponsavel: Scalars['String']
  tipoPessoa: TipoPessoaEnum
  cpfCnpj: Scalars['String']
  email: Scalars['String']
  nomeCredencial: Scalars['String']
}

export type CredencialRecebimentoExternoPayload = {
  __typename?: 'CredencialRecebimentoExternoPayload'
  content: Array<Maybe<CredencialRecebimentoExterno>>
  pageInfo: PageInfo
}

export type CredencialRecebimentoExternoQueryInput = {
  query?: Maybe<Scalars['String']>
  somenteAtivas?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type CredencialRecebimentoExternoUserPasswordPayload = {
  __typename?: 'CredencialRecebimentoExternoUserPasswordPayload'
  user: Scalars['String']
  password: Scalars['String']
}

export enum CriticidadeAlergia {
  BAIXA = 'BAIXA',
  ALTA = 'ALTA',
  NAO_INFORMADA = 'NAO_INFORMADA',
}

export type CuidadoCompartilhado = {
  __typename?: 'CuidadoCompartilhado'
  id: Scalars['ID']
  atendimentoProfissional: AtendimentoProfissional
  prontuario: Prontuario
  cidadao: Cidadao
  lotacaoSolicitante: Lotacao
  lotacaoExecutanteAtual?: Maybe<Lotacao>
  cboExecutanteAtual: Cbo
  unidadeSaudeExecutanteAtual: UnidadeSaude
  iniciadoEm: Scalars['Instant']
  cid10?: Maybe<Cid10>
  ciap?: Maybe<Ciap>
  classificacaoPrioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
  discussao: Scalars['String']
  ultimaEvolucao: CuidadoCompartilhadoEvolucao
  status: StatusCuidadoCompartilhadoEnum
  dataUltimaEvolucao: Scalars['Instant']
  cidadaoAceitaAtendTic: Scalars['Boolean']
  actions?: Maybe<CuidadoCompartilhadoActions>
  primeiraEvolucao: CuidadoCompartilhadoEvolucao
}

export type CuidadoCompartilhadoActions = {
  __typename?: 'CuidadoCompartilhadoActions'
  discutirCasoSolicitante?: Maybe<Action>
  discutirCasoExecutante?: Maybe<Action>
  visualizarDiscussao?: Maybe<Action>
  tornarseExecutante?: Maybe<Action>
}

export type CuidadoCompartilhadoEvolucao = {
  __typename?: 'CuidadoCompartilhadoEvolucao'
  id: Scalars['ID']
  cuidadoCompartilhado: CuidadoCompartilhado
  conduta: CondutaCuidadoCompartilhadoEnum
  lotacao: Lotacao
  dataEvolucao: Scalars['Instant']
  descricao?: Maybe<Scalars['String']>
  reclassificacaoPrioridade?: Maybe<ClassificacaoPrioridadeCuidadoEnum>
  agenda?: Maybe<Agendado>
  lotacaoAfetada?: Maybe<Lotacao>
  lotacaoExecutante?: Maybe<Lotacao>
  cboExecutante: Cbo
  unidadeSaudeExecutante: UnidadeSaude
  classificacaoPrioridade: ClassificacaoPrioridadeCuidadoEnum
  enviarGarantiaAcesso?: Maybe<CondutaCuidadoCompartilhadoEnum>
}

export type CuidadoCompartilhadoEvolucoesPayload = {
  __typename?: 'CuidadoCompartilhadoEvolucoesPayload'
  content: Array<Maybe<CuidadoCompartilhadoEvolucao>>
  pageInfo: PageInfo
}

export type CuidadoCompartilhadoEvolucoesQueryInput = {
  cuidadoCompartilhadoId: Scalars['ID']
  pageParams?: Maybe<PageParams>
}

export type CuidadoCompartilhadoPlanoInput = {
  lotacaoExecutanteId: Scalars['ID']
  cid10?: Maybe<Scalars['ID']>
  ciap?: Maybe<Scalars['ID']>
  classificacaoPrioridade: ClassificacaoPrioridadeCuidadoEnum
  discussao: Scalars['String']
  cidadaoAceitaAtendTic: Scalars['Boolean']
}

export enum CuidadoCompartilhadoQueryFilterEnum {
  EXECUTANTE = 'EXECUTANTE',
  GERENTE = 'GERENTE',
  SOLICITANTE = 'SOLICITANTE',
}

export type CuidadosCompartilhadoPayload = {
  __typename?: 'CuidadosCompartilhadoPayload'
  content: Array<CuidadoCompartilhado>
  pageInfo: PageInfo
}

export type CuidadosCompartilhadoQueryInput = {
  query?: Maybe<Scalars['String']>
  prontuarioId?: Maybe<Scalars['ID']>
  somenteUnidadeExecutante: Scalars['Boolean']
  somenteSemExecutante: Scalars['Boolean']
  unidadeSaudeId?: Maybe<Scalars['ID']>
  solicitadosPelaMinhaEquipe: Scalars['Boolean']
  solicitadosPorProfissionalId?: Maybe<Scalars['ID']>
  executadosPelaMinhaEquipe: Scalars['Boolean']
  executadosPorProfissionalId?: Maybe<Scalars['ID']>
  equipesIds?: Maybe<Array<Scalars['String']>>
  dataInicio?: Maybe<Scalars['LocalDate']>
  dataFim?: Maybe<Scalars['LocalDate']>
  status?: Maybe<Array<Maybe<StatusCuidadoCompartilhadoEnum>>>
  pageParams?: Maybe<PageParams>
}

export type DataUltimaConsultaOdontoGestanteQueryInput = {
  cidadaoId: Scalars['ID']
  prontuarioId: Scalars['ID']
  dataInicioGestacao: Scalars['LocalDate']
}

export type DateRange = {
  __typename?: 'DateRange'
  startDate: Scalars['LocalDate']
  endDate: Scalars['LocalDate']
}

export type DateRangeDto = {
  startDate: Scalars['LocalDate']
  endDate: Scalars['LocalDate']
}

export type DateRangeInput = {
  startDate?: Maybe<Scalars['LocalDate']>
  endDate?: Maybe<Scalars['LocalDate']>
}

export type DefinirSenhaProvisoriaInput = {
  usuarioId: Scalars['Long']
  novaSenha: Scalars['String']
}

export type Dente = {
  __typename?: 'Dente'
  id: Scalars['ID']
  numero?: Maybe<Scalars['Int']>
}

export type DesfechoAtendimento = {
  __typename?: 'DesfechoAtendimento'
  texto?: Maybe<Scalars['String']>
}

export type DesfechoAtendimentoInput = {
  manterCidadaoLista: Scalars['Boolean']
  atendimento?: Maybe<EncaminhamentoInternoInput>
}

export type DetalhesAtivacao = {
  __typename?: 'DetalhesAtivacao'
  profissional?: Maybe<Profissional>
  dataEvento: Scalars['Instant']
}

export type DevolutivaCuidadoCompartilhadoInput = {
  conduta: CondutaCuidadoCompartilhadoEnum
  resposta: Scalars['String']
}

export type DiaAgendaLotacao = {
  __typename?: 'DiaAgendaLotacao'
  data: Scalars['LocalDate']
  status: StatusDiaAgendaEnum
}

export enum DiaSemanaEnum {
  SEGUNDA = 'SEGUNDA',
  TERCA = 'TERCA',
  QUARTA = 'QUARTA',
  QUINTA = 'QUINTA',
  SEXTA = 'SEXTA',
  SABADO = 'SABADO',
  DOMINGO = 'DOMINGO',
}

export type DimensaoAgrupadorFiltro = {
  __typename?: 'DimensaoAgrupadorFiltro'
  id: Scalars['ID']
  dimensaoMunicipio?: Maybe<DimensaoMunicipio>
  dimensaoEquipe?: Maybe<DimensaoEquipe>
  dimensaoUnidadeSaude?: Maybe<DimensaoUnidadeSaude>
  dimensaoProfissional?: Maybe<DimensaoProfissional>
  dimensaoCbo?: Maybe<DimensaoCbo>
}

export type DimensaoAgrupadorFiltroQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  dimensaoMunicipioId?: Maybe<Scalars['String']>
  dimensaoUnidadeSaudeId?: Maybe<Scalars['String']>
  dimensaoEquipeId?: Maybe<Scalars['String']>
}

export type DimensaoAgrupadorFiltroQueryPayload = {
  __typename?: 'DimensaoAgrupadorFiltroQueryPayload'
  content: Array<Maybe<DimensaoAgrupadorFiltro>>
  pageInfo: PageInfo
}

export type DimensaoAleitamento = {
  __typename?: 'DimensaoAleitamento'
  id: Scalars['ID']
  identificador: Scalars['String']
  descricao: Scalars['String']
  ordem: Scalars['Int']
}

export type DimensaoCategoriaProfissional = {
  __typename?: 'DimensaoCategoriaProfissional'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type DimensaoCategoriaProfissionalQueryInput = {
  query?: Maybe<Scalars['String']>
}

export type DimensaoCbo = {
  __typename?: 'DimensaoCbo'
  id: Scalars['ID']
  cbo: Scalars['String']
  nome: Scalars['String']
}

export type DimensaoCiap = {
  __typename?: 'DimensaoCiap'
  id: Scalars['ID']
  codigoCiap: Scalars['String']
  nomeCiap: Scalars['String']
}

export type DimensaoCid10 = {
  __typename?: 'DimensaoCid10'
  id: Scalars['ID']
  codigoCid10: Scalars['String']
  nomeCid10: Scalars['String']
}

export type DimensaoCondutaAd = {
  __typename?: 'DimensaoCondutaAD'
  id: Scalars['ID']
  identificador: Scalars['String']
  conduta: Scalars['String']
}

export type DimensaoCuidador = {
  __typename?: 'DimensaoCuidador'
  id: Scalars['ID']
  numeroIdentificador: Scalars['String']
  descricao: Scalars['String']
  ordem: Scalars['Int']
}

export type DimensaoDesfechoVisita = {
  __typename?: 'DimensaoDesfechoVisita'
  id: Scalars['ID']
  numeroIdentificador: Scalars['String']
  descricao: Scalars['String']
  ordem: Scalars['Int']
}

export type DimensaoDoseImunobiologico = {
  __typename?: 'DimensaoDoseImunobiologico'
  id: Scalars['ID']
  nome: Scalars['String']
  sigla: Scalars['String']
  nomeApresentacao: Scalars['String']
  ordem: Scalars['Int']
  numeroIdentificador: Scalars['String']
}

export type DimensaoEquipe = {
  __typename?: 'DimensaoEquipe'
  id: Scalars['ID']
  nome: Scalars['String']
  ine: Scalars['String']
}

export type DimensaoEquipeQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  ibge?: Maybe<Scalars['String']>
  cnes?: Maybe<Scalars['String']>
}

export type DimensaoEquipeQueryPayload = {
  __typename?: 'DimensaoEquipeQueryPayload'
  content: Array<Maybe<DimensaoEquipe>>
  pageInfo: PageInfo
}

export type DimensaoEstrategiaVacinacao = {
  __typename?: 'DimensaoEstrategiaVacinacao'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type DimensaoFaixaEtaria = {
  __typename?: 'DimensaoFaixaEtaria'
  id: Scalars['ID']
  descricao: Scalars['String']
}

export type DimensaoGrupoAtendimento = {
  __typename?: 'DimensaoGrupoAtendimento'
  id: Scalars['ID']
  nome: Scalars['String']
  numeroIdentificador: Scalars['String']
}

export type DimensaoImunobiologico = {
  __typename?: 'DimensaoImunobiologico'
  id: Scalars['ID']
  nome: Scalars['String']
  sigla: Scalars['String']
  identificador: Scalars['String']
}

export type DimensaoInep = {
  __typename?: 'DimensaoInep'
  id: Scalars['ID']
  nuIdentificador: Scalars['String']
  noEstabelecimento: Scalars['String']
}

export type DimensaoLocalAtendimento = {
  __typename?: 'DimensaoLocalAtendimento'
  id: Scalars['ID']
  identificador: Scalars['String']
  descricao: Scalars['String']
}

export type DimensaoModalidadeAd = {
  __typename?: 'DimensaoModalidadeAD'
  id: Scalars['ID']
  numeroIdentificador: Scalars['String']
  descricao: Scalars['String']
  ordem: Scalars['Int']
}

export type DimensaoMunicipio = {
  __typename?: 'DimensaoMunicipio'
  id: Scalars['ID']
  nome: Scalars['String']
  ibge: Scalars['String']
}

export type DimensaoMunicipiosQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type DimensaoMunicipiosQueryPayload = {
  __typename?: 'DimensaoMunicipiosQueryPayload'
  content: Array<Maybe<DimensaoMunicipio>>
  pageInfo: PageInfo
}

export type DimensaoProcedimento = {
  __typename?: 'DimensaoProcedimento'
  id: Scalars['ID']
  codigoProcedimento: Scalars['String']
  descricaoProcedimento: Scalars['String']
}

export type DimensaoProfissional = {
  __typename?: 'DimensaoProfissional'
  id: Scalars['ID']
  cns?: Maybe<Scalars['String']>
  nome?: Maybe<Scalars['String']>
}

export type DimensaoRacionalidadeSaude = {
  __typename?: 'DimensaoRacionalidadeSaude'
  id: Scalars['ID']
  identificador: Scalars['String']
  descricao: Scalars['String']
  ordem: Scalars['Int']
}

export type DimensaoSexo = {
  __typename?: 'DimensaoSexo'
  id: Scalars['ID']
  identificador: Scalars['Int']
  descricao: Scalars['String']
  sigla: Scalars['String']
}

export type DimensaoTempo = {
  __typename?: 'DimensaoTempo'
  id: Scalars['ID']
  dataRegistro: Scalars['LocalDate']
}

export type DimensaoTipoAtividade = {
  __typename?: 'DimensaoTipoAtividade'
  id: Scalars['ID']
  nuIdentificador: Scalars['Int']
  descricaoTipoAtividade: Scalars['String']
  idCategoriaTipoAtividade?: Maybe<Scalars['ID']>
  descricaoCategoriaTipoAtividade?: Maybe<Scalars['String']>
}

export type DimensaoTipoGlicemia = {
  __typename?: 'DimensaoTipoGlicemia'
  id: Scalars['ID']
  descricao: Scalars['String']
  descricaoFiltro: Scalars['String']
  ordem: Scalars['Int']
  numeroIdentificador: Scalars['String']
}

export type DimensaoTipoOrigem = {
  __typename?: 'DimensaoTipoOrigem'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type DimensaoTipoParticipacaoAtendimento = {
  __typename?: 'DimensaoTipoParticipacaoAtendimento'
  id: Scalars['ID']
  descricao: Scalars['String']
}

export type DimensaoTurno = {
  __typename?: 'DimensaoTurno'
  id: Scalars['ID']
  identificador: Scalars['String']
  descricao: Scalars['String']
  ordem: Scalars['Int']
}

export type DimensaoUnidadeSaude = {
  __typename?: 'DimensaoUnidadeSaude'
  id: Scalars['ID']
  nome: Scalars['String']
  cnes: Scalars['String']
}

export type DimensaoUnidadeSaudeQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  ibge?: Maybe<Scalars['String']>
}

export type DimensaoUnidadeSaudeQueryPayload = {
  __typename?: 'DimensaoUnidadeSaudeQueryPayload'
  content: Array<Maybe<DimensaoUnidadeSaude>>
  pageInfo: PageInfo
}

export enum DimFaixaEtariaEnum {
  MENOS_DE_UM_ANO = 'MENOS_DE_UM_ANO',
  UM_ANO = 'UM_ANO',
  DOIS_ANOS = 'DOIS_ANOS',
  TRES_ANOS = 'TRES_ANOS',
  QUATRO_ANOS = 'QUATRO_ANOS',
  CINCO_A_NOVE_ANOS = 'CINCO_A_NOVE_ANOS',
  DEZ_A_QUATORZE_ANOS = 'DEZ_A_QUATORZE_ANOS',
  QUINZE_A_DEZENOVE_ANOS = 'QUINZE_A_DEZENOVE_ANOS',
  VINTE_A_VINTE_QUATRO_ANOS = 'VINTE_A_VINTE_QUATRO_ANOS',
  VINTE_CINCO_A_VINTE_NOVE_ANOS = 'VINTE_CINCO_A_VINTE_NOVE_ANOS',
  TRINTA_A_TRINTA_QUATRO_ANOS = 'TRINTA_A_TRINTA_QUATRO_ANOS',
  TRINTA_CINCO_A_TRINTA_NOVE_ANOS = 'TRINTA_CINCO_A_TRINTA_NOVE_ANOS',
  QUARENTA_A_QUARENTA_QUATRO_ANOS = 'QUARENTA_A_QUARENTA_QUATRO_ANOS',
  QUARENTA_CINCO_A_QUARENTA_NOVE_ANOS = 'QUARENTA_CINCO_A_QUARENTA_NOVE_ANOS',
  CINQUENTA_A_CINQUENTA_QUATRO_ANOS = 'CINQUENTA_A_CINQUENTA_QUATRO_ANOS',
  CINQUENTA_CINCO_A_CINQUENTA_NOVE_ANOS = 'CINQUENTA_CINCO_A_CINQUENTA_NOVE_ANOS',
  SESSENTA_A_SESSENTA_QUATRO_ANOS = 'SESSENTA_A_SESSENTA_QUATRO_ANOS',
  SESSENTA_CINCO_A_SESSENTA_NOVE_ANOS = 'SESSENTA_CINCO_A_SESSENTA_NOVE_ANOS',
  SETENTA_A_SETENTA_QUATRO_ANOS = 'SETENTA_A_SETENTA_QUATRO_ANOS',
  SETENTA_CINCO_A_SETENTA_NOVE_ANOS = 'SETENTA_CINCO_A_SETENTA_NOVE_ANOS',
  OITENTA_ANOS_OU_MAIS = 'OITENTA_ANOS_OU_MAIS',
  NAO_INFORMADO = 'NAO_INFORMADO',
}

export enum DimSexoEnum {
  MASCULINO = 'MASCULINO',
  FEMININO = 'FEMININO',
  NAO_INFORMADO = 'NAO_INFORMADO',
}

export type DisponibilidadeDadosClinicosHistorico = {
  __typename?: 'DisponibilidadeDadosClinicosHistorico'
  statusProcessamento: Scalars['Boolean']
  isVersaoMinimaOracle: Scalars['Boolean']
}

export enum DisponibilidadeHorusEnum {
  DISPONIVEL = 'DISPONIVEL',
  PARCIALMENTE_DISPONIVEL = 'PARCIALMENTE_DISPONIVEL',
  INDISPONIVEL = 'INDISPONIVEL',
  HABILITADO = 'HABILITADO',
  DESABILITADO = 'DESABILITADO',
  DESABILITADO_MANUAL = 'DESABILITADO_MANUAL',
  SEM_CONEXAO = 'SEM_CONEXAO',
  SUCESSO_CONEXAO = 'SUCESSO_CONEXAO',
}

export type DoseImunobiologico = {
  __typename?: 'DoseImunobiologico'
  id: Scalars['ID']
  sigla: Scalars['String']
  nome: Scalars['String']
  nomeApresentacaoDose: Scalars['String']
  ordem: Scalars['Int']
}

export type DoseImunobiologicoQueryPayload = {
  __typename?: 'DoseImunobiologicoQueryPayload'
  content: Array<Maybe<DoseImunobiologico>>
  pageInfo: PageInfo
}

export type DoseImunobiologicoVacinacaoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  estrategiaId?: Maybe<Scalars['ID']>
  imunobiologicoIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  semRegras?: Maybe<Scalars['Boolean']>
  sexo?: Maybe<SexoEnum>
  isOnlyRevacinacao?: Maybe<Scalars['Boolean']>
  onlyCalendarioVacinal?: Maybe<Scalars['Boolean']>
  grupoAlvoId?: Maybe<Scalars['ID']>
}

export type Dsei = {
  __typename?: 'Dsei'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type DseiInput = {
  query?: Maybe<Scalars['String']>
  poloBaseId?: Maybe<Scalars['ID']>
  aldeiaId?: Maybe<Scalars['ID']>
  pageParams?: Maybe<PageParams>
}

export type DseiQueryPayload = {
  __typename?: 'DseiQueryPayload'
  content: Array<Maybe<Dsei>>
  pageInfo: PageInfo
}

export type Dum = {
  __typename?: 'Dum'
  dum?: Maybe<Scalars['LocalDate']>
  registradoEm?: Maybe<Scalars['LocalDate']>
}

export type DuracaoAgendamentoAtencaoDomDto = {
  __typename?: 'DuracaoAgendamentoAtencaoDomDto'
  duracaoAgendamento: Scalars['Int']
}

export type DuracaoAgendamentoAtencaoDomInput = {
  duracaoAgendamento: Scalars['Int']
}

export type DuracaoAtendimentoPadraoMunicipio = {
  __typename?: 'DuracaoAtendimentoPadraoMunicipio'
  duracaoAtendimentoPadraoMunicipio: Scalars['Int']
}

export type DuracaoAtendimentoPadraoMunicipioInput = {
  duracaoAtendimentoPadraoMunicipio: Scalars['Int']
}

export type EditarAgendamentoReservaInput = {
  agendamentoId: Scalars['ID']
  horarioInicial: Scalars['Instant']
  horarioFinal: Scalars['Instant']
  motivoReserva: MotivoAgendamentoReservaEnum
  especificacaoMotivoReserva?: Maybe<Scalars['String']>
  observacoes?: Maybe<Scalars['String']>
}

export type EditarModeloRelatorioGerencialInput = {
  id: Scalars['ID']
  nome?: Maybe<Scalars['String']>
  tipoModulo: TipoModuloEnum
  modeloPersonalizado: ModeloPersonalizadoRelatorioGerencialInput
}

export type EncaminhamentoExterno = {
  __typename?: 'EncaminhamentoExterno'
  atendimentoProfissional: AtendimentoProfissional
  tipo: TipoEncaminhamentoExternoEnum
}

export type EncaminhamentoExternoEspecializado = {
  __typename?: 'EncaminhamentoExternoEspecializado'
  id: Scalars['ID']
  atendimentoProfissional: AtendimentoProfissional
  observacoes?: Maybe<Scalars['String']>
  especialidadeSisreg: EspecialidadeSisreg
  motivoEncaminhamento?: Maybe<Scalars['String']>
  hipoteseDiagnosticoCid10?: Maybe<Cid10>
  hipoteseDiagnosticoCiap?: Maybe<Ciap>
  complemento?: Maybe<Scalars['String']>
  classificacaoRiscoEncaminhamento: ClassificacaoRiscoEncaminhamentoEnum
}

export type EncaminhamentoExternoEspecializadoQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  somenteMeus?: Maybe<Scalars['Boolean']>
  especialidadeId?: Maybe<Scalars['ID']>
  periodo?: Maybe<Period>
  pageParams?: Maybe<PageParams>
}

export type EncaminhamentoExternoEspecializadoQueryPayload = {
  __typename?: 'EncaminhamentoExternoEspecializadoQueryPayload'
  content: Array<Maybe<EncaminhamentoExternoEspecializado>>
  pageInfo: PageInfo
}

export type EncaminhamentoExternoInput = {
  id?: Maybe<Scalars['ID']>
  observacoes?: Maybe<Scalars['String']>
  especialidadeSisreg: Scalars['ID']
  motivoEncaminhamento?: Maybe<Scalars['String']>
  hipoteseDiagnosticoCid10?: Maybe<Scalars['ID']>
  hipoteseDiagnosticoCiap?: Maybe<Scalars['ID']>
  complemento?: Maybe<Scalars['String']>
  classificacaoRiscoEncaminhamento: ClassificacaoRiscoEncaminhamentoEnum
}

export type EncaminhamentoExternoQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  somenteMeus?: Maybe<Scalars['Boolean']>
  tipoEncaminhamento?: Maybe<TipoEncaminhamentoExternoEnum>
  periodo?: Maybe<Period>
}

export type EncaminhamentoInternoInput = {
  agendado?: Maybe<Scalars['ID']>
  equipe?: Maybe<Scalars['ID']>
  responsavel?: Maybe<Scalars['ID']>
  tiposServico?: Maybe<Array<Scalars['ID']>>
}

export type EncerrarGestacaoInput = {
  tipoGravidez?: Maybe<TipoGravidezEnum>
  dataDesfecho: Scalars['LocalDate']
}

export type Endereco = {
  __typename?: 'Endereco'
  bairro?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  complemento?: Maybe<Scalars['String']>
  logradouro?: Maybe<Scalars['String']>
  municipio?: Maybe<Municipio>
  numero?: Maybe<Scalars['String']>
  pontoReferencia?: Maybe<Scalars['String']>
  semNumero?: Maybe<Scalars['Boolean']>
  tipoLogradouro?: Maybe<TipoLogradouro>
  uf?: Maybe<Uf>
}

export type EnderecoIndigena = {
  __typename?: 'EnderecoIndigena'
  dseiResidencia?: Maybe<Dsei>
  poloBaseResidencia?: Maybe<PoloBase>
  aldeiaResidencia?: Maybe<Aldeia>
  municipio?: Maybe<Municipio>
  uf?: Maybe<Uf>
  numero?: Maybe<Scalars['String']>
}

export type EnderecoIndigenaInput = {
  dseiResidenciaId: Scalars['ID']
  poloBaseResidenciaId: Scalars['ID']
  aldeiaResidenciaId: Scalars['ID']
  municipioId: Scalars['ID']
  ufId: Scalars['ID']
  numero: Scalars['String']
}

export type EnderecoIndigenaTerritorioInput = {
  dseiId: Scalars['Long']
  poloBaseId: Scalars['Long']
  aldeiaId: Scalars['Long']
  municipioId: Scalars['Long']
  ufId: Scalars['Long']
  numero?: Maybe<Scalars['String']>
}

export type EnderecoInput = {
  bairro?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  complemento?: Maybe<Scalars['String']>
  logradouro?: Maybe<Scalars['String']>
  municipio?: Maybe<Scalars['String']>
  numero?: Maybe<Scalars['String']>
  pontoReferencia?: Maybe<Scalars['String']>
  semNumero?: Maybe<Scalars['Boolean']>
  tipoLogradouro?: Maybe<Scalars['String']>
  uf?: Maybe<Scalars['String']>
}

export type EnderecoQueryPayload = {
  __typename?: 'EnderecoQueryPayload'
  cep: Scalars['String']
  municipio?: Maybe<Municipio>
  uf?: Maybe<Uf>
  bairro?: Maybe<Bairro>
  logradouro?: Maybe<Scalars['String']>
  tipoLogradouro?: Maybe<TipoLogradouro>
}

export type EnderecoTerritorioInput = {
  bairro: Scalars['String']
  cep: Scalars['String']
  logradouro: Scalars['String']
  municipioId: Scalars['Long']
  tipoLogradouroId: Scalars['Long']
  ufId: Scalars['Long']
  numero?: Maybe<Scalars['String']>
  semNumero?: Maybe<Scalars['Boolean']>
  complemento?: Maybe<Scalars['String']>
  pontoReferencia?: Maybe<Scalars['String']>
}

export type EnviarGarantiaAcessoCuidadoCompartilhadoInput = {
  tipoAgendamento: CondutaCuidadoCompartilhadoEnum
  motivo: Scalars['String']
}

export type Equipe = {
  __typename?: 'Equipe'
  id: Scalars['ID']
  ine: Scalars['String']
  unidadeSaude: UnidadeSaude
  ativo?: Maybe<Scalars['Boolean']>
  tipoEquipe: TipoEquipe
  area?: Maybe<Scalars['String']>
  nome: Scalars['String']
  emapsApoio: Array<Equipe>
  equipesVinculadas: Array<Equipe>
  isAD: Scalars['Boolean']
}

export type EquipeAdInput = {
  query?: Maybe<Scalars['String']>
  descricaoTipoEquipe: Scalars['String']
  pageParams?: Maybe<PageParams>
}

export type EquipeAdPayload = {
  __typename?: 'EquipeADPayload'
  content: Array<Maybe<Equipe>>
  pageInfo: PageInfo
}

export type EquipePayload = {
  __typename?: 'EquipePayload'
  content: Array<Maybe<Equipe>>
  pageInfo: PageInfo
}

export type EquipesAdQueryInput = {
  localidadeId?: Maybe<Scalars['ID']>
  equipeId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  mostrarEquipesRelacionadas?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type EquipesListaCuidadoCompartilhadoQueryInput = {
  query?: Maybe<Scalars['String']>
}

export type EquipesQueryInput = {
  localidadeId?: Maybe<Scalars['ID']>
  unidadeSaudeId?: Maybe<Scalars['ID']>
  equipePrincipalId?: Maybe<Scalars['ID']>
  equipeId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  tiposEquipe?: Maybe<Array<Maybe<Scalars['String']>>>
  mostrarInativas?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type Escolaridade = {
  __typename?: 'Escolaridade'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type EscutaInicialAprovacaoInput = {
  escutaInicialInput?: Maybe<EscutaInicialInput>
  rascunho?: Maybe<Scalars['String']>
}

export type EscutaInicialInput = {
  id: Scalars['ID']
  ciapId: Scalars['ID']
  motivoConsulta?: Maybe<Scalars['String']>
  medicoes?: Maybe<MedicoesInput>
  desfecho?: Maybe<DesfechoAtendimentoInput>
  procedimentos?: Maybe<Array<Maybe<ProcedimentoAtendimentoInput>>>
  procedimentosAdministrativos?: Maybe<Array<Maybe<ProcedimentoAtendimentoInput>>>
  classificacaoRiscoEnum?: Maybe<ClassificacaoRisco>
  agendamentoConsulta?: Maybe<AgendamentoConsultaAtendimentoInput>
  imprimirEscuta?: Maybe<Scalars['Boolean']>
}

export type EscutaOrPreAtendimentoOfAtendimentoInput = {
  atendimentoId: Scalars['ID']
  dataAtendimentoProfissional: Scalars['LocalDate']
}

export type EspecialidadeSisreg = {
  __typename?: 'EspecialidadeSisreg'
  id: Scalars['ID']
  descricao: Scalars['String']
}

export type EspecialidadeSisregQueryInput = {
  query?: Maybe<Scalars['String']>
  isAtendimentoOdonto?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type EspecialidadeSisregQueryPayload = {
  __typename?: 'EspecialidadeSisregQueryPayload'
  content: Array<Maybe<EspecialidadeSisreg>>
  pageInfo: PageInfo
}

export type EsquemaCalendarioVacinalQueryInput = {
  sexo: SexoEnum
  grupoAlvo: GrupoAlvoVacinacaoEnum
  gestante: Scalars['Boolean']
  dataNascimento: Scalars['LocalDate']
  dataAtendimento: Scalars['Instant']
}

export enum EstabelecimentoSaudeEnum {
  CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_I = 'CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_I',
  CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_II = 'CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_II',
  CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_III = 'CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_III',
  PRONTO_ATENDIMENTO_GERAL = 'PRONTO_ATENDIMENTO_GERAL',
  PRONTO_ATENDIMENTO_ESPECIALIZADO = 'PRONTO_ATENDIMENTO_ESPECIALIZADO',
  UPA = 'UPA',
  POLICLINICA = 'POLICLINICA',
  CENTRO_DE_ATENCAO_PSICOSSOCIAL = 'CENTRO_DE_ATENCAO_PSICOSSOCIAL',
  TELESSAUDE = 'TELESSAUDE',
  UNIDADE_DE_ATENCAO_EM_REGIME_RESIDENCIAL = 'UNIDADE_DE_ATENCAO_EM_REGIME_RESIDENCIAL',
  HOSPITAL_GERAL = 'HOSPITAL_GERAL',
  MEDIA_COMPLEXIDADE = 'MEDIA_COMPLEXIDADE',
  UNIDADE_DE_APOIO_DIAGNOSE_E_TERAPIA_SADT_ISOLADO = 'UNIDADE_DE_APOIO_DIAGNOSE_E_TERAPIA_SADT_ISOLADO',
  FARMACIA = 'FARMACIA',
  CENTRO_DE_PARTO_NORMAL_ISOLADO = 'CENTRO_DE_PARTO_NORMAL_ISOLADO',
  HOSPITAL_DIA_ISOLADO = 'HOSPITAL_DIA_ISOLADO',
  CENTRAL_DE_REGULACAO_DE_SERVICOS_DE_SAUDE = 'CENTRAL_DE_REGULACAO_DE_SERVICOS_DE_SAUDE',
  CENTRAL_DE_GESTAO_EM_SAUDE = 'CENTRAL_DE_GESTAO_EM_SAUDE',
  PRONTO_ATENDIMENTO = 'PRONTO_ATENDIMENTO',
  CENTRAL_DE_REGULACAO_MEDICA_DAS_URGENCIAS = 'CENTRAL_DE_REGULACAO_MEDICA_DAS_URGENCIAS',
  SERVICO_DE_ATENCAO_DOMICILIAR_ISOLADO_HOME_CARE = 'SERVICO_DE_ATENCAO_DOMICILIAR_ISOLADO_HOME_CARE',
  CENTRAL_DE_REGULACAO_DO_ACESSO = 'CENTRAL_DE_REGULACAO_DO_ACESSO',
  CENTRAL_DE_ABASTECIMENTO = 'CENTRAL_DE_ABASTECIMENTO',
}

export type EstabelecimentosCuidadoCompartilhadoByCboIdQueryInput = {
  cboId: Scalars['ID']
  isVinculado: Scalars['Boolean']
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type EstabelecimentosListaCuidadoCompartilhadoQueryInput = {
  role: CuidadoCompartilhadoQueryFilterEnum
  query?: Maybe<Scalars['String']>
}

export type EstadoCivil = {
  __typename?: 'EstadoCivil'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type Estagio = Acesso & {
  __typename?: 'Estagio'
  id: Scalars['ID']
  profissional: Profissional
  lotacaoSupervisora: Lotacao
  perfis: Array<Perfil>
  tipo: TipoAcesso
  ativo: Scalars['Boolean']
  dataInicioVigencia: Scalars['LocalDate']
  dataFimVigencia: Scalars['LocalDate']
  municipio: Municipio
  unidadeSaude: UnidadeSaude
  equipe?: Maybe<Equipe>
  cbo: Cbo
  nivelTecnico: Scalars['Boolean']
  instituicaoEnsino?: Maybe<Scalars['String']>
  moduloInicial?: Maybe<Scalars['String']>
  actions: AcessoActions
}

export type EstagioCreateInput = {
  unidadeSaudeId: Scalars['ID']
  equipeId?: Maybe<Scalars['ID']>
  profissionalId: Scalars['ID']
  lotacaoSupervisoraId: Scalars['ID']
  cboId: Scalars['ID']
  dataInicioVigencia: Scalars['LocalDate']
  dataFimVigencia: Scalars['LocalDate']
  perfisId: Array<Scalars['ID']>
  instituicaoEnsino?: Maybe<Scalars['String']>
  nivelTecnico: Scalars['Boolean']
  ativo?: Maybe<Scalars['Boolean']>
}

export type EstagiosQueryInput = {
  filterByNomeOrCns?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  profissionalId?: Maybe<Scalars['Long']>
  pageParams?: Maybe<PageParams>
}

export type EstagiosQueryPayload = {
  __typename?: 'EstagiosQueryPayload'
  content: Array<Estagio>
  pageInfo: PageInfo
}

export type EstagioUpdateInput = {
  id: Scalars['ID']
  dataInicioVigencia: Scalars['LocalDate']
  dataFimVigencia: Scalars['LocalDate']
  perfisId: Array<Scalars['ID']>
  instituicaoEnsino?: Maybe<Scalars['String']>
}

export type EstrategiasVacinacaoQueryInput = {
  imunobiologicoId?: Maybe<Scalars['ID']>
  doseId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  sexo?: Maybe<SexoEnum>
  outrosImunobiologicos?: Maybe<Scalars['Boolean']>
}

export type EstrategiaVacinacao = {
  __typename?: 'EstrategiaVacinacao'
  id: Scalars['ID']
  codigo: Scalars['String']
  nome: Scalars['String']
  nomeFiltro: Scalars['String']
  estrategiaVacinacaoDbEnum: EstrategiaVacinacaoEnum
}

export enum EstrategiaVacinacaoEnum {
  ROTINA = 'ROTINA',
  ESPECIAL = 'ESPECIAL',
  BLOQUEIO = 'BLOQUEIO',
  INTENSIFICACAO = 'INTENSIFICACAO',
  CAMPANHA_INDISCRIMINADA = 'CAMPANHA_INDISCRIMINADA',
  CAMPANHA_SELETIVA = 'CAMPANHA_SELETIVA',
  SOROTERAPIA = 'SOROTERAPIA',
  SERVICO_PRIVADO = 'SERVICO_PRIVADO',
  MRC = 'MRC',
  MULTIVACINACAO = 'MULTIVACINACAO',
  PESQUISA = 'PESQUISA',
  PRE_EXPOSICAO = 'PRE_EXPOSICAO',
  POS_EXPOSICAO = 'POS_EXPOSICAO',
  REEXPOSICAO = 'REEXPOSICAO',
}

export type Etnia = {
  __typename?: 'Etnia'
  id: Scalars['ID']
  nome: Scalars['String']
  codigoCadsus: Scalars['String']
}

export type EtniaPayload = {
  __typename?: 'EtniaPayload'
  content: Array<Maybe<Etnia>>
  pageInfo: PageInfo
}

export type EtniaQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type EvolucaoAlergia = {
  __typename?: 'EvolucaoAlergia'
  id: Scalars['ID']
  criticidade: CriticidadeAlergia
  dataInicio?: Maybe<Scalars['LocalDate']>
  observacao?: Maybe<Scalars['String']>
  tipoReacao?: Maybe<TipoReacaoAlergia>
  grauCerteza: GrauCertezaAlergia
  manifestacoes: Array<ManifestacaoAlergia>
  manifestacoesLegado?: Maybe<Scalars['String']>
  atualizadoEm: Scalars['Instant']
  atendimentoProfissional: AtendimentoProfissional
  possuiSubstanciaEspecificaCbara?: Maybe<Scalars['Boolean']>
  alergia?: Maybe<Alergia>
}

export type EvolucaoAvaliacao = {
  __typename?: 'EvolucaoAvaliacao'
  id: Scalars['ID']
  descricao?: Maybe<Scalars['String']>
  necessidadeDeProtese?: Maybe<Scalars['Boolean']>
  ciapsCids?: Maybe<Array<Maybe<EvolucaoAvaliacaoCiapCid>>>
  tiposVigilanciaBucal?: Maybe<Array<Maybe<VigilanciaEmSaudeBucalEnum>>>
}

export type EvolucaoAvaliacaoCiapCid = {
  __typename?: 'EvolucaoAvaliacaoCiapCid'
  id: Scalars['ID']
  ciap?: Maybe<Ciap>
  cid10?: Maybe<Cid10>
  nota?: Maybe<Scalars['String']>
  isCiapCidNaListaDeProblemas: Scalars['Boolean']
}

export type EvolucaoDente = {
  __typename?: 'EvolucaoDente'
  id: Scalars['ID']
  dente: Dente
  odontograma?: Maybe<Odontograma>
  situacoesFace?: Maybe<Array<SituacaoFaceEnum>>
  situacoesRaiz?: Maybe<Array<SituacaoRaizEnum>>
  situacaoCoroaCima?: Maybe<SituacaoCoroaEnum>
  situacaoCoroaBaixo?: Maybe<SituacaoCoroaEnum>
  situacaoCoroaEsquerda?: Maybe<SituacaoCoroaEnum>
  situacaoCoroaDireita?: Maybe<SituacaoCoroaEnum>
  situacaoCoroaCentro?: Maybe<SituacaoCoroaEnum>
}

export type EvolucaoDenteInput = {
  parteBucal: ParteBucalEnum
  situacoesFace?: Maybe<Array<Maybe<SituacaoFaceEnum>>>
  situacoesRaiz?: Maybe<Array<Maybe<SituacaoRaizEnum>>>
  situacaoCoroaCima?: Maybe<SituacaoCoroaEnum>
  situacaoCoroaBaixo?: Maybe<SituacaoCoroaEnum>
  situacaoCoroaEsquerda?: Maybe<SituacaoCoroaEnum>
  situacaoCoroaDireita?: Maybe<SituacaoCoroaEnum>
  situacaoCoroaCentro?: Maybe<SituacaoCoroaEnum>
  procedimentos?: Maybe<Array<Maybe<Scalars['ID']>>>
  observacao?: Maybe<Scalars['String']>
}

export type EvolucaoObjetivo = {
  __typename?: 'EvolucaoObjetivo'
  id: Scalars['ID']
  necessidadesEspeciais?: Maybe<Scalars['Boolean']>
  descricao?: Maybe<Scalars['String']>
}

export type EvolucaoPlano = {
  __typename?: 'EvolucaoPlano'
  id: Scalars['ID']
  descricao?: Maybe<Scalars['String']>
  intervencoesProcedimentos?: Maybe<Array<Maybe<EvolucaoPlanoCiap>>>
}

export type EvolucaoPlanoCiap = {
  __typename?: 'EvolucaoPlanoCiap'
  id: Scalars['ID']
  ciap?: Maybe<Ciap>
  procedimento?: Maybe<Procedimento>
  nota?: Maybe<Scalars['String']>
}

export type EvolucaoProblema = {
  __typename?: 'EvolucaoProblema'
  id: Scalars['ID']
  dataInicio?: Maybe<Scalars['LocalDate']>
  dataFim?: Maybe<Scalars['LocalDate']>
  situacao: SituacaoProblema
  observacao?: Maybe<Scalars['String']>
  atualizadoEm?: Maybe<Scalars['Instant']>
  atendimentoProfissional: AtendimentoProfissional
  possuiCid: Scalars['Boolean']
  possuiCiap: Scalars['Boolean']
  problema?: Maybe<Problema>
}

export type EvolucaoProcedimentoOdonto = {
  __typename?: 'EvolucaoProcedimentoOdonto'
  id: Scalars['ID']
  atendimentoProfissionalOdonto: AtendimentoProfissionalOdonto
  procedimentos?: Maybe<Array<Procedimento>>
  partesBucais?: Maybe<Array<ParteBucal>>
  descricaoParteBucal?: Maybe<Scalars['String']>
  observacao?: Maybe<Scalars['String']>
}

export type EvolucaoProcedimentoOdontoInput = {
  parteBucal: ParteBucalEnum
  descricaoLocal?: Maybe<Scalars['String']>
  procedimentos: Array<Maybe<Scalars['ID']>>
  observacao?: Maybe<Scalars['String']>
}

export type EvolucaoProcedimentoPayload = {
  __typename?: 'EvolucaoProcedimentoPayload'
  content?: Maybe<Array<Maybe<EvolucaoProcedimentoOdonto>>>
  pageInfo?: Maybe<PageInfo>
}

export type EvolucaoSubjetivo = {
  __typename?: 'EvolucaoSubjetivo'
  id: Scalars['ID']
  descricao?: Maybe<Scalars['String']>
  acompanhadoEspecialidade?: Maybe<Scalars['String']>
  ciaps?: Maybe<Array<Maybe<EvolucaoSubjetivoCiap>>>
}

export type EvolucaoSubjetivoCiap = {
  __typename?: 'EvolucaoSubjetivoCiap'
  id: Scalars['ID']
  ciap: Ciap
  nota?: Maybe<Scalars['String']>
}

export type EvolucoesProcedimentoOdontoInput = {
  prontuarioId: Scalars['ID']
  atendProfId?: Maybe<Scalars['ID']>
  tipoPartesBucais?: Maybe<Array<Maybe<TipoParteBucalEnum>>>
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  unpaged?: Maybe<Scalars['Boolean']>
}

export enum ExameEspecificoEnum {
  HEMOGLOBINA_GLICADA = 'HEMOGLOBINA_GLICADA',
  PRENATAL = 'PRENATAL',
  PEATEAB = 'PEATEAB',
  PEATEST = 'PEATEST',
  EXAMES_IMAGEM_US = 'EXAMES_IMAGEM_US',
  EXAMES_IMAGEM_TC = 'EXAMES_IMAGEM_TC',
  EXAMES_IMAGEM_RMN = 'EXAMES_IMAGEM_RMN',
  FUNDOSCOPIA = 'FUNDOSCOPIA',
  TESTE_OLHINHO = 'TESTE_OLHINHO',
  COLESTEROL_TOTAL = 'COLESTEROL_TOTAL',
  COLESTEROL_HDL = 'COLESTEROL_HDL',
  COLESTEROL_LDL = 'COLESTEROL_LDL',
  TRIGLICERIDIOS = 'TRIGLICERIDIOS',
  DOSAGEM_DE_CREATINA = 'DOSAGEM_DE_CREATINA',
  CLEARANCE_DE_CREATINA = 'CLEARANCE_DE_CREATINA',
}

export type ExameRequisitadoInput = {
  id?: Maybe<Scalars['ID']>
  exameId: Scalars['ID']
  observacao?: Maybe<Scalars['String']>
}

export type ExameZika = {
  __typename?: 'ExameZika'
  id: Scalars['ID']
  nome: Scalars['String']
  resultado: Scalars['String']
  dataRealizacao: Scalars['Instant']
}

export type ExcluirModeloRelatorioGerencialInput = {
  id: Scalars['ID']
  tipoModulo: TipoModuloEnum
}

export type ExcluirPerfilPayload = {
  __typename?: 'ExcluirPerfilPayload'
  id: Scalars['ID']
}

export enum FaixaEtariaConsumoAlimentar {
  MENOR_SEIS_MESES = 'MENOR_SEIS_MESES',
  MAIOR_SEIS_MESES_MENOR_DOIS_ANOS = 'MAIOR_SEIS_MESES_MENOR_DOIS_ANOS',
  MAIOR_DOIS_ANOS = 'MAIOR_DOIS_ANOS',
}

export type FaixaEtariaInput = {
  idadeMinima?: Maybe<Scalars['Long']>
  idadeMaxima?: Maybe<Scalars['Long']>
  tipoFaixaEtaria?: Maybe<AgeRangeType>
}

export type FaixaEtariaVacinacao = {
  __typename?: 'FaixaEtariaVacinacao'
  id: Scalars['ID']
  diasInicio: Scalars['Int']
  diasFim: Scalars['Int']
  descricao: Scalars['String']
}

export type FaixaEtariaVacinacaoQueryInput = {
  imunobiologicoId: Scalars['ID']
  doseId: Scalars['ID']
  estrategiaId: Scalars['ID']
}

export type Familia = {
  __typename?: 'Familia'
  id: Scalars['ID']
  cnes: Scalars['String']
  ine: Scalars['String']
  numeroProntuarioFamiliar?: Maybe<Scalars['String']>
  rendaFamiliar?: Maybe<RendaFamiliar>
  resideDesde?: Maybe<Scalars['LocalDate']>
  numeroMembros?: Maybe<Scalars['Int']>
  cpfCnsResponsavel?: Maybe<Scalars['String']>
  cidadaos: Array<CidadaoTerritorio>
  responsavel?: Maybe<CidadaoTerritorio>
  isResponsavelCadastrado: Scalars['Boolean']
  isResponsavelDeclarado: Scalars['Boolean']
  isResponsavelVivo: Scalars['Boolean']
  isResponsavelUnico: Scalars['Boolean']
  isResponsavelAindaReside: Scalars['Boolean']
  isFamiliaAindaReside: Scalars['Boolean']
  isInformacaoSuficiente: Scalars['Boolean']
  isDomicilioAtivo: Scalars['Boolean']
}

export type FamiliaCidadaosArgs = {
  input?: Maybe<CidadaosFamiliaInput>
}

export type FamiliaInput = {
  numeroProntuarioFamiliar?: Maybe<Scalars['String']>
  rendaFamiliar?: Maybe<RendaFamiliar>
  resideDesde?: Maybe<Scalars['LocalDate']>
  numeroMembros?: Maybe<Scalars['Int']>
  mudouSe?: Maybe<Scalars['Boolean']>
  responsavelId: Scalars['ID']
  cidadaos: Array<CidadaoTerritorioInput>
}

export type FamiliarAntecedentesInput = {
  ciaps?: Maybe<Array<Maybe<AntecedenteFamiliarCiapInput>>>
}

export type FamiliasImovelInput = {
  isDomicilioAtivo?: Maybe<Scalars['Boolean']>
  isFamiliaAindaReside?: Maybe<Scalars['Boolean']>
  isInformacaoSuficiente?: Maybe<Scalars['Boolean']>
  isResponsavelAindaReside?: Maybe<Scalars['Boolean']>
  isResponsavelCadastrado?: Maybe<Scalars['Boolean']>
  isResponsavelDeclarado?: Maybe<Scalars['Boolean']>
  isResponsavelUnico?: Maybe<Scalars['Boolean']>
  isResponsavelVivo?: Maybe<Scalars['Boolean']>
}

export type FatoAtendDomCondicaoAval = {
  __typename?: 'FatoAtendDomCondicaoAval'
  id: Scalars['ID']
  dimensaoCiap2?: Maybe<DimensaoCiap>
  dimensaoCid10?: Maybe<DimensaoCid10>
}

export type FatoAtendDomProced = {
  __typename?: 'FatoAtendDomProced'
  id: Scalars['ID']
  nuUuidFicha: Scalars['String']
  dimensaoProcedimento: DimensaoProcedimento
}

export type FatoAtendimentoDomiciliar = {
  __typename?: 'FatoAtendimentoDomiciliar'
  id: Scalars['ID']
  nuUuidFicha: Scalars['String']
  dimensaoProfissional1?: Maybe<DimensaoProfissional>
  dimensaoProfissional2?: Maybe<DimensaoProfissional>
  dimensaoCbo1?: Maybe<DimensaoCbo>
  dimensaoCbo2?: Maybe<DimensaoCbo>
  dimensaoUnidadeSaude1?: Maybe<DimensaoUnidadeSaude>
  dimensaoUnidadeSaude2?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe1?: Maybe<DimensaoEquipe>
  dimensaoEquipe2?: Maybe<DimensaoEquipe>
  dimensaoLocalAtendimento?: Maybe<DimensaoLocalAtendimento>
  dimensaoModalidadeAD?: Maybe<DimensaoModalidadeAd>
  dimensaoCondutaAD?: Maybe<DimensaoCondutaAd>
  condicoesAvaliadas: Array<Maybe<Scalars['String']>>
  procedimentos?: Maybe<Array<Maybe<FatoAtendDomProced>>>
  fatosAtendDomCondicaoAval?: Maybe<Array<Maybe<FatoAtendDomCondicaoAval>>>
}

export type FatoAtendimentoIndividual = {
  __typename?: 'FatoAtendimentoIndividual'
  id: Scalars['ID']
  nuUuidFicha: Scalars['String']
  nuCNS?: Maybe<Scalars['String']>
  nuCPFCidadao?: Maybe<Scalars['String']>
  dataInicialAtendimento: Scalars['Instant']
  dataFinalAtendimento: Scalars['Instant']
  dataNascimento: Scalars['Instant']
  dimensaoMunicipio?: Maybe<DimensaoMunicipio>
  dimensaoProfissional1?: Maybe<DimensaoProfissional>
  dimensaoProfissional2?: Maybe<DimensaoProfissional>
  dimensaoCbo1?: Maybe<DimensaoCbo>
  dimensaoCbo2?: Maybe<DimensaoCbo>
  dimensaoUnidadeSaude1?: Maybe<DimensaoUnidadeSaude>
  dimensaoUnidadeSaude2?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe1?: Maybe<DimensaoEquipe>
  dimensaoEquipe2?: Maybe<DimensaoEquipe>
  dimensaoLocalAtendimento?: Maybe<DimensaoLocalAtendimento>
  dataRegistro?: Maybe<Scalars['Instant']>
  dimensaoTurno: DimensaoTurno
  dimensaoSexo: DimensaoSexo
  dimensaoRacionalidadeSaude?: Maybe<DimensaoRacionalidadeSaude>
  dimensaoAleitamento?: Maybe<DimensaoAleitamento>
  dimensaoModalidadeAd?: Maybe<DimensaoModalidadeAd>
  cidsCiapsAvaliados: Array<Maybe<Scalars['String']>>
  examesSolicitados?: Maybe<Array<Maybe<DimensaoProcedimento>>>
  examesAvaliados?: Maybe<Array<Maybe<DimensaoProcedimento>>>
  stFicouEmObservacao?: Maybe<Scalars['Boolean']>
  nuPerimetroCefalico?: Maybe<Scalars['Float']>
  nuPeso?: Maybe<Scalars['Float']>
  nuAltura?: Maybe<Scalars['Float']>
  nuImc?: Maybe<Scalars['Float']>
  stVacinacaoEmDia?: Maybe<Scalars['Boolean']>
  dum?: Maybe<Scalars['Instant']>
  stGravidezPlanejada?: Maybe<Scalars['Boolean']>
  nuIdadeGestacional?: Maybe<Scalars['Int']>
  nuGestasPrevias?: Maybe<Scalars['Int']>
  nuPartos?: Maybe<Scalars['Int']>
  nasfPolo: Array<Maybe<Scalars['String']>>
  stNasf: Scalars['Boolean']
  condutaDesfecho: Array<Maybe<Scalars['String']>>
  encaminhamentos: Array<Maybe<Scalars['String']>>
  dimProfFinalizadorObs?: Maybe<DimensaoProfissional>
  dimCboFinalizadorObs?: Maybe<DimensaoCbo>
  dimUbsFinalizadorObs?: Maybe<DimensaoUnidadeSaude>
  dimEquipeFinalizadorObs?: Maybe<DimensaoEquipe>
  dimTipoParticipacaoCidadao?: Maybe<DimensaoTipoParticipacaoAtendimento>
  dimTipoParticipacaoProfConvidado?: Maybe<DimensaoTipoParticipacaoAtendimento>
}

export type FatoAtendimentoOdonto = {
  __typename?: 'FatoAtendimentoOdonto'
  id: Scalars['ID']
  isPacienteNecessidadesEspeciais: Scalars['Boolean']
  isGestante?: Maybe<Scalars['Boolean']>
  cidsCiapsAvaliados: Array<Maybe<Scalars['String']>>
  vigilanciaSaudeBucal: Array<Maybe<Scalars['String']>>
  fornecimento: Array<Maybe<Scalars['String']>>
  conduta: Array<Maybe<Scalars['String']>>
  encaminhamento: Array<Maybe<Scalars['String']>>
  procedimentos?: Maybe<Array<Maybe<FatoAtendOdontoProced>>>
  dimensaoProfissional1?: Maybe<DimensaoProfissional>
  dimensaoProfissional2?: Maybe<DimensaoProfissional>
  dimensaoCbo1?: Maybe<DimensaoCbo>
  dimensaoCbo2?: Maybe<DimensaoCbo>
  dimensaoUnidadeSaude1?: Maybe<DimensaoUnidadeSaude>
  dimensaoUnidadeSaude2?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe1?: Maybe<DimensaoEquipe>
  dimensaoEquipe2?: Maybe<DimensaoEquipe>
  dimensaoLocalAtendimento?: Maybe<DimensaoLocalAtendimento>
  dimTipoParticipacaoCidadao?: Maybe<DimensaoTipoParticipacaoAtendimento>
  dimTipoParticipacaoProfConvidado?: Maybe<DimensaoTipoParticipacaoAtendimento>
}

export type FatoAtendOdontoProced = {
  __typename?: 'FatoAtendOdontoProced'
  id: Scalars['ID']
  dimensaoProcedimento: DimensaoProcedimento
  quantidadeProcedimentos: Scalars['Int']
}

export type FatoAtividadeColetiva = {
  __typename?: 'FatoAtividadeColetiva'
  id: Scalars['ID']
  dimensaoProcedimento?: Maybe<DimensaoProcedimento>
  nuUuidFicha: Scalars['String']
  dimensaoMunicipio?: Maybe<DimensaoMunicipio>
  dimensaoUnidadeSaude?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe?: Maybe<DimensaoEquipe>
  dimensaoProfissional?: Maybe<DimensaoProfissional>
  dimensaoCbo?: Maybe<DimensaoCbo>
  dimensaoUnddSadeAcdmSade?: Maybe<DimensaoUnidadeSaude>
  dataRegistro?: Maybe<Scalars['Instant']>
  turno: DimensaoTurno
  nuParticipantes?: Maybe<Scalars['Int']>
  nuParticipantesRegistrados?: Maybe<Scalars['Int']>
  nuAvaliacoesAlteradas?: Maybe<Scalars['Int']>
  dimensaoTipoAtividade: DimensaoTipoAtividade
  stPseEducacao: Scalars['Boolean']
  stPseSaude: Scalars['Boolean']
  dimensaoInep?: Maybe<DimensaoInep>
  publicoAlvo: Array<Maybe<Scalars['String']>>
  temasSaude: Array<Maybe<Scalars['String']>>
  praticasSaude: Array<Maybe<Scalars['String']>>
  fatoAtividadeColetivaParticipantes?: Maybe<Array<FatoAtividadeColetivaParticipante>>
}

export type FatoAtividadeColetivaParticipante = {
  __typename?: 'FatoAtividadeColetivaParticipante'
  id: Scalars['ID']
  fatoAtividadeColetiva: FatoAtividadeColetiva
  nuUuidFicha: Scalars['String']
  dimensaoMunicipio?: Maybe<DimensaoMunicipio>
  dimensaoUnidadeSaude?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe?: Maybe<DimensaoEquipe>
  dimensaoProfissional?: Maybe<DimensaoProfissional>
  dimensaoCbo?: Maybe<DimensaoCbo>
  dimensaoTipoAtividade: DimensaoTipoAtividade
  dataRegistro?: Maybe<Scalars['Instant']>
  nuCpfParticipante?: Maybe<Scalars['String']>
  nuCnsParticipante?: Maybe<Scalars['String']>
  dtNascimentoParticipante: Scalars['Instant']
  dimensaoSexo: DimensaoSexo
  stAvaliacaoAlterada?: Maybe<Scalars['Boolean']>
  stPnctCessouHabitoFumar?: Maybe<Scalars['Boolean']>
  stPnctAbandonouGrupo?: Maybe<Scalars['Boolean']>
  pnctParticipante: Array<Maybe<Scalars['String']>>
  nuAlturaParticipante?: Maybe<Scalars['Float']>
  nuPesoParticipante?: Maybe<Scalars['Float']>
  nuImcParticipante?: Maybe<Scalars['Float']>
}

export type FatoAvaliacaoElegibilidade = {
  __typename?: 'FatoAvaliacaoElegibilidade'
  id: Scalars['ID']
  nuUuidFicha: Scalars['String']
  dimensaoCid10Principal?: Maybe<DimensaoCid10>
  dimensaoCid10SecundarioUm?: Maybe<DimensaoCid10>
  dimensaoCid10SecundarioDois?: Maybe<DimensaoCid10>
  dimensaoProfissional1?: Maybe<DimensaoProfissional>
  dimensaoCbo1?: Maybe<DimensaoCbo>
  dimensaoUnidadeSaude1?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe1?: Maybe<DimensaoEquipe>
  dimensaoProfissional2?: Maybe<DimensaoProfissional>
  dimensaoCbo2?: Maybe<DimensaoCbo>
  dimensaoUnidadeSaude2?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe2?: Maybe<DimensaoEquipe>
  dimensaoCuidador?: Maybe<DimensaoCuidador>
  dimensaoModalidadeAD?: Maybe<DimensaoModalidadeAd>
  procedenciaOrigem?: Maybe<Scalars['String']>
  condicoesAvaliadas?: Maybe<Array<Maybe<Scalars['String']>>>
  conclusao?: Maybe<Array<Maybe<Scalars['String']>>>
  cnsCuidador?: Maybe<Scalars['String']>
  cpfCuidador?: Maybe<Scalars['String']>
  isElegivel: Scalars['Boolean']
}

export type FatoCidadaoPec = {
  __typename?: 'FatoCidadaoPec'
  id: Scalars['ID']
  nome?: Maybe<Scalars['String']>
  nomeSocial?: Maybe<Scalars['String']>
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  dimensaoSexo?: Maybe<DimensaoSexo>
  dataNascimento?: Maybe<Scalars['LocalDate']>
}

export type FatoCuidadoCompartilhado = {
  __typename?: 'FatoCuidadoCompartilhado'
  id: Scalars['ID']
  isExecutante?: Maybe<Scalars['Boolean']>
}

export type FatoProcedAtend = {
  __typename?: 'FatoProcedAtend'
  id: Scalars['ID']
  dimensaoMunicipio: DimensaoMunicipio
  dimensaoProfissional: DimensaoProfissional
  dimensaoCbo: DimensaoCbo
  dimensaoUnidadeSaude: DimensaoUnidadeSaude
  dimensaoEquipe?: Maybe<DimensaoEquipe>
  dimensaoLocalAtendimento?: Maybe<DimensaoLocalAtendimento>
  dataRegistro: Scalars['Instant']
  dimensaoSexo: DimensaoSexo
  dimensaoTurno: DimensaoTurno
  stEscutaInicial?: Maybe<Scalars['Boolean']>
  nuUuidFicha: Scalars['String']
  nuCnsCidadao?: Maybe<Scalars['String']>
  nuCpfCidadao?: Maybe<Scalars['String']>
  dtNascimento?: Maybe<Scalars['Instant']>
  dtInicialAtendimento?: Maybe<Scalars['Instant']>
  dtFinalAtendimento?: Maybe<Scalars['Instant']>
  procedimentos?: Maybe<Array<Maybe<DimensaoProcedimento>>>
}

export type FatorRisco = AvaliacaoDesenvolvimento & {
  __typename?: 'FatorRisco'
  id: Scalars['ID']
  descricao: Scalars['String']
  status: StatusAvaliadoEnum
  dataAvaliacao?: Maybe<Scalars['LocalDate']>
}

export type FatoVacinacao = {
  __typename?: 'FatoVacinacao'
  id: Scalars['ID']
  viajante?: Maybe<Scalars['Boolean']>
  gestante?: Maybe<Scalars['Boolean']>
  puerpera?: Maybe<Scalars['Boolean']>
  comunicanteHanseniase?: Maybe<Scalars['Boolean']>
  dataRegistro: Scalars['Instant']
  fatosVacinacaoVacina?: Maybe<Array<FatoVacinacaoVacina>>
  dimensaoUnidadeSaude?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe?: Maybe<DimensaoEquipe>
  dimensaoProfissional?: Maybe<DimensaoProfissional>
  dimensaoCbo?: Maybe<DimensaoCbo>
  dimensaoLocalAtendimento?: Maybe<DimensaoLocalAtendimento>
}

export type FatoVacinacaoVacina = {
  __typename?: 'FatoVacinacaoVacina'
  id: Scalars['ID']
  fatoVacinacao: FatoVacinacao
  dimensaoImunobiologico: DimensaoImunobiologico
  dimensaoEstrategiaVacinacao?: Maybe<DimensaoEstrategiaVacinacao>
  dimensaoDoseImunobiologico: DimensaoDoseImunobiologico
  dataRegistro: Scalars['Instant']
  dataRegistroVacinaAplicada: Scalars['Instant']
  isRegistroAnterior: Scalars['Boolean']
  dimensaoUnidadeSaude: DimensaoUnidadeSaude
  dimensaoGrupoAtendimento: DimensaoGrupoAtendimento
  nomeLote?: Maybe<Scalars['String']>
  nomeFabricante?: Maybe<Scalars['String']>
}

export type FatoVisitaDomiciliar = {
  __typename?: 'FatoVisitaDomiciliar'
  id: Scalars['ID']
  nuUuidFicha: Scalars['String']
  dataVisita?: Maybe<Scalars['LocalDate']>
  cidadaoPec?: Maybe<FatoCidadaoPec>
  cpfCidadao?: Maybe<Scalars['String']>
  cnsCidadao?: Maybe<Scalars['String']>
  dataNascimentoCidadao?: Maybe<Scalars['Instant']>
  dimensaoSexo?: Maybe<DimensaoSexo>
  motivoVisita: Array<Maybe<Scalars['String']>>
  buscaAtiva: Array<Maybe<Scalars['String']>>
  acompanhamento: Array<Maybe<Scalars['String']>>
  controleAmbiental: Array<Maybe<Scalars['String']>>
  anotacoes?: Maybe<Scalars['String']>
  dimensaoProfissional?: Maybe<DimensaoProfissional>
  dimensaoCbo?: Maybe<DimensaoCbo>
  dimensaoUnidadeSaude?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe?: Maybe<DimensaoEquipe>
  dimensaoDesfechoVisita: DimensaoDesfechoVisita
  dimensaoTipoGlicemia?: Maybe<DimensaoTipoGlicemia>
  tipoOrigem?: Maybe<DimensaoTipoOrigem>
  turno?: Maybe<DimensaoTurno>
  peso?: Maybe<Scalars['Float']>
  altura?: Maybe<Scalars['Float']>
  temperatura?: Maybe<Scalars['String']>
  medicaoGlicemia?: Maybe<Scalars['String']>
  medicaoPressaoArterial?: Maybe<Scalars['String']>
  stVisitaCompartilhada?: Maybe<Scalars['Boolean']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
}

export type FechamentoAgenda = {
  __typename?: 'FechamentoAgenda'
  id?: Maybe<Scalars['ID']>
  dataInicial?: Maybe<Scalars['LocalDate']>
  dataFinal?: Maybe<Scalars['LocalDate']>
  motivo?: Maybe<Scalars['String']>
  especifique?: Maybe<Scalars['String']>
}

export type FechamentoAgendaDiaQueryInput = {
  lotacaoId: Scalars['ID']
  dia: Scalars['LocalDate']
}

export type FechamentoAgendaInput = {
  idLotacao: Scalars['ID']
  periodo: Period
  motivo: MotivoFechamentoEnum
  especifique?: Maybe<Scalars['String']>
}

export type FechamentoAgendaQueryInput = {
  idLotacao: Scalars['ID']
  pageParams?: Maybe<PageParams>
}

export type FechamentoAgendaQueryPayload = {
  __typename?: 'FechamentoAgendaQueryPayload'
  content: Array<Maybe<FechamentoAgenda>>
  pageInfo: PageInfo
}

export type FichaCadastroDomiciliarDto = {
  __typename?: 'FichaCadastroDomiciliarDto'
  tipoLocalizacaoId?: Maybe<Scalars['ID']>
  tipoDomicilioId?: Maybe<Scalars['ID']>
  numeroMoradores?: Maybe<Scalars['Int']>
}

export enum FichaComplementarZikaMicrocefaliaResultadoExameEnum {
  PRESENTE_BILATERAL = 'PRESENTE_BILATERAL',
  DUVIDOSO_OU_AUSENTE = 'DUVIDOSO_OU_AUSENTE',
  FUNDO_OLHO_NORMAL = 'FUNDO_OLHO_NORMAL',
  FUNDO_OLHO_ALTERADO = 'FUNDO_OLHO_ALTERADO',
  ORELHINHA_PASSOU = 'ORELHINHA_PASSOU',
  ORELHINHA_FALHOU = 'ORELHINHA_FALHOU',
  TRANSFONTANELA_NORMAL = 'TRANSFONTANELA_NORMAL',
  TRANSFONTANELA_SUGESTIVO_INFECCAO_CONGENITA = 'TRANSFONTANELA_SUGESTIVO_INFECCAO_CONGENITA',
  TRANSFONTANELA_OUTRAS_ALTERACOES = 'TRANSFONTANELA_OUTRAS_ALTERACOES',
  TRANSFONTANELA_INDETERMINADO = 'TRANSFONTANELA_INDETERMINADO',
  TOMOGRAFIA_NORMAL = 'TOMOGRAFIA_NORMAL',
  TOMOGRAFIA_SUGESTIVO_INFECCAO_CONGENITA = 'TOMOGRAFIA_SUGESTIVO_INFECCAO_CONGENITA',
  TOMOGRAFIA_OUTRAS_ALTERACOES = 'TOMOGRAFIA_OUTRAS_ALTERACOES',
  TOMOGRAFIA_INDETERMINADO = 'TOMOGRAFIA_INDETERMINADO',
  RESSONANCIA_NORMAL = 'RESSONANCIA_NORMAL',
  RESSONANCIA_SUGESTIVO_INFECCAO_CONGENITA = 'RESSONANCIA_SUGESTIVO_INFECCAO_CONGENITA',
  RESSONANCIA_OUTRAS_ALTERACOES = 'RESSONANCIA_OUTRAS_ALTERACOES',
  RESSONANCIA_INDETERMINADO = 'RESSONANCIA_INDETERMINADO',
}

export type FichaNotificacaoCasoSuspeito = {
  __typename?: 'FichaNotificacaoCasoSuspeito'
  id: Scalars['ID']
  nomeNotificacao: Scalars['String']
}

export type FichaNotificacaoQueryInput = {
  cidadaoId: Scalars['ID']
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type FichaNotificacaoQueryPayload = {
  __typename?: 'FichaNotificacaoQueryPayload'
  content: Array<Maybe<FichaNotificacaoCasoSuspeito>>
  pageInfo: PageInfo
}

export type FichasConcatenadasHistorico = {
  __typename?: 'FichasConcatenadasHistorico'
  uuidProcedimento?: Maybe<Scalars['String']>
  uuidZika?: Maybe<Scalars['String']>
}

export type FinalizacaoAtendimentoInput = {
  tipoAtendimento?: Maybe<TipoAtendimentoEnum>
  atendimentoCompartilhadoLotacaoId?: Maybe<Scalars['ID']>
  procedimentosAdministrativos?: Maybe<Array<Maybe<ProcedimentoAtendimentoInput>>>
  fichaNotificacaoCasoSuspeito?: Maybe<Array<Maybe<Scalars['ID']>>>
  racionalidadeEmSaude?: Maybe<RacionalidadeEmSaudeEnum>
  condutas?: Maybe<Array<Maybe<CondutaEnum>>>
  desfechoAtendimento?: Maybe<DesfechoAtendimentoInput>
  agendamentoConsultas?: Maybe<AgendamentoConsultasAtendimentoInput>
  tipoConsultaOdonto?: Maybe<TipoConsultaOdontoEnum>
  fornecimentosOdonto?: Maybe<Array<Maybe<FornecimentoOdontoEnum>>>
  condutasOdonto?: Maybe<Array<Maybe<TipoEncaminhamentoOdontoEnum>>>
  tipoParticipacaoCidadao?: Maybe<TipoParticipacaoAtendimentoEnum>
  tipoParticipacaoProfissionalConvidado?: Maybe<TipoParticipacaoAtendimentoEnum>
}

export type Flags = {
  __typename?: 'Flags'
  id: Scalars['ID']
  experimental: Scalars['Boolean']
  unificacaoBase: Scalars['Boolean']
  envioTreinamentoParaProd: Scalars['Boolean']
  listaRegistroTardioNova: Scalars['Boolean']
  observacao: Scalars['Boolean']
  assinaturaEletronicaAtendimento: Scalars['Boolean']
  buscaAtivaVacinacao: Scalars['Boolean']
  apiDadosDW: Scalars['Boolean']
  envioRac: Scalars['Boolean']
  envioRpm: Scalars['Boolean']
  envioVacina: Scalars['Boolean']
  envioRnds: Scalars['Boolean']
  equipesVinculadas: Scalars['Boolean']
  cuidadoCompartilhado: Scalars['Boolean']
  historicoCuidadoCompartilhado: Scalars['Boolean']
  pilotoCuidadoCompartilhado1: Scalars['Boolean']
  pilotoCuidadoCompartilhado2: Scalars['Boolean']
  cookie: Scalars['Boolean']
  nps: Scalars['Boolean']
  cadastroImovel: Scalars['Boolean']
  pilotoAtestadoDigital: Scalars['Boolean']
  pilotoVideochamada2: Scalars['Boolean']
  prescricaoControlada: Scalars['Boolean']
  impressaoEscutaInicial: Scalars['Boolean']
  pilotoVideochamada3: Scalars['Boolean']
  garantiaAcesso: Scalars['Boolean']
  pilotoVinculacaoServico1: Scalars['Boolean']
  prescricaoTurno: Scalars['Boolean']
  pilotoRecebimentoOnlineExterno: Scalars['Boolean']
  atualizacaoSigtap: Scalars['Boolean']
  acompanhamentoCondicaoSaude: Scalars['Boolean']
  acompanhamentoGestantesPuerperas: Scalars['Boolean']
  saudeIndigena: Scalars['Boolean']
  pilotoAlertasSdc1: Scalars['Boolean']
  acompanhamentoCondicaoSaudeCsv: Scalars['Boolean']
  atividadeColetiva: Scalars['Boolean']
}

export type FormaFarmaceutica = {
  __typename?: 'FormaFarmaceutica'
  id: Scalars['ID']
  nome: Scalars['String']
  ativo: Scalars['Boolean']
}

export type FormaFarmaceuticaPayload = {
  __typename?: 'FormaFarmaceuticaPayload'
  content: Array<Maybe<FormaFarmaceutica>>
  pageInfo: PageInfo
}

export type FormaFarmaceuticaQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export enum FornecimentoOdontoEnum {
  ESCOVA_DENTAL = 'ESCOVA_DENTAL',
  CREME_DENTAL = 'CREME_DENTAL',
  FIO_DENTAL = 'FIO_DENTAL',
}

export type FuncaoSocial = {
  __typename?: 'FuncaoSocial'
  id: Scalars['ID']
  nome: Scalars['String']
  nomeFiltro: Scalars['String']
}

export type FuncaoSocialPayload = {
  __typename?: 'FuncaoSocialPayload'
  content: Array<FuncaoSocial>
  pageInfo: PageInfo
}

export type FuncaoSocialQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type GarantiaAcessoAgendarConsultaInput = {
  garantiaAcessoId: Scalars['ID']
  agendamentoConsultaInput?: Maybe<AgendamentoConsultaAtendimentoInput>
  agendamentoConsultaCompartilhadaInput?: Maybe<AgendamentoConsultaCompartilhadaInput>
}

export type GarantiaAcessoFilter = {
  nomeCpfCns?: Maybe<Scalars['String']>
  lotacoesIds?: Maybe<Array<Scalars['ID']>>
  equipes?: Maybe<Array<Scalars['ID']>>
  tiposAtendimento?: Maybe<Array<ListaEsperaTipoAtendimentoEnum>>
  tiposServicoIds?: Maybe<Array<Scalars['ID']>>
  isCuidadoCompartilhado: Scalars['Boolean']
  isGarantiaAcesso: Scalars['Boolean']
  ordenacao: GarantiaAcessoSortEnum
  periodo?: Maybe<Period>
  pageParams?: Maybe<PageParams>
}

export type GarantiaAcessoItemCreateInput = {
  cidadaoId: Scalars['ID']
  equipeId?: Maybe<Scalars['ID']>
  tipoAtendimento?: Maybe<ListaEsperaTipoAtendimentoEnum>
  tipoServico?: Maybe<Scalars['ID']>
  motivo: Scalars['String']
}

export type GarantiaAcessoItemDeleteInput = {
  garantiaAcessoId: Scalars['ID']
  motivoSaida?: Maybe<ListaEsperaMotivoSaidaEnum>
  tentativasContato?: Maybe<Scalars['Int']>
  observacaoSaida?: Maybe<Scalars['String']>
}

export type GarantiaAcessoItemUpdateInput = {
  garantiaAcessoId: Scalars['ID']
  equipeId?: Maybe<Scalars['ID']>
  motivo: Scalars['String']
  tipoAtendimento?: Maybe<ListaEsperaTipoAtendimentoEnum>
  tipoServico?: Maybe<Scalars['ID']>
}

export enum GarantiaAcessoOrigemRegistroEnum {
  GARANTIA_ACESSO = 'GARANTIA_ACESSO',
  CUIDADO_COMPARTILHADO = 'CUIDADO_COMPARTILHADO',
}

export type GarantiaAcessoPayload = {
  __typename?: 'GarantiaAcessoPayload'
  content: Array<Maybe<ListaEspera>>
  pageInfo: PageInfo
}

export enum GarantiaAcessoSortEnum {
  CLASSIFICACAO_DE_PRIORIDADE = 'CLASSIFICACAO_DE_PRIORIDADE',
  ORDEM_CHEGADA_CRESCENTE = 'ORDEM_CHEGADA_CRESCENTE',
  ORDEM_CHEGADA_DECRESCENTE = 'ORDEM_CHEGADA_DECRESCENTE',
}

export type GestorEstadual = Acesso & {
  __typename?: 'GestorEstadual'
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  uf: Uf
  municipio?: Maybe<Municipio>
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  actions: AcessoActions
}

export type GestorMunicipal = Acesso & {
  __typename?: 'GestorMunicipal'
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  municipio: Municipio
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  actions: AcessoActions
}

export enum GrauCertezaAlergia {
  CONFIRMADO = 'CONFIRMADO',
  RESOLVIDO = 'RESOLVIDO',
  REFUTADO = 'REFUTADO',
  SUSPEITO = 'SUSPEITO',
  NAO_INFORMADO = 'NAO_INFORMADO',
}

export enum GrauSatisfacaoUsuario {
  MUITO_SATISFEITO = 'MUITO_SATISFEITO',
  SATISFEITO = 'SATISFEITO',
  INDIFERENTE = 'INDIFERENTE',
  INSATISFEITO = 'INSATISFEITO',
  MUITO_INSATISFEITO = 'MUITO_INSATISFEITO',
}

export enum GrupoAlvoBuscaAtivaVacinacaoEnum {
  CRIANCAS = 'CRIANCAS',
  ADOLESCENTES = 'ADOLESCENTES',
  ADULTOS = 'ADULTOS',
  IDOSOS = 'IDOSOS',
  GESTANTES_E_PUERPERAS = 'GESTANTES_E_PUERPERAS',
}

export enum GrupoAlvoVacinacaoEnum {
  CRIANCAS = 'CRIANCAS',
  ADOLESCENTE = 'ADOLESCENTE',
  ADULTO = 'ADULTO',
  IDOSO = 'IDOSO',
  GESTANTE = 'GESTANTE',
  TODOS = 'TODOS',
  MAIOR_IGUAL_10_ANOS = 'MAIOR_IGUAL_10_ANOS',
  CRIANCA_E_ADOLESCENTE = 'CRIANCA_E_ADOLESCENTE',
  ADULTO_MAIOR_IGUAL_30_ANOS = 'ADULTO_MAIOR_IGUAL_30_ANOS',
}

export type GrupoAtendimento = {
  __typename?: 'GrupoAtendimento'
  id: Scalars['ID']
  nome: Scalars['String']
  nomeFiltro: Scalars['String']
}

export type GrupoCondicaoDto = {
  __typename?: 'GrupoCondicaoDto'
  id: Scalars['ID']
  grupoCondicao: GrupoCondicaoEnum
  ciaps?: Maybe<Array<Maybe<Ciap>>>
  cids?: Maybe<Array<Maybe<Cid10>>>
}

export enum GrupoCondicaoEnum {
  ASMA = 'ASMA',
  AVC = 'AVC',
  DENGUE = 'DENGUE',
  DESNUTRICAO = 'DESNUTRICAO',
  DIABETES = 'DIABETES',
  DOENCA_CARDIACA = 'DOENCA_CARDIACA',
  DPOC = 'DPOC',
  DST = 'DST',
  HANSENIASE = 'HANSENIASE',
  HIPERTENSAO_ARTERIAL = 'HIPERTENSAO_ARTERIAL',
  OBESIDADE = 'OBESIDADE',
  GRAVIDEZ = 'GRAVIDEZ',
  INFARTO = 'INFARTO',
  REABILITACAO = 'REABILITACAO',
  RINS = 'RINS',
  SAUDE_MENTAL = 'SAUDE_MENTAL',
  SAUDE_SEXUAL_E_REPRODUTIVA = 'SAUDE_SEXUAL_E_REPRODUTIVA',
  TABAGISMO = 'TABAGISMO',
  TUBERCULOSE = 'TUBERCULOSE',
  USUARIO_DE_ALCOOL = 'USUARIO_DE_ALCOOL',
  USUARIO_DE_OUTRAS_DROGAS = 'USUARIO_DE_OUTRAS_DROGAS',
}

export type GrupoCondicaoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type GrupoCondicaoQueryPayLoad = {
  __typename?: 'GrupoCondicaoQueryPayLoad'
  content: Array<Maybe<GrupoCondicaoDto>>
  pageInfo: PageInfo
}

export type GrupoExame = {
  __typename?: 'GrupoExame'
  id: Scalars['ID']
  nome: Scalars['String']
  procedimentos?: Maybe<Array<Maybe<Procedimento>>>
  quantidadeProcedimentos?: Maybe<Scalars['Int']>
  idadeMinima?: Maybe<Scalars['Int']>
  idadeMaxima?: Maybe<Scalars['Int']>
}

export type GrupoExameInput = {
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
  procedimentos: Array<Maybe<Scalars['ID']>>
}

export type GrupoExameQueryInput = {
  query?: Maybe<Scalars['String']>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  sexo?: Maybe<SexoEnum>
  pageParams?: Maybe<PageParams>
}

export type GrupoMedicoesTipo = {
  __typename?: 'GrupoMedicoesTipo'
  tipo: TipoMedicaoEnum
  medicoes: Array<ItemGrupoMedicoesTipo>
}

export enum GrupoProcedimentoEnum {
  ACOES_PROMOCAO_PREVENCAO_SAUDE = 'ACOES_PROMOCAO_PREVENCAO_SAUDE',
  FINALIDADE_DIAGNOSTICA = 'FINALIDADE_DIAGNOSTICA',
  CLINICOS = 'CLINICOS',
  CIRURGICO = 'CIRURGICO',
  TRANSPLANTES_ORGAOS = 'TRANSPLANTES_ORGAOS',
  MEDICAMENTOS = 'MEDICAMENTOS',
  ORTESES_PROTESES_MATERIAIS = 'ORTESES_PROTESES_MATERIAIS',
  ACOES_COMPLEMENTARES_ATENCAO_SAUDE = 'ACOES_COMPLEMENTARES_ATENCAO_SAUDE',
}

export type GruposExameQueryPayload = {
  __typename?: 'GruposExameQueryPayload'
  content: Array<Maybe<GrupoExame>>
  pageInfo: PageInfo
}

export type GuiaEncaminhamento = {
  __typename?: 'GuiaEncaminhamento'
  id: Scalars['ID']
  atendimentoProfissional: AtendimentoProfissional
  prontuario: Prontuario
  cbo: Cbo
  cid10?: Maybe<Cid10>
  ciap?: Maybe<Ciap>
  classificacaoPrioridade: ClassificacaoPrioridadeCuidadoEnum
  discussao: Scalars['String']
}

export type Historico = {
  __typename?: 'Historico'
  idAtendRecente?: Maybe<Scalars['ID']>
  idAtendProcessado?: Maybe<Scalars['ID']>
  idCuidadoCompartilhado?: Maybe<Scalars['ID']>
  idCuidadoCompartilhadoEvolucao?: Maybe<Scalars['ID']>
  codigoUnicoRegistro?: Maybe<Scalars['String']>
  isAtendRecente: Scalars['Boolean']
  dataAtendimento?: Maybe<Scalars['Instant']>
  turno?: Maybe<TurnoEnum>
  tipoApresentacao?: Maybe<TipoRegistroHistoricoClinico>
  coSubtipoAtendimento?: Maybe<Scalars['Int']>
  tipoAtendProf?: Maybe<TipoAtendimentoProfissional>
  origemAtendimento?: Maybe<OrigemAtendimento>
  cpfCnsCidadao?: Maybe<Scalars['String']>
  profissional?: Maybe<Profissional>
  cnsProfissional?: Maybe<Scalars['String']>
  cbo?: Maybe<Cbo>
  estagiario?: Maybe<Profissional>
  cboEstagio?: Maybe<Cbo>
  unidadeSaude?: Maybe<UnidadeSaude>
  equipe?: Maybe<Equipe>
  idadeGestacional?: Maybe<IdadeGestacional>
  isCancelado?: Maybe<Scalars['Boolean']>
  classificacaoRisco?: Maybe<ClassificacaoRisco>
  condicoesAvaliadas: Array<Scalars['String']>
  condicoesVacinacao?: Maybe<CondicoesVacinacao>
  examesRealizadosZika?: Maybe<Array<Maybe<Scalars['String']>>>
  cuidadoCompartilhadoEvolucao?: Maybe<CuidadoCompartilhadoEvolucao>
  cuidadoCompartilhado?: Maybe<CuidadoCompartilhado>
  hasPrescricaoMedicamento?: Maybe<Scalars['Boolean']>
  hasOrientacao?: Maybe<Scalars['Boolean']>
  hasAtestado?: Maybe<Scalars['Boolean']>
  hasLembrete?: Maybe<Scalars['Boolean']>
  hasAlergia?: Maybe<Scalars['Boolean']>
  hasSolicitacaoExame?: Maybe<Scalars['Boolean']>
  hasResultadoExame?: Maybe<Scalars['Boolean']>
  hasEncaminhamento?: Maybe<Scalars['Boolean']>
  hasEncaminhamentoEspecializado?: Maybe<Scalars['Boolean']>
  hasProcedimentoClinico?: Maybe<Scalars['Boolean']>
  hasMarcadorConsumoAlimentar: Scalars['Boolean']
  hasCuidadoCompartilhado: Scalars['Boolean']
  dadosClinicos?: Maybe<Scalars['String']>
  fichasConcatenadas?: Maybe<FichasConcatenadasHistorico>
  tipoConsultaOdonto?: Maybe<TipoConsultaOdontoEnum>
  hasObservacao?: Maybe<Scalars['Boolean']>
  isAtendObsFinalizado?: Maybe<Scalars['Boolean']>
  inicioAtendimentoObservacao?: Maybe<Scalars['Instant']>
  fimAtendimentoObservacao?: Maybe<Scalars['Instant']>
  nomeFinalizadorObservacao?: Maybe<Scalars['String']>
  cnsFinalizadorObservacao?: Maybe<Scalars['String']>
  cboFinalizadorObservacao?: Maybe<Scalars['String']>
  ubsFinalizadorObservacao?: Maybe<Scalars['String']>
  ineFinalizadorObservacao?: Maybe<Scalars['String']>
  ciapNome?: Maybe<Scalars['String']>
  ciapCodigo?: Maybe<Scalars['String']>
  cidNome?: Maybe<Scalars['String']>
  cidCodigo?: Maybe<Scalars['String']>
  classificacaoPrioridade?: Maybe<Scalars['String']>
  reclassificacaoPrioridade?: Maybe<Scalars['String']>
  conduta?: Maybe<Scalars['String']>
  cnsSolicitante?: Maybe<Scalars['String']>
  nomeSolicitante?: Maybe<Scalars['String']>
  cboSolicitante?: Maybe<Scalars['String']>
  ineSolicitante?: Maybe<Scalars['String']>
  siglaEquipeSolicitante?: Maybe<Scalars['String']>
  nomeUbsSolicitante?: Maybe<Scalars['String']>
  cnsExecutante?: Maybe<Scalars['String']>
  nomeExecutante?: Maybe<Scalars['String']>
  cboExecutante?: Maybe<Scalars['String']>
  ineExecutante?: Maybe<Scalars['String']>
  siglaEquipeExecutante?: Maybe<Scalars['String']>
  nomeUbsExecutante?: Maybe<Scalars['String']>
}

export type HistoricoConsumoAlimentarDetail = {
  __typename?: 'HistoricoConsumoAlimentarDetail'
  id: Scalars['ID']
  questionario: Scalars['String']
  subtitle: Scalars['String']
  perguntasRespostas: Array<Maybe<PerguntasRespostasHistoricoConsumoAlimentar>>
  perguntasRespostasOntem: Array<Maybe<PerguntasRespostasHistoricoConsumoAlimentar>>
  dimensaoProfissional?: Maybe<DimensaoProfissional>
  dimensaoCbo?: Maybe<DimensaoCbo>
  dimensaoUnidadeSaude?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe?: Maybe<DimensaoEquipe>
  dimensaoLocalAtendimento?: Maybe<DimensaoLocalAtendimento>
}

export type HistoricoDetailQueryInput = {
  idAtend?: Maybe<Scalars['ID']>
  uuidRegistro?: Maybe<Scalars['String']>
  cpfOuCnsCidadao?: Maybe<Scalars['String']>
  isOrigemPec: Scalars['Boolean']
}

export type HistoricoExportacaoFiltro = {
  pageParams: PageParams
}

export type HistoricoFiltrosAvancadosQueryInput = {
  periodo?: Maybe<Period>
  subtiposAtendimento?: Maybe<Array<Scalars['ID']>>
  cod2002Cbos?: Maybe<Array<Scalars['String']>>
  ineEquipes?: Maybe<Array<Scalars['String']>>
  cpfProfissionais?: Maybe<Array<Scalars['String']>>
  codCids10?: Maybe<Array<Scalars['String']>>
  codCiaps2?: Maybe<Array<Scalars['String']>>
}

export type HistoricoPayload = {
  __typename?: 'HistoricoPayload'
  content: Array<Maybe<Historico>>
  pageInfo: PageInfo
}

export enum HistoricoPeriodoExibicao {
  TODOS_ATENDIMENTOS = 'TODOS_ATENDIMENTOS',
  ULTIMOS_TRINTA_DIAS = 'ULTIMOS_TRINTA_DIAS',
  ULTIMOS_SESSENTA_DIAS = 'ULTIMOS_SESSENTA_DIAS',
  ULTIMOS_NOVENTA_DIAS = 'ULTIMOS_NOVENTA_DIAS',
  ULTIMO_ANO = 'ULTIMO_ANO',
  PERSONALIZADO = 'PERSONALIZADO',
}

export type HistoricoPrescricaoQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  usoContinuo?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type HistoricoPrescricaoQueryPayload = {
  __typename?: 'HistoricoPrescricaoQueryPayload'
  content: Array<Maybe<AtendimentoProfissional>>
  pageInfo: PageInfo
}

export type HistoricoQueryInput = {
  cidadaoId: Scalars['ID']
  cidadaoCpf?: Maybe<Scalars['String']>
  profissionalCpf?: Maybe<Scalars['String']>
  tiposAtendimento?: Maybe<Array<Maybe<TipoRegistroHistoricoClinico>>>
  somenteMeusRegistros: Scalars['Boolean']
  filtrosAvancados?: Maybe<HistoricoFiltrosAvancadosQueryInput>
  unidadeSaudeCnes?: Maybe<Scalars['String']>
  equipeIne?: Maybe<Scalars['String']>
  excludeEscutaOrPreAtendimentoFromAtendProf?: Maybe<Scalars['ID']>
  sortDirection: Sort
  pageParams?: Maybe<PageParams>
}

export type HistoricoZikaDetail = {
  __typename?: 'HistoricoZikaDetail'
  examesDeImagem: Array<Maybe<ExameZika>>
  outrosExames: Array<Maybe<ExameZika>>
  dimensaoProfissional?: Maybe<DimensaoProfissional>
  dimensaoCbo?: Maybe<DimensaoCbo>
  dimensaoUnidadeSaude?: Maybe<DimensaoUnidadeSaude>
  dimensaoEquipe?: Maybe<DimensaoEquipe>
}

export type HorarioAgenda = {
  __typename?: 'HorarioAgenda'
  horario: Scalars['Instant']
  duracao: Scalars['Int']
  isOcupado: Scalars['Boolean']
  isReservadoAgendaOnline: Scalars['Boolean']
}

export type HorariosAgendaQueryInput = {
  lotacaoId: Scalars['ID']
  dia: Scalars['LocalDate']
  isAtencaoDomiciliar: Scalars['Boolean']
  outraLotacaoIdVerificarDisponibilidade?: Maybe<Scalars['ID']>
  agendamentoIdDesconsiderar?: Maybe<Scalars['ID']>
}

export type HorusFormDto = {
  __typename?: 'HorusFormDto'
  unidadeSaude: UnidadeSaude
  unidadePadrao: Scalars['Boolean']
}

export type IceServersConfig = {
  __typename?: 'IceServersConfig'
  urls: Array<Scalars['String']>
  user?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type IceServersConfigurationInput = {
  userCpf: Scalars['String']
}

export type IdadeGestacional = {
  __typename?: 'IdadeGestacional'
  semanas: Scalars['Int']
  dias: Scalars['Int']
}

export type IdadeGestacionalAcompanhamentoPreNatal = {
  __typename?: 'IdadeGestacionalAcompanhamentoPreNatal'
  dias: Scalars['Int']
  isEcografica: Scalars['Boolean']
}

export type IdadeGestacionalQueryInput = {
  prontuarioId: Scalars['ID']
  dataAtendimento: Scalars['LocalDate']
  resultadosExamesPreNatalAtendimentoAtual?: Maybe<Array<ResultadoExameInput>>
  dum: Scalars['LocalDate']
}

export enum IdentidadeGeneroEnum {
  HOMEM_TRANS = 'HOMEM_TRANS',
  MULHER_TRANS = 'MULHER_TRANS',
  TRANSGENERO = 'TRANSGENERO',
  TRAVESTI = 'TRAVESTI',
  HOMEM_CIS = 'HOMEM_CIS',
  MULHER_CIS = 'MULHER_CIS',
  NAO_BINARIO = 'NAO_BINARIO',
  OUTRO_IDENTIDADE_GENERO = 'OUTRO_IDENTIDADE_GENERO',
}

export type ImoveisQueryInput = {
  bairro?: Maybe<Scalars['String']>
  logradouro?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  microarea?: Maybe<Scalars['String']>
  foraDeArea?: Maybe<Scalars['Boolean']>
  cnes?: Maybe<Scalars['String']>
  ine?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type ImoveisQueryPayload = {
  __typename?: 'ImoveisQueryPayload'
  content: Array<Maybe<ImovelDto>>
  pageInfo: PageInfo
}

export type ImovelDto = {
  __typename?: 'ImovelDto'
  id: Scalars['ID']
  uuidDomicilio: Scalars['ID']
  uuidUltimaFicha: Scalars['ID']
  unidadeSaude: UnidadeSaude
  equipe?: Maybe<Equipe>
  profissional: Profissional
  cbo: Cbo
  tipoEndereco?: Maybe<TipoEnderecoEnum>
  uf?: Maybe<Uf>
  municipio?: Maybe<Municipio>
  numeroMoradores?: Maybe<Scalars['Int']>
  bairro?: Maybe<Scalars['String']>
  tipoLogradouro?: Maybe<TipoLogradouro>
  logradouro?: Maybe<Scalars['String']>
  complemento?: Maybe<Scalars['String']>
  pontoReferencia?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  dataUltimaVisita?: Maybe<Scalars['LocalDate']>
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  microarea?: Maybe<Scalars['String']>
  statusMicroareaPoloBase?: Maybe<Scalars['Boolean']>
  statusForaArea?: Maybe<Scalars['Boolean']>
  numero?: Maybe<Scalars['String']>
  semNumero?: Maybe<Scalars['Boolean']>
  nomeInstituicaoPermanencia?: Maybe<Scalars['String']>
  possuiOutrosProfissionaisVinculados?: Maybe<Scalars['Boolean']>
  responsavelTecnico?: Maybe<ResponsavelTecnicoImovel>
  familias: Array<Familia>
  ultimaFichaCadastrada?: Maybe<UltimaFichaCadastradaDto>
  permissaoReterritorializar?: Maybe<Action>
  dataUltimaAtualizacao?: Maybe<Scalars['Instant']>
  isCadastroCompleto?: Maybe<Scalars['Boolean']>
  tipoImovel?: Maybe<TipoImovelEnum>
  condicoesMoradia: CondicoesMoradia
  animaisImovel?: Maybe<AnimaisImovel>
  ultimaVisitaComAnotacao?: Maybe<FatoVisitaDomiciliar>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  descricaoTipoDomicilio?: Maybe<Scalars['String']>
  descricaoMaterialParede?: Maybe<Scalars['String']>
  numeroResidenciaIndigena?: Maybe<Scalars['String']>
  aldeia?: Maybe<Aldeia>
  poloBase?: Maybe<PoloBase>
  dsei?: Maybe<Dsei>
  municipioAldeia?: Maybe<Municipio>
  ufAldeia?: Maybe<Uf>
}

export type ImovelDtoFamiliasArgs = {
  input?: Maybe<FamiliasImovelInput>
}

export type ImovelInput = {
  id?: Maybe<Scalars['ID']>
  cnes: Scalars['String']
  ine?: Maybe<Scalars['String']>
  cns: Scalars['String']
  cbo2002: Scalars['String']
  dataColeta: Scalars['LocalDate']
  microarea?: Maybe<Scalars['String']>
  statusForaArea?: Maybe<Scalars['Boolean']>
  microareaPoloBase?: Maybe<Scalars['Boolean']>
  tipoEndereco: TipoEnderecoEnum
  endereco?: Maybe<EnderecoTerritorioInput>
  enderecoIndigena?: Maybe<EnderecoIndigenaTerritorioInput>
  numeroMoradores?: Maybe<Scalars['Int']>
  tipoImovel: TipoImovelEnum
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  condicoesMoradiaInput?: Maybe<CondicoesMoradiaInput>
  animaisImovelInput?: Maybe<AnimaisImovelInput>
  instituicaoPermanenciaInput?: Maybe<InstituicaoPermanenciaInput>
  familias?: Maybe<Array<Maybe<FamiliaInput>>>
}

export type ImportacaoArquivo = {
  __typename?: 'ImportacaoArquivo'
  dataEvento?: Maybe<Scalars['Instant']>
  status: StatusImportacaoArquivo
  message?: Maybe<Scalars['String']>
}

export type ImportacaoCnes = {
  __typename?: 'ImportacaoCnes'
  id: Scalars['ID']
  dataImportacao: Scalars['Instant']
  equipesNovas?: Maybe<Scalars['Int']>
  equipesAtualizadas?: Maybe<Scalars['Int']>
  lotacoesNovas?: Maybe<Scalars['Int']>
  lotacoesAtualizadas?: Maybe<Scalars['Int']>
  processo: Processo
  profissional: Profissional
  profissionaisNovos?: Maybe<Scalars['Int']>
  profissionaisAtualizados?: Maybe<Scalars['Int']>
  unidadesSaudeNovas?: Maybe<Scalars['Int']>
  unidadesSaudeAtualizadas?: Maybe<Scalars['Int']>
  vinculacoesEquipesNovas?: Maybe<Scalars['Int']>
  vinculacoesEquipesAtualizadas?: Maybe<Scalars['Int']>
  detalhes?: Maybe<Scalars['String']>
  municipio: Municipio
}

export type ImportacoesCnesQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  municipioId?: Maybe<Scalars['Int']>
}

export type ImportacoesCnesQueryPayload = {
  __typename?: 'ImportacoesCnesQueryPayload'
  content: Array<Maybe<ImportacaoCnes>>
  pageInfo: PageInfo
}

export type Imunobiologico = {
  __typename?: 'Imunobiologico'
  id: Scalars['ID']
  sigla: Scalars['String']
  nome: Scalars['String']
  nomeFiltro: Scalars['String']
  stAtivo: Scalars['Boolean']
}

export type ImunobiologicoFabricante = {
  __typename?: 'ImunobiologicoFabricante'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type ImunobiologicoFabricanteQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type ImunobiologicoFabricanteQueryPayload = {
  __typename?: 'ImunobiologicoFabricanteQueryPayload'
  content: Array<Maybe<ImunobiologicoFabricante>>
  pageInfo: PageInfo
}

export type ImunobiologicoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  outrosImunobiologicos?: Maybe<Scalars['Boolean']>
  semRegras?: Maybe<Scalars['Boolean']>
  sexo?: Maybe<SexoEnum>
  onlyCalendarioVacinal?: Maybe<Scalars['Boolean']>
  isOnlyCovid?: Maybe<Scalars['Boolean']>
  grupoAlvoId?: Maybe<Scalars['ID']>
}

export type ImunobiologicoQueryPayload = {
  __typename?: 'ImunobiologicoQueryPayload'
  content: Array<Maybe<Imunobiologico>>
  pageInfo: PageInfo
}

export type IndicesAcompanhamentoMicroarea = {
  __typename?: 'IndicesAcompanhamentoMicroarea'
  quantidadeImoveisAtualizados: Scalars['Long']
  quantidadeImoveisCadastroCompleto: Scalars['Long']
  quantidadeImoveisAvaliados: Scalars['Long']
  quantidadeDomiciliosAvaliados: Scalars['Long']
  quantidadeDomiciliosVisitados: Scalars['Long']
}

export type Info = {
  __typename?: 'Info'
  id: Scalars['ID']
  ativado: Scalars['Boolean']
  commit: Scalars['String']
  competenciaSigtap: Scalars['String']
  databaseVendor: Scalars['String']
  databaseSchemaVersion: Scalars['String']
  oldPecConnected: Scalars['Boolean']
  recursosDisponiveis: Scalars['String']
  tipoInstalacao?: Maybe<TipoInstalacao>
  versao: Scalars['String']
  internetHabilitada: Scalars['Boolean']
  smtpConfigurado: Scalars['Boolean']
  linkInstalacaoConfigurado: Scalars['Boolean']
  treinamento: Scalars['Boolean']
  firebaseEnabled: Scalars['Boolean']
  rocksdbEnabled: Scalars['Boolean']
  uuid: Scalars['String']
  govBREnabled: Scalars['Boolean']
  isPrescricaoDigitalEnabled: Scalars['Boolean']
  versaoTransporte: Scalars['String']
  flags: Flags
  buscaCidadaoPorPropriedadesEnabled: Scalars['Boolean']
}

export type InfoCadastroEnvioInput = {
  linkInstalacao?: Maybe<Scalars['String']>
  nomeInstalacao?: Maybe<Scalars['String']>
  senhaInstalacao?: Maybe<Scalars['String']>
}

export type InformacoesObstetricasInput = {
  gestaPrevias?: Maybe<Scalars['Int']>
  abortos?: Maybe<Scalars['Int']>
  partoMenosDeUmAno?: Maybe<Scalars['Boolean']>
  partos?: Maybe<Scalars['Int']>
  partosVaginais?: Maybe<Scalars['Int']>
  partosCesareas?: Maybe<Scalars['Int']>
  partosDomiciliares?: Maybe<Scalars['Int']>
  nascidosVivos?: Maybe<Scalars['Int']>
  natimortos?: Maybe<Scalars['Int']>
  recemNascidoAbaixo?: Maybe<Scalars['Int']>
  recemNascidoAcima?: Maybe<Scalars['Int']>
  filhosVivos?: Maybe<Scalars['Int']>
  obitoAntesPrimeiraSemana?: Maybe<Scalars['Int']>
  obitoAposPrimeiraSemana?: Maybe<Scalars['Int']>
}

export type IniciarVideochamadaInput = {
  termoProfissionalAceito?: Maybe<Scalars['Boolean']>
  termoCidadaoAceito?: Maybe<Scalars['Boolean']>
  videochamadaUuid?: Maybe<Scalars['String']>
}

export type InstalacaoInput = {
  dadosInstalacao?: Maybe<ConfiguracaoDadosServidorInput>
  tipoInstalacao?: Maybe<TipoInstalacao>
  novaSenha?: Maybe<Scalars['String']>
  profissional?: Maybe<ProfissionalInput>
}

export type InstituicaoPermanenciaInput = {
  nomeInstituicaoPermanencia?: Maybe<Scalars['String']>
  possuiOutrosProfissionaisVinculados?: Maybe<Scalars['Boolean']>
  responsavelTecnico: ResponsavelTecnicoInput
}

export type InterconsultaAgendamentoCuidadoCompartilhadoInput = {
  horarioInicial: Scalars['Instant']
  horarioFinal: Scalars['Instant']
  observacoes?: Maybe<Scalars['String']>
  cidadaoPresente?: Maybe<Scalars['Boolean']>
  videochamadaUuid?: Maybe<Scalars['String']>
}

export type ItemGrupoMedicoesTipo = {
  __typename?: 'ItemGrupoMedicoesTipo'
  dataMedicao: Scalars['Instant']
  valor: Scalars['String']
  observacao?: Maybe<Scalars['String']>
}

export type JustificativaCancelamentoAgendado = {
  __typename?: 'JustificativaCancelamentoAgendado'
  id: Scalars['ID']
  justificativa: Scalars['String']
  data: Scalars['Instant']
  responsavel?: Maybe<LotacaoOuEstagio>
}

export enum JustificativaCancelarAgendamentoEnum {
  LICENCA_MEDICA = 'LICENCA_MEDICA',
  REUNIAO_NAO_PROGRAMADA = 'REUNIAO_NAO_PROGRAMADA',
  CIDADAO_CANCELOU = 'CIDADAO_CANCELOU',
  JUSTIFICATIVA_AUTOMATICA_PROFISSIONAL = 'JUSTIFICATIVA_AUTOMATICA_PROFISSIONAL',
  OUTRA = 'OUTRA',
}

export type JustificativaVisualizarProntuarioInput = {
  prontuarioId: Scalars['ID']
  justificativa: Scalars['String']
}

export type Lembrete = {
  __typename?: 'Lembrete'
  id: Scalars['ID']
  ultimoLembrete: LembreteEvolucao
  historicoLembrete: Array<Maybe<LembreteEvolucao>>
  profissionalCriador: Profissional
}

export type LembreteEvolucao = {
  __typename?: 'LembreteEvolucao'
  id: Scalars['ID']
  descricao: Scalars['String']
  ativo: Scalars['Boolean']
  lotacao: Lotacao
  data: Scalars['Instant']
  visibilidadeLembrete: VisibilidadeLembreteEnum
}

export type LembreteSaveInput = {
  id?: Maybe<Scalars['ID']>
  descricao: Scalars['String']
  visibilidadeLembrete: VisibilidadeLembreteEnum
  ativo: Scalars['Boolean']
}

export type LembretesQueryInput = {
  prontuarioId: Scalars['ID']
  somenteMeus?: Maybe<Scalars['Boolean']>
}

export type LicencaMaternidadePlanoInput = {
  id?: Maybe<Scalars['ID']>
  descricao: Scalars['String']
  cpfCns?: Maybe<Scalars['String']>
  naoPossui?: Maybe<Scalars['Boolean']>
  assinadoDigitalmente: Scalars['Boolean']
}

export type ListaEspera = {
  __typename?: 'ListaEspera'
  id?: Maybe<Scalars['ID']>
  cidadao?: Maybe<Cidadao>
  unidadeSaude?: Maybe<UnidadeSaude>
  equipe?: Maybe<Equipe>
  motivoEspera?: Maybe<Scalars['String']>
  dataEntrada?: Maybe<Scalars['Instant']>
  dataSaida?: Maybe<Scalars['Instant']>
  tipoAtendimento?: Maybe<ListaEsperaTipoAtendimentoEnum>
  tipoServico?: Maybe<TipoServico>
  qtdCidadaoPresente?: Maybe<Scalars['Int']>
  cuidadoCompartilhadoEvolucao?: Maybe<CuidadoCompartilhadoEvolucao>
}

export enum ListaEsperaMotivoSaidaEnum {
  ATENDIDO_OUTRO_ESTABELECIMENTO = 'ATENDIDO_OUTRO_ESTABELECIMENTO',
  NAO_RESPONDEU_TENTATIVAS_CONTATO = 'NAO_RESPONDEU_TENTATIVAS_CONTATO',
  MUDOU_TERRITORIO = 'MUDOU_TERRITORIO',
  ATENDIMENTO_AGENDADO = 'ATENDIMENTO_AGENDADO',
  OUTROS = 'OUTROS',
}

export enum ListaEsperaTipoAtendimentoEnum {
  CONSULTA_MEDICA = 'CONSULTA_MEDICA',
  CONSULTA_ODONTOLOGICA = 'CONSULTA_ODONTOLOGICA',
  CONSULTA_ENFERMAGEM = 'CONSULTA_ENFERMAGEM',
  CONSULTA_EMULTI = 'CONSULTA_EMULTI',
}

export type ListaMedicamento = {
  __typename?: 'ListaMedicamento'
  id: Scalars['ID']
  tipo: Scalars['String']
  tipoReceita: TipoReceitaEnum
}

export type ListaMedicamentoAtivoConcluidoQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
  dataAtendimento?: Maybe<Scalars['Instant']>
  ativo?: Maybe<Scalars['Boolean']>
  usoContinuo?: Maybe<Scalars['Boolean']>
  query?: Maybe<Scalars['String']>
  dataMinimaFimTratamento?: Maybe<Scalars['Instant']>
  pageParams?: Maybe<OptionalPageParams>
}

export type ListaMedicamentoAtivoConcluidoQueryPayload = {
  __typename?: 'ListaMedicamentoAtivoConcluidoQueryPayload'
  content: Array<Maybe<ReceitaMedicamento>>
  pageInfo: PageInfo
}

export enum ListaMedicamentoEnum {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  C5 = 'C5',
  D1 = 'D1',
  D2 = 'D2',
  ANTIMICROBIANOS = 'ANTIMICROBIANOS',
  COMUNS = 'COMUNS',
}

export type LocalAplicacao = {
  __typename?: 'LocalAplicacao'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type LocalAplicacaoQueryInput = {
  viaAdministracaoId: Scalars['ID']
  query?: Maybe<Scalars['String']>
}

export type LocalAtendimento = {
  __typename?: 'LocalAtendimento'
  id: Scalars['ID']
  localAtendimentoExibicao?: Maybe<Scalars['String']>
}

export type LocalAtendimentoPayload = {
  __typename?: 'LocalAtendimentoPayload'
  content: Array<LocalAtendimento>
  pageInfo: PageInfo
}

export type LocalAtendimentoQueryInput = {
  query?: Maybe<Scalars['String']>
  excludeUbs?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type LocalidadeCnesDesatualizadoDto = {
  __typename?: 'LocalidadeCnesDesatualizadoDto'
  instalacaoCentral?: Maybe<Scalars['String']>
  instalacaoLocal?: Maybe<Scalars['String']>
}

export type LocalOcorrencia = {
  __typename?: 'LocalOcorrencia'
  id: Scalars['ID']
  nome: Scalars['String']
  nomeFiltro: Scalars['String']
}

export type LocalOcorrenciaPayload = {
  __typename?: 'LocalOcorrenciaPayload'
  content: Array<LocalOcorrencia>
  pageInfo: PageInfo
}

export type LocalOcorrenciaQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type LoginInput = {
  username: Scalars['String']
  password: Scalars['String']
  force?: Maybe<Scalars['Boolean']>
}

export type LoginPayload = {
  __typename?: 'LoginPayload'
  success: Scalars['Boolean']
  refreshToken?: Maybe<Scalars['String']>
  dataExpiracaoRefreshToken?: Maybe<Scalars['Instant']>
}

export type Logradouro = {
  __typename?: 'Logradouro'
  id: Scalars['ID']
  nome: Scalars['String']
  nomePreposicao: Scalars['String']
  nomeExibicao: Scalars['String']
  bairro: Bairro
  tipoLogradouro: TipoLogradouro
  cep: Scalars['String']
  dne: Scalars['String']
  tituloPatente?: Maybe<TituloPatente>
}

export type LogradouroInput = {
  municipioId?: Maybe<Scalars['ID']>
  bairroId?: Maybe<Scalars['ID']>
  tipoLogradouroId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type LogradouroQueryPayload = {
  __typename?: 'LogradouroQueryPayload'
  content: Array<Maybe<Logradouro>>
  pageInfo: PageInfo
}

export type LogradourosTerritorioQueryPayload = {
  __typename?: 'LogradourosTerritorioQueryPayload'
  content: Array<LogradouroTerritorioDto>
  pageInfo: PageInfo
}

export type LogradouroTerritorioDto = {
  __typename?: 'LogradouroTerritorioDto'
  id: Scalars['String']
  logradouro?: Maybe<Scalars['String']>
  municipio?: Maybe<Municipio>
  tipoLogradouroId?: Maybe<Scalars['ID']>
  tipoLogradouroNome?: Maybe<Scalars['String']>
  tipoLogradouroDne?: Maybe<Scalars['String']>
  bairro?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  dsei?: Maybe<Dsei>
  poloBase?: Maybe<PoloBase>
  aldeia?: Maybe<Aldeia>
  municipioAldeia?: Maybe<Municipio>
  tipoEndereco: TipoEnderecoEnum
  numeroImoveis: Scalars['Int']
  numeroCidadaos: Scalars['Int']
}

export type Lotacao = Acesso & {
  __typename?: 'Lotacao'
  id: Scalars['ID']
  perfis?: Maybe<Array<Maybe<Perfil>>>
  tipo: TipoAcesso
  ativo: Scalars['Boolean']
  cbo: Cbo
  equipe?: Maybe<Equipe>
  unidadeSaude: UnidadeSaude
  importada: Scalars['Boolean']
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  municipio: Municipio
  configuracaoAgenda?: Maybe<ConfiguracaoAgendaLotacao>
  hasConfiguracaoAgenda?: Maybe<Scalars['Boolean']>
  gradeConfiguracaoAgendaOnline?: Maybe<ConfiguracaoAgendaOnlineLotacao>
  hasConfiguracaoAgendaOnline?: Maybe<Scalars['Boolean']>
  hasAgendamentoFuturo?: Maybe<Scalars['Boolean']>
  atualizaPerfil?: Maybe<Scalars['Boolean']>
  actions: LotacaoActions
}

export type LotacaoActions = BaseAcessoActions & {
  __typename?: 'LotacaoActions'
  permissaoEdicao: Action
  permissaoExclusao: Action
  permissaoAtivacaoInativacao: Action
  permissaoConfigurarAgenda: Action
  permissaoConfigurarAgendaOnline: Action
  permissaoFecharAgenda: Action
}

export type LotacaoAgendamentoConflitanteInput = {
  lotacaoId: Scalars['ID']
  horario: Scalars['Instant']
}

export type LotacaoInput = {
  ativo: Scalars['Boolean']
  cbo: Scalars['ID']
  equipe?: Maybe<Scalars['ID']>
  profissional?: Maybe<Scalars['ID']>
  unidadeSaude: Scalars['ID']
  perfis: Array<Maybe<Scalars['ID']>>
  atualizaPerfil?: Maybe<Scalars['Boolean']>
}

export type LotacaoOuEstagio = Lotacao | Estagio

export type LotacaoQueryPayload = {
  __typename?: 'LotacaoQueryPayload'
  content: Array<Maybe<Lotacao>>
  pageInfo: PageInfo
}

export type LotacoesAgendaInput = {
  query?: Maybe<Scalars['String']>
  isAgendaAd: Scalars['Boolean']
  somenteCadastrarAgendamento?: Maybe<Scalars['Boolean']>
  incluirInativasComAgendamentosFuturos?: Maybe<Scalars['Boolean']>
  cbosAcesso?: Maybe<Array<AcessoCbo>>
  excludeProfissionaisIds?: Maybe<Array<Scalars['ID']>>
  includeEquipesComVinculo?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type LotacoesAndEstagiosInput = {
  cbo?: Maybe<Scalars['String']>
  equipe?: Maybe<Scalars['String']>
  unidadeSaude?: Maybe<Scalars['String']>
  query?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  canWriteAtendimento?: Maybe<Scalars['Boolean']>
  canWriteRegistroTardio?: Maybe<Scalars['Boolean']>
  excludeProfissionaisIds?: Maybe<Array<Scalars['ID']>>
  possuiAgendaCadastrada?: Maybe<Scalars['Boolean']>
  nivelSuperior?: Maybe<Scalars['Boolean']>
  includeEstagios?: Maybe<Scalars['Boolean']>
  semEquipe?: Maybe<Scalars['Boolean']>
  maxResults?: Maybe<Scalars['Int']>
  apenasEquipeCuidadoCompartilhado?: Maybe<Scalars['Boolean']>
  equipeVinculadaId?: Maybe<Scalars['ID']>
  municipio?: Maybe<Scalars['String']>
  canWriteCadastroDomiciliarAndCadastroIndividual?: Maybe<Scalars['Boolean']>
  canWriteCidadaoTerritorioIndigena?: Maybe<Scalars['Boolean']>
}

export type LotacoesListaCuidadoCompartilhadoQueryInput = {
  isLotacoesSolicitantes: Scalars['Boolean']
  role: CuidadoCompartilhadoQueryFilterEnum
  query?: Maybe<Scalars['String']>
}

export type LoteEnvio = {
  __typename?: 'LoteEnvio'
  id: Scalars['ID']
  statusEnvio: StatusEnvio
  dataCriacao: Scalars['Instant']
  quantidadeFichas: Scalars['Int']
  nodos?: Maybe<Array<Maybe<NodoLoteEnvio>>>
  historicoExportacao: LoteHistoricoExportacaoPayload
}

export type LoteEnvioHistoricoExportacaoArgs = {
  filtro: HistoricoExportacaoFiltro
}

export type LoteHistoricoExportacao = {
  __typename?: 'LoteHistoricoExportacao'
  id: Scalars['ID']
  nomeProfissional: Scalars['String']
  dataExportacao: Scalars['Instant']
}

export type LoteHistoricoExportacaoPayload = {
  __typename?: 'LoteHistoricoExportacaoPayload'
  content: Array<Maybe<LoteHistoricoExportacao>>
  pageInfo: PageInfo
}

export type LoteImunobiologico = {
  __typename?: 'LoteImunobiologico'
  id: Scalars['ID']
  lote?: Maybe<Scalars['String']>
  fabricante?: Maybe<ImunobiologicoFabricante>
  imunobiologico?: Maybe<Imunobiologico>
  validade?: Maybe<Scalars['LocalDate']>
  ativo?: Maybe<Scalars['Boolean']>
}

export type LoteImunobiologicoInput = {
  id?: Maybe<Scalars['ID']>
  lote: Scalars['String']
  idFabricante?: Maybe<Scalars['ID']>
  nomeFabricante?: Maybe<Scalars['String']>
  idImunobiologico: Scalars['ID']
  validade: Scalars['LocalDate']
}

export type LoteImunobiologicoQueryInput = {
  pageParams?: Maybe<PageParams>
  filtroImunoFabricante?: Maybe<Scalars['String']>
  mostrarLotesInativos?: Maybe<Scalars['Boolean']>
  imunobiologicoId?: Maybe<Scalars['ID']>
}

export type LoteImunobiologicoVacinacaoInput = {
  loteNome?: Maybe<Scalars['String']>
  fabricanteNome?: Maybe<Scalars['String']>
  fabricanteId?: Maybe<Scalars['String']>
  dataValidade?: Maybe<Scalars['LocalDate']>
}

export type LotesEnvioFiltro = {
  idLote?: Maybe<Scalars['ID']>
  dataCriacaoInicio?: Maybe<Scalars['LocalDate']>
  dataCriacaoFim?: Maybe<Scalars['LocalDate']>
  statusEnvio?: Maybe<Array<Maybe<StatusEnvio>>>
  pageParams?: Maybe<PageParams>
}

export type LotesEnvioPayload = {
  __typename?: 'LotesEnvioPayload'
  content: Array<Maybe<LoteEnvio>>
  pageInfo: PageInfo
}

export type LotesImunobiologicoQueryPayload = {
  __typename?: 'LotesImunobiologicoQueryPayload'
  content: Array<Maybe<LoteImunobiologico>>
  pageInfo: PageInfo
}

export type ManifestacaoAlergia = {
  __typename?: 'ManifestacaoAlergia'
  id: Scalars['ID']
  nome: Scalars['String']
  sinonimo?: Maybe<Scalars['String']>
}

export type ManifestacaoAlergiaQueryPayload = {
  __typename?: 'ManifestacaoAlergiaQueryPayload'
  content: Array<ManifestacaoAlergia>
  pageInfo: PageInfo
}

export type ManifestacoesAlergiaQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type MarcadorConsumoAlimentar = {
  __typename?: 'MarcadorConsumoAlimentar'
  id: Scalars['ID']
  categoria: FaixaEtariaConsumoAlimentar
  respostas: Array<RespostaMarcadoresConsumoAlimentar>
}

export type MarcadoresConsumoAlimentarInput = {
  id?: Maybe<Scalars['ID']>
  faixaEtaria: FaixaEtariaConsumoAlimentar
  respostas: Array<RespostaMarcadoresConsumoAlimentarInput>
}

export type MarcoDesenvolvimento = AvaliacaoDesenvolvimento & {
  __typename?: 'MarcoDesenvolvimento'
  id: Scalars['ID']
  descricao: Scalars['String']
  status: StatusAvaliadoEnum
  dataAvaliacao?: Maybe<Scalars['LocalDate']>
  hint?: Maybe<Scalars['String']>
  alcancadoComMeses?: Maybe<Scalars['Int']>
  alcancadoComAnos?: Maybe<Scalars['Int']>
  faixaEtariaEnum?: Maybe<NeuroFaixaEtariaEnum>
  faixaEtariaInicio?: Maybe<Scalars['Int']>
  faixaEtariaFim?: Maybe<Scalars['Int']>
  descricaoFaixaEtaria?: Maybe<Scalars['String']>
}

export type MarcoDesenvolvimentoLoadInput = {
  prontuarioId: Scalars['ID']
  dataReferencia: Scalars['LocalDate']
}

export type Medicamento = {
  __typename?: 'Medicamento'
  id: Scalars['ID']
  unidadeFornecimento: Scalars['String']
  principioAtivo: Scalars['String']
  concentracao?: Maybe<Scalars['String']>
  formaFarmaceutica?: Maybe<FormaFarmaceutica>
  medicamentoCatmat?: Maybe<MedicamentoCatmat>
  principioAtivoFiltro: Scalars['String']
}

export type MedicamentoCatmat = {
  __typename?: 'MedicamentoCatmat'
  id: Scalars['ID']
  ativo: Scalars['Boolean']
  medicamento: Medicamento
  unidadeFornecimento: UnidadeMedida
  volume?: Maybe<Scalars['String']>
  principioAtivo: PrincipioAtivo
  codigoCatMat?: Maybe<Scalars['String']>
  codigoRename?: Maybe<Scalars['String']>
  unidadeMedidaDose?: Maybe<UnidadeMedida>
}

export type MedicamentoCatmatQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type MedicamentoCatmatQueryPayload = {
  __typename?: 'MedicamentoCatmatQueryPayload'
  content: Array<Maybe<MedicamentoCatmat>>
  pageInfo: PageInfo
}

export type MedicamentoInterromperInput = {
  id: Scalars['ID']
  justificativa: Scalars['String']
}

export type MedicamentoPlanoInput = {
  id?: Maybe<Scalars['ID']>
  medicamentoId?: Maybe<Scalars['ID']>
  medicamentoRegistroManual?: Maybe<RegistroManualMedicamentoInput>
  tipoReceita: TipoReceitaEnum
  viaAdministracao: Scalars['ID']
  qtDose?: Maybe<Scalars['String']>
  qtDoseManha?: Maybe<Scalars['String']>
  qtDoseTarde?: Maybe<Scalars['String']>
  qtDoseNoite?: Maybe<Scalars['String']>
  unidadeMedidaDose: Scalars['ID']
  doseUnica: Scalars['Boolean']
  tipoFrequencia?: Maybe<TipoFrequencia>
  intervaloDose?: Maybe<Scalars['Int']>
  frequenciaDose?: Maybe<Scalars['Int']>
  quantidadePeriodoFrequenciaTurno?: Maybe<Scalars['Int']>
  unidadeMedidaTempoFrequenciaTurno?: Maybe<UnidadeMedidaTempoEnum>
  turno?: Maybe<TurnoEnum>
  posologia: Scalars['String']
  dataInicioTratamento: Scalars['LocalDate']
  dataFimTratamento?: Maybe<Scalars['LocalDate']>
  duracao?: Maybe<Scalars['Int']>
  escalaDuracao?: Maybe<UnidadeMedidaTempoEnum>
  quantidade: Scalars['Int']
  usoContinuo: Scalars['Boolean']
  recomendacoes?: Maybe<Scalars['String']>
  codigoPrescricaoDigital?: Maybe<Scalars['String']>
}

export type MedicamentoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type MedicamentoQueryPayload = {
  __typename?: 'MedicamentoQueryPayload'
  content: Array<Maybe<Medicamento>>
  pageInfo: PageInfo
}

export type Medicao = {
  __typename?: 'Medicao'
  id: Scalars['ID']
  atendimentoProfissional: AtendimentoProfissional
  dataMedicao: Scalars['Instant']
  dum?: Maybe<Scalars['LocalDate']>
  valorPeso?: Maybe<Scalars['Float']>
  valorAltura?: Maybe<Scalars['Float']>
  valorFrequenciaCardiaca?: Maybe<Scalars['Int']>
  valorPressaoArterial?: Maybe<Scalars['String']>
  valorPerimetroCefalico?: Maybe<Scalars['Float']>
  valorFrequenciaRespiratoria?: Maybe<Scalars['Int']>
  valorTemperatura?: Maybe<Scalars['Float']>
  valorSaturacaoO2?: Maybe<Scalars['Int']>
  valorGlicemia?: Maybe<Scalars['Int']>
  tipoGlicemia?: Maybe<TipoGlicemia>
  valorImc?: Maybe<Scalars['Float']>
  perimetroPanturrilha?: Maybe<Scalars['Float']>
  valorCircunferenciaAbdominal?: Maybe<Scalars['Float']>
  medicaoAnterior?: Maybe<Scalars['Boolean']>
  valorVacinacaoEmDia?: Maybe<Scalars['Boolean']>
  valorAlturaUterina?: Maybe<Scalars['String']>
  valorBatimentoCardiacoFetal?: Maybe<Scalars['String']>
}

export type MedicoesInput = {
  dum?: Maybe<Scalars['LocalDate']>
  peso?: Maybe<Scalars['Float']>
  altura?: Maybe<Scalars['Float']>
  perimetroCefalico?: Maybe<Scalars['Float']>
  circunferenciaAbdominal?: Maybe<Scalars['Float']>
  perimetroPanturrilha?: Maybe<Scalars['Float']>
  pressaoArterialSistolica?: Maybe<Scalars['Int']>
  pressaoArterialDiastolica?: Maybe<Scalars['Int']>
  frequenciaRespiratoria?: Maybe<Scalars['Int']>
  frequenciaCardiaca?: Maybe<Scalars['Int']>
  temperatura?: Maybe<Scalars['Float']>
  saturacaoO2?: Maybe<Scalars['Int']>
  glicemia?: Maybe<Scalars['Int']>
  tipoGlicemia?: Maybe<TipoGlicemia>
  vacinacaoEmDia?: Maybe<Scalars['Boolean']>
  dataMedicao?: Maybe<Scalars['LocalDate']>
}

export type MedicoesQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
}

export type MesAgendaLotacao = {
  __typename?: 'MesAgendaLotacao'
  id: Scalars['ID']
  dias: Array<DiaAgendaLotacao>
}

export type MesAgendaQueryInput = {
  lotacaoId: Scalars['ID']
  mes: Scalars['Int']
  ano: Scalars['Int']
}

export type MobileAppItemError = {
  __typename?: 'MobileAppItemError'
  uuid: Scalars['String']
  error: Scalars['String']
}

export type MobileAppRequestVacinacaoInput = {
  responsavel: MobileAppResponsavelInput
  vacinacoes?: Maybe<Array<MobileAppVacinacaoInput>>
}

export type MobileAppResponsavelInput = {
  cpf: Scalars['String']
  applicationUuid?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fone?: Maybe<Scalars['String']>
  nome?: Maybe<Scalars['String']>
  versaoSistema?: Maybe<Scalars['String']>
}

export type MobileAppResponseVacinacao = {
  __typename?: 'MobileAppResponseVacinacao'
  error?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<MobileAppItemError>>>
}

export type MobileAppVacinacaoInput = {
  header: UnicaLotacaoHeaderInput
  dataHoraInicialAtendimento?: Maybe<Scalars['LocalTime']>
  dataHoraFinalAtendimento?: Maybe<Scalars['LocalTime']>
  dataHorarioInicioAtendimento?: Maybe<Scalars['LocalDateTime']>
  dataHorarioFimAtendimento?: Maybe<Scalars['LocalDateTime']>
  uuid: Scalars['String']
  versao: VersaoTransportInput
  turno: Scalars['Long']
  numProntuario?: Maybe<Scalars['String']>
  cnsCidadao?: Maybe<Scalars['String']>
  cpfCidadao?: Maybe<Scalars['String']>
  dataNascimento: Scalars['LocalDate']
  sexo: Scalars['Long']
  localAtendimento: Scalars['Long']
  viajante: Scalars['Boolean']
  comunicanteHanseniase?: Maybe<Scalars['Boolean']>
  gestante: Scalars['Boolean']
  puerpera: Scalars['Boolean']
  vacina?: Maybe<MobileAppVacinaInput>
  vacinas?: Maybe<Array<Maybe<MobileAppVacinaInput>>>
}

export type MobileAppVacinaInput = {
  imunobiologico: Scalars['Long']
  estrategia?: Maybe<Scalars['Long']>
  dose: Scalars['Long']
  lote?: Maybe<Scalars['String']>
  fabricante?: Maybe<Scalars['String']>
  grupoAtendimento?: Maybe<Scalars['Long']>
}

export type ModeloPersonalizado = {
  __typename?: 'ModeloPersonalizado'
  id: Scalars['ID']
  nome: Scalars['String']
  atorPapelCriador: Acesso
  isPublico: Scalars['Boolean']
  tipoModulo: TipoModuloEnum
  modelo: Scalars['String']
}

export type ModeloPersonalizadoRelatorioGerencial = {
  __typename?: 'ModeloPersonalizadoRelatorioGerencial'
  id: Scalars['ID']
  nome: Scalars['String']
  isPublico: Scalars['Boolean']
  tipoModulo: TipoModuloEnum
  atorPapelCriadorId: Scalars['ID']
  modelo: ModeloRelatorioGerencial
}

export type ModeloPersonalizadoRelatorioGerencialInput = {
  unidadePeriodo: UnidadePeriodoRelatorioGerencialEnum
  opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum
  ciapIds?: Maybe<Array<Scalars['ID']>>
  cidIds?: Maybe<Array<Scalars['ID']>>
  gruposCondicoesIds?: Maybe<Array<Scalars['ID']>>
  campos: Array<CampoSalvoRelatorioGerencialInput>
  aggregator: AggregatorRelatorioGerencialEnum
}

export type ModeloPersonalizadoSelect = {
  __typename?: 'ModeloPersonalizadoSelect'
  id: Scalars['ID']
  nome: Scalars['String']
  isPublico: Scalars['Boolean']
}

export type ModeloRelatorioGerencial = {
  __typename?: 'ModeloRelatorioGerencial'
  unidadePeriodo: UnidadePeriodoRelatorioGerencialEnum
  opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum
  ciaps?: Maybe<Array<Ciap>>
  cids?: Maybe<Array<Cid10>>
  gruposCondicoes?: Maybe<Array<GrupoCondicaoDto>>
  campos: Array<CampoSalvoRelatorioGerencial>
  aggregator: AggregatorRelatorioGerencialEnum
}

export enum MotivoAgendamentoReservaEnum {
  REUNIAO = 'REUNIAO',
  OUTRO = 'OUTRO',
  ATIVIDADE_COLETIVA = 'ATIVIDADE_COLETIVA',
  ATENDIMENTO_EXTERNO = 'ATENDIMENTO_EXTERNO',
}

export type MotivoConsultaInput = {
  id?: Maybe<Scalars['ID']>
  ciap: Scalars['ID']
  observacao?: Maybe<Scalars['String']>
}

export enum MotivoFechamentoEnum {
  ATESTADO = 'ATESTADO',
  CURSO = 'CURSO',
  FERIAS = 'FERIAS',
  LICENCA = 'LICENCA',
  REUNIAO = 'REUNIAO',
  OUTRO = 'OUTRO',
}

export type Municipio = {
  __typename?: 'Municipio'
  id: Scalars['ID']
  bairros: Array<Maybe<Bairro>>
  ibge?: Maybe<Scalars['String']>
  nome: Scalars['String']
  uf: Uf
}

export type MunicipioResponsavelFiltroInput = {
  query?: Maybe<Scalars['String']>
  mostrarInativos: Scalars['Boolean']
  pageParams?: Maybe<PageParams>
}

export type MunicipioResponsavelInput = {
  localidade: Scalars['ID']
  profissional: Scalars['ID']
}

export type MunicipioResponsavelQueryPayload = {
  __typename?: 'MunicipioResponsavelQueryPayload'
  content: Array<Maybe<AdministradorMunicipal>>
  pageInfo: PageInfo
}

export type MunicipioResponsavelUpdateInput = {
  id: Scalars['ID']
  ativo: Scalars['Boolean']
}

export type MunicipiosQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  ufId?: Maybe<Scalars['ID']>
  aldeiaId?: Maybe<Scalars['ID']>
  ativo?: Maybe<Scalars['Boolean']>
}

export type MunicipiosQueryPayload = {
  __typename?: 'MunicipiosQueryPayload'
  content: Array<Maybe<Municipio>>
  pageInfo: PageInfo
}

export type Mutation = {
  __typename?: 'Mutation'
  aceitarSolicitacao: Scalars['Boolean']
  aceitarTermosUso?: Maybe<Usuario>
  acessoConecteSus: Scalars['String']
  adicionarCidadaoGarantiaAcesso: Scalars['ID']
  adicionarCidadaoGarantiaAcessoCeo: Scalars['ID']
  adicionarUnidadeConsultaHorus: UnidadeSaude
  agendarConsultaGarantiaAcesso: Scalars['ID']
  alterarAtivacaoAcesso: Scalars['Boolean']
  alterarBuscaCidadaoPorPropriedades: ConfiguracaoBuscaCidadaoPorPropriedades
  alterarCadsus?: Maybe<ConfiguracaoCadsus>
  alterarConfiguracaoPrescricaoDigital: ConfiguracaoPrescricaoDigital
  alterarConfiguracaoVideochamadas: ConfiguracaoVideochamadas
  alterarDadosServidor?: Maybe<ConfiguracaoDadosServidorFormDto>
  alterarDuracaoCbo?: Maybe<CboMunicipio>
  alterarDuracaoPadraoAtendimento: DuracaoAtendimentoPadraoMunicipio
  alterarHorarioGeracaoLotesProcessamentoFichas: Scalars['LocalTime']
  alterarHorus?: Maybe<ConfiguracaoHorus>
  alterarLotacao: Lotacao
  alterarNumeroTentativasLogin?: Maybe<ConfiguracaoTentativasLogin>
  alterarPeriodoInatividade?: Maybe<ConfiguracaoPeriodoInatividade>
  alterarPeriodoRedefinicaoSenha?: Maybe<ConfiguracaoRedefinicaoSenha>
  alterarQtdRequisicoes?: Maybe<ConfiguracaoPerformance>
  alterarSenhaAtual?: Maybe<Usuario>
  alterarServidorSMTP?: Maybe<ServidorSmtp>
  alterarTipoServico?: Maybe<TipoServico>
  alterarUnidadePadraoHorus: UnidadeSaude
  alternarInternet?: Maybe<ConfiguracaoInternet>
  alternarTransmissaoLink: Scalars['ID']
  ativarAgendaOnline?: Maybe<AgendaOnline>
  ativarPerfil: Perfil
  ativarTipoServico: RlUnidadeSaudeTipoServicoIds
  atualizarContatoCidadao: ContatoCidadao
  atualizarEmailCidadao: ContatoCidadao
  atualizarEnderecoTerritorio: Scalars['Boolean']
  atualizarMunicipioResponsavel: AdministradorMunicipal
  atualizarPerfil: Perfil
  auditGerarDeclaracaoComparecimento?: Maybe<Scalars['Boolean']>
  auditImpressaoEscutaInicial?: Maybe<Scalars['ID']>
  auditaImpressaoOrientacao?: Maybe<Scalars['ID']>
  auditoriaImpressaoAtestado?: Maybe<Scalars['ID']>
  autorizarMunicipio: AdministradorMunicipal
  cadastrarEnderecoEnvio: CadastroEnderecoEnvioDto
  cancelarAgendamento: Scalars['Boolean']
  cancelarAtendimento: Atendimento
  cancelarCidadaoNaoAguardou: Atendimento
  cancelarSolicitacaoEntradaVideochamada: Scalars['Boolean']
  cancelarSolicitacaoEnvio: Scalars['Boolean']
  compartilharVideochamada: Scalars['Boolean']
  concluirConfiguracaoMunicipio: AdministradorMunicipal
  createCidadaoCadsus: Scalars['ID']
  createEstagio: Estagio
  criarLotacao: Lotacao
  criarPerfil: Perfil
  definirModuloInicial: Acesso
  definirSenhaProvisoria: Scalars['Boolean']
  deleteEstagio: Scalars['ID']
  denifinirNovoAdministrador?: Maybe<Scalars['Boolean']>
  desabilitarConexaoSMTP: Scalars['Boolean']
  desativarAgendaOnline?: Maybe<AgendaOnline>
  desativarConfiguracaoAgendaOnlineLotacao: Scalars['Boolean']
  desativarTipoServico: RlUnidadeSaudeTipoServicoIds
  disponibilizarCbo?: Maybe<CboMunicipio>
  editarAgendamentoReserva: Scalars['ID']
  editarAtendimento: Atendimento
  editarCidadaoGarantiaAcesso: Scalars['ID']
  editarModeloPersonalizadoRelatorioGerencial?: Maybe<ModeloPersonalizado>
  editarRegistroTardio: Atendimento
  encerrarMinhasVideochamadas: Scalars['Boolean']
  encerrarVideochamada: Scalars['Boolean']
  enviarAtendimentoParaRetificacao: Scalars['Boolean']
  excluirAcesso: Scalars['ID']
  excluirAtendimento: Scalars['ID']
  excluirAtestadoModelo: Scalars['ID']
  excluirCidadao: Scalars['ID']
  excluirCidadaoAtendimentoDomiciliar: Scalars['ID']
  excluirConfigAtendimentoDom: Scalars['ID']
  excluirConfiguracaoAgendaLotacao: Scalars['Boolean']
  excluirConfiguracaoCertificado: Scalars['ID']
  excluirFechamento?: Maybe<Scalars['ID']>
  excluirGrupoExame: Scalars['ID']
  excluirLotacao: Scalars['ID']
  excluirLoteImunobiologico?: Maybe<Scalars['ID']>
  excluirModeloPersonalizadoRelatorioGerencial?: Maybe<Scalars['Boolean']>
  excluirMunicipioResponsavel: Scalars['ID']
  excluirPerfil: ExcluirPerfilPayload
  excluirProfissional: Scalars['ID']
  excluirTipoServico?: Maybe<Scalars['ID']>
  excluirTransmissaoLink: Scalars['ID']
  excluirVinculacaoServico?: Maybe<Scalars['Boolean']>
  exibirNotificacaoNovidades: Usuario
  forcarRedefinirSenhas?: Maybe<ConfiguracaoRedefinicaoSenha>
  gerarChaveAtivacaoAgendaOnline: Scalars['String']
  gerarLote: Scalars['Boolean']
  inativarCredencialRecebimentoExterno: CredencialRecebimentoExterno
  inativarPerfil: Perfil
  informarCidadaoNaoAguardou: Atendimento
  informarFaltaCidadao: Scalars['Boolean']
  iniciarUnificacaoBase: Scalars['Boolean']
  iniciarVideochamada: Scalars['String']
  inserirMunicipioResponsavel: AdministradorMunicipal
  inserirTransmissaoLink: Scalars['ID']
  instalar: Scalars['Boolean']
  login: LoginPayload
  logout?: Maybe<Sessao>
  negarSolicitacao: Scalars['Boolean']
  notifyConexaoVideochamadaFalhou: Scalars['Boolean']
  notifyEntradaParticipanteVideochamada: Scalars['Boolean']
  notifySaidaDonoVideochamada: Scalars['Boolean']
  notifyVideochamadaLotada: Scalars['Boolean']
  processAuditoria?: Maybe<Scalars['Boolean']>
  processarCidadaosTerritorio: Scalars['Boolean']
  processarTerritorio: Scalars['Boolean']
  realizarAtendimentoIndividual: Atendimento
  realizarAtendimentoVacinacao: Atendimento
  realizarEscutaInicial: Atendimento
  reativarCidadao: Cidadao
  redefinirSenha: Scalars['Boolean']
  redefinirSenhaAdminInstalacao: Scalars['Boolean']
  refazLoginExpirado?: Maybe<Array<Maybe<Scalars['String']>>>
  refreshTokenLogin: LoginPayload
  removerCidadaoGarantiaAcesso: Scalars['ID']
  removerFaltaCidadao: Scalars['Boolean']
  removerProcessoAssincrono: Scalars['Boolean']
  removerTodosProcessosAssincronos: Scalars['Boolean']
  removerUnidadeConsultaHorus: UnidadeSaude
  responderPesquisaSatisfacao: Usuario
  responderSolicitacaoEntradaVideochamada: Scalars['Boolean']
  reterritorializar: Scalars['Boolean']
  retificaAtendimento: Scalars['ID']
  salvarAgendamentoConsulta: Scalars['ID']
  salvarAgendamentoConsultaCompartilhada: Scalars['ID']
  salvarAgendamentoReserva: Scalars['ID']
  salvarAtendimento: Atendimento
  salvarAtendimentoIndividual: AtendimentoProfissional
  salvarAtendimentoIndividualParaAprovacao: Scalars['Boolean']
  salvarAtendimentoVacinacao?: Maybe<AtendimentoVacinacao>
  salvarAtendimentoVacinacaoParaAprovacao: Scalars['Boolean']
  salvarAtestadoModelo: AtestadoModelo
  salvarBatchVacinacao: MobileAppResponseVacinacao
  salvarCidadao: Cidadao
  salvarCidadaoAtencaoDomiciliar: AtencaoDomiciliarCidadao
  salvarConfigAtendimentoDomTipoApoio: ConfiguracoesAtendimentosDomiciliares
  salvarConfigAtendimentoDomTipoPodeAtender: ConfiguracoesAtendimentosDomiciliares
  salvarConfiguracaoAgendaLotacao?: Maybe<Scalars['Boolean']>
  salvarConfiguracaoAgendaOnlineLotacao?: Maybe<Scalars['Boolean']>
  salvarConfiguracaoCertificado: ConfiguracaoCertificado
  salvarConfiguracaoHorariosMunicipio?: Maybe<ConfiguracaoAgendaMunicipal>
  salvarCredencialRecebimentoExterno: SalvarCredencialRecebimentoExternoPayload
  salvarCuidadoCompartilhado: CuidadoCompartilhado
  salvarDuracaoAgendamentoAtencaoDom: DuracaoAgendamentoAtencaoDomDto
  salvarEscutaInicial: AtendimentoProfissional
  salvarEscutaInicialParaAprovacao: Scalars['Boolean']
  salvarFechamento?: Maybe<FechamentoAgenda>
  salvarGrupoExame: GrupoExame
  salvarImovel: Scalars['Boolean']
  salvarJustificativaAutomaticaVisualizarEscutaInicial: Scalars['ID']
  salvarJustificativaVisualizarProntuario: Scalars['ID']
  salvarLoteImunobiologico?: Maybe<LoteImunobiologico>
  salvarModeloPersonalizadoRelatorioGerencial?: Maybe<ModeloPersonalizado>
  salvarOutroAcesso: Acesso
  salvarProfissional: Profissional
  salvarProfissionalContraChave: Profissional
  salvarRegistroTardio: Atendimento
  salvarTipoServico?: Maybe<TipoServico>
  salvarVinculacaoServico?: Maybe<Scalars['Boolean']>
  selecionarAcesso?: Maybe<Sessao>
  setStatusLoteImunobiologico?: Maybe<Scalars['ID']>
  solicitarEntradaVideochamada: ParticipanteVideochamada
  solicitarRecuperarSenha: Scalars['Boolean']
  solicitarRedefinicaoSenha: QrCode
  testarConexaoAgendaOnline: TesteConexao
  testarConexaoInternet?: Maybe<ConfiguracaoInternet>
  testarConexaoLinkTransmissao: ResultadoTesteConexao
  tornarLotacaoAtualResponsavelAtendimentoObservacao: Scalars['ID']
  tornarseExecutanteCuidadoCompartilhado: CuidadoCompartilhado
  unificaCadastros: Scalars['Boolean']
  updateEstagio: Estagio
  updateTransmissaoLink: Scalars['ID']
  validaContraChave: Scalars['Boolean']
  validarConfiguracaoCertificado: Array<Maybe<ServicosCertificadoEnum>>
  visualizarCuidadoCompartilhado: Scalars['ID']
  visualizarNovidades: Scalars['Boolean']
}

export type MutationAceitarSolicitacaoArgs = {
  id: Scalars['ID']
}

export type MutationAcessoConecteSusArgs = {
  input: AcessoConecteSusInput
}

export type MutationAdicionarCidadaoGarantiaAcessoArgs = {
  input: GarantiaAcessoItemCreateInput
}

export type MutationAdicionarCidadaoGarantiaAcessoCeoArgs = {
  input: GarantiaAcessoItemCreateInput
}

export type MutationAdicionarUnidadeConsultaHorusArgs = {
  input: UnidadeConsultaHorusIds
}

export type MutationAgendarConsultaGarantiaAcessoArgs = {
  input: GarantiaAcessoAgendarConsultaInput
}

export type MutationAlterarAtivacaoAcessoArgs = {
  id: Scalars['ID']
}

export type MutationAlterarBuscaCidadaoPorPropriedadesArgs = {
  habilitado: Scalars['Boolean']
}

export type MutationAlterarCadsusArgs = {
  alterarCadsusInput: AlterarCadsusInput
}

export type MutationAlterarConfiguracaoPrescricaoDigitalArgs = {
  input: AlterarConfiguracaoPrescricaoDigitalInput
}

export type MutationAlterarConfiguracaoVideochamadasArgs = {
  input: AlterarConfiguracaoVideochamadasInput
}

export type MutationAlterarDadosServidorArgs = {
  input: ConfiguracaoDadosServidorInput
}

export type MutationAlterarDuracaoCboArgs = {
  input: CboDuracaoFormInput
}

export type MutationAlterarDuracaoPadraoAtendimentoArgs = {
  duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput
}

export type MutationAlterarHorarioGeracaoLotesProcessamentoFichasArgs = {
  horario: Scalars['LocalTime']
}

export type MutationAlterarHorusArgs = {
  alterarHorusInput: AlterarHorusInput
}

export type MutationAlterarLotacaoArgs = {
  input: AlterarLotacaoInput
}

export type MutationAlterarNumeroTentativasLoginArgs = {
  tentativas?: Maybe<Scalars['Int']>
}

export type MutationAlterarPeriodoInatividadeArgs = {
  periodo?: Maybe<Scalars['Int']>
}

export type MutationAlterarPeriodoRedefinicaoSenhaArgs = {
  periodo?: Maybe<Scalars['Int']>
}

export type MutationAlterarQtdRequisicoesArgs = {
  qtdRequisicoesSimultaneas?: Maybe<Scalars['Int']>
}

export type MutationAlterarSenhaAtualArgs = {
  input: AlterarSenhaAtualInput
}

export type MutationAlterarServidorSmtpArgs = {
  input: ConfiguracaoSmtpInput
}

export type MutationAlterarTipoServicoArgs = {
  input: TipoServicoUpdateInput
}

export type MutationAlterarUnidadePadraoHorusArgs = {
  input: UnidadeConsultaHorusIds
}

export type MutationAlternarInternetArgs = {
  habilitado?: Maybe<Scalars['Boolean']>
}

export type MutationAlternarTransmissaoLinkArgs = {
  id: Scalars['ID']
}

export type MutationAtivarAgendaOnlineArgs = {
  ativacaoAgendaOnlineInput: AtivacaoAgendaOnlineInput
}

export type MutationAtivarPerfilArgs = {
  id: Scalars['ID']
}

export type MutationAtivarTipoServicoArgs = {
  unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput
}

export type MutationAtualizarContatoCidadaoArgs = {
  input: AtualizarContatoCidadaoInput
}

export type MutationAtualizarEmailCidadaoArgs = {
  input: AtualizarEmailCidadaoInput
}

export type MutationAtualizarEnderecoTerritorioArgs = {
  input: AtualizarEnderecoTerritorioInput
}

export type MutationAtualizarMunicipioResponsavelArgs = {
  input: MunicipioResponsavelUpdateInput
}

export type MutationAtualizarPerfilArgs = {
  input: PerfilInput
}

export type MutationAuditGerarDeclaracaoComparecimentoArgs = {
  atendimentoId: Scalars['ID']
}

export type MutationAuditImpressaoEscutaInicialArgs = {
  atendProfId: Scalars['ID']
}

export type MutationAuditaImpressaoOrientacaoArgs = {
  prontuarioId: Scalars['ID']
  atendimentoId?: Maybe<Scalars['ID']>
  orientacaoId?: Maybe<Scalars['ID']>
}

export type MutationAuditoriaImpressaoAtestadoArgs = {
  prontuarioId: Scalars['ID']
  atendimentoId?: Maybe<Scalars['ID']>
  atestadoId?: Maybe<Scalars['ID']>
}

export type MutationAutorizarMunicipioArgs = {
  input?: Maybe<AutorizarMunicipioInput>
}

export type MutationCadastrarEnderecoEnvioArgs = {
  input: InfoCadastroEnvioInput
}

export type MutationCancelarAgendamentoArgs = {
  input: CancelarAgendamentoInput
}

export type MutationCancelarAtendimentoArgs = {
  atendimento: Scalars['ID']
}

export type MutationCancelarCidadaoNaoAguardouArgs = {
  atendimento: Scalars['ID']
}

export type MutationCancelarSolicitacaoEntradaVideochamadaArgs = {
  input: CancelarSolicitacaoEntradaVideochamadaInput
}

export type MutationCancelarSolicitacaoEnvioArgs = {
  input: CancelarSolicitacaoEnvioInput
}

export type MutationCompartilharVideochamadaArgs = {
  input: CompartilharVideochamadaInput
}

export type MutationCreateCidadaoCadsusArgs = {
  input: SincronizaCidadaoCadsusInput
}

export type MutationCreateEstagioArgs = {
  input: EstagioCreateInput
}

export type MutationCriarLotacaoArgs = {
  input: LotacaoInput
}

export type MutationCriarPerfilArgs = {
  input: PerfilInput
}

export type MutationDefinirModuloInicialArgs = {
  input: SelecionarModuloInicialInput
}

export type MutationDefinirSenhaProvisoriaArgs = {
  input: DefinirSenhaProvisoriaInput
}

export type MutationDeleteEstagioArgs = {
  estagioId: Scalars['ID']
}

export type MutationDenifinirNovoAdministradorArgs = {
  input: ValidateNovoAdminInput
}

export type MutationDesativarConfiguracaoAgendaOnlineLotacaoArgs = {
  id: Scalars['ID']
}

export type MutationDesativarTipoServicoArgs = {
  unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput
}

export type MutationDisponibilizarCboArgs = {
  input: CboDisponibilizarFormInput
}

export type MutationEditarAgendamentoReservaArgs = {
  input: EditarAgendamentoReservaInput
}

export type MutationEditarAtendimentoArgs = {
  input?: Maybe<AtendimentoEditInput>
}

export type MutationEditarCidadaoGarantiaAcessoArgs = {
  input: GarantiaAcessoItemUpdateInput
}

export type MutationEditarModeloPersonalizadoRelatorioGerencialArgs = {
  input: EditarModeloRelatorioGerencialInput
}

export type MutationEditarRegistroTardioArgs = {
  input: RegistroTardioInput
}

export type MutationEncerrarVideochamadaArgs = {
  videochamadaUuid: Scalars['String']
}

export type MutationEnviarAtendimentoParaRetificacaoArgs = {
  atendimentoId: Scalars['ID']
}

export type MutationExcluirAcessoArgs = {
  id: Scalars['ID']
}

export type MutationExcluirAtendimentoArgs = {
  atendimento: Scalars['ID']
}

export type MutationExcluirAtestadoModeloArgs = {
  idModelo: Scalars['ID']
}

export type MutationExcluirCidadaoArgs = {
  cidadaoId: Scalars['ID']
}

export type MutationExcluirCidadaoAtendimentoDomiciliarArgs = {
  id: Scalars['ID']
}

export type MutationExcluirConfigAtendimentoDomArgs = {
  idConfig: Scalars['ID']
}

export type MutationExcluirConfiguracaoAgendaLotacaoArgs = {
  id: Scalars['ID']
}

export type MutationExcluirConfiguracaoCertificadoArgs = {
  configuracaoCertificadoId: Scalars['ID']
}

export type MutationExcluirFechamentoArgs = {
  id: Scalars['ID']
}

export type MutationExcluirGrupoExameArgs = {
  id: Scalars['ID']
}

export type MutationExcluirLotacaoArgs = {
  lotacaoId: Scalars['ID']
}

export type MutationExcluirLoteImunobiologicoArgs = {
  idLote: Scalars['ID']
}

export type MutationExcluirModeloPersonalizadoRelatorioGerencialArgs = {
  input: ExcluirModeloRelatorioGerencialInput
}

export type MutationExcluirMunicipioResponsavelArgs = {
  id: Scalars['ID']
}

export type MutationExcluirPerfilArgs = {
  id: Scalars['ID']
}

export type MutationExcluirProfissionalArgs = {
  profissionalId: Scalars['ID']
}

export type MutationExcluirTipoServicoArgs = {
  id: Scalars['ID']
}

export type MutationExcluirTransmissaoLinkArgs = {
  id: Scalars['ID']
}

export type MutationExcluirVinculacaoServicoArgs = {
  referenciaId: Scalars['Long']
}

export type MutationInativarCredencialRecebimentoExternoArgs = {
  id: Scalars['ID']
}

export type MutationInativarPerfilArgs = {
  id: Scalars['ID']
}

export type MutationInformarCidadaoNaoAguardouArgs = {
  atendimento: Scalars['ID']
}

export type MutationInformarFaltaCidadaoArgs = {
  agendamentoId: Scalars['ID']
}

export type MutationIniciarVideochamadaArgs = {
  input: IniciarVideochamadaInput
}

export type MutationInserirMunicipioResponsavelArgs = {
  input: MunicipioResponsavelInput
}

export type MutationInserirTransmissaoLinkArgs = {
  form: TransmissaoLinkInsertDto
}

export type MutationInstalarArgs = {
  input: InstalacaoInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationNegarSolicitacaoArgs = {
  id: Scalars['ID']
}

export type MutationNotifyConexaoVideochamadaFalhouArgs = {
  videochamadaUuid: Scalars['String']
}

export type MutationNotifyEntradaParticipanteVideochamadaArgs = {
  videochamadaUuid: Scalars['String']
  participanteId: Scalars['ID']
  nomeParticipante: Scalars['String']
}

export type MutationNotifySaidaDonoVideochamadaArgs = {
  videochamadaUuid: Scalars['String']
}

export type MutationNotifyVideochamadaLotadaArgs = {
  videochamadaUuid: Scalars['String']
  lotada: Scalars['Boolean']
}

export type MutationProcessAuditoriaArgs = {
  input: AuditoriaInput
}

export type MutationProcessarCidadaosTerritorioArgs = {
  cidadaosIds: Array<Scalars['ID']>
}

export type MutationProcessarTerritorioArgs = {
  imovelId: Scalars['ID']
}

export type MutationRealizarAtendimentoIndividualArgs = {
  atendimento: Scalars['ID']
}

export type MutationRealizarAtendimentoVacinacaoArgs = {
  atendimento: Scalars['ID']
}

export type MutationRealizarEscutaInicialArgs = {
  atendimento: Scalars['ID']
}

export type MutationReativarCidadaoArgs = {
  cidadaoId: Scalars['ID']
}

export type MutationRedefinirSenhaArgs = {
  input: RedefinirSenhaInput
}

export type MutationRedefinirSenhaAdminInstalacaoArgs = {
  input: RedefinirSenhaAdminInstalacaoInput
}

export type MutationRefazLoginExpiradoArgs = {
  senha: Scalars['String']
}

export type MutationRefreshTokenLoginArgs = {
  token: Scalars['String']
}

export type MutationRemoverCidadaoGarantiaAcessoArgs = {
  input: GarantiaAcessoItemDeleteInput
}

export type MutationRemoverFaltaCidadaoArgs = {
  agendamentoId: Scalars['ID']
}

export type MutationRemoverProcessoAssincronoArgs = {
  asyncProcessingName?: Maybe<AsyncProcessingNameEnum>
}

export type MutationRemoverUnidadeConsultaHorusArgs = {
  input: UnidadeConsultaHorusIds
}

export type MutationResponderPesquisaSatisfacaoArgs = {
  input: RespostaPesquisa
}

export type MutationResponderSolicitacaoEntradaVideochamadaArgs = {
  input: ResponderSolicitacaoEntradaVideochamadaInput
}

export type MutationReterritorializarArgs = {
  input: ReterritorializarInput
}

export type MutationRetificaAtendimentoArgs = {
  atendProfId: Scalars['ID']
}

export type MutationSalvarAgendamentoConsultaArgs = {
  input: AgendamentoConsultaInput
}

export type MutationSalvarAgendamentoConsultaCompartilhadaArgs = {
  input: AgendamentoConsultaCompartilhadaInput
}

export type MutationSalvarAgendamentoReservaArgs = {
  input: AgendamentoReservaInput
}

export type MutationSalvarAtendimentoArgs = {
  input: AtendimentoInput
}

export type MutationSalvarAtendimentoIndividualArgs = {
  input: AtendimentoIndividualInput
}

export type MutationSalvarAtendimentoIndividualParaAprovacaoArgs = {
  input: AtendimentoIndividualAprovacaoInput
}

export type MutationSalvarAtendimentoVacinacaoArgs = {
  input: AtendimentoVacinacaoInput
}

export type MutationSalvarAtendimentoVacinacaoParaAprovacaoArgs = {
  input: AtendimentoVacinacaoAprovacaoInput
}

export type MutationSalvarAtestadoModeloArgs = {
  input: AtestadoModeloInput
}

export type MutationSalvarBatchVacinacaoArgs = {
  input: MobileAppRequestVacinacaoInput
}

export type MutationSalvarCidadaoArgs = {
  input: CidadaoInput
}

export type MutationSalvarCidadaoAtencaoDomiciliarArgs = {
  input: AtencaoDomiciliarCidadaoInput
}

export type MutationSalvarConfigAtendimentoDomTipoApoioArgs = {
  input: ConfigAtendimentoDomInput
}

export type MutationSalvarConfigAtendimentoDomTipoPodeAtenderArgs = {
  input: ConfigAtendimentoDomInput
}

export type MutationSalvarConfiguracaoAgendaLotacaoArgs = {
  configuracaoAgendaLotacaoInput?: Maybe<ConfiguracaoAgendaLotacaoInput>
}

export type MutationSalvarConfiguracaoAgendaOnlineLotacaoArgs = {
  configuracaoAgendaOnlineLotacaoInput?: Maybe<ConfiguracaoAgendaOnlineLotacaoInput>
}

export type MutationSalvarConfiguracaoCertificadoArgs = {
  configuracaoCertificadoInput: ConfiguracaoCertificadoInput
}

export type MutationSalvarConfiguracaoHorariosMunicipioArgs = {
  configuracaoAgendaMunicipalInput?: Maybe<ConfiguracaoAgendaMunicipalInput>
}

export type MutationSalvarCredencialRecebimentoExternoArgs = {
  input: CredencialRecebimentoExternoInput
}

export type MutationSalvarCuidadoCompartilhadoArgs = {
  input: SalvarCuidadoCompartilhadoInput
}

export type MutationSalvarDuracaoAgendamentoAtencaoDomArgs = {
  input: DuracaoAgendamentoAtencaoDomInput
}

export type MutationSalvarEscutaInicialArgs = {
  input: EscutaInicialInput
}

export type MutationSalvarEscutaInicialParaAprovacaoArgs = {
  input: EscutaInicialAprovacaoInput
}

export type MutationSalvarFechamentoArgs = {
  input: FechamentoAgendaInput
}

export type MutationSalvarGrupoExameArgs = {
  input: GrupoExameInput
}

export type MutationSalvarImovelArgs = {
  input: ImovelInput
}

export type MutationSalvarJustificativaAutomaticaVisualizarEscutaInicialArgs = {
  prontuarioId: Scalars['ID']
}

export type MutationSalvarJustificativaVisualizarProntuarioArgs = {
  input: JustificativaVisualizarProntuarioInput
}

export type MutationSalvarLoteImunobiologicoArgs = {
  input: LoteImunobiologicoInput
}

export type MutationSalvarModeloPersonalizadoRelatorioGerencialArgs = {
  input: SalvarModeloRelatorioGerencialInput
}

export type MutationSalvarOutroAcessoArgs = {
  input: OutroAcessoInput
}

export type MutationSalvarProfissionalArgs = {
  input: ProfissionalInput
}

export type MutationSalvarProfissionalContraChaveArgs = {
  input: ProfissionalContraChaveInput
}

export type MutationSalvarRegistroTardioArgs = {
  input: RegistroTardioInput
}

export type MutationSalvarTipoServicoArgs = {
  input: TipoServicoInput
}

export type MutationSalvarVinculacaoServicoArgs = {
  input: SalvarVinculacaoServicoInput
}

export type MutationSelecionarAcessoArgs = {
  input: SelecionarAcessoInput
}

export type MutationSetStatusLoteImunobiologicoArgs = {
  input: LoteImunobiologicoInput
  status?: Maybe<Scalars['Boolean']>
}

export type MutationSolicitarEntradaVideochamadaArgs = {
  input: SolicitarEntradaVideochamadaInput
}

export type MutationSolicitarRecuperarSenhaArgs = {
  input?: Maybe<SolicitarRecuperacaoSenhaInput>
}

export type MutationSolicitarRedefinicaoSenhaArgs = {
  input: SolicitarRecuperacaoSenhaInput
}

export type MutationTestarConexaoLinkTransmissaoArgs = {
  id: Scalars['ID']
}

export type MutationTornarLotacaoAtualResponsavelAtendimentoObservacaoArgs = {
  atendimentoId: Scalars['ID']
}

export type MutationTornarseExecutanteCuidadoCompartilhadoArgs = {
  id: Scalars['ID']
}

export type MutationUnificaCadastrosArgs = {
  input: UnificacaoCadastroInput
}

export type MutationUpdateEstagioArgs = {
  input: EstagioUpdateInput
}

export type MutationUpdateTransmissaoLinkArgs = {
  form: TransmissaoLinkUpdateDto
}

export type MutationValidaContraChaveArgs = {
  input?: Maybe<AutorizarMunicipioInput>
}

export type MutationValidarConfiguracaoCertificadoArgs = {
  configuracaoCertificadoInput: ConfiguracaoCertificadoInput
}

export type MutationVisualizarCuidadoCompartilhadoArgs = {
  input: VisualizarCuidadoCompartilhadoInput
}

export type Nacionalidade = {
  __typename?: 'Nacionalidade'
  id: Scalars['ID']
  nome: Scalars['String']
  nacionalidadeDbEnum: NacionalidadeEnum
}

export enum NacionalidadeEnum {
  BRASILEIRA = 'BRASILEIRA',
  NATURALIZADA = 'NATURALIZADA',
  ESTRANGEIRA = 'ESTRANGEIRA',
}

export type NeuroAlteracaoFenotipicaDetalhe = {
  __typename?: 'NeuroAlteracaoFenotipicaDetalhe'
  id: Scalars['ID']
  descricao?: Maybe<Scalars['String']>
}

export type NeuroAlteracaoFenotipicaEvolucao = {
  __typename?: 'NeuroAlteracaoFenotipicaEvolucao'
  id: Scalars['ID']
  statusAvaliacao?: Maybe<StatusAvaliadoEnum>
  alteracaoFenotipicaDetalhe?: Maybe<NeuroAlteracaoFenotipicaDetalhe>
}

export type NeuroFaixaEtaria = {
  __typename?: 'NeuroFaixaEtaria'
  id: Scalars['ID']
  descricao?: Maybe<Scalars['String']>
}

export enum NeuroFaixaEtariaEnum {
  PRIMEIROS_15_DIAS = 'PRIMEIROS_15_DIAS',
  MES_1 = 'MES_1',
  MES_2 = 'MES_2',
  MES_3_AO_4 = 'MES_3_AO_4',
  MES_5_AO_6 = 'MES_5_AO_6',
  MES_7_AO_9 = 'MES_7_AO_9',
  MES_10_AO_12 = 'MES_10_AO_12',
  MES_13_AO_15 = 'MES_13_AO_15',
  MES_16_AO_18 = 'MES_16_AO_18',
  MES_19_AO_24 = 'MES_19_AO_24',
  MES_25_AO_30 = 'MES_25_AO_30',
  MES_31_AO_36 = 'MES_31_AO_36',
  MES_37_AO_42 = 'MES_37_AO_42',
  MES_43_AO_48 = 'MES_43_AO_48',
  MES_49_AO_54 = 'MES_49_AO_54',
  MES_55_AO_60 = 'MES_55_AO_60',
  MES_61_AO_66 = 'MES_61_AO_66',
  MES_67_AO_72 = 'MES_67_AO_72',
}

export type NeuroFatorRiscoDetalhe = {
  __typename?: 'NeuroFatorRiscoDetalhe'
  id: Scalars['ID']
  descricao?: Maybe<Scalars['String']>
}

export type NeuroFatorRiscoEvolucao = {
  __typename?: 'NeuroFatorRiscoEvolucao'
  id: Scalars['ID']
  statusAvaliacao?: Maybe<StatusAvaliadoEnum>
  fatorRiscoDetalhe?: Maybe<NeuroFatorRiscoDetalhe>
}

export type NeuroMarcoDetalhe = {
  __typename?: 'NeuroMarcoDetalhe'
  id: Scalars['ID']
  titulo?: Maybe<Scalars['String']>
  faixaEtaria?: Maybe<NeuroFaixaEtaria>
}

export type NeuroMarcoEvolucao = {
  __typename?: 'NeuroMarcoEvolucao'
  id: Scalars['ID']
  statusAvaliacao?: Maybe<StatusAvaliadoEnum>
  marcoDetalhe?: Maybe<NeuroMarcoDetalhe>
  anosIdadeRegistro?: Maybe<Scalars['Int']>
  mesesIdadeRegistro?: Maybe<Scalars['Int']>
}

export enum NivelDetalheSerieHistoricaEnum {
  MUNICIPIO = 'MUNICIPIO',
  UNIDADE_SAUDE = 'UNIDADE_SAUDE',
  EQUIPE = 'EQUIPE',
  PROFISSIONAL = 'PROFISSIONAL',
  CATEGORIA_PROFISSIONAL = 'CATEGORIA_PROFISSIONAL',
}

export enum NivelProducaoEnum {
  MINHAS_PRODUCOES = 'MINHAS_PRODUCOES',
  MINHA_EQUIPE = 'MINHA_EQUIPE',
}

export type NodoLoteEnvio = {
  __typename?: 'NodoLoteEnvio'
  id?: Maybe<Scalars['ID']>
  nodeNome: Scalars['String']
  statusEnvio: StatusEnvio
  dataEnvio?: Maybe<Scalars['Instant']>
}

export type NomesInstalacoesUnificacaoDto = {
  __typename?: 'NomesInstalacoesUnificacaoDto'
  nomeInstalacaoCentral?: Maybe<Scalars['String']>
  nomesInstalacoesUnificadas?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NomesProfissionaisAgendamentosConflitantes = {
  __typename?: 'NomesProfissionaisAgendamentosConflitantes'
  horarioAgendamentoConflitante: Scalars['Instant']
  nomesProfissionais: Array<Scalars['String']>
}

export type ObjetivoInput = {
  texto?: Maybe<Scalars['String']>
  medicoes?: Maybe<MedicoesInput>
  marcadoresConsumoAlimentar?: Maybe<MarcadoresConsumoAlimentarInput>
  necessidadesEspeciais?: Maybe<Scalars['Boolean']>
  puericultura?: Maybe<PuericulturaInput>
  resultadosExame?: Maybe<Array<ResultadoExameInput>>
}

export type Odontograma = {
  __typename?: 'Odontograma'
  id: Scalars['ID']
  evolucoesDente?: Maybe<Array<Maybe<EvolucaoDente>>>
  atendimentoProfissionalOdonto: AtendimentoProfissionalOdonto
  proteseTotalInferior?: Maybe<Scalars['Boolean']>
  proteseTotalSuperior?: Maybe<Scalars['Boolean']>
  contencao?: Maybe<Scalars['Boolean']>
  aparelho?: Maybe<Scalars['Boolean']>
}

export type OdontogramasPayload = {
  __typename?: 'OdontogramasPayload'
  content: Array<Maybe<Odontograma>>
  pageInfo: PageInfo
}

export type OdontogramasQueryInput = {
  prontuarioId?: Maybe<Scalars['ID']>
  pageParams?: Maybe<PageParams>
}

export type OdontologiaInput = {
  proteseTotalSuperior?: Maybe<Scalars['Boolean']>
  proteseTotalInferior?: Maybe<Scalars['Boolean']>
  contencao?: Maybe<Scalars['Boolean']>
  aparelho?: Maybe<Scalars['Boolean']>
  evolucoesDente?: Maybe<Array<Maybe<EvolucaoDenteInput>>>
  evolucoesProcedimentoOdonto?: Maybe<Array<Maybe<EvolucaoProcedimentoOdontoInput>>>
  periodontia?: Maybe<PeriodontiaInput>
  tecidosMoles?: Maybe<Array<Maybe<TecidoMoleInput>>>
}

export enum OpcaoSelecionadaRelatorioGerencialEnum {
  PRIMEIRA = 'PRIMEIRA',
  SEGUNDA = 'SEGUNDA',
  TERCEIRA = 'TERCEIRA',
  OUTRO = 'OUTRO',
}

export enum OpcaoTipoPerguntaEnum {
  SIM = 'SIM',
  NAO = 'NAO',
  NAO_SABE = 'NAO_SABE',
  UMA_VEZ = 'UMA_VEZ',
  DUAS_VEZES = 'DUAS_VEZES',
  TRES_VEZES_OU_MAIS = 'TRES_VEZES_OU_MAIS',
  EM_PEDACOS = 'EM_PEDACOS',
  AMASSADA = 'AMASSADA',
  PASSADA_NA_PENEIRA = 'PASSADA_NA_PENEIRA',
  LIQUIDIFICADA = 'LIQUIDIFICADA',
  SO_O_CALDO = 'SO_O_CALDO',
  CAFE_DA_MANHA = 'CAFE_DA_MANHA',
  LANCHE_DA_MANHA = 'LANCHE_DA_MANHA',
  ALMOCO = 'ALMOCO',
  LANCHE_DA_TARDE = 'LANCHE_DA_TARDE',
  JANTAR = 'JANTAR',
  CEIA = 'CEIA',
}

export type OptionalPageParams = {
  page?: Maybe<PageParams>
  unpaged?: Maybe<Scalars['Boolean']>
}

export enum OrdemListaAtendimentoEnum {
  ORDEM_CHEGADA_CRESCENTE = 'ORDEM_CHEGADA_CRESCENTE',
  ORDEM_CHEGADA_DECRESCENTE = 'ORDEM_CHEGADA_DECRESCENTE',
  CLASSIFICACAO_RISCO = 'CLASSIFICACAO_RISCO',
}

export type Orientacao = {
  __typename?: 'Orientacao'
  id: Scalars['ID']
  atendimentoProfissional: AtendimentoProfissional
  descricao: Scalars['String']
}

export type OrientacaoPlanoInput = {
  id?: Maybe<Scalars['ID']>
  descricao: Scalars['String']
}

export type OrientacaoQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
}

export enum OrientacaoSexualEnum {
  HETEROSSEXUAL = 'HETEROSSEXUAL',
  HOMOSSEXUAL = 'HOMOSSEXUAL',
  GAY = 'GAY',
  LESBICA = 'LESBICA',
  ASSEXUAL = 'ASSEXUAL',
  PANSEXUAL = 'PANSEXUAL',
  BISSEXUAL = 'BISSEXUAL',
  OUTRO_ORIENTACAO_SEXUAL = 'OUTRO_ORIENTACAO_SEXUAL',
}

export enum OrigemAgendamentoEnum {
  PEC = 'PEC',
  ATENCAO_DOMICILIAR = 'ATENCAO_DOMICILIAR',
  MEUDIGISUS = 'MEUDIGISUS',
  PEC_AUTOMATICO = 'PEC_AUTOMATICO',
}

export enum OrigemAtendimento {
  PEC = 'PEC',
  CDS = 'CDS',
  RES = 'RES',
  SISTEMA_TERCEIRO = 'SISTEMA_TERCEIRO',
  RAS_PEC = 'RAS_PEC',
  AC = 'AC',
  AD = 'AD',
  TERRITORIO = 'TERRITORIO',
  APP_VACINACAO = 'APP_VACINACAO',
}

export type OutroAcessoInput = {
  id?: Maybe<Scalars['ID']>
  profissional: Scalars['ID']
  tipo: TipoAcesso
  perfis: Array<Scalars['ID']>
  municipio?: Maybe<Scalars['ID']>
  uf?: Maybe<Scalars['ID']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  first: Scalars['Boolean']
  last: Scalars['Boolean']
  number: Scalars['Int']
  numberOfElements: Scalars['Int']
  size: Scalars['Int']
  totalPages: Scalars['Int']
  totalElements: Scalars['Int']
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PageParams = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
  fetchPageInfo?: Maybe<Scalars['Boolean']>
}

export type Pais = {
  __typename?: 'Pais'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type PaisQueryInput = {
  query?: Maybe<Scalars['String']>
  omiteBrasil?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type PaisQueryPayload = {
  __typename?: 'PaisQueryPayload'
  content: Array<Maybe<Pais>>
  pageInfo: PageInfo
}

export type ParteBucal = {
  __typename?: 'ParteBucal'
  id: Scalars['ID']
  tipoParteBucal: TipoParteBucalEnum
  descricao?: Maybe<Scalars['String']>
  parteBucalEnum: ParteBucalEnum
}

export enum ParteBucalEnum {
  ARCADA_SUPERIOR = 'ARCADA_SUPERIOR',
  ARCADA_INFERIOR = 'ARCADA_INFERIOR',
  SEXTANTE_S1 = 'SEXTANTE_S1',
  SEXTANTE_S2 = 'SEXTANTE_S2',
  SEXTANTE_S3 = 'SEXTANTE_S3',
  SEXTANTE_S4 = 'SEXTANTE_S4',
  SEXTANTE_S5 = 'SEXTANTE_S5',
  SEXTANTE_S6 = 'SEXTANTE_S6',
  DENTE_14 = 'DENTE_14',
  DENTE_15 = 'DENTE_15',
  DENTE_16 = 'DENTE_16',
  DENTE_17 = 'DENTE_17',
  DENTE_18 = 'DENTE_18',
  DENTE_13 = 'DENTE_13',
  DENTE_12 = 'DENTE_12',
  DENTE_11 = 'DENTE_11',
  DENTE_21 = 'DENTE_21',
  DENTE_22 = 'DENTE_22',
  DENTE_23 = 'DENTE_23',
  DENTE_24 = 'DENTE_24',
  DENTE_25 = 'DENTE_25',
  DENTE_26 = 'DENTE_26',
  DENTE_27 = 'DENTE_27',
  DENTE_28 = 'DENTE_28',
  DENTE_48 = 'DENTE_48',
  DENTE_47 = 'DENTE_47',
  DENTE_46 = 'DENTE_46',
  DENTE_45 = 'DENTE_45',
  DENTE_44 = 'DENTE_44',
  DENTE_43 = 'DENTE_43',
  DENTE_42 = 'DENTE_42',
  DENTE_41 = 'DENTE_41',
  DENTE_31 = 'DENTE_31',
  DENTE_32 = 'DENTE_32',
  DENTE_33 = 'DENTE_33',
  DENTE_34 = 'DENTE_34',
  DENTE_35 = 'DENTE_35',
  DENTE_36 = 'DENTE_36',
  DENTE_37 = 'DENTE_37',
  DENTE_38 = 'DENTE_38',
  DENTE_51 = 'DENTE_51',
  DENTE_52 = 'DENTE_52',
  DENTE_53 = 'DENTE_53',
  DENTE_54 = 'DENTE_54',
  DENTE_55 = 'DENTE_55',
  DENTE_61 = 'DENTE_61',
  DENTE_62 = 'DENTE_62',
  DENTE_63 = 'DENTE_63',
  DENTE_64 = 'DENTE_64',
  DENTE_65 = 'DENTE_65',
  DENTE_71 = 'DENTE_71',
  DENTE_72 = 'DENTE_72',
  DENTE_73 = 'DENTE_73',
  DENTE_74 = 'DENTE_74',
  DENTE_75 = 'DENTE_75',
  DENTE_81 = 'DENTE_81',
  DENTE_82 = 'DENTE_82',
  DENTE_83 = 'DENTE_83',
  DENTE_84 = 'DENTE_84',
  DENTE_85 = 'DENTE_85',
  DENTE_SUPRANUMERARIO = 'DENTE_SUPRANUMERARIO',
  BOCA = 'BOCA',
  ARTICULACAO_TEMPORO_MANDIBULAS = 'ARTICULACAO_TEMPORO_MANDIBULAS',
  MAXILA = 'MAXILA',
  MANDIBULA = 'MANDIBULA',
  AMIDALA = 'AMIDALA',
  LINGUA = 'LINGUA',
  LABIO_SUPERIOR = 'LABIO_SUPERIOR',
  LABIO_INFERIOR = 'LABIO_INFERIOR',
  PALATO_DURO = 'PALATO_DURO',
  PALATO_MOLE = 'PALATO_MOLE',
  REGIAO_RETROMOLAR = 'REGIAO_RETROMOLAR',
  UVULA = 'UVULA',
  MUCOSA_ALVEOLAR = 'MUCOSA_ALVEOLAR',
  MUCOSA_JUGAL = 'MUCOSA_JUGAL',
  GENGIVA = 'GENGIVA',
  ASSOALHO_BUCAL = 'ASSOALHO_BUCAL',
  COMISSURA_LABIAL = 'COMISSURA_LABIAL',
  FRENULO_LINGUAL = 'FRENULO_LINGUAL',
  FRENULO_LABIAL = 'FRENULO_LABIAL',
  GLANDULAS_SALIVARES = 'GLANDULAS_SALIVARES',
  OUTROS = 'OUTROS',
}

export type PartesBucaisPayload = {
  __typename?: 'PartesBucaisPayload'
  content: Array<Maybe<ParteBucal>>
  pageInfo: PageInfo
}

export type PartesBucaisQueryInput = {
  tiposPartesBucais?: Maybe<Array<Maybe<TipoParteBucalEnum>>>
  procedimentos?: Maybe<Array<Maybe<Scalars['ID']>>>
  pageParams?: Maybe<OptionalPageParams>
}

export type ParticipanteVideochamada = {
  __typename?: 'ParticipanteVideochamada'
  id: Scalars['ID']
  nome: Scalars['String']
  cpf: Scalars['String']
}

export type PedidoEnvioBase = {
  __typename?: 'PedidoEnvioBase'
  id?: Maybe<Scalars['ID']>
  nomeInstalacao: Scalars['String']
  chaveAutenticacao?: Maybe<Scalars['String']>
  linkInstalacao: Scalars['String']
  status: StatusPedidoEnvioEnum
  dataSolicitacao: Scalars['Instant']
  dataFimUnificacao?: Maybe<Scalars['Instant']>
  loginAtivo: Scalars['Boolean']
}

export type Perfil = {
  __typename?: 'Perfil'
  id: Scalars['ID']
  nome: Scalars['String']
  ativo: Scalars['Boolean']
  tipoPerfil: TipoAcesso
  recursos: Array<Maybe<Scalars['String']>>
  perfilPadrao?: Maybe<Scalars['String']>
  actions: PerfilActions
}

export type PerfilActions = {
  __typename?: 'PerfilActions'
  excluir: Action
}

export type PerfilInput = {
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
  tipoPerfil: TipoAcesso
  recursos?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PerfisQueryInput = {
  tipoAcesso?: Maybe<TipoAcesso>
  query?: Maybe<Scalars['String']>
  mostrarInativos?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type PerfisQueryPayload = {
  __typename?: 'PerfisQueryPayload'
  content: Array<Maybe<Perfil>>
  pageInfo: PageInfo
}

export type PerguntaCuidadoCompartilhadoInput = {
  pergunta: Scalars['String']
}

export enum PerguntaSaidaCadastroEnum {
  SAIDA_CIDADAO_MUDANCA_TERRITORIO = 'SAIDA_CIDADAO_MUDANCA_TERRITORIO',
  SAIDA_CIDADAO_OBITO = 'SAIDA_CIDADAO_OBITO',
}

export enum PerguntasQuestionarioEnum {
  CRIANCA_ONTEM_TOMOU_LEITE_PEITO = 'CRIANCA_ONTEM_TOMOU_LEITE_PEITO',
  MINGAU = 'MINGAU',
  AGUA_CHA = 'AGUA_CHA',
  LEITE_VACA = 'LEITE_VACA',
  FORMULA_INFANTIL = 'FORMULA_INFANTIL',
  SUCO_FRUTA = 'SUCO_FRUTA',
  FRUTA = 'FRUTA',
  COMIDA_SAL = 'COMIDA_SAL',
  OUTROS_ALIMENTOS_BEBIDAS = 'OUTROS_ALIMENTOS_BEBIDAS',
  VOCE_COSTUME_REALIZAR_REFEICOES_ASSISTINDO_TV = 'VOCE_COSTUME_REALIZAR_REFEICOES_ASSISTINDO_TV',
  QUAIS_REFEICOES_VOCE_FEZ_LONGO_DIA = 'QUAIS_REFEICOES_VOCE_FEZ_LONGO_DIA',
  FEIJAO_MAIOR_2ANOS = 'FEIJAO_MAIOR_2ANOS',
  FRUTAS_FRESCAS = 'FRUTAS_FRESCAS',
  VERDURAS_LEGUMES = 'VERDURAS_LEGUMES',
  HAMBURGUER_E_OU_EMBUTIDOS_MAIOR_2ANOS = 'HAMBURGUER_E_OU_EMBUTIDOS_MAIOR_2ANOS',
  BEBIDAS_ADOCADAS_MAIOR_2ANOS = 'BEBIDAS_ADOCADAS_MAIOR_2ANOS',
  MACARRAO_INSTANTANEO_SALGADINHO_BISCOITO_MAIOR_2ANOS = 'MACARRAO_INSTANTANEO_SALGADINHO_BISCOITO_MAIOR_2ANOS',
  BISCOITO_RECHEADO_DOCES_GULOSEIMAS_MAIOR_2ANOS = 'BISCOITO_RECHEADO_DOCES_GULOSEIMAS_MAIOR_2ANOS',
  CRIANCA_ONTEM_TOMOU_LEITE_PEITO_2 = 'CRIANCA_ONTEM_TOMOU_LEITE_PEITO_2',
  ONTEM_CRIANCA_COMEU_FRUTA_INTEIRA_PEDACO_AMASSADA = 'ONTEM_CRIANCA_COMEU_FRUTA_INTEIRA_PEDACO_AMASSADA',
  SE_SIM_QUANTAS_VEZES_FRUTA = 'SE_SIM_QUANTAS_VEZES_FRUTA',
  ONTEM_CRIANCA_COMEU_COMIDA_SAL = 'ONTEM_CRIANCA_COMEU_COMIDA_SAL',
  SE_SIM_QUANTAS_VEZES_COMIDA_SAL = 'SE_SIM_QUANTAS_VEZES_COMIDA_SAL',
  SE_SIM_ESSA_COMIDA_FOI_OFERECIDA = 'SE_SIM_ESSA_COMIDA_FOI_OFERECIDA',
  OUTRO_LEITE_NAO_LEITE_PEITO = 'OUTRO_LEITE_NAO_LEITE_PEITO',
  MINGAU_COM_LEITE = 'MINGAU_COM_LEITE',
  IOGURTE = 'IOGURTE',
  LEGUMES = 'LEGUMES',
  VEGETAL_OU_FRUTA_ALARANJADA = 'VEGETAL_OU_FRUTA_ALARANJADA',
  VERDURA_FOLHA = 'VERDURA_FOLHA',
  CARNE = 'CARNE',
  FIGADO = 'FIGADO',
  FEIJAO_6MESES_2ANOS = 'FEIJAO_6MESES_2ANOS',
  ARROZ_BATATA_INHAME_AIPIM = 'ARROZ_BATATA_INHAME_AIPIM',
  HAMBURGUER_E_OU_EMBUTIDOS_6MESES_2ANOS = 'HAMBURGUER_E_OU_EMBUTIDOS_6MESES_2ANOS',
  BEBIDAS_ADOCADAS_6MESES_2ANOS = 'BEBIDAS_ADOCADAS_6MESES_2ANOS',
  MACARRAO_INSTANTANEO_SALGADINHOS_PACOTES_6MESES_2ANOS = 'MACARRAO_INSTANTANEO_SALGADINHOS_PACOTES_6MESES_2ANOS',
  BISCOITO_RECHEADO_DOCES_GULOSEIMAS_6MESES_2ANOS = 'BISCOITO_RECHEADO_DOCES_GULOSEIMAS_6MESES_2ANOS',
}

export type PerguntasRespostasHistoricoConsumoAlimentar = {
  __typename?: 'PerguntasRespostasHistoricoConsumoAlimentar'
  pergunta: Scalars['String']
  respostas: Array<Maybe<Scalars['String']>>
}

export type Period = {
  startDate?: Maybe<Scalars['LocalDate']>
  endDate?: Maybe<Scalars['LocalDate']>
}

export type PeriodoAusencia = {
  __typename?: 'PeriodoAusencia'
  id: Scalars['ID']
  dtAusencia: Scalars['LocalDate']
  dtRetorno?: Maybe<Scalars['LocalDate']>
}

export enum PeriodoDesfechoEnum {
  ULTIMA_SEMANA = 'ULTIMA_SEMANA',
  ULTIMO_MES = 'ULTIMO_MES',
  ULTIMOS_42_DIAS = 'ULTIMOS_42_DIAS',
}

export enum PeriodoEnum {
  PRIMEIRO_PERIODO = 'PRIMEIRO_PERIODO',
  SEGUNDO_PERIODO = 'SEGUNDO_PERIODO',
  TERCEIRO_PERIODO = 'TERCEIRO_PERIODO',
  QUARTO_PERIODO = 'QUARTO_PERIODO',
}

export type PeriodoGestacao = {
  __typename?: 'PeriodoGestacao'
  id: Scalars['ID']
  inicio: Scalars['LocalDate']
  fim?: Maybe<Scalars['LocalDate']>
}

export type PeriodontiaInput = {
  evolucoes?: Maybe<Array<EvolucaoProcedimentoOdontoInput>>
  periogramaSimplificado?: Maybe<PeriogramaSimplificadoInput>
  periogramaCompleto?: Maybe<Array<PeriogramaCompletoInput>>
  observacaoPeriogramaCompleto?: Maybe<Scalars['String']>
}

export type PeriogramaCompleto = {
  __typename?: 'PeriogramaCompleto'
  id: Scalars['ID']
  dente: ParteBucal
  excluido: Scalars['Boolean']
  profundidadeVestibular?: Maybe<Scalars['String']>
  recessaoVestibular?: Maybe<Scalars['String']>
  placaDVestibular?: Maybe<Scalars['Boolean']>
  placaVVestibular?: Maybe<Scalars['Boolean']>
  placaMVestibular?: Maybe<Scalars['Boolean']>
  sangramentoDVestibular?: Maybe<Scalars['Boolean']>
  sangramentoVVestibular?: Maybe<Scalars['Boolean']>
  sangramentoMVestibular?: Maybe<Scalars['Boolean']>
  profundidadeInterna?: Maybe<Scalars['String']>
  recessaoInterna?: Maybe<Scalars['String']>
  placaDInterna?: Maybe<Scalars['Boolean']>
  placaVInterna?: Maybe<Scalars['Boolean']>
  placaMInterna?: Maybe<Scalars['Boolean']>
  sangramentoDInterna?: Maybe<Scalars['Boolean']>
  sangramentoVInterna?: Maybe<Scalars['Boolean']>
  sangramentoMInterna?: Maybe<Scalars['Boolean']>
  mobilidade?: Maybe<Scalars['String']>
  lesaoDeFurca?: Maybe<Scalars['String']>
  atendimentoProfissionalOdonto: AtendimentoProfissionalOdonto
}

export type PeriogramaCompletoInput = {
  dente: ParteBucalEnum
  excluido: Scalars['Boolean']
  profundidadeVestibular?: Maybe<Scalars['String']>
  recessaoVestibular?: Maybe<Scalars['String']>
  placaDVestibular?: Maybe<Scalars['Boolean']>
  placaVVestibular?: Maybe<Scalars['Boolean']>
  placaMVestibular?: Maybe<Scalars['Boolean']>
  sangramentoDVestibular?: Maybe<Scalars['Boolean']>
  sangramentoVVestibular?: Maybe<Scalars['Boolean']>
  sangramentoMVestibular?: Maybe<Scalars['Boolean']>
  profundidadeInterna?: Maybe<Scalars['String']>
  recessaoInterna?: Maybe<Scalars['String']>
  placaDInterna?: Maybe<Scalars['Boolean']>
  placaVInterna?: Maybe<Scalars['Boolean']>
  placaMInterna?: Maybe<Scalars['Boolean']>
  sangramentoDInterna?: Maybe<Scalars['Boolean']>
  sangramentoVInterna?: Maybe<Scalars['Boolean']>
  sangramentoMInterna?: Maybe<Scalars['Boolean']>
  mobilidade?: Maybe<Scalars['String']>
  lesaoDeFurca?: Maybe<Scalars['String']>
}

export type PeriogramaCompletoQueryInput = {
  prontuarioId: Scalars['ID']
  atendProfId?: Maybe<Scalars['ID']>
}

export type PeriogramaSimplificado = {
  __typename?: 'PeriogramaSimplificado'
  id: Scalars['ID']
  avaliacaoSextante1: Scalars['String']
  avaliacaoSextante2: Scalars['String']
  avaliacaoSextante3: Scalars['String']
  avaliacaoSextante4: Scalars['String']
  avaliacaoSextante5: Scalars['String']
  avaliacaoSextante6: Scalars['String']
  observacao?: Maybe<Scalars['String']>
  atendimentoProfissionalOdonto: AtendimentoProfissionalOdonto
}

export type PeriogramaSimplificadoInput = {
  avaliacaoSextante1: Scalars['String']
  avaliacaoSextante2: Scalars['String']
  avaliacaoSextante3: Scalars['String']
  avaliacaoSextante4: Scalars['String']
  avaliacaoSextante5: Scalars['String']
  avaliacaoSextante6: Scalars['String']
  observacao?: Maybe<Scalars['String']>
}

export type PeriogramaSimplificadoPayload = {
  __typename?: 'PeriogramaSimplificadoPayload'
  content: Array<Maybe<PeriogramaSimplificado>>
  pageInfo: PageInfo
}

export type PeriogramaSimplificadoQueryInput = {
  prontuarioId: Scalars['ID']
  pageParams?: Maybe<PageParams>
}

export type PessoalAntecedentesInput = {
  texto?: Maybe<Scalars['String']>
  puericultura?: Maybe<PrenatalPartoNascimentoInput>
  informacoesObstetricas?: Maybe<InformacoesObstetricasInput>
  cirurgiasInternacoes?: Maybe<Array<Maybe<AntecedenteCirurgiaInternacaoInput>>>
}

export type PlanoInput = {
  texto?: Maybe<Scalars['String']>
  ciaps?: Maybe<Array<Maybe<CiapPlanoInput>>>
  procedimentos?: Maybe<Array<Maybe<ProcedimentosPlanoInput>>>
  prescricaoMedicamento?: Maybe<PrescricaoMedicamentoPlanoInput>
  odontologia?: Maybe<OdontologiaInput>
  atestado?: Maybe<AtestadoPlanoInput>
  encaminhamentosExternos?: Maybe<Array<TipoEncaminhamentoExternoEnum>>
  encaminhamentosExternosEspecializados?: Maybe<Array<EncaminhamentoExternoInput>>
  solicitacoesExame?: Maybe<Array<SolicitacaoExameInput>>
  orientacoes?: Maybe<Array<Maybe<OrientacaoPlanoInput>>>
  isObservacao?: Maybe<Scalars['Boolean']>
  cuidadosCompartilhado?: Maybe<Array<CuidadoCompartilhadoPlanoInput>>
  compartilhamentosCuidado?: Maybe<Array<CompartilhamentoCuidadoPlanoInput>>
}

export type PoloBase = {
  __typename?: 'PoloBase'
  id: Scalars['ID']
  nome: Scalars['String']
  dsei: Dsei
}

export type PoloBaseInput = {
  query?: Maybe<Scalars['String']>
  dseiId?: Maybe<Scalars['ID']>
  aldeiaId?: Maybe<Scalars['ID']>
  pageParams?: Maybe<PageParams>
}

export type PoloBaseQueryPayload = {
  __typename?: 'PoloBaseQueryPayload'
  content: Array<Maybe<PoloBase>>
  pageInfo: PageInfo
}

export enum PosicaoCampoRelatorioGerencialEnum {
  FILTRO = 'FILTRO',
  LINHA = 'LINHA',
  COLUNA = 'COLUNA',
}

export type PossuiAtendimentosNaoFinalizadosInput = {
  startDate: Scalars['Instant']
  endDate: Scalars['Instant']
  isRegistroTardio: Scalars['Boolean']
}

export type PossuiTratamentoConcluidoQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
  dataAtendimento: Scalars['Instant']
}

export type PreNatal = {
  __typename?: 'PreNatal'
  id: Scalars['ID']
  altoRisco: Scalars['Boolean']
  tipoGravidez?: Maybe<TipoGravidez>
  gravidezPlanejada?: Maybe<Scalars['Boolean']>
  problema: Problema
  ultimaDum?: Maybe<Scalars['LocalDate']>
}

export type PreNatalInput = {
  tipoGravidez?: Maybe<TipoGravidezEnum>
  alturaUterina?: Maybe<Scalars['Int']>
  edema?: Maybe<TipoEdemaEnum>
  movimentacaoFetal?: Maybe<Scalars['Boolean']>
  gravidezPlanejada?: Maybe<Scalars['Boolean']>
  batimentoCardiacoFetal?: Maybe<Scalars['Int']>
}

export type PrenatalPartoNascimentoInput = {
  tipoParto?: Maybe<TipoPartoEnum>
  tipoGravidez?: Maybe<TipoGravidezEnum>
  idadeGestacionalEmSemanas?: Maybe<Scalars['Int']>
  idadeGestacionalEmDias?: Maybe<Scalars['Int']>
  apgarPrimeiroMinuto?: Maybe<Scalars['Int']>
  apgarQuintoMinuto?: Maybe<Scalars['Int']>
  apgarDecimoMinuto?: Maybe<Scalars['Int']>
  peso?: Maybe<Scalars['Float']>
  altura?: Maybe<Scalars['Float']>
  perimetroCefalico?: Maybe<Scalars['Float']>
}

export type PrescricaoMedicamentoPlanoInput = {
  medicamentos: Array<Maybe<MedicamentoPlanoInput>>
}

export type PrincipioAtivo = {
  __typename?: 'PrincipioAtivo'
  id: Scalars['ID']
  nome: Scalars['String']
  listaMaterial: ListaMedicamento
}

export type Problema = {
  __typename?: 'Problema'
  id: Scalars['ID']
  cid10?: Maybe<Cid10>
  ciap?: Maybe<Ciap>
  descricaoOutro?: Maybe<Scalars['String']>
  evolucoes: Array<EvolucaoProblema>
  ultimaEvolucao: EvolucaoProblema
  situacao: SituacaoProblema
  evolucaoAvaliacaoCiapCid?: Maybe<EvolucaoAvaliacaoCiapCid>
}

export type ProblemaByCiapCidQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
  ciapId?: Maybe<Scalars['ID']>
  cidId?: Maybe<Scalars['ID']>
  situacoes?: Maybe<Array<Maybe<SituacaoProblema>>>
}

export type ProblemaQueryInput = {
  prontuarioId: Scalars['ID']
  situacoes?: Maybe<Array<SituacaoProblema>>
  pageParams?: Maybe<PageParams>
}

export type ProblemaQueryPayload = {
  __typename?: 'ProblemaQueryPayload'
  content: Array<Problema>
  pageInfo: PageInfo
}

export type ProblemasECondicoesInput = {
  ciapId?: Maybe<Scalars['ID']>
  cidId?: Maybe<Scalars['ID']>
  situacao?: Maybe<SituacaoProblema>
  dataInicio?: Maybe<Scalars['LocalDate']>
  dataFim?: Maybe<Scalars['LocalDate']>
  observacao?: Maybe<Scalars['String']>
  automatico: Scalars['Boolean']
}

export type Procedimento = {
  __typename?: 'Procedimento'
  id: Scalars['ID']
  descricao: Scalars['String']
  codigo: Scalars['String']
  idadeMinima?: Maybe<Scalars['Int']>
  idadeMaxima?: Maybe<Scalars['Int']>
  sexo?: Maybe<SexoEnum>
  procedimentoReferencia?: Maybe<Procedimento>
  tipoProcedimento?: Maybe<TipoProcedimentoEnum>
}

export type ProcedimentoAtendimentoInput = {
  id: Scalars['ID']
  cid10PrincipalId?: Maybe<Scalars['ID']>
  isAutomatico?: Maybe<Scalars['Boolean']>
}

export type ProcedimentoFiltroInput = {
  tipoExame?: Maybe<TipoExameEnum>
  tipoProcedimento?: Maybe<TipoProcedimentoEnum>
  gruposProcedimento?: Maybe<Array<Maybe<GrupoProcedimentoEnum>>>
  filtroPadrao?: Maybe<Scalars['Boolean']>
  sexo?: Maybe<SexoEnum>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  dataAtendimento: Scalars['LocalDate']
  cboIds?: Maybe<Array<Scalars['ID']>>
  filtrarProcedimentosOdonto?: Maybe<Scalars['Boolean']>
}

export type ProcedimentoHistoricoDto = {
  __typename?: 'ProcedimentoHistoricoDto'
  codigo?: Maybe<Scalars['String']>
  descricao: Scalars['String']
  categoriaProblema: CategoriaProblemaEnum
}

export type ProcedimentoOdontoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  parteBucal?: Maybe<ParteBucalEnum>
  dataNascimento: Scalars['LocalDate']
  dataAtendimento: Scalars['LocalDate']
  filtroPadrao?: Maybe<Scalars['Boolean']>
}

export type ProcedimentoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  filtro: ProcedimentoFiltroInput
  ids?: Maybe<Array<Scalars['ID']>>
}

export type ProcedimentosPlanoInput = {
  id?: Maybe<Scalars['ID']>
  procedimentoId: Scalars['ID']
  observacao?: Maybe<Scalars['String']>
}

export type ProcedimentosQueryPayload = {
  __typename?: 'ProcedimentosQueryPayload'
  content: Array<Maybe<Procedimento>>
  pageInfo: PageInfo
}

export enum ProcessamentoStatusEnum {
  EM_PROCESSAMENTO = 'EM_PROCESSAMENTO',
  PROCESSADO = 'PROCESSADO',
  ERRO = 'ERRO',
}

export type ProcessarTerritorioInput = {
  imovelId?: Maybe<Scalars['ID']>
  cidadaosIds?: Maybe<Array<Scalars['ID']>>
}

export type Processo = {
  __typename?: 'Processo'
  id: Scalars['ID']
  status: StatusProcesso
}

export type ProfissionaisAdQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type ProfissionaisInconsistenciaQueryPayload = {
  __typename?: 'ProfissionaisInconsistenciaQueryPayload'
  content: Array<Maybe<TransmissaoRecebimentoLoteDto>>
  pageInfo: PageInfo
}

export type ProfissionaisQueryInput = {
  query?: Maybe<Scalars['String']>
  mostrarSemLotacaoAtiva?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
  unidadeSaudeId?: Maybe<Scalars['ID']>
  equipesId?: Maybe<Array<Maybe<Scalars['ID']>>>
  cboId?: Maybe<Scalars['ID']>
}

export type ProfissionaisQueryPayload = {
  __typename?: 'ProfissionaisQueryPayload'
  content: Array<Maybe<Profissional>>
  pageInfo: PageInfo
}

export type ProfissionaisRelatorioInconsistenciaQueryInput = {
  dataInicio: Scalars['LocalDate']
  dataFim: Scalars['LocalDate']
  nomeProfissional?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type ProfissionaisSugestaoAgendamento = {
  __typename?: 'ProfissionaisSugestaoAgendamento'
  preNatal?: Maybe<Lotacao>
  odontologico?: Maybe<Lotacao>
}

export type Profissional = {
  __typename?: 'Profissional'
  cns?: Maybe<Scalars['String']>
  conselhoClasse?: Maybe<ConselhoClasse>
  cpf: Scalars['String']
  dataNascimento?: Maybe<Scalars['LocalDate']>
  email?: Maybe<Scalars['String']>
  endereco?: Maybe<Endereco>
  id: Scalars['ID']
  nome: Scalars['String']
  numeroConselhoClasse?: Maybe<Scalars['String']>
  sexo?: Maybe<Scalars['String']>
  telefone?: Maybe<Scalars['String']>
  ufEmissoraConselhoClasse?: Maybe<Uf>
  usuario: Usuario
  lotacoes: Array<Lotacao>
  estagios: Array<Estagio>
  acessos?: Maybe<Array<Acesso>>
  actions: ProfissionalActions
}

export type ProfissionalAcessosArgs = {
  input?: Maybe<AcessosConnectionInput>
}

export type ProfissionalActions = {
  __typename?: 'ProfissionalActions'
  redefinirSenha: Action
}

export type ProfissionalContraChaveInput = {
  profissional: ProfissionalInput
  contraChave: Scalars['String']
  novaSenha: Scalars['String']
}

export type ProfissionalInput = {
  conselhoClasse?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  cpf: Scalars['String']
  dataNascimento?: Maybe<Scalars['LocalDate']>
  email?: Maybe<Scalars['String']>
  endereco?: Maybe<EnderecoInput>
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
  numeroConselhoClasse?: Maybe<Scalars['String']>
  sexo?: Maybe<Scalars['String']>
  telefone?: Maybe<Scalars['String']>
  ufEmissoraConselhoClasse?: Maybe<Scalars['String']>
}

export type Prontuario = {
  __typename?: 'Prontuario'
  id: Scalars['ID']
  atendimentos: Array<Atendimento>
  gestacoes?: Maybe<Array<PeriodoGestacao>>
  preNatalAtivo?: Maybe<PreNatal>
  puerpera?: Maybe<Scalars['Boolean']>
}

export type ProximaDoseAprazamento = {
  __typename?: 'ProximaDoseAprazamento'
  diasAprazamento?: Maybe<Scalars['Int']>
  proximaDoseId?: Maybe<Scalars['ID']>
  proximaDoseSigla?: Maybe<Scalars['String']>
  proximaDoseNome?: Maybe<Scalars['String']>
}

export type ProximaDoseAprazamentoQueryInput = {
  imunobiologicoId: Scalars['ID']
  doseId: Scalars['ID']
  estrategiaId: Scalars['ID']
}

export type PuericulturaInput = {
  prenatalPartoNascimento?: Maybe<PrenatalPartoNascimentoInput>
  tipoAleitamento?: Maybe<TipoAleitamentoMaternoEnum>
  fatoresRisco?: Maybe<Array<AvaliacaoDesenvolvimentoInput>>
  alteracoesFenotipicas?: Maybe<Array<AvaliacaoDesenvolvimentoInput>>
  marcosDesenvolvimento?: Maybe<Array<AvaliacaoDesenvolvimentoInput>>
}

export type QrCode = {
  __typename?: 'QRCode'
  value: Scalars['String']
  base64PngImage: Scalars['String']
}

export type QuadroRelatorioExameItem = {
  __typename?: 'QuadroRelatorioExameItem'
  descricao: Scalars['String']
  quantidadeSolicitada: Scalars['Int']
  quantidadeAvaliada: Scalars['Int']
}

export type QuadroRelatorioFaixaEtariaItem = {
  __typename?: 'QuadroRelatorioFaixaEtariaItem'
  id: DimFaixaEtariaEnum
  descricao: Scalars['String']
  quantidadeHomens: Scalars['Int']
  quantidadeMulheres: Scalars['Int']
  quantidadeNaoInformado: Scalars['Int']
}

export type QuadroRelatorioItem = {
  __typename?: 'QuadroRelatorioItem'
  descricao: Scalars['String']
  quantidade: Scalars['Int']
}

export type QuantidadeStatusEnvioRndsDto = {
  __typename?: 'QuantidadeStatusEnvioRndsDto'
  qtdEnviado?: Maybe<Scalars['Long']>
  qtdNaoEnviado?: Maybe<Scalars['Long']>
  qtdFalhaConexao?: Maybe<Scalars['Long']>
  qtdDadosInvalidos?: Maybe<Scalars['Long']>
  qtdErro?: Maybe<Scalars['Long']>
}

export type Query = {
  __typename?: 'Query'
  absenteismos?: Maybe<Array<Absenteismo>>
  acesso: Acesso
  acessoHasVinculacaoEquipeNasf: Scalars['Boolean']
  acompanhamentoCidadaosVinculados: AcompanhamentoCidadaosVinculadosPayload
  acompanhamentoCondicaoSaude: AcompanhamentoCondicaoSaudeQueryPayload
  administradoresGerais: Array<AdministradorGeral>
  administradoresMunicipais: Array<AdministradorMunicipal>
  agendaEmConflito: Scalars['Boolean']
  agendado: Agendado
  agendados: AgendadoQueryPayload
  aldeias: AldeiaQueryPayload
  alergia?: Maybe<Alergia>
  alergias: AlergiaQueryPayload
  alertasSdc: Array<AlertaSdc>
  alteracoesFenotipicas?: Maybe<Array<AlteracaoFenotipica>>
  antecedente?: Maybe<Antecedente>
  aplicacoesMedicamento: AplicacaoMedicamentoPayload
  asyncProcessingStatus?: Maybe<AsyncProcessingStatus>
  atendimento: Atendimento
  atendimentoCompartilhadoRelatorioGerencialAtendimento?: Maybe<Array<Scalars['String']>>
  atendimentoIndividual: AtendimentoProfissional
  atendimentoPolling: Scalars['Boolean']
  atendimentoPollingEnabled: Scalars['Boolean']
  atendimentos: AtendimentosPayload
  atendimentosDoDia: AtendimentosProfissionalPayload
  atendimentosFinalizados?: Maybe<AtendimentosProfissionalPayload>
  atendimentosProfUltimaGestacao?: Maybe<Array<Maybe<AtendimentoProfissional>>>
  atendimentosProfissional: AtendimentosProfissionalPayload
  atestado: Atestado
  atestadoModelo: AtestadoModelo
  atestadoModelos: Array<Maybe<AtestadoModelo>>
  atestados: AtestadoQueryPayload
  auditoriaHistorico: AuditoriaHistoricoPayload
  auditoriaTipoEvento: Array<Maybe<AuditoriaTipoEvento>>
  auditoriaTipoRegistroAfetado: Array<Maybe<AuditoriaTipoRegistroAfetado>>
  avancada: ConfiguracaoAvancada
  bairros: BairroQueryPayload
  buscaAtivaVacinacao: BuscaAtivaVacinacaoPayload
  buscaCidadaoCadsusAtualizado: Scalars['ID']
  buscaCidadaoCadsusAtualizadoForcado: Scalars['ID']
  buscaCidadaosCadsus: Scalars['String']
  buscaCidadaosCadsusCompletoPolling?: Maybe<BuscaCidadaosCadsusResultDto>
  cadastrosDuplicados: CidadaoUnificacaoBasePayload
  categoriasProfissionalRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  categoriasProibidas: Array<ListaMedicamentoEnum>
  cboGrupo: Array<CboGrupo>
  cbos: CbosQueryPayload
  cbosRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  cbosTrocaExecutante: CboPayload
  cbosVinculacoesServico: CbosCuidadoCompartilhadoPayload
  certificados: CertificadoPayload
  chaveByCpf: Scalars['String']
  ciaps: CiapQueryPayLoad
  cidPrimeiroAtendimentoPreNatal?: Maybe<Cid10>
  cidadao: Cidadao
  cidadaoAD: AtencaoDomiciliarCidadao
  cidadaos: CidadaosPayload
  cidadaosAD: CidadaosAdQueryPayload
  cidadaosCombo: Array<Maybe<CidadaoBasico>>
  cidadaosComboByCns: Array<Maybe<CidadaoBasico>>
  cidadaosComboByCpf: Array<Maybe<CidadaoBasico>>
  cidadaosComboByDataNascimento: Array<Maybe<CidadaoBasico>>
  cidadaosComboByNome: Array<Maybe<CidadaoBasico>>
  cids: Cid10QueryPayLoad
  compartilhamentosCuidadoPlano: Array<CompartilhamentoCuidadoPlanoDto>
  condicoesAutorreferidas: Array<Maybe<CondicaoAutorreferida>>
  condutasRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<Scalars['String']>>
  conexao: ConfiguracaoConexao
  configuracaoCertificadoHabilitado?: Maybe<ConfiguracaoCertificado>
  configuracaoHorariosMunicipio: ConfiguracaoAgendaMunicipal
  configuracoesAtencaoDom: ConfiguracoesAtencaoDom
  conselhosClasse: ConselhoClassePayload
  consultaEstoqueHorus: Scalars['String']
  contatoCidadao: ContatoCidadao
  contraChaveAtivacaoMunicipio?: Maybe<Scalars['String']>
  credenciaisRecebimentoExterno: CredencialRecebimentoExternoPayload
  credencialRecebimentoExterno: CredencialRecebimentoExterno
  cuidadoCompartilhado?: Maybe<CuidadoCompartilhado>
  cuidadoCompartilhadoEvolucao?: Maybe<CuidadoCompartilhadoEvolucao>
  cuidadoCompartilhadoEvolucoes: CuidadoCompartilhadoEvolucoesPayload
  cuidadosCompartilhado: CuidadosCompartilhadoPayload
  dataUltimaConsultaOdontoGestante?: Maybe<Scalars['LocalDate']>
  dataUltimoProcessamento?: Maybe<Scalars['Instant']>
  diasDaSemanaRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  dimensaoAgrupadorFiltro: DimensaoAgrupadorFiltroQueryPayload
  dimensaoCategoriaProfissional: Array<Maybe<DimensaoCategoriaProfissional>>
  dimensaoEquipe: DimensaoEquipeQueryPayload
  dimensaoMunicipios: DimensaoMunicipiosQueryPayload
  dimensaoUnidadeSaude: DimensaoUnidadeSaudeQueryPayload
  disponibilidadeDadosClinicosHistorico: DisponibilidadeDadosClinicosHistorico
  doseImunobiologicoVacinacao: DoseImunobiologicoQueryPayload
  dosesImunobiologicosRelatorioGerencialVacinacao?: Maybe<Array<Scalars['String']>>
  dseis: DseiQueryPayload
  duracaoAtendimentoPadraoMunicipio?: Maybe<DuracaoAtendimentoPadraoMunicipio>
  encaminhamentoEspecializado: EncaminhamentoExternoEspecializado
  encaminhamentos: Array<EncaminhamentoExterno>
  encaminhamentosEspecializados: EncaminhamentoExternoEspecializadoQueryPayload
  endereco?: Maybe<EnderecoQueryPayload>
  equipe: Equipe
  equipes: EquipePayload
  equipesAD: EquipePayload
  equipesAtencaoDom: EquipeAdPayload
  equipesListaCuidadoCompartilhado: Array<Equipe>
  equipesRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  equipesVinculadas: EquipePayload
  escutaInicialDetail?: Maybe<AtendimentoProfissional>
  escutaOrPreAtendimentoOfAtendimento?: Maybe<Scalars['ID']>
  especialidadesSisreg: EspecialidadeSisregQueryPayload
  esquemaCalendarioVacinal: Array<BaseCalendarioVacinal>
  estabelecimentosCuidadoCompartilhado: UnidadeSaudePayload
  estabelecimentosListaCuidadoCompartilhado: Array<UnidadeSaude>
  estagio: Estagio
  estagios?: Maybe<EstagiosQueryPayload>
  estrategiasRelatorioGerencialVacinacao?: Maybe<Array<Scalars['String']>>
  estrategiasVacinacao: Array<EstrategiaVacinacao>
  etnias: EtniaPayload
  evolucoesProcedimentoOdonto: EvolucaoProcedimentoPayload
  examesPreNatal: Array<Procedimento>
  existeProblemaResolvido: Scalars['Boolean']
  existemAlergiasResolvidasRefutadas: Scalars['Boolean']
  existemFichasParaProcessar: Scalars['Boolean']
  fabricantesImunobiologico: ImunobiologicoFabricanteQueryPayload
  faixaEtariaVacinacao?: Maybe<Array<Maybe<FaixaEtariaVacinacao>>>
  faixasEtariaRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  fatoresRisco?: Maybe<Array<FatorRisco>>
  fechamentoDia?: Maybe<FechamentoAgenda>
  fechamentos: FechamentoAgendaQueryPayload
  fichasNotificacao: FichaNotificacaoQueryPayload
  formasFarmaceuticas: FormaFarmaceuticaPayload
  funcoesSociais: FuncaoSocialPayload
  garantiaAcessoByUnidadeSaudeId: GarantiaAcessoPayload
  garantiaAcessoItemById: ListaEspera
  gestanteRelatorioGerencialVacinacao?: Maybe<Array<Scalars['String']>>
  gestoresEstaduais: Array<GestorEstadual>
  gestoresMunicipais: Array<GestorMunicipal>
  grupoExame: GrupoExame
  grupos: GrupoCondicaoQueryPayLoad
  gruposAtendimento: Array<GrupoAtendimento>
  gruposExame: GruposExameQueryPayload
  hasAgendamentoFuturo?: Maybe<Scalars['Boolean']>
  hasConfiguracaoAgenda?: Maybe<Scalars['Boolean']>
  hasDocumentosDuplicados: Scalars['Boolean']
  hasEquipesVinculadas: Scalars['Boolean']
  hasMunicipioAtivo: Scalars['Boolean']
  hasProblemasGestacao?: Maybe<Scalars['Boolean']>
  hasPuericultura: Scalars['Boolean']
  hasVacinaAtrasada: Scalars['Boolean']
  historico: HistoricoPayload
  historicoAtendimentoDomiciliar?: Maybe<FatoAtendimentoDomiciliar>
  historicoAtendimentoIndividualDw?: Maybe<FatoAtendimentoIndividual>
  historicoAtendimentoOdontoDw?: Maybe<FatoAtendimentoOdonto>
  historicoAtendimentoPec?: Maybe<AtendimentoProfissional>
  historicoAtividadeColetiva?: Maybe<FatoAtividadeColetivaParticipante>
  historicoConsumoAlimentarDetail?: Maybe<HistoricoConsumoAlimentarDetail>
  historicoCuidadoCompartilhadoDw?: Maybe<FatoCuidadoCompartilhado>
  historicoElegibilidadeDetail?: Maybe<FatoAvaliacaoElegibilidade>
  historicoPrescricao: HistoricoPrescricaoQueryPayload
  historicoProcedimentoDw?: Maybe<FatoProcedAtend>
  historicoVacinacaoDw: FatoVacinacao
  historicoVacinacaoPec: AtendimentoVacinacao
  historicoVisitaDomiciliar?: Maybe<FatoVisitaDomiciliar>
  historicoZikaDetail?: Maybe<HistoricoZikaDetail>
  horariosAgenda: Array<HorarioAgenda>
  horariosRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  iceServersConfiguration: IceServersConfig
  idadeGestacional?: Maybe<IdadeGestacionalAcompanhamentoPreNatal>
  imoveis: ImoveisQueryPayload
  imoveisPermissaoAtualizar: TotalImoveisPermissao
  imovel?: Maybe<ImovelDto>
  importacaoCnes: ImportacaoCnes
  importacoesCnes: ImportacoesCnesQueryPayload
  imunobiologicos: ImunobiologicoQueryPayload
  imunobiologicosRelatorioGerencialVacinacao?: Maybe<Array<Scalars['String']>>
  info: Info
  isEstabelecimentoAllowedAsReferenciaVinculacaoServico: Scalars['Boolean']
  isParticipantePermitidoVideochamada: Scalars['Boolean']
  isProcessamentoTerritorioRunning: Scalars['Boolean']
  lembretes: Array<Maybe<Lembrete>>
  listaMedicamento: ListaMedicamentoAtivoConcluidoQueryPayload
  listagemCidadaos: CidadaosPayload
  loadHorarioGeracaoLotesProcessamentoFichas?: Maybe<Scalars['LocalTime']>
  locaisAplicacao: Array<LocalAplicacao>
  locaisOcorrencia: LocalOcorrenciaPayload
  localAtendimento: LocalAtendimentoPayload
  logradouros: LogradouroQueryPayload
  lotacao: Lotacao
  lotacoesAgenda?: Maybe<LotacaoQueryPayload>
  lotacoesAndEstagios: Array<Acesso>
  lotacoesListaCuidadoCompartilhado: Array<Lotacao>
  loteEnvio: LoteEnvio
  loteHasCadastro?: Maybe<Scalars['Boolean']>
  lotesEnvio: LotesEnvioPayload
  lotesImunobiologico: LotesImunobiologicoQueryPayload
  manifestacoesAlergia: ManifestacaoAlergiaQueryPayload
  marcadorConsumoAlimentar?: Maybe<MarcadorConsumoAlimentar>
  marcosDesenvolvimento?: Maybe<Array<MarcoDesenvolvimento>>
  medicamentos: MedicamentoQueryPayload
  medicamentosCatmat: MedicamentoCatmatQueryPayload
  medicoes: Array<Medicao>
  mesAgenda: MesAgendaLotacao
  mesesRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  microareas: Array<Maybe<Scalars['String']>>
  microareasImoveis: Array<Scalars['String']>
  modeloPersonalizadoRelatorioGerencialById?: Maybe<ModeloPersonalizadoRelatorioGerencial>
  modelosPersonalizadosByTipoModulo: Array<ModeloPersonalizadoSelect>
  municipios: MunicipiosQueryPayload
  municipiosRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  municipiosResponsaveis: MunicipioResponsavelQueryPayload
  nomesInstalacoes?: Maybe<NomesInstalacoesUnificacaoDto>
  nomesOutrosProfissionaisAgendamentosConflitantes: Array<NomesProfissionaisAgendamentosConflitantes>
  odontograma: Odontograma
  odontogramas: OdontogramasPayload
  orientacoes: Array<Maybe<Orientacao>>
  paises: PaisQueryPayload
  partesBucais: PartesBucaisPayload
  pedidoEnvioBaseAtivo?: Maybe<PedidoEnvioBase>
  pedidosEnvioBase?: Maybe<Array<Maybe<PedidoEnvioBase>>>
  perfil?: Maybe<Perfil>
  perfis: PerfisQueryPayload
  periogramasCompleto: Array<Maybe<PeriogramaCompleto>>
  periogramasSimplificados: PeriogramaSimplificadoPayload
  planosDeCuidado: Array<AtendimentoObservacaoPlanoCuidado>
  polosBases: PoloBaseQueryPayload
  possuiAgendamentoMesmoProfissionalDataFutura: Array<AgendamentoMesmoProfissionalDataFutura>
  possuiAtendimentosNaoFinalizados?: Maybe<Scalars['Boolean']>
  possuiTratamentoConcluido: Scalars['Boolean']
  primeirasRespostasRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<Scalars['String']>>
  prioridadesRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<Scalars['String']>>
  problema?: Maybe<Problema>
  problemaByCiapCid?: Maybe<Problema>
  problemas: ProblemaQueryPayload
  procedimentos: ProcedimentosQueryPayload
  procedimentosAutomaticos: Array<Maybe<Procedimento>>
  procedimentosEvolucaoOdonto: ProcedimentosQueryPayload
  processamentoStatus?: Maybe<ProcessamentoStatusEnum>
  profissionais: ProfissionaisQueryPayload
  profissionaisAD: ProfissionaisQueryPayload
  profissionaisRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  profissionaisRelatorioInconsistencia: ProfissionaisInconsistenciaQueryPayload
  profissional: Profissional
  profissionalByCpfContraChave?: Maybe<Profissional>
  proximaDoseAprazamentoAutomatico?: Maybe<ProximaDoseAprazamento>
  puerperaRelatorioGerencialVacinacao?: Maybe<Array<Scalars['String']>>
  realizadoPorRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<Scalars['String']>>
  recursos: Array<Scalars['String']>
  registroAvaliacaoPuericultura: RegistroAvaliacaoPuericultura
  registrosAplicadosVacinacao?: Maybe<Array<RegistroAplicado>>
  registrosDoseVacinacao?: Maybe<Array<Maybe<RegistroDose>>>
  regrasVacinaisValidasUltimaVersao: Array<RegraVacinal>
  relatorioProducaoAtendimentoDomiciliar: RelatorioProducaoAtendimentoDomiciliar
  relatorioProducaoAtendimentoIndividual: RelatorioProducaoAtendimentoIndividual
  relatorioProducaoAtendimentoOdonto: RelatorioProducaoAtendimentoOdonto
  relatorioProducaoAtividadeColetiva: RelatorioProducaoAtividadeColetiva
  relatorioProducaoProcedimentosIndividualizados: RelatorioProducaoProcedimentosIndividualizados
  relatorioProducaoVacinacao: RelatorioProducaoVacinacao
  relatorioProducaoVisitaDomiciliarTerritorial: RelatorioProducaoVisitaDomiciliarTerritorial
  resultadosExame: ResultadosExamePayload
  resumoEnviosRnds: QuantidadeStatusEnvioRndsDto
  seguranca: ConfiguracaoSeguranca
  serieHistorica: Array<SerieHistoricaPayload>
  serverTime: Scalars['Instant']
  serverTimezoneOffset: Scalars['Int']
  servidores: ConfiguracaoServidoresFormDto
  sessao?: Maybe<Sessao>
  sexosRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  sincronizacaoCadsusPolling?: Maybe<SincronizacaoCadsusResultDto>
  solicitacoesExame: SolicitacoesExamePayload
  solicitacoesExamePaged: SolicitacoesExamePayload
  statusConexaoRnds: StatusConexaoRndsEnum
  substanciasCbaraAlergia: SubstanciaCbaraAlergiaQueryPayload
  subtipoAtendimentoHistorico?: Maybe<Array<Maybe<SubtipoAtendimentoHistoricoClinico>>>
  sugestoesDatas: SugestoesDatas
  sugestoesProfissionais: ProfissionaisSugestaoAgendamento
  tecidosMoles: TecidosMolesPayload
  tempoEntreAgendamentoEConsultaRelatorioGerencialAbsenteismo?: Maybe<Array<Scalars['String']>>
  temposDeEsperaRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<Scalars['String']>>
  temposDeRespostaRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<Scalars['String']>>
  territorioEquipe: TerritorioEquipeDto
  territorioLogradouro: Array<ImovelDto>
  territorioMicroarea: TerritorioMicroarea
  tipoAleitamentoMaterno: AleitamentoMaterno
  tipoRegistroHistorico?: Maybe<Array<Maybe<TipoRegistroHistoricoClinico>>>
  tiposDeAtendimentoRelatorioGerencialAtendimento?: Maybe<Array<Scalars['String']>>
  tiposDeConsultaRelatorioGerencialAtendimento?: Maybe<Array<Scalars['String']>>
  tiposDeFaltaRelatorioGerencialAbsenteismo?: Maybe<Array<Scalars['String']>>
  tiposLogradouro: TipoLogradouroQueryPayload
  tiposOrigemDadoTransporte: Array<TipoOrigemDadoTransporte>
  tiposProducaoRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  tiposReceitaProibidos: Array<TipoReceitaEnum>
  tiposServico: TipoServicoQueryPayload
  todasVinculacoesEquipeEmulti: TodasVinculacoesEquipesPayload
  transmissao: Transmissao
  /** @deprecated use transmissao.links */
  transmissaoLink: TransmissaoLinksPayload
  transmissaoRecebimentoCnes: TransmissaoRecebimentoCnesPayload
  transmissaoRecebimentoCnesDetailItems: Array<Maybe<TransmissaoRecebimentoCnesDetailItem>>
  transmissaoRecebimentoLotes: TransmissaoRecebimentoQueryPayload
  turnosRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  ultimaAvaliacaoMultidimensional?: Maybe<Scalars['Instant']>
  ultimaVacinaAprazada?: Maybe<UltimaVacina>
  ultimasMedicoesDiasAnteriores: Array<GrupoMedicoesTipo>
  ultimasMedicoesDoDia: Array<GrupoMedicoesTipo>
  ultimasTresVacinasAplicadas?: Maybe<Array<Maybe<UltimaVacina>>>
  ultimoArquivoProntoEmProcessamento?: Maybe<StatusArquivo>
  ultimoAtendimentoProfissionalCidadao?: Maybe<AtendimentoProfissional>
  ultimoAtendimentoPuericultura: AtendimentoPuericultura
  ultimosCincoArquivos?: Maybe<Array<Maybe<StatusArquivo>>>
  unidadeSaude: UnidadeSaude
  unidadeSaudeTiposServico: UnidadeSaudeTiposServicoPayload
  unidadesConsultaHorus: Array<Maybe<HorusFormDto>>
  unidadesMedida: UnidadeMedidaPayload
  unidadesSaude: UnidadeSaudePayload
  unidadesSaudeByGarantiaAcessoCidadao: Array<UnidadeSaude>
  unidadesSaudeByTipoVinculacaoServico: Array<UnidadeSaude>
  unidadesSaudeRelatorioGerencial?: Maybe<Array<Scalars['String']>>
  unificacoesBase?: Maybe<Array<Maybe<UnificacaoBase>>>
  unificacoesCidadao?: Maybe<Array<Maybe<UnificacoesCidadao>>>
  validatePermissaoNovoAdmin: Scalars['Boolean']
  validateResetAdmin: Scalars['Boolean']
  viajanteRelatorioGerencialVacinacao?: Maybe<Array<Scalars['String']>>
  viasAdministracaoVacina: Array<ViaAdministracao>
  videochamada?: Maybe<Videochamada>
  vinculacaoFichaCadastroIndividual?: Maybe<CidadaoVinculacaoFichaDto>
  vinculacaoServico?: Maybe<VinculacaoServicoDto>
  vinculacoesServico: VinculacaoServicosPayload
  visita?: Maybe<FatoVisitaDomiciliar>
  visitas: VisitasQueryPayload
}

export type QueryAbsenteismosArgs = {
  input: DateRangeDto
}

export type QueryAcessoArgs = {
  id: Scalars['ID']
}

export type QueryAcompanhamentoCidadaosVinculadosArgs = {
  input: AcompanhamentoCidadaosVinculadosFilterInput
}

export type QueryAcompanhamentoCondicaoSaudeArgs = {
  input: AcompanhamentoCondicaoSaudeQueryInput
}

export type QueryAgendaEmConflitoArgs = {
  idLotacao: Scalars['ID']
}

export type QueryAgendadoArgs = {
  id: Scalars['ID']
}

export type QueryAgendadosArgs = {
  input: AgendadosQueryInput
}

export type QueryAldeiasArgs = {
  input: AldeiaInput
}

export type QueryAlergiaArgs = {
  id: Scalars['ID']
}

export type QueryAlergiasArgs = {
  input: AlergiasQueryInput
}

export type QueryAlertasSdcArgs = {
  input: AlertasSdcInput
}

export type QueryAlteracoesFenotipicasArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryAntecedenteArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryAplicacoesMedicamentoArgs = {
  input?: Maybe<AplicacaoMedicamentoQueryInput>
}

export type QueryAsyncProcessingStatusArgs = {
  asyncProcessingName: AsyncProcessingNameEnum
}

export type QueryAtendimentoArgs = {
  id: Scalars['ID']
}

export type QueryAtendimentoIndividualArgs = {
  id: Scalars['ID']
}

export type QueryAtendimentoPollingArgs = {
  time: Scalars['Instant']
}

export type QueryAtendimentosArgs = {
  input: AtendimentosQueryInput
}

export type QueryAtendimentosDoDiaArgs = {
  id: Scalars['ID']
}

export type QueryAtendimentosFinalizadosArgs = {
  input?: Maybe<AtendimentosFinalizadosInput>
}

export type QueryAtendimentosProfUltimaGestacaoArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryAtendimentosProfissionalArgs = {
  input: AtendimentosProfissionalQueryInput
}

export type QueryAtestadoArgs = {
  id: Scalars['ID']
}

export type QueryAtestadoModeloArgs = {
  id: Scalars['ID']
}

export type QueryAtestadosArgs = {
  input?: Maybe<AtestadoQueryInput>
}

export type QueryAuditoriaHistoricoArgs = {
  input: AuditoriaHistoricoQueryInput
}

export type QueryBairrosArgs = {
  input: BairroInput
}

export type QueryBuscaAtivaVacinacaoArgs = {
  input: BuscaAtivaVacinacaoInput
}

export type QueryBuscaCidadaoCadsusAtualizadoArgs = {
  buscaCidadaoCadsusInput: BuscaCidadaoCadsusInput
}

export type QueryBuscaCidadaoCadsusAtualizadoForcadoArgs = {
  buscaCidadaoCadsusInput: BuscaCidadaoCadsusInput
}

export type QueryBuscaCidadaosCadsusArgs = {
  filtro: BuscaListaCidadaoCadsusInput
}

export type QueryBuscaCidadaosCadsusCompletoPollingArgs = {
  uuid: Scalars['String']
}

export type QueryCadastrosDuplicadosArgs = {
  input: CidadaoUnificacaoBaseInput
}

export type QueryCategoriasProfissionalRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type QueryCboGrupoArgs = {
  acessoCbo?: Maybe<AcessoCbo>
}

export type QueryCbosArgs = {
  input: CbosQueryInput
}

export type QueryCbosRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type QueryCbosTrocaExecutanteArgs = {
  input: CbosTrocaExecutanteCuidadoCompartilhadoQueryInput
}

export type QueryCbosVinculacoesServicoArgs = {
  input: CbosCuidadoCompartilhadoQueryInput
}

export type QueryCertificadosArgs = {
  input: CertificadoQueryInput
}

export type QueryChaveByCpfArgs = {
  cpf: Scalars['String']
}

export type QueryCiapsArgs = {
  input: CiapQueryInput
}

export type QueryCidPrimeiroAtendimentoPreNatalArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryCidadaoArgs = {
  id: Scalars['ID']
}

export type QueryCidadaoAdArgs = {
  input: Scalars['ID']
}

export type QueryCidadaosArgs = {
  input: CidadaosQueryInput
}

export type QueryCidadaosAdArgs = {
  input: CidadaosAdQueryInput
}

export type QueryCidadaosComboArgs = {
  input: CidadaosComboQueryInput
  atencaoDomiciliar?: Scalars['Boolean']
  equipeResponsavelId?: Maybe<Scalars['ID']>
}

export type QueryCidadaosComboByCnsArgs = {
  input: CidadaosComboQueryInput
}

export type QueryCidadaosComboByCpfArgs = {
  input: CidadaosComboQueryInput
}

export type QueryCidadaosComboByDataNascimentoArgs = {
  input: CidadaosComboQueryInput
}

export type QueryCidadaosComboByNomeArgs = {
  input: CidadaosComboQueryInput
}

export type QueryCidsArgs = {
  input: Cid10QueryInput
}

export type QueryCompartilhamentosCuidadoPlanoArgs = {
  input: CompartilhamentosCuidadoPlanoQueryInput
}

export type QueryCondicoesAutorreferidasArgs = {
  cidadaoId: Scalars['ID']
}

export type QueryConselhosClasseArgs = {
  input: ConselhoClasseQueryInput
}

export type QueryConsultaEstoqueHorusArgs = {
  input: ConsultaEstoqueHorusQueryInput
}

export type QueryContatoCidadaoArgs = {
  id: Scalars['ID']
}

export type QueryCredenciaisRecebimentoExternoArgs = {
  input: CredencialRecebimentoExternoQueryInput
}

export type QueryCredencialRecebimentoExternoArgs = {
  id: Scalars['ID']
}

export type QueryCuidadoCompartilhadoArgs = {
  id: Scalars['ID']
}

export type QueryCuidadoCompartilhadoEvolucaoArgs = {
  id: Scalars['ID']
}

export type QueryCuidadoCompartilhadoEvolucoesArgs = {
  input: CuidadoCompartilhadoEvolucoesQueryInput
}

export type QueryCuidadosCompartilhadoArgs = {
  input: CuidadosCompartilhadoQueryInput
}

export type QueryDataUltimaConsultaOdontoGestanteArgs = {
  filter: DataUltimaConsultaOdontoGestanteQueryInput
}

export type QueryDiasDaSemanaRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type QueryDimensaoAgrupadorFiltroArgs = {
  input: DimensaoAgrupadorFiltroQueryInput
}

export type QueryDimensaoCategoriaProfissionalArgs = {
  input: DimensaoCategoriaProfissionalQueryInput
}

export type QueryDimensaoEquipeArgs = {
  input: DimensaoEquipeQueryInput
}

export type QueryDimensaoMunicipiosArgs = {
  input: DimensaoMunicipiosQueryInput
}

export type QueryDimensaoUnidadeSaudeArgs = {
  input: DimensaoUnidadeSaudeQueryInput
}

export type QueryDoseImunobiologicoVacinacaoArgs = {
  input?: Maybe<DoseImunobiologicoVacinacaoQueryInput>
}

export type QueryDosesImunobiologicosRelatorioGerencialVacinacaoArgs = {
  input?: Maybe<Scalars['String']>
}

export type QueryDseisArgs = {
  input: DseiInput
}

export type QueryEncaminhamentoEspecializadoArgs = {
  id: Scalars['ID']
}

export type QueryEncaminhamentosArgs = {
  input?: Maybe<EncaminhamentoExternoQueryInput>
}

export type QueryEncaminhamentosEspecializadosArgs = {
  input?: Maybe<EncaminhamentoExternoEspecializadoQueryInput>
}

export type QueryEnderecoArgs = {
  cep: Scalars['String']
}

export type QueryEquipeArgs = {
  id: Scalars['ID']
}

export type QueryEquipesArgs = {
  input: EquipesQueryInput
}

export type QueryEquipesAdArgs = {
  input: EquipesAdQueryInput
}

export type QueryEquipesAtencaoDomArgs = {
  input?: Maybe<EquipeAdInput>
}

export type QueryEquipesListaCuidadoCompartilhadoArgs = {
  input: EquipesListaCuidadoCompartilhadoQueryInput
}

export type QueryEquipesRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type QueryEquipesVinculadasArgs = {
  input: EquipesQueryInput
}

export type QueryEscutaInicialDetailArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryEscutaOrPreAtendimentoOfAtendimentoArgs = {
  input: EscutaOrPreAtendimentoOfAtendimentoInput
}

export type QueryEspecialidadesSisregArgs = {
  input?: Maybe<EspecialidadeSisregQueryInput>
}

export type QueryEsquemaCalendarioVacinalArgs = {
  input: EsquemaCalendarioVacinalQueryInput
}

export type QueryEstabelecimentosCuidadoCompartilhadoArgs = {
  input: EstabelecimentosCuidadoCompartilhadoByCboIdQueryInput
}

export type QueryEstabelecimentosListaCuidadoCompartilhadoArgs = {
  input: EstabelecimentosListaCuidadoCompartilhadoQueryInput
}

export type QueryEstagioArgs = {
  id: Scalars['ID']
}

export type QueryEstagiosArgs = {
  input?: Maybe<EstagiosQueryInput>
}

export type QueryEstrategiasVacinacaoArgs = {
  input: EstrategiasVacinacaoQueryInput
}

export type QueryEtniasArgs = {
  input: EtniaQueryInput
}

export type QueryEvolucoesProcedimentoOdontoArgs = {
  input: EvolucoesProcedimentoOdontoInput
}

export type QueryExisteProblemaResolvidoArgs = {
  input: Scalars['ID']
}

export type QueryExistemAlergiasResolvidasRefutadasArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryFabricantesImunobiologicoArgs = {
  input: ImunobiologicoFabricanteQueryInput
}

export type QueryFaixaEtariaVacinacaoArgs = {
  input?: Maybe<FaixaEtariaVacinacaoQueryInput>
}

export type QueryFaixasEtariaRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type QueryFatoresRiscoArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryFechamentoDiaArgs = {
  input?: Maybe<FechamentoAgendaDiaQueryInput>
}

export type QueryFechamentosArgs = {
  input?: Maybe<FechamentoAgendaQueryInput>
}

export type QueryFichasNotificacaoArgs = {
  input: FichaNotificacaoQueryInput
}

export type QueryFormasFarmaceuticasArgs = {
  input: FormaFarmaceuticaQueryInput
}

export type QueryFuncoesSociaisArgs = {
  input: FuncaoSocialQueryInput
}

export type QueryGarantiaAcessoByUnidadeSaudeIdArgs = {
  input: GarantiaAcessoFilter
}

export type QueryGarantiaAcessoItemByIdArgs = {
  input: Scalars['ID']
}

export type QueryGrupoExameArgs = {
  id: Scalars['ID']
}

export type QueryGruposArgs = {
  input: GrupoCondicaoQueryInput
}

export type QueryGruposAtendimentoArgs = {
  inputQuery?: Maybe<Scalars['String']>
  onlyAtivos?: Maybe<Scalars['Boolean']>
}

export type QueryGruposExameArgs = {
  input: GrupoExameQueryInput
}

export type QueryHasAgendamentoFuturoArgs = {
  input: CidadaoPossuiAgendamentoFuturoQueryInput
}

export type QueryHasConfiguracaoAgendaArgs = {
  idLotacao: Scalars['ID']
}

export type QueryHasDocumentosDuplicadosArgs = {
  unificacaoBaseId: Scalars['ID']
}

export type QueryHasEquipesVinculadasArgs = {
  equipePrincipalId: Scalars['ID']
}

export type QueryHasProblemasGestacaoArgs = {
  id: Scalars['Long']
}

export type QueryHasPuericulturaArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryHasVacinaAtrasadaArgs = {
  input: VacinacaoUltimaVacinaInput
}

export type QueryHistoricoArgs = {
  input: HistoricoQueryInput
}

export type QueryHistoricoAtendimentoDomiciliarArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoAtendimentoIndividualDwArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoAtendimentoOdontoDwArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoAtendimentoPecArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoAtividadeColetivaArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoConsumoAlimentarDetailArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoCuidadoCompartilhadoDwArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoElegibilidadeDetailArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoPrescricaoArgs = {
  input: HistoricoPrescricaoQueryInput
}

export type QueryHistoricoProcedimentoDwArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoVacinacaoDwArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoVacinacaoPecArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoVisitaDomiciliarArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHistoricoZikaDetailArgs = {
  input: HistoricoDetailQueryInput
}

export type QueryHorariosAgendaArgs = {
  input: HorariosAgendaQueryInput
}

export type QueryHorariosRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type QueryIceServersConfigurationArgs = {
  input: IceServersConfigurationInput
}

export type QueryIdadeGestacionalArgs = {
  input: IdadeGestacionalQueryInput
}

export type QueryImoveisArgs = {
  input: ImoveisQueryInput
}

export type QueryImoveisPermissaoAtualizarArgs = {
  input: TerritorioLogradouroQueryInput
}

export type QueryImovelArgs = {
  imovelId: Scalars['ID']
}

export type QueryImportacaoCnesArgs = {
  id: Scalars['ID']
}

export type QueryImportacoesCnesArgs = {
  input: ImportacoesCnesQueryInput
}

export type QueryImunobiologicosArgs = {
  input: ImunobiologicoQueryInput
}

export type QueryImunobiologicosRelatorioGerencialVacinacaoArgs = {
  input?: Maybe<Scalars['String']>
}

export type QueryIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoArgs = {
  tipoCoMs: Scalars['Long']
  subtipoCoMs?: Maybe<Scalars['String']>
}

export type QueryIsParticipantePermitidoVideochamadaArgs = {
  input?: Maybe<VerificarParticipantePermitidoVideochamadaInput>
}

export type QueryIsProcessamentoTerritorioRunningArgs = {
  token: Scalars['ID']
}

export type QueryLembretesArgs = {
  input: LembretesQueryInput
}

export type QueryListaMedicamentoArgs = {
  input: ListaMedicamentoAtivoConcluidoQueryInput
}

export type QueryListagemCidadaosArgs = {
  input: CidadaosQueryInput
}

export type QueryLocaisAplicacaoArgs = {
  input: LocalAplicacaoQueryInput
}

export type QueryLocaisOcorrenciaArgs = {
  input: LocalOcorrenciaQueryInput
}

export type QueryLocalAtendimentoArgs = {
  input: LocalAtendimentoQueryInput
}

export type QueryLogradourosArgs = {
  input: LogradouroInput
}

export type QueryLotacaoArgs = {
  id: Scalars['ID']
}

export type QueryLotacoesAgendaArgs = {
  input?: Maybe<LotacoesAgendaInput>
}

export type QueryLotacoesAndEstagiosArgs = {
  input?: Maybe<LotacoesAndEstagiosInput>
}

export type QueryLotacoesListaCuidadoCompartilhadoArgs = {
  input: LotacoesListaCuidadoCompartilhadoQueryInput
}

export type QueryLoteEnvioArgs = {
  idLote: Scalars['ID']
}

export type QueryLoteHasCadastroArgs = {
  input: LoteImunobiologicoInput
}

export type QueryLotesEnvioArgs = {
  input: LotesEnvioFiltro
}

export type QueryLotesImunobiologicoArgs = {
  input?: Maybe<LoteImunobiologicoQueryInput>
}

export type QueryManifestacoesAlergiaArgs = {
  input?: Maybe<ManifestacoesAlergiaQueryInput>
}

export type QueryMarcadorConsumoAlimentarArgs = {
  atendProfId: Scalars['ID']
}

export type QueryMarcosDesenvolvimentoArgs = {
  input: MarcoDesenvolvimentoLoadInput
}

export type QueryMedicamentosArgs = {
  input: MedicamentoQueryInput
}

export type QueryMedicamentosCatmatArgs = {
  input: MedicamentoCatmatQueryInput
}

export type QueryMedicoesArgs = {
  input: MedicoesQueryInput
}

export type QueryMesAgendaArgs = {
  input?: Maybe<MesAgendaQueryInput>
}

export type QueryMesesRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type QueryMicroareasArgs = {
  equipeResponsavelId?: Maybe<Scalars['ID']>
}

export type QueryMicroareasImoveisArgs = {
  input: TerritorioEquipeQueryInput
}

export type QueryModeloPersonalizadoRelatorioGerencialByIdArgs = {
  id: Scalars['ID']
}

export type QueryModelosPersonalizadosByTipoModuloArgs = {
  tipoModulo: TipoModuloEnum
}

export type QueryMunicipiosArgs = {
  input: MunicipiosQueryInput
}

export type QueryMunicipiosRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type QueryMunicipiosResponsaveisArgs = {
  input: MunicipioResponsavelFiltroInput
}

export type QueryNomesOutrosProfissionaisAgendamentosConflitantesArgs = {
  input: AgendamentoConflitanteInput
}

export type QueryOdontogramaArgs = {
  id: Scalars['ID']
}

export type QueryOdontogramasArgs = {
  input: OdontogramasQueryInput
}

export type QueryOrientacoesArgs = {
  input: OrientacaoQueryInput
}

export type QueryPaisesArgs = {
  input: PaisQueryInput
}

export type QueryPartesBucaisArgs = {
  input?: Maybe<PartesBucaisQueryInput>
}

export type QueryPerfilArgs = {
  id: Scalars['ID']
}

export type QueryPerfisArgs = {
  input: PerfisQueryInput
}

export type QueryPeriogramasCompletoArgs = {
  input: PeriogramaCompletoQueryInput
}

export type QueryPeriogramasSimplificadosArgs = {
  input: PeriogramaSimplificadoQueryInput
}

export type QueryPlanosDeCuidadoArgs = {
  atendProfId: Scalars['ID']
}

export type QueryPolosBasesArgs = {
  input: PoloBaseInput
}

export type QueryPossuiAgendamentoMesmoProfissionalDataFuturaArgs = {
  input: AgendamentoConflitanteInput
}

export type QueryPossuiAtendimentosNaoFinalizadosArgs = {
  input?: Maybe<PossuiAtendimentosNaoFinalizadosInput>
}

export type QueryPossuiTratamentoConcluidoArgs = {
  input: PossuiTratamentoConcluidoQueryInput
}

export type QueryProblemaArgs = {
  id: Scalars['ID']
}

export type QueryProblemaByCiapCidArgs = {
  input: ProblemaByCiapCidQueryInput
}

export type QueryProblemasArgs = {
  input: ProblemaQueryInput
}

export type QueryProcedimentosArgs = {
  input: ProcedimentoQueryInput
}

export type QueryProcedimentosEvolucaoOdontoArgs = {
  input?: Maybe<ProcedimentoOdontoQueryInput>
}

export type QueryProfissionaisArgs = {
  input: ProfissionaisQueryInput
}

export type QueryProfissionaisAdArgs = {
  equipeFilter: EquipesAdQueryInput
  profissionalFilter: ProfissionaisAdQueryInput
}

export type QueryProfissionaisRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type QueryProfissionaisRelatorioInconsistenciaArgs = {
  filtro: ProfissionaisRelatorioInconsistenciaQueryInput
}

export type QueryProfissionalArgs = {
  id: Scalars['ID']
}

export type QueryProfissionalByCpfContraChaveArgs = {
  input: CpfContraChaveInput
}

export type QueryProximaDoseAprazamentoAutomaticoArgs = {
  input: ProximaDoseAprazamentoQueryInput
}

export type QueryRecursosArgs = {
  tipoAcesso?: Maybe<TipoAcesso>
}

export type QueryRegistrosAplicadosVacinacaoArgs = {
  input: RegistrosAplicadosVacinacaoInput
}

export type QueryRegistrosDoseVacinacaoArgs = {
  input: RegistrosDoseVacinacaoInput
}

export type QueryRelatorioProducaoAtendimentoDomiciliarArgs = {
  input: RelatorioProducaoAtendimentoDomiciliarQueryInput
}

export type QueryRelatorioProducaoAtendimentoIndividualArgs = {
  input: RelatorioProducaoAtendimentoIndividualQueryInput
}

export type QueryRelatorioProducaoAtendimentoOdontoArgs = {
  input: RelatorioProducaoAtendimentoOdontoQueryInput
}

export type QueryRelatorioProducaoAtividadeColetivaArgs = {
  input: RelatorioProducaoAtividadeColetivaQueryInput
}

export type QueryRelatorioProducaoProcedimentosIndividualizadosArgs = {
  input: RelatorioProducaoProcedimentosIndividualizadosQueryInput
}

export type QueryRelatorioProducaoVacinacaoArgs = {
  input: RelatorioProducaoVacinacaoQueryInput
}

export type QueryRelatorioProducaoVisitaDomiciliarTerritorialArgs = {
  input: RelatorioProducaoVisitaDomiciliarTerritorialQueryInput
}

export type QueryResultadosExameArgs = {
  input: ResultadosExameQueryInput
}

export type QueryResumoEnviosRndsArgs = {
  input: TipoRegistroRndsEnum
}

export type QuerySerieHistoricaArgs = {
  input: SerieHistoricaQueryInput
}

export type QuerySexosRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type QuerySincronizacaoCadsusPollingArgs = {
  uuid: Scalars['String']
}

export type QuerySolicitacoesExameArgs = {
  input: SolicitacoesExameQueryInput
}

export type QuerySolicitacoesExamePagedArgs = {
  input: SolicitacoesExameQueryInput
}

export type QuerySubstanciasCbaraAlergiaArgs = {
  input: SubstanciasCbaraAlergiaQueryInput
}

export type QuerySubtipoAtendimentoHistoricoArgs = {
  input?: Maybe<SubtipoAtendimentoHistoricoClinicoQueryInput>
}

export type QuerySugestoesDatasArgs = {
  input: SugestoesDatasInput
}

export type QuerySugestoesProfissionaisArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryTecidosMolesArgs = {
  input: TecidosMolesQueryInput
}

export type QueryTerritorioEquipeArgs = {
  input: TerritorioEquipeQueryInput
}

export type QueryTerritorioLogradouroArgs = {
  input: TerritorioLogradouroQueryInput
}

export type QueryTerritorioMicroareaArgs = {
  input: TerritorioMicroareaQueryInput
}

export type QueryTipoAleitamentoMaternoArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryTipoRegistroHistoricoArgs = {
  input?: Maybe<TipoRegistroHistoricoClinicoQueryInput>
}

export type QueryTiposLogradouroArgs = {
  input: TipoLogradouroInput
}

export type QueryTiposProducaoRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type QueryTiposServicoArgs = {
  input: TipoServicoQueryInput
}

export type QueryTodasVinculacoesEquipeEmultiArgs = {
  input: TodasVinculacoesEquipesInput
}

export type QueryTransmissaoLinkArgs = {
  input: TransmissaoLinkPagingQueryFiltroDto
}

export type QueryTransmissaoRecebimentoCnesArgs = {
  input: TransmissaoRecebimentoCnesFiltro
}

export type QueryTransmissaoRecebimentoCnesDetailItemsArgs = {
  input: TransmissaoRecebimentoCnesDetailFiltro
}

export type QueryTransmissaoRecebimentoLotesArgs = {
  input: TransmissaoRecebimentoLoteFiltro
}

export type QueryTurnosRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type QueryUltimaAvaliacaoMultidimensionalArgs = {
  input: AcompanhamentoIdosoQueryInput
}

export type QueryUltimaVacinaAprazadaArgs = {
  input: VacinacaoUltimaVacinaInput
}

export type QueryUltimasMedicoesDiasAnterioresArgs = {
  filtro: UltimasMedicoesInput
}

export type QueryUltimasMedicoesDoDiaArgs = {
  filtro: UltimasMedicoesInput
}

export type QueryUltimasTresVacinasAplicadasArgs = {
  input: VacinacaoUltimaVacinaInput
}

export type QueryUltimoArquivoProntoEmProcessamentoArgs = {
  input: ArquivoQueryInput
}

export type QueryUltimoAtendimentoProfissionalCidadaoArgs = {
  input?: Maybe<UltimoAtendimentoProfissionalFinalizadoQueryInput>
}

export type QueryUltimoAtendimentoPuericulturaArgs = {
  prontuarioId: Scalars['ID']
}

export type QueryUltimosCincoArquivosArgs = {
  arquivoIdentificador: ArquivoIdentificadorEnum
}

export type QueryUnidadeSaudeArgs = {
  id: Scalars['ID']
}

export type QueryUnidadeSaudeTiposServicoArgs = {
  input: UnidadeSaudeTipoServicoQueryInput
}

export type QueryUnidadesConsultaHorusArgs = {
  id: Scalars['ID']
}

export type QueryUnidadesMedidaArgs = {
  input?: Maybe<UnidadeMedidaQueryInput>
}

export type QueryUnidadesSaudeArgs = {
  input: UnidadesSaudeQueryInput
}

export type QueryUnidadesSaudeByGarantiaAcessoCidadaoArgs = {
  input: UnidadesSaudeByGarantiaAcessoCidadaoInput
}

export type QueryUnidadesSaudeByTipoVinculacaoServicoArgs = {
  input: UnidadesSaudeByTipoVinculacaoServicoInput
}

export type QueryUnidadesSaudeRelatorioGerencialArgs = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type QueryUnificacoesCidadaoArgs = {
  cidadaoId: Scalars['ID']
}

export type QueryValidatePermissaoNovoAdminArgs = {
  input: ValidateNovoAdminInput
}

export type QueryValidateResetAdminArgs = {
  input: CpfContraChaveInput
}

export type QueryViasAdministracaoVacinaArgs = {
  inputQuery?: Maybe<Scalars['String']>
}

export type QueryVideochamadaArgs = {
  uuid: Scalars['String']
}

export type QueryVinculacaoFichaCadastroIndividualArgs = {
  input: CidadaoVinculacaoFichaQueryInput
}

export type QueryVinculacaoServicoArgs = {
  referenciaId: Scalars['Long']
}

export type QueryVinculacoesServicoArgs = {
  input: VinculacaoServicosInput
}

export type QueryVisitaArgs = {
  id: Scalars['ID']
}

export type QueryVisitasArgs = {
  input: VisitasQueryInput
}

export type RacaCor = {
  __typename?: 'RacaCor'
  id: Scalars['ID']
  nome: Scalars['String']
  codigoMS: Scalars['String']
  codigoCadsus: Scalars['String']
  racaCorDbEnum: RacaCorDbEnum
}

export enum RacaCorDbEnum {
  BRANCA = 'BRANCA',
  PRETA = 'PRETA',
  PARDA = 'PARDA',
  AMARELA = 'AMARELA',
  INDIGENA = 'INDIGENA',
  SEM_INFORMACAO = 'SEM_INFORMACAO',
}

export enum RacionalidadeEmSaudeEnum {
  MEDICINA_TRADICIONAL_CHINESA = 'MEDICINA_TRADICIONAL_CHINESA',
  ANTROPOSOFIA_APLICADA_A_SAUDE = 'ANTROPOSOFIA_APLICADA_A_SAUDE',
  HOMEOPATIA = 'HOMEOPATIA',
  FITOTERAPIA = 'FITOTERAPIA',
  AYUVEDA = 'AYUVEDA',
  OUTRA = 'OUTRA',
}

export type ReceitaMedicamento = {
  __typename?: 'ReceitaMedicamento'
  id: Scalars['ID']
  medicamento: Medicamento
  aplicacaoMedicamento: AplicacaoMedicamento
  quantidadeReceitada: Scalars['Int']
  recomendacoes?: Maybe<Scalars['String']>
  dose?: Maybe<Scalars['String']>
  qtDose?: Maybe<Scalars['String']>
  qtDoseManha?: Maybe<Scalars['String']>
  qtDoseTarde?: Maybe<Scalars['String']>
  qtDoseNoite?: Maybe<Scalars['String']>
  unidadeMedidaDose?: Maybe<UnidadeMedida>
  frequenciaDose?: Maybe<Scalars['String']>
  posologia: Scalars['String']
  tipoFrequenciaDose?: Maybe<TipoFrequencia>
  qtPeriodoFrequencia?: Maybe<Scalars['Int']>
  medidaTempoFrequencia?: Maybe<UnidadeMedidaTempoEnum>
  dtInicioTratamento?: Maybe<Scalars['Instant']>
  dtFimTratamento?: Maybe<Scalars['Instant']>
  qtDuracaoTratamento?: Maybe<Scalars['Int']>
  medidaTempoTratamento?: Maybe<UnidadeMedidaTempoEnum>
  usoContinuo?: Maybe<Scalars['Boolean']>
  registroManual?: Maybe<Scalars['Boolean']>
  doseUnica?: Maybe<Scalars['Boolean']>
  tipoReceita?: Maybe<TipoReceitaEnum>
  codigoPrescricaoDigital?: Maybe<Scalars['String']>
  tratamentoInterrompido?: Maybe<Scalars['Boolean']>
  justificativaInterrupcao?: Maybe<Scalars['String']>
  dtInterrupcao?: Maybe<Scalars['Instant']>
  atendProf?: Maybe<AtendimentoProfissional>
}

export type RedefinirSenhaAdminInstalacaoInput = {
  cpf?: Maybe<Scalars['String']>
  contraChave?: Maybe<Scalars['String']>
  novaSenha: Scalars['String']
}

export type RedefinirSenhaInput = {
  usuario: Scalars['String']
  token: Scalars['String']
  novaSenha: Scalars['String']
}

export type ReferenceMonth = {
  month: Scalars['Int']
  year: Scalars['Int']
}

export type RegistroAplicado = {
  __typename?: 'RegistroAplicado'
  imunobiologicoId: Scalars['ID']
  imunobiologicoSigla: Scalars['String']
  imunobiologicoNome: Scalars['String']
  imunobiologicoNomeFiltro: Scalars['String']
  doseId: Scalars['ID']
  doseNomeApresentacao: Scalars['String']
  doseNome: Scalars['String']
  doseOrdem: Scalars['Int']
  doseSigla: Scalars['String']
  dataAprazamento?: Maybe<Scalars['LocalDate']>
  dataRegistroAprazamento?: Maybe<Scalars['Instant']>
  dataAplicacao?: Maybe<Scalars['LocalDate']>
  quantidadeAplicacoes: Scalars['Int']
}

export type RegistroAvaliacaoPuericultura = {
  __typename?: 'RegistroAvaliacaoPuericultura'
  ciap: Ciap
  cid10: Cid10
}

export type RegistroDose = {
  __typename?: 'RegistroDose'
  lote?: Maybe<Scalars['String']>
  viaAdministracao?: Maybe<Scalars['String']>
  localAplicacao?: Maybe<Scalars['String']>
  estrategia?: Maybe<Scalars['String']>
  dataAplicacao?: Maybe<Scalars['LocalDate']>
  dataAprazamento?: Maybe<Scalars['LocalDate']>
  dataRegistro?: Maybe<Scalars['Instant']>
  dataValidadeLote?: Maybe<Scalars['LocalDate']>
  profissional?: Maybe<Scalars['String']>
  grupoAtendimento?: Maybe<Scalars['String']>
  descricao?: Maybe<Scalars['String']>
  fabricante?: Maybe<Scalars['String']>
  cbo?: Maybe<Scalars['String']>
  isGestante?: Maybe<Scalars['Boolean']>
  isPuerpera?: Maybe<Scalars['Boolean']>
  isViajante?: Maybe<Scalars['Boolean']>
  isComunicanteHanseniase?: Maybe<Scalars['Boolean']>
  unidadeSaude?: Maybe<Scalars['String']>
  origemDados?: Maybe<OrigemAtendimento>
  isRegistroAnterior?: Maybe<Scalars['Boolean']>
  isAplicadoExterior?: Maybe<Scalars['Boolean']>
  tipoCdsOrigem?: Maybe<Scalars['Long']>
  tipoOrigemDadoTransp?: Maybe<Scalars['Long']>
}

export type RegistroManualMedicamentoInput = {
  id?: Maybe<Scalars['ID']>
  medicamentoText?: Maybe<Scalars['String']>
  concentracao?: Maybe<Scalars['String']>
  formaFarmaceutica: Scalars['ID']
  unidadeFornecimento: Scalars['String']
}

export type RegistroObitoInput = {
  dataObito: Scalars['LocalDate']
  numeroDeclaracaoObito?: Maybe<Scalars['String']>
}

export type RegistrosAplicadosVacinacaoInput = {
  prontuarioId: Scalars['ID']
  cidadaoId: Scalars['ID']
  cpf?: Maybe<Scalars['String']>
}

export type RegistrosDoseVacinacaoInput = {
  prontuarioId: Scalars['ID']
  imunobiologicoId: Scalars['ID']
  doseId: Scalars['ID']
  cpf?: Maybe<Scalars['String']>
  cidadaoId: Scalars['ID']
}

export type RegistroTardioInput = {
  atendimentoId?: Maybe<Scalars['ID']>
  cidadaoId: Scalars['ID']
  atorPapelId: Scalars['ID']
  dataHorarioAtendimento: Scalars['Instant']
  localAtendimentoId: Scalars['ID']
  justificativa?: Maybe<RegistroTardioJustificativaEnum>
}

export enum RegistroTardioJustificativaEnum {
  FALTA_ENERGIA_ELETRICA = 'FALTA_ENERGIA_ELETRICA',
  PEC_INDISPONIVEL = 'PEC_INDISPONIVEL',
  COMPUTADOR_INOPERANTE = 'COMPUTADOR_INOPERANTE',
  OUTRO = 'OUTRO',
}

export type RegistroVacinacao = {
  __typename?: 'RegistroVacinacao'
  id: Scalars['ID']
  vacinacao: AtendimentoVacinacao
  tipoRegistroVacinacao: TipoRegistroVacinacao
  isRegistroAnterior?: Maybe<Scalars['Boolean']>
  imunobiologico: Imunobiologico
  estrategiaVacinacao?: Maybe<EstrategiaVacinacao>
  grupoAtendimento?: Maybe<GrupoAtendimento>
  doseImunobiologico: DoseImunobiologico
  dataAprazamento?: Maybe<Scalars['Instant']>
  dataAplicacao?: Maybe<Scalars['Instant']>
  loteImunobiologico?: Maybe<LoteImunobiologico>
  viaAdministracao?: Maybe<ViaAdministracao>
  localAplicacaoVacinacao?: Maybe<LocalAplicacao>
  observacoes?: Maybe<Scalars['String']>
  isAplicadoExterior?: Maybe<Scalars['Boolean']>
}

export type RegistroVacinacaoInput = {
  tipoRegistroVacinacao: TipoRegistroVacinacaoEnum
  isRegistroAnterior: Scalars['Boolean']
  isCadastrarNovoLote: Scalars['Boolean']
  imunobiologicoId: Scalars['ID']
  estrategiaVacinacao?: Maybe<EstrategiaVacinacaoEnum>
  doseImunobiologicoId: Scalars['ID']
  dataAprazamento?: Maybe<Scalars['LocalDate']>
  dataAplicacao?: Maybe<Scalars['LocalDate']>
  viaAdministracaoId?: Maybe<Scalars['ID']>
  localAplicacaoVacinacaoId?: Maybe<Scalars['ID']>
  observacoes?: Maybe<Scalars['String']>
  grupoAtendimentoId?: Maybe<Scalars['ID']>
  loteImunobiologicoId?: Maybe<Scalars['ID']>
  loteImunobiologicoCadastro?: Maybe<LoteImunobiologicoVacinacaoInput>
  isAplicadoExterior?: Maybe<Scalars['Boolean']>
  isPesquisaEstrategia?: Maybe<Scalars['Boolean']>
}

export type RegraVacinal = {
  __typename?: 'RegraVacinal'
  id: Scalars['ID']
  imunobiologico: Imunobiologico
  dose: DoseImunobiologico
  estrategia: EstrategiaVacinacao
}

export enum RelacaoCidadaoCuidador {
  NAO_POSSUI = 'NAO_POSSUI',
  CONJUGE_COMPANHEIRO = 'CONJUGE_COMPANHEIRO',
  FILHO_ENTEADO = 'FILHO_ENTEADO',
  PAI_MAE = 'PAI_MAE',
  AVO = 'AVO',
  NETO = 'NETO',
  IRMAO = 'IRMAO',
  OUTRO = 'OUTRO',
}

export type RelatorioProducaoAtendimentoDomiciliar = {
  __typename?: 'RelatorioProducaoAtendimentoDomiciliar'
  resumoProducao: Array<Maybe<QuadroRelatorioItem>>
  turno: Array<Maybe<QuadroRelatorioItem>>
  sexo: Array<Maybe<QuadroRelatorioItem>>
  faixaEtaria: Array<Maybe<QuadroRelatorioFaixaEtariaItem>>
  localAtendimento: Array<Maybe<QuadroRelatorioItem>>
  tipoAtendimento: Array<Maybe<QuadroRelatorioItem>>
}

export type RelatorioProducaoAtendimentoDomiciliarQueryInput = {
  inicio: Scalars['LocalDate']
  fim: Scalars['LocalDate']
  dimMunicipio?: Maybe<Scalars['ID']>
  dimUnidadeSaude?: Maybe<Scalars['ID']>
  dimEquipe?: Maybe<Scalars['ID']>
  dimProfissional?: Maybe<Scalars['ID']>
  dimCategoriaProfissional?: Maybe<Scalars['ID']>
  dimsSexo?: Maybe<Array<Maybe<DimSexoEnum>>>
  dimsFaixaEtaria?: Maybe<Array<Maybe<DimFaixaEtariaEnum>>>
  producaoUsuario?: Maybe<NivelProducaoEnum>
}

export type RelatorioProducaoAtendimentoIndividual = {
  __typename?: 'RelatorioProducaoAtendimentoIndividual'
  resumoProducao: Array<Maybe<QuadroRelatorioItem>>
  turno: Array<Maybe<QuadroRelatorioItem>>
  sexo: Array<Maybe<QuadroRelatorioItem>>
  faixaEtaria: Array<Maybe<QuadroRelatorioFaixaEtariaItem>>
  localAtendimento: Array<Maybe<QuadroRelatorioItem>>
  tipoAtendimento: Array<Maybe<QuadroRelatorioItem>>
  problemasCondicoesAvaliadas: Array<Maybe<QuadroRelatorioItem>>
  problemasCondicoesAvaliadasDoencasTransmissiveis: Array<Maybe<QuadroRelatorioItem>>
  problemasCondicoesAvaliadasOutrosCiap: Array<Maybe<QuadroRelatorioItem>>
  problemasCondicoesAvaliadasOutrosCid: Array<Maybe<QuadroRelatorioItem>>
  examesSolicitadosAvaliados: Array<Maybe<QuadroRelatorioExameItem>>
  examesTriagemNeonatal: Array<Maybe<QuadroRelatorioExameItem>>
  outrosExamesSolicitadosAvaliadosSigtap: Array<Maybe<QuadroRelatorioExameItem>>
}

export type RelatorioProducaoAtendimentoIndividualQueryInput = {
  inicio: Scalars['LocalDate']
  fim: Scalars['LocalDate']
  dimMunicipio?: Maybe<Scalars['ID']>
  dimUnidadeSaude?: Maybe<Scalars['ID']>
  dimEquipe?: Maybe<Scalars['ID']>
  dimProfissional?: Maybe<Scalars['ID']>
  dimCategoriaProfissional?: Maybe<Scalars['ID']>
  dimsSexo?: Maybe<Array<Maybe<DimSexoEnum>>>
  dimsFaixaEtaria?: Maybe<Array<Maybe<DimFaixaEtariaEnum>>>
  producaoUsuario?: Maybe<NivelProducaoEnum>
}

export type RelatorioProducaoAtendimentoOdonto = {
  __typename?: 'RelatorioProducaoAtendimentoOdonto'
  resumoProducao: Array<Maybe<QuadroRelatorioItem>>
  turno: Array<Maybe<QuadroRelatorioItem>>
  sexo: Array<Maybe<QuadroRelatorioItem>>
  faixaEtaria: Array<Maybe<QuadroRelatorioFaixaEtariaItem>>
  localAtendimento: Array<Maybe<QuadroRelatorioItem>>
  tipoAtendimento: Array<Maybe<QuadroRelatorioItem>>
  procedimentos: Array<Maybe<QuadroRelatorioItem>>
  outrosProcedimentos: Array<Maybe<QuadroRelatorioItem>>
}

export type RelatorioProducaoAtendimentoOdontoQueryInput = {
  inicio: Scalars['LocalDate']
  fim: Scalars['LocalDate']
  dimMunicipio?: Maybe<Scalars['ID']>
  dimUnidadeSaude?: Maybe<Scalars['ID']>
  dimEquipe?: Maybe<Scalars['ID']>
  dimProfissional?: Maybe<Scalars['ID']>
  dimCategoriaProfissional?: Maybe<Scalars['ID']>
  dimsSexo?: Maybe<Array<Maybe<DimSexoEnum>>>
  dimsFaixaEtaria?: Maybe<Array<Maybe<DimFaixaEtariaEnum>>>
  producaoUsuario?: Maybe<NivelProducaoEnum>
}

export type RelatorioProducaoAtividadeColetiva = {
  __typename?: 'RelatorioProducaoAtividadeColetiva'
  resumoProducao: Array<Maybe<QuadroRelatorioItem>>
  turno: Array<Maybe<QuadroRelatorioItem>>
  programaSaudeEscola: Array<Maybe<QuadroRelatorioItem>>
  atividade: Array<Maybe<QuadroRelatorioItem>>
  publicoAlvo: Array<Maybe<QuadroRelatorioItem>>
  temasSaude: Array<Maybe<QuadroRelatorioItem>>
  temasReuniao: Array<Maybe<QuadroRelatorioItem>>
}

export type RelatorioProducaoAtividadeColetivaQueryInput = {
  inicio: Scalars['LocalDate']
  fim: Scalars['LocalDate']
  dimMunicipio?: Maybe<Scalars['ID']>
  dimUnidadeSaude?: Maybe<Scalars['ID']>
  dimEquipe?: Maybe<Scalars['ID']>
  dimProfissional?: Maybe<Scalars['ID']>
  dimCategoriaProfissional?: Maybe<Scalars['ID']>
  producaoUsuario?: Maybe<NivelProducaoEnum>
}

export type RelatorioProducaoProcedimentosIndividualizados = {
  __typename?: 'RelatorioProducaoProcedimentosIndividualizados'
  resumoProducao: Array<Maybe<QuadroRelatorioItem>>
  turno: Array<Maybe<QuadroRelatorioItem>>
  sexo: Array<Maybe<QuadroRelatorioItem>>
  faixaEtaria: Array<Maybe<QuadroRelatorioFaixaEtariaItem>>
  localAtendimento: Array<Maybe<QuadroRelatorioItem>>
  outrosProcedimentosSigtap: Array<Maybe<QuadroRelatorioItem>>
  procedimentosPequenasCirurgias: Array<Maybe<QuadroRelatorioItem>>
  procedimentosTesteRapido: Array<Maybe<QuadroRelatorioItem>>
  procedimentosAdministracaoMedicamentos: Array<Maybe<QuadroRelatorioItem>>
}

export type RelatorioProducaoProcedimentosIndividualizadosQueryInput = {
  inicio: Scalars['LocalDate']
  fim: Scalars['LocalDate']
  dimMunicipio?: Maybe<Scalars['ID']>
  dimUnidadeSaude?: Maybe<Scalars['ID']>
  dimEquipe?: Maybe<Scalars['ID']>
  dimProfissional?: Maybe<Scalars['ID']>
  dimCategoriaProfissional?: Maybe<Scalars['ID']>
  dimsSexo?: Maybe<Array<Maybe<DimSexoEnum>>>
  dimsFaixaEtaria?: Maybe<Array<Maybe<DimFaixaEtariaEnum>>>
  producaoUsuario?: Maybe<NivelProducaoEnum>
}

export type RelatorioProducaoVacinacao = {
  __typename?: 'RelatorioProducaoVacinacao'
  resumoProducao: Array<Maybe<QuadroRelatorioItem>>
  turno: Array<Maybe<QuadroRelatorioItem>>
  sexo: Array<Maybe<QuadroRelatorioItem>>
  faixaEtaria: Array<Maybe<QuadroRelatorioFaixaEtariaItem>>
  localAtendimento: Array<Maybe<QuadroRelatorioItem>>
}

export type RelatorioProducaoVacinacaoQueryInput = {
  inicio: Scalars['LocalDate']
  fim: Scalars['LocalDate']
  dimMunicipio?: Maybe<Scalars['ID']>
  dimUnidadeSaude?: Maybe<Scalars['ID']>
  dimEquipe?: Maybe<Scalars['ID']>
  dimProfissional?: Maybe<Scalars['ID']>
  dimCategoriaProfissional?: Maybe<Scalars['ID']>
  dimsSexo?: Maybe<Array<Maybe<DimSexoEnum>>>
  dimsFaixaEtaria?: Maybe<Array<Maybe<DimFaixaEtariaEnum>>>
  producaoUsuario?: Maybe<NivelProducaoEnum>
}

export type RelatorioProducaoVisitaDomiciliarTerritorial = {
  __typename?: 'RelatorioProducaoVisitaDomiciliarTerritorial'
  resumoProducao: Array<Maybe<QuadroRelatorioItem>>
  turno: Array<Maybe<QuadroRelatorioItem>>
  sexo: Array<Maybe<QuadroRelatorioItem>>
  faixaEtaria: Array<Maybe<QuadroRelatorioFaixaEtariaItem>>
  tipoImovel: Array<Maybe<QuadroRelatorioItem>>
  desfecho: Array<Maybe<QuadroRelatorioItem>>
  motivoVisitaGeral: Array<Maybe<QuadroRelatorioItem>>
  motivoVisitaAcompanhamento: Array<Maybe<QuadroRelatorioItem>>
  motivoVisitaControleAmbientalVetorial: Array<Maybe<QuadroRelatorioItem>>
  visitaCompartilhada: Array<Maybe<QuadroRelatorioItem>>
  glicemia: Array<Maybe<QuadroRelatorioItem>>
  antropometria: Array<Maybe<QuadroRelatorioItem>>
  sinaisVitais: Array<Maybe<QuadroRelatorioItem>>
}

export type RelatorioProducaoVisitaDomiciliarTerritorialQueryInput = {
  inicio: Scalars['LocalDate']
  fim: Scalars['LocalDate']
  dimMunicipio?: Maybe<Scalars['ID']>
  dimUnidadeSaude?: Maybe<Scalars['ID']>
  dimEquipe?: Maybe<Scalars['ID']>
  dimProfissional?: Maybe<Scalars['ID']>
  dimCategoriaProfissional?: Maybe<Scalars['ID']>
  dimsSexo?: Maybe<Array<Maybe<DimSexoEnum>>>
  dimsFaixaEtaria?: Maybe<Array<Maybe<DimFaixaEtariaEnum>>>
  producaoUsuario?: Maybe<NivelProducaoEnum>
}

export enum RendaFamiliar {
  _1_4_SALARIO_MINIMO = '_1_4_SALARIO_MINIMO',
  _1_2_SALARIO_MINIMO = '_1_2_SALARIO_MINIMO',
  _1_SALARIO_MINIMO = '_1_SALARIO_MINIMO',
  _2_SALARIOS_MINIMOS = '_2_SALARIOS_MINIMOS',
  _3_SALARIOS_MINIMOS = '_3_SALARIOS_MINIMOS',
  _4_SALARIOS_MINIMOS = '_4_SALARIOS_MINIMOS',
  ACIMA_4_SALARIOS_MINIMOS = 'ACIMA_4_SALARIOS_MINIMOS',
  AUSENCIA_RENDA = 'AUSENCIA_RENDA',
}

export type ResponderSolicitacaoEntradaVideochamadaInput = {
  videochamadaUuid: Scalars['String']
  participanteId: Scalars['String']
  resposta: RespostaSolicitacaoEntradaVideochamadaEnum
}

export type ResponsavelTecnicoImovel = {
  __typename?: 'ResponsavelTecnicoImovel'
  id: Scalars['ID']
  nome: Scalars['String']
  contato?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  cargo?: Maybe<Scalars['String']>
}

export type ResponsavelTecnicoInput = {
  nome: Scalars['String']
  contato?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  cargo?: Maybe<Scalars['String']>
}

export type RespostaMarcadoresConsumoAlimentar = {
  __typename?: 'RespostaMarcadoresConsumoAlimentar'
  pergunta: PerguntasQuestionarioEnum
  opcoesSelecionadas: Array<OpcaoTipoPerguntaEnum>
}

export type RespostaMarcadoresConsumoAlimentarInput = {
  pergunta: PerguntasQuestionarioEnum
  opcoesSelecionadas: Array<Maybe<OpcaoTipoPerguntaEnum>>
}

export type RespostaPesquisa = {
  grauSatisfacao?: Maybe<GrauSatisfacaoUsuario>
  observacao?: Maybe<Scalars['String']>
  tipoResposta?: Maybe<TipoRespostaPesquisa>
  enviaMunicipio?: Maybe<Scalars['Boolean']>
}

export enum RespostaSolicitacaoEntradaVideochamadaEnum {
  ACEITA = 'ACEITA',
  NEGADA = 'NEGADA',
  TIMEOUT = 'TIMEOUT',
  VIDEOCHAMADA_LOTADA = 'VIDEOCHAMADA_LOTADA',
}

export type ResultadoExame = {
  __typename?: 'ResultadoExame'
  id: Scalars['ID']
  exame: Procedimento
  dataSolicitacao?: Maybe<Scalars['LocalDate']>
  dataRealizacao?: Maybe<Scalars['LocalDate']>
  dataResultado?: Maybe<Scalars['LocalDate']>
  resultado?: Maybe<Scalars['String']>
  conferido?: Maybe<Scalars['Boolean']>
  observacao?: Maybe<Scalars['String']>
  especifico?: Maybe<ResultadoExameEspecifico>
  atendimentoProfissional?: Maybe<AtendimentoProfissional>
  solicitacao?: Maybe<SolicitacaoExame>
}

export type ResultadoExameEspecializadoInput = {
  procedimento: ExameEspecificoEnum
  valor?: Maybe<Scalars['Float']>
  igDias?: Maybe<Scalars['Int']>
  igSemanas?: Maybe<Scalars['Int']>
  dpp?: Maybe<Scalars['LocalDate']>
  fichaComplementarPuericultura?: Maybe<FichaComplementarZikaMicrocefaliaResultadoExameEnum>
}

export type ResultadoExameEspecifico = {
  __typename?: 'ResultadoExameEspecifico'
  id: Scalars['ID']
  exameEspecificoEnum: ExameEspecificoEnum
  valor?: Maybe<Scalars['Float']>
  igDias?: Maybe<Scalars['Int']>
  igSemanas?: Maybe<Scalars['Int']>
  dpp?: Maybe<Scalars['LocalDate']>
  resultadoExamePuericulturaEnum?: Maybe<FichaComplementarZikaMicrocefaliaResultadoExameEnum>
}

export type ResultadoExameInput = {
  id?: Maybe<Scalars['ID']>
  exameId?: Maybe<Scalars['ID']>
  dataSolicitacao?: Maybe<Scalars['LocalDate']>
  dataRealizacao: Scalars['LocalDate']
  dataResultado?: Maybe<Scalars['LocalDate']>
  resultado?: Maybe<Scalars['String']>
  especifico?: Maybe<ResultadoExameEspecializadoInput>
}

export type ResultadosExamePayload = {
  __typename?: 'ResultadosExamePayload'
  content: Array<Maybe<ResultadoExame>>
  pageInfo: PageInfo
}

export type ResultadosExameQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
  pageParams?: Maybe<PageParams>
}

export type ResultadoTesteConexao = {
  __typename?: 'ResultadoTesteConexao'
  link: TransmissaoLink
  validationDescription: Scalars['String']
}

export type ReterritorializarInput = {
  novaMicroarea: Scalars['String']
  lotacaoId: Scalars['ID']
  imoveisId: Array<Scalars['ID']>
  progressToken: Scalars['String']
}

export enum RiscoGravidez {
  HABITUAL = 'HABITUAL',
  ALTO = 'ALTO',
}

export type RlUnidadeSaudeTipoServicoIds = {
  __typename?: 'RlUnidadeSaudeTipoServicoIds'
  unidadeSaudeId: Scalars['ID']
  tipoServicoId: Scalars['ID']
}

export type SalvarCredencialRecebimentoExternoPayload = {
  __typename?: 'SalvarCredencialRecebimentoExternoPayload'
  credencialRecebimento: CredencialRecebimentoExterno
  userPassword: CredencialRecebimentoExternoUserPasswordPayload
}

export type SalvarCuidadoCompartilhadoInput = {
  cuidadoCompartilhadoId: Scalars['ID']
  reclassificacaoPrioridade?: Maybe<ClassificacaoPrioridadeCuidadoEnum>
  devolutiva?: Maybe<DevolutivaCuidadoCompartilhadoInput>
  pergunta?: Maybe<PerguntaCuidadoCompartilhadoInput>
  agendamento?: Maybe<AgendamentoCuidadoCompartilhadoInput>
  interconsulta?: Maybe<InterconsultaAgendamentoCuidadoCompartilhadoInput>
  trocaExecutante?: Maybe<TrocaExecutanteCuidadoCompartilhadoInput>
  sugestaoAgendamentoGrupo?: Maybe<SugestaoAgendamentoGrupoCuidadoCompartilhadoInput>
  enviarGarantiaAcesso?: Maybe<EnviarGarantiaAcessoCuidadoCompartilhadoInput>
}

export type SalvarModeloRelatorioGerencialInput = {
  nome: Scalars['String']
  isPublico: Scalars['Boolean']
  tipoModulo: TipoModuloEnum
  modeloPersonalizado: ModeloPersonalizadoRelatorioGerencialInput
}

export type SalvarVinculacaoServicoInput = {
  referenciaId: Scalars['ID']
  vinculacoesId: Array<Scalars['ID']>
}

export type SelecionarAcessoInput = {
  id: Scalars['ID']
}

export type SelecionarModuloInicialInput = {
  moduloInicial: Scalars['String']
}

export type SemanasDiasInput = {
  semanas?: Maybe<Scalars['Int']>
  dias?: Maybe<Scalars['Int']>
}

export type SerieHistoricaAgrupamento = {
  __typename?: 'SerieHistoricaAgrupamento'
  codigo?: Maybe<Scalars['Int']>
  identificacao?: Maybe<Scalars['String']>
  descricao?: Maybe<Scalars['String']>
}

export type SerieHistoricaConsolidado = {
  __typename?: 'SerieHistoricaConsolidado'
  competencia: Scalars['Int']
  numConsolidados: Scalars['Int']
}

export type SerieHistoricaPayload = {
  __typename?: 'SerieHistoricaPayload'
  agrupamento3?: Maybe<SerieHistoricaAgrupamento>
  agrupamento2?: Maybe<SerieHistoricaAgrupamento>
  agrupamento1: SerieHistoricaAgrupamento
  consolidadosPorCompetencia?: Maybe<Array<Maybe<SerieHistoricaConsolidado>>>
  totalConsolidados?: Maybe<Scalars['Int']>
}

export type SerieHistoricaQueryInput = {
  tipoRelatorio: TipoSerieHistoricaEnum
  inicio: Scalars['LocalDate']
  fim: Scalars['LocalDate']
  nivelDetalhe: NivelDetalheSerieHistoricaEnum
  dimMunicipio?: Maybe<Scalars['ID']>
  dimUnidadeSaude?: Maybe<Scalars['ID']>
  dimEquipe?: Maybe<Scalars['ID']>
  dimProfissional?: Maybe<Scalars['ID']>
  dimCategoriaProfissional?: Maybe<Scalars['ID']>
  dimsSexo?: Maybe<Array<Maybe<DimSexoEnum>>>
  dimsFaixaEtaria?: Maybe<Array<Maybe<DimFaixaEtariaEnum>>>
  producaoUsuario?: Maybe<NivelProducaoEnum>
}

export enum ServicosCertificadoEnum {
  RNDS = 'RNDS',
  CADSUS = 'CADSUS',
}

export type ServidorSmtp = {
  __typename?: 'ServidorSMTP'
  id: Scalars['ID']
  email?: Maybe<Scalars['String']>
  senha?: Maybe<Scalars['String']>
  usuario?: Maybe<Scalars['String']>
  endereco?: Maybe<Scalars['String']>
  porta?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  dataRegistro?: Maybe<Scalars['Instant']>
}

export type Sessao = {
  __typename?: 'Sessao'
  id: Scalars['ID']
  profissional: Profissional
  acesso?: Maybe<Acesso>
  recursos?: Maybe<Array<Maybe<Scalars['String']>>>
  timeout: Scalars['Int']
  expDateAccessTokenGovBr?: Maybe<Scalars['Instant']>
  sessionProvider: SessionProviderEnum
}

export enum SessionEventType {
  LOGGED_IN_ANOTHER_SESSION = 'LOGGED_IN_ANOTHER_SESSION',
}

export enum SessionProviderEnum {
  PEC = 'PEC',
  GOVBR = 'GOVBR',
}

export enum SexoEnum {
  MASCULINO = 'MASCULINO',
  FEMININO = 'FEMININO',
  AMBOS = 'AMBOS',
  NAO_INFORMADO = 'NAO_INFORMADO',
  IGNORADO = 'IGNORADO',
}

export type SinanNotificacaoEvolucao = {
  __typename?: 'SinanNotificacaoEvolucao'
  id: Scalars['ID']
  notificacao: FichaNotificacaoCasoSuspeito
}

export type SincronizacaoCadsusResultDto = {
  __typename?: 'SincronizacaoCadsusResultDto'
  cidadaoLocal?: Maybe<Cidadao>
  cidadaoCadsus?: Maybe<CidadaoCadsus>
  camposAlterados?: Maybe<Array<Maybe<CampoAlteradoCadsus>>>
  errors?: Maybe<Array<Maybe<Scalars['String']>>>
  cidadaosDuplicados?: Maybe<Array<Maybe<CidadaoDuplicadoDto>>>
  cpfAntigo?: Maybe<Scalars['String']>
  result?: Maybe<SincronizacaoCadsusResultEnum>
  uuid: Scalars['String']
  usuario?: Maybe<Scalars['ID']>
}

export enum SincronizacaoCadsusResultEnum {
  SUCESSO = 'SUCESSO',
  ERRO = 'ERRO',
  ERRO_SEM_CONEXAO = 'ERRO_SEM_CONEXAO',
  ERRO_CADSUS_DESABILITADO = 'ERRO_CADSUS_DESABILITADO',
  ERRO_CERTIFICADO_DESABILITADO = 'ERRO_CERTIFICADO_DESABILITADO',
  ERRO_AUTENTICACAO = 'ERRO_AUTENTICACAO',
  VALIDACAO_COM_CNS = 'VALIDACAO_COM_CNS',
  VALIDACAO_SEM_CNS = 'VALIDACAO_SEM_CNS',
  DUPLICIDADE_CNS_ATIVOS = 'DUPLICIDADE_CNS_ATIVOS',
  DUPLICIDADE_CNS_INATIVOS = 'DUPLICIDADE_CNS_INATIVOS',
  DUPLICIDADE_CPF_ATIVOS = 'DUPLICIDADE_CPF_ATIVOS',
  DUPLICIDADE_CPF_INATIVOS = 'DUPLICIDADE_CPF_INATIVOS',
  POSSUI_PRONTUARIO = 'POSSUI_PRONTUARIO',
  ERRO_BUSCA_SEM_RETORNO = 'ERRO_BUSCA_SEM_RETORNO',
  LENTO = 'LENTO',
  VALIDACAO_APENAS_NOME = 'VALIDACAO_APENAS_NOME',
}

export type SincronizaCidadaoCadsusInput = {
  cidadao: Scalars['ID']
  telefoneCelularAntigo?: Maybe<Scalars['String']>
  telefoneContatoAntigo?: Maybe<Scalars['String']>
  telefoneResidencialAntigo?: Maybe<Scalars['String']>
  nomeSocialAntigo?: Maybe<Scalars['String']>
  uuid: Scalars['String']
}

export enum SituacaoAgendadoEnum {
  EXCLUIDO = 'EXCLUIDO',
  AGENDADO = 'AGENDADO',
  CIDADAO_PRESENTE_NA_UNIDADE = 'CIDADAO_PRESENTE_NA_UNIDADE',
  NAO_COMPARECEU = 'NAO_COMPARECEU',
  ATENDIMENTO_REALIZADO = 'ATENDIMENTO_REALIZADO',
  NAO_AGUARDOU = 'NAO_AGUARDOU',
  CANCELADO = 'CANCELADO',
}

export enum SituacaoCoroaEnum {
  A_SER_TRATADO = 'A_SER_TRATADO',
  TRATADO = 'TRATADO',
  NENHUM = 'NENHUM',
}

export enum SituacaoFaceEnum {
  NENHUM = 'NENHUM',
  AUSENTE = 'AUSENTE',
  CALCULO_DENTAL = 'CALCULO_DENTAL',
  CARIADO = 'CARIADO',
  COROA = 'COROA',
  EXTRACAO_INDICADA = 'EXTRACAO_INDICADA',
  EXTRAIDO = 'EXTRAIDO',
  FRATURA = 'FRATURA',
  HIGIDO = 'HIGIDO',
  HIGIDO_SELADO = 'HIGIDO_SELADO',
  INCLUSO = 'INCLUSO',
  MANCHA_BRANCA_ATIVA = 'MANCHA_BRANCA_ATIVA',
  MANTENEDOR_ESPACO = 'MANTENEDOR_ESPACO',
  PILAR = 'PILAR',
  RESTAURADO = 'RESTAURADO',
  RESTAURADO_COM_CARIE = 'RESTAURADO_COM_CARIE',
  RESTO_RADICULAR = 'RESTO_RADICULAR',
  RETRACAO_GENGIVAL = 'RETRACAO_GENGIVAL',
  SELANTE_INDICADO = 'SELANTE_INDICADO',
  PROTESE_TOTAL = 'PROTESE_TOTAL',
  PROTESE_PARCIAL_REMOVIVEL = 'PROTESE_PARCIAL_REMOVIVEL',
  PROTESE_CORONARIA_UNITARIA = 'PROTESE_CORONARIA_UNITARIA',
  PROTESE_TEMPORARIA = 'PROTESE_TEMPORARIA',
}

export enum SituacaoProblema {
  ATIVO = 'ATIVO',
  LATENTE = 'LATENTE',
  RESOLVIDO = 'RESOLVIDO',
}

export enum SituacaoRaizEnum {
  NENHUM = 'NENHUM',
  CARIE_DE_RAIZ_TRATADA = 'CARIE_DE_RAIZ_TRATADA',
  CARIE_DE_RAIZ = 'CARIE_DE_RAIZ',
  ENDODONTIA_TRATADA = 'ENDODONTIA_TRATADA',
  ENDODONTIA = 'ENDODONTIA',
  LESAO_DE_FURCA_TRATADA = 'LESAO_DE_FURCA_TRATADA',
  LESAO_DE_FURCA = 'LESAO_DE_FURCA',
  IMPLANTE = 'IMPLANTE',
  NUCLEO_METALICO_FUNDIDO = 'NUCLEO_METALICO_FUNDIDO',
}

export enum SituacaoSincronizacaoAgendaOnlineEnum {
  SINCRONIZADO = 'SINCRONIZADO',
  AGUARDANDO_SINCRONIZACAO = 'AGUARDANDO_SINCRONIZACAO',
  AGUARDANDO_SINCRONIZACAO_ATUALIZACAO = 'AGUARDANDO_SINCRONIZACAO_ATUALIZACAO',
  ANTERIOR_ATIVACAO_AGENDA_ONLINE = 'ANTERIOR_ATIVACAO_AGENDA_ONLINE',
  REGISTRO_INCONSISTENTE = 'REGISTRO_INCONSISTENTE',
  INELEGIVEL = 'INELEGIVEL',
  AGUARDANDO_ELEGIBILIDADE_CADASTRO_CIDADAO = 'AGUARDANDO_ELEGIBILIDADE_CADASTRO_CIDADAO',
}

export type SolicitacaoExame = {
  __typename?: 'SolicitacaoExame'
  id: Scalars['ID']
  data: Scalars['LocalDate']
  exames: Array<ResultadoExame>
  justificativa: Scalars['String']
  observacoes?: Maybe<Scalars['String']>
  cid10?: Maybe<Cid10>
  tipoExame: TipoExameEnum
  atendimentoProfissional: AtendimentoProfissional
}

export type SolicitacaoExameInput = {
  id?: Maybe<Scalars['ID']>
  examesRequisitados: Array<ExameRequisitadoInput>
  justificativa: Scalars['String']
  observacoes?: Maybe<Scalars['String']>
  cid10?: Maybe<Scalars['ID']>
  tipoExame: TipoExameEnum
}

export type SolicitacoesExamePayload = {
  __typename?: 'SolicitacoesExamePayload'
  content: Array<SolicitacaoExame>
  pageInfo: PageInfo
}

export type SolicitacoesExameQueryInput = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
  dataInicio?: Maybe<Scalars['LocalDate']>
  dataFim?: Maybe<Scalars['LocalDate']>
  query?: Maybe<Scalars['String']>
  profissionalId?: Maybe<Scalars['ID']>
  cboId?: Maybe<Scalars['ID']>
  tipoExame?: Maybe<TipoExameEnum>
  somenteMinhasSolicitacoes?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type SolicitarEntradaVideochamadaInput = {
  videochamadaUuid: Scalars['String']
  nomeParticipante?: Maybe<Scalars['String']>
  cpfParticipante?: Maybe<Scalars['String']>
  termoProfissionalAceito: Scalars['Boolean']
}

export type SolicitarRecuperacaoSenhaInput = {
  usuario: Scalars['String']
}

export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type StatusArquivo = {
  __typename?: 'StatusArquivo'
  id: Scalars['ID']
  dataInicioGerado: Scalars['Instant']
  status: ArquivoStatusEnum
  identificador: ArquivoIdentificadorEnum
  formato: ArquivoFormatoEnum
}

export enum StatusAssinatura {
  ASSINADO = 'ASSINADO',
  ERRO_ASSINATURA = 'ERRO_ASSINATURA',
  TENTATIVA_NAO_CONCLUIDA = 'TENTATIVA_NAO_CONCLUIDA',
  ASSINATURA_INVALIDA = 'ASSINATURA_INVALIDA',
}

export enum StatusAtendimento {
  AGUARDANDO_ATENDIMENTO = 'AGUARDANDO_ATENDIMENTO',
  EM_ESCUTA_INICIAL = 'EM_ESCUTA_INICIAL',
  EM_ATENDIMENTO = 'EM_ATENDIMENTO',
  NAO_AGUARDOU = 'NAO_AGUARDOU',
  ATENDIMENTO_REALIZADO = 'ATENDIMENTO_REALIZADO',
  AGUARDANDO_OBSERVACAO = 'AGUARDANDO_OBSERVACAO',
  EM_OBSERVACAO = 'EM_OBSERVACAO',
  EVADIU_OBSERVACAO = 'EVADIU_OBSERVACAO',
}

export enum StatusAtendimentoProfissional {
  EM_ATENDIMENTO = 'EM_ATENDIMENTO',
  ATENDIMENTO_FINALIZADO = 'ATENDIMENTO_FINALIZADO',
}

export enum StatusAvaliadoEnum {
  PRESENTE = 'PRESENTE',
  PRESENTE_COM_ATRASO = 'PRESENTE_COM_ATRASO',
  AUSENTE = 'AUSENTE',
  NAO_AVALIADO = 'NAO_AVALIADO',
}

export enum StatusConexaoRndsEnum {
  SEM_CONEXAO = 'SEM_CONEXAO',
  CONEXAO_BEM_SUCEDIDA = 'CONEXAO_BEM_SUCEDIDA',
  CERTIFICADO_EXPIRADO = 'CERTIFICADO_EXPIRADO',
  CERTIFICADO_INVALIDO = 'CERTIFICADO_INVALIDO',
}

export enum StatusCuidadoCompartilhadoEnum {
  AGUARDANDO = 'AGUARDANDO',
  RESPONDIDO = 'RESPONDIDO',
}

export enum StatusDiaAgendaEnum {
  DISPONIVEL = 'DISPONIVEL',
  INDISPONIVEL = 'INDISPONIVEL',
}

export enum StatusDocumentosCidadao {
  DOCUMENTOS_DUPLICADOS = 'DOCUMENTOS_DUPLICADOS',
  SEM_CORRESPONDENCIA = 'SEM_CORRESPONDENCIA',
  MANUALMENTE_UNIFICADO = 'MANUALMENTE_UNIFICADO',
}

export enum StatusEnvio {
  NAO_ENVIADO = 'NAO_ENVIADO',
  ENVIANDO = 'ENVIANDO',
  PARCIALMENTE_ENVIADO = 'PARCIALMENTE_ENVIADO',
  TOTALMENTE_ENVIADO = 'TOTALMENTE_ENVIADO',
}

export enum StatusFichaEnum {
  NAO_ENVIADA = 'NAO_ENVIADA',
  ENVIADA = 'ENVIADA',
  RASCUNHO = 'RASCUNHO',
}

export enum StatusImportacaoArquivo {
  SEM_RESULTADO = 'SEM_RESULTADO',
  IMPORTANDO = 'IMPORTANDO',
  CONCLUIDO = 'CONCLUIDO',
  PROCESSAMENTO_DUPLICADO_CONCLUIDO = 'PROCESSAMENTO_DUPLICADO_CONCLUIDO',
  ERRO = 'ERRO',
}

export enum StatusPedidoEnvioEnum {
  AGUARDANDO_APROVACAO = 'AGUARDANDO_APROVACAO',
  APROVADO = 'APROVADO',
  ENVIANDO_PROFISSIONAIS = 'ENVIANDO_PROFISSIONAIS',
  SALVANDO_ESTAGIOS = 'SALVANDO_ESTAGIOS',
  SALVANDO_RESERVAS = 'SALVANDO_RESERVAS',
  ENVIANDO_CIDADAOS = 'ENVIANDO_CIDADAOS',
  ENVIANDO_CDS = 'ENVIANDO_CDS',
  FINALIZANDO_UNIFICACAO_BASE = 'FINALIZANDO_UNIFICACAO_BASE',
  ENVIO_COMPLETO = 'ENVIO_COMPLETO',
  ERRO_PROFISSIONAIS = 'ERRO_PROFISSIONAIS',
  ERRO_ESTAGIOS = 'ERRO_ESTAGIOS',
  ERRO_RESERVAS = 'ERRO_RESERVAS',
  ERRO_CIDADAOS = 'ERRO_CIDADAOS',
  ERRO_VERSAO = 'ERRO_VERSAO',
  RECUSADO = 'RECUSADO',
  CANCELADO = 'CANCELADO',
  ERRO_FINALIZACAO = 'ERRO_FINALIZACAO',
}

export enum StatusProcesso {
  AGUARDANDO = 'AGUARDANDO',
  CONCLUIDO = 'CONCLUIDO',
  EM_EXECUCAO = 'EM_EXECUCAO',
  INTERROMPIDO = 'INTERROMPIDO',
  ERRO = 'ERRO',
}

export enum StatusProcessoAuditoria {
  PROCESSANDO = 'PROCESSANDO',
  PROCESSADO = 'PROCESSADO',
  ERRO_SEM_DADOS = 'ERRO_SEM_DADOS',
  ERRO_PDF_NAO_SUPORTADO = 'ERRO_PDF_NAO_SUPORTADO',
  ERRO_MUITAS_REVISOES = 'ERRO_MUITAS_REVISOES',
  ERRO_PROCESSO_EXECUTANDO = 'ERRO_PROCESSO_EXECUTANDO',
  ERRO_INESPERADO = 'ERRO_INESPERADO',
}

export enum StatusRevisaoAtendimento {
  AGUARDANDO_REVISAO = 'AGUARDANDO_REVISAO',
  EM_REVISAO = 'EM_REVISAO',
  EM_RETIFICACAO = 'EM_RETIFICACAO',
  AGUARDANDO_RETIFICACAO = 'AGUARDANDO_RETIFICACAO',
}

export enum StatusUnificacaoBaseEnum {
  AGUARDANDO_AVALIACAO = 'AGUARDANDO_AVALIACAO',
  AGUARDANDO_ENVIO_BASE = 'AGUARDANDO_ENVIO_BASE',
  UNIFICANDO_BASES = 'UNIFICANDO_BASES',
  BASE_UNIFICADA = 'BASE_UNIFICADA',
  CANCELADA = 'CANCELADA',
  REPROVADA = 'REPROVADA',
  ERRO_VERSAO = 'ERRO_VERSAO',
  ERRO = 'ERRO',
  AGUARDANDO_FINALIZACAO = 'AGUARDANDO_FINALIZACAO',
}

export enum StatusVacinacaoEnum {
  ATRASADA = 'ATRASADA',
  NO_PRAZO = 'NO_PRAZO',
  APLICADA = 'APLICADA',
}

export enum StatusVideochamadaEnum {
  ENCERRADA = 'ENCERRADA',
  ATIVA = 'ATIVA',
  ATIVA_SEM_DONO = 'ATIVA_SEM_DONO',
  LOTADA = 'LOTADA',
  AGUARDANDO_INICIO = 'AGUARDANDO_INICIO',
  CANCELADA = 'CANCELADA',
  NAO_ENCONTRADA = 'NAO_ENCONTRADA',
}

export type SubjetivoInput = {
  texto?: Maybe<Scalars['String']>
  motivos?: Maybe<Array<MotivoConsultaInput>>
}

export type SubstanciaCbaraAlergia = {
  __typename?: 'SubstanciaCbaraAlergia'
  id: Scalars['ID']
  categoria: CategoriaSubstanciaAlergia
  nome: Scalars['String']
  sinonimos?: Maybe<Scalars['String']>
}

export type SubstanciaCbaraAlergiaQueryPayload = {
  __typename?: 'SubstanciaCbaraAlergiaQueryPayload'
  content: Array<SubstanciaCbaraAlergia>
  pageInfo: PageInfo
}

export type SubstanciaEspecificaAlergia = {
  __typename?: 'SubstanciaEspecificaAlergia'
  id: Scalars['ID']
  coSubstanciaEspecifica: Scalars['Long']
  categoria: CategoriaSubstanciaAlergia
  nome: Scalars['String']
  sinonimos?: Maybe<Scalars['String']>
  sigla?: Maybe<Scalars['String']>
}

export type SubstanciasCbaraAlergiaQueryInput = {
  query?: Maybe<Scalars['String']>
  categoria: CategoriaSubstanciaAlergia
  pageParams?: Maybe<PageParams>
}

export enum SubtipoAtendimentoHistoricoClinico {
  CONSULTA = 'CONSULTA',
  CONSULTA_DIA = 'CONSULTA_DIA',
  CONSULTA_PROGRAMADA_CUIDADO_CONTINUADO = 'CONSULTA_PROGRAMADA_CUIDADO_CONTINUADO',
  URGENCIA = 'URGENCIA',
  CONSULTA_AGENDADA_ODONTO = 'CONSULTA_AGENDADA_ODONTO',
  ESCUTA_INICIAL_ORIENTACAO = 'ESCUTA_INICIAL_ORIENTACAO',
  CONSULTA_DIA_ODONTO = 'CONSULTA_DIA_ODONTO',
  URGENCIA_ODONTO = 'URGENCIA_ODONTO',
  PRIMEIRA_CONSULTA = 'PRIMEIRA_CONSULTA',
  CONSULTA_RETORNO = 'CONSULTA_RETORNO',
  CONSULTA_MANUTENCAO = 'CONSULTA_MANUTENCAO',
  CRIANCAS_MENORES_6_MESES = 'CRIANCAS_MENORES_6_MESES',
  CRIANCAS_6_A_23_MESES = 'CRIANCAS_6_A_23_MESES',
  CIDADAOS_MAIORES_2_ANOS = 'CIDADAOS_MAIORES_2_ANOS',
  VISITA_REALIZADA = 'VISITA_REALIZADA',
  VISITA_RECUSADA = 'VISITA_RECUSADA',
  CIDADAO_AUSENTE = 'CIDADAO_AUSENTE',
  NAO_PROGRAMADA = 'NAO_PROGRAMADA',
  PROGRAMADA = 'PROGRAMADA',
  VISITA_DOMICILIAR_POS_OBITO = 'VISITA_DOMICILIAR_POS_OBITO',
  EDUCACAO_SAUDE = 'EDUCACAO_SAUDE',
  ATENDIMENTO_GRUPO = 'ATENDIMENTO_GRUPO',
  AVALIACAO_PROCEDIMENTO_COLETIVO = 'AVALIACAO_PROCEDIMENTO_COLETIVO',
  MOBILIZACAO_SOCIAL = 'MOBILIZACAO_SOCIAL',
}

export type SubtipoAtendimentoHistoricoClinicoQueryInput = {
  query?: Maybe<Scalars['String']>
  tiposAtendimentoId?: Maybe<Array<Scalars['ID']>>
}

export type SubtipoUnidadeSaude = {
  __typename?: 'SubtipoUnidadeSaude'
  id: Scalars['ID']
  tipoUnidadeSaude: TipoUnidadeSaude
  codigoMs: Scalars['String']
  descricao?: Maybe<Scalars['String']>
}

export type SugestaoAgendamentoGrupoCuidadoCompartilhadoInput = {
  observacao: Scalars['String']
}

export type SugestoesDatas = {
  __typename?: 'SugestoesDatas'
  preNatal: Array<Scalars['LocalDate']>
  odontologica: Array<Scalars['LocalDate']>
}

export type SugestoesDatasInput = {
  prontuarioId: Scalars['ID']
  idadeGestacionalEmDias: Scalars['Int']
  dataAtendimento: Scalars['LocalDate']
}

export type TecidoMole = {
  __typename?: 'TecidoMole'
  id: Scalars['ID']
  atendimentoProfissionalOdonto: AtendimentoProfissionalOdonto
  parteBucal: ParteBucal
  cid10?: Maybe<Cid10>
  caracteristicasLesao?: Maybe<Array<CaracteristicaLesao>>
  descricaoSintomatologia?: Maybe<Scalars['String']>
  evolucaoProcedimentoOdonto?: Maybe<EvolucaoProcedimentoOdonto>
}

export type TecidoMoleInput = {
  parteBucal: ParteBucalEnum
  cid10?: Maybe<Scalars['ID']>
  caracteristicasLesao?: Maybe<Array<CaracteristicaLesaoEnum>>
  descricaoSintomatologia?: Maybe<Scalars['String']>
  procedimentos?: Maybe<Array<Maybe<Scalars['ID']>>>
  observacao?: Maybe<Scalars['String']>
}

export type TecidosMolesPayload = {
  __typename?: 'TecidosMolesPayload'
  content?: Maybe<Array<Maybe<TecidoMole>>>
  pageInfo?: Maybe<PageInfo>
}

export type TecidosMolesQueryInput = {
  prontuarioId: Scalars['ID']
  atendProfId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type TerraIndigena = {
  __typename?: 'TerraIndigena'
  id: Scalars['ID']
  nome: Scalars['String']
  dtInclusao: Scalars['LocalDate']
  dtExtincao: Scalars['LocalDate']
  stAtivo: Scalars['Boolean']
}

export type TerritorioEquipeDto = {
  __typename?: 'TerritorioEquipeDto'
  microareas: Array<Maybe<Scalars['String']>>
  poloBase?: Maybe<PoloBase>
}

export type TerritorioEquipeQueryInput = {
  cnes: Scalars['String']
  ine: Scalars['String']
  tipoEndereco?: Maybe<TipoEnderecoEnum>
  bairro?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  tipoLogradouroId?: Maybe<Scalars['ID']>
  logradouro?: Maybe<Scalars['String']>
  dseiId?: Maybe<Scalars['ID']>
  poloBaseId?: Maybe<Scalars['ID']>
  aldeiaId?: Maybe<Scalars['ID']>
}

export type TerritorioLogradouroQueryInput = {
  bairro?: Maybe<Scalars['String']>
  tipoLogradouroId?: Maybe<Scalars['ID']>
  logradouro?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  cnes: Scalars['String']
  microarea?: Maybe<Scalars['String']>
  ine: Scalars['String']
  dseiId?: Maybe<Scalars['ID']>
  poloBaseId?: Maybe<Scalars['ID']>
  aldeiaId?: Maybe<Scalars['ID']>
  tipoEndereco?: Maybe<TipoEnderecoEnum>
  isCadastroAtualizadoMenosDeUmAno?: Maybe<Scalars['Boolean']>
  isCadastroCompleto?: Maybe<Scalars['Boolean']>
  isImovelVisitadoMenosDeUmMes?: Maybe<Scalars['Boolean']>
}

export type TerritorioMicroarea = {
  __typename?: 'TerritorioMicroarea'
  id: Scalars['String']
  logradouros: LogradourosTerritorioQueryPayload
  indices: IndicesAcompanhamentoMicroarea
}

export type TerritorioMicroareaQueryInput = {
  cnes: Scalars['String']
  ine: Scalars['String']
  microarea?: Maybe<Scalars['String']>
  tipoEndereco: TipoEnderecoEnum
  bairro?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  tipoLogradouroId?: Maybe<Scalars['ID']>
  logradouro?: Maybe<Scalars['String']>
  dseiId?: Maybe<Scalars['ID']>
  poloBaseId?: Maybe<Scalars['ID']>
  aldeiaId?: Maybe<Scalars['ID']>
  isCadastroAtualizadoMenosDeUmAno?: Maybe<Scalars['Boolean']>
  isCadastroCompleto?: Maybe<Scalars['Boolean']>
  isImovelVisitadoMenosDeUmMes?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type TesteConexao = {
  __typename?: 'TesteConexao'
  sucesso: Scalars['Boolean']
  dataEvento: Scalars['Instant']
}

export enum TipoAbastecimentoAguaEnum {
  REDE_ENCANADA = 'REDE_ENCANADA',
  REDE_ENCANADA_ATE_O_DOMICILIO_SESAI = 'REDE_ENCANADA_ATE_O_DOMICILIO_SESAI',
  POCO_NASCENTE_DOMICILIO = 'POCO_NASCENTE_DOMICILIO',
  CISTERNA = 'CISTERNA',
  CARRO_PIPA = 'CARRO_PIPA',
  CAPTACAO_DIRETA_RIO = 'CAPTACAO_DIRETA_RIO',
  CAPTACAO_DIRETA_POCO = 'CAPTACAO_DIRETA_POCO',
  CHAFARIZ = 'CHAFARIZ',
  OUTRO = 'OUTRO',
}

export enum TipoAcesso {
  ADMINISTRADOR_GERAL = 'ADMINISTRADOR_GERAL',
  ADMINISTRADOR_MUNICIPAL = 'ADMINISTRADOR_MUNICIPAL',
  GESTOR_MUNICIPAL = 'GESTOR_MUNICIPAL',
  GESTOR_ESTADUAL = 'GESTOR_ESTADUAL',
  LOTACAO = 'LOTACAO',
  ESTAGIO = 'ESTAGIO',
}

export enum TipoAcessoDomicilioEnum {
  PAVIMENTO = 'PAVIMENTO',
  CHAO_BATIDO = 'CHAO_BATIDO',
  FLUVIAL = 'FLUVIAL',
  OUTRO = 'OUTRO',
}

export enum TipoAgendamentoEnum {
  CONSULTA = 'CONSULTA',
  RESERVA = 'RESERVA',
}

export enum TipoAleitamentoMaternoEnum {
  EXCLUSIVO = 'EXCLUSIVO',
  PREDOMINANTE = 'PREDOMINANTE',
  COMPLEMENTADO = 'COMPLEMENTADO',
  INEXISTENTE = 'INEXISTENTE',
}

export type TipoArea = {
  __typename?: 'TipoArea'
  id: Scalars['ID']
  tipoArea: Scalars['String']
  registroAtivo: Scalars['Boolean']
}

export enum TipoArquivoEnum {
  PDF = 'PDF',
  CSV = 'CSV',
}

export enum TipoAtendimentoEnum {
  CONSULTA_NO_DIA = 'CONSULTA_NO_DIA',
  URGENCIA = 'URGENCIA',
  CONSULTA = 'CONSULTA',
  CONSULTA_AGENDADA_CUIDADO_CONTINUADO = 'CONSULTA_AGENDADA_CUIDADO_CONTINUADO',
}

export enum TipoAtendimentoProfissional {
  CONSULTA = 'CONSULTA',
  ESCUTA_INICIAL = 'ESCUTA_INICIAL',
  ATENDIMENTO_DOMICILIAR = 'ATENDIMENTO_DOMICILIAR',
  ATENDIMENTO_ODONTOLOGICO = 'ATENDIMENTO_ODONTOLOGICO',
  AVALIACAO_ELEGIBILIDADE = 'AVALIACAO_ELEGIBILIDADE',
  PRE_NATAL = 'PRE_NATAL',
  PUERPERIO = 'PUERPERIO',
  ATENDIMENTO_PROCEDIMENTOS = 'ATENDIMENTO_PROCEDIMENTOS',
  PUERICULTURA = 'PUERICULTURA',
  VACINACAO = 'VACINACAO',
  ZIKA = 'ZIKA',
  OBSERVACAO = 'OBSERVACAO',
}

export enum TipoAtestadoEnum {
  EM_BRANCO = 'EM_BRANCO',
  PADRAO = 'PADRAO',
  LICENCA_MATERNIDADE = 'LICENCA_MATERNIDADE',
  PERSONALIZADO = 'PERSONALIZADO',
}

export enum TipoConsultaOdontoEnum {
  PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA = 'PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA',
  CONSULTA_DE_RETORNO = 'CONSULTA_DE_RETORNO',
  CONSULTA_DE_MANUTENCAO = 'CONSULTA_DE_MANUTENCAO',
}

export enum TipoDestinoLixoEnum {
  COLETADO = 'COLETADO',
  QUEIMADO = 'QUEIMADO',
  ENTERRADO = 'ENTERRADO',
  QUEIMADO_ENTERRADO = 'QUEIMADO_ENTERRADO',
  CEU_ABERTO = 'CEU_ABERTO',
  OUTRO = 'OUTRO',
}

export enum TipoDomicilioEnum {
  CASA = 'CASA',
  APARTAMENTO = 'APARTAMENTO',
  COMODO = 'COMODO',
  MALOCA = 'MALOCA',
  IMPROVISADO = 'IMPROVISADO',
  OUTRO = 'OUTRO',
}

export enum TipoEdemaEnum {
  NEGATIVO = 'NEGATIVO',
  POSITIVO = 'POSITIVO',
  DOIS_POSITIVOS = 'DOIS_POSITIVOS',
  TRES_POSITIVOS = 'TRES_POSITIVOS',
}

export enum TipoEncaminhamentoExternoEnum {
  CAPS = 'CAPS',
  INTERNACAO_HOSPITALAR = 'INTERNACAO_HOSPITALAR',
  URGENCIA = 'URGENCIA',
  SERVICO_ATENCAO_DOMICILIAR = 'SERVICO_ATENCAO_DOMICILIAR',
  INTERSETORIAL = 'INTERSETORIAL',
  SERVICO_ESPECIALIZADO = 'SERVICO_ESPECIALIZADO',
}

export enum TipoEncaminhamentoOdontoEnum {
  AGENDAMENTO_P_GRUPOS = 'AGENDAMENTO_P_GRUPOS',
  AGENDAMENTO_P_NASF = 'AGENDAMENTO_P_NASF',
  AGENDAMENTO_P_EMULTI = 'AGENDAMENTO_P_EMULTI',
  AGENDAMENTO_P_OUTROS_PROFISSIONAIS_AB = 'AGENDAMENTO_P_OUTROS_PROFISSIONAIS_AB',
  ALTA_DO_EPISODIO = 'ALTA_DO_EPISODIO',
  RETORNO_PARA_CONSULTA_AGENDADA = 'RETORNO_PARA_CONSULTA_AGENDADA',
  TRATAMENTO_CONCLUIDO = 'TRATAMENTO_CONCLUIDO',
  PACIENTES_COM_NECESSIDADES_ESPECIAIS = 'PACIENTES_COM_NECESSIDADES_ESPECIAIS',
  CIRURGIA_BMF = 'CIRURGIA_BMF',
  ENDODONTIA = 'ENDODONTIA',
  ESTOMATOLOGIA = 'ESTOMATOLOGIA',
  IMPLANTODONTIA = 'IMPLANTODONTIA',
  ODONTOPEDIATRIA = 'ODONTOPEDIATRIA',
  ORTODONTIA_ORTOPEDIA = 'ORTODONTIA_ORTOPEDIA',
  PERIODONTIA = 'PERIODONTIA',
  PROTESE_DENTARIA = 'PROTESE_DENTARIA',
  RADIOLOGIA = 'RADIOLOGIA',
  OUTROS = 'OUTROS',
  NAO_SE_APLICA = 'NAO_SE_APLICA',
}

export enum TipoEnderecoEnum {
  ALDEIA = 'ALDEIA',
  ACAMPAMENTO = 'ACAMPAMENTO',
  LOGRADOURO = 'LOGRADOURO',
}

export type TipoEquipe = {
  __typename?: 'TipoEquipe'
  id: Scalars['ID']
  sigla: Scalars['String']
  nome: Scalars['String']
  codigoMs?: Maybe<Scalars['String']>
  descricao?: Maybe<Scalars['String']>
}

export enum TipoEscoamentoSanitarioEnum {
  REDE_COLETORA_ESGOTO_PLUVIAL = 'REDE_COLETORA_ESGOTO_PLUVIAL',
  FOSSA_SEPTICA = 'FOSSA_SEPTICA',
  FOSSA_RUDIMENTAR = 'FOSSA_RUDIMENTAR',
  DIRETO_PARA_RIO_LAGO_MAR = 'DIRETO_PARA_RIO_LAGO_MAR',
  CEU_ABERTO = 'CEU_ABERTO',
  OUTRO = 'OUTRO',
}

export enum TipoEstabelecimentoEnum {
  CEO = 'CEO',
  POLICLINICA = 'POLICLINICA',
  UBS = 'UBS',
  UPA = 'UPA',
}

export type TipoExame = {
  __typename?: 'TipoExame'
  id: Scalars['ID']
  nome: Scalars['String']
  tipoExameEnum: TipoExameEnum
  sexo: SexoEnum
}

export enum TipoExameEnum {
  COMUM = 'COMUM',
  ALTO_CUSTO = 'ALTO_CUSTO',
}

export enum TipoFrequencia {
  INTERVALO = 'INTERVALO',
  FREQUENCIA = 'FREQUENCIA',
  TURNO = 'TURNO',
  FRACIONADO = 'FRACIONADO',
}

export enum TipoGlicemia {
  JEJUM = 'JEJUM',
  POSPRANDIAL = 'POSPRANDIAL',
  PREPRANDIAL = 'PREPRANDIAL',
  NAO_ESPECIFICADO = 'NAO_ESPECIFICADO',
}

export type TipoGravidez = {
  __typename?: 'TipoGravidez'
  id: Scalars['ID']
  descricao?: Maybe<Scalars['String']>
  tipoGravidez?: Maybe<TipoGravidezEnum>
}

export enum TipoGravidezEnum {
  UNICA = 'UNICA',
  DUPLA_GEMELAR = 'DUPLA_GEMELAR',
  TRIPLA_OU_MAIS = 'TRIPLA_OU_MAIS',
  IGNORADA = 'IGNORADA',
}

export enum TipoImovelEnum {
  DOMICILIO = 'DOMICILIO',
  COMERCIO = 'COMERCIO',
  TERRENO_BALDIO = 'TERRENO_BALDIO',
  PONTO_ESTRATEGICO = 'PONTO_ESTRATEGICO',
  ESCOLA = 'ESCOLA',
  CRECHE = 'CRECHE',
  ABRIGO = 'ABRIGO',
  INSTITUICAO_LONGA_PERMANENCIA_PARA_IDOSOS = 'INSTITUICAO_LONGA_PERMANENCIA_PARA_IDOSOS',
  UNIDADE_PRISIONAL = 'UNIDADE_PRISIONAL',
  UNIDADE_MEDIDA_SOCIOEDUCATIVA = 'UNIDADE_MEDIDA_SOCIOEDUCATIVA',
  DELEGACIA = 'DELEGACIA',
  ESTABELECIMENTO_RELIGIOSO = 'ESTABELECIMENTO_RELIGIOSO',
  CASAI = 'CASAI',
  OUTROS = 'OUTROS',
}

export enum TipoInstalacao {
  PRONTUARIO = 'PRONTUARIO',
  CENTRALIZADORA = 'CENTRALIZADORA',
}

export enum TipoLocalizacaoEnum {
  URBANA = 'URBANA',
  RURAL = 'RURAL',
  PERIURBANA = 'PERIURBANA',
}

export enum TipoLocalNascimentoEnum {
  ALDEIA = 'ALDEIA',
  MUNICIPIO = 'MUNICIPIO',
}

export type TipoLogradouro = {
  __typename?: 'TipoLogradouro'
  id: Scalars['ID']
  nome: Scalars['String']
  numeroDne: Scalars['String']
}

export type TipoLogradouroInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type TipoLogradouroQueryPayload = {
  __typename?: 'TipoLogradouroQueryPayload'
  content: Array<Maybe<TipoLogradouro>>
  pageInfo: PageInfo
}

export enum TipoMaterialParedeEnum {
  ALVENARIA_COM_REVESTIMENTO = 'ALVENARIA_COM_REVESTIMENTO',
  ALVENARINA_SEM_REVESTIMENTO = 'ALVENARINA_SEM_REVESTIMENTO',
  TAIPA_COM_REVESTIMENTO = 'TAIPA_COM_REVESTIMENTO',
  TAIPA_SEM_REVESTIMENTO = 'TAIPA_SEM_REVESTIMENTO',
  MADEIRA_APARELHADA = 'MADEIRA_APARELHADA',
  MATERIAL_APROVEITADO = 'MATERIAL_APROVEITADO',
  PALHA = 'PALHA',
  OUTROS_LONA = 'OUTROS_LONA',
  OUTROS_MISTO_DIFERENTES_MATERIAIS = 'OUTROS_MISTO_DIFERENTES_MATERIAIS',
  OUTROS_CAULE_PALMEIRA = 'OUTROS_CAULE_PALMEIRA',
  OUTROS_SEM_PAREDE = 'OUTROS_SEM_PAREDE',
  OUTRO = 'OUTRO',
}

export enum TipoMedicaoEnum {
  PESO = 'PESO',
  ALTURA = 'ALTURA',
  IMC = 'IMC',
  PERIMETRO_CEFALICO = 'PERIMETRO_CEFALICO',
  PERIMETRO_PANTURRILHA = 'PERIMETRO_PANTURRILHA',
  CIRCUNFERENCIA_ABDOMINAL = 'CIRCUNFERENCIA_ABDOMINAL',
  FREQUENCIA_CARDIACA = 'FREQUENCIA_CARDIACA',
  FREQUENCIA_RESPIRATORIA = 'FREQUENCIA_RESPIRATORIA',
  PRESSAO_ARTERIAL = 'PRESSAO_ARTERIAL',
  TEMPERATURA = 'TEMPERATURA',
  SATURACAO_O2 = 'SATURACAO_O2',
  GLICEMIA = 'GLICEMIA',
  DUM = 'DUM',
  ALTURA_UTERINA = 'ALTURA_UTERINA',
}

export enum TipoModuloEnum {
  RELATORIO_GERENCIAL_ATENDIMENTO = 'RELATORIO_GERENCIAL_ATENDIMENTO',
  RELATORIO_GERENCIAL_VACINACAO = 'RELATORIO_GERENCIAL_VACINACAO',
  RELATORIO_GERENCIAL_ABSENTEISMO = 'RELATORIO_GERENCIAL_ABSENTEISMO',
  RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO = 'RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO',
}

export type TipoOrigemDadoTransporte = {
  __typename?: 'TipoOrigemDadoTransporte'
  id: Scalars['ID']
  descricao: Scalars['String']
}

export enum TipoOrigemEnergiaEletricaEnum {
  CONCESSIONARIA = 'CONCESSIONARIA',
  FOTOVOLTAICA_COMUNITARIA = 'FOTOVOLTAICA_COMUNITARIA',
  FOTOVOLTAICA_INDIVIDUAL = 'FOTOVOLTAICA_INDIVIDUAL',
  GERADOR_COMUNITARIO = 'GERADOR_COMUNITARIO',
  GERADOR_INDIVIDUAL = 'GERADOR_INDIVIDUAL',
}

export enum TipoParteBucalEnum {
  ARCADA = 'ARCADA',
  DENTE = 'DENTE',
  SEXTANTE = 'SEXTANTE',
  OUTRO = 'OUTRO',
  SUPRANUMERARIO = 'SUPRANUMERARIO',
  TECIDO_MOLE = 'TECIDO_MOLE',
}

export enum TipoParticipacaoAtendimentoEnum {
  NAO_PARTICIPOU = 'NAO_PARTICIPOU',
  PRESENCIAL = 'PRESENCIAL',
  CHAMADA_DE_VIDEO = 'CHAMADA_DE_VIDEO',
  CHAMADA_DE_VOZ = 'CHAMADA_DE_VOZ',
  EMAIL = 'EMAIL',
  MENSAGEM = 'MENSAGEM',
  OUTROS = 'OUTROS',
}

export enum TipoPartoEnum {
  NORMAL = 'NORMAL',
  FORCEPS = 'FORCEPS',
  CESAREO = 'CESAREO',
}

export enum TipoPessoaEnum {
  FISICA = 'FISICA',
  JURIDICA = 'JURIDICA',
}

export enum TipoPosseTerraEnum {
  PROPRIETARIO = 'PROPRIETARIO',
  PARCEIRO = 'PARCEIRO',
  ASSENTADO = 'ASSENTADO',
  POSSEIRO = 'POSSEIRO',
  ARRENDATARIO = 'ARRENDATARIO',
  COMODATARIO = 'COMODATARIO',
  BENEFICIARIO_BANCO_TERRA = 'BENEFICIARIO_BANCO_TERRA',
  TERRA_INDIGENA_DEMARCADA = 'TERRA_INDIGENA_DEMARCADA',
  TERRA_NAO_DEMARCADA = 'TERRA_NAO_DEMARCADA',
  NAO_SE_APLICA = 'NAO_SE_APLICA',
}

export enum TipoProcedimentoEnum {
  ADMINISTRATIVO = 'ADMINISTRATIVO',
  CLINICO = 'CLINICO',
  GRUPO = 'GRUPO',
  OPME = 'OPME',
}

export enum TipoReacaoAlergia {
  ALERGIA = 'ALERGIA',
  INTOLERANCIA = 'INTOLERANCIA',
}

export enum TipoReceitaEnum {
  COMUM = 'COMUM',
  ESPECIAL = 'ESPECIAL',
  ESPECIAL_BRANCA = 'ESPECIAL_BRANCA',
  ESPECIAL_AZUL = 'ESPECIAL_AZUL',
  ESPECIAL_AMARELA = 'ESPECIAL_AMARELA',
}

export enum TipoRegistroHistoricoClinico {
  CONSULTA = 'CONSULTA',
  ESCUTA_INICIAL = 'ESCUTA_INICIAL',
  VISITA_DOMICILIAR = 'VISITA_DOMICILIAR',
  ATIVIDADE_COLETIVA = 'ATIVIDADE_COLETIVA',
  ATENDIMENTO_DOMICILIAR = 'ATENDIMENTO_DOMICILIAR',
  ATENDIMENTO_ODONTOLOGICO = 'ATENDIMENTO_ODONTOLOGICO',
  AVALIACAO_ELEGIBILIDADE = 'AVALIACAO_ELEGIBILIDADE',
  PRE_NATAL = 'PRE_NATAL',
  PUERPERIO = 'PUERPERIO',
  ATENDIMENTO_PROCEDIMENTOS = 'ATENDIMENTO_PROCEDIMENTOS',
  PUERICULTURA = 'PUERICULTURA',
  VACINACAO = 'VACINACAO',
  ZIKA = 'ZIKA',
  CONSUMO_ALIMENTAR = 'CONSUMO_ALIMENTAR',
  OBSERVACAO = 'OBSERVACAO',
  PRE_ATENDIMENTO = 'PRE_ATENDIMENTO',
  CUIDADO_COMPARTILHADO = 'CUIDADO_COMPARTILHADO',
}

export type TipoRegistroHistoricoClinicoQueryInput = {
  query?: Maybe<Scalars['String']>
}

export enum TipoRegistroRndsEnum {
  VACINA = 'VACINA',
  ATENDIMENTO_INDIVIDUAL = 'ATENDIMENTO_INDIVIDUAL',
  PRESCRICAO_MEDICAMENTO = 'PRESCRICAO_MEDICAMENTO',
}

export type TipoRegistroVacinacao = {
  __typename?: 'TipoRegistroVacinacao'
  id: Scalars['ID']
  nome: Scalars['String']
  identificador: TipoRegistroVacinacaoEnum
}

export enum TipoRegistroVacinacaoEnum {
  APLICACAO = 'APLICACAO',
  APRAZAMENTO = 'APRAZAMENTO',
}

export enum TipoRelatorioAcompanhamentoEnum {
  GESTANTES = 'GESTANTES',
  PUERPERAS = 'PUERPERAS',
}

export enum TipoRespostaPesquisa {
  FECHADO = 'FECHADO',
  NAO_RESPONDIDO = 'NAO_RESPONDIDO',
  ENVIADO = 'ENVIADO',
}

export enum TipoSanguineoEnum {
  A_POSITIVO = 'A_POSITIVO',
  A_NEGATIVO = 'A_NEGATIVO',
  B_POSITIVO = 'B_POSITIVO',
  B_NEGATIVO = 'B_NEGATIVO',
  AB_POSITIVO = 'AB_POSITIVO',
  AB_NEGATIVO = 'AB_NEGATIVO',
  O_POSITIVO = 'O_POSITIVO',
  O_NEGATIVO = 'O_NEGATIVO',
}

export enum TipoSerieHistoricaEnum {
  ATENDIMENTO_DOMICILIAR = 'ATENDIMENTO_DOMICILIAR',
  ATENDIMENTO_INDIVIDUAL = 'ATENDIMENTO_INDIVIDUAL',
  ATENDIMENTO_ODONTOLOGICO = 'ATENDIMENTO_ODONTOLOGICO',
  ATIVIDADE_COLETIVA = 'ATIVIDADE_COLETIVA',
  PROCEDIMENTO_INDIVIDUALIZADO = 'PROCEDIMENTO_INDIVIDUALIZADO',
  VACINACAO = 'VACINACAO',
  VISITA_DOMICILIAR = 'VISITA_DOMICILIAR',
}

export type TipoServico = {
  __typename?: 'TipoServico'
  id: Scalars['ID']
  nome: Scalars['String']
  municipio?: Maybe<Municipio>
}

export type TipoServicoInput = {
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
  tiposEstabelecimento: Array<Maybe<TipoEstabelecimentoEnum>>
}

export enum TipoServicoPadraoEnum {
  DEMANDA_ESPONTANEA = 'DEMANDA_ESPONTANEA',
  NEBULIZACAO = 'NEBULIZACAO',
  VACINA = 'VACINA',
  PROCEDIMENTOS = 'PROCEDIMENTOS',
  EXAMES = 'EXAMES',
  AGENDADO = 'AGENDADO',
  ESCUTA_INICIAL = 'ESCUTA_INICIAL',
  CURATIVO = 'CURATIVO',
  ADM_MEDICAMENTO = 'ADM_MEDICAMENTO',
  ODONTOLOGIA = 'ODONTOLOGIA',
}

export type TipoServicoQueryInput = {
  query?: Maybe<Scalars['String']>
  unidadeSaudeSessao?: Maybe<Scalars['Boolean']>
  includeInterno?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type TipoServicoQueryPayload = {
  __typename?: 'TipoServicoQueryPayload'
  content: Array<Maybe<TipoServico>>
  pageInfo: PageInfo
}

export type TipoServicoUpdateInput = {
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
}

export enum TipoSituacaoMoradiaEnum {
  PROPRIO = 'PROPRIO',
  FINANCIADO = 'FINANCIADO',
  ALUGADO = 'ALUGADO',
  ARRENDADO = 'ARRENDADO',
  CEDIDO = 'CEDIDO',
  OCUPACAO = 'OCUPACAO',
  SITUACAO_DE_RUA = 'SITUACAO_DE_RUA',
  TEMPORARIA = 'TEMPORARIA',
  OUTRO = 'OUTRO',
}

export type TipoTerra = {
  __typename?: 'TipoTerra'
  id: Scalars['ID']
  tipoTerra: Scalars['String']
  registroAtivo: Scalars['Boolean']
}

export enum TipoTratamentoAguaEnum {
  CLORACAO_INTRADOMICILIAR = 'CLORACAO_INTRADOMICILIAR',
  CLORADA = 'CLORADA',
  FERVIDA = 'FERVIDA',
  FILTRADA = 'FILTRADA',
  FILTRACAO_BARRO = 'FILTRACAO_BARRO',
  FILTRACAO_OUTRO = 'FILTRACAO_OUTRO',
  MINERAL = 'MINERAL',
  SEM_TRATAMENTO = 'SEM_TRATAMENTO',
}

export type TipoUnidadeSaude = {
  __typename?: 'TipoUnidadeSaude'
  id: Scalars['ID']
  codigoMs: Scalars['Long']
  descricao: Scalars['String']
}

export enum TipoVisualizacaoBuscaAtivaVacinacaoEnum {
  CALENDARIO_VACINAL = 'CALENDARIO_VACINAL',
  COVID_19 = 'COVID_19',
}

export type TituloPatente = {
  __typename?: 'TituloPatente'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type TodasVinculacoesEquipeDto = {
  __typename?: 'TodasVinculacoesEquipeDto'
  equipeEmulti: Equipe
  vinculacoesPorEstabelecimento: Array<VinculacaoEquipePorEstabelecimentoDto>
  quantidadeVinculacoes: Scalars['Int']
}

export type TodasVinculacoesEquipesInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type TodasVinculacoesEquipesPayload = {
  __typename?: 'TodasVinculacoesEquipesPayload'
  content: Array<TodasVinculacoesEquipeDto>
  pageInfo: PageInfo
}

export type TotalImoveisPermissao = {
  __typename?: 'TotalImoveisPermissao'
  totalImoveis: Scalars['Int']
  totalImoveisPermitidos: Scalars['Int']
}

export type Transmissao = {
  __typename?: 'Transmissao'
  id: Scalars['ID']
  importacaoArquivo: ImportacaoArquivo
  links?: Maybe<TransmissaoLinksPayload>
}

export type TransmissaoLinksArgs = {
  input: TransmissaoLinksConnectionInput
}

export type TransmissaoLink = {
  __typename?: 'TransmissaoLink'
  id: Scalars['ID']
  hostname: Scalars['String']
  name: Scalars['String']
  ativo: Scalars['Boolean']
  statusConexao?: Maybe<Scalars['Boolean']>
  dataTesteConexao?: Maybe<Scalars['Instant']>
  actions: TransmissaoLinkActions
}

export type TransmissaoLinkActions = {
  __typename?: 'TransmissaoLinkActions'
  alternarAtivacao: Action
  excluir: Action
  editar: Action
}

export type TransmissaoLinkInsertDto = {
  hostname?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type TransmissaoLinkPagingQueryFiltroDto = {
  query?: Maybe<Scalars['String']>
  mostrarInativos?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type TransmissaoLinksConnectionInput = {
  query?: Maybe<Scalars['String']>
  mostrarInativos?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type TransmissaoLinksPayload = {
  __typename?: 'TransmissaoLinksPayload'
  content: Array<Maybe<TransmissaoLink>>
  pageInfo: PageInfo
}

export type TransmissaoLinkUpdateDto = {
  id: Scalars['ID']
  hostname?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type TransmissaoRecebimentoCnesDetailFiltro = {
  numCnes: Scalars['String']
  mesRecebimento: Scalars['Int']
  anoRecebimento: Scalars['Int']
}

export type TransmissaoRecebimentoCnesDetailItem = {
  __typename?: 'TransmissaoRecebimentoCnesDetailItem'
  nomeEstabelecimento?: Maybe<Scalars['String']>
  tipoFicha: Scalars['String']
  quantidade: Scalars['Int']
}

export type TransmissaoRecebimentoCnesDto = {
  __typename?: 'TransmissaoRecebimentoCnesDto'
  numCnes: Scalars['String']
  mesRecebimento: Scalars['Int']
  anoRecebimento: Scalars['Int']
  fichasRecebidas: Scalars['Int']
  estabelecimentoOrigem?: Maybe<Scalars['String']>
}

export type TransmissaoRecebimentoCnesFiltro = {
  query?: Maybe<Scalars['String']>
  estabelecimentoOrigem?: Maybe<Scalars['String']>
  competencia?: Maybe<ReferenceMonth>
  pageParams?: Maybe<PageParams>
}

export type TransmissaoRecebimentoCnesPayload = {
  __typename?: 'TransmissaoRecebimentoCnesPayload'
  content: Array<Maybe<TransmissaoRecebimentoCnesDto>>
  pageInfo: PageInfo
}

export type TransmissaoRecebimentoLoteCountsDto = {
  __typename?: 'TransmissaoRecebimentoLoteCountsDto'
  fichasValidas: Scalars['Int']
  fichasInvalidas: Scalars['Int']
  totalFichas: Scalars['Int']
  fichasRepetidas: Scalars['Int']
}

export type TransmissaoRecebimentoLoteDto = {
  __typename?: 'TransmissaoRecebimentoLoteDto'
  id: Scalars['ID']
  numeroLote?: Maybe<Scalars['String']>
  responsavel: Scalars['String']
  cpfResponsavel: Scalars['String']
  processado?: Maybe<Scalars['Boolean']>
  data: Scalars['Instant']
  counts: TransmissaoRecebimentoLoteCountsDto
  possuiInconsistencia: Scalars['Boolean']
  origemLote: TipoOrigemDadoTransporte
  credencialRecebimento?: Maybe<CredencialRecebimentoExterno>
}

export type TransmissaoRecebimentoLoteFiltro = {
  query?: Maybe<Scalars['String']>
  origensLote?: Maybe<Array<Maybe<Scalars['Long']>>>
  periodo?: Maybe<Period>
  pageParams?: Maybe<PageParams>
}

export type TransmissaoRecebimentoQueryPayload = {
  __typename?: 'TransmissaoRecebimentoQueryPayload'
  content: Array<Maybe<TransmissaoRecebimentoLoteDto>>
  pageInfo: PageInfo
}

export type TrocaExecutanteCuidadoCompartilhadoInput = {
  lotacaoExecutanteId: Scalars['ID']
  motivo: Scalars['String']
}

export enum TurnoEnum {
  MANHA = 'MANHA',
  TARDE = 'TARDE',
  NOITE = 'NOITE',
}

export type Uf = {
  __typename?: 'UF'
  id: Scalars['ID']
  nome: Scalars['String']
  sigla: Scalars['String']
}

export type UltimaFichaCadastradaDto = {
  __typename?: 'UltimaFichaCadastradaDto'
  id: Scalars['ID']
  uuidFichaOrigem?: Maybe<Scalars['String']>
  isProcessado: Scalars['Boolean']
}

export type UltimasMedicoesInput = {
  prontuarioId: Scalars['ID']
  data?: Maybe<Scalars['Instant']>
}

export type UltimaVacina = {
  __typename?: 'UltimaVacina'
  imunobiologicoNome?: Maybe<Scalars['String']>
  dataAplicacao?: Maybe<Scalars['LocalDate']>
  dataAprazamento?: Maybe<Scalars['LocalDate']>
  doseNomeApresentacao?: Maybe<Scalars['String']>
}

export type UltimoAtendimentoProfissionalFinalizadoQueryInput = {
  cidadaoId: Scalars['ID']
  onlyAtendimentosEvoluemProntuario?: Maybe<Scalars['Boolean']>
}

export type UnicaLotacaoHeaderInput = {
  cns: Scalars['String']
  cbo: Scalars['String']
  cnes: Scalars['String']
  ine?: Maybe<Scalars['String']>
  dataAtendimento?: Maybe<Scalars['LocalDate']>
  ibge: Scalars['String']
}

export type UnidadeConsultaHorusIds = {
  unidadeSaudeId: Scalars['ID']
  unidadeConsultaId: Scalars['ID']
}

export type UnidadeMedida = {
  __typename?: 'UnidadeMedida'
  id: Scalars['ID']
  nome: Scalars['String']
  nomePlural: Scalars['String']
}

export type UnidadeMedidaPayload = {
  __typename?: 'UnidadeMedidaPayload'
  content: Array<Maybe<UnidadeMedida>>
  pageInfo: PageInfo
}

export type UnidadeMedidaQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export enum UnidadeMedidaTempoEnum {
  DIAS = 'DIAS',
  SEMANAS = 'SEMANAS',
  MESES = 'MESES',
  INDETERMINADO = 'INDETERMINADO',
  NAO_INFORMADO = 'NAO_INFORMADO',
}

export enum UnidadePeriodoRelatorioGerencialEnum {
  SEMANA = 'SEMANA',
  MES = 'MES',
  DIA = 'DIA',
}

export type UnidadeSaude = {
  __typename?: 'UnidadeSaude'
  id: Scalars['ID']
  nome?: Maybe<Scalars['String']>
  cnes: Scalars['String']
  cnpj?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  telefone1?: Maybe<Scalars['String']>
  telefone2?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  tipo: TipoUnidadeSaude
  subtipo?: Maybe<SubtipoUnidadeSaude>
  tipoEstabelecimento: TipoEstabelecimentoEnum
  isEstabelecimentoAtencaoPrimaria: Scalars['Boolean']
  complexidades?: Maybe<Array<Maybe<Complexidade>>>
  ativo?: Maybe<Scalars['Boolean']>
  endereco?: Maybe<Endereco>
  actions: UnidadeSaudeActions
}

export type UnidadeSaudeActions = {
  __typename?: 'UnidadeSaudeActions'
  permissaoConexaoHorus: Action
  permissaoAtivarInativarTipoServico: Action
}

export type UnidadeSaudePayload = {
  __typename?: 'UnidadeSaudePayload'
  content: Array<Maybe<UnidadeSaude>>
  pageInfo: PageInfo
}

export type UnidadeSaudeTipoServico = {
  __typename?: 'UnidadeSaudeTipoServico'
  tipoServico: TipoServico
  ativo: Scalars['Boolean']
}

export type UnidadeSaudeTipoServicoFormInput = {
  unidadeSaudeId: Scalars['ID']
  tipoServicoId: Scalars['ID']
}

export type UnidadeSaudeTipoServicoQueryInput = {
  query?: Maybe<Scalars['String']>
  mostrarInativos?: Maybe<Scalars['Boolean']>
  unidadeSaudeId: Scalars['ID']
  pageParams?: Maybe<PageParams>
}

export type UnidadeSaudeTiposServicoPayload = {
  __typename?: 'UnidadeSaudeTiposServicoPayload'
  content: Array<Maybe<UnidadeSaudeTipoServico>>
  pageInfo: PageInfo
}

export type UnidadesSaudeByGarantiaAcessoCidadaoInput = {
  cidadaoId: Scalars['ID']
  tipoAtendimento?: Maybe<ListaEsperaTipoAtendimentoEnum>
  tipoServico?: Maybe<Scalars['ID']>
}

export type UnidadesSaudeByTipoVinculacaoServicoInput = {
  query?: Maybe<Scalars['String']>
  tipoEstabelecimento: VinculacaoServicoTipoEstabelecimentoEnum
}

export type UnidadesSaudeQueryInput = {
  query?: Maybe<Scalars['String']>
  mostrarInativas?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
  somenteUnidadesPermitidas?: Maybe<Scalars['Boolean']>
}

export type UnificacaoBase = {
  __typename?: 'UnificacaoBase'
  id: Scalars['ID']
  nomeInstalacao: Scalars['String']
  chave?: Maybe<Scalars['String']>
  idInstalacao?: Maybe<Scalars['String']>
  offsetProfissional: Scalars['Int']
  offsetCidadao: Scalars['Int']
  status?: Maybe<StatusUnificacaoBaseEnum>
  dataSolicitacao: Scalars['Instant']
  dataFimUnificacao?: Maybe<Scalars['Instant']>
}

export enum UnificacaoBaseOrigemBroadcastEnum {
  ENVIO = 'ENVIO',
  RECEBIMENTO = 'RECEBIMENTO',
  RECUSA = 'RECUSA',
  LOGIN = 'LOGIN',
}

export type UnificacaoCadastroInput = {
  idCidadaoMaster: Scalars['ID']
  idCidadaos: Array<Maybe<Scalars['ID']>>
}

export type UnificacoesCidadao = {
  __typename?: 'UnificacoesCidadao'
  id: Scalars['ID']
  nomeProfissional?: Maybe<Scalars['String']>
  dataUnificacao?: Maybe<Scalars['Instant']>
  cidadaos?: Maybe<Array<Maybe<Cidadao>>>
}

export type Usuario = {
  __typename?: 'Usuario'
  id: Scalars['ID']
  bloqueado: Scalars['Boolean']
  aceitouTermosUso: Scalars['Boolean']
  forcarTrocaSenha: Scalars['Boolean']
  visualizouNovidades: Scalars['Boolean']
  notificaNovidadesVersao: Scalars['Boolean']
  mostrarPesquisaSatisfacao: Scalars['Boolean']
  hashId: Scalars['String']
  aceitouTermoTeleinterconsulta: Scalars['Boolean']
}

export type VacinacaoUltimaVacinaInput = {
  idProntuario?: Maybe<Scalars['ID']>
  dataAtendimento?: Maybe<Scalars['Instant']>
}

export type ValidateNovoAdminInput = {
  id?: Maybe<Scalars['ID']>
  cpf: Scalars['String']
  contraChave: Scalars['String']
}

export enum ValidationState {
  ERRO_COMUNICACAO = 'ERRO_COMUNICACAO',
  SUCESSO_COMUNICACAO = 'SUCESSO_COMUNICACAO',
  UBS_SEM_HORUS = 'UBS_SEM_HORUS',
  UBS_COM_HORUS = 'UBS_COM_HORUS',
}

export type VerificarParticipantePermitidoVideochamadaInput = {
  videochamadaUuid: Scalars['String']
  participanteId: Scalars['String']
}

export type VersaoTransportInput = {
  major: Scalars['Int']
  minor: Scalars['Int']
  revision: Scalars['Int']
}

export type ViaAdministracao = {
  __typename?: 'ViaAdministracao'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type Videochamada = {
  __typename?: 'Videochamada'
  id: Scalars['ID']
  uuid: Scalars['String']
  dataInicio?: Maybe<Scalars['Instant']>
  dataFim?: Maybe<Scalars['Instant']>
  agendamento?: Maybe<Agendado>
  owner?: Maybe<Acesso>
  status: StatusVideochamadaEnum
}

export enum VigilanciaEmSaudeBucalEnum {
  ABSCESSO_DENTO_ALVEOLAR = 'ABSCESSO_DENTO_ALVEOLAR',
  ALTERACAO_EM_TECIDOS_MOLES = 'ALTERACAO_EM_TECIDOS_MOLES',
  DOR_DE_DENTE = 'DOR_DE_DENTE',
  FENDAS_OU_FISSURAS_LABIO_PALATAIS = 'FENDAS_OU_FISSURAS_LABIO_PALATAIS',
  FLUOROSE_DENTARIA_MODERADA_OU_SEVERA = 'FLUOROSE_DENTARIA_MODERADA_OU_SEVERA',
  TRAUMATISMO_DENTO_ALVEOLAR = 'TRAUMATISMO_DENTO_ALVEOLAR',
  NAO_IDENTIFICADO = 'NAO_IDENTIFICADO',
}

export type VigilanciaSaudeBucalInput = {
  tiposVigilancia: Array<VigilanciaEmSaudeBucalEnum>
  necessidadeProtese?: Maybe<Scalars['Boolean']>
}

export type VinculacaoEquipePorEstabelecimentoDto = {
  __typename?: 'VinculacaoEquipePorEstabelecimentoDto'
  estabelecimento: UnidadeSaude
  equipes: Array<Equipe>
}

export type VinculacaoServicoDto = {
  __typename?: 'VinculacaoServicoDto'
  referencia: UnidadeSaude
  vinculacoes: Array<UnidadeSaude>
  quantidadeVinculacoes: Scalars['Int']
}

export type VinculacaoServicosInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type VinculacaoServicosPayload = {
  __typename?: 'VinculacaoServicosPayload'
  content: Array<VinculacaoServicoDto>
  pageInfo: PageInfo
}

export enum VinculacaoServicoTipoEstabelecimentoEnum {
  REFERENCIA = 'REFERENCIA',
  VINCULADOS = 'VINCULADOS',
}

export enum VisibilidadeLembreteEnum {
  PUBLICO = 'PUBLICO',
  SOMENTE_EU = 'SOMENTE_EU',
}

export type VisitasQueryInput = {
  imovelId: Scalars['ID']
  pageParams?: Maybe<PageParams>
}

export type VisitasQueryPayload = {
  __typename?: 'VisitasQueryPayload'
  content: Array<FatoVisitaDomiciliar>
  pageInfo: PageInfo
}

export type VisualizarCuidadoCompartilhadoInput = {
  prontuarioId: Scalars['ID']
  cuidadoCompartilhadoId: Scalars['ID']
  isDiscutirCaso: Scalars['Boolean']
  justificativa?: Maybe<Scalars['String']>
}

export type LogoutMutationVariables = {}

export type LogoutMutation = { __typename?: 'Mutation'; logout?: Maybe<{ __typename?: 'Sessao' } & SessaoDataFragment> }

export type SessaoQueryVariables = {}

export type SessaoQuery = { __typename?: 'Query'; sessao?: Maybe<{ __typename?: 'Sessao' } & SessaoDataFragment> }

export type SessaoDataFragment = {
  __typename?: 'Sessao'
  id: string
  timeout: number
  expDateAccessTokenGovBr?: Maybe<any>
  recursos?: Maybe<Array<Maybe<string>>>
  sessionProvider: SessionProviderEnum
  acesso?: Maybe<
    | ({
        __typename?: 'Lotacao'
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        unidadeSaude: {
          __typename?: 'UnidadeSaude'
          id: string
          nome?: Maybe<string>
          cnes: string
          tipoEstabelecimento: TipoEstabelecimentoEnum
          isEstabelecimentoAtencaoPrimaria: boolean
          tipo: { __typename?: 'TipoUnidadeSaude'; id: string; codigoMs: any }
          subtipo?: Maybe<{ __typename?: 'SubtipoUnidadeSaude'; id: string; codigoMs: string }>
        }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string } & TipoEquipeFragment>
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
        perfis?: Maybe<Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>>
      } & Cbo_Lotacao_Fragment)
    | ({
        __typename?: 'Estagio'
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        unidadeSaude: {
          __typename?: 'UnidadeSaude'
          id: string
          nome?: Maybe<string>
          cnes: string
          tipoEstabelecimento: TipoEstabelecimentoEnum
          tipo: { __typename?: 'TipoUnidadeSaude'; id: string; codigoMs: any }
          subtipo?: Maybe<{ __typename?: 'SubtipoUnidadeSaude'; id: string; codigoMs: string }>
        }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string } & TipoEquipeFragment>
        lotacaoSupervisora: {
          __typename: 'Lotacao'
          id: string
          profissional: {
            __typename?: 'Profissional'
            id: string
            nome: string
            numeroConselhoClasse?: Maybe<string>
            conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; sigla: string }>
            ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
          }
          municipio: {
            __typename?: 'Municipio'
            id: string
            nome: string
            uf: { __typename?: 'UF'; id: string; sigla: string }
          }
          unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
        }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
        perfis: Array<{ __typename?: 'Perfil'; id: string; nome: string }>
      } & Cbo_Estagio_Fragment)
    | {
        __typename?: 'AdministradorGeral'
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        municipio?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>
      }
    | {
        __typename?: 'AdministradorMunicipal'
        autorizado: boolean
        habilitado: boolean
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>
      }
    | {
        __typename?: 'GestorEstadual'
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        municipio?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>
      }
    | {
        __typename?: 'GestorMunicipal'
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>
      }
  >
  profissional: {
    __typename?: 'Profissional'
    id: string
    cpf: string
    cns?: Maybe<string>
    nome: string
    numeroConselhoClasse?: Maybe<string>
    conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; sigla: string; descricao?: Maybe<string> }>
    ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
    usuario: {
      __typename?: 'Usuario'
      id: string
      aceitouTermosUso: boolean
      aceitouTermoTeleinterconsulta: boolean
      forcarTrocaSenha: boolean
      visualizouNovidades: boolean
      mostrarPesquisaSatisfacao: boolean
      notificaNovidadesVersao: boolean
      hashId: string
    }
    acessos?: Maybe<
      Array<
        | ({ __typename?: 'Lotacao'; id: string; tipo: TipoAcesso } & AcessoLotacaoFragment)
        | ({ __typename?: 'Estagio'; id: string; tipo: TipoAcesso } & AcessoEstagioFragment)
        | { __typename?: 'AdministradorGeral'; id: string; tipo: TipoAcesso }
        | ({
            __typename?: 'AdministradorMunicipal'
            id: string
            tipo: TipoAcesso
          } & AcessoAdministradorMunicipalFragment)
        | ({ __typename?: 'GestorEstadual'; id: string; tipo: TipoAcesso } & AcessoGestorEstadualFragment)
        | ({ __typename?: 'GestorMunicipal'; id: string; tipo: TipoAcesso } & AcessoGestorMunicipalFragment)
      >
    >
  }
}

export type AcessoAdministradorMunicipalFragment = {
  __typename?: 'AdministradorMunicipal'
  municipio: { __typename?: 'Municipio'; id: string; nome: string }
}

export type AcessoGestorEstadualFragment = {
  __typename?: 'GestorEstadual'
  uf: { __typename?: 'UF'; id: string; nome: string }
}

export type AcessoGestorMunicipalFragment = {
  __typename?: 'GestorMunicipal'
  municipio: { __typename?: 'Municipio'; id: string; nome: string }
}

export type AcessoLotacaoFragment = {
  __typename?: 'Lotacao'
  equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
  unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
} & Cbo_Lotacao_Fragment

export type AcessoEstagioFragment = {
  __typename?: 'Estagio'
  lotacaoSupervisora: {
    __typename?: 'Lotacao'
    id: string
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
  }
  equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
  unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
} & Cbo_Estagio_Fragment

export type TipoEquipeFragment = {
  __typename?: 'Equipe'
  tipoEquipe: { __typename?: 'TipoEquipe'; id: string; descricao?: Maybe<string>; nome: string; sigla: string }
}

export type Cbo_Lotacao_Fragment = {
  __typename?: 'Lotacao'
  cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
}

export type Cbo_Estagio_Fragment = {
  __typename?: 'Estagio'
  cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
}

export type CboFragment = Cbo_Lotacao_Fragment | Cbo_Estagio_Fragment

export type EnderecoQueryVariables = {
  cep: Scalars['String']
}

export type EnderecoQuery = {
  __typename?: 'Query'
  endereco?: Maybe<{
    __typename?: 'EnderecoQueryPayload'
    logradouro?: Maybe<string>
    municipio?: Maybe<{ __typename?: 'Municipio'; id: string; nome: string }>
    uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
    tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>
    bairro?: Maybe<{ __typename?: 'Bairro'; id: string; nome: string }>
  }>
}

export type AldeiaSelectFieldQueryVariables = {
  input: AldeiaInput
}

export type AldeiaSelectFieldQuery = {
  __typename?: 'Query'
  aldeias: {
    __typename?: 'AldeiaQueryPayload'
    content: Array<{
      __typename?: 'Aldeia'
      id: string
      nome: string
      poloBase: {
        __typename?: 'PoloBase'
        id: string
        nome: string
        dsei: { __typename?: 'Dsei'; id: string; nome: string }
      }
      municipio: Array<{
        __typename?: 'Municipio'
        id: string
        nome: string
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
      }>
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type AlergiaSelectFieldQueryVariables = {
  filtro: AlergiasQueryInput
}

export type AlergiaSelectFieldQuery = {
  __typename?: 'Query'
  alergias: {
    __typename?: 'AlergiaQueryPayload'
    content: Array<
      {
        __typename?: 'Alergia'
        substanciaEspecifica?: Maybe<
          { __typename?: 'SubstanciaEspecificaAlergia'; sigla?: Maybe<string> } & SubstanciaEspecificaAlergiaFragment
        >
        ultimaEvolucao: {
          __typename?: 'EvolucaoAlergia'
          id: string
          dataInicio?: Maybe<any>
          manifestacoesLegado?: Maybe<string>
          possuiSubstanciaEspecificaCbara?: Maybe<boolean>
          manifestacoes: Array<{
            __typename?: 'ManifestacaoAlergia'
            id: string
            nome: string
            sinonimo?: Maybe<string>
          }>
        }
      } & AlergiaFragment
    >
  }
}

export type BairroSelectFieldQueryVariables = {
  input: BairroInput
}

export type BairroSelectFieldQuery = {
  __typename?: 'Query'
  bairros: {
    __typename?: 'BairroQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Bairro'
        id: string
        nome: string
        municipio: { __typename?: 'Municipio'; id: string; nome: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryVariables = {}

export type AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery = {
  __typename?: 'Query'
  atendimentoCompartilhadoRelatorioGerencialAtendimento?: Maybe<Array<string>>
}

export type CategoriaProfissionalRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type CategoriaProfissionalRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  categoriasProfissionalRelatorioGerencial?: Maybe<Array<string>>
}

export type CboRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type CboRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  cbosRelatorioGerencial?: Maybe<Array<string>>
}

export type CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables = {}

export type CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  condutasRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<string>>
}

export type DiaDaSemanaRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type DiaDaSemanaRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  diasDaSemanaRelatorioGerencial?: Maybe<Array<string>>
}

export type DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables = {
  input?: Maybe<Scalars['String']>
}

export type DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery = {
  __typename?: 'Query'
  dosesImunobiologicosRelatorioGerencialVacinacao?: Maybe<Array<string>>
}

export type EquipeRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type EquipeRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  equipesRelatorioGerencial?: Maybe<Array<string>>
}

export type EstrategiaRelatorioGerencialVacinacaoSelectFieldQueryVariables = {}

export type EstrategiaRelatorioGerencialVacinacaoSelectFieldQuery = {
  __typename?: 'Query'
  estrategiasRelatorioGerencialVacinacao?: Maybe<Array<string>>
}

export type FaixaEtariaRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type FaixaEtariaRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  faixasEtariaRelatorioGerencial?: Maybe<Array<string>>
}

export type GestanteRelatorioGerencialVacinacaoSelectFieldQueryVariables = {}

export type GestanteRelatorioGerencialVacinacaoSelectFieldQuery = {
  __typename?: 'Query'
  gestanteRelatorioGerencialVacinacao?: Maybe<Array<string>>
}

export type HorarioRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type HorarioRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  horariosRelatorioGerencial?: Maybe<Array<string>>
}

export type ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables = {
  input?: Maybe<Scalars['String']>
}

export type ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery = {
  __typename?: 'Query'
  imunobiologicosRelatorioGerencialVacinacao?: Maybe<Array<string>>
}

export type MesRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type MesRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  mesesRelatorioGerencial?: Maybe<Array<string>>
}

export type MunicipioRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type MunicipioRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  municipiosRelatorioGerencial?: Maybe<Array<string>>
}

export type PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables = {}

export type PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  primeirasRespostasRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<string>>
}

export type PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables = {}

export type PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  prioridadesRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<string>>
}

export type ProfissionalRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type ProfissionalRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  profissionaisRelatorioGerencial?: Maybe<Array<string>>
}

export type PuerperaRelatorioGerencialVacinacaoSelectFieldQueryVariables = {}

export type PuerperaRelatorioGerencialVacinacaoSelectFieldQuery = {
  __typename?: 'Query'
  puerperaRelatorioGerencialVacinacao?: Maybe<Array<string>>
}

export type RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables = {}

export type RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  realizadoPorRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<string>>
}

export type SexoRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type SexoRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  sexosRelatorioGerencial?: Maybe<Array<string>>
}

export type TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables = {}

export type TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  temposDeEsperaRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<string>>
}

export type TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables = {}

export type TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  temposDeRespostaRelatorioGerencialCuidadoCompartilhado?: Maybe<Array<string>>
}

export type TempoEntreAgendamentoEConsultaSelectFieldQueryVariables = {}

export type TempoEntreAgendamentoEConsultaSelectFieldQuery = {
  __typename?: 'Query'
  tempoEntreAgendamentoEConsultaRelatorioGerencialAbsenteismo?: Maybe<Array<string>>
}

export type TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryVariables = {}

export type TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery = {
  __typename?: 'Query'
  tiposDeAtendimentoRelatorioGerencialAtendimento?: Maybe<Array<string>>
}

export type TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryVariables = {}

export type TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery = {
  __typename?: 'Query'
  tiposDeConsultaRelatorioGerencialAtendimento?: Maybe<Array<string>>
}

export type TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryVariables = {}

export type TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery = {
  __typename?: 'Query'
  tiposDeFaltaRelatorioGerencialAbsenteismo?: Maybe<Array<string>>
}

export type TipoProducaoRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type TipoProducaoRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  tiposProducaoRelatorioGerencial?: Maybe<Array<string>>
}

export type TurnoRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
}

export type TurnoRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  turnosRelatorioGerencial?: Maybe<Array<string>>
}

export type UnidadeSaudeRelatorioGerencialSelectFieldQueryVariables = {
  tipoModulo?: Maybe<TipoModuloEnum>
  input?: Maybe<Scalars['String']>
}

export type UnidadeSaudeRelatorioGerencialSelectFieldQuery = {
  __typename?: 'Query'
  unidadesSaudeRelatorioGerencial?: Maybe<Array<string>>
}

export type ViajanteRelatorioGerencialVacinacaoSelectFieldQueryVariables = {}

export type ViajanteRelatorioGerencialVacinacaoSelectFieldQuery = {
  __typename?: 'Query'
  viajanteRelatorioGerencialVacinacao?: Maybe<Array<string>>
}

export type CboCuidadoCompartilhadoSelectFieldQueryVariables = {
  input: CbosCuidadoCompartilhadoQueryInput
}

export type CboCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  cbosVinculacoesServico: {
    __typename?: 'CbosCuidadoCompartilhadoPayload'
    content: Array<{
      __typename?: 'CbosCuidadoCompartilhadoDto'
      isVinculado: boolean
      cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CboSelectFieldQueryVariables = {
  input: CbosQueryInput
}

export type CboSelectFieldQuery = {
  __typename?: 'Query'
  cbos: {
    __typename?: 'CbosQueryPayload'
    content: Array<
      Maybe<{ __typename?: 'CboMunicipio'; cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string } }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CiapCid10SelectFieldQueryVariables = {
  inputCiap: CiapQueryInput
  inputCid10: Cid10QueryInput
}

export type CiapCid10SelectFieldQuery = {
  __typename?: 'Query'
  ciaps: {
    __typename?: 'CiapQueryPayLoad'
    content: Array<Maybe<{ __typename?: 'Ciap'; id: string; codigo: string; descricao: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
  cids: {
    __typename?: 'Cid10QueryPayLoad'
    content: Array<Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type AntecedenteCiapSelectFieldQueryVariables = {
  input: CiapQueryInput
}

export type AntecedenteCiapSelectFieldQuery = {
  __typename?: 'Query'
  ciaps: {
    __typename?: 'CiapQueryPayLoad'
    content: Array<Maybe<{ __typename?: 'Ciap'; id: string; codigo: string; descricao: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CiapSelectFieldQueryVariables = {
  input: CiapQueryInput
  fetchCID10Encaminhamento: Scalars['Boolean']
}

export type CiapSelectFieldQuery = {
  __typename?: 'Query'
  ciaps: {
    __typename?: 'CiapQueryPayLoad'
    content: Array<
      Maybe<{
        __typename?: 'Ciap'
        id: string
        codigo: string
        descricao: string
        inclui?: Maybe<string>
        exclui?: Maybe<string>
        cid10Encaminhamento?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CidSelectFieldQueryVariables = {
  input: Cid10QueryInput
  fetchCiapsRelacionadas: Scalars['Boolean']
}

export type CidSelectFieldQuery = {
  __typename?: 'Query'
  cids: {
    __typename?: 'Cid10QueryPayLoad'
    content: Array<
      Maybe<{
        __typename?: 'Cid10'
        id: string
        codigo: string
        nome: string
        ciapsRelacionadas?: Maybe<Array<Maybe<{ __typename?: 'Ciap'; id: string }>>>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CidadaoAtendimentoPorCnsSelectFieldQueryVariables = {
  input: CidadaosComboQueryInput
}

export type CidadaoAtendimentoPorCnsSelectFieldQuery = {
  __typename?: 'Query'
  cidadaosComboByCns: Array<Maybe<{ __typename?: 'CidadaoBasico' } & CidadaoBasicoFragment>>
}

export type CidadaoAtendimentoPorCpfSelectFieldQueryVariables = {
  input: CidadaosComboQueryInput
}

export type CidadaoAtendimentoPorCpfSelectFieldQuery = {
  __typename?: 'Query'
  cidadaosComboByCpf: Array<Maybe<{ __typename?: 'CidadaoBasico' } & CidadaoBasicoFragment>>
}

export type CidadaoAtendimentoPorDataNascimentoSelectFieldQueryVariables = {
  input: CidadaosComboQueryInput
}

export type CidadaoAtendimentoPorDataNascimentoSelectFieldQuery = {
  __typename?: 'Query'
  cidadaosComboByDataNascimento: Array<Maybe<{ __typename?: 'CidadaoBasico' } & CidadaoBasicoFragment>>
}

export type CidadaoAtendimentoPorNomeSelectFieldQueryVariables = {
  input: CidadaosComboQueryInput
}

export type CidadaoAtendimentoPorNomeSelectFieldQuery = {
  __typename?: 'Query'
  cidadaosComboByNome: Array<Maybe<{ __typename?: 'CidadaoBasico' } & CidadaoBasicoFragment>>
}

export type CidadaoAtendimentoSelectFieldQueryVariables = {
  input: CidadaosComboQueryInput
  emAtencaoDomiciliar: Scalars['Boolean']
  equipeResponsavelId?: Maybe<Scalars['ID']>
  retrieveContato: Scalars['Boolean']
}

export type CidadaoAtendimentoSelectFieldQuery = {
  __typename?: 'Query'
  cidadaosCombo: Array<
    Maybe<
      {
        __typename?: 'CidadaoBasico'
        isCidadaoAusente?: Maybe<boolean>
        contato?: Maybe<{
          __typename?: 'ContatoCidadao'
          id: string
          telefoneCelular?: Maybe<string>
          email?: Maybe<string>
        }>
      } & CidadaoBasicoFragment
    >
  >
}

export type CidadaoSelectFieldQueryVariables = {
  input: CidadaosQueryInput
}

export type CidadaoSelectFieldQuery = {
  __typename?: 'Query'
  cidadaos: {
    __typename?: 'CidadaosPayload'
    content: Array<
      Maybe<{
        __typename?: 'Cidadao'
        id: string
        nome: string
        nomeSocial?: Maybe<string>
        nomeTradicional?: Maybe<string>
        cpf?: Maybe<string>
        cns?: Maybe<string>
        nomeMae?: Maybe<string>
        dataNascimento?: Maybe<any>
        sexo: SexoEnum
        identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ConselhoClasseSelectQueryVariables = {
  input: ConselhoClasseQueryInput
}

export type ConselhoClasseSelectQuery = {
  __typename?: 'Query'
  conselhosClasse: {
    __typename?: 'ConselhoClassePayload'
    content: Array<Maybe<{ __typename?: 'ConselhoClasse'; id: string; descricao?: Maybe<string> }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type DoseImunobiologicoVacinacaoSelectFieldQueryVariables = {
  input: DoseImunobiologicoVacinacaoQueryInput
}

export type DoseImunobiologicoVacinacaoSelectFieldQuery = {
  __typename?: 'Query'
  doseImunobiologicoVacinacao: {
    __typename?: 'DoseImunobiologicoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'DoseImunobiologico'
        id: string
        sigla: string
        nome: string
        nomeApresentacaoDose: string
        ordem: number
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type DseiSelectFieldQueryVariables = {
  input: DseiInput
}

export type DseiSelectFieldQuery = {
  __typename?: 'Query'
  dseis: {
    __typename?: 'DseiQueryPayload'
    content: Array<Maybe<{ __typename?: 'Dsei'; id: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type EquipeAdSelectFieldQueryVariables = {
  input: EquipesAdQueryInput
}

export type EquipeAdSelectFieldQuery = {
  __typename?: 'Query'
  equipesAD: {
    __typename?: 'EquipePayload'
    content: Array<
      Maybe<{
        __typename?: 'Equipe'
        id: string
        nome: string
        ine: string
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type EquipeSelectFieldQueryVariables = {
  input: EquipesQueryInput
}

export type EquipeSelectFieldQuery = {
  __typename?: 'Query'
  equipes: {
    __typename?: 'EquipePayload'
    content: Array<
      Maybe<{
        __typename?: 'Equipe'
        id: string
        nome: string
        ine: string
        ativo?: Maybe<boolean>
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type EspecialidadeSisregSelectFieldQueryVariables = {
  input: EspecialidadeSisregQueryInput
}

export type EspecialidadeSisregSelectFieldQuery = {
  __typename?: 'Query'
  especialidadesSisreg: {
    __typename?: 'EspecialidadeSisregQueryPayload'
    content: Array<Maybe<{ __typename?: 'EspecialidadeSisreg'; id: string; descricao: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type EstrategiaVacinacaoSelectFieldQueryVariables = {
  input: EstrategiasVacinacaoQueryInput
}

export type EstrategiaVacinacaoSelectFieldQuery = {
  __typename?: 'Query'
  estrategiasVacinacao: Array<{
    __typename?: 'EstrategiaVacinacao'
    id: string
    codigo: string
    nome: string
    estrategiaVacinacaoDbEnum: EstrategiaVacinacaoEnum
  }>
}

export type EtniaSelectFieldQueryVariables = {
  input: EtniaQueryInput
}

export type EtniaSelectFieldQuery = {
  __typename?: 'Query'
  etnias: {
    __typename?: 'EtniaPayload'
    content: Array<Maybe<{ __typename?: 'Etnia'; id: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables = {
  input: FichaNotificacaoQueryInput
}

export type FichaNotificacaoCasoSuspeitoSelectFieldQuery = {
  __typename?: 'Query'
  fichasNotificacao: {
    __typename?: 'FichaNotificacaoQueryPayload'
    content: Array<Maybe<{ __typename?: 'FichaNotificacaoCasoSuspeito'; id: string; nomeNotificacao: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type FormaFarmaceuticaSelectFieldQueryVariables = {
  input: FormaFarmaceuticaQueryInput
}

export type FormaFarmaceuticaSelectFieldQuery = {
  __typename?: 'Query'
  formasFarmaceuticas: {
    __typename?: 'FormaFarmaceuticaPayload'
    content: Array<Maybe<{ __typename?: 'FormaFarmaceutica'; id: string; nome: string }>>
  }
}

export type FuncaoSocialSelectFieldQueryVariables = {
  input: FuncaoSocialQueryInput
}

export type FuncaoSocialSelectFieldQuery = {
  __typename?: 'Query'
  funcoesSociais: {
    __typename?: 'FuncaoSocialPayload'
    content: Array<{ __typename?: 'FuncaoSocial'; id: string; nome: string }>
  }
}

export type GrupoAtendimentoSelectFieldQueryVariables = {
  inputQuery?: Maybe<Scalars['String']>
  onlyAtivos?: Maybe<Scalars['Boolean']>
}

export type GrupoAtendimentoSelectFieldQuery = {
  __typename?: 'Query'
  gruposAtendimento: Array<{ __typename?: 'GrupoAtendimento'; id: string; nome: string }>
}

export type GrupoCondicaoSelectFieldQueryVariables = {
  inputGrupo: GrupoCondicaoQueryInput
}

export type GrupoCondicaoSelectFieldQuery = {
  __typename?: 'Query'
  grupos: {
    __typename?: 'GrupoCondicaoQueryPayLoad'
    content: Array<
      Maybe<{
        __typename?: 'GrupoCondicaoDto'
        id: string
        grupoCondicao: GrupoCondicaoEnum
        ciaps?: Maybe<Array<Maybe<{ __typename?: 'Ciap'; id: string; codigo: string; descricao: string }>>>
        cids?: Maybe<Array<Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>>>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type HorarioAgendaSelectFieldQueryVariables = {
  input: HorariosAgendaQueryInput
}

export type HorarioAgendaSelectFieldQuery = {
  __typename?: 'Query'
  horariosAgenda: Array<{
    __typename?: 'HorarioAgenda'
    horario: any
    duracao: number
    isOcupado: boolean
    isReservadoAgendaOnline: boolean
  }>
}

export type LocalAplicacaoSelectFieldQueryVariables = {
  input: LocalAplicacaoQueryInput
}

export type LocalAplicacaoSelectFieldQuery = {
  __typename?: 'Query'
  locaisAplicacao: Array<{ __typename?: 'LocalAplicacao'; id: string; nome: string }>
}

export type LocalAtendimentoSelectFieldQueryVariables = {
  filtro: LocalAtendimentoQueryInput
}

export type LocalAtendimentoSelectFieldQuery = {
  __typename?: 'Query'
  localAtendimento: {
    __typename?: 'LocalAtendimentoPayload'
    content: Array<{ __typename?: 'LocalAtendimento'; id: string; localAtendimentoExibicao?: Maybe<string> }>
  }
}

export type LocalOcorrenciaSelectFieldQueryVariables = {
  input: LocalOcorrenciaQueryInput
}

export type LocalOcorrenciaSelectFieldQuery = {
  __typename?: 'Query'
  locaisOcorrencia: {
    __typename?: 'LocalOcorrenciaPayload'
    content: Array<{ __typename?: 'LocalOcorrencia'; id: string; nome: string }>
  }
}

export type LogradouroSelectFieldQueryVariables = {
  input: LogradouroInput
}

export type LogradouroSelectFieldQuery = {
  __typename?: 'Query'
  logradouros: {
    __typename?: 'LogradouroQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Logradouro'
        id: string
        nome: string
        nomeExibicao: string
        cep: string
        tituloPatente?: Maybe<{ __typename?: 'TituloPatente'; id: string; nome: string }>
        bairro: {
          __typename?: 'Bairro'
          id: string
          nome: string
          municipio: {
            __typename?: 'Municipio'
            id: string
            nome: string
            uf: { __typename?: 'UF'; id: string; sigla: string }
          }
        }
        tipoLogradouro: { __typename?: 'TipoLogradouro'; id: string; nome: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type LotacaoAgendaSelectFragment = {
  __typename?: 'Lotacao'
  id: string
  ativo: boolean
  importada: boolean
  hasConfiguracaoAgenda?: Maybe<boolean>
  hasConfiguracaoAgendaOnline?: Maybe<boolean>
  hasAgendamentoFuturo?: Maybe<boolean>
  profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string; email?: Maybe<string> }
  cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
  equipe?: Maybe<{
    __typename?: 'Equipe'
    id: string
    ine: string
    nome: string
    tipoEquipe: { __typename?: 'TipoEquipe'; id: string; descricao?: Maybe<string> }
  }>
}

export type LotacaoAgendaSelectQueryVariables = {
  id: Scalars['ID']
}

export type LotacaoAgendaSelectQuery = {
  __typename?: 'Query'
  lotacao: { __typename?: 'Lotacao' } & LotacaoAgendaSelectFragment
}

export type LotacoesAgendaSelectQueryVariables = {
  input: LotacoesAgendaInput
}

export type LotacoesAgendaSelectQuery = {
  __typename?: 'Query'
  lotacoesAgenda?: Maybe<{
    __typename?: 'LotacaoQueryPayload'
    content: Array<Maybe<{ __typename?: 'Lotacao' } & LotacaoAgendaSelectFragment>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }>
}

export type LotacaoAndEstagioSelectFieldQueryVariables = {
  input: LotacoesAndEstagiosInput
}

export type LotacaoAndEstagioSelectFieldQuery = {
  __typename?: 'Query'
  lotacoesAndEstagios: Array<
    | {
        __typename?: 'Lotacao'
        id: string
        profissional: {
          __typename?: 'Profissional'
          id: string
          nome: string
          cpf: string
          email?: Maybe<string>
          cns?: Maybe<string>
        }
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string; isAD: boolean }>
      }
    | {
        __typename?: 'Estagio'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
        lotacaoSupervisora: {
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        }
      }
    | { __typename?: 'AdministradorGeral'; id: string }
    | { __typename?: 'AdministradorMunicipal'; id: string }
    | { __typename?: 'GestorEstadual'; id: string }
    | { __typename?: 'GestorMunicipal'; id: string }
  >
}

export type LoteImunobiologicoSelectFieldQueryVariables = {
  input: LoteImunobiologicoQueryInput
}

export type LoteImunobiologicoSelectFieldQuery = {
  __typename?: 'Query'
  lotesImunobiologico: {
    __typename?: 'LotesImunobiologicoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'LoteImunobiologico'
        id: string
        lote?: Maybe<string>
        validade?: Maybe<any>
        fabricante?: Maybe<{ __typename?: 'ImunobiologicoFabricante'; id: string; nome: string }>
      }>
    >
  }
}

export type ManifestacaoAlergiaSelectFieldQueryVariables = {
  filtro: ManifestacoesAlergiaQueryInput
}

export type ManifestacaoAlergiaSelectFieldQuery = {
  __typename?: 'Query'
  manifestacoesAlergia: {
    __typename?: 'ManifestacaoAlergiaQueryPayload'
    content: Array<{ __typename?: 'ManifestacaoAlergia'; id: string; nome: string; sinonimo?: Maybe<string> }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type MedicamentoCatmatSelectFieldQueryVariables = {
  input: MedicamentoCatmatQueryInput
}

export type MedicamentoCatmatSelectFieldQuery = {
  __typename?: 'Query'
  medicamentosCatmat: {
    __typename?: 'MedicamentoCatmatQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'MedicamentoCatmat'
        id: string
        volume?: Maybe<string>
        codigoCatMat?: Maybe<string>
        codigoRename?: Maybe<string>
        medicamento: {
          __typename?: 'Medicamento'
          id: string
          principioAtivo: string
          unidadeFornecimento: string
          concentracao?: Maybe<string>
          formaFarmaceutica?: Maybe<{ __typename?: 'FormaFarmaceutica'; id: string; nome: string }>
        }
        unidadeFornecimento: { __typename?: 'UnidadeMedida'; id: string; nome: string; nomePlural: string }
        principioAtivo: {
          __typename?: 'PrincipioAtivo'
          id: string
          nome: string
          listaMaterial: { __typename?: 'ListaMedicamento'; id: string; tipo: string; tipoReceita: TipoReceitaEnum }
        }
        unidadeMedidaDose?: Maybe<{ __typename?: 'UnidadeMedida'; id: string; nome: string; nomePlural: string }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type MedicamentosQueryVariables = {
  input: MedicamentoQueryInput
}

export type MedicamentosQuery = {
  __typename?: 'Query'
  medicamentos: {
    __typename?: 'MedicamentoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Medicamento'
        id: string
        principioAtivo: string
        concentracao?: Maybe<string>
        unidadeFornecimento: string
        formaFarmaceutica?: Maybe<{ __typename?: 'FormaFarmaceutica'; id: string; nome: string }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type MicroareaImovelSelectFieldQueryVariables = {
  input: TerritorioEquipeQueryInput
}

export type MicroareaImovelSelectFieldQuery = { __typename?: 'Query'; microareasImoveis: Array<string> }

export type MicroareasSelectFieldQueryVariables = {
  equipeResponsavelId?: Maybe<Scalars['ID']>
}

export type MicroareasSelectFieldQuery = { __typename?: 'Query'; microareas: Array<Maybe<string>> }

export type MunicipioSelectFieldQueryVariables = {
  input: MunicipiosQueryInput
}

export type MunicipioSelectFieldQuery = {
  __typename?: 'Query'
  municipios: {
    __typename?: 'MunicipiosQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Municipio'
        id: string
        nome: string
        ibge?: Maybe<string>
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type PaisSelectFieldQueryVariables = {
  input: PaisQueryInput
}

export type PaisSelectFieldQuery = {
  __typename?: 'Query'
  paises: {
    __typename?: 'PaisQueryPayload'
    content: Array<Maybe<{ __typename?: 'Pais'; id: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type PerfilSelectFieldQueryVariables = {
  input: PerfisQueryInput
}

export type PerfilSelectFieldQuery = {
  __typename?: 'Query'
  perfis: {
    __typename?: 'PerfisQueryPayload'
    content: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type PoloBaseSelectFieldQueryVariables = {
  input: PoloBaseInput
}

export type PoloBaseSelectFieldQuery = {
  __typename?: 'Query'
  polosBases: {
    __typename?: 'PoloBaseQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'PoloBase'
        id: string
        nome: string
        dsei: { __typename?: 'Dsei'; id: string; nome: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ProblemaCondicaoFragment = {
  __typename?: 'Problema'
  id: string
  situacao: SituacaoProblema
  descricaoOutro?: Maybe<string>
  ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
  cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
  ultimaEvolucao: {
    __typename?: 'EvolucaoProblema'
    id: string
    dataInicio?: Maybe<any>
    dataFim?: Maybe<any>
    situacao: SituacaoProblema
    observacao?: Maybe<string>
    atualizadoEm?: Maybe<any>
  }
  evolucaoAvaliacaoCiapCid?: Maybe<{ __typename?: 'EvolucaoAvaliacaoCiapCid'; id: string }>
}

export type ProblemaCondicaoSelectFieldQueryVariables = {
  filtro: ProblemaQueryInput
}

export type ProblemaCondicaoSelectFieldQuery = {
  __typename?: 'Query'
  problemas: {
    __typename?: 'ProblemaQueryPayload'
    content: Array<{ __typename?: 'Problema' } & ProblemaCondicaoFragment>
  }
}

export type ProcedimentoOdontoSelectFieldQueryVariables = {
  input: ProcedimentoOdontoQueryInput
}

export type ProcedimentoOdontoSelectFieldQuery = {
  __typename?: 'Query'
  procedimentosEvolucaoOdonto: {
    __typename?: 'ProcedimentosQueryPayload'
    content: Array<Maybe<{ __typename?: 'Procedimento' } & ProcedimentoFragment>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ProcedimentoSelectFieldQueryVariables = {
  input: ProcedimentoQueryInput
}

export type ProcedimentoSelectFieldQuery = {
  __typename?: 'Query'
  procedimentos: {
    __typename?: 'ProcedimentosQueryPayload'
    content: Array<
      Maybe<
        { __typename?: 'Procedimento'; idadeMinima?: Maybe<number>; idadeMaxima?: Maybe<number> } & ProcedimentoFragment
      >
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ProfissionaisRelatorioInconsistenciaQueryVariables = {
  filtro: ProfissionaisRelatorioInconsistenciaQueryInput
}

export type ProfissionaisRelatorioInconsistenciaQuery = {
  __typename?: 'Query'
  profissionaisRelatorioInconsistencia: {
    __typename?: 'ProfissionaisInconsistenciaQueryPayload'
    content: Array<Maybe<{ __typename?: 'TransmissaoRecebimentoLoteDto'; responsavel: string; cpfResponsavel: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ProfissionalAdSelectFieldQueryVariables = {
  inputEquipe: EquipesAdQueryInput
  inputProf: ProfissionaisAdQueryInput
}

export type ProfissionalAdSelectFieldQuery = {
  __typename?: 'Query'
  profissionaisAD: {
    __typename?: 'ProfissionaisQueryPayload'
    content: Array<Maybe<{ __typename?: 'Profissional'; id: string; nome: string; cpf: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ProfissionalSelectQueryVariables = {
  input: ProfissionaisQueryInput
}

export type ProfissionalSelectQuery = {
  __typename?: 'Query'
  profissionais: {
    __typename?: 'ProfissionaisQueryPayload'
    content: Array<Maybe<{ __typename?: 'Profissional'; id: string; nome: string; cpf: string; cns?: Maybe<string> }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type SubstanciaAlergiaSelectFieldQueryVariables = {
  filtro: SubstanciasCbaraAlergiaQueryInput
}

export type SubstanciaAlergiaSelectFieldQuery = {
  __typename?: 'Query'
  substanciasCbaraAlergia: {
    __typename?: 'SubstanciaCbaraAlergiaQueryPayload'
    content: Array<{
      __typename?: 'SubstanciaCbaraAlergia'
      id: string
      categoria: CategoriaSubstanciaAlergia
      nome: string
      sinonimos?: Maybe<string>
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type SubtipoAtendimentoSelectFieldQueryVariables = {
  input: SubtipoAtendimentoHistoricoClinicoQueryInput
}

export type SubtipoAtendimentoSelectFieldQuery = {
  __typename?: 'Query'
  subtipoAtendimentoHistorico?: Maybe<Array<Maybe<SubtipoAtendimentoHistoricoClinico>>>
}

export type TipoEventoSelectFieldQueryVariables = {}

export type TipoEventoSelectFieldQuery = {
  __typename?: 'Query'
  auditoriaTipoEvento: Array<Maybe<{ __typename?: 'AuditoriaTipoEvento'; id: string; nome: string }>>
}

export type TipoLogradouroSelectFieldQueryVariables = {
  input: TipoLogradouroInput
}

export type TipoLogradouroSelectFieldQuery = {
  __typename?: 'Query'
  tiposLogradouro: {
    __typename?: 'TipoLogradouroQueryPayload'
    content: Array<Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type TipoRegistroAfetadoSelectFieldQueryVariables = {}

export type TipoRegistroAfetadoSelectFieldQuery = {
  __typename?: 'Query'
  auditoriaTipoRegistroAfetado: Array<Maybe<{ __typename?: 'AuditoriaTipoRegistroAfetado'; id: string; nome: string }>>
}

export type TipoRegistroSelectFieldQueryVariables = {
  input: TipoRegistroHistoricoClinicoQueryInput
}

export type TipoRegistroSelectFieldQuery = {
  __typename?: 'Query'
  tipoRegistroHistorico?: Maybe<Array<Maybe<TipoRegistroHistoricoClinico>>>
}

export type TipoServicoSelectFieldQueryVariables = {
  input: TipoServicoQueryInput
}

export type TipoServicoSelectFieldQuery = {
  __typename?: 'Query'
  tiposServico: {
    __typename?: 'TipoServicoQueryPayload'
    content: Array<Maybe<{ __typename?: 'TipoServico'; id: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type TiposOrigemDadoTransporteSelectFieldQueryVariables = {}

export type TiposOrigemDadoTransporteSelectFieldQuery = {
  __typename?: 'Query'
  tiposOrigemDadoTransporte: Array<{ __typename?: 'TipoOrigemDadoTransporte'; id: string; descricao: string }>
}

export type UnidadeMedidaSelectFieldQueryVariables = {
  input: UnidadeMedidaQueryInput
}

export type UnidadeMedidaSelectFieldQuery = {
  __typename?: 'Query'
  unidadesMedida: {
    __typename?: 'UnidadeMedidaPayload'
    content: Array<Maybe<{ __typename?: 'UnidadeMedida'; id: string; nome: string; nomePlural: string }>>
  }
}

export type UnidadesSaudeSelectFieldQueryVariables = {
  input: UnidadesSaudeQueryInput
}

export type UnidadesSaudeSelectFieldQuery = {
  __typename?: 'Query'
  unidadesSaude: {
    __typename?: 'UnidadeSaudePayload'
    content: Array<
      Maybe<{ __typename?: 'UnidadeSaude'; id: string; cnes: string; nome?: Maybe<string>; ativo?: Maybe<boolean> }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ViaAdministracaoSelectFieldQueryVariables = {
  input: AplicacaoMedicamentoQueryInput
}

export type ViaAdministracaoSelectFieldQuery = {
  __typename?: 'Query'
  aplicacoesMedicamento: {
    __typename?: 'AplicacaoMedicamentoPayload'
    content: Array<Maybe<{ __typename?: 'AplicacaoMedicamento'; id: string; nome: string }>>
  }
}

export type ViaAdministracaoVacinaSelectFieldQueryVariables = {
  inputQuery?: Maybe<Scalars['String']>
}

export type ViaAdministracaoVacinaSelectFieldQuery = {
  __typename?: 'Query'
  viasAdministracaoVacina: Array<{ __typename?: 'ViaAdministracao'; id: string; nome: string }>
}

export type CompartilharVideochamadaMutationVariables = {
  input: CompartilharVideochamadaInput
}

export type CompartilharVideochamadaMutation = { __typename?: 'Mutation'; compartilharVideochamada: boolean }

export type FlagsQueryVariables = {}

export type FlagsQuery = {
  __typename?: 'Query'
  info: {
    __typename?: 'Info'
    id: string
    flags: {
      __typename?: 'Flags'
      UNIFICACAO_BASE_ENABLED: boolean
      LISTA_REGISTRO_TARDIO_NOVA_ENABLED: boolean
      BUSCA_ATIVA_VACINACAO_ENABLED: boolean
      OBSERVACAO_ENABLED: boolean
      ASSINATURA_ELETRONICA_ENABLED: boolean
      API_DADOS_DW_ENABLED: boolean
      ENVIO_RAC_ENABLED: boolean
      ENVIO_RPM_ENABLED: boolean
      ENVIO_VACINA_ENABLED: boolean
      ENVIO_RNDS_ENABLED: boolean
      CUIDADO_COMPARTILHADO_ENABLED: boolean
      HISTORICO_CUIDADO_COMPARTILHADO_ENABLED: boolean
      PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED: boolean
      PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED: boolean
      COOKIE_ENABLED: boolean
      NPS_ENABLED: boolean
      CADASTRO_IMOVEL_ENABLED: boolean
      PILOTO_ATESTADO_DIGITAL_ENABLED: boolean
      PILOTO_VIDEOCHAMADA_2_ENABLED: boolean
      PILOTO_VIDEOCHAMADA_3_ENABLED: boolean
      PRESCRICAO_CONTROLADA: boolean
      IMPRESSAO_ESCUTA_INICIAL_ENABLED: boolean
      PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED: boolean
      GARANTIA_ACESSO_ENABLED: boolean
      PILOTO_VINCULACAO_SERVICO_1_ENABLED: boolean
      PRESCRICAO_TURNO: boolean
      ATUALIZACAO_SIGTAP_ENABLED: boolean
      ACOMPANHAMENTO_CONDICAO_SAUDE_ENABLED: boolean
      ACOMPANHAMENTO_GESTANTES_PUERPERAS_ENABLED: boolean
      SAUDE_INDIGENA_ENABLED: boolean
      PILOTO_ALERTAS_SDC_1_ENABLED: boolean
      ACOMPANHAMENTO_CONDICAO_SAUDE_CSV: boolean
      ATIVIDADE_COLETIVA_ENABLED: boolean
    }
  }
}

export type AlergiaFragment = {
  __typename?: 'Alergia'
  id: string
  criticidade: CriticidadeAlergia
  tipoReacao?: Maybe<TipoReacaoAlergia>
  grauCerteza: GrauCertezaAlergia
  categoriaSubstanciaEspecificaLegado?: Maybe<CategoriaSubstanciaAlergiaLegado>
  substanciaEspecificaLegado?: Maybe<string>
}

export type CidadaoBasicoFragment = {
  __typename?: 'CidadaoBasico'
  id: string
  nome: string
  nomeSocial?: Maybe<string>
  nomeTradicional?: Maybe<string>
  cpf?: Maybe<string>
  cns?: Maybe<string>
  nomeMae?: Maybe<string>
  dataNascimento?: Maybe<any>
  presenteListaAtendimento?: Maybe<boolean>
}

export type EncaminhamentoExternoEspecializadoFragment = {
  __typename?: 'EncaminhamentoExternoEspecializado'
  id: string
  motivoEncaminhamento?: Maybe<string>
  classificacaoRiscoEncaminhamento: ClassificacaoRiscoEncaminhamentoEnum
  complemento?: Maybe<string>
  observacoes?: Maybe<string>
  especialidadeSisreg: { __typename?: 'EspecialidadeSisreg'; id: string; descricao: string }
  hipoteseDiagnosticoCid10?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
  hipoteseDiagnosticoCiap?: Maybe<{ __typename?: 'Ciap'; id: string; codigo: string; descricao: string }>
  atendimentoProfissional: {
    __typename?: 'AtendimentoProfissional'
    id: string
    iniciadoEm: any
    lotacao: { __typename?: 'Lotacao'; id: string; profissional: { __typename?: 'Profissional'; id: string } }
  }
}

export type MunicipioFragment = {
  __typename?: 'Municipio'
  id: string
  ibge?: Maybe<string>
  nome: string
  uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
}

export type PageInfoFragment = {
  __typename?: 'PageInfo'
  number: number
  size: number
  totalPages: number
  totalElements: number
  sort?: Maybe<Array<Maybe<string>>>
  first: boolean
  last: boolean
  numberOfElements: number
}

export type PoloBaseFragment = {
  __typename?: 'PoloBase'
  id: string
  nome: string
  dsei: { __typename?: 'Dsei'; id: string; nome: string }
}

export type ProcedimentoFragment = {
  __typename?: 'Procedimento'
  id: string
  descricao: string
  codigo: string
  procedimentoReferencia?: Maybe<{ __typename?: 'Procedimento'; id: string; descricao: string; codigo: string }>
}

export type ResultadoExameFragment = {
  __typename?: 'ResultadoExame'
  id: string
  dataResultado?: Maybe<any>
  dataRealizacao?: Maybe<any>
  dataSolicitacao?: Maybe<any>
  conferido?: Maybe<boolean>
  observacao?: Maybe<string>
  resultado?: Maybe<string>
  exame: { __typename?: 'Procedimento' } & ProcedimentoFragment
  solicitacao?: Maybe<{
    __typename?: 'SolicitacaoExame'
    id: string
    data: any
    atendimentoProfissional: {
      __typename?: 'AtendimentoProfissional'
      id: string
      lotacao: {
        __typename?: 'Lotacao'
        id: string
        cbo: { __typename?: 'Cbo'; id: string; nome: string }
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
      }
    }
  }>
  especifico?: Maybe<{
    __typename?: 'ResultadoExameEspecifico'
    id: string
    exameEspecificoEnum: ExameEspecificoEnum
    valor?: Maybe<number>
    igDias?: Maybe<number>
    igSemanas?: Maybe<number>
    dpp?: Maybe<any>
    resultadoExamePuericulturaEnum?: Maybe<FichaComplementarZikaMicrocefaliaResultadoExameEnum>
  }>
  atendimentoProfissional?: Maybe<{
    __typename?: 'AtendimentoProfissional'
    id: string
    lotacao: {
      __typename?: 'Lotacao'
      id: string
      cbo: { __typename?: 'Cbo'; id: string; nome: string }
      unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
      profissional: { __typename?: 'Profissional'; id: string; nome: string }
    }
  }>
}

export type SolicitacaoExameFragment = {
  __typename?: 'SolicitacaoExame'
  id: string
  data: any
  tipoExame: TipoExameEnum
  justificativa: string
  observacoes?: Maybe<string>
  exames: Array<{
    __typename?: 'ResultadoExame'
    id: string
    conferido?: Maybe<boolean>
    observacao?: Maybe<string>
    solicitacao?: Maybe<{ __typename?: 'SolicitacaoExame'; id: string; data: any }>
    exame: { __typename?: 'Procedimento' } & ProcedimentoFragment
  }>
  cid10?: Maybe<
    {
      __typename?: 'Cid10'
      ciapsRelacionadas?: Maybe<Array<Maybe<{ __typename?: 'Ciap'; id: string }>>>
    } & Cid10Fragment
  >
  atendimentoProfissional: {
    __typename?: 'AtendimentoProfissional'
    id: string
    lotacao: {
      __typename?: 'Lotacao'
      id: string
      cbo: { __typename?: 'Cbo'; id: string; nome: string }
      unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
      profissional: { __typename?: 'Profissional'; id: string; nome: string }
    }
  }
}

export type SubstanciaEspecificaAlergiaFragment = {
  __typename?: 'SubstanciaEspecificaAlergia'
  id: string
  coSubstanciaEspecifica: any
  categoria: CategoriaSubstanciaAlergia
  nome: string
}

export type AsyncProcessingStatusQueryVariables = {
  asyncProcessingName: AsyncProcessingNameEnum
}

export type AsyncProcessingStatusQuery = {
  __typename?: 'Query'
  asyncProcessingStatus?: Maybe<{
    __typename?: 'AsyncProcessingStatus'
    isStarted: boolean
    isFinished: boolean
    errorMessage?: Maybe<string>
  }>
}

export type RemoverProcessoAssincronoMutationVariables = {
  asyncProcessingName: AsyncProcessingNameEnum
}

export type RemoverProcessoAssincronoMutation = { __typename?: 'Mutation'; removerProcessoAssincrono: boolean }

export type RemoverTodosProcessosAssincronosMutationVariables = {}

export type RemoverTodosProcessosAssincronosMutation = {
  __typename?: 'Mutation'
  removerTodosProcessosAssincronos: boolean
}

export type ConfiguracoesQueryVariables = {}

export type ConfiguracoesQuery = {
  __typename?: 'Query'
  info: {
    __typename?: 'Info'
    id: string
    smtpConfigurado: boolean
    internetHabilitada: boolean
    linkInstalacaoConfigurado: boolean
    govBREnabled: boolean
    versao: string
    buscaCidadaoPorPropriedadesEnabled: boolean
    oldPecConnected: boolean
    firebaseEnabled: boolean
    ativado: boolean
    treinamento: boolean
    isPrescricaoDigitalEnabled: boolean
    rocksdbEnabled: boolean
    tipoInstalacao?: Maybe<TipoInstalacao>
  }
}

export type DefinirModuloInicialMutationVariables = {
  input: SelecionarModuloInicialInput
}

export type DefinirModuloInicialMutation = {
  __typename?: 'Mutation'
  definirModuloInicial:
    | { __typename?: 'Lotacao'; id: string; moduloInicial?: Maybe<string> }
    | { __typename?: 'Estagio'; id: string; moduloInicial?: Maybe<string> }
    | { __typename?: 'AdministradorGeral'; id: string; moduloInicial?: Maybe<string> }
    | { __typename?: 'AdministradorMunicipal'; id: string; moduloInicial?: Maybe<string> }
    | { __typename?: 'GestorEstadual'; id: string; moduloInicial?: Maybe<string> }
    | { __typename?: 'GestorMunicipal'; id: string; moduloInicial?: Maybe<string> }
}

export type AcompanhamentoCidadaosVinculadosProcessamentoQueryVariables = {}

export type AcompanhamentoCidadaosVinculadosProcessamentoQuery = {
  __typename?: 'Query'
  processamentoStatus?: Maybe<ProcessamentoStatusEnum>
}

export type AcompanhamentoCidadaosVinculadosQueryVariables = {
  input: AcompanhamentoCidadaosVinculadosFilterInput
}

export type AcompanhamentoCidadaosVinculadosQuery = {
  __typename?: 'Query'
  acompanhamentoCidadaosVinculados: {
    __typename?: 'AcompanhamentoCidadaosVinculadosPayload'
    content: Array<{
      __typename?: 'AcompanhamentoCidadaosVinculados'
      id: string
      nomeCidadao: string
      dataNascimentoCidadao?: Maybe<any>
      sexoIdentidadeGeneroCidadao: string
      cpfCidadao?: Maybe<string>
      cnsCidadao?: Maybe<string>
      telefoneCelular?: Maybe<string>
      telefoneContato?: Maybe<string>
      dataUltimaAtualizacaoCidadao?: Maybe<any>
      telefoneResidencial?: Maybe<string>
      idade: string
      endereco: string
      equipe: string
      microarea: string
      origem: string
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type BuscaCidadaoListagemQueryVariables = {
  input: AcompanhamentoCondicaoSaudeQueryInput
}

export type BuscaCidadaoListagemQuery = {
  __typename?: 'Query'
  acompanhamentoCondicaoSaude: {
    __typename?: 'AcompanhamentoCondicaoSaudeQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'AcompanhamentoCondicaoSaudeDto'
        idCidadao: string
        dataNascimentoCidadao?: Maybe<any>
        nomeCidadao?: Maybe<string>
        nomeSocialCidadao?: Maybe<string>
        cpfCidadao?: Maybe<string>
        cnsCidadao?: Maybe<string>
        telefoneResidencial?: Maybe<string>
        telefoneCelular?: Maybe<string>
        telefoneContato?: Maybe<string>
        dataUltimoAtendimento?: Maybe<any>
        endereco?: Maybe<string>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ExamesPreNatalQueryVariables = {}

export type ExamesPreNatalQuery = {
  __typename?: 'Query'
  examesPreNatal: Array<{ __typename?: 'Procedimento'; id: string; descricao: string }>
}

export type SalvarApoioEmapEmadMutationVariables = {
  input: ConfigAtendimentoDomInput
}

export type SalvarApoioEmapEmadMutation = {
  __typename?: 'Mutation'
  salvarConfigAtendimentoDomTipoApoio: { __typename?: 'ConfiguracoesAtendimentosDomiciliares'; id: string }
}

export type SalvarPodeAtenderEmadMutationVariables = {
  input: ConfigAtendimentoDomInput
}

export type SalvarPodeAtenderEmadMutation = {
  __typename?: 'Mutation'
  salvarConfigAtendimentoDomTipoPodeAtender: { __typename?: 'ConfiguracoesAtendimentosDomiciliares'; id: string }
}

export type ConfiguracoesAtencaoDomQueryVariables = {
  input?: Maybe<ConfiguracoesAtendimentosDomInput>
}

export type ConfiguracoesAtencaoDomQuery = {
  __typename?: 'Query'
  configuracoesAtencaoDom: {
    __typename?: 'ConfiguracoesAtencaoDom'
    duracao: number
    emadsAtendemEmads: {
      __typename?: 'ConfiguracoesAtendimentosDomPayload'
      content: Array<
        Maybe<{
          __typename?: 'ConfiguracoesAtendimentosDomiciliares'
          id: string
          identificadorNacionalEquipePai: { __typename?: 'Equipe'; id: string; nome: string; ine: string }
          identificadorNacionalEquipeFilho: { __typename?: 'Equipe'; id: string; nome: string; ine: string }
        }>
      >
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
    }
    emapsApoiamEmads: {
      __typename?: 'ConfiguracoesAtendimentosDomPayload'
      content: Array<
        Maybe<{
          __typename?: 'ConfiguracoesAtendimentosDomiciliares'
          id: string
          identificadorNacionalEquipePai: { __typename?: 'Equipe'; id: string; nome: string; ine: string }
          identificadorNacionalEquipeFilho: { __typename?: 'Equipe'; id: string; nome: string; ine: string }
        }>
      >
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
    }
  }
}

export type EquipesAtencaoDomQueryVariables = {
  input?: Maybe<EquipeAdInput>
}

export type EquipesAtencaoDomQuery = {
  __typename?: 'Query'
  equipesAtencaoDom: {
    __typename?: 'EquipeADPayload'
    content: Array<
      Maybe<{
        __typename?: 'Equipe'
        id: string
        nome: string
        ine: string
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ExcluirConfigAtendimentoDomMutationVariables = {
  idConfig: Scalars['ID']
}

export type ExcluirConfigAtendimentoDomMutation = { __typename?: 'Mutation'; excluirConfigAtendimentoDom: string }

export type SalvarDuracaoAgendamentoAdMutationVariables = {
  input: DuracaoAgendamentoAtencaoDomInput
}

export type SalvarDuracaoAgendamentoAdMutation = {
  __typename?: 'Mutation'
  salvarDuracaoAgendamentoAtencaoDom: { __typename?: 'DuracaoAgendamentoAtencaoDomDto'; duracaoAgendamento: number }
}

export type CidadaoAdLoadQueryVariables = {
  input: Scalars['ID']
}

export type CidadaoAdLoadQuery = {
  __typename?: 'Query'
  cidadaoAD: {
    __typename?: 'AtencaoDomiciliarCidadao'
    id: string
    modalidade?: Maybe<AtencaoDomiciliarModalidade>
    procedencia?: Maybe<AtencaoDomiciliarOrigem>
    procedenciaEspecificacao?: Maybe<string>
    condutaDesfecho?: Maybe<AtencaoDomiciliarCondutaDesfecho>
    dataAdmissao?: Maybe<any>
    dataSaida?: Maybe<any>
    dataObito?: Maybe<any>
    dataAgendado?: Maybe<any>
    dataUltimaVisita?: Maybe<any>
    numeroDocumentoObito?: Maybe<string>
    cidadao?: Maybe<{
      __typename?: 'Cidadao'
      id: string
      nome: string
      dataNascimento?: Maybe<any>
      nomeMae?: Maybe<string>
      nomeSocial?: Maybe<string>
      nomeResponsavel?: Maybe<string>
      dtNascimentoResponsavel?: Maybe<any>
      cpfCnsResponsavel?: Maybe<string>
      naoPossuiCuidador?: Maybe<boolean>
      cpfCnsCuidador?: Maybe<string>
      nomeCuidador?: Maybe<string>
      dtNascimentoCuidador?: Maybe<any>
      tipoCuidador?: Maybe<RelacaoCidadaoCuidador>
      sexo: SexoEnum
      identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
      dataObito?: Maybe<any>
    }>
    cidadaoAdEmObito?: Maybe<{
      __typename?: 'AtencaoDomiciliarCidadao'
      id: string
      cidadao?: Maybe<{
        __typename?: 'Cidadao'
        id: string
        nome: string
        nomeSocial?: Maybe<string>
        dataNascimento?: Maybe<any>
        dataObito?: Maybe<any>
      }>
    }>
    cidadaosAdEmPosObito?: Maybe<
      Array<
        Maybe<{
          __typename?: 'AtencaoDomiciliarCidadao'
          id: string
          cidadao?: Maybe<{
            __typename?: 'Cidadao'
            id: string
            nome: string
            nomeSocial?: Maybe<string>
            cpf?: Maybe<string>
            cns?: Maybe<string>
          }>
        }>
      >
    >
    cid10Principal?: Maybe<{ __typename?: 'Cid10'; id: string; nome: string; codigo: string }>
    cid10Secundario1?: Maybe<{ __typename?: 'Cid10'; id: string; nome: string; codigo: string }>
    cid10Secundario2?: Maybe<{ __typename?: 'Cid10'; id: string; nome: string; codigo: string }>
    actions: {
      __typename?: 'AtencaoDomiciliarCidadaoActions'
      permissaoEditarAD: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
    }
  }
}

export type CidadaoAdSaveMutationVariables = {
  input: AtencaoDomiciliarCidadaoInput
}

export type CidadaoAdSaveMutation = {
  __typename?: 'Mutation'
  salvarCidadaoAtencaoDomiciliar: { __typename?: 'AtencaoDomiciliarCidadao'; id: string }
}

export type EquipeCidadaoAdListaQueryVariables = {
  input: CidadaosAdQueryInput
}

export type EquipeCidadaoAdListaQuery = {
  __typename?: 'Query'
  cidadaosAD: {
    __typename?: 'CidadaosAdQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'AtencaoDomiciliarCidadao'
        id: string
        cidadao?: Maybe<{ __typename?: 'Cidadao'; id: string }>
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
      }>
    >
  }
}

export type CidadaoAdExcluirMutationVariables = {
  id: Scalars['ID']
}

export type CidadaoAdExcluirMutation = { __typename?: 'Mutation'; excluirCidadaoAtendimentoDomiciliar: string }

export type ListaAdTableQueryVariables = {
  input: CidadaosAdQueryInput
}

export type ListaAdTableQuery = {
  __typename?: 'Query'
  cidadaosAD: {
    __typename?: 'CidadaosAdQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'AtencaoDomiciliarCidadao'
        id: string
        modalidade?: Maybe<AtencaoDomiciliarModalidade>
        condutaDesfecho?: Maybe<AtencaoDomiciliarCondutaDesfecho>
        dataAgendado?: Maybe<any>
        dataUltimaVisita?: Maybe<any>
        cidadao?: Maybe<{
          __typename?: 'Cidadao'
          id: string
          nome: string
          cpf?: Maybe<string>
          cns?: Maybe<string>
          nomeSocial?: Maybe<string>
          prontuario?: Maybe<{ __typename?: 'Prontuario'; id: string }>
        }>
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string }>
        actions: {
          __typename?: 'AtencaoDomiciliarCidadaoActions'
          permissaoEditarAD: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
          adEncerrado: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
          permissaoExcluirAD: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CidadaoAdSelectFieldQueryVariables = {
  input: CidadaosAdQueryInput
}

export type CidadaoAdSelectFieldQuery = {
  __typename?: 'Query'
  cidadaosAD: {
    __typename?: 'CidadaosAdQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'AtencaoDomiciliarCidadao'
        id: string
        cidadao?: Maybe<{
          __typename?: 'Cidadao'
          id: string
          nome: string
          cpf?: Maybe<string>
          cns?: Maybe<string>
          nomeSocial?: Maybe<string>
          dataNascimento?: Maybe<any>
          nomeMae?: Maybe<string>
          dataObito?: Maybe<any>
        }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CidadaoListaAdQueryVariables = {
  id: Scalars['ID']
}

export type CidadaoListaAdQuery = {
  __typename?: 'Query'
  cidadao: {
    __typename?: 'Cidadao'
    id: string
    nome: string
    nomeSocial?: Maybe<string>
    cpf?: Maybe<string>
    cns?: Maybe<string>
    nomeMae?: Maybe<string>
    dataNascimento?: Maybe<any>
    sexo: SexoEnum
    identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
  }
}

export type CidadaoListaAdSelectFieldQueryVariables = {
  input: CidadaosComboQueryInput
  emAtencaoDomiciliar: Scalars['Boolean']
}

export type CidadaoListaAdSelectFieldQuery = {
  __typename?: 'Query'
  cidadaosCombo: Array<
    Maybe<{
      __typename?: 'CidadaoBasico'
      id: string
      nome: string
      nomeSocial?: Maybe<string>
      cpf?: Maybe<string>
      cns?: Maybe<string>
      nomeMae?: Maybe<string>
      dataNascimento?: Maybe<any>
      sexo: SexoEnum
      identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
      equipeAtendimentoAd?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
    }>
  >
}

export type AgendamentoFragment = {
  __typename?: 'Agendado'
  id: string
  horarioInicial?: Maybe<any>
  horarioFinal?: Maybe<any>
  dataCriacao?: Maybe<any>
  origem?: Maybe<OrigemAgendamentoEnum>
  observacao?: Maybe<string>
  situacao: SituacaoAgendadoEnum
  situacaoSincronizacao: SituacaoSincronizacaoAgendaOnlineEnum
  isForaUbs: boolean
  isAtencaoDomiciliar: boolean
  tipoAgendamento: TipoAgendamentoEnum
  cidadao?: Maybe<{ __typename?: 'CidadaoBasico'; id: string; nome: string; nomeSocial?: Maybe<string> }>
  localAtendimento?: Maybe<{ __typename?: 'LocalAtendimento'; id: string; localAtendimentoExibicao?: Maybe<string> }>
}

export type AgendamentosDiaLotacaoQueryVariables = {
  input: AgendadosQueryInput
}

export type AgendamentosDiaLotacaoQuery = {
  __typename?: 'Query'
  agendados: {
    __typename?: 'AgendadoQueryPayload'
    content: Array<
      {
        __typename?: 'Agendado'
        tipoAgendamento: TipoAgendamentoEnum
        isAtencaoDomiciliar: boolean
        motivoReserva?: Maybe<MotivoAgendamentoReservaEnum>
        outroMotivoReserva?: Maybe<string>
        prontuarioId?: Maybe<string>
        cidadao?: Maybe<{
          __typename?: 'CidadaoBasico'
          id: string
          nome: string
          nomeSocial?: Maybe<string>
          contato?: Maybe<{
            __typename?: 'ContatoCidadao'
            id: string
            telefoneResidencial?: Maybe<string>
            telefoneCelular?: Maybe<string>
            telefoneContato?: Maybe<string>
          }>
        }>
        atendimento?: Maybe<{
          __typename?: 'Atendimento'
          id: string
          statusAtendimento: StatusAtendimento
          possuiAtendimentosProfissionais: boolean
        }>
        agendamentoPrincipal?: Maybe<{
          __typename?: 'Agendado'
          id: string
          lotacaoAgendada: {
            __typename?: 'Lotacao'
            id: string
            profissional: { __typename?: 'Profissional'; id: string; email?: Maybe<string> }
          }
        }>
        lotacaoParticipante?: Maybe<{
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string; email?: Maybe<string> }
        }>
        videochamada?: Maybe<{ __typename?: 'Videochamada'; id: string; uuid: string }>
      } & AgendamentoFragment
    >
  }
}

export type LotacaoAssociadaAgendamentoFragment = {
  __typename?: 'Lotacao'
  id: string
  ativo: boolean
  profissional: { __typename?: 'Profissional'; id: string; nome: string }
  cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
  equipe?: Maybe<{
    __typename?: 'Equipe'
    id: string
    nome: string
    ine: string
    tipoEquipe: { __typename?: 'TipoEquipe'; id: string; descricao?: Maybe<string> }
  }>
}

export type EstagioAssociadoAgendamentoFragment = {
  __typename?: 'Estagio'
  id: string
  ativo: boolean
  profissional: { __typename?: 'Profissional'; id: string; nome: string }
  cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
  equipe?: Maybe<{
    __typename?: 'Equipe'
    id: string
    nome: string
    ine: string
    tipoEquipe: { __typename?: 'TipoEquipe'; id: string; descricao?: Maybe<string> }
  }>
}

export type ConfiguracaoHorariosAgendaLotacaoQueryVariables = {
  lotacaoId: Scalars['ID']
  isAtencaoDomiciliar: Scalars['Boolean']
  isAgendaAtiva: Scalars['Boolean']
}

export type ConfiguracaoHorariosAgendaLotacaoQuery = {
  __typename?: 'Query'
  lotacao: {
    __typename?: 'Lotacao'
    id: string
    configuracaoAgenda?: Maybe<{
      __typename?: 'ConfiguracaoAgendaLotacao'
      duracaoAgendamento?: Maybe<number>
      configuracaoHorarioAgenda: {
        __typename?: 'ConfiguracaoHorarioAgenda'
        configuracaoDiasSemana?: Maybe<
          Array<{
            __typename?: 'ConfiguracaoDiaSemana'
            diaSemana: DiaSemanaEnum
            periodos?: Maybe<
              Array<{
                __typename?: 'ConfiguracaoPeriodo'
                horarioInicial?: Maybe<string>
                horarioFinal?: Maybe<string>
              }>
            >
          }>
        >
      }
    }>
    gradeConfiguracaoAgendaOnline?: Maybe<{
      __typename?: 'ConfiguracaoAgendaOnlineLotacao'
      diasLiberacaoReserva: any
      configuracoesSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoSemanaAgendaOnline'
          diaSemana: DiaSemanaEnum
          configuracoes: Array<
            Maybe<{ __typename?: 'ConfiguracaoHorarioAgendaOnline'; horario: string; ativo: boolean }>
          >
        }>
      >
    }>
  }
  conexao: { __typename?: 'ConfiguracaoConexao'; agendaOnline?: Maybe<{ __typename?: 'AgendaOnline'; ativa: boolean }> }
}

export type FechamentoAgendaDiaQueryVariables = {
  input: FechamentoAgendaDiaQueryInput
}

export type FechamentoAgendaDiaQuery = {
  __typename?: 'Query'
  fechamentoDia?: Maybe<{
    __typename?: 'FechamentoAgenda'
    id?: Maybe<string>
    dataInicial?: Maybe<any>
    dataFinal?: Maybe<any>
    motivo?: Maybe<string>
    especifique?: Maybe<string>
  }>
}

export type AgendaAuthorizationQueryVariables = {
  equipeId: Scalars['ID']
  isAd: Scalars['Boolean']
}

export type AgendaAuthorizationQuery = {
  __typename?: 'Query'
  equipe: {
    __typename?: 'Equipe'
    id: string
    emapsApoio: Array<{ __typename?: 'Equipe'; id: string }>
    equipesVinculadas: Array<{ __typename?: 'Equipe'; id: string }>
  }
}

export type AgendamentosConflitantesQueryVariables = {
  input: AgendamentoConflitanteInput
}

export type AgendamentosConflitantesQuery = {
  __typename?: 'Query'
  nomesOutrosProfissionaisAgendamentosConflitantes: Array<{
    __typename?: 'NomesProfissionaisAgendamentosConflitantes'
    nomesProfissionais: Array<string>
    horarioAgendamentoConflitante: any
  }>
  possuiAgendamentoMesmoProfissionalDataFutura: Array<{
    __typename?: 'AgendamentoMesmoProfissionalDataFutura'
    profissionalId: string
    dataFutura: any
  }>
}

export type CidadaoCadastroAgendamentoQueryVariables = {
  id: Scalars['ID']
}

export type CidadaoCadastroAgendamentoQuery = {
  __typename?: 'Query'
  cidadao: {
    __typename?: 'Cidadao'
    id: string
    nome: string
    nomeSocial?: Maybe<string>
    cpf?: Maybe<string>
    cns?: Maybe<string>
    nomeMae?: Maybe<string>
    dataNascimento?: Maybe<any>
    sexo: SexoEnum
    identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
    telefoneCelular?: Maybe<string>
    email?: Maybe<string>
  }
}

export type EditarAgendamentoReservaMutationVariables = {
  input: EditarAgendamentoReservaInput
}

export type EditarAgendamentoReservaMutation = { __typename?: 'Mutation'; editarAgendamentoReserva: string }

export type SalvarAgendamentoConsultaCompartilhadaMutationVariables = {
  input: AgendamentoConsultaCompartilhadaInput
}

export type SalvarAgendamentoConsultaCompartilhadaMutation = {
  __typename?: 'Mutation'
  salvarAgendamentoConsultaCompartilhada: string
}

export type SalvarAgendamentoConsultaMutationVariables = {
  input: AgendamentoConsultaInput
}

export type SalvarAgendamentoConsultaMutation = { __typename?: 'Mutation'; salvarAgendamentoConsulta: string }

export type SalvarAgendamentoReservaMutationVariables = {
  input: AgendamentoReservaInput
}

export type SalvarAgendamentoReservaMutation = { __typename?: 'Mutation'; salvarAgendamentoReserva: string }

export type AgendamentosCidadaoPagedQueryVariables = {
  input: AgendadosQueryInput
}

export type AgendamentosCidadaoPagedQuery = {
  __typename?: 'Query'
  agendados: {
    __typename?: 'AgendadoQueryPayload'
    content: Array<
      {
        __typename?: 'Agendado'
        lotacaoAgendada: {
          __typename?: 'Lotacao'
          unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
        } & LotacaoAssociadaAgendamentoFragment
        responsavelCriador?: Maybe<
          | ({ __typename?: 'Lotacao' } & LotacaoAssociadaAgendamentoFragment)
          | ({ __typename?: 'Estagio' } & EstagioAssociadoAgendamentoFragment)
        >
        justificativaCancelamento?: Maybe<{
          __typename?: 'JustificativaCancelamentoAgendado'
          id: string
          justificativa: string
          data: any
          responsavel?: Maybe<
            | ({ __typename?: 'Lotacao' } & LotacaoAssociadaAgendamentoFragment)
            | ({ __typename?: 'Estagio' } & EstagioAssociadoAgendamentoFragment)
          >
        }>
        agendamentoPrincipal?: Maybe<{
          __typename?: 'Agendado'
          id: string
          lotacaoAgendada: {
            __typename?: 'Lotacao'
            id: string
            profissional: { __typename?: 'Profissional'; id: string; nome: string }
            cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
            equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
          }
        }>
        lotacaoParticipante?: Maybe<{
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
          cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
          equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
        }>
      } & AgendamentoFragment
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
  conexao: { __typename?: 'ConfiguracaoConexao'; agendaOnline?: Maybe<{ __typename?: 'AgendaOnline'; ativa: boolean }> }
}

export type LotacaoConfiguracaoAgendaQueryVariables = {
  id: Scalars['ID']
}

export type LotacaoConfiguracaoAgendaQuery = { __typename?: 'Query'; hasConfiguracaoAgenda?: Maybe<boolean> }

export type CancelarAgendamentoMutationVariables = {
  input: CancelarAgendamentoInput
}

export type CancelarAgendamentoMutation = { __typename?: 'Mutation'; cancelarAgendamento: boolean }

export type DataCriacaoAgendadoQueryVariables = {
  id: Scalars['ID']
}

export type DataCriacaoAgendadoQuery = {
  __typename?: 'Query'
  agendado: { __typename?: 'Agendado'; id: string; dataCriacao?: Maybe<any> }
}

export type EditarAgendadoQueryVariables = {
  id: Scalars['ID']
}

export type EditarAgendadoQuery = {
  __typename?: 'Query'
  agendado: {
    __typename?: 'Agendado'
    id: string
    horarioInicial?: Maybe<any>
    horarioFinal?: Maybe<any>
    motivoReserva?: Maybe<MotivoAgendamentoReservaEnum>
    outroMotivoReserva?: Maybe<string>
    observacao?: Maybe<string>
    lotacaoAgendada: { __typename?: 'Lotacao' } & LotacaoAssociadaAgendamentoFragment
  }
}

export type InformarFaltaCidadaoMutationVariables = {
  agendamentoId: Scalars['ID']
}

export type InformarFaltaCidadaoMutation = { __typename?: 'Mutation'; informarFaltaCidadao: boolean }

export type InserirListaAtendimentoMutationVariables = {
  input: AtendimentoInput
}

export type InserirListaAtendimentoMutation = {
  __typename?: 'Mutation'
  salvarAtendimento: { __typename?: 'Atendimento'; id: string }
}

export type RemoverFaltaCidadaoMutationMutationVariables = {
  agendamentoId: Scalars['ID']
}

export type RemoverFaltaCidadaoMutationMutation = { __typename?: 'Mutation'; removerFaltaCidadao: boolean }

export type RemoverListaAtendimentoMutationVariables = {
  atendimentoId: Scalars['ID']
}

export type RemoverListaAtendimentoMutation = { __typename?: 'Mutation'; excluirAtendimento: string }

export type MesAgendaQueryVariables = {
  input: MesAgendaQueryInput
}

export type MesAgendaQuery = {
  __typename?: 'Query'
  mesAgenda: {
    __typename?: 'MesAgendaLotacao'
    id: string
    dias: Array<{ __typename?: 'DiaAgendaLotacao'; data: any; status: StatusDiaAgendaEnum }>
  }
}

export type LotacaoAgendaActionsQueryVariables = {
  id: Scalars['ID']
}

export type LotacaoAgendaActionsQuery = {
  __typename?: 'Query'
  lotacao: {
    __typename?: 'Lotacao'
    id: string
    actions: {
      __typename?: 'LotacaoActions'
      permissaoConfigurarAgenda: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
      permissaoConfigurarAgendaOnline: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
      permissaoFecharAgenda: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
    }
  }
}

export type DetalhesAgendamentoQueryVariables = {
  id: Scalars['ID']
}

export type DetalhesAgendamentoQuery = {
  __typename?: 'Query'
  agendado: {
    __typename?: 'Agendado'
    id: string
    dataCriacao?: Maybe<any>
    agendamentoPrincipal?: Maybe<{
      __typename?: 'Agendado'
      id: string
      cidadao?: Maybe<{
        __typename?: 'CidadaoBasico'
        id: string
        nome: string
        nomeSocial?: Maybe<string>
        cpf?: Maybe<string>
        cns?: Maybe<string>
        dataNascimento?: Maybe<any>
        contato?: Maybe<{
          __typename?: 'ContatoCidadao'
          id: string
          telefoneResidencial?: Maybe<string>
          telefoneCelular?: Maybe<string>
          telefoneContato?: Maybe<string>
        }>
      }>
      lotacaoAgendada: {
        __typename?: 'Lotacao'
        id: string
        cbo: { __typename?: 'Cbo'; id: string; nome: string }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
        profissional: { __typename?: 'Profissional'; id: string; nome: string; email?: Maybe<string> }
      }
    }>
    cidadao?: Maybe<{
      __typename?: 'CidadaoBasico'
      id: string
      nome: string
      nomeSocial?: Maybe<string>
      cpf?: Maybe<string>
      cns?: Maybe<string>
      dataNascimento?: Maybe<any>
      contato?: Maybe<{
        __typename?: 'ContatoCidadao'
        id: string
        telefoneResidencial?: Maybe<string>
        telefoneCelular?: Maybe<string>
        telefoneContato?: Maybe<string>
      }>
    }>
    lotacaoParticipante?: Maybe<{
      __typename?: 'Lotacao'
      id: string
      profissional: { __typename?: 'Profissional'; id: string; nome: string }
      cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
      equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
    }>
    videochamada?: Maybe<{ __typename?: 'Videochamada'; id: string; uuid: string }>
    responsavelCriador?: Maybe<
      | ({ __typename?: 'Lotacao' } & LotacaoAssociadaAgendamentoFragment)
      | ({ __typename?: 'Estagio' } & EstagioAssociadoAgendamentoFragment)
    >
    lotacaoAgendada: { __typename?: 'Lotacao' } & LotacaoAssociadaAgendamentoFragment
  }
}

export type AlterarSenhaMutationVariables = {
  input: AlterarSenhaAtualInput
}

export type AlterarSenhaMutation = {
  __typename?: 'Mutation'
  alterarSenhaAtual?: Maybe<{ __typename?: 'Usuario'; id: string; forcarTrocaSenha: boolean }>
}

export type UltimoArquivoProntoEmProcessamentoQueryVariables = {
  input: ArquivoQueryInput
}

export type UltimoArquivoProntoEmProcessamentoQuery = {
  __typename?: 'Query'
  ultimoArquivoProntoEmProcessamento?: Maybe<{
    __typename?: 'StatusArquivo'
    id: string
    dataInicioGerado: any
    status: ArquivoStatusEnum
    identificador: ArquivoIdentificadorEnum
    formato: ArquivoFormatoEnum
  }>
}

export type UltimosCincoArquivosQueryVariables = {
  input: ArquivoIdentificadorEnum
}

export type UltimosCincoArquivosQuery = {
  __typename?: 'Query'
  ultimosCincoArquivos?: Maybe<
    Array<
      Maybe<{
        __typename?: 'StatusArquivo'
        id: string
        dataInicioGerado: any
        status: ArquivoStatusEnum
        identificador: ArquivoIdentificadorEnum
        formato: ArquivoFormatoEnum
      }>
    >
  >
}

export type AtendimentosFinalizadosQueryVariables = {
  input: AtendimentosFinalizadosInput
}

export type AtendimentosFinalizadosQuery = {
  __typename?: 'Query'
  atendimentosFinalizados?: Maybe<{
    __typename?: 'AtendimentosProfissionalPayload'
    content: Array<
      Maybe<{
        __typename?: 'AtendimentoProfissional'
        id: string
        tipo: TipoAtendimentoProfissional
        iniciadoEm: any
        isAtendRecente: boolean
        codigoUnicoRegistro: string
        lotacao: {
          __typename?: 'Lotacao'
          id: string
          equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        }
        atendimento?: Maybe<{
          __typename?: 'Atendimento'
          id: string
          cidadao: {
            __typename?: 'CidadaoBasico'
            id: string
            nome: string
            nomeSocial?: Maybe<string>
            cpf?: Maybe<string>
            cns?: Maybe<string>
            dataNascimento?: Maybe<any>
          }
        }>
        assinaturaEletronicaAtend?: Maybe<{
          __typename?: 'AssinaturaEletronica'
          id: string
          dtAssinatura: any
          status?: Maybe<StatusAssinatura>
        }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }>
}

export type InternetHabilitadaQueryVariables = {}

export type InternetHabilitadaQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
  }
}

export type AgendadoAtendimentoFormQueryVariables = {
  id: Scalars['ID']
}

export type AgendadoAtendimentoFormQuery = {
  __typename?: 'Query'
  agendado: {
    __typename?: 'Agendado'
    id: string
    lotacaoAgendada: {
      __typename?: 'Lotacao'
      id: string
      cbo: { __typename?: 'Cbo'; id: string; cbo2002: string }
      profissional: { __typename?: 'Profissional'; id: string; nome: string }
      equipe?: Maybe<{
        __typename?: 'Equipe'
        id: string
        nome: string
        ine: string
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
    }
  }
}

export type AgendamentosDiaQueryVariables = {
  input: AgendadosQueryInput
}

export type AgendamentosDiaQuery = {
  __typename?: 'Query'
  agendados: {
    __typename?: 'AgendadoQueryPayload'
    content: Array<{
      __typename?: 'Agendado'
      id: string
      horarioInicial?: Maybe<any>
      lotacaoAgendada: {
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
        cbo: { __typename?: 'Cbo'; id: string; nome: string }
      }
      lotacaoParticipante?: Maybe<{
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
        cbo: { __typename?: 'Cbo'; id: string; nome: string }
      }>
    }>
  }
}

export type AtendimentoIndividualViewQueryVariables = {
  atendimentoId: Scalars['ID']
}

export type AtendimentoIndividualViewQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    iniciadoEm: any
    isRegistroTardio: boolean
    statusRevisaoAtendimento?: Maybe<StatusRevisaoAtendimento>
    agendado?: Maybe<{
      __typename?: 'Agendado'
      id: string
      lotacaoAgendada: { __typename?: 'Lotacao'; id: string }
      lotacaoParticipante?: Maybe<{
        __typename?: 'Lotacao'
        id: string
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
      }>
      agendamentoPrincipal?: Maybe<{
        __typename?: 'Agendado'
        id: string
        lotacaoAgendada: {
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        }
      }>
      videochamada?: Maybe<{ __typename?: 'Videochamada'; id: string }>
    }>
    tiposServico?: Maybe<Array<Maybe<{ __typename?: 'TipoServico'; id: string }>>>
    cidadao: {
      __typename?: 'CidadaoBasico'
      id: string
      cpf?: Maybe<string>
      cns?: Maybe<string>
      nome: string
      nomeSocial?: Maybe<string>
      dataNascimento?: Maybe<any>
      sexo: SexoEnum
      nomeMae?: Maybe<string>
      area?: Maybe<string>
      microArea?: Maybe<string>
      desejaInformarIdentidadeGenero?: Maybe<boolean>
      identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
      stCompartilhaProntuario?: Maybe<boolean>
      ultimaDum?: Maybe<{ __typename?: 'Dum'; dum?: Maybe<any>; registradoEm?: Maybe<any> }>
    }
    atendimentoProfissional?: Maybe<{
      __typename?: 'AtendimentoProfissional'
      id: string
      tipo: TipoAtendimentoProfissional
      tipoAtendimento?: Maybe<TipoAtendimentoEnum>
      iniciadoEm: any
      finalizadoEm?: Maybe<any>
      rascunho?: Maybe<string>
      estagio?: Maybe<{ __typename?: 'Estagio'; id: string }>
      lotacao: {
        __typename?: 'Lotacao'
        id: string
        profissional: {
          __typename?: 'Profissional'
          id: string
          nome: string
          cns?: Maybe<string>
          cpf: string
          numeroConselhoClasse?: Maybe<string>
          conselhoClasse?: Maybe<{
            __typename?: 'ConselhoClasse'
            id: string
            sigla: string
            descricao?: Maybe<string>
          }>
          ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
        }
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
      atendimentoProfissionalObservacao?: Maybe<{
        __typename?: 'AtendimentoProfissionalObservacao'
        id: string
        atendimentoObservacao: {
          __typename?: 'AtendimentoObservacao'
          id: string
          responsaveis: Array<{
            __typename?: 'AtendimentoObservacaoResponsavel'
            id: string
            atendeuComoResponsavel: boolean
            responsavel: { __typename?: 'Lotacao' } & ResponsavelAtendimentoObservacaoFragment
          }>
          ultimoResponsavelObservacao: { __typename?: 'Lotacao' } & ResponsavelAtendimentoObservacaoFragment
        }
      }>
    }>
    prontuario: {
      __typename?: 'Prontuario'
      id: string
      puerpera?: Maybe<boolean>
      gestacoes?: Maybe<Array<{ __typename?: 'PeriodoGestacao'; id: string; inicio: any; fim?: Maybe<any> }>>
      preNatalAtivo?: Maybe<{
        __typename?: 'PreNatal'
        id: string
        gravidezPlanejada?: Maybe<boolean>
        ultimaDum?: Maybe<any>
        altoRisco: boolean
        tipoGravidez?: Maybe<{
          __typename?: 'TipoGravidez'
          id: string
          descricao?: Maybe<string>
          tipoGravidez?: Maybe<TipoGravidezEnum>
        }>
        problema: {
          __typename?: 'Problema'
          id: string
          ultimaEvolucao: { __typename?: 'EvolucaoProblema'; id: string; dataInicio?: Maybe<any> }
        }
      }>
    }
  }
}

export type CiapCidAtendimentoQueryVariables = {
  inputCiap: CiapQueryInput
  inputCid10: Cid10QueryInput
}

export type CiapCidAtendimentoQuery = {
  __typename?: 'Query'
  ciaps: {
    __typename?: 'CiapQueryPayLoad'
    content: Array<
      Maybe<{
        __typename?: 'Ciap'
        id: string
        codigo: string
        descricao: string
        inclui?: Maybe<string>
        exclui?: Maybe<string>
      }>
    >
  }
  cids: {
    __typename?: 'Cid10QueryPayLoad'
    content: Array<Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>>
  }
}

export type ProcedimentosAutomaticosQueryVariables = {}

export type ProcedimentosAutomaticosQuery = {
  __typename?: 'Query'
  procedimentosAutomaticos: Array<
    Maybe<
      {
        __typename?: 'Procedimento'
        idadeMinima?: Maybe<number>
        idadeMaxima?: Maybe<number>
        sexo?: Maybe<SexoEnum>
      } & ProcedimentoFragment
    >
  >
  registroAvaliacaoPuericultura: {
    __typename?: 'RegistroAvaliacaoPuericultura'
    ciap: { __typename?: 'Ciap' } & CiapFragment
    cid10: { __typename?: 'Cid10' } & Cid10Fragment
  }
}

export type SalvarAtendimentoIndividualMutationVariables = {
  input: AtendimentoIndividualInput
}

export type SalvarAtendimentoIndividualMutation = {
  __typename?: 'Mutation'
  salvarAtendimentoIndividual: { __typename?: 'AtendimentoProfissional'; id: string }
}

export type SalvarAtendimentoIndividualParaAprovacaoMutationVariables = {
  input: AtendimentoIndividualAprovacaoInput
}

export type SalvarAtendimentoIndividualParaAprovacaoMutation = {
  __typename?: 'Mutation'
  salvarAtendimentoIndividualParaAprovacao: boolean
}

export type AtendimentoObservacaoViewQueryVariables = {
  atendimentoId: Scalars['ID']
}

export type AtendimentoObservacaoViewQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    atendimentoProfissional?: Maybe<{
      __typename?: 'AtendimentoProfissional'
      id: string
      encaminhamentos?: Maybe<Array<TipoEncaminhamentoExternoEnum>>
      tipoAtendimento?: Maybe<TipoAtendimentoEnum>
      condutas?: Maybe<Array<Maybe<CondutaEnum>>>
      racionalidadeSaude?: Maybe<RacionalidadeEmSaudeEnum>
      atestados?: Maybe<
        Array<{
          __typename?: 'Atestado'
          id: string
          descricao?: Maybe<string>
          tipo?: Maybe<TipoAtestadoEnum>
          assinadoDigitalmente: boolean
        }>
      >
      encaminhamentosEspecializados?: Maybe<
        Array<Maybe<{ __typename?: 'EncaminhamentoExternoEspecializado' } & EncaminhamentoExternoEspecializadoFragment>>
      >
      orientacoes?: Maybe<Array<Maybe<{ __typename?: 'Orientacao'; id: string; descricao: string }>>>
      requisicoesExames?: Maybe<Array<Maybe<{ __typename?: 'SolicitacaoExame' } & SolicitacaoExameFragment>>>
      examesResultados?: Maybe<Array<Maybe<{ __typename?: 'ResultadoExame' } & ResultadoExameFragment>>>
      receitaMedicamento?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ReceitaMedicamento'
            id: string
            quantidadeReceitada: number
            recomendacoes?: Maybe<string>
            qtDose?: Maybe<string>
            frequenciaDose?: Maybe<string>
            posologia: string
            tipoFrequenciaDose?: Maybe<TipoFrequencia>
            qtPeriodoFrequencia?: Maybe<number>
            medidaTempoFrequencia?: Maybe<UnidadeMedidaTempoEnum>
            qtDuracaoTratamento?: Maybe<number>
            medidaTempoTratamento?: Maybe<UnidadeMedidaTempoEnum>
            usoContinuo?: Maybe<boolean>
            registroManual?: Maybe<boolean>
            doseUnica?: Maybe<boolean>
            tipoReceita?: Maybe<TipoReceitaEnum>
            tratamentoInterrompido?: Maybe<boolean>
            justificativaInterrupcao?: Maybe<string>
            dtInterrupcao?: Maybe<any>
            unidadeMedidaDose?: Maybe<{ __typename?: 'UnidadeMedida'; id: string; nome: string; nomePlural: string }>
            aplicacaoMedicamento: { __typename?: 'AplicacaoMedicamento'; id: string; nome: string }
            medicamento: {
              __typename?: 'Medicamento'
              id: string
              concentracao?: Maybe<string>
              principioAtivo: string
              principioAtivoFiltro: string
              unidadeFornecimento: string
              formaFarmaceutica?: Maybe<{ __typename?: 'FormaFarmaceutica'; id: string; nome: string; ativo: boolean }>
              medicamentoCatmat?: Maybe<{
                __typename?: 'MedicamentoCatmat'
                id: string
                ativo: boolean
                volume?: Maybe<string>
                codigoCatMat?: Maybe<string>
                codigoRename?: Maybe<string>
                unidadeFornecimento: { __typename?: 'UnidadeMedida'; id: string; nome: string; nomePlural: string }
                principioAtivo: {
                  __typename?: 'PrincipioAtivo'
                  id: string
                  nome: string
                  listaMaterial: {
                    __typename?: 'ListaMedicamento'
                    id: string
                    tipo: string
                    tipoReceita: TipoReceitaEnum
                  }
                }
              }>
            }
          }>
        >
      >
      evolucaoProblema?: Maybe<
        Array<
          Maybe<{
            __typename?: 'EvolucaoProblema'
            id: string
            situacao: SituacaoProblema
            observacao?: Maybe<string>
            possuiCid: boolean
            possuiCiap: boolean
            dataInicio?: Maybe<any>
            dataFim?: Maybe<any>
            atualizadoEm?: Maybe<any>
            problema?: Maybe<{ __typename?: 'Problema' } & ProblemaCondicaoFragment>
          }>
        >
      >
      evolucaoSubjetivo?: Maybe<{
        __typename?: 'EvolucaoSubjetivo'
        id: string
        descricao?: Maybe<string>
        ciaps?: Maybe<
          Array<
            Maybe<{
              __typename?: 'EvolucaoSubjetivoCiap'
              id: string
              nota?: Maybe<string>
              ciap: { __typename?: 'Ciap' } & CiapFragment
            }>
          >
        >
      }>
      evolucaoAvaliacao?: Maybe<{
        __typename?: 'EvolucaoAvaliacao'
        id: string
        descricao?: Maybe<string>
        ciapsCids?: Maybe<
          Array<
            Maybe<{
              __typename?: 'EvolucaoAvaliacaoCiapCid'
              id: string
              nota?: Maybe<string>
              isCiapCidNaListaDeProblemas: boolean
              ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
              cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
            }>
          >
        >
      }>
      evolucaoObjetivo?: Maybe<{ __typename?: 'EvolucaoObjetivo'; id: string; descricao?: Maybe<string> }>
      evolucaoPlano?: Maybe<{
        __typename?: 'EvolucaoPlano'
        id: string
        descricao?: Maybe<string>
        intervencoesProcedimentos?: Maybe<
          Array<
            Maybe<{
              __typename?: 'EvolucaoPlanoCiap'
              id: string
              nota?: Maybe<string>
              ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
              procedimento?: Maybe<{ __typename?: 'Procedimento' } & ProcedimentoFragment>
            }>
          >
        >
      }>
      notificacoesCasoSuspeito?: Maybe<
        Array<
          Maybe<{
            __typename?: 'SinanNotificacaoEvolucao'
            id: string
            notificacao: { __typename?: 'FichaNotificacaoCasoSuspeito'; id: string; nomeNotificacao: string }
          }>
        >
      >
      lotacaoAtendimentoCompartilhado?: Maybe<{
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string; isAD: boolean }>
      }>
      procedimentosAtendimento?: Maybe<
        Array<
          Maybe<{
            __typename?: 'AtendimentoProcedimento'
            id: string
            automatico?: Maybe<boolean>
            procedimento: { __typename?: 'Procedimento'; id: string; descricao: string; codigo: string }
          }>
        >
      >
      marcadorConsumoAlimentar?: Maybe<{
        __typename?: 'MarcadorConsumoAlimentar'
        id: string
        categoria: FaixaEtariaConsumoAlimentar
        respostas: Array<{
          __typename?: 'RespostaMarcadoresConsumoAlimentar'
          pergunta: PerguntasQuestionarioEnum
          opcoesSelecionadas: Array<OpcaoTipoPerguntaEnum>
        }>
      }>
    }>
  }
}

export type ResponsavelAtendimentoObservacaoFragment = {
  __typename?: 'Lotacao'
  id: string
  profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
}

export type SalvarAtendimentoVacinacaoParaAprovacaoMutationVariables = {
  input: AtendimentoVacinacaoAprovacaoInput
}

export type SalvarAtendimentoVacinacaoParaAprovacaoMutation = {
  __typename?: 'Mutation'
  salvarAtendimentoVacinacaoParaAprovacao: boolean
}

export type AtendimentoVacinacaoViewQueryVariables = {
  atendimentoId: Scalars['ID']
}

export type AtendimentoVacinacaoViewQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    iniciadoEm: any
    statusAtendimento: StatusAtendimento
    statusRevisaoAtendimento?: Maybe<StatusRevisaoAtendimento>
    isRegistroTardio: boolean
    atendimentoProfissional?: Maybe<{
      __typename?: 'AtendimentoProfissional'
      id: string
      tipo: TipoAtendimentoProfissional
      iniciadoEm: any
      rascunho?: Maybe<string>
      estagio?: Maybe<{ __typename?: 'Estagio'; id: string }>
      lotacao: {
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; cpf: string; cns?: Maybe<string>; nome: string }
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
    }>
    agendado?: Maybe<{ __typename?: 'Agendado'; id: string; lotacaoAgendada: { __typename?: 'Lotacao'; id: string } }>
    cidadao: {
      __typename?: 'CidadaoBasico'
      id: string
      cpf?: Maybe<string>
      cns?: Maybe<string>
      nome: string
      nomeSocial?: Maybe<string>
      dataNascimento?: Maybe<any>
      sexo: SexoEnum
      nomeMae?: Maybe<string>
      area?: Maybe<string>
      microArea?: Maybe<string>
      identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
      stCompartilhaProntuario?: Maybe<boolean>
    }
    tiposServico?: Maybe<Array<Maybe<{ __typename?: 'TipoServico'; id: string }>>>
    prontuario: {
      __typename?: 'Prontuario'
      id: string
      puerpera?: Maybe<boolean>
      gestacoes?: Maybe<Array<{ __typename?: 'PeriodoGestacao'; id: string; inicio: any; fim?: Maybe<any> }>>
      preNatalAtivo?: Maybe<{ __typename?: 'PreNatal'; id: string; ultimaDum?: Maybe<any> }>
    }
  }
}

export type EsquemaCalendarioVacinalQueryVariables = {
  input: EsquemaCalendarioVacinalQueryInput
}

export type EsquemaCalendarioVacinalQuery = {
  __typename?: 'Query'
  esquemaCalendarioVacinal: Array<{
    __typename?: 'BaseCalendarioVacinal'
    id: string
    descricaoImunobiologico: string
    idadeRecomendada?: Maybe<string>
    mostrarNoCalendarioDoGrupo: boolean
    ordemImuno: number
    imunobiologico: { __typename?: 'Imunobiologico'; id: string; sigla: string; nome: string }
    dose: {
      __typename?: 'DoseImunobiologico'
      id: string
      nomeApresentacaoDose: string
      sigla: string
      nome: string
      ordem: number
    }
    faixaEtaria: {
      __typename?: 'FaixaEtariaVacinacao'
      id: string
      diasInicio: number
      diasFim: number
      descricao: string
    }
  }>
}

export type FaixaEtariaVacinacaoQueryVariables = {
  input: FaixaEtariaVacinacaoQueryInput
}

export type FaixaEtariaVacinacaoQuery = {
  __typename?: 'Query'
  faixaEtariaVacinacao?: Maybe<
    Array<
      Maybe<{ __typename?: 'FaixaEtariaVacinacao'; id: string; diasInicio: number; diasFim: number; descricao: string }>
    >
  >
}

export type HasVacinaAtrasadaQueryVariables = {
  input: VacinacaoUltimaVacinaInput
}

export type HasVacinaAtrasadaQuery = { __typename?: 'Query'; hasVacinaAtrasada: boolean }

export type ProximaDoseAprazamentoQueryVariables = {
  input: ProximaDoseAprazamentoQueryInput
}

export type ProximaDoseAprazamentoQuery = {
  __typename?: 'Query'
  proximaDoseAprazamentoAutomatico?: Maybe<{
    __typename?: 'ProximaDoseAprazamento'
    diasAprazamento?: Maybe<number>
    proximaDoseId?: Maybe<string>
    proximaDoseSigla?: Maybe<string>
    proximaDoseNome?: Maybe<string>
  }>
}

export type RegistrosAplicadosVacinacaoQueryVariables = {
  input: RegistrosAplicadosVacinacaoInput
}

export type RegistrosAplicadosVacinacaoQuery = {
  __typename?: 'Query'
  registrosAplicadosVacinacao?: Maybe<
    Array<{
      __typename?: 'RegistroAplicado'
      imunobiologicoId: string
      imunobiologicoSigla: string
      imunobiologicoNome: string
      doseId: string
      doseNomeApresentacao: string
      doseNome: string
      doseSigla: string
      dataAprazamento?: Maybe<any>
      dataAplicacao?: Maybe<any>
      quantidadeAplicacoes: number
      dataRegistroAprazamento?: Maybe<any>
    }>
  >
}

export type SalvarAtendimentoVacinacaoMutationVariables = {
  input: AtendimentoVacinacaoInput
}

export type SalvarAtendimentoVacinacaoMutation = {
  __typename?: 'Mutation'
  salvarAtendimentoVacinacao?: Maybe<{
    __typename?: 'AtendimentoVacinacao'
    id: string
    atendimentoProfissional: { __typename?: 'AtendimentoProfissional'; id: string }
  }>
}

export type UltimaVacinaAprazadaQueryVariables = {
  input: VacinacaoUltimaVacinaInput
}

export type UltimaVacinaAprazadaQuery = {
  __typename?: 'Query'
  ultimaVacinaAprazada?: Maybe<{
    __typename?: 'UltimaVacina'
    imunobiologicoNome?: Maybe<string>
    dataAprazamento?: Maybe<any>
    doseNomeApresentacao?: Maybe<string>
  }>
}

export type UltimasTresVacinasAplicadasQueryVariables = {
  input: VacinacaoUltimaVacinaInput
}

export type UltimasTresVacinasAplicadasQuery = {
  __typename?: 'Query'
  ultimasTresVacinasAplicadas?: Maybe<
    Array<
      Maybe<{
        __typename?: 'UltimaVacina'
        imunobiologicoNome?: Maybe<string>
        dataAplicacao?: Maybe<any>
        doseNomeApresentacao?: Maybe<string>
      }>
    >
  >
}

export type AcessoConectesusRndsMutationVariables = {
  input: AcessoConecteSusInput
}

export type AcessoConectesusRndsMutation = { __typename?: 'Mutation'; acessoConecteSus: string }

export type AtendimentoViewQueryVariables = {
  id: Scalars['ID']
}

export type AtendimentoViewQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    statusAtendimento: StatusAtendimento
    isRegistroTardio: boolean
    atendimentoProfissional?: Maybe<{
      __typename?: 'AtendimentoProfissional'
      id: string
      tipo: TipoAtendimentoProfissional
      lotacao: { __typename?: 'Lotacao'; id: string }
      estagio?: Maybe<{ __typename?: 'Estagio'; id: string }>
    }>
  }
}

export type CancelarAtendimentoMutationVariables = {
  atendimentoId: Scalars['ID']
}

export type CancelarAtendimentoMutation = {
  __typename?: 'Mutation'
  cancelarAtendimento: { __typename?: 'Atendimento'; id: string }
}

export type RascunhoAtendimentoQueryVariables = {
  id: Scalars['ID']
}

export type RascunhoAtendimentoQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    atendimentoProfissional?: Maybe<{ __typename?: 'AtendimentoProfissional'; id: string; rascunho?: Maybe<string> }>
  }
}

export type RegistrosDoseVacinacaoQueryVariables = {
  input: RegistrosDoseVacinacaoInput
}

export type RegistrosDoseVacinacaoQuery = {
  __typename?: 'Query'
  registrosDoseVacinacao?: Maybe<
    Array<
      Maybe<{
        __typename?: 'RegistroDose'
        lote?: Maybe<string>
        viaAdministracao?: Maybe<string>
        localAplicacao?: Maybe<string>
        estrategia?: Maybe<string>
        dataAplicacao?: Maybe<any>
        dataAprazamento?: Maybe<any>
        dataRegistro?: Maybe<any>
        dataValidadeLote?: Maybe<any>
        profissional?: Maybe<string>
        grupoAtendimento?: Maybe<string>
        descricao?: Maybe<string>
        fabricante?: Maybe<string>
        cbo?: Maybe<string>
        isGestante?: Maybe<boolean>
        isPuerpera?: Maybe<boolean>
        isViajante?: Maybe<boolean>
        isComunicanteHanseniase?: Maybe<boolean>
        unidadeSaude?: Maybe<string>
        origemDados?: Maybe<OrigemAtendimento>
        isRegistroAnterior?: Maybe<boolean>
        isAplicadoExterior?: Maybe<boolean>
      }>
    >
  >
}

export type AlergiasModalQueryVariables = {
  filtro: AlergiasQueryInput
}

export type AlergiasModalQuery = {
  __typename?: 'Query'
  alergias: {
    __typename?: 'AlergiaQueryPayload'
    content: Array<
      {
        __typename?: 'Alergia'
        substanciaEspecifica?: Maybe<
          { __typename?: 'SubstanciaEspecificaAlergia'; sigla?: Maybe<string> } & SubstanciaEspecificaAlergiaFragment
        >
        ultimaEvolucao: {
          __typename?: 'EvolucaoAlergia'
          id: string
          dataInicio?: Maybe<any>
          manifestacoesLegado?: Maybe<string>
          possuiSubstanciaEspecificaCbara?: Maybe<boolean>
          atualizadoEm: any
          manifestacoes: Array<{
            __typename?: 'ManifestacaoAlergia'
            id: string
            nome: string
            sinonimo?: Maybe<string>
          }>
        }
        evolucoes: Array<{
          __typename?: 'EvolucaoAlergia'
          id: string
          tipoReacao?: Maybe<TipoReacaoAlergia>
          criticidade: CriticidadeAlergia
          grauCerteza: GrauCertezaAlergia
          dataInicio?: Maybe<any>
          manifestacoesLegado?: Maybe<string>
          atualizadoEm: any
          observacao?: Maybe<string>
          possuiSubstanciaEspecificaCbara?: Maybe<boolean>
          manifestacoes: Array<{ __typename?: 'ManifestacaoAlergia'; id: string; nome: string }>
          atendimentoProfissional: {
            __typename?: 'AtendimentoProfissional'
            id: string
            lotacao: {
              __typename?: 'Lotacao'
              id: string
              cbo: { __typename?: 'Cbo'; id: string; nome: string }
              profissional: { __typename?: 'Profissional'; id: string; nome: string }
            }
          }
        }>
      } & AlergiaFragment
    >
  }
}

export type LembretesModalQueryVariables = {
  lembretesQueryInput: LembretesQueryInput
}

export type LembretesModalQuery = {
  __typename?: 'Query'
  lembretes: Array<
    Maybe<{
      __typename?: 'Lembrete'
      id: string
      historicoLembrete: Array<
        Maybe<{
          __typename?: 'LembreteEvolucao'
          id: string
          visibilidadeLembrete: VisibilidadeLembreteEnum
          ativo: boolean
          descricao: string
          data: any
          lotacao: {
            __typename?: 'Lotacao'
            id: string
            profissional: { __typename?: 'Profissional'; id: string; nome: string }
            cbo: { __typename?: 'Cbo'; id: string; nome: string }
          }
        }>
      >
      profissionalCriador: { __typename?: 'Profissional'; id: string }
    }>
  >
}

export type ExisteProblemaResolvidoQueryVariables = {
  prontuarioId: Scalars['ID']
}

export type ExisteProblemaResolvidoQuery = { __typename?: 'Query'; existeProblemaResolvido: boolean }

export type ProblemasModalQueryVariables = {
  filtro: ProblemaQueryInput
}

export type ProblemasModalQuery = {
  __typename?: 'Query'
  problemas: {
    __typename?: 'ProblemaQueryPayload'
    content: Array<{
      __typename?: 'Problema'
      id: string
      situacao: SituacaoProblema
      descricaoOutro?: Maybe<string>
      ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
      cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
      ultimaEvolucao: {
        __typename?: 'EvolucaoProblema'
        id: string
        dataInicio?: Maybe<any>
        dataFim?: Maybe<any>
        atualizadoEm?: Maybe<any>
        situacao: SituacaoProblema
        observacao?: Maybe<string>
      }
      evolucaoAvaliacaoCiapCid?: Maybe<{ __typename?: 'EvolucaoAvaliacaoCiapCid'; id: string }>
      evolucoes: Array<{
        __typename?: 'EvolucaoProblema'
        id: string
        dataInicio?: Maybe<any>
        dataFim?: Maybe<any>
        situacao: SituacaoProblema
        observacao?: Maybe<string>
        atualizadoEm?: Maybe<any>
        possuiCid: boolean
        possuiCiap: boolean
        atendimentoProfissional: {
          __typename?: 'AtendimentoProfissional'
          id: string
          lotacao: {
            __typename?: 'Lotacao'
            id: string
            cbo: { __typename?: 'Cbo'; id: string; nome: string }
            profissional: { __typename?: 'Profissional'; id: string; nome: string }
          }
        }
      }>
    }>
  }
}

export type ProblemasModalSimplificadoQueryVariables = {
  filtro: ProblemaQueryInput
}

export type ProblemasModalSimplificadoQuery = {
  __typename?: 'Query'
  problemas: {
    __typename?: 'ProblemaQueryPayload'
    content: Array<{ __typename?: 'Problema' } & ProblemaCondicaoFragment>
  }
}

export type MedicamentoUsoModalQueryVariables = {
  input: ListaMedicamentoAtivoConcluidoQueryInput
}

export type MedicamentoUsoModalQuery = {
  __typename?: 'Query'
  listaMedicamento: {
    __typename?: 'ListaMedicamentoAtivoConcluidoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'ReceitaMedicamento'
        id: string
        usoContinuo?: Maybe<boolean>
        posologia: string
        dtInicioTratamento?: Maybe<any>
        dtFimTratamento?: Maybe<any>
        medidaTempoTratamento?: Maybe<UnidadeMedidaTempoEnum>
        tipoReceita?: Maybe<TipoReceitaEnum>
        recomendacoes?: Maybe<string>
        tratamentoInterrompido?: Maybe<boolean>
        justificativaInterrupcao?: Maybe<string>
        dtInterrupcao?: Maybe<any>
        medicamento: { __typename?: 'Medicamento'; id: string; principioAtivo: string; concentracao?: Maybe<string> }
        atendProf?: Maybe<{
          __typename?: 'AtendimentoProfissional'
          id: string
          versaoAnterior?: Maybe<boolean>
          lotacao: { __typename?: 'Lotacao'; id: string }
        }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type AcompanhamentoPreNatalQueryVariables = {
  input: Scalars['ID']
}

export type AcompanhamentoPreNatalQuery = {
  __typename?: 'Query'
  atendimentosProfUltimaGestacao?: Maybe<
    Array<
      Maybe<{
        __typename?: 'AtendimentoProfissional'
        id: string
        iniciadoEm: any
        atendimentoProfissionalPreNatal?: Maybe<{
          __typename?: 'AtendimentoProfissionalPreNatal'
          id: string
          movimentacaoFetal?: Maybe<boolean>
          tipoEdema?: Maybe<TipoEdemaEnum>
        }>
        medicoes?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Medicao'
              id: string
              dataMedicao: any
              valorBatimentoCardiacoFetal?: Maybe<string>
              dum?: Maybe<any>
              valorAlturaUterina?: Maybe<string>
              medicaoAnterior?: Maybe<boolean>
            }>
          >
        >
        examesResultados?: Maybe<Array<Maybe<{ __typename?: 'ResultadoExame' } & ExamePreNatalFragment>>>
      }>
    >
  >
}

export type ExamePreNatalFragment = {
  __typename?: 'ResultadoExame'
  id: string
  dataRealizacao?: Maybe<any>
  especifico?: Maybe<{
    __typename?: 'ResultadoExameEspecifico'
    id: string
    igSemanas?: Maybe<number>
    igDias?: Maybe<number>
    dpp?: Maybe<any>
  }>
}

export type MedicoesAtendimentosAnterioresQueryVariables = {
  prontuarioId: Scalars['ID']
}

export type MedicoesAtendimentosAnterioresQuery = {
  __typename?: 'Query'
  medicoes: Array<{
    __typename?: 'Medicao'
    id: string
    dataMedicao: any
    dum?: Maybe<any>
    valorPeso?: Maybe<number>
    valorAltura?: Maybe<number>
    valorFrequenciaCardiaca?: Maybe<number>
    valorPressaoArterial?: Maybe<string>
    valorPerimetroCefalico?: Maybe<number>
    valorFrequenciaRespiratoria?: Maybe<number>
    valorTemperatura?: Maybe<number>
    valorSaturacaoO2?: Maybe<number>
    valorGlicemia?: Maybe<number>
    tipoGlicemia?: Maybe<TipoGlicemia>
    valorImc?: Maybe<number>
    perimetroPanturrilha?: Maybe<number>
    valorCircunferenciaAbdominal?: Maybe<number>
    medicaoAnterior?: Maybe<boolean>
    valorAlturaUterina?: Maybe<string>
    atendimentoProfissional: {
      __typename?: 'AtendimentoProfissional'
      id: string
      iniciadoEm: any
      lotacao: {
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
      }
    }
  }>
  medicoesNoNascimento?: Maybe<{
    __typename?: 'Antecedente'
    id: string
    peso?: Maybe<string>
    altura?: Maybe<string>
    perimetroCefalico?: Maybe<string>
    dataUltimaAtualizacao?: Maybe<any>
  }>
}

export type HistoricoResultadosExamesQueryVariables = {
  input: ResultadosExameQueryInput
}

export type HistoricoResultadosExamesQuery = {
  __typename?: 'Query'
  resultadosExame: {
    __typename?: 'ResultadosExamePayload'
    content: Array<
      Maybe<{
        __typename?: 'ResultadoExame'
        id: string
        resultado?: Maybe<string>
        dataRealizacao?: Maybe<any>
        dataResultado?: Maybe<any>
        dataSolicitacao?: Maybe<any>
        conferido?: Maybe<boolean>
        exame: { __typename?: 'Procedimento' } & ProcedimentoFragment
        solicitacao?: Maybe<{
          __typename?: 'SolicitacaoExame'
          id: string
          data: any
          atendimentoProfissional: {
            __typename?: 'AtendimentoProfissional'
            id: string
            lotacao: {
              __typename?: 'Lotacao'
              id: string
              cbo: { __typename?: 'Cbo'; id: string; nome: string }
              profissional: { __typename?: 'Profissional'; id: string; nome: string }
            }
          }
        }>
        especifico?: Maybe<{
          __typename?: 'ResultadoExameEspecifico'
          id: string
          exameEspecificoEnum: ExameEspecificoEnum
          valor?: Maybe<number>
          igDias?: Maybe<number>
          igSemanas?: Maybe<number>
          dpp?: Maybe<any>
          resultadoExamePuericulturaEnum?: Maybe<FichaComplementarZikaMicrocefaliaResultadoExameEnum>
        }>
        atendimentoProfissional?: Maybe<{
          __typename?: 'AtendimentoProfissional'
          id: string
          iniciadoEm: any
          lotacao: {
            __typename?: 'Lotacao'
            id: string
            cbo: { __typename?: 'Cbo'; id: string; nome: string }
            profissional: { __typename?: 'Profissional'; id: string; nome: string }
          }
        }>
      }>
    >
  }
}

export type DadosEscutaPreAtendimentoQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type DadosEscutaPreAtendimentoQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<{
    __typename?: 'AtendimentoProfissional'
    id: string
    finalizadoEm?: Maybe<any>
    classificacaoRisco?: Maybe<ClassificacaoRisco>
    evolucaoSubjetivo?: Maybe<{
      __typename?: 'EvolucaoSubjetivo'
      id: string
      descricao?: Maybe<string>
      ciaps?: Maybe<
        Array<
          Maybe<{
            __typename?: 'EvolucaoSubjetivoCiap'
            id: string
            nota?: Maybe<string>
            ciap: { __typename?: 'Ciap' } & CiapFragment
          }>
        >
      >
    }>
    procedimentosAtendimento?: Maybe<
      Array<
        Maybe<{
          __typename?: 'AtendimentoProcedimento'
          id: string
          procedimento: {
            __typename?: 'Procedimento'
            id: string
            descricao: string
            codigo: string
            tipoProcedimento?: Maybe<TipoProcedimentoEnum>
          }
        }>
      >
    >
    lotacao: {
      __typename?: 'Lotacao'
      id: string
      cbo: { __typename?: 'Cbo'; id: string; nome: string }
      profissional: { __typename?: 'Profissional'; id: string; nome: string }
      equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string }>
    }
  }>
}

export type EscutaPreAtendimentoMaisRecenteQueryVariables = {
  input: EscutaOrPreAtendimentoOfAtendimentoInput
}

export type EscutaPreAtendimentoMaisRecenteQuery = {
  __typename?: 'Query'
  escutaOrPreAtendimentoOfAtendimento?: Maybe<string>
}

export type CuidadoCompartilhadoDwFragment = {
  __typename?: 'Historico'
  ciapNome?: Maybe<string>
  ciapCodigo?: Maybe<string>
  cidNome?: Maybe<string>
  cidCodigo?: Maybe<string>
  classificacaoPrioridade?: Maybe<string>
  reclassificacaoPrioridade?: Maybe<string>
  conduta?: Maybe<string>
  cnsSolicitante?: Maybe<string>
  nomeSolicitante?: Maybe<string>
  cboSolicitante?: Maybe<string>
  ineSolicitante?: Maybe<string>
  siglaEquipeSolicitante?: Maybe<string>
  nomeUbsSolicitante?: Maybe<string>
  cnsExecutante?: Maybe<string>
  nomeExecutante?: Maybe<string>
  cboExecutante?: Maybe<string>
  ineExecutante?: Maybe<string>
  siglaEquipeExecutante?: Maybe<string>
  nomeUbsExecutante?: Maybe<string>
}

export type DadosUltimosContatosQueryVariables = {
  input: HistoricoQueryInput
}

export type DadosUltimosContatosQuery = {
  __typename?: 'Query'
  historico: {
    __typename?: 'HistoricoPayload'
    content: Array<
      Maybe<
        {
          __typename?: 'Historico'
          codigoUnicoRegistro?: Maybe<string>
          origemAtendimento?: Maybe<OrigemAtendimento>
          dataAtendimento?: Maybe<any>
          turno?: Maybe<TurnoEnum>
          tipoAtendProf?: Maybe<TipoAtendimentoProfissional>
          tipoApresentacao?: Maybe<TipoRegistroHistoricoClinico>
          isCancelado?: Maybe<boolean>
          coSubtipoAtendimento?: Maybe<number>
          classificacaoRisco?: Maybe<ClassificacaoRisco>
          dadosClinicos?: Maybe<string>
          condicoesAvaliadas: Array<string>
          examesRealizadosZika?: Maybe<Array<Maybe<string>>>
          tipoConsultaOdonto?: Maybe<TipoConsultaOdontoEnum>
          isAtendRecente: boolean
          idAtendRecente?: Maybe<string>
          cpfCnsCidadao?: Maybe<string>
          hasObservacao?: Maybe<boolean>
          hasCuidadoCompartilhado: boolean
          idadeGestacional?: Maybe<{ __typename?: 'IdadeGestacional'; dias: number; semanas: number }>
          cuidadoCompartilhado?: Maybe<
            { __typename?: 'CuidadoCompartilhado' } & HistoricoCuidadoCompartilhadoHeaderFragment
          >
          cuidadoCompartilhadoEvolucao?: Maybe<
            { __typename?: 'CuidadoCompartilhadoEvolucao' } & HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment
          >
        } & CuidadoCompartilhadoDwFragment
      >
    >
  }
}

export type VideochamadaAtendimentoQueryVariables = {
  atendimentoId: Scalars['ID']
}

export type VideochamadaAtendimentoQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    agendado?: Maybe<{
      __typename?: 'Agendado'
      id: string
      lotacaoAgendada: { __typename?: 'Lotacao'; id: string }
      lotacaoParticipante?: Maybe<{
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; email?: Maybe<string> }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
        cbo: { __typename?: 'Cbo'; id: string; nome: string }
      }>
      videochamada?: Maybe<{ __typename?: 'Videochamada'; id: string; uuid: string }>
    }>
  }
}

export type AlertasSdcQueryVariables = {
  input: AlertasSdcInput
}

export type AlertasSdcQuery = {
  __typename?: 'Query'
  alertasSdc: Array<{
    __typename?: 'AlertaSdc'
    id: string
    titulo: string
    subtitulo?: Maybe<string>
    descricao?: Maybe<string>
  }>
}

export type AtendimentoHeaderCidadaoQueryVariables = {
  cidadaoId: Scalars['ID']
}

export type AtendimentoHeaderCidadaoQuery = {
  __typename?: 'Query'
  cidadao: {
    __typename?: 'Cidadao'
    id: string
    cpf?: Maybe<string>
    cns?: Maybe<string>
    nome: string
    nomeSocial?: Maybe<string>
    nomeTradicional?: Maybe<string>
    dataNascimento?: Maybe<any>
    sexo: SexoEnum
    nomeMae?: Maybe<string>
    identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
    ativo?: Maybe<boolean>
    faleceu?: Maybe<boolean>
    unificado?: Maybe<boolean>
    unificacaoBase?: Maybe<string>
  }
}

export type ConfiguracaoRndsQueryVariables = {}

export type ConfiguracaoRndsQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
    cadsus: { __typename?: 'ConfiguracaoCadsus'; id: string; habilitado: boolean }
  }
  configuracaoCertificadoHabilitado?: Maybe<{
    __typename?: 'ConfiguracaoCertificado'
    id: string
    rndsHabilitado: boolean
  }>
}

export type BuscaListagemHistoricoClinicoQueryVariables = {
  input: HistoricoQueryInput
}

export type BuscaListagemHistoricoClinicoQuery = {
  __typename?: 'Query'
  historico: {
    __typename?: 'HistoricoPayload'
    content: Array<
      Maybe<
        {
          __typename?: 'Historico'
          idAtendRecente?: Maybe<string>
          idAtendProcessado?: Maybe<string>
          codigoUnicoRegistro?: Maybe<string>
          dataAtendimento?: Maybe<any>
          turno?: Maybe<TurnoEnum>
          tipoApresentacao?: Maybe<TipoRegistroHistoricoClinico>
          tipoAtendProf?: Maybe<TipoAtendimentoProfissional>
          isCancelado?: Maybe<boolean>
          coSubtipoAtendimento?: Maybe<number>
          origemAtendimento?: Maybe<OrigemAtendimento>
          cpfCnsCidadao?: Maybe<string>
          classificacaoRisco?: Maybe<ClassificacaoRisco>
          isAtendRecente: boolean
          condicoesAvaliadas: Array<string>
          examesRealizadosZika?: Maybe<Array<Maybe<string>>>
          cnsProfissional?: Maybe<string>
          hasPrescricaoMedicamento?: Maybe<boolean>
          hasOrientacao?: Maybe<boolean>
          hasAtestado?: Maybe<boolean>
          hasLembrete?: Maybe<boolean>
          hasAlergia?: Maybe<boolean>
          hasSolicitacaoExame?: Maybe<boolean>
          hasResultadoExame?: Maybe<boolean>
          hasEncaminhamento?: Maybe<boolean>
          hasEncaminhamentoEspecializado?: Maybe<boolean>
          hasProcedimentoClinico?: Maybe<boolean>
          hasMarcadorConsumoAlimentar: boolean
          hasCuidadoCompartilhado: boolean
          dadosClinicos?: Maybe<string>
          tipoConsultaOdonto?: Maybe<TipoConsultaOdontoEnum>
          hasObservacao?: Maybe<boolean>
          isAtendObsFinalizado?: Maybe<boolean>
          inicioAtendimentoObservacao?: Maybe<any>
          fimAtendimentoObservacao?: Maybe<any>
          nomeFinalizadorObservacao?: Maybe<string>
          cnsFinalizadorObservacao?: Maybe<string>
          cboFinalizadorObservacao?: Maybe<string>
          ubsFinalizadorObservacao?: Maybe<string>
          ineFinalizadorObservacao?: Maybe<string>
          idadeGestacional?: Maybe<{ __typename?: 'IdadeGestacional'; dias: number; semanas: number }>
          profissional?: Maybe<{ __typename?: 'Profissional'; id: string; nome: string }>
          cbo?: Maybe<{ __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }>
          estagiario?: Maybe<{ __typename?: 'Profissional'; id: string; nome: string }>
          cboEstagio?: Maybe<{ __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }>
          unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }>
          equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
          condicoesVacinacao?: Maybe<{
            __typename?: 'CondicoesVacinacao'
            idAtend: string
            isViajante?: Maybe<boolean>
            isGestante?: Maybe<boolean>
            isPuerpera?: Maybe<boolean>
            isComunicanteHanseniase?: Maybe<boolean>
          }>
          fichasConcatenadas?: Maybe<{
            __typename?: 'FichasConcatenadasHistorico'
            uuidProcedimento?: Maybe<string>
            uuidZika?: Maybe<string>
          }>
          cuidadoCompartilhadoEvolucao?: Maybe<
            { __typename?: 'CuidadoCompartilhadoEvolucao' } & HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment
          >
          cuidadoCompartilhado?: Maybe<
            { __typename?: 'CuidadoCompartilhado' } & HistoricoCuidadoCompartilhadoHeaderFragment
          >
        } & CuidadoCompartilhadoDwFragment
      >
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type HistoricoAntecedentesPecFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  antecedentes?: Maybe<{
    __typename?: 'AntecedenteItemDto'
    id: string
    observacoes?: Maybe<string>
    puericultura?: Maybe<{
      __typename?: 'AntecedentePuericulturaDto'
      tipoGravidez?: Maybe<string>
      tipoParto?: Maybe<string>
      idadeGestacionalDias?: Maybe<string>
      idadeGestacionalSemanas?: Maybe<string>
      apgarUm?: Maybe<string>
      apgarCinco?: Maybe<string>
      apgarDez?: Maybe<string>
      peso?: Maybe<string>
      comprimento?: Maybe<string>
      perimetroCefalico?: Maybe<string>
    }>
    informacoesObstetricas?: Maybe<{
      __typename?: 'AntecedenteInformacoesObstetricasDto'
      gestaPrevias?: Maybe<string>
      abortos?: Maybe<string>
      partoMenosDeUmAno?: Maybe<boolean>
      partos?: Maybe<string>
      partosVaginais?: Maybe<string>
      partosCesareas?: Maybe<string>
      partosDomiciliares?: Maybe<string>
      nascidosVivos?: Maybe<string>
      natimortos?: Maybe<string>
      recemNascidoAbaixo?: Maybe<string>
      recemNascidoAcima?: Maybe<string>
      filhosVivos?: Maybe<string>
      obitoAntesPrimeiraSemana?: Maybe<string>
      obitoAposPrimeiraSemana?: Maybe<string>
    }>
  }>
  cirurgiasInternacoes?: Maybe<
    Array<
      Maybe<{
        __typename?: 'AntecedenteCirurgiasInternacoes'
        id: string
        descricao: string
        observacoes?: Maybe<string>
        dataCirurgiaInternacao?: Maybe<any>
      }>
    >
  >
}

export type HistoricoAvaliacaoPecFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  evolucaoAvaliacao?: Maybe<{
    __typename?: 'EvolucaoAvaliacao'
    id: string
    descricao?: Maybe<string>
    ciapsCids?: Maybe<
      Array<
        Maybe<{
          __typename?: 'EvolucaoAvaliacaoCiapCid'
          id: string
          nota?: Maybe<string>
          isCiapCidNaListaDeProblemas: boolean
          ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
          cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
        }>
      >
    >
  }>
  evolucaoProblema?: Maybe<
    Array<
      Maybe<{
        __typename?: 'EvolucaoProblema'
        id: string
        dataInicio?: Maybe<any>
        dataFim?: Maybe<any>
        situacao: SituacaoProblema
        observacao?: Maybe<string>
        atualizadoEm?: Maybe<any>
        possuiCid: boolean
        possuiCiap: boolean
        problema?: Maybe<{
          __typename?: 'Problema'
          id: string
          descricaoOutro?: Maybe<string>
          situacao: SituacaoProblema
          cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
          ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
        }>
      }>
    >
  >
  alergiaEvolucao?: Maybe<
    Array<
      Maybe<{
        __typename?: 'EvolucaoAlergia'
        id: string
        criticidade: CriticidadeAlergia
        dataInicio?: Maybe<any>
        observacao?: Maybe<string>
        tipoReacao?: Maybe<TipoReacaoAlergia>
        grauCerteza: GrauCertezaAlergia
        manifestacoesLegado?: Maybe<string>
        possuiSubstanciaEspecificaCbara?: Maybe<boolean>
        alergia?: Maybe<{
          __typename?: 'Alergia'
          id: string
          substanciaEspecificaLegado?: Maybe<string>
          categoriaSubstanciaEspecificaLegado?: Maybe<CategoriaSubstanciaAlergiaLegado>
          substanciaEspecifica?: Maybe<{
            __typename?: 'SubstanciaEspecificaAlergia'
            id: string
            categoria: CategoriaSubstanciaAlergia
            nome: string
            sinonimos?: Maybe<string>
            sigla?: Maybe<string>
          }>
        }>
        manifestacoes: Array<{ __typename?: 'ManifestacaoAlergia'; id: string; nome: string; sinonimo?: Maybe<string> }>
      }>
    >
  >
}

export type HistoricoDesfechoPecFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  racionalidadeSaude?: Maybe<RacionalidadeEmSaudeEnum>
  condutas?: Maybe<Array<Maybe<CondutaEnum>>>
  procedimentosAtendimento?: Maybe<
    Array<
      Maybe<{
        __typename?: 'AtendimentoProcedimento'
        id: string
        procedimento: { __typename?: 'Procedimento'; id: string; codigo: string; descricao: string }
      }>
    >
  >
  notificacoesCasoSuspeito?: Maybe<
    Array<
      Maybe<{
        __typename?: 'SinanNotificacaoEvolucao'
        id: string
        notificacao: { __typename?: 'FichaNotificacaoCasoSuspeito'; id: string; nomeNotificacao: string }
      }>
    >
  >
}

export type HistoricoDesfechoOdontoPecFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  atendimentoProfissionalOdonto?: Maybe<{
    __typename?: 'AtendimentoProfissionalOdonto'
    id: string
    cdsTipoFornecimOdonto?: Maybe<Array<Maybe<FornecimentoOdontoEnum>>>
    tipoConduta?: Maybe<Array<Maybe<TipoEncaminhamentoOdontoEnum>>>
  }>
} & HistoricoDesfechoPecFragmentFragment

export type HistoricoFinalizadorObservacaoFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  atendimentoProfissionalObservacao?: Maybe<{
    __typename?: 'AtendimentoProfissionalObservacao'
    id: string
    atendimentoObservacao: {
      __typename?: 'AtendimentoObservacao'
      id: string
      atendimentoProfissionalFinalizador?: Maybe<{
        __typename?: 'AtendimentoProfissional'
        id: string
        lotacao: {
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
          unidadeSaude: {
            __typename?: 'UnidadeSaude'
            id: string
            nome?: Maybe<string>
            cnes: string
            tipoEstabelecimento: TipoEstabelecimentoEnum
          }
        } & AcessoLotacaoFragment
      }>
    }
  }>
}

export type HistoricoInfoAdministrativasPecFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  tipoParticipacaoCidadao?: Maybe<TipoParticipacaoAtendimentoEnum>
  tipoParticipacaoProfissionalConvidado?: Maybe<TipoParticipacaoAtendimentoEnum>
  estagio?: Maybe<{
    __typename?: 'Estagio'
    id: string
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
    cbo: { __typename?: 'Cbo'; id: string; nome: string }
    equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
  }>
  atendimento?: Maybe<{
    __typename?: 'Atendimento'
    id: string
    isRegistroTardio: boolean
    dataCriacaoRegistro?: Maybe<any>
    localAtendimento?: Maybe<{ __typename?: 'LocalAtendimento'; id: string; localAtendimentoExibicao?: Maybe<string> }>
  }>
  lotacao: {
    __typename?: 'Lotacao'
    id: string
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
    unidadeSaude: {
      __typename?: 'UnidadeSaude'
      id: string
      nome?: Maybe<string>
      cnes: string
      tipoEstabelecimento: TipoEstabelecimentoEnum
    }
  } & AcessoLotacaoFragment
  lotacaoAtendimentoCompartilhado?: Maybe<
    {
      __typename?: 'Lotacao'
      id: string
      profissional: { __typename?: 'Profissional'; id: string; nome: string }
      unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    } & AcessoLotacaoFragment
  >
} & HistoricoFinalizadorObservacaoFragmentFragment

export type HistoricoObjetivoPecFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  evolucaoObjetivo?: Maybe<{ __typename?: 'EvolucaoObjetivo'; id: string; descricao?: Maybe<string> }>
  medicoes?: Maybe<
    Array<
      Maybe<
        {
          __typename?: 'Medicao'
          valorImc?: Maybe<number>
          dum?: Maybe<any>
          valorVacinacaoEmDia?: Maybe<boolean>
        } & MedicoesFragmentFragment
      >
    >
  >
  marcadorConsumoAlimentar?: Maybe<{
    __typename?: 'MarcadorConsumoAlimentar'
    id: string
    categoria: FaixaEtariaConsumoAlimentar
    respostas: Array<{
      __typename?: 'RespostaMarcadoresConsumoAlimentar'
      pergunta: PerguntasQuestionarioEnum
      opcoesSelecionadas: Array<OpcaoTipoPerguntaEnum>
    }>
  }>
  examesResultados?: Maybe<
    Array<
      Maybe<{
        __typename?: 'ResultadoExame'
        id: string
        dataSolicitacao?: Maybe<any>
        dataRealizacao?: Maybe<any>
        dataResultado?: Maybe<any>
        resultado?: Maybe<string>
        exame: { __typename?: 'Procedimento' } & ProcedimentoFragment
        especifico?: Maybe<{
          __typename?: 'ResultadoExameEspecifico'
          id: string
          exameEspecificoEnum: ExameEspecificoEnum
          valor?: Maybe<number>
          igDias?: Maybe<number>
          igSemanas?: Maybe<number>
          dpp?: Maybe<any>
          resultadoExamePuericulturaEnum?: Maybe<FichaComplementarZikaMicrocefaliaResultadoExameEnum>
        }>
      }>
    >
  >
}

export type HistoricoPlanoCuidadoFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  planosDeCuidado?: Maybe<
    Array<{ __typename?: 'AtendimentoObservacaoPlanoCuidado'; id: string; descricao: string; dataDescricao: any }>
  >
}

export type HistoricoPlanoPecCuidadoCompartilhadoFragment = {
  __typename?: 'CuidadoCompartilhado'
  id: string
  discussao: string
  cidadaoAceitaAtendTic: boolean
  ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
  cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
  primeiraEvolucao: {
    __typename?: 'CuidadoCompartilhadoEvolucao'
    classificacaoPrioridade: ClassificacaoPrioridadeCuidadoEnum
    lotacaoExecutante?: Maybe<{ __typename?: 'Lotacao' } & LotacaoHistoricoCuidadoCompartilhadoHeaderFragment>
    cboExecutante: { __typename?: 'Cbo' } & CboHistoricoCuidadoCompartilhadoHeaderFragment
    unidadeSaudeExecutante: { __typename?: 'UnidadeSaude' } & UnidadeSaudeHistoricoCuidadoCompartilhadoHeaderFragment
  }
}

export type HistoricoPlanoPecFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  quantidadeAtestados?: Maybe<number>
  quantidadeOrientacoes?: Maybe<number>
  encaminhamentos?: Maybe<Array<TipoEncaminhamentoExternoEnum>>
  evolucaoPlano?: Maybe<{
    __typename?: 'EvolucaoPlano'
    id: string
    descricao?: Maybe<string>
    intervencoesProcedimentos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'EvolucaoPlanoCiap'
          id: string
          nota?: Maybe<string>
          ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
          procedimento?: Maybe<{ __typename?: 'Procedimento'; id: string; descricao: string; codigo: string }>
        }>
      >
    >
  }>
  encaminhamentosEspecializados?: Maybe<
    Array<
      Maybe<{
        __typename?: 'EncaminhamentoExternoEspecializado'
        id: string
        observacoes?: Maybe<string>
        motivoEncaminhamento?: Maybe<string>
        complemento?: Maybe<string>
        classificacaoRiscoEncaminhamento: ClassificacaoRiscoEncaminhamentoEnum
        hipoteseDiagnosticoCid10?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
        hipoteseDiagnosticoCiap?: Maybe<{ __typename?: 'Ciap'; id: string; codigo: string; descricao: string }>
        especialidadeSisreg: { __typename?: 'EspecialidadeSisreg'; id: string; descricao: string }
      }>
    >
  >
  requisicoesExames?: Maybe<
    Array<
      Maybe<{
        __typename?: 'SolicitacaoExame'
        id: string
        justificativa: string
        observacoes?: Maybe<string>
        cid10?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
        exames: Array<{
          __typename?: 'ResultadoExame'
          id: string
          observacao?: Maybe<string>
          exame: { __typename?: 'Procedimento' } & ProcedimentoFragment
        }>
      }>
    >
  >
  receitaMedicamento?: Maybe<
    Array<
      Maybe<{
        __typename?: 'ReceitaMedicamento'
        id: string
        quantidadeReceitada: number
        recomendacoes?: Maybe<string>
        posologia: string
        dtInicioTratamento?: Maybe<any>
        dtFimTratamento?: Maybe<any>
        medidaTempoTratamento?: Maybe<UnidadeMedidaTempoEnum>
        medicamento: {
          __typename?: 'Medicamento'
          id: string
          principioAtivo: string
          concentracao?: Maybe<string>
          formaFarmaceutica?: Maybe<{ __typename?: 'FormaFarmaceutica'; id: string; nome: string }>
        }
        aplicacaoMedicamento: { __typename?: 'AplicacaoMedicamento'; id: string; nome: string }
      }>
    >
  >
  cuidadosCompartilhados?: Maybe<
    Array<{ __typename?: 'CuidadoCompartilhado' } & HistoricoPlanoPecCuidadoCompartilhadoFragment>
  >
  guiasEncaminhamento?: Maybe<
    Array<{ __typename?: 'GuiaEncaminhamento' } & HistoricoPlanoPecGuiaEncaminhamentoFragment>
  >
}

export type HistoricoPlanoPecGuiaEncaminhamentoFragment = {
  __typename?: 'GuiaEncaminhamento'
  id: string
  discussao: string
  classificacaoPrioridade: ClassificacaoPrioridadeCuidadoEnum
  ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
  cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
  cbo: { __typename?: 'Cbo'; id: string; nome: string }
}

export type HistoricoRegistrosAnterioresVacinacaoFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  vacinacao?: Maybe<{
    __typename?: 'AtendimentoVacinacao'
    id: string
    registrosVacinacao?: Maybe<
      Array<{
        __typename?: 'RegistroVacinacao'
        id: string
        dataAplicacao?: Maybe<any>
        isRegistroAnterior?: Maybe<boolean>
        isAplicadoExterior?: Maybe<boolean>
        observacoes?: Maybe<string>
        imunobiologico: { __typename?: 'Imunobiologico'; id: string; nome: string }
        doseImunobiologico: { __typename?: 'DoseImunobiologico'; id: string; sigla: string }
        tipoRegistroVacinacao: {
          __typename?: 'TipoRegistroVacinacao'
          id: string
          identificador: TipoRegistroVacinacaoEnum
        }
        loteImunobiologico?: Maybe<{
          __typename?: 'LoteImunobiologico'
          id: string
          lote?: Maybe<string>
          fabricante?: Maybe<{ __typename?: 'ImunobiologicoFabricante'; id: string; nome: string }>
        }>
        estrategiaVacinacao?: Maybe<{ __typename?: 'EstrategiaVacinacao'; id: string; nome: string }>
      }>
    >
  }>
}

export type HistoricoSubjetivoPecFragmentFragment = {
  __typename?: 'AtendimentoProfissional'
  id: string
  evolucaoSubjetivo?: Maybe<{
    __typename?: 'EvolucaoSubjetivo'
    id: string
    descricao?: Maybe<string>
    ciaps?: Maybe<
      Array<
        Maybe<{
          __typename?: 'EvolucaoSubjetivoCiap'
          id: string
          nota?: Maybe<string>
          ciap: { __typename?: 'Ciap' } & CiapFragment
        }>
      >
    >
  }>
}

export type HistoricoAtendimentoDomiciliarAdQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoAtendimentoDomiciliarAdQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      evolucaoObjetivo?: Maybe<{ __typename?: 'EvolucaoObjetivo'; id: string; descricao?: Maybe<string> }>
      medicoes?: Maybe<
        Array<
          Maybe<{
            __typename?: 'Medicao'
            id: string
            valorPeso?: Maybe<number>
            valorAltura?: Maybe<number>
            valorFrequenciaCardiaca?: Maybe<number>
            valorPressaoArterial?: Maybe<string>
            valorPerimetroCefalico?: Maybe<number>
            valorFrequenciaRespiratoria?: Maybe<number>
            valorTemperatura?: Maybe<number>
            valorSaturacaoO2?: Maybe<number>
            valorGlicemia?: Maybe<number>
            tipoGlicemia?: Maybe<TipoGlicemia>
            valorImc?: Maybe<number>
            perimetroPanturrilha?: Maybe<number>
            valorCircunferenciaAbdominal?: Maybe<number>
            dum?: Maybe<any>
            valorVacinacaoEmDia?: Maybe<boolean>
            medicaoAnterior?: Maybe<boolean>
            dataMedicao: any
          }>
        >
      >
      evolucaoAvaliacao?: Maybe<{
        __typename?: 'EvolucaoAvaliacao'
        id: string
        descricao?: Maybe<string>
        ciapsCids?: Maybe<
          Array<
            Maybe<{
              __typename?: 'EvolucaoAvaliacaoCiapCid'
              id: string
              nota?: Maybe<string>
              isCiapCidNaListaDeProblemas: boolean
              ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
              cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
            }>
          >
        >
      }>
      evolucaoPlano?: Maybe<{
        __typename?: 'EvolucaoPlano'
        id: string
        descricao?: Maybe<string>
        intervencoesProcedimentos?: Maybe<
          Array<
            Maybe<{
              __typename?: 'EvolucaoPlanoCiap'
              id: string
              nota?: Maybe<string>
              ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
              procedimento?: Maybe<{ __typename?: 'Procedimento'; id: string; descricao: string; codigo: string }>
            }>
          >
        >
      }>
      atendimentoProfissionalAD?: Maybe<{
        __typename?: 'AtendimentoProfissionalAtencaoDomiciliar'
        id: string
        origem?: Maybe<AtencaoDomiciliarOrigem>
        modalidade: AtencaoDomiciliarModalidade
        conduta?: Maybe<AtencaoDomiciliarCondutaDesfecho>
        cid10Principal?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
        cid10Secundario1?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
        cid10Secundario2?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
        condicoesAvaliadasAD?: Maybe<
          Array<
            Maybe<{
              __typename?: 'AtencaoDomiciliarResposta'
              id: number
              situacaoAtiva?: Maybe<boolean>
              questao?: Maybe<{ __typename?: 'AtencaoDomiciliarQuestao'; id: string; descricao: string }>
            }>
          >
        >
      }>
      cidadaoAD?: Maybe<{
        __typename?: 'AtencaoDomiciliarCidadao'
        id: string
        dataObito?: Maybe<any>
        numeroDocumentoObito?: Maybe<string>
      }>
      procedimentosAtendimento?: Maybe<
        Array<
          Maybe<{
            __typename?: 'AtendimentoProcedimento'
            id: string
            procedimento: { __typename?: 'Procedimento'; id: string; descricao: string; codigo: string }
            cid10Principal?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
          }>
        >
      >
    } & HistoricoSubjetivoPecFragmentFragment &
      HistoricoDesfechoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type HistoricoAtendimentoDomiciliarDwQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoAtendimentoDomiciliarDwQuery = {
  __typename?: 'Query'
  historicoAtendimentoDomiciliar?: Maybe<{
    __typename?: 'FatoAtendimentoDomiciliar'
    id: string
    condicoesAvaliadas: Array<Maybe<string>>
    fatosAtendDomCondicaoAval?: Maybe<
      Array<
        Maybe<{
          __typename?: 'FatoAtendDomCondicaoAval'
          id: string
          dimensaoCiap2?: Maybe<{ __typename?: 'DimensaoCiap'; id: string; codigoCiap: string; nomeCiap: string }>
          dimensaoCid10?: Maybe<{ __typename?: 'DimensaoCid10'; id: string; codigoCid10: string; nomeCid10: string }>
        }>
      >
    >
    dimensaoProfissional1?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoProfissional2?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoCbo1?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoCbo2?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoUnidadeSaude1?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoUnidadeSaude2?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe1?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoEquipe2?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoLocalAtendimento?: Maybe<{ __typename?: 'DimensaoLocalAtendimento'; id: string; descricao: string }>
    dimensaoModalidadeAD?: Maybe<{ __typename?: 'DimensaoModalidadeAD'; id: string; descricao: string }>
    dimensaoCondutaAD?: Maybe<{ __typename?: 'DimensaoCondutaAD'; id: string; conduta: string }>
    procedimentos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'FatoAtendDomProced'
          id: string
          dimensaoProcedimento: {
            __typename?: 'DimensaoProcedimento'
            id: string
            codigoProcedimento: string
            descricaoProcedimento: string
          }
        }>
      >
    >
  }>
}

export type HistoricoConsultaPecQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoConsultaPecQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      medicoes?: Maybe<
        Array<Maybe<{ __typename?: 'Medicao'; id: string; medicaoAnterior?: Maybe<boolean>; dataMedicao: any }>>
      >
    } & HistoricoSubjetivoPecFragmentFragment &
      HistoricoObjetivoPecFragmentFragment &
      HistoricoPlanoCuidadoFragmentFragment &
      HistoricoAvaliacaoPecFragmentFragment &
      HistoricoPlanoPecFragmentFragment &
      HistoricoAntecedentesPecFragmentFragment &
      HistoricoRegistrosAnterioresVacinacaoFragmentFragment &
      HistoricoDesfechoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type HistoricoAtendimentoIndividualDwQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoAtendimentoIndividualDwQuery = {
  __typename?: 'Query'
  historicoAtendimentoIndividualDw?: Maybe<{
    __typename?: 'FatoAtendimentoIndividual'
    id: string
    stFicouEmObservacao?: Maybe<boolean>
    stVacinacaoEmDia?: Maybe<boolean>
    nuPeso?: Maybe<number>
    nuAltura?: Maybe<number>
    nuImc?: Maybe<number>
    nuPerimetroCefalico?: Maybe<number>
    dum?: Maybe<any>
    stGravidezPlanejada?: Maybe<boolean>
    nuIdadeGestacional?: Maybe<number>
    nuGestasPrevias?: Maybe<number>
    nuPartos?: Maybe<number>
    cidsCiapsAvaliados: Array<Maybe<string>>
    nasfPolo: Array<Maybe<string>>
    stNasf: boolean
    condutaDesfecho: Array<Maybe<string>>
    encaminhamentos: Array<Maybe<string>>
    dimensaoAleitamento?: Maybe<{ __typename?: 'DimensaoAleitamento'; id: string; descricao: string }>
    dimensaoModalidadeAd?: Maybe<{ __typename?: 'DimensaoModalidadeAD'; id: string; descricao: string }>
    dimensaoRacionalidadeSaude?: Maybe<{ __typename?: 'DimensaoRacionalidadeSaude'; id: string; descricao: string }>
    examesSolicitados?: Maybe<
      Array<
        Maybe<{
          __typename?: 'DimensaoProcedimento'
          id: string
          codigoProcedimento: string
          descricaoProcedimento: string
        }>
      >
    >
    examesAvaliados?: Maybe<
      Array<
        Maybe<{
          __typename?: 'DimensaoProcedimento'
          id: string
          codigoProcedimento: string
          descricaoProcedimento: string
        }>
      >
    >
    dimensaoProfissional1?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoProfissional2?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoCbo1?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoCbo2?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoUnidadeSaude1?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoUnidadeSaude2?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe1?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoEquipe2?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoLocalAtendimento?: Maybe<{ __typename?: 'DimensaoLocalAtendimento'; id: string; descricao: string }>
    dimProfFinalizadorObs?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimCboFinalizadorObs?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimUbsFinalizadorObs?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimEquipeFinalizadorObs?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimTipoParticipacaoCidadao?: Maybe<{
      __typename?: 'DimensaoTipoParticipacaoAtendimento'
      id: string
      descricao: string
    }>
    dimTipoParticipacaoProfConvidado?: Maybe<{
      __typename?: 'DimensaoTipoParticipacaoAtendimento'
      id: string
      descricao: string
    }>
  }>
}

export type HistoricoConcatenadaProcedimentoQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoConcatenadaProcedimentoQuery = {
  __typename?: 'Query'
  historicoProcedimentoDw?: Maybe<{
    __typename?: 'FatoProcedAtend'
    id: string
    procedimentos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'DimensaoProcedimento'
          id: string
          codigoProcedimento: string
          descricaoProcedimento: string
        }>
      >
    >
  }>
}

export type HistoricoConcatenadaZikaQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoConcatenadaZikaQuery = {
  __typename?: 'Query'
  historicoZikaDetail?: Maybe<{
    __typename?: 'HistoricoZikaDetail'
    examesDeImagem: Array<
      Maybe<{ __typename?: 'ExameZika'; id: string; nome: string; resultado: string; dataRealizacao: any }>
    >
    outrosExames: Array<
      Maybe<{ __typename?: 'ExameZika'; id: string; nome: string; resultado: string; dataRealizacao: any }>
    >
  }>
}

export type HistoricoPreNatalPecQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoPreNatalPecQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      medicoes?: Maybe<
        Array<
          Maybe<{
            __typename?: 'Medicao'
            id: string
            valorAlturaUterina?: Maybe<string>
            valorBatimentoCardiacoFetal?: Maybe<string>
            medicaoAnterior?: Maybe<boolean>
            dataMedicao: any
          }>
        >
      >
      atendimentoProfissionalPreNatal?: Maybe<{
        __typename?: 'AtendimentoProfissionalPreNatal'
        id: string
        gravidezPlanejada?: Maybe<boolean>
        movimentacaoFetal?: Maybe<boolean>
        tipoEdema?: Maybe<TipoEdemaEnum>
      }>
    } & HistoricoSubjetivoPecFragmentFragment &
      HistoricoObjetivoPecFragmentFragment &
      HistoricoPlanoCuidadoFragmentFragment &
      HistoricoAvaliacaoPecFragmentFragment &
      HistoricoPlanoPecFragmentFragment &
      HistoricoAntecedentesPecFragmentFragment &
      HistoricoRegistrosAnterioresVacinacaoFragmentFragment &
      HistoricoDesfechoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type HistoricoPuericulturaPecQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoPuericulturaPecQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      medicoes?: Maybe<
        Array<
          Maybe<{
            __typename?: 'Medicao'
            id: string
            valorAlturaUterina?: Maybe<string>
            valorBatimentoCardiacoFetal?: Maybe<string>
            medicaoAnterior?: Maybe<boolean>
            dataMedicao: any
          }>
        >
      >
      atendimentoProfissionalPuericultura?: Maybe<{
        __typename?: 'AtendimentoProfissionalPuericultura'
        id: string
        aleitamentoMaterno?: Maybe<TipoAleitamentoMaternoEnum>
        neuroAlteracaoFenotipica?: Maybe<
          Array<
            Maybe<{
              __typename?: 'NeuroAlteracaoFenotipicaEvolucao'
              id: string
              statusAvaliacao?: Maybe<StatusAvaliadoEnum>
              alteracaoFenotipicaDetalhe?: Maybe<{
                __typename?: 'NeuroAlteracaoFenotipicaDetalhe'
                id: string
                descricao?: Maybe<string>
              }>
            }>
          >
        >
        neuroFatorRisco?: Maybe<
          Array<
            Maybe<{
              __typename?: 'NeuroFatorRiscoEvolucao'
              id: string
              statusAvaliacao?: Maybe<StatusAvaliadoEnum>
              fatorRiscoDetalhe?: Maybe<{
                __typename?: 'NeuroFatorRiscoDetalhe'
                id: string
                descricao?: Maybe<string>
              }>
            }>
          >
        >
        neuroMarco?: Maybe<
          Array<
            Maybe<{
              __typename?: 'NeuroMarcoEvolucao'
              id: string
              statusAvaliacao?: Maybe<StatusAvaliadoEnum>
              anosIdadeRegistro?: Maybe<number>
              mesesIdadeRegistro?: Maybe<number>
              marcoDetalhe?: Maybe<{
                __typename?: 'NeuroMarcoDetalhe'
                id: string
                titulo?: Maybe<string>
                faixaEtaria?: Maybe<{ __typename?: 'NeuroFaixaEtaria'; id: string; descricao?: Maybe<string> }>
              }>
            }>
          >
        >
      }>
    } & HistoricoSubjetivoPecFragmentFragment &
      HistoricoObjetivoPecFragmentFragment &
      HistoricoPlanoCuidadoFragmentFragment &
      HistoricoAvaliacaoPecFragmentFragment &
      HistoricoPlanoPecFragmentFragment &
      HistoricoAntecedentesPecFragmentFragment &
      HistoricoRegistrosAnterioresVacinacaoFragmentFragment &
      HistoricoDesfechoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type HistoricoPuerperioPecQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoPuerperioPecQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      medicoes?: Maybe<
        Array<Maybe<{ __typename?: 'Medicao'; id: string; medicaoAnterior?: Maybe<boolean>; dataMedicao: any }>>
      >
    } & HistoricoSubjetivoPecFragmentFragment &
      HistoricoObjetivoPecFragmentFragment &
      HistoricoPlanoCuidadoFragmentFragment &
      HistoricoAvaliacaoPecFragmentFragment &
      HistoricoPlanoPecFragmentFragment &
      HistoricoAntecedentesPecFragmentFragment &
      HistoricoRegistrosAnterioresVacinacaoFragmentFragment &
      HistoricoDesfechoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type HistoricoAtendimentoOdontologicoDwQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoAtendimentoOdontologicoDwQuery = {
  __typename?: 'Query'
  historicoAtendimentoOdontoDw?: Maybe<{
    __typename?: 'FatoAtendimentoOdonto'
    id: string
    isPacienteNecessidadesEspeciais: boolean
    isGestante?: Maybe<boolean>
    cidsCiapsAvaliados: Array<Maybe<string>>
    vigilanciaSaudeBucal: Array<Maybe<string>>
    fornecimento: Array<Maybe<string>>
    conduta: Array<Maybe<string>>
    encaminhamento: Array<Maybe<string>>
    procedimentos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'FatoAtendOdontoProced'
          id: string
          quantidadeProcedimentos: number
          dimensaoProcedimento: {
            __typename?: 'DimensaoProcedimento'
            id: string
            codigoProcedimento: string
            descricaoProcedimento: string
          }
        }>
      >
    >
    dimensaoProfissional1?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoProfissional2?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoCbo1?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoCbo2?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoUnidadeSaude1?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoUnidadeSaude2?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe1?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoEquipe2?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoLocalAtendimento?: Maybe<{ __typename?: 'DimensaoLocalAtendimento'; id: string; descricao: string }>
    dimTipoParticipacaoCidadao?: Maybe<{
      __typename?: 'DimensaoTipoParticipacaoAtendimento'
      id: string
      descricao: string
    }>
    dimTipoParticipacaoProfConvidado?: Maybe<{
      __typename?: 'DimensaoTipoParticipacaoAtendimento'
      id: string
      descricao: string
    }>
  }>
}

export type HistoricoAtendimentoOdontoPecQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoAtendimentoOdontoPecQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      evolucaoObjetivo?: Maybe<{
        __typename?: 'EvolucaoObjetivo'
        id: string
        descricao?: Maybe<string>
        necessidadesEspeciais?: Maybe<boolean>
      }>
      medicoes?: Maybe<
        Array<Maybe<{ __typename?: 'Medicao'; id: string; medicaoAnterior?: Maybe<boolean>; dataMedicao: any }>>
      >
      evolucaoAvaliacao?: Maybe<{
        __typename?: 'EvolucaoAvaliacao'
        id: string
        necessidadeDeProtese?: Maybe<boolean>
        tiposVigilanciaBucal?: Maybe<Array<Maybe<VigilanciaEmSaudeBucalEnum>>>
      }>
      atendimentoProfissionalOdonto?: Maybe<{
        __typename?: 'AtendimentoProfissionalOdonto'
        id: string
        observacaoRPC?: Maybe<string>
        odontograma?: Maybe<{
          __typename?: 'Odontograma'
          id: string
          proteseTotalInferior?: Maybe<boolean>
          proteseTotalSuperior?: Maybe<boolean>
          contencao?: Maybe<boolean>
          aparelho?: Maybe<boolean>
        }>
        evolucoes?: Maybe<
          Array<
            Maybe<{
              __typename?: 'EvolucaoProcedimentoOdonto'
              id: string
              descricaoParteBucal?: Maybe<string>
              observacao?: Maybe<string>
              procedimentos?: Maybe<
                Array<{ __typename?: 'Procedimento'; id: string; descricao: string; codigo: string }>
              >
              partesBucais?: Maybe<
                Array<{
                  __typename?: 'ParteBucal'
                  id: string
                  tipoParteBucal: TipoParteBucalEnum
                  descricao?: Maybe<string>
                  parteBucalEnum: ParteBucalEnum
                }>
              >
            }>
          >
        >
        periogramaSimplificado?: Maybe<{
          __typename?: 'PeriogramaSimplificado'
          id: string
          avaliacaoSextante1: string
          avaliacaoSextante2: string
          avaliacaoSextante3: string
          avaliacaoSextante4: string
          avaliacaoSextante5: string
          avaliacaoSextante6: string
          observacao?: Maybe<string>
        }>
        periogramaCompleto?: Maybe<
          Array<
            Maybe<{
              __typename?: 'PeriogramaCompleto'
              id: string
              excluido: boolean
              profundidadeVestibular?: Maybe<string>
              recessaoVestibular?: Maybe<string>
              profundidadeInterna?: Maybe<string>
              recessaoInterna?: Maybe<string>
              placaDVestibular?: Maybe<boolean>
              placaVVestibular?: Maybe<boolean>
              placaMVestibular?: Maybe<boolean>
              sangramentoDVestibular?: Maybe<boolean>
              sangramentoVVestibular?: Maybe<boolean>
              sangramentoMVestibular?: Maybe<boolean>
              placaDInterna?: Maybe<boolean>
              placaVInterna?: Maybe<boolean>
              placaMInterna?: Maybe<boolean>
              sangramentoDInterna?: Maybe<boolean>
              sangramentoVInterna?: Maybe<boolean>
              sangramentoMInterna?: Maybe<boolean>
              mobilidade?: Maybe<string>
              lesaoDeFurca?: Maybe<string>
              dente: { __typename?: 'ParteBucal'; id: string; parteBucalEnum: ParteBucalEnum }
            }>
          >
        >
        tecidosMoles?: Maybe<
          Array<
            Maybe<{
              __typename?: 'TecidoMole'
              id: string
              descricaoSintomatologia?: Maybe<string>
              parteBucal: { __typename?: 'ParteBucal'; id: string; descricao?: Maybe<string> }
              cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
              caracteristicasLesao?: Maybe<
                Array<{ __typename?: 'CaracteristicaLesao'; id: string; caracteristicaLesao: CaracteristicaLesaoEnum }>
              >
              evolucaoProcedimentoOdonto?: Maybe<{
                __typename?: 'EvolucaoProcedimentoOdonto'
                id: string
                observacao?: Maybe<string>
                procedimentos?: Maybe<
                  Array<{ __typename?: 'Procedimento'; id: string; descricao: string; codigo: string }>
                >
              }>
            }>
          >
        >
      }>
    } & HistoricoSubjetivoPecFragmentFragment &
      HistoricoObjetivoPecFragmentFragment &
      HistoricoAvaliacaoPecFragmentFragment &
      HistoricoPlanoPecFragmentFragment &
      HistoricoAntecedentesPecFragmentFragment &
      HistoricoRegistrosAnterioresVacinacaoFragmentFragment &
      HistoricoDesfechoOdontoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type HistoricoAtividadeColetivaQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoAtividadeColetivaQuery = {
  __typename?: 'Query'
  historicoAtividadeColetiva?: Maybe<{
    __typename?: 'FatoAtividadeColetivaParticipante'
    id: string
    nuUuidFicha: string
    stAvaliacaoAlterada?: Maybe<boolean>
    nuPesoParticipante?: Maybe<number>
    nuAlturaParticipante?: Maybe<number>
    nuImcParticipante?: Maybe<number>
    pnctParticipante: Array<Maybe<string>>
    fatoAtividadeColetiva: {
      __typename?: 'FatoAtividadeColetiva'
      id: string
      nuAvaliacoesAlteradas?: Maybe<number>
      stPseEducacao: boolean
      stPseSaude: boolean
      temasSaude: Array<Maybe<string>>
      praticasSaude: Array<Maybe<string>>
      publicoAlvo: Array<Maybe<string>>
      dimensaoProcedimento?: Maybe<{
        __typename?: 'DimensaoProcedimento'
        id: string
        descricaoProcedimento: string
        codigoProcedimento: string
      }>
      dimensaoProfissional?: Maybe<{
        __typename?: 'DimensaoProfissional'
        id: string
        cns?: Maybe<string>
        nome?: Maybe<string>
      }>
      dimensaoCbo?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
      dimensaoUnidadeSaude?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
      dimensaoEquipe?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
      dimensaoTipoAtividade: { __typename?: 'DimensaoTipoAtividade'; id: string; descricaoTipoAtividade: string }
      dimensaoInep?: Maybe<{
        __typename?: 'DimensaoInep'
        id: string
        nuIdentificador: string
        noEstabelecimento: string
      }>
    }
  }>
}

export type HistoricoAvaliacaoElegibilidadeAdQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoAvaliacaoElegibilidadeAdQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      atendimentoProfissionalAD?: Maybe<{
        __typename?: 'AtendimentoProfissionalAtencaoDomiciliar'
        id: string
        origem?: Maybe<AtencaoDomiciliarOrigem>
        modalidade: AtencaoDomiciliarModalidade
        elegivel?: Maybe<boolean>
        conclusao?: Maybe<Array<Maybe<string>>>
        conduta?: Maybe<AtencaoDomiciliarCondutaDesfecho>
        cid10Principal?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
        cid10Secundario1?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
        cid10Secundario2?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
        condicoesAvaliadasElegibilidade?: Maybe<
          Array<
            Maybe<{
              __typename?: 'AtendimentoProfissionalAtencaoDomiciliarQuestao'
              id: string
              situacaoAtiva?: Maybe<boolean>
              descricao?: Maybe<string>
            }>
          >
        >
      }>
      cidadaoAD?: Maybe<{
        __typename?: 'AtencaoDomiciliarCidadao'
        id: string
        dataObito?: Maybe<any>
        numeroDocumentoObito?: Maybe<string>
      }>
    } & HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type HistoricoAvaliacaoElegibilidadeDwQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoAvaliacaoElegibilidadeDwQuery = {
  __typename?: 'Query'
  historicoElegibilidadeDetail?: Maybe<{
    __typename?: 'FatoAvaliacaoElegibilidade'
    id: string
    nuUuidFicha: string
    procedenciaOrigem?: Maybe<string>
    condicoesAvaliadas?: Maybe<Array<Maybe<string>>>
    conclusao?: Maybe<Array<Maybe<string>>>
    isElegivel: boolean
    cpfCuidador?: Maybe<string>
    cnsCuidador?: Maybe<string>
    dimensaoCid10Principal?: Maybe<{ __typename?: 'DimensaoCid10'; id: string; codigoCid10: string; nomeCid10: string }>
    dimensaoCid10SecundarioUm?: Maybe<{
      __typename?: 'DimensaoCid10'
      id: string
      codigoCid10: string
      nomeCid10: string
    }>
    dimensaoCid10SecundarioDois?: Maybe<{
      __typename?: 'DimensaoCid10'
      id: string
      codigoCid10: string
      nomeCid10: string
    }>
    dimensaoProfissional1?: Maybe<{
      __typename?: 'DimensaoProfissional'
      id: string
      cns?: Maybe<string>
      nome?: Maybe<string>
    }>
    dimensaoCbo1?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; cbo: string; nome: string }>
    dimensaoUnidadeSaude1?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe1?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoProfissional2?: Maybe<{
      __typename?: 'DimensaoProfissional'
      id: string
      cns?: Maybe<string>
      nome?: Maybe<string>
    }>
    dimensaoCbo2?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; cbo: string; nome: string }>
    dimensaoUnidadeSaude2?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe2?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoCuidador?: Maybe<{ __typename?: 'DimensaoCuidador'; id: string; descricao: string }>
    dimensaoModalidadeAD?: Maybe<{ __typename?: 'DimensaoModalidadeAD'; id: string; descricao: string }>
  }>
}

export type CboHistoricoCuidadoCompartilhadoHeaderFragment = { __typename?: 'Cbo'; id: string; nome: string }

export type HistoricoCuidadoCompartilhadoDwDetalhesQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoCuidadoCompartilhadoDwDetalhesQuery = {
  __typename?: 'Query'
  historicoCuidadoCompartilhadoDw?: Maybe<{
    __typename?: 'FatoCuidadoCompartilhado'
    id: string
    isExecutante?: Maybe<boolean>
  }>
}

export type HistoricoCuidadoCompartilhadoEvolucaoDetalhesQueryVariables = {
  id: Scalars['ID']
}

export type HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery = {
  __typename?: 'Query'
  cuidadoCompartilhadoEvolucao?: Maybe<{
    __typename?: 'CuidadoCompartilhadoEvolucao'
    id: string
    conduta: CondutaCuidadoCompartilhadoEnum
    dataEvolucao: any
    descricao?: Maybe<string>
    classificacaoPrioridade: ClassificacaoPrioridadeCuidadoEnum
    reclassificacaoPrioridade?: Maybe<ClassificacaoPrioridadeCuidadoEnum>
    enviarGarantiaAcesso?: Maybe<CondutaCuidadoCompartilhadoEnum>
    lotacao: { __typename?: 'Lotacao' } & LotacaoHistoricoCuidadoCompartilhadoHeaderFragment
    cuidadoCompartilhado: {
      __typename?: 'CuidadoCompartilhado'
      id: string
      iniciadoEm: any
      discussao: string
      atendimentoProfissional: {
        __typename?: 'AtendimentoProfissional'
        id: string
        atendimento?: Maybe<{
          __typename?: 'Atendimento'
          id: string
          localAtendimento?: Maybe<{
            __typename?: 'LocalAtendimento'
            id: string
            localAtendimentoExibicao?: Maybe<string>
          }>
          unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        }>
      }
    }
    agenda?: Maybe<{
      __typename?: 'Agendado'
      id: string
      horarioInicial?: Maybe<any>
      observacao?: Maybe<string>
      situacao: SituacaoAgendadoEnum
    }>
  }>
}

export type HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment = {
  __typename?: 'CuidadoCompartilhadoEvolucao'
  id: string
  conduta: CondutaCuidadoCompartilhadoEnum
  classificacaoPrioridade: ClassificacaoPrioridadeCuidadoEnum
  reclassificacaoPrioridade?: Maybe<ClassificacaoPrioridadeCuidadoEnum>
  lotacaoExecutante?: Maybe<{ __typename?: 'Lotacao' } & LotacaoHistoricoCuidadoCompartilhadoHeaderFragment>
  cboExecutante: { __typename?: 'Cbo' } & CboHistoricoCuidadoCompartilhadoHeaderFragment
  unidadeSaudeExecutante: { __typename?: 'UnidadeSaude' } & UnidadeSaudeHistoricoCuidadoCompartilhadoHeaderFragment
}

export type HistoricoCuidadoCompartilhadoHeaderFragment = {
  __typename?: 'CuidadoCompartilhado'
  id: string
  cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
  ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
  lotacaoSolicitante: { __typename?: 'Lotacao' } & LotacaoHistoricoCuidadoCompartilhadoHeaderFragment
}

export type LotacaoHistoricoCuidadoCompartilhadoHeaderFragment = {
  __typename?: 'Lotacao'
  id: string
  profissional: { __typename?: 'Profissional'; id: string; cns?: Maybe<string>; nome: string }
  cbo: { __typename?: 'Cbo' } & CboHistoricoCuidadoCompartilhadoHeaderFragment
  equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
  unidadeSaude: { __typename?: 'UnidadeSaude' } & UnidadeSaudeHistoricoCuidadoCompartilhadoHeaderFragment
}

export type UnidadeSaudeHistoricoCuidadoCompartilhadoHeaderFragment = {
  __typename?: 'UnidadeSaude'
  id: string
  nome?: Maybe<string>
  cnes: string
}

export type HistoricoCuidadoCompartilhadoLotacaoAfetadaQueryVariables = {
  id: Scalars['ID']
}

export type HistoricoCuidadoCompartilhadoLotacaoAfetadaQuery = {
  __typename?: 'Query'
  cuidadoCompartilhadoEvolucao?: Maybe<{
    __typename?: 'CuidadoCompartilhadoEvolucao'
    id: string
    lotacaoAfetada?: Maybe<{ __typename?: 'Lotacao' } & LotacaoHistoricoCuidadoCompartilhadoHeaderFragment>
  }>
}

export type HistoricoAuditImpressaoEscutaMutationVariables = {
  atendProfId: Scalars['ID']
}

export type HistoricoAuditImpressaoEscutaMutation = {
  __typename?: 'Mutation'
  auditImpressaoEscutaInicial?: Maybe<string>
}

export type HistoricoEscutaInicialQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoEscutaInicialQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      classificacaoRisco?: Maybe<ClassificacaoRisco>
      evolucaoSubjetivo?: Maybe<{ __typename?: 'EvolucaoSubjetivo'; acompanhadoEspecialidade?: Maybe<string> }>
      medicoes?: Maybe<Array<Maybe<{ __typename?: 'Medicao'; valorImc?: Maybe<number> } & MedicoesFragmentFragment>>>
      procedimentosAtendimento?: Maybe<
        Array<
          Maybe<{
            __typename?: 'AtendimentoProcedimento'
            id: string
            obrigatorio?: Maybe<boolean>
            automatico?: Maybe<boolean>
            procedimento: {
              __typename?: 'Procedimento'
              id: string
              descricao: string
              codigo: string
              tipoProcedimento?: Maybe<TipoProcedimentoEnum>
            }
          }>
        >
      >
    } & HistoricoSubjetivoPecFragmentFragment &
      HistoricoDesfechoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type ImpressaoEscutaInicialQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type ImpressaoEscutaInicialQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<{
    __typename?: 'AtendimentoProfissional'
    id: string
    classificacaoRisco?: Maybe<ClassificacaoRisco>
    iniciadoEm: any
    motivoConsulta?: Maybe<{
      __typename?: 'EvolucaoSubjetivo'
      id: string
      texto?: Maybe<string>
      ciaps?: Maybe<
        Array<Maybe<{ __typename?: 'EvolucaoSubjetivoCiap'; id: string; ciap: { __typename?: 'Ciap' } & CiapFragment }>>
      >
    }>
    medicoes?: Maybe<Array<Maybe<{ __typename?: 'Medicao' } & MedicoesFragmentFragment>>>
    procedimentos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'AtendimentoProcedimento'
          automatico?: Maybe<boolean>
          _id: string
          procedimento: { __typename?: 'Procedimento'; id: string; descricao: string; codigo: string }
        }>
      >
    >
    atendimento?: Maybe<{
      __typename?: 'Atendimento'
      id: string
      isAgendado: boolean
      cidadao: {
        __typename?: 'CidadaoBasico'
        id: string
        cns?: Maybe<string>
        cpf?: Maybe<string>
        nome: string
        nomeSocial?: Maybe<string>
        dataNascimento?: Maybe<any>
        sexo: SexoEnum
        identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
      }
    }>
    lotacao: {
      __typename?: 'Lotacao'
      id: string
      profissional: {
        __typename?: 'Profissional'
        id: string
        nome: string
        numeroConselhoClasse?: Maybe<string>
        conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; sigla: string }>
        ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
      }
      municipio: {
        __typename?: 'Municipio'
        id: string
        nome: string
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
      }
      unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
      cbo: { __typename?: 'Cbo'; id: string; nome: string }
    }
  }>
}

export type HistoricoMarcadoresConsumoAlimentarQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoMarcadoresConsumoAlimentarQuery = {
  __typename?: 'Query'
  historicoConsumoAlimentarDetail?: Maybe<{
    __typename?: 'HistoricoConsumoAlimentarDetail'
    id: string
    subtitle: string
    questionario: string
    perguntasRespostas: Array<
      Maybe<{
        __typename?: 'PerguntasRespostasHistoricoConsumoAlimentar'
        pergunta: string
        respostas: Array<Maybe<string>>
      }>
    >
    perguntasRespostasOntem: Array<
      Maybe<{
        __typename?: 'PerguntasRespostasHistoricoConsumoAlimentar'
        pergunta: string
        respostas: Array<Maybe<string>>
      }>
    >
    dimensaoProfissional?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoCbo?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoUnidadeSaude?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoLocalAtendimento?: Maybe<{ __typename?: 'DimensaoLocalAtendimento'; id: string; descricao: string }>
  }>
}

export type HistoricoProcedimentoDwQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoProcedimentoDwQuery = {
  __typename?: 'Query'
  historicoProcedimentoDw?: Maybe<{
    __typename?: 'FatoProcedAtend'
    id: string
    procedimentos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'DimensaoProcedimento'
          id: string
          codigoProcedimento: string
          descricaoProcedimento: string
        }>
      >
    >
    dimensaoProfissional: { __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }
    dimensaoCbo: { __typename?: 'DimensaoCbo'; id: string; nome: string }
    dimensaoUnidadeSaude: { __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }
    dimensaoEquipe?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoLocalAtendimento?: Maybe<{ __typename?: 'DimensaoLocalAtendimento'; id: string; descricao: string }>
  }>
}

export type HistoricoProcedimentoPecQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoProcedimentoPecQuery = {
  __typename?: 'Query'
  historicoAtendimentoPec?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      quantidadeOrientacoes?: Maybe<number>
      evolucaoPlano?: Maybe<{
        __typename?: 'EvolucaoPlano'
        id: string
        descricao?: Maybe<string>
        intervencoesProcedimentos?: Maybe<
          Array<
            Maybe<{
              __typename?: 'EvolucaoPlanoCiap'
              id: string
              nota?: Maybe<string>
              ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
              procedimento?: Maybe<{ __typename?: 'Procedimento'; id: string; descricao: string; codigo: string }>
            }>
          >
        >
      }>
      planosDeCuidado?: Maybe<
        Array<{ __typename?: 'AtendimentoObservacaoPlanoCuidado'; id: string; descricao: string; dataDescricao: any }>
      >
    } & HistoricoSubjetivoPecFragmentFragment &
      HistoricoObjetivoPecFragmentFragment &
      HistoricoAntecedentesPecFragmentFragment &
      HistoricoDesfechoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type HistoricoVacinacaoDwQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoVacinacaoDwQuery = {
  __typename?: 'Query'
  historicoVacinacaoDw: {
    __typename?: 'FatoVacinacao'
    id: string
    gestante?: Maybe<boolean>
    puerpera?: Maybe<boolean>
    viajante?: Maybe<boolean>
    comunicanteHanseniase?: Maybe<boolean>
    dataRegistro: any
    fatosVacinacaoVacina?: Maybe<
      Array<{
        __typename?: 'FatoVacinacaoVacina'
        id: string
        nomeFabricante?: Maybe<string>
        nomeLote?: Maybe<string>
        isRegistroAnterior: boolean
        dataRegistroVacinaAplicada: any
        dataRegistro: any
        dimensaoImunobiologico: { __typename?: 'DimensaoImunobiologico'; id: string; nome: string }
        dimensaoEstrategiaVacinacao?: Maybe<{ __typename?: 'DimensaoEstrategiaVacinacao'; id: string; nome: string }>
        dimensaoDoseImunobiologico: { __typename?: 'DimensaoDoseImunobiologico'; id: string; nome: string }
      }>
    >
    dimensaoProfissional?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoCbo?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoUnidadeSaude?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoLocalAtendimento?: Maybe<{ __typename?: 'DimensaoLocalAtendimento'; id: string; descricao: string }>
  }
}

export type HistoricoVacinacaoPecQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoVacinacaoPecQuery = {
  __typename?: 'Query'
  historicoVacinacaoPec: {
    __typename?: 'AtendimentoVacinacao'
    id: string
    isGestante?: Maybe<boolean>
    isPuerpera?: Maybe<boolean>
    isViajante?: Maybe<boolean>
    isComunicanteHanseniase?: Maybe<boolean>
    atendimentoProfissional: {
      __typename?: 'AtendimentoProfissional'
      id: string
      finalizadoEm?: Maybe<any>
    } & HistoricoDesfechoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
    registrosVacinacao?: Maybe<
      Array<{
        __typename?: 'RegistroVacinacao'
        id: string
        isRegistroAnterior?: Maybe<boolean>
        isAplicadoExterior?: Maybe<boolean>
        dataAplicacao?: Maybe<any>
        observacoes?: Maybe<string>
        imunobiologico: { __typename?: 'Imunobiologico'; id: string; nome: string }
        estrategiaVacinacao?: Maybe<{ __typename?: 'EstrategiaVacinacao'; id: string; nome: string }>
        grupoAtendimento?: Maybe<{ __typename?: 'GrupoAtendimento'; id: string; nome: string }>
        tipoRegistroVacinacao: {
          __typename?: 'TipoRegistroVacinacao'
          id: string
          identificador: TipoRegistroVacinacaoEnum
        }
        doseImunobiologico: { __typename?: 'DoseImunobiologico'; id: string; nome: string }
        loteImunobiologico?: Maybe<{
          __typename?: 'LoteImunobiologico'
          id: string
          lote?: Maybe<string>
          validade?: Maybe<any>
          fabricante?: Maybe<{ __typename?: 'ImunobiologicoFabricante'; id: string; nome: string }>
        }>
        viaAdministracao?: Maybe<{ __typename?: 'ViaAdministracao'; id: string; nome: string }>
        localAplicacaoVacinacao?: Maybe<{ __typename?: 'LocalAplicacao'; id: string; nome: string }>
      }>
    >
  }
}

export type HistoricoVisitaDomiciliarQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoVisitaDomiciliarQuery = {
  __typename?: 'Query'
  historicoVisitaDomiciliar?: Maybe<{
    __typename?: 'FatoVisitaDomiciliar'
    id: string
    nuUuidFicha: string
    cpfCidadao?: Maybe<string>
    cnsCidadao?: Maybe<string>
    motivoVisita: Array<Maybe<string>>
    buscaAtiva: Array<Maybe<string>>
    acompanhamento: Array<Maybe<string>>
    controleAmbiental: Array<Maybe<string>>
    anotacoes?: Maybe<string>
    peso?: Maybe<number>
    altura?: Maybe<number>
    temperatura?: Maybe<string>
    medicaoGlicemia?: Maybe<string>
    medicaoPressaoArterial?: Maybe<string>
    stVisitaCompartilhada?: Maybe<boolean>
    dimensaoDesfechoVisita: {
      __typename?: 'DimensaoDesfechoVisita'
      id: string
      numeroIdentificador: string
      descricao: string
    }
    dimensaoTipoGlicemia?: Maybe<{ __typename?: 'DimensaoTipoGlicemia'; id: string; descricao: string }>
    dimensaoProfissional?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoCbo?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoUnidadeSaude?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
  }>
}

export type HistoricoZikaDetailQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type HistoricoZikaDetailQuery = {
  __typename?: 'Query'
  historicoZikaDetail?: Maybe<{
    __typename?: 'HistoricoZikaDetail'
    examesDeImagem: Array<
      Maybe<{ __typename?: 'ExameZika'; id: string; nome: string; resultado: string; dataRealizacao: any }>
    >
    outrosExames: Array<
      Maybe<{ __typename?: 'ExameZika'; id: string; nome: string; resultado: string; dataRealizacao: any }>
    >
    dimensaoProfissional?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
    dimensaoCbo?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string }>
    dimensaoUnidadeSaude?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
  }>
}

export type HistoricoRetificaAtendimentoMutationMutationVariables = {
  id: Scalars['ID']
}

export type HistoricoRetificaAtendimentoMutationMutation = { __typename?: 'Mutation'; retificaAtendimento: string }

export type DisponibilidadeDadosClinicosHistoricoQueryVariables = {}

export type DisponibilidadeDadosClinicosHistoricoQuery = {
  __typename?: 'Query'
  disponibilidadeDadosClinicosHistorico: {
    __typename?: 'DisponibilidadeDadosClinicosHistorico'
    statusProcessamento: boolean
    isVersaoMinimaOracle: boolean
  }
}

export type HasProblemasGestacaoQueryVariables = {
  id: Scalars['Long']
}

export type HasProblemasGestacaoQuery = { __typename?: 'Query'; hasProblemasGestacao?: Maybe<boolean> }

export type HasPuericulturaQueryVariables = {
  prontuarioId: Scalars['ID']
}

export type HasPuericulturaQuery = { __typename?: 'Query'; hasPuericultura: boolean }

export type LoadAntecedentesQueryVariables = {
  id: Scalars['ID']
}

export type LoadAntecedentesQuery = {
  __typename?: 'Query'
  antecedente?: Maybe<{
    __typename?: 'Antecedente'
    id: string
    observacoes?: Maybe<string>
    dataUltimaAtualizacaoPessoal?: Maybe<any>
    gestacoesPrevias?: Maybe<string>
    qtPartos?: Maybe<string>
    qtAbortos?: Maybe<string>
    qtCesareas?: Maybe<string>
    partosVaginais?: Maybe<string>
    partosDomiciliares?: Maybe<string>
    nascidosVivos?: Maybe<string>
    natimortos?: Maybe<string>
    obitoAntesPrimeiraSemana?: Maybe<string>
    obitoAposPrimeiraSemana?: Maybe<string>
    filhosVivos?: Maybe<string>
    recemNascidosAcima?: Maybe<string>
    recemNascidosAbaixo?: Maybe<string>
    dataUltimoParto?: Maybe<any>
    partoMenosDeUmAno?: Maybe<boolean>
    dataUltimaAtualizacaoGestacional?: Maybe<any>
    peso?: Maybe<string>
    altura?: Maybe<string>
    perimetroCefalico?: Maybe<string>
    apgarUm?: Maybe<string>
    apgarCinco?: Maybe<string>
    apgarDez?: Maybe<string>
    tipoGravidez?: Maybe<TipoGravidezEnum>
    tipoParto?: Maybe<TipoPartoEnum>
    idadeGestacionalSemanas?: Maybe<string>
    idadeGestacionalDias?: Maybe<string>
    dataUltimaAtualizacaoPuericultura?: Maybe<any>
    antecedentesFamiliaresCiap?: Maybe<
      Array<
        Maybe<{
          __typename?: 'AntecedentesFamiliaresCiap'
          idCiap: string
          codigoCiap: string
          descricao: string
          observacao?: Maybe<string>
        }>
      >
    >
    cirurgiasInternacoes?: Maybe<
      Array<
        Maybe<{
          __typename?: 'AntecedenteCirurgiasInternacoes'
          id: string
          descricao: string
          observacoes?: Maybe<string>
          dataCirurgiaInternacao?: Maybe<any>
        }>
      >
    >
  }>
}

export type AcompanhamentoIdosoCardQueryVariables = {
  acompanhamentoIdosoQueryInput: AcompanhamentoIdosoQueryInput
}

export type AcompanhamentoIdosoCardQuery = { __typename?: 'Query'; ultimaAvaliacaoMultidimensional?: Maybe<any> }

export type AcompanhamentoPuericulturaCardQueryVariables = {
  prontuarioId: Scalars['ID']
  dataReferencia: Scalars['LocalDate']
}

export type AcompanhamentoPuericulturaCardQuery = {
  __typename?: 'Query'
  ultimoAtendimentoPuericultura: {
    __typename?: 'AtendimentoPuericultura'
    id?: Maybe<string>
    dataInicio?: Maybe<any>
    nomeProfissional?: Maybe<string>
  }
  tipoAleitamentoMaterno: {
    __typename?: 'AleitamentoMaterno'
    id?: Maybe<string>
    tipo?: Maybe<TipoAleitamentoMaternoEnum>
    dataInicioAtendimento?: Maybe<any>
  }
  preNatalPartoNascimento?: Maybe<{
    __typename?: 'Antecedente'
    id: string
    tipoGravidez?: Maybe<TipoGravidezEnum>
    tipoParto?: Maybe<TipoPartoEnum>
    idadeGestacionalSemanas?: Maybe<string>
    idadeGestacionalDias?: Maybe<string>
    apgarUm?: Maybe<string>
    apgarCinco?: Maybe<string>
    apgarDez?: Maybe<string>
    peso?: Maybe<string>
    altura?: Maybe<string>
    perimetroCefalico?: Maybe<string>
  }>
  alteracoesFenotipicas?: Maybe<
    Array<{
      __typename?: 'AlteracaoFenotipica'
      id: string
      descricao: string
      status: StatusAvaliadoEnum
      dataAvaliacao?: Maybe<any>
    }>
  >
  fatoresRisco?: Maybe<
    Array<{
      __typename?: 'FatorRisco'
      id: string
      descricao: string
      status: StatusAvaliadoEnum
      dataAvaliacao?: Maybe<any>
    }>
  >
  marcosDesenvolvimento?: Maybe<
    Array<{
      __typename?: 'MarcoDesenvolvimento'
      id: string
      descricao: string
      hint?: Maybe<string>
      status: StatusAvaliadoEnum
      dataAvaliacao?: Maybe<any>
      alcancadoComMeses?: Maybe<number>
      alcancadoComAnos?: Maybe<number>
      faixaEtariaEnum?: Maybe<NeuroFaixaEtariaEnum>
      faixaEtariaFim?: Maybe<number>
      descricaoFaixaEtaria?: Maybe<string>
    }>
  >
  medicoesAnteriores: Array<{
    __typename?: 'Medicao'
    id: string
    dataMedicao: any
    valorVacinacaoEmDia?: Maybe<boolean>
    valorPerimetroCefalico?: Maybe<number>
  }>
  antecedente?: Maybe<{ __typename?: 'Antecedente'; id: string; perimetroCefalico?: Maybe<string> }>
}

export type AlergiasCardQueryVariables = {
  filtro: AlergiasQueryInput
  prontuarioId: Scalars['ID']
}

export type AlergiasCardQuery = {
  __typename?: 'Query'
  existemAlergiasResolvidasRefutadas: boolean
  alergias: {
    __typename?: 'AlergiaQueryPayload'
    content: Array<{
      __typename?: 'Alergia'
      id: string
      categoriaSubstanciaEspecificaLegado?: Maybe<CategoriaSubstanciaAlergiaLegado>
      substanciaEspecificaLegado?: Maybe<string>
      tipoReacao?: Maybe<TipoReacaoAlergia>
      criticidade: CriticidadeAlergia
      grauCerteza: GrauCertezaAlergia
      substanciaEspecifica?: Maybe<{ __typename?: 'SubstanciaEspecificaAlergia' } & SubstanciaEspecificaAlergiaFragment>
    }>
  }
}

export type LembretesCardQueryVariables = {
  lembretesQueryInput: LembretesQueryInput
}

export type LembretesCardQuery = {
  __typename?: 'Query'
  lembretes: Array<
    Maybe<{
      __typename?: 'Lembrete'
      id: string
      ultimoLembrete: {
        __typename?: 'LembreteEvolucao'
        id: string
        descricao: string
        ativo: boolean
        data: any
        lotacao: {
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
        }
      }
    }>
  >
}

export type ProblemasCardQueryVariables = {
  filtro: ProblemaQueryInput
}

export type ProblemasCardQuery = {
  __typename?: 'Query'
  problemas: {
    __typename?: 'ProblemaQueryPayload'
    content: Array<{ __typename?: 'Problema' } & ProblemaCondicaoFragment>
  }
}

export type MedicamentoUsoBarraLateralQueryVariables = {
  input: ListaMedicamentoAtivoConcluidoQueryInput
}

export type MedicamentoUsoBarraLateralQuery = {
  __typename?: 'Query'
  listaMedicamento: {
    __typename?: 'ListaMedicamentoAtivoConcluidoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'ReceitaMedicamento'
        id: string
        dtInicioTratamento?: Maybe<any>
        dtFimTratamento?: Maybe<any>
        usoContinuo?: Maybe<boolean>
        posologia: string
        medicamento: { __typename?: 'Medicamento'; id: string; principioAtivo: string; concentracao?: Maybe<string> }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type PossuiTratamentoConcluidoQueryVariables = {
  input: PossuiTratamentoConcluidoQueryInput
}

export type PossuiTratamentoConcluidoQuery = { __typename?: 'Query'; possuiTratamentoConcluido: boolean }

export type GrupoMedicoesCardFragment = {
  __typename?: 'GrupoMedicoesTipo'
  tipo: TipoMedicaoEnum
  medicoes: Array<{ __typename?: 'ItemGrupoMedicoesTipo'; dataMedicao: any; valor: string; observacao?: Maybe<string> }>
}

export type MedicoesCardQueryVariables = {
  prontuarioId: Scalars['ID']
  fetchMedicoesAnteriores: Scalars['Boolean']
  dataReferencia?: Maybe<Scalars['Instant']>
}

export type MedicoesCardQuery = {
  __typename?: 'Query'
  ultimasMedicoesDoDia: Array<{ __typename?: 'GrupoMedicoesTipo' } & GrupoMedicoesCardFragment>
  ultimasMedicoesDiasAnteriores: Array<{ __typename?: 'GrupoMedicoesTipo' } & GrupoMedicoesCardFragment>
  medicoesNoNascimento?: Maybe<{
    __typename?: 'Antecedente'
    id: string
    peso?: Maybe<string>
    altura?: Maybe<string>
    perimetroCefalico?: Maybe<string>
  }>
}

export type MedicoesFragmentFragment = {
  __typename?: 'Medicao'
  id: string
  valorPeso?: Maybe<number>
  valorAltura?: Maybe<number>
  valorFrequenciaCardiaca?: Maybe<number>
  valorPressaoArterial?: Maybe<string>
  valorPerimetroCefalico?: Maybe<number>
  valorFrequenciaRespiratoria?: Maybe<number>
  valorTemperatura?: Maybe<number>
  valorSaturacaoO2?: Maybe<number>
  valorGlicemia?: Maybe<number>
  tipoGlicemia?: Maybe<TipoGlicemia>
  perimetroPanturrilha?: Maybe<number>
  valorCircunferenciaAbdominal?: Maybe<number>
}

export type AcompanhamentoPreNatalCardQueryVariables = {
  input: Scalars['ID']
}

export type AcompanhamentoPreNatalCardQuery = {
  __typename?: 'Query'
  atendimentosProfUltimaGestacao?: Maybe<
    Array<
      Maybe<{
        __typename?: 'AtendimentoProfissional'
        id: string
        iniciadoEm: any
        atendimentoProfissionalPreNatal?: Maybe<{
          __typename?: 'AtendimentoProfissionalPreNatal'
          id: string
          preNatal?: Maybe<{
            __typename?: 'PreNatal'
            id: string
            altoRisco: boolean
            tipoGravidez?: Maybe<{ __typename?: 'TipoGravidez'; id: string; descricao?: Maybe<string> }>
          }>
        }>
        medicoes?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Medicao'
              id: string
              dataMedicao: any
              dum?: Maybe<any>
              medicaoAnterior?: Maybe<boolean>
            }>
          >
        >
        examesResultados?: Maybe<Array<Maybe<{ __typename?: 'ResultadoExame' } & ExamePreNatalFragment>>>
      }>
    >
  >
}

export type DataUltimaConsultaOdontoGestanteQueryVariables = {
  input: DataUltimaConsultaOdontoGestanteQueryInput
}

export type DataUltimaConsultaOdontoGestanteQuery = {
  __typename?: 'Query'
  dataUltimaConsultaOdontoGestante?: Maybe<any>
}

export type ResultadosExamePreNatalQueryVariables = {
  input: ResultadosExameQueryInput
}

export type ResultadosExamePreNatalQuery = {
  __typename?: 'Query'
  resultadosExame: {
    __typename?: 'ResultadosExamePayload'
    content: Array<Maybe<{ __typename?: 'ResultadoExame' } & ExamePreNatalFragment>>
  }
}

export type ResultadosExamesCardQueryVariables = {
  input: ResultadosExameQueryInput
}

export type ResultadosExamesCardQuery = {
  __typename?: 'Query'
  resultadosExame: {
    __typename?: 'ResultadosExamePayload'
    content: Array<
      Maybe<{
        __typename?: 'ResultadoExame'
        id: string
        resultado?: Maybe<string>
        dataSolicitacao?: Maybe<any>
        dataRealizacao?: Maybe<any>
        dataResultado?: Maybe<any>
        observacao?: Maybe<string>
        conferido?: Maybe<boolean>
        exame: { __typename?: 'Procedimento' } & ProcedimentoFragment
        especifico?: Maybe<{
          __typename?: 'ResultadoExameEspecifico'
          id: string
          exameEspecificoEnum: ExameEspecificoEnum
          valor?: Maybe<number>
          igDias?: Maybe<number>
          igSemanas?: Maybe<number>
          dpp?: Maybe<any>
          resultadoExamePuericulturaEnum?: Maybe<FichaComplementarZikaMicrocefaliaResultadoExameEnum>
        }>
        atendimentoProfissional?: Maybe<{ __typename?: 'AtendimentoProfissional'; id: string; iniciadoEm: any }>
      }>
    >
  }
}

export type LotacaoSugestaoAgendamentoFragment = {
  __typename?: 'Lotacao'
  id: string
  profissional: { __typename?: 'Profissional'; id: string; nome: string }
  cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
  equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
}

export type SugestaoDatasAgendamentoQueryVariables = {
  input: SugestoesDatasInput
}

export type SugestaoDatasAgendamentoQuery = {
  __typename?: 'Query'
  sugestoesDatas: { __typename?: 'SugestoesDatas'; preNatal: Array<any>; odontologica: Array<any> }
}

export type SugestaoProfissionaisAgendamentoQueryVariables = {
  prontuarioId: Scalars['ID']
}

export type SugestaoProfissionaisAgendamentoQuery = {
  __typename?: 'Query'
  sugestoesProfissionais: {
    __typename?: 'ProfissionaisSugestaoAgendamento'
    preNatal?: Maybe<{ __typename?: 'Lotacao' } & LotacaoSugestaoAgendamentoFragment>
    odontologico?: Maybe<{ __typename?: 'Lotacao' } & LotacaoSugestaoAgendamentoFragment>
  }
}

export type PreNatalEmAtendimentoObservacaoQueryVariables = {
  id: Scalars['ID']
}

export type PreNatalEmAtendimentoObservacaoQuery = {
  __typename?: 'Query'
  atendimentoIndividual: {
    __typename?: 'AtendimentoProfissional'
    id: string
    medicoes?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Medicao'
          id: string
          valorAlturaUterina?: Maybe<string>
          valorBatimentoCardiacoFetal?: Maybe<string>
          medicaoAnterior?: Maybe<boolean>
        }>
      >
    >
    atendimentoProfissionalPreNatal?: Maybe<{
      __typename?: 'AtendimentoProfissionalPreNatal'
      id: string
      gravidezPlanejada?: Maybe<boolean>
      movimentacaoFetal?: Maybe<boolean>
      tipoEdema?: Maybe<TipoEdemaEnum>
      preNatal?: Maybe<{
        __typename?: 'PreNatal'
        id: string
        altoRisco: boolean
        tipoGravidez?: Maybe<{
          __typename?: 'TipoGravidez'
          id: string
          descricao?: Maybe<string>
          tipoGravidez?: Maybe<TipoGravidezEnum>
        }>
      }>
    }>
  }
}

export type PuericulturaQueryVariables = {
  prontuarioId: Scalars['ID']
  dataReferencia: Scalars['LocalDate']
}

export type PuericulturaQuery = {
  __typename?: 'Query'
  hasPuericultura: boolean
  alteracoesFenotipicas?: Maybe<
    Array<{
      __typename?: 'AlteracaoFenotipica'
      id: string
      descricao: string
      status: StatusAvaliadoEnum
      dataAvaliacao?: Maybe<any>
    }>
  >
  fatoresRisco?: Maybe<
    Array<{
      __typename?: 'FatorRisco'
      id: string
      descricao: string
      status: StatusAvaliadoEnum
      dataAvaliacao?: Maybe<any>
    }>
  >
  marcosDesenvolvimento?: Maybe<
    Array<{
      __typename?: 'MarcoDesenvolvimento'
      id: string
      descricao: string
      hint?: Maybe<string>
      status: StatusAvaliadoEnum
      dataAvaliacao?: Maybe<any>
      alcancadoComMeses?: Maybe<number>
      alcancadoComAnos?: Maybe<number>
      faixaEtariaEnum?: Maybe<NeuroFaixaEtariaEnum>
      faixaEtariaFim?: Maybe<number>
      descricaoFaixaEtaria?: Maybe<string>
    }>
  >
  medicoesAnteriores: Array<{
    __typename?: 'Medicao'
    id: string
    dataMedicao: any
    valorPerimetroCefalico?: Maybe<number>
  }>
  antecedente?: Maybe<{ __typename?: 'Antecedente'; id: string; perimetroCefalico?: Maybe<string> }>
}

export type SolicitacoesExamesPagedQueryVariables = {
  input: SolicitacoesExameQueryInput
}

export type SolicitacoesExamesPagedQuery = {
  __typename?: 'Query'
  solicitacoesExamePaged: {
    __typename?: 'SolicitacoesExamePayload'
    content: Array<{
      __typename?: 'SolicitacaoExame'
      id: string
      data: any
      tipoExame: TipoExameEnum
      observacoes?: Maybe<string>
      justificativa: string
      exames: Array<{
        __typename?: 'ResultadoExame'
        id: string
        conferido?: Maybe<boolean>
        observacao?: Maybe<string>
        solicitacao?: Maybe<{ __typename?: 'SolicitacaoExame'; id: string; data: any }>
        exame: { __typename?: 'Procedimento' } & ProcedimentoFragment
        atendimentoProfissional?: Maybe<{ __typename?: 'AtendimentoProfissional'; id: string }>
      }>
      cid10?: Maybe<{ __typename?: 'Cid10'; sexo?: Maybe<string> } & Cid10Fragment>
      atendimentoProfissional: {
        __typename?: 'AtendimentoProfissional'
        id: string
        iniciadoEm: any
        lotacao: {
          __typename?: 'Lotacao'
          id: string
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
          unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type SolicitacoesExameUnpagedQueryVariables = {
  input: SolicitacoesExameQueryInput
}

export type SolicitacoesExameUnpagedQuery = {
  __typename?: 'Query'
  solicitacoesExame: {
    __typename?: 'SolicitacoesExamePayload'
    content: Array<{ __typename?: 'SolicitacaoExame' } & SolicitacaoExameFragment>
  }
}

export type AtestadoModelosLoadQueryVariables = {}

export type AtestadoModelosLoadQuery = {
  __typename?: 'Query'
  atestadoModelos: Array<Maybe<{ __typename?: 'AtestadoModelo'; id: string; nome: string }>>
}

export type LoadAtestadoByIdQueryVariables = {
  id: Scalars['ID']
}

export type LoadAtestadoByIdQuery = {
  __typename?: 'Query'
  atestado: {
    __typename?: 'Atestado'
    id: string
    descricao?: Maybe<string>
    tipo?: Maybe<TipoAtestadoEnum>
    atendimentoProfissional: {
      __typename?: 'AtendimentoProfissional'
      id: string
      iniciadoEm: any
      lotacao: {
        __typename?: 'Lotacao'
        id: string
        profissional: {
          __typename?: 'Profissional'
          id: string
          nome: string
          numeroConselhoClasse?: Maybe<string>
          conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; sigla: string }>
          ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
        }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
        cbo: { __typename?: 'Cbo'; id: string; nome: string }
      }
    }
  }
}

export type LoadAtestadosQueryVariables = {
  input: AtestadoQueryInput
}

export type LoadAtestadosQuery = {
  __typename?: 'Query'
  atestados: {
    __typename?: 'AtestadoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Atestado'
        id: string
        assinadoDigitalmente: boolean
        atendimentoProfissional: {
          __typename?: 'AtendimentoProfissional'
          id: string
          iniciadoEm: any
          lotacao: {
            __typename?: 'Lotacao'
            id: string
            profissional: {
              __typename?: 'Profissional'
              id: string
              nome: string
              numeroConselhoClasse?: Maybe<string>
              conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; sigla: string }>
              ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
            }
            cbo: { __typename?: 'Cbo'; id: string; nome: string }
            unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
            municipio: {
              __typename?: 'Municipio'
              id: string
              nome: string
              uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
            }
          }
        }
      }>
    >
  }
}

export type AuditaImpressaoAtestadoMutationVariables = {
  prontuarioId: Scalars['ID']
  atendimentoId?: Maybe<Scalars['ID']>
  atestadoId?: Maybe<Scalars['ID']>
}

export type AuditaImpressaoAtestadoMutation = { __typename?: 'Mutation'; auditoriaImpressaoAtestado?: Maybe<string> }

export type AtestadoModeloByIdQueryVariables = {
  id: Scalars['ID']
}

export type AtestadoModeloByIdQuery = {
  __typename?: 'Query'
  atestadoModelo: { __typename?: 'AtestadoModelo'; id: string; nome: string; descricao: string }
}

export type AtestadoModeloDeleteMutationVariables = {
  id: Scalars['ID']
}

export type AtestadoModeloDeleteMutation = { __typename?: 'Mutation'; excluirAtestadoModelo: string }

export type AtestadoModeloSaveMutationVariables = {
  input: AtestadoModeloInput
}

export type AtestadoModeloSaveMutation = {
  __typename?: 'Mutation'
  salvarAtestadoModelo: { __typename?: 'AtestadoModelo'; id: string }
}

export type CompartilhamentoCuidadoPlanoQueryVariables = {
  input: CompartilhamentosCuidadoPlanoQueryInput
}

export type CompartilhamentoCuidadoPlanoQuery = {
  __typename?: 'Query'
  compartilhamentosCuidadoPlano: Array<{
    __typename?: 'CompartilhamentoCuidadoPlanoDto'
    id: string
    dataInicio: any
    classificacaoPrioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
    discussao: string
    cidadaoAceitaAtendTic?: Maybe<boolean>
    isEncaminhamento: boolean
    lotacaoSolicitante: {
      __typename?: 'Lotacao'
      cbo: { __typename?: 'Cbo'; id: string; nome: string }
    } & CompartilhamentoCuidadoLotacaoFragment
    lotacaoExecutanteAtual?: Maybe<{ __typename?: 'Lotacao' } & CompartilhamentoCuidadoLotacaoFragment>
    cbo: { __typename?: 'Cbo'; id: string; nome: string }
    unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
    cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
    ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
  }>
}

export type CompartilhamentoCuidadoLotacaoFragment = {
  __typename?: 'Lotacao'
  id: string
  profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string; cns?: Maybe<string> }
}

export type AcessoHasVinculacaoEquipeNasfQueryVariables = {}

export type AcessoHasVinculacaoEquipeNasfQuery = { __typename?: 'Query'; acessoHasVinculacaoEquipeNasf: boolean }

export type EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables = {
  input: EstabelecimentosCuidadoCompartilhadoByCboIdQueryInput
}

export type EstabelecimentosCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  estabelecimentosCuidadoCompartilhado: {
    __typename?: 'UnidadeSaudePayload'
    content: Array<
      Maybe<{ __typename?: 'UnidadeSaude'; id: string; cnes: string; nome?: Maybe<string>; ativo?: Maybe<boolean> }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CuidadosCompartilhadoPlanoQueryVariables = {
  input: CuidadosCompartilhadoQueryInput
}

export type CuidadosCompartilhadoPlanoQuery = {
  __typename?: 'Query'
  cuidadosCompartilhado: {
    __typename?: 'CuidadosCompartilhadoPayload'
    content: Array<{
      __typename?: 'CuidadoCompartilhado'
      id: string
      iniciadoEm: any
      discussao: string
      cidadaoAceitaAtendTic: boolean
      classificacaoPrioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
      lotacaoExecutanteAtual?: Maybe<{
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
      }>
      cboExecutanteAtual: { __typename?: 'Cbo'; id: string; nome: string }
      lotacaoSolicitante: {
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
        cbo: { __typename?: 'Cbo'; id: string; nome: string }
      }
      cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
      ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
    }>
  }
}

export type AuditGerarDeclaracaoComparecimentoMutationVariables = {
  atendimento: Scalars['ID']
}

export type AuditGerarDeclaracaoComparecimentoMutation = {
  __typename?: 'Mutation'
  auditGerarDeclaracaoComparecimento?: Maybe<boolean>
}

export type AtendimentoProfissionalEncaminhamentoByIdQueryVariables = {
  atendimentoProfissionalId: Scalars['ID']
}

export type AtendimentoProfissionalEncaminhamentoByIdQuery = {
  __typename?: 'Query'
  atendimentoIndividual: {
    __typename?: 'AtendimentoProfissional'
    id: string
    lotacao: {
      __typename?: 'Lotacao'
      id: string
      profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string; cns?: Maybe<string> }
      cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    }
  }
}

export type EncaminhamentoEspecializadoByIdQueryVariables = {
  id: Scalars['ID']
}

export type EncaminhamentoEspecializadoByIdQuery = {
  __typename?: 'Query'
  encaminhamentoEspecializado: {
    __typename?: 'EncaminhamentoExternoEspecializado'
    id: string
    classificacaoRiscoEncaminhamento: ClassificacaoRiscoEncaminhamentoEnum
    complemento?: Maybe<string>
    motivoEncaminhamento?: Maybe<string>
    observacoes?: Maybe<string>
    atendimentoProfissional: {
      __typename?: 'AtendimentoProfissional'
      id: string
      iniciadoEm: any
      lotacao: {
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string; cns?: Maybe<string> }
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
      }
    }
    especialidadeSisreg: { __typename?: 'EspecialidadeSisreg'; id: string; descricao: string }
    hipoteseDiagnosticoCiap?: Maybe<{ __typename?: 'Ciap'; id: string; descricao: string; codigo: string }>
    hipoteseDiagnosticoCid10?: Maybe<{ __typename?: 'Cid10'; id: string; nome: string; codigo: string }>
  }
}

export type EncaminhamentosExternosEspecializadosQueryVariables = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
}

export type EncaminhamentosExternosEspecializadosQuery = {
  __typename?: 'Query'
  encaminhamentosEspecializados: {
    __typename?: 'EncaminhamentoExternoEspecializadoQueryPayload'
    content: Array<
      Maybe<{ __typename?: 'EncaminhamentoExternoEspecializado' } & EncaminhamentoExternoEspecializadoFragment>
    >
  }
}

export type EncaminhamentosExternosQueryVariables = {
  prontuarioId: Scalars['ID']
  editAtendProfId?: Maybe<Scalars['ID']>
}

export type EncaminhamentosExternosQuery = {
  __typename?: 'Query'
  encaminhamentos: Array<{
    __typename?: 'EncaminhamentoExterno'
    tipo: TipoEncaminhamentoExternoEnum
    atendimentoProfissional: {
      __typename?: 'AtendimentoProfissional'
      id: string
      iniciadoEm: any
      lotacao: { __typename?: 'Lotacao'; id: string; profissional: { __typename?: 'Profissional'; id: string } }
    }
  }>
}

export type PartesBucaisQueryVariables = {
  input: PartesBucaisQueryInput
}

export type PartesBucaisQuery = {
  __typename?: 'Query'
  partesBucais: {
    __typename?: 'PartesBucaisPayload'
    content: Array<
      Maybe<{ __typename?: 'ParteBucal'; id: string; descricao?: Maybe<string>; parteBucalEnum: ParteBucalEnum }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type OdontogramaProcedimentosQueryVariables = {
  input: EvolucoesProcedimentoOdontoInput
}

export type OdontogramaProcedimentosQuery = {
  __typename?: 'Query'
  evolucoesProcedimentoOdonto: {
    __typename?: 'EvolucaoProcedimentoPayload'
    content?: Maybe<
      Array<
        Maybe<{
          __typename?: 'EvolucaoProcedimentoOdonto'
          id: string
          descricaoParteBucal?: Maybe<string>
          observacao?: Maybe<string>
          atendimentoProfissionalOdonto: { __typename?: 'AtendimentoProfissionalOdonto'; id: string }
          partesBucais?: Maybe<
            Array<{
              __typename?: 'ParteBucal'
              id: string
              tipoParteBucal: TipoParteBucalEnum
              descricao?: Maybe<string>
            }>
          >
          procedimentos?: Maybe<Array<{ __typename?: 'Procedimento' } & ProcedimentoFragment>>
        }>
      >
    >
  }
}

export type OdontogramaQueryVariables = {
  id: Scalars['ID']
}

export type OdontogramaQuery = {
  __typename?: 'Query'
  odontograma: {
    __typename?: 'Odontograma'
    id: string
    proteseTotalInferior?: Maybe<boolean>
    proteseTotalSuperior?: Maybe<boolean>
    contencao?: Maybe<boolean>
    aparelho?: Maybe<boolean>
    evolucoesDente?: Maybe<
      Array<
        Maybe<{
          __typename?: 'EvolucaoDente'
          id: string
          situacoesFace?: Maybe<Array<SituacaoFaceEnum>>
          situacoesRaiz?: Maybe<Array<SituacaoRaizEnum>>
          situacaoCoroaCima?: Maybe<SituacaoCoroaEnum>
          situacaoCoroaBaixo?: Maybe<SituacaoCoroaEnum>
          situacaoCoroaEsquerda?: Maybe<SituacaoCoroaEnum>
          situacaoCoroaDireita?: Maybe<SituacaoCoroaEnum>
          situacaoCoroaCentro?: Maybe<SituacaoCoroaEnum>
          dente: { __typename?: 'Dente'; id: string; numero?: Maybe<number> }
        }>
      >
    >
  }
}

export type OdontogramasQueryVariables = {
  input: OdontogramasQueryInput
}

export type OdontogramasQuery = {
  __typename?: 'Query'
  odontogramas: {
    __typename?: 'OdontogramasPayload'
    content: Array<
      Maybe<{
        __typename?: 'Odontograma'
        id: string
        proteseTotalSuperior?: Maybe<boolean>
        proteseTotalInferior?: Maybe<boolean>
        contencao?: Maybe<boolean>
        aparelho?: Maybe<boolean>
        evolucoesDente?: Maybe<
          Array<
            Maybe<{
              __typename?: 'EvolucaoDente'
              id: string
              situacoesRaiz?: Maybe<Array<SituacaoRaizEnum>>
              situacoesFace?: Maybe<Array<SituacaoFaceEnum>>
              situacaoCoroaCima?: Maybe<SituacaoCoroaEnum>
              situacaoCoroaDireita?: Maybe<SituacaoCoroaEnum>
              situacaoCoroaEsquerda?: Maybe<SituacaoCoroaEnum>
              situacaoCoroaBaixo?: Maybe<SituacaoCoroaEnum>
              situacaoCoroaCentro?: Maybe<SituacaoCoroaEnum>
              dente: { __typename?: 'Dente'; id: string; numero?: Maybe<number> }
            }>
          >
        >
      }>
    >
  }
}

export type PeriodoOdontogramaSelectQueryVariables = {
  input: OdontogramasQueryInput
}

export type PeriodoOdontogramaSelectQuery = {
  __typename?: 'Query'
  odontogramas: {
    __typename?: 'OdontogramasPayload'
    content: Array<
      Maybe<{
        __typename?: 'Odontograma'
        id: string
        atendimentoProfissionalOdonto: {
          __typename?: 'AtendimentoProfissionalOdonto'
          id: string
          atendimentoProfissional: {
            __typename?: 'AtendimentoProfissional'
            id: string
            finalizadoEm?: Maybe<any>
            iniciadoEm: any
          }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type HistoricoEvolucoesOdontoTableQueryVariables = {
  input: EvolucoesProcedimentoOdontoInput
}

export type HistoricoEvolucoesOdontoTableQuery = {
  __typename?: 'Query'
  evolucoesProcedimentoOdonto: {
    __typename?: 'EvolucaoProcedimentoPayload'
    content?: Maybe<
      Array<
        Maybe<{
          __typename?: 'EvolucaoProcedimentoOdonto'
          id: string
          observacao?: Maybe<string>
          descricaoParteBucal?: Maybe<string>
          atendimentoProfissionalOdonto: {
            __typename?: 'AtendimentoProfissionalOdonto'
            id: string
            atendimentoProfissional: {
              __typename?: 'AtendimentoProfissional'
              id: string
              iniciadoEm: any
              lotacao: {
                __typename?: 'Lotacao'
                id: string
                profissional: { __typename?: 'Profissional'; id: string; nome: string }
                cbo: { __typename?: 'Cbo'; id: string; nome: string }
              }
            }
          }
          procedimentos?: Maybe<Array<{ __typename?: 'Procedimento'; id: string; codigo: string; descricao: string }>>
          partesBucais?: Maybe<
            Array<{
              __typename?: 'ParteBucal'
              id: string
              tipoParteBucal: TipoParteBucalEnum
              descricao?: Maybe<string>
              parteBucalEnum: ParteBucalEnum
            }>
          >
        }>
      >
    >
    pageInfo?: Maybe<{ __typename?: 'PageInfo' } & PageInfoFragment>
  }
}

export type PeriogramasCompletoQueryVariables = {
  input: PeriogramaCompletoQueryInput
}

export type PeriogramasCompletoQuery = {
  __typename?: 'Query'
  periogramasCompleto: Array<
    Maybe<{
      __typename?: 'PeriogramaCompleto'
      id: string
      excluido: boolean
      profundidadeVestibular?: Maybe<string>
      recessaoVestibular?: Maybe<string>
      profundidadeInterna?: Maybe<string>
      recessaoInterna?: Maybe<string>
      placaDVestibular?: Maybe<boolean>
      placaVVestibular?: Maybe<boolean>
      placaMVestibular?: Maybe<boolean>
      sangramentoDVestibular?: Maybe<boolean>
      sangramentoVVestibular?: Maybe<boolean>
      sangramentoMVestibular?: Maybe<boolean>
      placaDInterna?: Maybe<boolean>
      placaVInterna?: Maybe<boolean>
      placaMInterna?: Maybe<boolean>
      sangramentoDInterna?: Maybe<boolean>
      sangramentoVInterna?: Maybe<boolean>
      sangramentoMInterna?: Maybe<boolean>
      mobilidade?: Maybe<string>
      lesaoDeFurca?: Maybe<string>
      dente: { __typename?: 'ParteBucal'; id: string; parteBucalEnum: ParteBucalEnum }
      atendimentoProfissionalOdonto: {
        __typename?: 'AtendimentoProfissionalOdonto'
        id: string
        observacaoRPC?: Maybe<string>
        atendimentoProfissional: {
          __typename?: 'AtendimentoProfissional'
          id: string
          iniciadoEm: any
          lotacao: {
            __typename?: 'Lotacao'
            id: string
            cbo: { __typename?: 'Cbo'; id: string; nome: string }
            profissional: { __typename?: 'Profissional'; id: string; nome: string }
          }
        }
      }
    }>
  >
}

export type HistoricoPeriogramaSimplificadoQueryVariables = {
  input: PeriogramaSimplificadoQueryInput
}

export type HistoricoPeriogramaSimplificadoQuery = {
  __typename?: 'Query'
  periogramasSimplificados: {
    __typename?: 'PeriogramaSimplificadoPayload'
    content: Array<
      Maybe<{
        __typename?: 'PeriogramaSimplificado'
        id: string
        avaliacaoSextante1: string
        avaliacaoSextante2: string
        avaliacaoSextante3: string
        avaliacaoSextante4: string
        avaliacaoSextante5: string
        avaliacaoSextante6: string
        observacao?: Maybe<string>
        atendimentoProfissionalOdonto: {
          __typename?: 'AtendimentoProfissionalOdonto'
          id: string
          atendimentoProfissional: {
            __typename?: 'AtendimentoProfissional'
            id: string
            iniciadoEm: any
            lotacao: {
              __typename?: 'Lotacao'
              id: string
              cbo: { __typename?: 'Cbo'; id: string; nome: string }
              profissional: { __typename?: 'Profissional'; id: string; nome: string }
            }
          }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type HistoricoTecidosMolesQueryVariables = {
  input: TecidosMolesQueryInput
}

export type HistoricoTecidosMolesQuery = {
  __typename?: 'Query'
  tecidosMoles: {
    __typename?: 'TecidosMolesPayload'
    content?: Maybe<
      Array<
        Maybe<{
          __typename?: 'TecidoMole'
          id: string
          descricaoSintomatologia?: Maybe<string>
          parteBucal: { __typename?: 'ParteBucal'; id: string; descricao?: Maybe<string> }
          cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
          caracteristicasLesao?: Maybe<
            Array<{ __typename?: 'CaracteristicaLesao'; id: string; caracteristicaLesao: CaracteristicaLesaoEnum }>
          >
          evolucaoProcedimentoOdonto?: Maybe<{
            __typename?: 'EvolucaoProcedimentoOdonto'
            id: string
            observacao?: Maybe<string>
            procedimentos?: Maybe<Array<{ __typename?: 'Procedimento'; id: string; codigo: string; descricao: string }>>
          }>
          atendimentoProfissionalOdonto: {
            __typename?: 'AtendimentoProfissionalOdonto'
            id: string
            atendimentoProfissional: {
              __typename?: 'AtendimentoProfissional'
              id: string
              iniciadoEm: any
              lotacao: {
                __typename?: 'Lotacao'
                id: string
                profissional: { __typename?: 'Profissional'; id: string; nome: string }
                cbo: { __typename?: 'Cbo'; id: string; nome: string }
              }
            }
          }
        }>
      >
    >
    pageInfo?: Maybe<{ __typename?: 'PageInfo' } & PageInfoFragment>
  }
}

export type GruposExamesQueryVariables = {
  input: GrupoExameQueryInput
}

export type GruposExamesQuery = {
  __typename?: 'Query'
  gruposExame: {
    __typename?: 'GruposExameQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'GrupoExame'
        id: string
        nome: string
        idadeMinima?: Maybe<number>
        idadeMaxima?: Maybe<number>
        procedimentos?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Procedimento'
              id: string
              descricao: string
              codigo: string
              idadeMinima?: Maybe<number>
              idadeMaxima?: Maybe<number>
              sexo?: Maybe<SexoEnum>
              procedimentoReferencia?: Maybe<{
                __typename?: 'Procedimento'
                id: string
                descricao: string
                codigo: string
              }>
            }>
          >
        >
      }>
    >
  }
}

export type HistoricoPlanosDeCuidadoQueryVariables = {
  atendProfId: Scalars['ID']
}

export type HistoricoPlanosDeCuidadoQuery = {
  __typename?: 'Query'
  planosDeCuidado: Array<{
    __typename?: 'AtendimentoObservacaoPlanoCuidado'
    id: string
    descricao: string
    dataDescricao: any
    atendimentoProfissionalObservacao: {
      __typename?: 'AtendimentoProfissionalObservacao'
      id: string
      atendimentoProfissional: {
        __typename?: 'AtendimentoProfissional'
        id: string
        lotacao: {
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
        }
      }
      atendimentoObservacao: {
        __typename?: 'AtendimentoObservacao'
        id: string
        atendimentoProfissionalIniciador: {
          __typename?: 'AtendimentoProfissional'
          id: string
          iniciadoEm: any
          lotacao: {
            __typename?: 'Lotacao'
            id: string
            profissional: { __typename?: 'Profissional'; id: string; nome: string }
          }
        }
      }
    }
  }>
}

export type OrientacoesQueryVariables = {
  orientacaoQueryInput: OrientacaoQueryInput
}

export type OrientacoesQuery = {
  __typename?: 'Query'
  orientacoes: Array<
    Maybe<{
      __typename?: 'Orientacao'
      id: string
      descricao: string
      atendimentoProfissional: {
        __typename?: 'AtendimentoProfissional'
        id: string
        iniciadoEm: any
        lotacao: {
          __typename?: 'Lotacao'
          id: string
          profissional: {
            __typename?: 'Profissional'
            id: string
            nome: string
            numeroConselhoClasse?: Maybe<string>
            conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; sigla: string }>
            ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
          }
          municipio: {
            __typename?: 'Municipio'
            id: string
            nome: string
            uf: { __typename?: 'UF'; id: string; sigla: string }
          }
          unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
        }
      }
    }>
  >
}

export type AuditaImpressaoOrientacaoMutationVariables = {
  prontuarioId: Scalars['ID']
  atendimentoId?: Maybe<Scalars['ID']>
  orientacaoId?: Maybe<Scalars['ID']>
}

export type AuditaImpressaoOrientacaoMutation = { __typename?: 'Mutation'; auditaImpressaoOrientacao?: Maybe<string> }

export type HistoricoPrescricaoQueryVariables = {
  input: HistoricoPrescricaoQueryInput
}

export type HistoricoPrescricaoQuery = {
  __typename?: 'Query'
  historicoPrescricao: {
    __typename?: 'HistoricoPrescricaoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'AtendimentoProfissional'
        id: string
        versao?: Maybe<string>
        versaoAnterior?: Maybe<boolean>
        finalizadoEm?: Maybe<any>
        lotacao: {
          __typename?: 'Lotacao'
          id: string
          profissional: {
            __typename?: 'Profissional'
            id: string
            nome: string
            numeroConselhoClasse?: Maybe<string>
            ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; sigla: string }>
            conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; sigla: string }>
          }
        }
        receitaMedicamento?: Maybe<
          Array<
            Maybe<{
              __typename?: 'ReceitaMedicamento'
              id: string
              quantidadeReceitada: number
              recomendacoes?: Maybe<string>
              dose?: Maybe<string>
              qtDose?: Maybe<string>
              qtDoseManha?: Maybe<string>
              qtDoseTarde?: Maybe<string>
              qtDoseNoite?: Maybe<string>
              frequenciaDose?: Maybe<string>
              posologia: string
              tipoFrequenciaDose?: Maybe<TipoFrequencia>
              qtPeriodoFrequencia?: Maybe<number>
              medidaTempoFrequencia?: Maybe<UnidadeMedidaTempoEnum>
              dtInicioTratamento?: Maybe<any>
              dtFimTratamento?: Maybe<any>
              qtDuracaoTratamento?: Maybe<number>
              medidaTempoTratamento?: Maybe<UnidadeMedidaTempoEnum>
              usoContinuo?: Maybe<boolean>
              registroManual?: Maybe<boolean>
              doseUnica?: Maybe<boolean>
              tipoReceita?: Maybe<TipoReceitaEnum>
              tratamentoInterrompido?: Maybe<boolean>
              justificativaInterrupcao?: Maybe<string>
              dtInterrupcao?: Maybe<any>
              medicamento: {
                __typename?: 'Medicamento'
                id: string
                principioAtivo: string
                concentracao?: Maybe<string>
                unidadeFornecimento: string
                medicamentoCatmat?: Maybe<{
                  __typename?: 'MedicamentoCatmat'
                  id: string
                  ativo: boolean
                  volume?: Maybe<string>
                  codigoCatMat?: Maybe<string>
                  codigoRename?: Maybe<string>
                  unidadeFornecimento: { __typename?: 'UnidadeMedida'; id: string; nome: string; nomePlural: string }
                  principioAtivo: {
                    __typename?: 'PrincipioAtivo'
                    id: string
                    nome: string
                    listaMaterial: {
                      __typename?: 'ListaMedicamento'
                      id: string
                      tipo: string
                      tipoReceita: TipoReceitaEnum
                    }
                  }
                }>
                formaFarmaceutica?: Maybe<{ __typename?: 'FormaFarmaceutica'; id: string; nome: string }>
              }
              aplicacaoMedicamento: { __typename?: 'AplicacaoMedicamento'; id: string; nome: string }
              unidadeMedidaDose?: Maybe<{ __typename?: 'UnidadeMedida'; id: string; nome: string; nomePlural: string }>
            }>
          >
        >
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ConexaoHorusQueryVariables = {}

export type ConexaoHorusQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
    horus: { __typename?: 'ConfiguracaoHorus'; id: string; habilitado: boolean }
  }
}

export type ConsultaEstoqueHorusQueryVariables = {
  filter: ConsultaEstoqueHorusQueryInput
}

export type ConsultaEstoqueHorusQuery = { __typename?: 'Query'; consultaEstoqueHorus: string }

export type CategoriasProibidasQueryVariables = {}

export type CategoriasProibidasQuery = { __typename?: 'Query'; categoriasProibidas: Array<ListaMedicamentoEnum> }

export type CidadaoEmailUpdateMutationVariables = {
  input: AtualizarEmailCidadaoInput
}

export type CidadaoEmailUpdateMutation = {
  __typename?: 'Mutation'
  atualizarEmailCidadao: { __typename?: 'ContatoCidadao'; id: string; email?: Maybe<string> }
}

export type CidadaoInformacoesContatoQueryVariables = {
  id: Scalars['ID']
}

export type CidadaoInformacoesContatoQuery = {
  __typename?: 'Query'
  contatoCidadao: { __typename?: 'ContatoCidadao'; id: string; email?: Maybe<string> }
}

export type TiposReceitaProibidosQueryVariables = {}

export type TiposReceitaProibidosQuery = { __typename?: 'Query'; tiposReceitaProibidos: Array<TipoReceitaEnum> }

export type CidPrimeiroAtendimentoPreNatalQueryVariables = {
  prontuarioId: Scalars['ID']
}

export type CidPrimeiroAtendimentoPreNatalQuery = {
  __typename?: 'Query'
  cidPrimeiroAtendimentoPreNatal?: Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>
}

export type IdadeGestacionalQueryVariables = {
  input: IdadeGestacionalQueryInput
}

export type IdadeGestacionalQuery = {
  __typename?: 'Query'
  idadeGestacional?: Maybe<{
    __typename?: 'IdadeGestacionalAcompanhamentoPreNatal'
    dias: number
    isEcografica: boolean
  }>
}

export type EscutaInicialSaveMutationVariables = {
  input: EscutaInicialInput
}

export type EscutaInicialSaveMutation = {
  __typename?: 'Mutation'
  salvarEscutaInicial: { __typename?: 'AtendimentoProfissional'; id: string }
}

export type EscutaInicialViewQueryVariables = {
  atendimentoId: Scalars['ID']
}

export type EscutaInicialViewQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    statusAtendimento: StatusAtendimento
    iniciadoEm: any
    isAgendado: boolean
    cidadao: {
      __typename?: 'CidadaoBasico'
      id: string
      cns?: Maybe<string>
      cpf?: Maybe<string>
      nome: string
      nomeSocial?: Maybe<string>
      dataNascimento?: Maybe<any>
      sexo: SexoEnum
      identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
    }
    prontuario: {
      __typename?: 'Prontuario'
      id: string
      gestacoes?: Maybe<Array<{ __typename?: 'PeriodoGestacao'; id: string; inicio: any; fim?: Maybe<any> }>>
      preNatalAtivo?: Maybe<{ __typename?: 'PreNatal'; id: string; ultimaDum?: Maybe<any> }>
    }
    tiposServico?: Maybe<Array<Maybe<{ __typename?: 'TipoServico'; id: string }>>>
    atendimentoProfissional?: Maybe<{
      __typename?: 'AtendimentoProfissional'
      id: string
      tipo: TipoAtendimentoProfissional
      iniciadoEm: any
      rascunho?: Maybe<string>
      estagio?: Maybe<{ __typename?: 'Estagio'; id: string }>
      lotacao: {
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; cpf: string; cns?: Maybe<string>; nome: string }
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
    }>
  }
  procedimentosAutomaticos: Array<Maybe<{ __typename?: 'Procedimento' } & ProcedimentoFragment>>
}

export type SalvarEscutaInicialParaAprovacaoMutationVariables = {
  input: EscutaInicialAprovacaoInput
}

export type SalvarEscutaInicialParaAprovacaoMutation = {
  __typename?: 'Mutation'
  salvarEscutaInicialParaAprovacao: boolean
}

export type EscutaInicialDetailQueryVariables = {
  input: HistoricoDetailQueryInput
}

export type EscutaInicialDetailQuery = {
  __typename?: 'Query'
  escutaInicialDetail?: Maybe<
    {
      __typename?: 'AtendimentoProfissional'
      id: string
      classificacaoRisco?: Maybe<ClassificacaoRisco>
      evolucaoSubjetivo?: Maybe<{
        __typename?: 'EvolucaoSubjetivo'
        id: string
        acompanhadoEspecialidade?: Maybe<string>
      }>
      medicoes?: Maybe<Array<Maybe<{ __typename?: 'Medicao'; valorImc?: Maybe<number> } & MedicoesFragmentFragment>>>
      procedimentosAtendimento?: Maybe<
        Array<
          Maybe<{
            __typename?: 'AtendimentoProcedimento'
            id: string
            obrigatorio?: Maybe<boolean>
            automatico?: Maybe<boolean>
            procedimento: {
              __typename?: 'Procedimento'
              id: string
              descricao: string
              codigo: string
              tipoProcedimento?: Maybe<TipoProcedimentoEnum>
            }
          }>
        >
      >
    } & HistoricoSubjetivoPecFragmentFragment &
      HistoricoDesfechoPecFragmentFragment &
      HistoricoInfoAdministrativasPecFragmentFragment
  >
}

export type EscutaInicialVisualizarQueryVariables = {
  atendimentoId: Scalars['ID']
}

export type EscutaInicialVisualizarQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    isAgendado: boolean
    cidadao: { __typename?: 'CidadaoBasico'; id: string }
    escutaInicialAtendProf?: Maybe<{ __typename?: 'AtendimentoProfissional'; id: string; iniciadoEm: any }>
  }
}

export type CiapFragment = { __typename?: 'Ciap'; id: string; codigo: string; descricao: string }

export type Cid10Fragment = { __typename?: 'Cid10'; id: string; codigo: string; nome: string }

export type EquipeAtendimentoQueryVariables = {
  id: Scalars['ID']
}

export type EquipeAtendimentoQuery = {
  __typename?: 'Query'
  equipe: {
    __typename?: 'Equipe'
    id: string
    nome: string
    ine: string
    tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
  }
}

export type ExcluirAtendimentoMutationVariables = {
  atendimento: Scalars['ID']
}

export type ExcluirAtendimentoMutation = { __typename?: 'Mutation'; excluirAtendimento: string }

export type ListaAtendimentoPollingQueryVariables = {
  time: Scalars['Instant']
}

export type ListaAtendimentoPollingQuery = { __typename?: 'Query'; atendimentoPolling: boolean }

export type ListaAtendimentoPollingEnabledQueryVariables = {}

export type ListaAtendimentoPollingEnabledQuery = { __typename?: 'Query'; atendimentoPollingEnabled: boolean }

export type ListaAtendimentoQueryVariables = {
  input: AtendimentosQueryInput
}

export type ListaAtendimentoQuery = {
  __typename?: 'Query'
  atendimentos: {
    __typename?: 'AtendimentosPayload'
    content: Array<
      Maybe<{
        __typename?: 'Atendimento'
        id: string
        prontuarioId: string
        iniciadoEm: any
        encaminhadoEm: any
        statusAtendimento: StatusAtendimento
        statusRevisaoAtendimento?: Maybe<StatusRevisaoAtendimento>
        classificacaoRisco?: Maybe<ClassificacaoRisco>
        ultimaAlteracaoStatusEm: any
        hasTagNaoFinalizado: boolean
        cidadao: {
          __typename?: 'CidadaoBasico'
          id: string
          nome: string
          nomeSocial?: Maybe<string>
          dataNascimento?: Maybe<any>
        }
        agendado?: Maybe<{
          __typename?: 'Agendado'
          id: string
          horarioInicial?: Maybe<any>
          situacao: SituacaoAgendadoEnum
          lotacaoAgendada: {
            __typename?: 'Lotacao'
            id: string
            profissional: { __typename?: 'Profissional'; id: string; nome: string }
          }
          lotacaoParticipante?: Maybe<{
            __typename?: 'Lotacao'
            id: string
            profissional: { __typename?: 'Profissional'; id: string; nome: string }
          }>
          videochamada?: Maybe<{ __typename?: 'Videochamada'; id: string }>
        }>
        tiposServico?: Maybe<Array<Maybe<{ __typename?: 'TipoServico'; id: string; nome: string }>>>
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; area?: Maybe<string> }>
        responsavel?: Maybe<
          | {
              __typename?: 'Lotacao'
              id: string
              profissional: { __typename?: 'Profissional'; id: string; nome: string }
              cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
            }
          | {
              __typename?: 'Estagio'
              id: string
              profissional: { __typename?: 'Profissional'; id: string; nome: string }
              cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
            }
        >
        actions: {
          __typename?: 'AtendimentoActions'
          atender?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          revisarRetificar?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          escutaInicial?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          vacinacao?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          informarAusencia?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          cancelarAusencia?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          visualizarProntuario?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          visualizarEscutaInicial?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          editar?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          excluir?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          atendimentosDia?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          declaracaoComparecimento?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          cancelarRegistro?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
          tornarResponsavelObservacao?: Maybe<{ __typename?: 'Action'; hint?: Maybe<string>; enabled: boolean }>
        }
        ultimoResponsavelObservacao?: Maybe<{ __typename?: 'Lotacao' } & ResponsavelAtendimentoObservacaoFragment>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type PossuiAtendimentosNaoFinalizadosQueryVariables = {
  input: PossuiAtendimentosNaoFinalizadosInput
}

export type PossuiAtendimentosNaoFinalizadosQuery = {
  __typename?: 'Query'
  possuiAtendimentosNaoFinalizados?: Maybe<boolean>
}

export type SalvarAtendimentoMutationVariables = {
  input: AtendimentoInput
}

export type SalvarAtendimentoMutation = {
  __typename?: 'Mutation'
  salvarAtendimento: {
    __typename?: 'Atendimento'
    id: string
    cidadao: { __typename?: 'CidadaoBasico'; id: string; nome: string; nomeSocial?: Maybe<string> }
  }
}

export type SalvarJustificativaAutomaticaVisualizarEscutaInicialMutationVariables = {
  prontuarioId: Scalars['ID']
}

export type SalvarJustificativaAutomaticaVisualizarEscutaInicialMutation = {
  __typename?: 'Mutation'
  salvarJustificativaAutomaticaVisualizarEscutaInicial: string
}

export type TornarLotacaoAtualResponsavelAtendimentoObservacaoMutationVariables = {
  atendimentoId: Scalars['ID']
}

export type TornarLotacaoAtualResponsavelAtendimentoObservacaoMutation = {
  __typename?: 'Mutation'
  tornarLotacaoAtualResponsavelAtendimentoObservacao: string
}

export type AtenderMutationVariables = {
  atendimento: Scalars['ID']
}

export type AtenderMutation = {
  __typename?: 'Mutation'
  realizarAtendimentoIndividual: {
    __typename?: 'Atendimento'
    id: string
    statusAtendimento: StatusAtendimento
    cidadao: { __typename?: 'CidadaoBasico'; id: string }
    atendimentoProfissional?: Maybe<{ __typename?: 'AtendimentoProfissional'; id: string }>
  }
}

export type CancelarAusenciaMutationVariables = {
  atendimento: Scalars['ID']
}

export type CancelarAusenciaMutation = {
  __typename?: 'Mutation'
  cancelarCidadaoNaoAguardou: { __typename?: 'Atendimento'; id: string }
}

export type InformarAusenciaMutationVariables = {
  atendimento: Scalars['ID']
}

export type InformarAusenciaMutation = {
  __typename?: 'Mutation'
  informarCidadaoNaoAguardou: { __typename?: 'Atendimento'; id: string }
}

export type RealizarAtendimentoVacinacaoMutationVariables = {
  atendimento: Scalars['ID']
}

export type RealizarAtendimentoVacinacaoMutation = {
  __typename?: 'Mutation'
  realizarAtendimentoVacinacao: {
    __typename?: 'Atendimento'
    id: string
    cidadao: { __typename?: 'CidadaoBasico'; id: string }
    atendimentoProfissional?: Maybe<{ __typename?: 'AtendimentoProfissional'; id: string }>
  }
}

export type RealizarEscutaInicialMutationVariables = {
  atendimento: Scalars['ID']
}

export type RealizarEscutaInicialMutation = {
  __typename?: 'Mutation'
  realizarEscutaInicial: {
    __typename?: 'Atendimento'
    id: string
    cidadao: { __typename?: 'CidadaoBasico'; id: string }
    atendimentoProfissional?: Maybe<{ __typename?: 'AtendimentoProfissional'; id: string }>
  }
}

export type AtendimentosDoDiaQueryVariables = {
  id: Scalars['ID']
}

export type AtendimentosDoDiaQuery = {
  __typename?: 'Query'
  atendimentosDoDia: {
    __typename?: 'AtendimentosProfissionalPayload'
    content: Array<
      Maybe<{
        __typename?: 'AtendimentoProfissional'
        id: string
        iniciadoEm: any
        finalizadoEm?: Maybe<any>
        tipo: TipoAtendimentoProfissional
        lotacao: {
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
        }
        atendimento?: Maybe<{
          __typename?: 'Atendimento'
          id: string
          iniciadoEm: any
          dataCriacaoRegistro?: Maybe<any>
          cidadao: {
            __typename?: 'CidadaoBasico'
            id: string
            nome: string
            cpf?: Maybe<string>
            cns?: Maybe<string>
            dataNascimento?: Maybe<any>
            nomeSocial?: Maybe<string>
          }
        }>
      }>
    >
  }
}

export type CidadaoDeclaracaoComparecimentoQueryVariables = {
  id: Scalars['ID']
}

export type CidadaoDeclaracaoComparecimentoQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    dataCriacaoRegistro?: Maybe<any>
    iniciadoEm: any
    isRegistroTardio: boolean
    responsavel?: Maybe<
      | { __typename?: 'Lotacao'; id: string; profissional: { __typename?: 'Profissional'; id: string; nome: string } }
      | { __typename?: 'Estagio'; id: string; profissional: { __typename?: 'Profissional'; id: string; nome: string } }
    >
    cidadao: {
      __typename?: 'CidadaoBasico'
      id: string
      cpf?: Maybe<string>
      cns?: Maybe<string>
      dataNascimento?: Maybe<any>
      nome: string
      nomeSocial?: Maybe<string>
    }
    unidadeSaude: {
      __typename?: 'UnidadeSaude'
      id: string
      nome?: Maybe<string>
      endereco?: Maybe<{
        __typename?: 'Endereco'
        municipio?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
      }>
    }
  }
}

export type AtendimentoQueryVariables = {
  id: Scalars['ID']
}

export type AtendimentoQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    agendado?: Maybe<{ __typename?: 'Agendado'; id: string }>
    cidadao: {
      __typename?: 'CidadaoBasico'
      id: string
      nome: string
      nomeSocial?: Maybe<string>
      dataNascimento?: Maybe<any>
      sexo: SexoEnum
      racaCor: {
        __typename?: 'RacaCor'
        id: string
        nome: string
        codigoMS: string
        codigoCadsus: string
        racaCorDbEnum: RacaCorDbEnum
      }
    }
    responsavel?: Maybe<
      | ({
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        } & ResponsavelAtendimentoLotacaoFragment)
      | ({
          __typename?: 'Estagio'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        } & ResponsavelAtendimentoEstagioFragment)
    >
    equipe?: Maybe<{
      __typename?: 'Equipe'
      id: string
      ine: string
      nome: string
      tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
    }>
    tiposServico?: Maybe<Array<Maybe<{ __typename?: 'TipoServico'; id: string; nome: string }>>>
  }
}

export type ResponsavelAtendimentoCommon_Lotacao_Fragment = {
  __typename?: 'Lotacao'
  unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
  equipe?: Maybe<{
    __typename?: 'Equipe'
    id: string
    ine: string
    nome: string
    isAD: boolean
    tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
  }>
}

export type ResponsavelAtendimentoCommon_Estagio_Fragment = {
  __typename?: 'Estagio'
  unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
  equipe?: Maybe<{
    __typename?: 'Equipe'
    id: string
    ine: string
    nome: string
    isAD: boolean
    tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
  }>
}

export type ResponsavelAtendimentoCommonFragment =
  | ResponsavelAtendimentoCommon_Lotacao_Fragment
  | ResponsavelAtendimentoCommon_Estagio_Fragment

export type ResponsavelAtendimentoLotacaoFragment = {
  __typename?: 'Lotacao'
  cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
} & ResponsavelAtendimentoCommon_Lotacao_Fragment

export type ResponsavelAtendimentoEstagioFragment = {
  __typename?: 'Estagio'
  cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
} & ResponsavelAtendimentoCommon_Estagio_Fragment

export type EditarAtendimentoMutationVariables = {
  input: AtendimentoEditInput
}

export type EditarAtendimentoMutation = {
  __typename?: 'Mutation'
  editarAtendimento: {
    __typename?: 'Atendimento'
    id: string
    cidadao: { __typename?: 'CidadaoBasico'; id: string; nome: string; nomeSocial?: Maybe<string> }
  }
}

export type AuditoriaHistoricoTableQueryVariables = {
  input: AuditoriaHistoricoQueryInput
}

export type AuditoriaHistoricoTableQuery = {
  __typename?: 'Query'
  auditoriaHistorico: {
    __typename?: 'AuditoriaHistoricoPayload'
    content: Array<
      Maybe<{
        __typename?: 'AuditoriaHistorico'
        id: string
        dataInicio: any
        dataFim: any
        dataSolicitacao: any
        status: StatusProcessoAuditoria
        csv?: Maybe<boolean>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ProcessarAuditoriaMutationVariables = {
  input: AuditoriaInput
}

export type ProcessarAuditoriaMutation = { __typename?: 'Mutation'; processAuditoria?: Maybe<boolean> }

export type AutorizarMunicipioConfigAgendaPadraoQueryVariables = {}

export type AutorizarMunicipioConfigAgendaPadraoQuery = {
  __typename?: 'Query'
  duracaoAtendimentoPadraoMunicipio?: Maybe<{
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }>
  configuracaoHorariosMunicipio: {
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }
}

export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables = {
  duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput
  configuracaoAgendaMunicipalInput?: Maybe<ConfiguracaoAgendaMunicipalInput>
}

export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation = {
  __typename?: 'Mutation'
  alterarDuracaoPadraoAtendimento: {
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }
  salvarConfiguracaoHorariosMunicipio?: Maybe<{
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }>
}

export type SaveAutorizarMunicipioMutationVariables = {
  input: AutorizarMunicipioInput
}

export type SaveAutorizarMunicipioMutation = {
  __typename?: 'Mutation'
  autorizarMunicipio: { __typename?: 'AdministradorMunicipal'; id: string; autorizado: boolean }
}

export type AutorizarMunicipioQueryVariables = {
  acessoId: Scalars['ID']
}

export type AutorizarMunicipioQuery = {
  __typename?: 'Query'
  acesso:
    | {
        __typename: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
    | {
        __typename: 'Estagio'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
    | {
        __typename: 'AdministradorGeral'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
      }
    | {
        __typename: 'AdministradorMunicipal'
        autorizado: boolean
        chaveMunicipio: string
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
    | {
        __typename: 'GestorEstadual'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
      }
    | {
        __typename: 'GestorMunicipal'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
}

export type ConcluirConfiguracaoMunicipioMutationVariables = {}

export type ConcluirConfiguracaoMunicipioMutation = {
  __typename?: 'Mutation'
  concluirConfiguracaoMunicipio: { __typename?: 'AdministradorMunicipal'; id: string; habilitado: boolean }
}

export type BuscaAtivaVacinacaoQueryVariables = {
  input: BuscaAtivaVacinacaoInput
}

export type BuscaAtivaVacinacaoQuery = {
  __typename?: 'Query'
  buscaAtivaVacinacao: {
    __typename?: 'BuscaAtivaVacinacaoPayload'
    content: Array<{
      __typename?: 'BuscaAtivaVacinacaoDto'
      idCidadao: string
      nomeSocialOuNomeCidadao: string
      cpfCidadao?: Maybe<string>
      cnsCidadao?: Maybe<string>
      dataNascimentoCidadao?: Maybe<any>
      telefoneResidencial?: Maybe<string>
      telefoneCelular?: Maybe<string>
      telefoneContato?: Maybe<string>
      endereco?: Maybe<string>
      vacinasAtrasadas?: Maybe<
        Array<
          Maybe<{
            __typename?: 'BuscaAtivaVacinaImuno'
            cidadaoId: string
            nome?: Maybe<string>
            siglaDose?: Maybe<string>
          }>
        >
      >
      vacinasNoPrazo?: Maybe<
        Array<
          Maybe<{
            __typename?: 'BuscaAtivaVacinaImuno'
            cidadaoId: string
            nome?: Maybe<string>
            siglaDose?: Maybe<string>
          }>
        >
      >
      vacinasAplicadas?: Maybe<
        Array<
          Maybe<{
            __typename?: 'BuscaAtivaVacinaImuno'
            cidadaoId: string
            nome?: Maybe<string>
            siglaDose?: Maybe<string>
            dataAplicacaoVacina?: Maybe<any>
          }>
        >
      >
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CidadaosCadastroImovelQueryVariables = {
  input: CidadaosQueryInput
}

export type CidadaosCadastroImovelQuery = {
  __typename?: 'Query'
  cidadaos: {
    __typename?: 'CidadaosPayload'
    content: Array<
      Maybe<{
        __typename?: 'Cidadao'
        id: string
        cns?: Maybe<string>
        cpf?: Maybe<string>
        dataNascimento?: Maybe<any>
        nome: string
        nomeSocial?: Maybe<string>
        sexo: SexoEnum
        faleceu?: Maybe<boolean>
        telefoneCelular?: Maybe<string>
        telefoneContato?: Maybe<string>
        telefoneResidencial?: Maybe<string>
        nomeMae?: Maybe<string>
        nomePai?: Maybe<string>
        desconheceNomePai?: Maybe<boolean>
        desconheceNomeMae?: Maybe<boolean>
        dataNaturalizacao?: Maybe<any>
        portariaNaturalizacao?: Maybe<string>
        dataEntradaBrasil?: Maybe<any>
        dataObito?: Maybe<any>
        numeroDocumentoObito?: Maybe<string>
        racaCor: { __typename?: 'RacaCor'; id: string; racaCorDbEnum: RacaCorDbEnum }
        etnia?: Maybe<{ __typename?: 'Etnia'; id: string }>
        nacionalidade?: Maybe<{ __typename?: 'Nacionalidade'; id: string; nacionalidadeDbEnum: NacionalidadeEnum }>
        paisNascimento?: Maybe<{ __typename?: 'Pais'; id: string }>
        localidadeNascimento?: Maybe<{ __typename?: 'Municipio'; id: string; uf: { __typename?: 'UF'; id: string } }>
      }>
    >
  }
}

export type ImovelCadastroQueryVariables = {
  input: Scalars['ID']
}

export type ImovelCadastroQuery = {
  __typename?: 'Query'
  imovel?: Maybe<{
    __typename?: 'ImovelDto'
    id: string
    tipoEndereco?: Maybe<TipoEnderecoEnum>
    complemento?: Maybe<string>
    pontoReferencia?: Maybe<string>
    cep?: Maybe<string>
    telefoneContato?: Maybe<string>
    telefoneResidencial?: Maybe<string>
    microarea?: Maybe<string>
    numero?: Maybe<string>
    bairro?: Maybe<string>
    logradouro?: Maybe<string>
    tipoImovel?: Maybe<TipoImovelEnum>
    numeroMoradores?: Maybe<number>
    nomeInstituicaoPermanencia?: Maybe<string>
    possuiOutrosProfissionaisVinculados?: Maybe<boolean>
    numeroResidenciaIndigena?: Maybe<string>
    statusMicroareaPoloBase?: Maybe<boolean>
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string; isAD: boolean }>
    profissional: { __typename?: 'Profissional'; id: string; nome: string; cns?: Maybe<string>; cpf: string }
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>
    uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
    municipio?: Maybe<{ __typename?: 'Municipio'; id: string; nome: string; ibge?: Maybe<string> }>
    condicoesMoradia: {
      __typename?: 'CondicoesMoradia'
      id: string
      tipoAbastecimentoAgua?: Maybe<TipoAbastecimentoAguaEnum>
      tipoSituacaoMoradia?: Maybe<TipoSituacaoMoradiaEnum>
      tipoTratamentoAgua?: Maybe<TipoTratamentoAguaEnum>
      tipoPosseTerra?: Maybe<TipoPosseTerraEnum>
      tipoMaterialParede?: Maybe<TipoMaterialParedeEnum>
      tipoLocalizacao?: Maybe<TipoLocalizacaoEnum>
      tipoEscoamentoSanitario?: Maybe<TipoEscoamentoSanitarioEnum>
      tipoDomicilio?: Maybe<TipoDomicilioEnum>
      tipoDestinoLixo?: Maybe<TipoDestinoLixoEnum>
      tipoAcessoDomicilio?: Maybe<TipoAcessoDomicilioEnum>
      isEnergiaEletricaDisponivel?: Maybe<boolean>
      tipoOrigemEnergiaEletrica?: Maybe<TipoOrigemEnergiaEletricaEnum>
      numeroComodos?: Maybe<number>
    }
    animaisImovel?: Maybe<{
      __typename?: 'AnimaisImovel'
      id?: Maybe<any>
      possuiAnimais?: Maybe<boolean>
      possuiGato?: Maybe<boolean>
      possuiCachorro?: Maybe<boolean>
      possuiPassaro?: Maybe<boolean>
      possuiMacaco?: Maybe<boolean>
      possuiGalinha?: Maybe<boolean>
      possuiPorco?: Maybe<boolean>
      possuiRepteis?: Maybe<boolean>
      possuiOutros?: Maybe<boolean>
      quantidadeAnimais?: Maybe<number>
    }>
    responsavelTecnico?: Maybe<{
      __typename?: 'ResponsavelTecnicoImovel'
      id: string
      nome: string
      contato?: Maybe<string>
      cns?: Maybe<string>
      cargo?: Maybe<string>
    }>
    familias: Array<{
      __typename?: 'Familia'
      id: string
      numeroProntuarioFamiliar?: Maybe<string>
      numeroMembros?: Maybe<number>
      rendaFamiliar?: Maybe<RendaFamiliar>
      resideDesde?: Maybe<any>
      cpfCnsResponsavel?: Maybe<string>
      isResponsavelCadastrado: boolean
      isResponsavelDeclarado: boolean
      cidadaos: Array<{
        __typename?: 'CidadaoTerritorio'
        id: string
        nome?: Maybe<string>
        nomeSocial?: Maybe<string>
        sexo?: Maybe<SexoEnum>
        cpf?: Maybe<string>
        cns?: Maybe<string>
        dataNascimento?: Maybe<any>
      }>
    }>
    aldeia?: Maybe<{
      __typename?: 'Aldeia'
      id: string
      nome: string
      poloBase: { __typename?: 'PoloBase' } & PoloBaseFragment
      municipio: Array<{ __typename?: 'Municipio' } & MunicipioFragment>
    }>
    poloBase?: Maybe<{ __typename?: 'PoloBase' } & PoloBaseFragment>
    dsei?: Maybe<{ __typename?: 'Dsei'; id: string; nome: string }>
    municipioAldeia?: Maybe<{ __typename?: 'Municipio' } & MunicipioFragment>
    ufAldeia?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
  }>
}

export type SalvarImovelMutationVariables = {
  input: ImovelInput
}

export type SalvarImovelMutation = { __typename?: 'Mutation'; salvarImovel: boolean }

export type AlterarDuracaoCboMutationVariables = {
  cboDuracaoForm: CboDuracaoFormInput
}

export type AlterarDuracaoCboMutation = {
  __typename?: 'Mutation'
  alterarDuracaoCbo?: Maybe<{ __typename?: 'CboMunicipio'; cbo: { __typename?: 'Cbo'; id: string } }>
}

export type DisponibilizarCboMutationVariables = {
  cboDisponibilizarForm: CboDisponibilizarFormInput
}

export type DisponibilizarCboMutation = {
  __typename?: 'Mutation'
  disponibilizarCbo?: Maybe<{ __typename?: 'CboMunicipio'; cbo: { __typename?: 'Cbo'; id: string } }>
}

export type CboEditComponentQueryVariables = {}

export type CboEditComponentQuery = {
  __typename?: 'Query'
  conexao: { __typename?: 'ConfiguracaoConexao'; agendaOnline?: Maybe<{ __typename?: 'AgendaOnline'; ativa: boolean }> }
}

export type CboTableQueryVariables = {
  input: CbosQueryInput
}

export type CboTableQuery = {
  __typename?: 'Query'
  cbos: {
    __typename?: 'CbosQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'CboMunicipio'
        disponivelLotacao?: Maybe<boolean>
        duracao?: Maybe<number>
        duracaoPadrao?: Maybe<number>
        possuiLotacaoComAgendaOnline?: Maybe<boolean>
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        actions: {
          __typename?: 'CboMunicipioActions'
          inativar: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type AtualizarContatoCidadaoMutationVariables = {
  input: AtualizarContatoCidadaoInput
}

export type AtualizarContatoCidadaoMutation = {
  __typename?: 'Mutation'
  atualizarContatoCidadao: { __typename?: 'ContatoCidadao'; id: string }
}

export type BuscaCidadaoCabecalhoQueryVariables = {
  id: Scalars['ID']
}

export type BuscaCidadaoCabecalhoQuery = {
  __typename?: 'Query'
  cidadao: {
    __typename?: 'Cidadao'
    id: string
    cpf?: Maybe<string>
    cns?: Maybe<string>
    nome: string
    nomeSocial?: Maybe<string>
    dataNascimento?: Maybe<any>
    dataAtualizado?: Maybe<any>
    sexo: SexoEnum
    nomeMae?: Maybe<string>
    telefoneResidencial?: Maybe<string>
    telefoneCelular?: Maybe<string>
    telefoneContato?: Maybe<string>
    identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
    ativo?: Maybe<boolean>
    faleceu?: Maybe<boolean>
    possuiAgendamento?: Maybe<boolean>
    unificado?: Maybe<boolean>
    prontuario?: Maybe<{ __typename?: 'Prontuario'; id: string }>
    cidadaoVinculacaoEquipe?: Maybe<{
      __typename?: 'CidadaoVinculacaoEquipe'
      id?: Maybe<string>
      tpCdsOrigem?: Maybe<CdsOrigemEnum>
      unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
      equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
    }>
  }
}

export type BuscaCidadaoCadsusPollingQueryVariables = {
  uuid: Scalars['String']
}

export type BuscaCidadaoCadsusPollingQuery = {
  __typename?: 'Query'
  buscaCidadaosCadsusCompletoPolling?: Maybe<{
    __typename?: 'BuscaCidadaosCadsusResultDto'
    usuario: string
    uuid: string
    resultCadsus?: Maybe<SincronizacaoCadsusResultEnum>
    cidadaos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'CidadaoCadsus'
          cpf?: Maybe<string>
          cns?: Maybe<string>
          nome: string
          nomeSocial?: Maybe<string>
          nomeMae?: Maybe<string>
          nomePai?: Maybe<string>
          telefoneContato?: Maybe<Array<Maybe<string>>>
          telefoneResidencial?: Maybe<Array<Maybe<string>>>
          telefoneCelular?: Maybe<Array<Maybe<string>>>
          dataNascimento?: Maybe<any>
          dataObito?: Maybe<any>
          obito?: Maybe<string>
          sexo?: Maybe<SexoEnum>
          email?: Maybe<string>
          portariaNaturalizacao?: Maybe<string>
          dataEntradaBrasil?: Maybe<any>
          dataNaturalizacao?: Maybe<any>
          municipioResidenciaExterior?: Maybe<string>
          numeroPisPasep?: Maybe<string>
          racaCor?: Maybe<{ __typename?: 'RacaCor'; id: string; nome: string; racaCorDbEnum: RacaCorDbEnum }>
          etnia?: Maybe<{ __typename?: 'Etnia'; id: string; nome: string }>
          nacionalidade?: Maybe<{ __typename?: 'Nacionalidade'; id: string; nacionalidadeDbEnum: NacionalidadeEnum }>
          municipioNascimento?: Maybe<{
            __typename?: 'Municipio'
            id: string
            ibge?: Maybe<string>
            nome: string
            uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
          }>
          paisNascimento?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
          endereco?: Maybe<{
            __typename?: 'Endereco'
            bairro?: Maybe<string>
            cep?: Maybe<string>
            complemento?: Maybe<string>
            logradouro?: Maybe<string>
            numero?: Maybe<string>
            pontoReferencia?: Maybe<string>
            semNumero?: Maybe<boolean>
            municipio?: Maybe<{
              __typename?: 'Municipio'
              id: string
              ibge?: Maybe<string>
              nome: string
              uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
            }>
            tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>
            uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
          }>
          paisResidencia?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
        }>
      >
    >
  }>
}

export type BuscaDetailCidadaoQueryVariables = {
  id: Scalars['ID']
}

export type BuscaDetailCidadaoQuery = {
  __typename?: 'Query'
  cidadao: {
    __typename?: 'Cidadao'
    id: string
    cpf?: Maybe<string>
    cns?: Maybe<string>
    nisPisPasep?: Maybe<string>
    nome: string
    nomeSocial?: Maybe<string>
    dataNascimento?: Maybe<any>
    dataAtualizado?: Maybe<any>
    dataObito?: Maybe<any>
    numeroDocumentoObito?: Maybe<string>
    sexo: SexoEnum
    nomeMae?: Maybe<string>
    nomePai?: Maybe<string>
    telefoneResidencial?: Maybe<string>
    telefoneCelular?: Maybe<string>
    telefoneContato?: Maybe<string>
    email?: Maybe<string>
    area?: Maybe<string>
    microArea?: Maybe<string>
    localidadeExterior?: Maybe<string>
    identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
    ativo?: Maybe<boolean>
    faleceu?: Maybe<boolean>
    possuiAgendamento?: Maybe<boolean>
    unificado?: Maybe<boolean>
    unificacaoBase?: Maybe<string>
    tipoSanguineo?: Maybe<TipoSanguineoEnum>
    orientacaoSexualDbEnum?: Maybe<OrientacaoSexualEnum>
    portariaNaturalizacao?: Maybe<string>
    dataNaturalizacao?: Maybe<any>
    dataEntradaBrasil?: Maybe<any>
    stCompartilhaProntuario?: Maybe<boolean>
    numeroFamilia?: Maybe<string>
    tipoEndereco?: Maybe<TipoEnderecoEnum>
    tipoLocalNascimento?: Maybe<TipoLocalNascimentoEnum>
    endereco?: Maybe<{
      __typename?: 'Endereco'
      cep?: Maybe<string>
      bairro?: Maybe<string>
      logradouro?: Maybe<string>
      numero?: Maybe<string>
      semNumero?: Maybe<boolean>
      complemento?: Maybe<string>
      pontoReferencia?: Maybe<string>
      uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string }>
      municipio?: Maybe<{ __typename?: 'Municipio'; id: string; nome: string }>
      tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string }>
    }>
    prontuario?: Maybe<{
      __typename?: 'Prontuario'
      id: string
      puerpera?: Maybe<boolean>
      gestacoes?: Maybe<Array<{ __typename?: 'PeriodoGestacao'; id: string; inicio: any; fim?: Maybe<any> }>>
      preNatalAtivo?: Maybe<{ __typename?: 'PreNatal'; id: string; ultimaDum?: Maybe<any> }>
    }>
    etnia?: Maybe<{ __typename?: 'Etnia'; id: string; nome: string }>
    racaCor: { __typename?: 'RacaCor'; id: string; nome: string; racaCorDbEnum: RacaCorDbEnum }
    cbo?: Maybe<{ __typename?: 'Cbo'; id: string; nome: string }>
    escolaridade?: Maybe<{ __typename?: 'Escolaridade'; id: string; nome: string }>
    localidadeNascimento?: Maybe<{
      __typename?: 'Municipio'
      id: string
      nome: string
      uf: { __typename?: 'UF'; id: string; sigla: string }
    }>
    cidadaoVinculacaoEquipe?: Maybe<{
      __typename?: 'CidadaoVinculacaoEquipe'
      id?: Maybe<string>
      tpCdsOrigem?: Maybe<CdsOrigemEnum>
      utilizarCadastroIndividual?: Maybe<boolean>
      unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
      equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
    }>
    estadoCivil?: Maybe<{ __typename?: 'EstadoCivil'; id: string; nome: string }>
    paisExterior?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
    nacionalidade?: Maybe<{ __typename?: 'Nacionalidade'; id: string; nacionalidadeDbEnum: NacionalidadeEnum }>
    paisNascimento?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
    periodoAusenciaList?: Maybe<
      Array<Maybe<{ __typename?: 'PeriodoAusencia'; id: string; dtAusencia: any; dtRetorno?: Maybe<any> }>>
    >
    cidadaoAldeado?: Maybe<{
      __typename?: 'CidadaoAldeado'
      id: string
      beneficios?: Maybe<Array<Maybe<BeneficioDbEnum>>>
      nomeTradicional?: Maybe<string>
      responsavelLegal?: Maybe<string>
      stChefeFamilia: boolean
      unidadeFunai?: Maybe<string>
      livro?: Maybe<number>
      folha?: Maybe<number>
      cadastroUnico?: Maybe<string>
      dtEmissao?: Maybe<any>
      dtReconhecimento?: Maybe<any>
      aldeiaNascimento?: Maybe<{ __typename?: 'Aldeia'; id: string; nome: string }>
      funcaoSocial?: Maybe<{ __typename?: 'FuncaoSocial'; id: string; nome: string }>
      localOcorrencia?: Maybe<{ __typename?: 'LocalOcorrencia'; id: string; nome: string }>
      ufNascimento?: Maybe<{ __typename?: 'UF'; id: string; sigla: string }>
    }>
    enderecoIndigena?: Maybe<{
      __typename?: 'EnderecoIndigena'
      numero?: Maybe<string>
      aldeiaResidencia?: Maybe<{ __typename?: 'Aldeia'; id: string; nome: string }>
      poloBaseResidencia?: Maybe<{ __typename?: 'PoloBase'; id: string; nome: string }>
      dseiResidencia?: Maybe<{ __typename?: 'Dsei'; id: string; nome: string }>
      municipio?: Maybe<{ __typename?: 'Municipio'; id: string; nome: string }>
      uf?: Maybe<{ __typename?: 'UF'; id: string; sigla: string }>
    }>
  }
}

export type CidadaoEditQueryVariables = {
  input: BuscaCidadaoCadsusInput
}

export type CidadaoEditQuery = { __typename?: 'Query'; buscaCidadaoCadsusAtualizado: string }

export type CidadaoListingQueryVariables = {
  filtro: CidadaosQueryInput
}

export type CidadaoListingQuery = {
  __typename?: 'Query'
  cidadaos: {
    __typename?: 'CidadaosPayload'
    content: Array<
      Maybe<{
        __typename?: 'Cidadao'
        id: string
        nome: string
        nomeSocial?: Maybe<string>
        cpf?: Maybe<string>
        cns?: Maybe<string>
        nomeMae?: Maybe<string>
        dataNascimento?: Maybe<any>
        telefoneCelular?: Maybe<string>
        telefoneResidencial?: Maybe<string>
        sexo: SexoEnum
        identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
        dataAtualizado?: Maybe<any>
        ativo?: Maybe<boolean>
        unificado?: Maybe<boolean>
        unificacaoBase?: Maybe<string>
        possuiAgendamento?: Maybe<boolean>
        faleceu?: Maybe<boolean>
        prontuario?: Maybe<{ __typename?: 'Prontuario'; id: string }>
        localidadeNascimento?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
        cidadaoVinculacaoEquipe?: Maybe<{
          __typename?: 'CidadaoVinculacaoEquipe'
          id?: Maybe<string>
          unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
        }>
        enderecoIndigena?: Maybe<{
          __typename?: 'EnderecoIndigena'
          aldeiaResidencia?: Maybe<{ __typename?: 'Aldeia'; id: string; nome: string }>
          poloBaseResidencia?: Maybe<{ __typename?: 'PoloBase'; id: string; nome: string }>
          dseiResidencia?: Maybe<{ __typename?: 'Dsei'; id: string; nome: string }>
        }>
        cidadaoAldeado?: Maybe<{ __typename?: 'CidadaoAldeado'; id: string; nomeTradicional?: Maybe<string> }>
      }>
    >
  }
}

export type CidadaoQueryVariables = {
  id: Scalars['ID']
}

export type CidadaoQuery = {
  __typename?: 'Query'
  cidadao: {
    __typename?: 'Cidadao'
    id: string
    nome: string
    nomeSocial?: Maybe<string>
    cpf?: Maybe<string>
    cns?: Maybe<string>
    nomeMae?: Maybe<string>
    dataNascimento?: Maybe<any>
    sexo: SexoEnum
    identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
    faleceu?: Maybe<boolean>
    isCidadaoAusente?: Maybe<boolean>
  }
}

export type CondicoesAutorreferidasQueryVariables = {
  cidadaoId: Scalars['ID']
}

export type CondicoesAutorreferidasQuery = {
  __typename?: 'Query'
  condicoesAutorreferidas: Array<Maybe<{ __typename?: 'CondicaoAutorreferida'; descricao: string }>>
}

export type ExcluirCidadaoMutationVariables = {
  cidadaoId: Scalars['ID']
}

export type ExcluirCidadaoMutation = { __typename?: 'Mutation'; excluirCidadao: string }

export type HasCidadaoAgendamentoFuturoQueryVariables = {
  input: CidadaoPossuiAgendamentoFuturoQueryInput
}

export type HasCidadaoAgendamentoFuturoQuery = { __typename?: 'Query'; hasAgendamentoFuturo?: Maybe<boolean> }

export type ReativarCidadaoMutationVariables = {
  cidadaoId: Scalars['ID']
}

export type ReativarCidadaoMutation = {
  __typename?: 'Mutation'
  reativarCidadao: { __typename?: 'Cidadao'; id: string }
}

export type SalvarCidadaoMutationVariables = {
  input: CidadaoInput
}

export type SalvarCidadaoMutation = {
  __typename?: 'Mutation'
  salvarCidadao: { __typename?: 'Cidadao'; id: string; nome: string; cns?: Maybe<string> }
}

export type SincronizaCidadaoCadsusPollingQueryVariables = {
  uuid: Scalars['String']
}

export type SincronizaCidadaoCadsusPollingQuery = {
  __typename?: 'Query'
  sincronizacaoCadsusPolling?: Maybe<{
    __typename?: 'SincronizacaoCadsusResultDto'
    result?: Maybe<SincronizacaoCadsusResultEnum>
    errors?: Maybe<Array<Maybe<string>>>
    uuid: string
    camposAlterados?: Maybe<
      Array<Maybe<{ __typename?: 'CampoAlteradoCadsus'; campo?: Maybe<string>; conteudo?: Maybe<string> }>>
    >
    cidadaoCadsus?: Maybe<{ __typename?: 'CidadaoCadsus'; cns?: Maybe<string>; cpf?: Maybe<string> }>
    cidadaosDuplicados?: Maybe<
      Array<
        Maybe<{
          __typename?: 'CidadaoDuplicadoDto'
          id?: Maybe<string>
          nome?: Maybe<string>
          cpf?: Maybe<string>
          cns?: Maybe<string>
        }>
      >
    >
    cidadaoLocal?: Maybe<{
      __typename?: 'Cidadao'
      id: string
      cpf?: Maybe<string>
      cns?: Maybe<string>
      nisPisPasep?: Maybe<string>
      nome: string
      nomeSocial?: Maybe<string>
      dataNascimento?: Maybe<any>
      dataObito?: Maybe<any>
      numeroDocumentoObito?: Maybe<string>
      sexo: SexoEnum
      desconheceNomeMae?: Maybe<boolean>
      nomeMae?: Maybe<string>
      desconheceNomePai?: Maybe<boolean>
      nomePai?: Maybe<string>
      telefoneResidencial?: Maybe<string>
      telefoneCelular?: Maybe<string>
      telefoneContato?: Maybe<string>
      email?: Maybe<string>
      area?: Maybe<string>
      microArea?: Maybe<string>
      statusForaArea?: Maybe<boolean>
      faleceu?: Maybe<boolean>
      tipoSanguineo?: Maybe<TipoSanguineoEnum>
      desejaInformarIdentidadeGenero?: Maybe<boolean>
      identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
      desejaInformarOrientacaoSexual?: Maybe<boolean>
      orientacaoSexualDbEnum?: Maybe<OrientacaoSexualEnum>
      localidadeExterior?: Maybe<string>
      portariaNaturalizacao?: Maybe<string>
      dataNaturalizacao?: Maybe<any>
      dataEntradaBrasil?: Maybe<any>
      stCompartilhaProntuario?: Maybe<boolean>
      uuidUltimaFicha?: Maybe<string>
      numeroFamilia?: Maybe<string>
      tipoEndereco?: Maybe<TipoEnderecoEnum>
      tipoLocalNascimento?: Maybe<TipoLocalNascimentoEnum>
      endereco?: Maybe<{
        __typename?: 'Endereco'
        bairro?: Maybe<string>
        cep?: Maybe<string>
        complemento?: Maybe<string>
        logradouro?: Maybe<string>
        numero?: Maybe<string>
        pontoReferencia?: Maybe<string>
        semNumero?: Maybe<boolean>
        municipio?: Maybe<{
          __typename?: 'Municipio'
          id: string
          ibge?: Maybe<string>
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
        tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>
        uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
      }>
      etnia?: Maybe<{ __typename?: 'Etnia'; id: string; nome: string }>
      racaCor: { __typename?: 'RacaCor'; id: string; nome: string; racaCorDbEnum: RacaCorDbEnum }
      cbo?: Maybe<{ __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }>
      escolaridade?: Maybe<{ __typename?: 'Escolaridade'; id: string; nome: string }>
      localidadeNascimento?: Maybe<{
        __typename?: 'Municipio'
        id: string
        nome: string
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
      }>
      estadoCivil?: Maybe<{ __typename?: 'EstadoCivil'; id: string; nome: string }>
      paisExterior?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
      nacionalidade?: Maybe<{ __typename?: 'Nacionalidade'; id: string; nacionalidadeDbEnum: NacionalidadeEnum }>
      paisNascimento?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
      cidadaoVinculacaoEquipe?: Maybe<{
        __typename?: 'CidadaoVinculacaoEquipe'
        id?: Maybe<string>
        utilizarCadastroIndividual?: Maybe<boolean>
        unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }>
        equipe?: Maybe<{
          __typename?: 'Equipe'
          id: string
          nome: string
          ine: string
          tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
        }>
      }>
      periodoAusenciaList?: Maybe<
        Array<Maybe<{ __typename?: 'PeriodoAusencia'; id: string; dtAusencia: any; dtRetorno?: Maybe<any> }>>
      >
      cidadaoAldeado?: Maybe<{
        __typename?: 'CidadaoAldeado'
        id: string
        nomeTradicional?: Maybe<string>
        responsavelLegal?: Maybe<string>
        stChefeFamilia: boolean
        beneficios?: Maybe<Array<Maybe<BeneficioDbEnum>>>
        unidadeFunai?: Maybe<string>
        livro?: Maybe<number>
        folha?: Maybe<number>
        cadastroUnico?: Maybe<string>
        dtEmissao?: Maybe<any>
        dtReconhecimento?: Maybe<any>
        aldeiaNascimento?: Maybe<{ __typename?: 'Aldeia'; id: string; nome: string }>
        funcaoSocial?: Maybe<{ __typename?: 'FuncaoSocial'; id: string; nome: string }>
        localOcorrencia?: Maybe<{ __typename?: 'LocalOcorrencia'; id: string; nome: string }>
        ufNascimento?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
      }>
      enderecoIndigena?: Maybe<{
        __typename?: 'EnderecoIndigena'
        numero?: Maybe<string>
        aldeiaResidencia?: Maybe<{
          __typename?: 'Aldeia'
          id: string
          nome: string
          poloBase: { __typename?: 'PoloBase' } & PoloBaseFragment
          municipio: Array<{ __typename?: 'Municipio' } & MunicipioFragment>
        }>
        poloBaseResidencia?: Maybe<{ __typename?: 'PoloBase' } & PoloBaseFragment>
        dseiResidencia?: Maybe<{ __typename?: 'Dsei'; id: string; nome: string }>
        municipio?: Maybe<{ __typename?: 'Municipio' } & MunicipioFragment>
        uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
      }>
    }>
  }>
}

export type UnificacoesCidadaoQueryVariables = {
  id: Scalars['ID']
}

export type UnificacoesCidadaoQuery = {
  __typename?: 'Query'
  unificacoesCidadao?: Maybe<
    Array<
      Maybe<{
        __typename?: 'UnificacoesCidadao'
        id: string
        nomeProfissional?: Maybe<string>
        dataUnificacao?: Maybe<any>
        cidadaos?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Cidadao'
              id: string
              cpf?: Maybe<string>
              cns?: Maybe<string>
              nome: string
              nomeSocial?: Maybe<string>
              dataNascimento?: Maybe<any>
              identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
              sexo: SexoEnum
              nomeMae?: Maybe<string>
              localidadeNascimento?: Maybe<{
                __typename?: 'Municipio'
                id: string
                nome: string
                uf: { __typename?: 'UF'; id: string; sigla: string }
              }>
            }>
          >
        >
      }>
    >
  >
}

export type VinculacaoFichaCadastroIndividualQueryVariables = {
  input: CidadaoVinculacaoFichaQueryInput
}

export type VinculacaoFichaCadastroIndividualQuery = {
  __typename?: 'Query'
  vinculacaoFichaCadastroIndividual?: Maybe<{
    __typename?: 'CidadaoVinculacaoFichaDto'
    perguntaSaidaCadastroEnum?: Maybe<PerguntaSaidaCadastroEnum>
    unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }>
    equipe?: Maybe<{
      __typename?: 'Equipe'
      id: string
      ine: string
      nome: string
      tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
    }>
  }>
}

export type BuscaCidadaoCadsusQueryVariables = {
  filter: BuscaListaCidadaoCadsusInput
}

export type BuscaCidadaoCadsusQuery = { __typename?: 'Query'; buscaCidadaosCadsus: string }

export type ConfiguracaoCadsusQueryVariables = {}

export type ConfiguracaoCadsusQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
    cadsus: { __typename?: 'ConfiguracaoCadsus'; id: string; habilitado: boolean }
  }
  configuracaoCertificadoHabilitado?: Maybe<{
    __typename?: 'ConfiguracaoCertificado'
    id: string
    cadsusHabilitado: boolean
  }>
}

export type ConfiguracaoCertificadoQueryVariables = {}

export type ConfiguracaoCertificadoQuery = {
  __typename?: 'Query'
  configuracaoCertificadoHabilitado?: Maybe<{
    __typename?: 'ConfiguracaoCertificado'
    id: string
    cadsusHabilitado: boolean
  }>
}

export type CreateCidadaoCadsusMutationVariables = {
  input: SincronizaCidadaoCadsusInput
}

export type CreateCidadaoCadsusMutation = { __typename?: 'Mutation'; createCidadaoCadsus: string }

export type ForcaAtualizacaoCidadaoCadsusQueryVariables = {
  input: BuscaCidadaoCadsusInput
}

export type ForcaAtualizacaoCidadaoCadsusQuery = { __typename?: 'Query'; buscaCidadaoCadsusAtualizadoForcado: string }

export type ImportacoesCnesQueryVariables = {
  input: ImportacoesCnesQueryInput
}

export type ImportacoesCnesQuery = {
  __typename?: 'Query'
  importacoesCnes: {
    __typename?: 'ImportacoesCnesQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'ImportacaoCnes'
        id: string
        dataImportacao: any
        equipesNovas?: Maybe<number>
        equipesAtualizadas?: Maybe<number>
        lotacoesNovas?: Maybe<number>
        lotacoesAtualizadas?: Maybe<number>
        profissionaisNovos?: Maybe<number>
        profissionaisAtualizados?: Maybe<number>
        unidadesSaudeNovas?: Maybe<number>
        unidadesSaudeAtualizadas?: Maybe<number>
        processo: { __typename?: 'Processo'; id: string; status: StatusProcesso }
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type DetalhesImportacaoCnesViewQueryVariables = {
  id: Scalars['ID']
}

export type DetalhesImportacaoCnesViewQuery = {
  __typename?: 'Query'
  importacaoCnes: {
    __typename?: 'ImportacaoCnes'
    id: string
    dataImportacao: any
    detalhes?: Maybe<string>
    equipesNovas?: Maybe<number>
    equipesAtualizadas?: Maybe<number>
    lotacoesNovas?: Maybe<number>
    lotacoesAtualizadas?: Maybe<number>
    profissionaisNovos?: Maybe<number>
    profissionaisAtualizados?: Maybe<number>
    unidadesSaudeNovas?: Maybe<number>
    unidadesSaudeAtualizadas?: Maybe<number>
    vinculacoesEquipesNovas?: Maybe<number>
    vinculacoesEquipesAtualizadas?: Maybe<number>
    municipio: { __typename?: 'Municipio'; id: string; nome: string }
  }
}

export type ConfiguracoesAvancadasQueryVariables = {}

export type ConfiguracoesAvancadasQuery = {
  __typename?: 'Query'
  avancada: {
    __typename?: 'ConfiguracaoAvancada'
    performance: {
      __typename?: 'ConfiguracaoPerformance'
      qtdRequisicoesSimultaneas?: Maybe<number>
      buscaCidadaoPorPropriedades: {
        __typename?: 'ConfiguracaoBuscaCidadaoPorPropriedades'
        id: string
        habilitado: boolean
      }
    }
  }
}

export type AlterarBuscaCidadaoPorPropriedadesMutationVariables = {
  input: Scalars['Boolean']
}

export type AlterarBuscaCidadaoPorPropriedadesMutation = {
  __typename?: 'Mutation'
  alterarBuscaCidadaoPorPropriedades: {
    __typename?: 'ConfiguracaoBuscaCidadaoPorPropriedades'
    id: string
    habilitado: boolean
  }
}

export type AlterarQtdRequisicoesMutationVariables = {
  qtdRequisicoesSimultaneas?: Maybe<Scalars['Int']>
}

export type AlterarQtdRequisicoesMutation = {
  __typename?: 'Mutation'
  alterarQtdRequisicoes?: Maybe<{ __typename?: 'ConfiguracaoPerformance'; qtdRequisicoesSimultaneas?: Maybe<number> }>
}

export type ConexaoQueryVariables = {}

export type ConexaoQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    internet: {
      __typename?: 'ConfiguracaoInternet'
      habilitado: boolean
      dataUltimoTeste?: Maybe<any>
      resultadoUltimoTeste?: Maybe<boolean>
    }
    cadsus: {
      __typename?: 'ConfiguracaoCadsus'
      id: string
      habilitado: boolean
      desabilitadoAteData?: Maybe<any>
      intervalo?: Maybe<number>
    }
    horus: {
      __typename?: 'ConfiguracaoHorus'
      id: string
      habilitado: boolean
      desabilitadoAteData?: Maybe<any>
      intervalo?: Maybe<number>
    }
    videochamadas: { __typename?: 'ConfiguracaoVideochamadas'; id: string; habilitado: boolean }
    agendaOnline?: Maybe<{
      __typename?: 'AgendaOnline'
      ativa: boolean
      detalhesAtivacao?: Maybe<{
        __typename?: 'DetalhesAtivacao'
        dataEvento: any
        profissional?: Maybe<{ __typename?: 'Profissional'; id: string; nome: string }>
      }>
      testeConexao?: Maybe<{ __typename?: 'TesteConexao'; sucesso: boolean; dataEvento: any }>
    }>
    prescricaoDigital: {
      __typename?: 'ConfiguracaoPrescricaoDigital'
      id: string
      habilitado: boolean
      usuario?: Maybe<string>
      senha?: Maybe<string>
    }
  }
}

export type AlternarConexaoInternetMutationVariables = {
  habilitado?: Maybe<Scalars['Boolean']>
}

export type AlternarConexaoInternetMutation = {
  __typename?: 'Mutation'
  alternarInternet?: Maybe<{ __typename?: 'ConfiguracaoInternet'; habilitado: boolean }>
}

export type TestarConexaoInternetMutationVariables = {}

export type TestarConexaoInternetMutation = {
  __typename?: 'Mutation'
  testarConexaoInternet?: Maybe<{
    __typename?: 'ConfiguracaoInternet'
    habilitado: boolean
    dataUltimoTeste?: Maybe<any>
    resultadoUltimoTeste?: Maybe<boolean>
  }>
}

export type GerarChaveAtivacaoAgendaOnlineMutationVariables = {}

export type GerarChaveAtivacaoAgendaOnlineMutation = { __typename?: 'Mutation'; gerarChaveAtivacaoAgendaOnline: string }

export type AtivarAgendaOnlineMutationVariables = {
  ativacaoAgendaOnlineInput: AtivacaoAgendaOnlineInput
}

export type AtivarAgendaOnlineMutation = {
  __typename?: 'Mutation'
  ativarAgendaOnline?: Maybe<{
    __typename?: 'AgendaOnline'
    ativa: boolean
    detalhesAtivacao?: Maybe<{
      __typename?: 'DetalhesAtivacao'
      dataEvento: any
      profissional?: Maybe<{ __typename?: 'Profissional'; nome: string }>
    }>
  }>
}

export type TestarConexaoAgendaOnlineMutationVariables = {}

export type TestarConexaoAgendaOnlineMutation = {
  __typename?: 'Mutation'
  testarConexaoAgendaOnline: { __typename?: 'TesteConexao'; sucesso: boolean; dataEvento: any }
}

export type CfgLotacaoAgendaOnlineQueryVariables = {}

export type CfgLotacaoAgendaOnlineQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    hasLotacoesConfiguradasOnline?: Maybe<boolean>
    lotacoesAgendaOnlineComDuracaoPadrao?: Maybe<number>
    agendaOnline?: Maybe<{ __typename?: 'AgendaOnline'; ativa: boolean }>
  }
}

export type DesativarAgendaOnlineMutationVariables = {}

export type DesativarAgendaOnlineMutation = {
  __typename?: 'Mutation'
  desativarAgendaOnline?: Maybe<{
    __typename?: 'AgendaOnline'
    ativa: boolean
    detalhesAtivacao?: Maybe<{
      __typename?: 'DetalhesAtivacao'
      dataEvento: any
      profissional?: Maybe<{ __typename?: 'Profissional'; nome: string }>
    }>
  }>
}

export type UnificacaoBaseAtivaQueryVariables = {}

export type UnificacaoBaseAtivaQuery = {
  __typename?: 'Query'
  pedidoEnvioBaseAtivo?: Maybe<{ __typename?: 'PedidoEnvioBase'; id?: Maybe<string> }>
  unificacoesBase?: Maybe<
    Array<Maybe<{ __typename?: 'UnificacaoBase'; id: string; status?: Maybe<StatusUnificacaoBaseEnum> }>>
  >
}

export type AlterarConfiguracaoPrescricaoDigitalMutationVariables = {
  input: AlterarConfiguracaoPrescricaoDigitalInput
}

export type AlterarConfiguracaoPrescricaoDigitalMutation = {
  __typename?: 'Mutation'
  alterarConfiguracaoPrescricaoDigital: {
    __typename?: 'ConfiguracaoPrescricaoDigital'
    id: string
    habilitado: boolean
    usuario?: Maybe<string>
    senha?: Maybe<string>
  }
}

export type AlterarCadsusMutationVariables = {
  alterarCadsusInput: AlterarCadsusInput
}

export type AlterarCadsusMutation = {
  __typename?: 'Mutation'
  alterarCadsus?: Maybe<{
    __typename?: 'ConfiguracaoCadsus'
    id: string
    habilitado: boolean
    intervalo?: Maybe<number>
    desabilitadoAteData?: Maybe<any>
  }>
}

export type AlterarHorusMutationVariables = {
  alterarHorusInput: AlterarHorusInput
}

export type AlterarHorusMutation = {
  __typename?: 'Mutation'
  alterarHorus?: Maybe<{
    __typename?: 'ConfiguracaoHorus'
    id: string
    habilitado: boolean
    intervalo?: Maybe<number>
    desabilitadoAteData?: Maybe<any>
  }>
}

export type AlterarVideochamadaMutationVariables = {
  input: AlterarConfiguracaoVideochamadasInput
}

export type AlterarVideochamadaMutation = {
  __typename?: 'Mutation'
  alterarConfiguracaoVideochamadas: { __typename?: 'ConfiguracaoVideochamadas'; id: string; habilitado: boolean }
}

export type HasMunicipioAtivoQueryVariables = {}

export type HasMunicipioAtivoQuery = { __typename?: 'Query'; hasMunicipioAtivo: boolean }

export type MunicipiosResponsaveisQueryVariables = {
  input: MunicipioResponsavelFiltroInput
}

export type MunicipiosResponsaveisQuery = {
  __typename?: 'Query'
  municipiosResponsaveis: {
    __typename?: 'MunicipioResponsavelQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'AdministradorMunicipal'
        id: string
        habilitado: boolean
        ativo: boolean
        dataAdicao?: Maybe<any>
        dataInativacao?: Maybe<any>
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; sigla: string }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type InserirMunicipioResponsavelMutationVariables = {
  input: MunicipioResponsavelInput
}

export type InserirMunicipioResponsavelMutation = {
  __typename?: 'Mutation'
  inserirMunicipioResponsavel: {
    __typename?: 'AdministradorMunicipal'
    id: string
    habilitado: boolean
    ativo: boolean
    dataAdicao?: Maybe<any>
    dataInativacao?: Maybe<any>
    profissional: { __typename?: 'Profissional'; nome: string }
    municipio: { __typename?: 'Municipio'; nome: string }
  }
}

export type AtualizarMunicipioResponsavelMutationVariables = {
  input: MunicipioResponsavelUpdateInput
}

export type AtualizarMunicipioResponsavelMutation = {
  __typename?: 'Mutation'
  atualizarMunicipioResponsavel: {
    __typename?: 'AdministradorMunicipal'
    id: string
    habilitado: boolean
    ativo: boolean
    dataAdicao?: Maybe<any>
    dataInativacao?: Maybe<any>
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
    municipio: {
      __typename?: 'Municipio'
      id: string
      nome: string
      uf: { __typename?: 'UF'; id: string; sigla: string }
    }
  }
}

export type ExcluirMunicipioResponsavelMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirMunicipioResponsavelMutation = { __typename?: 'Mutation'; excluirMunicipioResponsavel: string }

export type AlterarNumeroTentativasLoginMutationVariables = {
  tentativas?: Maybe<Scalars['Int']>
}

export type AlterarNumeroTentativasLoginMutation = {
  __typename?: 'Mutation'
  alterarNumeroTentativasLogin?: Maybe<{ __typename?: 'ConfiguracaoTentativasLogin'; tentativasLogin?: Maybe<number> }>
}

export type AlterarPeriodoInatividadeMutationVariables = {
  periodo?: Maybe<Scalars['Int']>
}

export type AlterarPeriodoInatividadeMutation = {
  __typename?: 'Mutation'
  alterarPeriodoInatividade?: Maybe<{
    __typename?: 'ConfiguracaoPeriodoInatividade'
    periodoInatividade?: Maybe<number>
  }>
}

export type AlterarPeriodoRedefinicaoSenhaMutationVariables = {
  periodo?: Maybe<Scalars['Int']>
}

export type AlterarPeriodoRedefinicaoSenhaMutation = {
  __typename?: 'Mutation'
  alterarPeriodoRedefinicaoSenha?: Maybe<{
    __typename?: 'ConfiguracaoRedefinicaoSenha'
    redefinicaoSenha?: Maybe<number>
  }>
}

export type ForcarRedefinirSenhasMutationVariables = {}

export type ForcarRedefinirSenhasMutation = {
  __typename?: 'Mutation'
  forcarRedefinirSenhas?: Maybe<{ __typename?: 'ConfiguracaoRedefinicaoSenha'; redefinicaoSenha?: Maybe<number> }>
}

export type SegurancaQueryVariables = {}

export type SegurancaQuery = {
  __typename?: 'Query'
  seguranca: {
    __typename?: 'ConfiguracaoSeguranca'
    periodoEmMesesParaRedefinir: { __typename?: 'ConfiguracaoRedefinicaoSenha'; redefinicaoSenha?: Maybe<number> }
    periodoEmMinutosInatividade: { __typename?: 'ConfiguracaoPeriodoInatividade'; periodoInatividade?: Maybe<number> }
    loginNumeroTentativas: { __typename?: 'ConfiguracaoTentativasLogin'; tentativasLogin?: Maybe<number> }
  }
}

export type AlterarDadosServidorInstalacaoMutationVariables = {
  input: ConfiguracaoDadosServidorInput
}

export type AlterarDadosServidorInstalacaoMutation = {
  __typename?: 'Mutation'
  alterarDadosServidor?: Maybe<{
    __typename?: 'ConfiguracaoDadosServidorFormDto'
    nomeInstalacao?: Maybe<string>
    linkInstalacao?: Maybe<string>
  }>
}

export type AlterarServidorSmtpMutationVariables = {
  input: ConfiguracaoSmtpInput
}

export type AlterarServidorSmtpMutation = {
  __typename?: 'Mutation'
  alterarServidorSMTP?: Maybe<{
    __typename?: 'ServidorSMTP'
    email?: Maybe<string>
    senha?: Maybe<string>
    usuario?: Maybe<string>
    endereco?: Maybe<string>
    porta?: Maybe<string>
  }>
}

export type DesabilitarConexaoSmtpMutationVariables = {}

export type DesabilitarConexaoSmtpMutation = { __typename?: 'Mutation'; desabilitarConexaoSMTP: boolean }

export type DadosServidoresQueryVariables = {}

export type DadosServidoresQuery = {
  __typename?: 'Query'
  servidores: {
    __typename?: 'ConfiguracaoServidoresFormDto'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
    dadosServidor?: Maybe<{
      __typename?: 'ConfiguracaoDadosServidorFormDto'
      nomeInstalacao?: Maybe<string>
      linkInstalacao?: Maybe<string>
    }>
    servidorSMTP?: Maybe<{
      __typename?: 'ServidorSMTP'
      email?: Maybe<string>
      senha?: Maybe<string>
      usuario?: Maybe<string>
      endereco?: Maybe<string>
      porta?: Maybe<string>
      ativo?: Maybe<boolean>
      dataRegistro?: Maybe<any>
    }>
  }
}

export type ConfiguracaoInternetQueryVariables = {}

export type ConfiguracaoInternetQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
    agendaOnline?: Maybe<{ __typename?: 'AgendaOnline'; ativa: boolean }>
  }
}

export type PedidoEnvioBaseAtivoQueryVariables = {}

export type PedidoEnvioBaseAtivoQuery = {
  __typename?: 'Query'
  pedidoEnvioBaseAtivo?: Maybe<{
    __typename?: 'PedidoEnvioBase'
    id?: Maybe<string>
    nomeInstalacao: string
    chaveAutenticacao?: Maybe<string>
    linkInstalacao: string
    status: StatusPedidoEnvioEnum
    loginAtivo: boolean
  }>
}

export type PedidosEnvioBaseQueryVariables = {}

export type PedidosEnvioBaseQuery = {
  __typename?: 'Query'
  pedidosEnvioBase?: Maybe<
    Array<
      Maybe<{
        __typename?: 'PedidoEnvioBase'
        id?: Maybe<string>
        linkInstalacao: string
        status: StatusPedidoEnvioEnum
        dataSolicitacao: any
        dataFimUnificacao?: Maybe<any>
        loginAtivo: boolean
      }>
    >
  >
}

export type UnificacoesBaseQueryVariables = {}

export type UnificacoesBaseQuery = {
  __typename?: 'Query'
  unificacoesBase?: Maybe<
    Array<
      Maybe<{
        __typename?: 'UnificacaoBase'
        id: string
        nomeInstalacao: string
        status?: Maybe<StatusUnificacaoBaseEnum>
        dataSolicitacao: any
        dataFimUnificacao?: Maybe<any>
      }>
    >
  >
}

export type AceitarSolicitacaoMutationVariables = {
  id: Scalars['ID']
}

export type AceitarSolicitacaoMutation = { __typename?: 'Mutation'; aceitarSolicitacao: boolean }

export type CadastrarEnderecoEnvioMutationVariables = {
  input: InfoCadastroEnvioInput
}

export type CadastrarEnderecoEnvioMutation = {
  __typename?: 'Mutation'
  cadastrarEnderecoEnvio: {
    __typename?: 'CadastroEnderecoEnvioDto'
    errorLogin?: Maybe<Array<Maybe<string>>>
    pedidoEnvioBase?: Maybe<{ __typename?: 'PedidoEnvioBase'; id?: Maybe<string> }>
    errorImportacaoCnes?: Maybe<{
      __typename?: 'LocalidadeCnesDesatualizadoDto'
      instalacaoCentral?: Maybe<string>
      instalacaoLocal?: Maybe<string>
    }>
  }
}

export type CancelarSolicitacaoEnvioMutationVariables = {
  input: CancelarSolicitacaoEnvioInput
}

export type CancelarSolicitacaoEnvioMutation = { __typename?: 'Mutation'; cancelarSolicitacaoEnvio: boolean }

export type HasImpressaoDocumentosDuplicadosQueryVariables = {
  id: Scalars['ID']
}

export type HasImpressaoDocumentosDuplicadosQuery = { __typename?: 'Query'; hasDocumentosDuplicados: boolean }

export type InicarUnificacaoBaseMutationVariables = {}

export type InicarUnificacaoBaseMutation = { __typename?: 'Mutation'; iniciarUnificacaoBase: boolean }

export type NegarSolicitacaoMutationVariables = {
  id: Scalars['ID']
}

export type NegarSolicitacaoMutation = { __typename?: 'Mutation'; negarSolicitacao: boolean }

export type RefazerLoginMutationVariables = {
  senha: Scalars['String']
}

export type RefazerLoginMutation = { __typename?: 'Mutation'; refazLoginExpirado?: Maybe<Array<Maybe<string>>> }

export type CadsusHabilitadoInstalacaoQueryVariables = {}

export type CadsusHabilitadoInstalacaoQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    cadsus: { __typename?: 'ConfiguracaoCadsus'; id: string; habilitado: boolean }
  }
}

export type CertificadoSelectQueryVariables = {
  input: CertificadoQueryInput
}

export type CertificadoSelectQuery = {
  __typename?: 'Query'
  certificados: {
    __typename?: 'CertificadoPayload'
    content: Array<Maybe<{ __typename?: 'Certificado'; path: string; nome: string }>>
  }
}

export type SalvarConfiguracaoCertificadoMutationVariables = {
  configuracaoCertificadoInput: ConfiguracaoCertificadoInput
}

export type SalvarConfiguracaoCertificadoMutation = {
  __typename?: 'Mutation'
  salvarConfiguracaoCertificado: {
    __typename?: 'ConfiguracaoCertificado'
    id: string
    dataInclusao: any
    dataHabilitacao: any
    dataValidadeCertificado: any
    rndsHabilitado: boolean
    cadsusHabilitado: boolean
    error?: Maybe<Array<Maybe<string>>>
    admMunicipal: { __typename?: 'AdministradorMunicipal'; profissional: { __typename?: 'Profissional'; nome: string } }
  }
}

export type ConfiguracaoCertificadoHabilitadoQueryVariables = {}

export type ConfiguracaoCertificadoHabilitadoQuery = {
  __typename?: 'Query'
  configuracaoCertificadoHabilitado?: Maybe<{
    __typename?: 'ConfiguracaoCertificado'
    id: string
    dataInclusao: any
    dataHabilitacao: any
    dataValidadeCertificado: any
    rndsHabilitado: boolean
    cadsusHabilitado: boolean
    error?: Maybe<Array<Maybe<string>>>
    admMunicipal: { __typename?: 'AdministradorMunicipal'; profissional: { __typename?: 'Profissional'; nome: string } }
  }>
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
  }
}

export type ContraChaveAtivacaoMunicipioQueryVariables = {}

export type ContraChaveAtivacaoMunicipioQuery = { __typename?: 'Query'; contraChaveAtivacaoMunicipio?: Maybe<string> }

export type ExcluirConfiguracaoCertificadoMutationVariables = {
  configuracaoCertificadoId: Scalars['ID']
}

export type ExcluirConfiguracaoCertificadoMutation = { __typename?: 'Mutation'; excluirConfiguracaoCertificado: string }

export type ValidarConfiguracaoCertificadoMutationVariables = {
  configuracaoCertificadoInput: ConfiguracaoCertificadoInput
}

export type ValidarConfiguracaoCertificadoMutation = {
  __typename?: 'Mutation'
  validarConfiguracaoCertificado: Array<Maybe<ServicosCertificadoEnum>>
}

export type ConfiguracaoAgendaMunicipioQueryVariables = {}

export type ConfiguracaoAgendaMunicipioQuery = {
  __typename?: 'Query'
  duracaoAtendimentoPadraoMunicipio?: Maybe<{
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }>
  configuracaoHorariosMunicipio: {
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }
}

export type SalvarConfiguracaoHorariosMunicipioMutationVariables = {
  configuracaoAgendaMunicipalInput?: Maybe<ConfiguracaoAgendaMunicipalInput>
}

export type SalvarConfiguracaoHorariosMunicipioMutation = {
  __typename?: 'Mutation'
  salvarConfiguracaoHorariosMunicipio?: Maybe<{
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }>
}

export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables = {
  duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput
}

export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation = {
  __typename?: 'Mutation'
  alterarDuracaoPadraoAtendimento: {
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }
}

export type ExcluirGrupoExameMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirGrupoExameMutation = { __typename?: 'Mutation'; excluirGrupoExame: string }

export type GrupoExamesQueryVariables = {
  id: Scalars['ID']
}

export type GrupoExamesQuery = {
  __typename?: 'Query'
  grupoExame: {
    __typename?: 'GrupoExame'
    id: string
    nome: string
    procedimentos?: Maybe<Array<Maybe<{ __typename?: 'Procedimento' } & ProcedimentoFragment>>>
  }
}

export type SalvarGrupoExameMutationVariables = {
  input: GrupoExameInput
}

export type SalvarGrupoExameMutation = {
  __typename?: 'Mutation'
  salvarGrupoExame: {
    __typename?: 'GrupoExame'
    id: string
    nome: string
    procedimentos?: Maybe<Array<Maybe<{ __typename?: 'Procedimento' } & ProcedimentoFragment>>>
  }
}

export type GrupoExameTableQueryVariables = {
  input: GrupoExameQueryInput
}

export type GrupoExameTableQuery = {
  __typename?: 'Query'
  gruposExame: {
    __typename?: 'GruposExameQueryPayload'
    content: Array<
      Maybe<{ __typename?: 'GrupoExame'; id: string; nome: string; quantidadeProcedimentos?: Maybe<number> }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CertificadoRndsQueryVariables = {}

export type CertificadoRndsQuery = {
  __typename?: 'Query'
  configuracaoCertificadoHabilitado?: Maybe<{
    __typename?: 'ConfiguracaoCertificado'
    id: string
    rndsHabilitado: boolean
  }>
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
  }
}

export type ResumoEnviosRndsQueryVariables = {
  input: TipoRegistroRndsEnum
}

export type ResumoEnviosRndsQuery = {
  __typename?: 'Query'
  resumoEnviosRnds: {
    __typename?: 'QuantidadeStatusEnvioRndsDto'
    qtdEnviado?: Maybe<any>
    qtdNaoEnviado?: Maybe<any>
    qtdFalhaConexao?: Maybe<any>
    qtdDadosInvalidos?: Maybe<any>
    qtdErro?: Maybe<any>
  }
}

export type StatusConexaoRndsQueryVariables = {}

export type StatusConexaoRndsQuery = { __typename?: 'Query'; statusConexaoRnds: StatusConexaoRndsEnum }

export type EquipeEMultiVinculacoesTableQueryVariables = {
  input: TodasVinculacoesEquipesInput
}

export type EquipeEMultiVinculacoesTableQuery = {
  __typename?: 'Query'
  todasVinculacoesEquipeEmulti: {
    __typename?: 'TodasVinculacoesEquipesPayload'
    content: Array<{
      __typename?: 'TodasVinculacoesEquipeDto'
      quantidadeVinculacoes: number
      equipeEmulti: { __typename?: 'Equipe'; id: string; nome: string; ine: string }
      vinculacoesPorEstabelecimento: Array<{
        __typename?: 'VinculacaoEquipePorEstabelecimentoDto'
        estabelecimento: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        equipes: Array<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
      }>
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ExcluirVinculacaoServicoMutationVariables = {
  referenciaId: Scalars['Long']
}

export type ExcluirVinculacaoServicoMutation = { __typename?: 'Mutation'; excluirVinculacaoServico?: Maybe<boolean> }

export type SalvarVinculacaoServicoMutationVariables = {
  input: SalvarVinculacaoServicoInput
}

export type SalvarVinculacaoServicoMutation = { __typename?: 'Mutation'; salvarVinculacaoServico?: Maybe<boolean> }

export type UnidadesSaudeByTipoVinculacaoServicoQueryVariables = {
  input: UnidadesSaudeByTipoVinculacaoServicoInput
}

export type UnidadesSaudeByTipoVinculacaoServicoQuery = {
  __typename?: 'Query'
  unidadesSaudeByTipoVinculacaoServico: Array<{
    __typename?: 'UnidadeSaude'
    id: string
    nome?: Maybe<string>
    cnes: string
  }>
}

export type VinculacoesEstabelecimentosTableQueryVariables = {
  input: VinculacaoServicosInput
}

export type VinculacoesEstabelecimentosTableQuery = {
  __typename?: 'Query'
  vinculacoesServico: {
    __typename?: 'VinculacaoServicosPayload'
    content: Array<{
      __typename?: 'VinculacaoServicoDto'
      quantidadeVinculacoes: number
      referencia: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
      vinculacoes: Array<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }>
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type VinculacaoServicoQueryVariables = {
  referenciaId: Scalars['Long']
}

export type VinculacaoServicoQuery = {
  __typename?: 'Query'
  vinculacaoServico?: Maybe<{
    __typename?: 'VinculacaoServicoDto'
    referencia: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    vinculacoes: Array<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }>
  }>
}

export type TipoServicoCheckQueryVariables = {
  input: TipoServicoQueryInput
}

export type TipoServicoCheckQuery = {
  __typename?: 'Query'
  tiposServico: {
    __typename?: 'TipoServicoQueryPayload'
    content: Array<Maybe<{ __typename?: 'TipoServico'; id: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type TipoServicoTableQueryVariables = {
  input: TipoServicoQueryInput
}

export type TipoServicoTableQuery = {
  __typename?: 'Query'
  tiposServico: {
    __typename?: 'TipoServicoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'TipoServico'
        id: string
        nome: string
        municipio?: Maybe<{ __typename?: 'Municipio'; id: string }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type AlterarTipoServicoMutationVariables = {
  input: TipoServicoUpdateInput
}

export type AlterarTipoServicoMutation = {
  __typename?: 'Mutation'
  alterarTipoServico?: Maybe<{ __typename?: 'TipoServico'; id: string; nome: string }>
}

export type SalvarTipoServicoMutationVariables = {
  input: TipoServicoInput
}

export type SalvarTipoServicoMutation = {
  __typename?: 'Mutation'
  salvarTipoServico?: Maybe<{ __typename?: 'TipoServico'; id: string; nome: string }>
}

export type ExcluirTipoServicoMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirTipoServicoMutation = { __typename?: 'Mutation'; excluirTipoServico?: Maybe<string> }

export type CuidadoCompartilhadoQueryVariables = {
  id: Scalars['ID']
}

export type CuidadoCompartilhadoQuery = {
  __typename?: 'Query'
  cuidadoCompartilhado?: Maybe<{
    __typename?: 'CuidadoCompartilhado'
    id: string
    iniciadoEm: any
    dataUltimaEvolucao: any
    classificacaoPrioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
    cidadaoAceitaAtendTic: boolean
    discussao: string
    status: StatusCuidadoCompartilhadoEnum
    cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
    ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
    lotacaoExecutanteAtual?: Maybe<{
      __typename?: 'Lotacao'
      id: string
      profissional: {
        __typename?: 'Profissional'
        id: string
        nome: string
        telefone?: Maybe<string>
        email?: Maybe<string>
      }
      cbo: { __typename?: 'Cbo'; id: string; nome: string }
      equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
      unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
    }>
    cboExecutanteAtual: { __typename?: 'Cbo'; id: string; nome: string }
    unidadeSaudeExecutanteAtual: {
      __typename?: 'UnidadeSaude'
      id: string
      nome?: Maybe<string>
      tipo: { __typename?: 'TipoUnidadeSaude'; id: string; codigoMs: any }
      subtipo?: Maybe<{ __typename?: 'SubtipoUnidadeSaude'; id: string; codigoMs: string }>
    }
    lotacaoSolicitante: {
      __typename?: 'Lotacao'
      id: string
      profissional: {
        __typename?: 'Profissional'
        id: string
        nome: string
        telefone?: Maybe<string>
        email?: Maybe<string>
      }
      cbo: { __typename?: 'Cbo'; id: string; nome: string }
      equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
      unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
    }
    cidadao: {
      __typename?: 'Cidadao'
      id: string
      cpf?: Maybe<string>
      cns?: Maybe<string>
      nome: string
      nomeSocial?: Maybe<string>
      dataNascimento?: Maybe<any>
      dataAtualizado?: Maybe<any>
      sexo: SexoEnum
      nomeMae?: Maybe<string>
      telefoneResidencial?: Maybe<string>
      telefoneCelular?: Maybe<string>
      telefoneContato?: Maybe<string>
      email?: Maybe<string>
      identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
      ativo?: Maybe<boolean>
      faleceu?: Maybe<boolean>
      possuiAgendamento?: Maybe<boolean>
      unificado?: Maybe<boolean>
      unificacaoBase?: Maybe<string>
      prontuario?: Maybe<{
        __typename?: 'Prontuario'
        id: string
        preNatalAtivo?: Maybe<{ __typename?: 'PreNatal'; id: string; ultimaDum?: Maybe<any> }>
        gestacoes?: Maybe<Array<{ __typename?: 'PeriodoGestacao'; id: string; inicio: any; fim?: Maybe<any> }>>
      }>
      cidadaoVinculacaoEquipe?: Maybe<{
        __typename?: 'CidadaoVinculacaoEquipe'
        id?: Maybe<string>
        tpCdsOrigem?: Maybe<CdsOrigemEnum>
        unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
      }>
    }
  }>
}

export type SalvarCuidadoCompartilhadoMutationVariables = {
  input: SalvarCuidadoCompartilhadoInput
}

export type SalvarCuidadoCompartilhadoMutation = {
  __typename?: 'Mutation'
  salvarCuidadoCompartilhado: { __typename?: 'CuidadoCompartilhado'; id: string }
}

export type CboTrocaExecutanteSelectFieldQueryVariables = {
  input: CbosTrocaExecutanteCuidadoCompartilhadoQueryInput
}

export type CboTrocaExecutanteSelectFieldQuery = {
  __typename?: 'Query'
  cbosTrocaExecutante: {
    __typename?: 'CboPayload'
    content: Array<{ __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CuidadoCompartilhadoEvolucoesQueryVariables = {
  input: CuidadoCompartilhadoEvolucoesQueryInput
}

export type CuidadoCompartilhadoEvolucoesQuery = {
  __typename?: 'Query'
  cuidadoCompartilhadoEvolucoes: {
    __typename?: 'CuidadoCompartilhadoEvolucoesPayload'
    content: Array<
      Maybe<{
        __typename?: 'CuidadoCompartilhadoEvolucao'
        id: string
        dataEvolucao: any
        conduta: CondutaCuidadoCompartilhadoEnum
        descricao?: Maybe<string>
        classificacaoPrioridade: ClassificacaoPrioridadeCuidadoEnum
        reclassificacaoPrioridade?: Maybe<ClassificacaoPrioridadeCuidadoEnum>
        enviarGarantiaAcesso?: Maybe<CondutaCuidadoCompartilhadoEnum>
        lotacao: {
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
        }
        lotacaoExecutante?: Maybe<{ __typename?: 'Lotacao'; id: string }>
        lotacaoAfetada?: Maybe<{
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
          equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string }>
          unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
        }>
        agenda?: Maybe<{
          __typename?: 'Agendado'
          id: string
          horarioInicial?: Maybe<any>
          situacao: SituacaoAgendadoEnum
          observacao?: Maybe<string>
          justificativaCancelamento?: Maybe<{
            __typename?: 'JustificativaCancelamentoAgendado'
            id: string
            justificativa: string
          }>
        }>
        cuidadoCompartilhado: {
          __typename?: 'CuidadoCompartilhado'
          id: string
          lotacaoSolicitante: { __typename?: 'Lotacao'; id: string }
        }
      }>
    >
  }
}

export type VisualizarCuidadoCompartilhadoMutationVariables = {
  input: VisualizarCuidadoCompartilhadoInput
}

export type VisualizarCuidadoCompartilhadoMutation = { __typename?: 'Mutation'; visualizarCuidadoCompartilhado: string }

export type IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQueryVariables = {
  tipoCoMs: Scalars['Long']
  subtipoCoMs?: Maybe<Scalars['String']>
}

export type IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery = {
  __typename?: 'Query'
  isEstabelecimentoAllowedAsReferenciaVinculacaoServico: boolean
}

export type ListaCuidadoCompartilhadoQueryVariables = {
  input: CuidadosCompartilhadoQueryInput
}

export type ListaCuidadoCompartilhadoQuery = {
  __typename?: 'Query'
  cuidadosCompartilhado: {
    __typename?: 'CuidadosCompartilhadoPayload'
    content: Array<{
      __typename?: 'CuidadoCompartilhado'
      id: string
      iniciadoEm: any
      classificacaoPrioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
      status: StatusCuidadoCompartilhadoEnum
      dataUltimaEvolucao: any
      discussao: string
      cidadao: {
        __typename?: 'Cidadao'
        id: string
        dataNascimento?: Maybe<any>
        nomeSocial?: Maybe<string>
        nome: string
      }
      lotacaoExecutanteAtual?: Maybe<{
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string }>
      }>
      cboExecutanteAtual: { __typename?: 'Cbo'; id: string; nome: string }
      unidadeSaudeExecutanteAtual: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
      lotacaoSolicitante: {
        __typename?: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
        cbo: { __typename?: 'Cbo'; id: string; nome: string }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string }>
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
      }
      cid10?: Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>
      ciap?: Maybe<{ __typename?: 'Ciap' } & CiapFragment>
      actions?: Maybe<{
        __typename?: 'CuidadoCompartilhadoActions'
        discutirCasoSolicitante?: Maybe<{ __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }>
        discutirCasoExecutante?: Maybe<{ __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }>
        visualizarDiscussao?: Maybe<{ __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }>
        tornarseExecutante?: Maybe<{ __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }>
      }>
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type EquipeCuidadoCompartilhadoSelectFieldQueryVariables = {
  input: EquipesListaCuidadoCompartilhadoQueryInput
}

export type EquipeCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  equipesListaCuidadoCompartilhado: Array<{
    __typename?: 'Equipe'
    id: string
    nome: string
    ine: string
    tipoEquipe: { __typename?: 'TipoEquipe'; id: string; sigla: string }
  }>
}

export type UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryVariables = {
  input: EstabelecimentosListaCuidadoCompartilhadoQueryInput
}

export type UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  estabelecimentosListaCuidadoCompartilhado: Array<{
    __typename?: 'UnidadeSaude'
    id: string
    nome?: Maybe<string>
    cnes: string
  }>
}

export type LotacacaoCuidadoCompartilhadoSelectFieldQueryVariables = {
  input: LotacoesListaCuidadoCompartilhadoQueryInput
}

export type LotacacaoCuidadoCompartilhadoSelectFieldQuery = {
  __typename?: 'Query'
  lotacoesListaCuidadoCompartilhado: Array<{
    __typename?: 'Lotacao'
    id: string
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
    equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
  }>
}

export type TornarseExecutanteCuidadoCompartilhadoMutationVariables = {
  id: Scalars['ID']
}

export type TornarseExecutanteCuidadoCompartilhadoMutation = {
  __typename?: 'Mutation'
  tornarseExecutanteCuidadoCompartilhado: { __typename?: 'CuidadoCompartilhado'; id: string }
}

export type EquipeDetailQueryVariables = {
  id: Scalars['ID']
}

export type EquipeDetailQuery = {
  __typename?: 'Query'
  equipe: {
    __typename?: 'Equipe'
    id: string
    nome: string
    ine: string
    area?: Maybe<string>
    ativo?: Maybe<boolean>
    tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
  }
}

export type EquipeTableQueryVariables = {
  input: EquipesQueryInput
}

export type EquipeTableQuery = {
  __typename?: 'Query'
  equipes: {
    __typename?: 'EquipePayload'
    content: Array<
      Maybe<{
        __typename?: 'Equipe'
        id: string
        nome: string
        ine: string
        area?: Maybe<string>
        ativo?: Maybe<boolean>
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CriarEstagioMutationVariables = {
  input: EstagioCreateInput
}

export type CriarEstagioMutation = { __typename?: 'Mutation'; createEstagio: { __typename?: 'Estagio'; id: string } }

export type DeleteEstagioMutationVariables = {
  estagioId: Scalars['ID']
}

export type DeleteEstagioMutation = { __typename?: 'Mutation'; deleteEstagio: string }

export type EstagioEditViewQueryVariables = {
  id: Scalars['ID']
}

export type EstagioEditViewQuery = {
  __typename?: 'Query'
  estagio: {
    __typename?: 'Estagio'
    id: string
    ativo: boolean
    dataInicioVigencia: any
    dataFimVigencia: any
    instituicaoEnsino?: Maybe<string>
    nivelTecnico: boolean
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
    lotacaoSupervisora: {
      __typename?: 'Lotacao'
      id: string
      profissional: { __typename?: 'Profissional'; id: string; cpf: string; nome: string }
      unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
      cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
      equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
    }
    profissional: { __typename?: 'Profissional'; id: string; cpf: string; nome: string }
    perfis: Array<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
  }
}

export type ProfissionalEstagioQueryVariables = {
  id: Scalars['ID']
}

export type ProfissionalEstagioQuery = {
  __typename?: 'Query'
  profissional: { __typename?: 'Profissional'; id: string; nome: string }
}

export type UpdateEstagioMutationVariables = {
  input: EstagioUpdateInput
}

export type UpdateEstagioMutation = { __typename?: 'Mutation'; updateEstagio: { __typename?: 'Estagio'; id: string } }

export type AlterarUnidadePadraoHorusMutationVariables = {
  input: UnidadeConsultaHorusIds
}

export type AlterarUnidadePadraoHorusMutation = {
  __typename?: 'Mutation'
  alterarUnidadePadraoHorus: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
}

export type CadastroUnidadeConsultaHorusMutationVariables = {
  input: UnidadeConsultaHorusIds
}

export type CadastroUnidadeConsultaHorusMutation = {
  __typename?: 'Mutation'
  adicionarUnidadeConsultaHorus: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
}

export type HorusListViewQueryVariables = {
  unidadeSaudeId: Scalars['ID']
}

export type HorusListViewQuery = {
  __typename?: 'Query'
  unidadesConsultaHorus: Array<
    Maybe<{
      __typename?: 'HorusFormDto'
      unidadePadrao: boolean
      unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    }>
  >
  unidadeSaude: {
    __typename?: 'UnidadeSaude'
    id: string
    actions: {
      __typename?: 'UnidadeSaudeActions'
      permissaoConexaoHorus: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
    }
  }
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    horus: { __typename?: 'ConfiguracaoHorus'; id: string; habilitado: boolean }
  }
}

export type RemoverUnidadeConsultaHorusMutationVariables = {
  input: UnidadeConsultaHorusIds
}

export type RemoverUnidadeConsultaHorusMutation = {
  __typename?: 'Mutation'
  removerUnidadeConsultaHorus: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
}

export type InstalarMutationVariables = {
  input: InstalacaoInput
}

export type InstalarMutation = { __typename?: 'Mutation'; instalar: boolean }

export type CuidadoCompartilhadoEvolucaoGarantiaAcessoFragmentFragment = {
  __typename?: 'CuidadoCompartilhadoEvolucao'
  id: string
  condutaAgendamento?: Maybe<CondutaCuidadoCompartilhadoEnum>
  cuidadoCompartilhado: {
    __typename?: 'CuidadoCompartilhado'
    id: string
    classificacaoPrioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
    lotacaoSolicitante: {
      __typename?: 'Lotacao'
      id: string
      equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; area?: Maybe<string> }>
      profissional: { __typename?: 'Profissional'; id: string; nome: string; email?: Maybe<string> }
      cbo: { __typename?: 'Cbo'; id: string; nome: string }
    }
  }
  lotacaoExecutante?: Maybe<{
    __typename?: 'Lotacao'
    id: string
    equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; area?: Maybe<string> }>
    profissional: { __typename?: 'Profissional'; id: string; nome: string; email?: Maybe<string> }
    cbo: { __typename?: 'Cbo'; id: string; nome: string }
  }>
}

export type GarantiaAcessoCidadaoQueryVariables = {
  id: Scalars['ID']
}

export type GarantiaAcessoCidadaoQuery = {
  __typename?: 'Query'
  cidadao: {
    __typename?: 'Cidadao'
    id: string
    nome: string
    nomeSocial?: Maybe<string>
    cpf?: Maybe<string>
    cns?: Maybe<string>
    nomeMae?: Maybe<string>
    dataNascimento?: Maybe<any>
    sexo: SexoEnum
    identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
    faleceu?: Maybe<boolean>
    cidadaoVinculacaoEquipe?: Maybe<{
      __typename?: 'CidadaoVinculacaoEquipe'
      id?: Maybe<string>
      unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string }>
      equipe?: Maybe<{
        __typename?: 'Equipe'
        id: string
        ine: string
        nome: string
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
    }>
  }
}

export type GarantiaAcessoQueryVariables = {
  input: Scalars['ID']
}

export type GarantiaAcessoQuery = {
  __typename?: 'Query'
  garantiaAcessoItemById: {
    __typename?: 'ListaEspera'
    id?: Maybe<string>
    motivoEspera?: Maybe<string>
    tipoAtendimento?: Maybe<ListaEsperaTipoAtendimentoEnum>
    dataEntrada?: Maybe<any>
    cidadao?: Maybe<{
      __typename?: 'Cidadao'
      id: string
      nome: string
      nomeSocial?: Maybe<string>
      cpf?: Maybe<string>
      cns?: Maybe<string>
      telefoneResidencial?: Maybe<string>
      telefoneCelular?: Maybe<string>
      telefoneContato?: Maybe<string>
    }>
    equipe?: Maybe<{
      __typename?: 'Equipe'
      id: string
      ine: string
      nome: string
      tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
    }>
    tipoServico?: Maybe<{ __typename?: 'TipoServico'; id: string; nome: string }>
    cuidadoCompartilhadoEvolucao?: Maybe<{
      __typename?: 'CuidadoCompartilhadoEvolucao'
      id: string
      condutaAgendamento?: Maybe<CondutaCuidadoCompartilhadoEnum>
      cuidadoCompartilhado: {
        __typename?: 'CuidadoCompartilhado'
        id: string
        lotacaoSolicitante: {
          __typename?: 'Lotacao'
          id: string
          ativo: boolean
          importada: boolean
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string; email?: Maybe<string> }
          cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        }
      }
      lotacaoExecutante?: Maybe<{
        __typename?: 'Lotacao'
        id: string
        ativo: boolean
        importada: boolean
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string; email?: Maybe<string> }
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
      }>
    }>
  }
}

export type GarantiaAcessoTableQueryVariables = {
  input: GarantiaAcessoFilter
}

export type GarantiaAcessoTableQuery = {
  __typename?: 'Query'
  garantiaAcessoByUnidadeSaudeId: {
    __typename?: 'GarantiaAcessoPayload'
    content: Array<
      Maybe<{
        __typename?: 'ListaEspera'
        id?: Maybe<string>
        motivoEspera?: Maybe<string>
        dataEntrada?: Maybe<any>
        dataSaida?: Maybe<any>
        tipoAtendimento?: Maybe<ListaEsperaTipoAtendimentoEnum>
        qtdCidadaoPresente?: Maybe<number>
        cidadao?: Maybe<{
          __typename?: 'Cidadao'
          id: string
          nome: string
          nomeSocial?: Maybe<string>
          dataNascimento?: Maybe<any>
          cpf?: Maybe<string>
          cns?: Maybe<string>
          telefoneResidencial?: Maybe<string>
          telefoneCelular?: Maybe<string>
          telefoneContato?: Maybe<string>
          prontuario?: Maybe<{ __typename?: 'Prontuario'; id: string }>
        }>
        unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string }>
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; area?: Maybe<string> }>
        tipoServico?: Maybe<{ __typename?: 'TipoServico'; id: string; nome: string }>
        cuidadoCompartilhadoEvolucao?: Maybe<
          { __typename?: 'CuidadoCompartilhadoEvolucao' } & CuidadoCompartilhadoEvolucaoGarantiaAcessoFragmentFragment
        >
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type UnidadesSaudeByGarantiaAcessoCidadaoQueryVariables = {
  input: UnidadesSaudeByGarantiaAcessoCidadaoInput
}

export type UnidadesSaudeByGarantiaAcessoCidadaoQuery = {
  __typename?: 'Query'
  unidadesSaudeByGarantiaAcessoCidadao: Array<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
}

export type AgendarConsultaGarantiaAcessoMutationVariables = {
  input: GarantiaAcessoAgendarConsultaInput
}

export type AgendarConsultaGarantiaAcessoMutation = { __typename?: 'Mutation'; agendarConsultaGarantiaAcesso: string }

export type AdicionarGarantiaAcessoCeoMutationVariables = {
  input: GarantiaAcessoItemCreateInput
}

export type AdicionarGarantiaAcessoCeoMutation = { __typename?: 'Mutation'; adicionarCidadaoGarantiaAcessoCeo: string }

export type AdicionarGarantiaAcessoMutationVariables = {
  input: GarantiaAcessoItemCreateInput
}

export type AdicionarGarantiaAcessoMutation = { __typename?: 'Mutation'; adicionarCidadaoGarantiaAcesso: string }

export type EditarCidadaoGarantiaAcessoMutationVariables = {
  input: GarantiaAcessoItemUpdateInput
}

export type EditarCidadaoGarantiaAcessoMutation = { __typename?: 'Mutation'; editarCidadaoGarantiaAcesso: string }

export type RemoverCidadaoGarantiaAcessoMutationVariables = {
  input: GarantiaAcessoItemDeleteInput
}

export type RemoverCidadaoGarantiaAcessoMutation = { __typename?: 'Mutation'; removerCidadaoGarantiaAcesso: string }

export type LoginMutationVariables = {
  input: LoginInput
}

export type LoginMutation = { __typename?: 'Mutation'; login: { __typename?: 'LoginPayload'; success: boolean } }

export type AcessoEstagioQueryVariables = {
  id: Scalars['ID']
}

export type AcessoEstagioQuery = {
  __typename?: 'Query'
  estagio: {
    __typename?: 'Estagio'
    id: string
    ativo: boolean
    lotacaoSupervisora: {
      __typename?: 'Lotacao'
      id: string
      profissional: { __typename?: 'Profissional'; id: string; nome: string }
    }
    equipe?: Maybe<{
      __typename?: 'Equipe'
      id: string
      nome: string
      ine: string
      tipoEquipe: { __typename?: 'TipoEquipe'; id: string; descricao?: Maybe<string> }
    }>
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
  }
}

export type SelecionarAcessoMutationVariables = {
  input: SelecionarAcessoInput
}

export type SelecionarAcessoMutation = {
  __typename?: 'Mutation'
  selecionarAcesso?: Maybe<{
    __typename?: 'Sessao'
    id: string
    recursos?: Maybe<Array<Maybe<string>>>
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
    acesso?: Maybe<
      | { __typename?: 'Lotacao'; id: string; moduloInicial?: Maybe<string> }
      | { __typename?: 'Estagio'; id: string; moduloInicial?: Maybe<string> }
      | { __typename?: 'AdministradorGeral'; id: string; moduloInicial?: Maybe<string> }
      | { __typename?: 'AdministradorMunicipal'; id: string; moduloInicial?: Maybe<string> }
      | { __typename?: 'GestorEstadual'; id: string; moduloInicial?: Maybe<string> }
      | { __typename?: 'GestorMunicipal'; id: string; moduloInicial?: Maybe<string> }
    >
  }>
}

export type ImunobiologicoComboQueryVariables = {
  input: ImunobiologicoQueryInput
}

export type ImunobiologicoComboQuery = {
  __typename?: 'Query'
  imunobiologicos: {
    __typename?: 'ImunobiologicoQueryPayload'
    content: Array<Maybe<{ __typename?: 'Imunobiologico'; id: string; sigla: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type LoteHasCadastroQueryVariables = {
  input: LoteImunobiologicoInput
}

export type LoteHasCadastroQuery = { __typename?: 'Query'; loteHasCadastro?: Maybe<boolean> }

export type ExcluirLoteImunobiologicoMutationVariables = {
  idLote: Scalars['ID']
}

export type ExcluirLoteImunobiologicoMutation = { __typename?: 'Mutation'; excluirLoteImunobiologico?: Maybe<string> }

export type LoteImunobiologicoFabricanteQueryVariables = {
  input: ImunobiologicoFabricanteQueryInput
}

export type LoteImunobiologicoFabricanteQuery = {
  __typename?: 'Query'
  fabricantesImunobiologico: {
    __typename?: 'ImunobiologicoFabricanteQueryPayload'
    content: Array<Maybe<{ __typename?: 'ImunobiologicoFabricante'; id: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type LoteImunobiologicoQueryVariables = {
  input: LoteImunobiologicoQueryInput
}

export type LoteImunobiologicoQuery = {
  __typename?: 'Query'
  lotesImunobiologico: {
    __typename?: 'LotesImunobiologicoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'LoteImunobiologico'
        id: string
        lote?: Maybe<string>
        validade?: Maybe<any>
        ativo?: Maybe<boolean>
        fabricante?: Maybe<{ __typename?: 'ImunobiologicoFabricante'; id: string; nome: string }>
        imunobiologico?: Maybe<{ __typename?: 'Imunobiologico'; id: string; nome: string; sigla: string }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type SetStatusLoteImunobiologicoMutationVariables = {
  input: LoteImunobiologicoInput
  ativado?: Maybe<Scalars['Boolean']>
}

export type SetStatusLoteImunobiologicoMutation = {
  __typename?: 'Mutation'
  setStatusLoteImunobiologico?: Maybe<string>
}

export type SalvarLoteImunobiologicoMutationVariables = {
  input: LoteImunobiologicoInput
}

export type SalvarLoteImunobiologicoMutation = {
  __typename?: 'Mutation'
  salvarLoteImunobiologico?: Maybe<{ __typename?: 'LoteImunobiologico'; id: string }>
}

export type VisualizouNovidadesMutationVariables = {}

export type VisualizouNovidadesMutation = { __typename?: 'Mutation'; visualizarNovidades: boolean }

export type VisualizarNovidadesQueryVariables = {}

export type VisualizarNovidadesQuery = {
  __typename?: 'Query'
  sessao?: Maybe<{
    __typename?: 'Sessao'
    id: string
    profissional: {
      __typename?: 'Profissional'
      id: string
      usuario: { __typename?: 'Usuario'; id: string; visualizouNovidades: boolean }
    }
  }>
}

export type ExibirNotificacaoNovidadesMutationVariables = {}

export type ExibirNotificacaoNovidadesMutation = {
  __typename?: 'Mutation'
  exibirNotificacaoNovidades: { __typename?: 'Usuario'; id: string; notificaNovidadesVersao: boolean }
}

export type AtivarPerfilMutationVariables = {
  id: Scalars['ID']
}

export type AtivarPerfilMutation = {
  __typename?: 'Mutation'
  ativarPerfil: { __typename?: 'Perfil'; id: string; ativo: boolean }
}

export type CriarPerfilMutationVariables = {
  input: PerfilInput
}

export type CriarPerfilMutation = {
  __typename?: 'Mutation'
  criarPerfil: { __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }
}

export type ExcluirPerfilMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirPerfilMutation = {
  __typename?: 'Mutation'
  excluirPerfil: { __typename?: 'ExcluirPerfilPayload'; id: string }
}

export type InativarPerfilMutationVariables = {
  id: Scalars['ID']
}

export type InativarPerfilMutation = {
  __typename?: 'Mutation'
  inativarPerfil: { __typename?: 'Perfil'; id: string; ativo: boolean }
}

export type RecursosQueryVariables = {
  tipoAcesso: TipoAcesso
}

export type RecursosQuery = { __typename?: 'Query'; recursos: Array<string> }

export type PerfilDetailQueryVariables = {
  perfilId: Scalars['ID']
}

export type PerfilDetailQuery = {
  __typename?: 'Query'
  perfil?: Maybe<{
    __typename?: 'Perfil'
    id: string
    nome: string
    tipoPerfil: TipoAcesso
    recursos: Array<Maybe<string>>
    perfilPadrao?: Maybe<string>
  }>
}

export type AtualizarPerfilMutationVariables = {
  input: PerfilInput
}

export type AtualizarPerfilMutation = {
  __typename?: 'Mutation'
  atualizarPerfil: { __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }
}

export type PerfilEditQueryVariables = {
  perfilId: Scalars['ID']
}

export type PerfilEditQuery = {
  __typename?: 'Query'
  perfil?: Maybe<{
    __typename?: 'Perfil'
    id: string
    nome: string
    tipoPerfil: TipoAcesso
    recursos: Array<Maybe<string>>
    perfilPadrao?: Maybe<string>
  }>
}

export type PerfilTableQueryVariables = {
  input: PerfisQueryInput
}

export type PerfilTableQuery = {
  __typename?: 'Query'
  perfis: {
    __typename?: 'PerfisQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Perfil'
        id: string
        nome: string
        ativo: boolean
        tipoPerfil: TipoAcesso
        perfilPadrao?: Maybe<string>
        actions: {
          __typename?: 'PerfilActions'
          excluir: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ResponderPesquisaSatisfacaoMutationVariables = {
  input: RespostaPesquisa
}

export type ResponderPesquisaSatisfacaoMutation = {
  __typename?: 'Mutation'
  responderPesquisaSatisfacao: { __typename?: 'Usuario'; id: string; mostrarPesquisaSatisfacao: boolean }
}

export type ProfissionalEditQueryVariables = {
  id: Scalars['ID']
}

export type ProfissionalEditQuery = {
  __typename?: 'Query'
  profissional: {
    __typename?: 'Profissional'
    id: string
    nome: string
    numeroConselhoClasse?: Maybe<string>
    sexo?: Maybe<string>
    telefone?: Maybe<string>
    cns?: Maybe<string>
    cpf: string
    dataNascimento?: Maybe<any>
    email?: Maybe<string>
    conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; descricao?: Maybe<string>; sigla: string }>
    ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
    endereco?: Maybe<{
      __typename?: 'Endereco'
      bairro?: Maybe<string>
      cep?: Maybe<string>
      complemento?: Maybe<string>
      logradouro?: Maybe<string>
      numero?: Maybe<string>
      pontoReferencia?: Maybe<string>
      semNumero?: Maybe<boolean>
      municipio?: Maybe<{
        __typename?: 'Municipio'
        id: string
        ibge?: Maybe<string>
        nome: string
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
      }>
      tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>
      uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
    }>
    lotacoes: Array<{ __typename?: 'Lotacao'; id: string; ativo: boolean }>
    estagios: Array<{ __typename?: 'Estagio'; id: string; ativo: boolean }>
  }
}

export type SalvarProfissionalMutationVariables = {
  input: ProfissionalInput
}

export type SalvarProfissionalMutation = {
  __typename?: 'Mutation'
  salvarProfissional: { __typename?: 'Profissional'; id: string; nome: string }
}

export type ConfiguracaoAgendaOnlineLotacaoQueryVariables = {
  lotacaoId: Scalars['ID']
}

export type ConfiguracaoAgendaOnlineLotacaoQuery = {
  __typename?: 'Query'
  lotacao: {
    __typename?: 'Lotacao'
    id: string
    hasConfiguracaoAgendaOnline?: Maybe<boolean>
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
    gradeConfiguracaoAgendaOnline?: Maybe<{
      __typename?: 'ConfiguracaoAgendaOnlineLotacao'
      horariosConsolidados: Array<Maybe<string>>
      configuracoesSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoSemanaAgendaOnline'
          diaSemana: DiaSemanaEnum
          configuracoes: Array<
            Maybe<{ __typename?: 'ConfiguracaoHorarioAgendaOnline'; horario: string; ativo: boolean }>
          >
        }>
      >
    }>
    actions: {
      __typename?: 'LotacaoActions'
      permissaoConfigurarAgendaOnline: { __typename?: 'Action'; enabled: boolean }
    }
  }
}

export type SalvarConfiguracaoAgendaOnlineLotacaoMutationVariables = {
  configuracaoAgendaOnlineLotacaoInput?: Maybe<ConfiguracaoAgendaOnlineLotacaoInput>
}

export type SalvarConfiguracaoAgendaOnlineLotacaoMutation = {
  __typename?: 'Mutation'
  salvarConfiguracaoAgendaOnlineLotacao?: Maybe<boolean>
}

export type ConfiguracaoAgendaLotacaoQueryVariables = {
  lotacaoId: Scalars['ID']
}

export type ConfiguracaoAgendaLotacaoQuery = {
  __typename?: 'Query'
  lotacao: {
    __typename?: 'Lotacao'
    id: string
    hasConfiguracaoAgenda?: Maybe<boolean>
    hasConfiguracaoAgendaOnline?: Maybe<boolean>
    hasAgendamentoFuturo?: Maybe<boolean>
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
    configuracaoAgenda?: Maybe<{
      __typename?: 'ConfiguracaoAgendaLotacao'
      configuracaoHorarioAgenda: {
        __typename?: 'ConfiguracaoHorarioAgenda'
        configuracaoDiasSemana?: Maybe<
          Array<{
            __typename?: 'ConfiguracaoDiaSemana'
            diaSemana: DiaSemanaEnum
            periodos?: Maybe<
              Array<{
                __typename?: 'ConfiguracaoPeriodo'
                periodo: PeriodoEnum
                horarioInicial?: Maybe<string>
                horarioFinal?: Maybe<string>
              }>
            >
          }>
        >
      }
    }>
    actions: {
      __typename?: 'LotacaoActions'
      permissaoConfigurarAgenda: { __typename?: 'Action'; enabled: boolean }
      permissaoFecharAgenda: { __typename?: 'Action'; enabled: boolean }
    }
  }
  conexao: { __typename?: 'ConfiguracaoConexao'; agendaOnline?: Maybe<{ __typename?: 'AgendaOnline'; ativa: boolean }> }
  duracaoAtendimentoPadraoMunicipio?: Maybe<{
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }>
  configuracaoHorariosMunicipio: {
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }
}

export type DesativarConfiguracaoAgendaOnlineLotacaoMutationVariables = {
  id: Scalars['ID']
}

export type DesativarConfiguracaoAgendaOnlineLotacaoMutation = {
  __typename?: 'Mutation'
  desativarConfiguracaoAgendaOnlineLotacao: boolean
}

export type ExcluirConfiguracaoAgendaLotacaoMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirConfiguracaoAgendaLotacaoMutation = {
  __typename?: 'Mutation'
  excluirConfiguracaoAgendaLotacao: boolean
}

export type SalvarConfiguracaoAgendaLotacaoMutationVariables = {
  configuracaoAgendaLotacaoInput?: Maybe<ConfiguracaoAgendaLotacaoInput>
}

export type SalvarConfiguracaoAgendaLotacaoMutation = {
  __typename?: 'Mutation'
  salvarConfiguracaoAgendaLotacao?: Maybe<boolean>
}

export type ExcluirFechamentoAgendaMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirFechamentoAgendaMutation = { __typename?: 'Mutation'; excluirFechamento?: Maybe<string> }

export type FechamentoAgendaTableQueryVariables = {
  input: FechamentoAgendaQueryInput
}

export type FechamentoAgendaTableQuery = {
  __typename?: 'Query'
  fechamentos: {
    __typename?: 'FechamentoAgendaQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'FechamentoAgenda'
        id?: Maybe<string>
        dataInicial?: Maybe<any>
        dataFinal?: Maybe<any>
        motivo?: Maybe<string>
        especifique?: Maybe<string>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type SalvarFechamentoAgendaMutationVariables = {
  input: FechamentoAgendaInput
}

export type SalvarFechamentoAgendaMutation = {
  __typename?: 'Mutation'
  salvarFechamento?: Maybe<{ __typename?: 'FechamentoAgenda'; id?: Maybe<string> }>
}

export type ProfissionalAcessosQueryVariables = {
  profissionalId: Scalars['ID']
}

export type ProfissionalAcessosQuery = {
  __typename?: 'Query'
  profissional: {
    __typename?: 'Profissional'
    id: string
    cns?: Maybe<string>
    acessos?: Maybe<
      Array<
        | {
            __typename?: 'Lotacao'
            importada: boolean
            hasConfiguracaoAgenda?: Maybe<boolean>
            hasConfiguracaoAgendaOnline?: Maybe<boolean>
            hasAgendamentoFuturo?: Maybe<boolean>
            id: string
            tipo: TipoAcesso
            ativo: boolean
            cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
            equipe?: Maybe<{
              __typename?: 'Equipe'
              id: string
              nome: string
              ine: string
              tipoEquipe: { __typename?: 'TipoEquipe'; id: string; descricao?: Maybe<string> }
            }>
            unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
            perfis?: Maybe<Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>>
            actions: {
              __typename?: 'LotacaoActions'
              permissaoConfigurarAgenda: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoConfigurarAgendaOnline: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoFecharAgenda: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
        | {
            __typename?: 'Estagio'
            nivelTecnico: boolean
            dataFimVigencia: any
            instituicaoEnsino?: Maybe<string>
            dataInicioVigencia: any
            id: string
            tipo: TipoAcesso
            ativo: boolean
            lotacaoSupervisora: {
              __typename?: 'Lotacao'
              id: string
              profissional: { __typename?: 'Profissional'; id: string; nome: string }
              cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
            }
            cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
            equipe?: Maybe<{
              __typename?: 'Equipe'
              id: string
              nome: string
              ine: string
              tipoEquipe: { __typename?: 'TipoEquipe'; id: string; descricao?: Maybe<string> }
            }>
            unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
            perfis: Array<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>
            actions: {
              __typename?: 'AcessoActions'
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
        | {
            __typename?: 'AdministradorGeral'
            id: string
            tipo: TipoAcesso
            ativo: boolean
            perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
            actions: {
              __typename?: 'AcessoActions'
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
        | {
            __typename?: 'AdministradorMunicipal'
            id: string
            tipo: TipoAcesso
            ativo: boolean
            municipio: { __typename?: 'Municipio'; id: string; nome: string }
            perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
            actions: {
              __typename?: 'AcessoActions'
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
        | {
            __typename?: 'GestorEstadual'
            id: string
            tipo: TipoAcesso
            ativo?: Maybe<boolean>
            uf: { __typename?: 'UF'; id: string; nome: string }
            perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
            actions: {
              __typename?: 'AcessoActions'
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
        | {
            __typename?: 'GestorMunicipal'
            id: string
            tipo: TipoAcesso
            ativo?: Maybe<boolean>
            municipio: { __typename?: 'Municipio'; id: string; nome: string }
            perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
            actions: {
              __typename?: 'AcessoActions'
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
      >
    >
  }
}

export type ProfissionalDetailFragment = {
  __typename?: 'Profissional'
  cns?: Maybe<string>
  cpf: string
  dataNascimento?: Maybe<any>
  email?: Maybe<string>
  id: string
  nome: string
  numeroConselhoClasse?: Maybe<string>
  sexo?: Maybe<string>
  telefone?: Maybe<string>
  conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; descricao?: Maybe<string>; sigla: string }>
  endereco?: Maybe<{
    __typename?: 'Endereco'
    bairro?: Maybe<string>
    cep?: Maybe<string>
    complemento?: Maybe<string>
    logradouro?: Maybe<string>
    numero?: Maybe<string>
    pontoReferencia?: Maybe<string>
    semNumero?: Maybe<boolean>
    municipio?: Maybe<{
      __typename?: 'Municipio'
      id: string
      ibge?: Maybe<string>
      nome: string
      uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
    }>
    tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>
    uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
  }>
  ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
}

export type ProfissionalDetailQueryVariables = {
  id: Scalars['ID']
}

export type ProfissionalDetailQuery = {
  __typename?: 'Query'
  profissional: {
    __typename?: 'Profissional'
    lotacoes: Array<{
      __typename?: 'Lotacao'
      ativo: boolean
      id: string
      cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
      equipe?: Maybe<{
        __typename?: 'Equipe'
        id: string
        ine: string
        nome: string
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
      unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    }>
  } & ProfissionalDetailFragment
}

export type AcessoCreateQueryVariables = {
  profissionalId: Scalars['ID']
}

export type AcessoCreateQuery = {
  __typename?: 'Query'
  profissional: { __typename?: 'Profissional'; id: string; nome: string }
}

export type AcessoEditQueryVariables = {
  acessoId: Scalars['ID']
  profissionalId: Scalars['ID']
}

export type AcessoEditQuery = {
  __typename?: 'Query'
  acesso:
    | {
        __typename?: 'Lotacao'
        tipo: TipoAcesso
        actions: { __typename?: 'LotacaoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis?: Maybe<Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>>
      }
    | {
        __typename?: 'Estagio'
        tipo: TipoAcesso
        actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>
      }
    | {
        __typename?: 'AdministradorGeral'
        tipo: TipoAcesso
        actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
      }
    | {
        __typename?: 'AdministradorMunicipal'
        tipo: TipoAcesso
        actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
      }
    | {
        __typename?: 'GestorEstadual'
        tipo: TipoAcesso
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
      }
    | {
        __typename?: 'GestorMunicipal'
        tipo: TipoAcesso
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
        actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
      }
  profissional: { __typename?: 'Profissional'; id: string; nome: string }
}

export type SalvarAcessoMutationVariables = {
  input: OutroAcessoInput
}

export type SalvarAcessoMutation = {
  __typename?: 'Mutation'
  salvarOutroAcesso:
    | { __typename?: 'Lotacao'; id: string }
    | { __typename?: 'Estagio'; id: string }
    | { __typename?: 'AdministradorGeral'; id: string }
    | { __typename?: 'AdministradorMunicipal'; id: string }
    | { __typename?: 'GestorEstadual'; id: string }
    | { __typename?: 'GestorMunicipal'; id: string }
}

export type AgendaEmConflitoQueryVariables = {
  id: Scalars['ID']
}

export type AgendaEmConflitoQuery = { __typename?: 'Query'; agendaEmConflito: boolean }

export type ExcluirLotacaoMutationVariables = {
  lotacaoId: Scalars['ID']
}

export type ExcluirLotacaoMutation = { __typename?: 'Mutation'; excluirLotacao: string }

export type ProfissionaisLotacoesSectionQueryVariables = {}

export type ProfissionaisLotacoesSectionQuery = {
  __typename?: 'Query'
  conexao: { __typename?: 'ConfiguracaoConexao'; agendaOnline?: Maybe<{ __typename?: 'AgendaOnline'; ativa: boolean }> }
}

export type AlterarLotacaoMutationVariables = {
  input: AlterarLotacaoInput
}

export type AlterarLotacaoMutation = { __typename?: 'Mutation'; alterarLotacao: { __typename?: 'Lotacao'; id: string } }

export type CriarLotacaoMutationVariables = {
  input: LotacaoInput
}

export type CriarLotacaoMutation = { __typename?: 'Mutation'; criarLotacao: { __typename?: 'Lotacao'; id: string } }

export type LotacaoCreateQueryVariables = {
  profissionalId: Scalars['ID']
}

export type LotacaoCreateQuery = {
  __typename?: 'Query'
  profissional: { __typename?: 'Profissional'; id: string; nome: string }
}

export type LotacaoEditViewQueryVariables = {
  id: Scalars['ID']
}

export type LotacaoEditViewQuery = {
  __typename?: 'Query'
  lotacao: {
    __typename?: 'Lotacao'
    id: string
    importada: boolean
    hasConfiguracaoAgenda?: Maybe<boolean>
    atualizaPerfil?: Maybe<boolean>
    ativo: boolean
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    equipe?: Maybe<{
      __typename?: 'Equipe'
      id: string
      nome: string
      ine: string
      tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
    }>
    perfis?: Maybe<Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>>
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    actions: { __typename?: 'LotacaoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
  }
}

export type AlterarAtivacaoAcessoMutationVariables = {
  id: Scalars['ID']
}

export type AlterarAtivacaoAcessoMutation = { __typename?: 'Mutation'; alterarAtivacaoAcesso: boolean }

export type ExcluirAcessoMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirAcessoMutation = { __typename?: 'Mutation'; excluirAcesso: string }

export type DefinirSenhaProvisoriaMutationVariables = {
  input: DefinirSenhaProvisoriaInput
}

export type DefinirSenhaProvisoriaMutation = { __typename?: 'Mutation'; definirSenhaProvisoria: boolean }

export type ExcluirProfissionalMutationVariables = {
  profissionalId: Scalars['ID']
}

export type ExcluirProfissionalMutation = { __typename?: 'Mutation'; excluirProfissional: string }

export type ProfissionalTableQueryVariables = {
  input: ProfissionaisQueryInput
}

export type ProfissionalTableQuery = {
  __typename?: 'Query'
  profissionais: {
    __typename?: 'ProfissionaisQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Profissional'
        id: string
        nome: string
        cpf: string
        cns?: Maybe<string>
        lotacoes: Array<{
          __typename?: 'Lotacao'
          id: string
          ativo: boolean
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
        }>
        estagios: Array<{
          __typename?: 'Estagio'
          id: string
          ativo: boolean
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
        }>
        usuario: { __typename?: 'Usuario'; id: string; bloqueado: boolean }
        actions: {
          __typename?: 'ProfissionalActions'
          redefinirSenha: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type SolicitarRedefinicaoSenhaMutationVariables = {
  input: SolicitarRecuperacaoSenhaInput
}

export type SolicitarRedefinicaoSenhaMutation = {
  __typename?: 'Mutation'
  solicitarRedefinicaoSenha: { __typename?: 'QRCode'; value: string; base64PngImage: string }
}

export type SalvarJustificativaVisualizarProntuarioMutationVariables = {
  input: JustificativaVisualizarProntuarioInput
}

export type SalvarJustificativaVisualizarProntuarioMutation = {
  __typename?: 'Mutation'
  salvarJustificativaVisualizarProntuario: string
}

export type RedefinirSenhaMutationVariables = {
  input: RedefinirSenhaInput
}

export type RedefinirSenhaMutation = { __typename?: 'Mutation'; redefinirSenha: boolean }

export type SolicitacaoRecuperarSenhaMutationVariables = {
  input: SolicitarRecuperacaoSenhaInput
}

export type SolicitacaoRecuperarSenhaMutation = { __typename?: 'Mutation'; solicitarRecuperarSenha: boolean }

export type AtendimentoRegistroTardioQueryVariables = {
  id: Scalars['ID']
}

export type AtendimentoRegistroTardioQuery = {
  __typename?: 'Query'
  atendimento: {
    __typename?: 'Atendimento'
    id: string
    justificativa?: Maybe<RegistroTardioJustificativaEnum>
    iniciadoEm: any
    cidadao: {
      __typename?: 'CidadaoBasico'
      id: string
      nome: string
      nomeSocial?: Maybe<string>
      dataNascimento?: Maybe<any>
    }
    responsavel?: Maybe<
      | {
          __typename?: 'Lotacao'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
          cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        }
      | {
          __typename?: 'Estagio'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string }
          cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        }
    >
    actions: { __typename?: 'AtendimentoActions'; editar?: Maybe<{ __typename?: 'Action'; enabled: boolean }> }
    localAtendimento?: Maybe<{ __typename?: 'LocalAtendimento'; id: string; localAtendimentoExibicao?: Maybe<string> }>
  }
}

export type EditarRegistroTardioMutationVariables = {
  input: RegistroTardioInput
}

export type EditarRegistroTardioMutation = {
  __typename?: 'Mutation'
  editarRegistroTardio: { __typename?: 'Atendimento'; id: string }
}

export type SalvarRegistroTardioMutationVariables = {
  input: RegistroTardioInput
}

export type SalvarRegistroTardioMutation = {
  __typename?: 'Mutation'
  salvarRegistroTardio: { __typename?: 'Atendimento'; id: string }
}

export type UltimoAtendimentoProfissionalCidadaoQueryVariables = {
  input?: Maybe<UltimoAtendimentoProfissionalFinalizadoQueryInput>
}

export type UltimoAtendimentoProfissionalCidadaoQuery = {
  __typename?: 'Query'
  ultimoAtendimentoProfissionalCidadao?: Maybe<{
    __typename?: 'AtendimentoProfissional'
    id: string
    finalizadoEm?: Maybe<any>
    lotacao: {
      __typename?: 'Lotacao'
      id: string
      cbo: { __typename?: 'Cbo'; id: string; nome: string }
      profissional: { __typename?: 'Profissional'; id: string; nome: string }
    }
  }>
}

export type AbsenteismosQueryVariables = {
  input: DateRangeDto
}

export type AbsenteismosQuery = {
  __typename?: 'Query'
  absenteismos?: Maybe<
    Array<{
      __typename?: 'Absenteismo'
      id: string
      municipio?: Maybe<string>
      unidadeSaude?: Maybe<string>
      equipe?: Maybe<string>
      nomeProfissional?: Maybe<string>
      nomeSituacaoAgendamento: string
      categoriaProfissional?: Maybe<string>
      turno: string
      intervaloAgendamento: string
      faixaEtaria: string
      cbo?: Maybe<string>
      producoes?: Maybe<string>
    }>
  >
}

export type ModeloPersonalizadoRelatorioGerencialQueryVariables = {
  id: Scalars['ID']
}

export type ModeloPersonalizadoRelatorioGerencialQuery = {
  __typename?: 'Query'
  modeloPersonalizadoRelatorioGerencialById?: Maybe<{
    __typename?: 'ModeloPersonalizadoRelatorioGerencial'
    id: string
    nome: string
    isPublico: boolean
    tipoModulo: TipoModuloEnum
    atorPapelCriadorId: string
    modelo: {
      __typename?: 'ModeloRelatorioGerencial'
      unidadePeriodo: UnidadePeriodoRelatorioGerencialEnum
      opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum
      aggregator: AggregatorRelatorioGerencialEnum
      ciaps?: Maybe<Array<{ __typename?: 'Ciap' } & CiapFragment>>
      cids?: Maybe<Array<{ __typename?: 'Cid10' } & Cid10Fragment>>
      gruposCondicoes?: Maybe<
        Array<{
          __typename?: 'GrupoCondicaoDto'
          id: string
          grupoCondicao: GrupoCondicaoEnum
          ciaps?: Maybe<Array<Maybe<{ __typename?: 'Ciap' } & CiapFragment>>>
          cids?: Maybe<Array<Maybe<{ __typename?: 'Cid10' } & Cid10Fragment>>>
        }>
      >
      campos: Array<{
        __typename?: 'CampoSalvoRelatorioGerencial'
        key: string
        posicao: PosicaoCampoRelatorioGerencialEnum
        items: Array<string>
      }>
    }
  }>
}

export type ModelosByTipoQueryVariables = {
  tipoModulo: TipoModuloEnum
}

export type ModelosByTipoQuery = {
  __typename?: 'Query'
  modelosPersonalizadosByTipoModulo: Array<{
    __typename?: 'ModeloPersonalizadoSelect'
    id: string
    nome: string
    isPublico: boolean
  }>
}

export type EditarModeloPersonalizadoRelatorioGerencialMutationVariables = {
  input: EditarModeloRelatorioGerencialInput
}

export type EditarModeloPersonalizadoRelatorioGerencialMutation = {
  __typename?: 'Mutation'
  editarModeloPersonalizadoRelatorioGerencial?: Maybe<{
    __typename?: 'ModeloPersonalizado'
    id: string
    nome: string
    isPublico: boolean
    tipoModulo: TipoModuloEnum
    modelo: string
  }>
}

export type ExcluirModeloPersonalizadoRelatorioGerencialMutationVariables = {
  input: ExcluirModeloRelatorioGerencialInput
}

export type ExcluirModeloPersonalizadoRelatorioGerencialMutation = {
  __typename?: 'Mutation'
  excluirModeloPersonalizadoRelatorioGerencial?: Maybe<boolean>
}

export type SalvarModeloPersonalizadoRelatorioGerencialMutationVariables = {
  input: SalvarModeloRelatorioGerencialInput
}

export type SalvarModeloPersonalizadoRelatorioGerencialMutation = {
  __typename?: 'Mutation'
  salvarModeloPersonalizadoRelatorioGerencial?: Maybe<{
    __typename?: 'ModeloPersonalizado'
    id: string
    nome: string
    isPublico: boolean
    tipoModulo: TipoModuloEnum
    modelo: string
  }>
}

export type DataUltimoProcessamentoQueryVariables = {}

export type DataUltimoProcessamentoQuery = { __typename?: 'Query'; dataUltimoProcessamento?: Maybe<any> }

export type InfoProfissionalCpfQueryVariables = {
  input: CpfContraChaveInput
}

export type InfoProfissionalCpfQuery = {
  __typename?: 'Query'
  profissionalByCpfContraChave?: Maybe<{ __typename?: 'Profissional'; id: string }>
}

export type RedefinirSenhaAdminInstalacaoMutationVariables = {
  input: RedefinirSenhaAdminInstalacaoInput
}

export type RedefinirSenhaAdminInstalacaoMutation = { __typename?: 'Mutation'; redefinirSenhaAdminInstalacao: boolean }

export type SaveAdminProfissionalMutationVariables = {
  input: ProfissionalContraChaveInput
}

export type SaveAdminProfissionalMutation = {
  __typename?: 'Mutation'
  salvarProfissionalContraChave: { __typename?: 'Profissional'; id: string }
}

export type ValidateNovoAdminQueryVariables = {
  input: ValidateNovoAdminInput
}

export type ValidateNovoAdminQuery = { __typename?: 'Query'; validatePermissaoNovoAdmin: boolean }

export type ValidateResetAdminQueryVariables = {
  input: CpfContraChaveInput
}

export type ValidateResetAdminQuery = { __typename?: 'Query'; validateResetAdmin: boolean }

export type AlteraAdminMutationVariables = {
  input: ValidateNovoAdminInput
}

export type AlteraAdminMutation = { __typename?: 'Mutation'; denifinirNovoAdministrador?: Maybe<boolean> }

export type ChaveByCpfQueryVariables = {
  cpf: Scalars['String']
}

export type ChaveByCpfQuery = { __typename?: 'Query'; chaveByCpf: string }

export type ProfissionalAdminDetailQueryVariables = {
  input: CpfContraChaveInput
}

export type ProfissionalAdminDetailQuery = {
  __typename?: 'Query'
  profissionalByCpfContraChave?: Maybe<
    { __typename?: 'Profissional'; usuario: { __typename?: 'Usuario'; id: string } } & ProfissionalDetailFragment
  >
}

export type LoadImoveisQueryVariables = {
  input: ImoveisQueryInput
}

export type LoadImoveisQuery = {
  __typename?: 'Query'
  imoveis: {
    __typename?: 'ImoveisQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'ImovelDto'
        id: string
        logradouro?: Maybe<string>
        bairro?: Maybe<string>
        microarea?: Maybe<string>
        numero?: Maybe<string>
        cep?: Maybe<string>
        complemento?: Maybe<string>
        tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string }>
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string; ativo?: Maybe<boolean> }>
        unidadeSaude: {
          __typename?: 'UnidadeSaude'
          id: string
          nome?: Maybe<string>
          cnes: string
          ativo?: Maybe<boolean>
        }
        familias: Array<{
          __typename?: 'Familia'
          id: string
          cidadaos: Array<{
            __typename?: 'CidadaoTerritorio'
            id: string
            permissaoReterritorializar?: Maybe<{ __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }>
            ultimaFichaCadastrada?: Maybe<{
              __typename?: 'UltimaFichaCadastradaDto'
              id: string
              isProcessado: boolean
            }>
          }>
          responsavel?: Maybe<{
            __typename?: 'CidadaoTerritorio'
            id: string
            nome?: Maybe<string>
            nomeSocial?: Maybe<string>
          }>
        }>
        permissaoReterritorializar?: Maybe<{ __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }>
        responsavelTecnico?: Maybe<{ __typename?: 'ResponsavelTecnicoImovel'; id: string; nome: string }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type IsProcessTerritorioRunningQueryVariables = {
  input: Scalars['ID']
}

export type IsProcessTerritorioRunningQuery = { __typename?: 'Query'; isProcessamentoTerritorioRunning: boolean }

export type ReterritorializarMutationVariables = {
  input: ReterritorializarInput
}

export type ReterritorializarMutation = { __typename?: 'Mutation'; reterritorializar: boolean }

export type SobreQueryVariables = {}

export type SobreQuery = {
  __typename?: 'Query'
  info: {
    __typename?: 'Info'
    id: string
    commit: string
    competenciaSigtap: string
    databaseVendor: string
    databaseSchemaVersion: string
    recursosDisponiveis: string
    versao: string
    internetHabilitada: boolean
    smtpConfigurado: boolean
    linkInstalacaoConfigurado: boolean
    govBREnabled: boolean
  }
}

export type AceitarTermosUsoMutationVariables = {}

export type AceitarTermosUsoMutation = {
  __typename?: 'Mutation'
  aceitarTermosUso?: Maybe<{ __typename?: 'Usuario'; id: string; forcarTrocaSenha: boolean; aceitouTermosUso: boolean }>
}

export type AlternarTransmissaoLinkMutationVariables = {
  id: Scalars['ID']
}

export type AlternarTransmissaoLinkMutation = { __typename?: 'Mutation'; alternarTransmissaoLink: string }

export type ExcluirTransmissaoLinkMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirTransmissaoLinkMutation = { __typename?: 'Mutation'; excluirTransmissaoLink: string }

export type InserirTransmissaoLinkMutationVariables = {
  form: TransmissaoLinkInsertDto
}

export type InserirTransmissaoLinkMutation = { __typename?: 'Mutation'; inserirTransmissaoLink: string }

export type TransmissaoLinkQueryVariables = {
  input: TransmissaoLinkPagingQueryFiltroDto
}

export type TransmissaoLinkQuery = {
  __typename?: 'Query'
  transmissaoLink: {
    __typename?: 'TransmissaoLinksPayload'
    content: Array<
      Maybe<{
        __typename?: 'TransmissaoLink'
        id: string
        hostname: string
        name: string
        ativo: boolean
        statusConexao?: Maybe<boolean>
        dataTesteConexao?: Maybe<any>
        actions: {
          __typename?: 'TransmissaoLinkActions'
          alternarAtivacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
          excluir: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
          editar: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type TestarConexaoLinkTransmissaoMutationVariables = {
  id: Scalars['ID']
}

export type TestarConexaoLinkTransmissaoMutation = {
  __typename?: 'Mutation'
  testarConexaoLinkTransmissao: {
    __typename?: 'ResultadoTesteConexao'
    validationDescription: string
    link: {
      __typename?: 'TransmissaoLink'
      id: string
      hostname: string
      name: string
      ativo: boolean
      statusConexao?: Maybe<boolean>
      dataTesteConexao?: Maybe<any>
    }
  }
}

export type UpdateTransmissaoLinkMutationVariables = {
  form: TransmissaoLinkUpdateDto
}

export type UpdateTransmissaoLinkMutation = { __typename?: 'Mutation'; updateTransmissaoLink: string }

export type TransmissaoRecebimentoLotesQueryVariables = {
  input: TransmissaoRecebimentoLoteFiltro
}

export type TransmissaoRecebimentoLotesQuery = {
  __typename?: 'Query'
  transmissaoRecebimentoLotes: {
    __typename?: 'TransmissaoRecebimentoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'TransmissaoRecebimentoLoteDto'
        id: string
        numeroLote?: Maybe<string>
        responsavel: string
        processado?: Maybe<boolean>
        data: any
        possuiInconsistencia: boolean
        counts: {
          __typename?: 'TransmissaoRecebimentoLoteCountsDto'
          fichasValidas: number
          fichasInvalidas: number
          totalFichas: number
          fichasRepetidas: number
        }
        origemLote: { __typename?: 'TipoOrigemDadoTransporte'; id: string; descricao: string }
        credencialRecebimento?: Maybe<{
          __typename?: 'CredencialRecebimentoExterno'
          id: string
          nomeResponsavel: string
        }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CredenciaisRecebimentoExternoQueryVariables = {
  input: CredencialRecebimentoExternoQueryInput
}

export type CredenciaisRecebimentoExternoQuery = {
  __typename?: 'Query'
  credenciaisRecebimentoExterno: {
    __typename?: 'CredencialRecebimentoExternoPayload'
    content: Array<
      Maybe<{
        __typename?: 'CredencialRecebimentoExterno'
        id: string
        nomeResponsavel: string
        nomeCredencial: string
        dataCriacao: any
        email: string
        ativo: boolean
        tipoPessoa: TipoPessoaEnum
        cpfCnpj: string
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type InativarCredencialRecebimentoExternoMutationVariables = {
  id: Scalars['ID']
}

export type InativarCredencialRecebimentoExternoMutation = {
  __typename?: 'Mutation'
  inativarCredencialRecebimentoExterno: { __typename?: 'CredencialRecebimentoExterno'; id: string }
}

export type SalvarCredencialRecebimentoExternoMutationVariables = {
  input: CredencialRecebimentoExternoInput
}

export type SalvarCredencialRecebimentoExternoMutation = {
  __typename?: 'Mutation'
  salvarCredencialRecebimentoExterno: {
    __typename?: 'SalvarCredencialRecebimentoExternoPayload'
    userPassword: { __typename?: 'CredencialRecebimentoExternoUserPasswordPayload'; user: string; password: string }
  }
}

export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables = {
  horario: Scalars['LocalTime']
}

export type AlterarHorarioGeracaoLotesProcessamentoFichasMutation = {
  __typename?: 'Mutation'
  alterarHorarioGeracaoLotesProcessamentoFichas: any
}

export type LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables = {}

export type LoadHorarioGeracaoLotesProcessamentoFichasQuery = {
  __typename?: 'Query'
  loadHorarioGeracaoLotesProcessamentoFichas?: Maybe<any>
}

export type LoteEnvioQueryVariables = {
  idLote: Scalars['ID']
  filtroHistorico: HistoricoExportacaoFiltro
}

export type LoteEnvioQuery = {
  __typename?: 'Query'
  loteEnvio: {
    __typename?: 'LoteEnvio'
    id: string
    dataCriacao: any
    quantidadeFichas: number
    nodos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'NodoLoteEnvio'
          id?: Maybe<string>
          dataEnvio?: Maybe<any>
          nodeNome: string
          statusEnvio: StatusEnvio
        }>
      >
    >
    historicoExportacao: {
      __typename?: 'LoteHistoricoExportacaoPayload'
      content: Array<
        Maybe<{ __typename?: 'LoteHistoricoExportacao'; id: string; nomeProfissional: string; dataExportacao: any }>
      >
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
    }
  }
}

export type GerarLotesMutationVariables = {}

export type GerarLotesMutation = { __typename?: 'Mutation'; gerarLote: boolean }

export type LotesEnvioQueryVariables = {
  input: LotesEnvioFiltro
}

export type LotesEnvioQuery = {
  __typename?: 'Query'
  lotesEnvio: {
    __typename?: 'LotesEnvioPayload'
    content: Array<
      Maybe<{
        __typename?: 'LoteEnvio'
        id: string
        statusEnvio: StatusEnvio
        dataCriacao: any
        quantidadeFichas: number
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type TransmissaoRecebimentoCnesDetailItemsQueryVariables = {
  input: TransmissaoRecebimentoCnesDetailFiltro
}

export type TransmissaoRecebimentoCnesDetailItemsQuery = {
  __typename?: 'Query'
  transmissaoRecebimentoCnesDetailItems: Array<
    Maybe<{
      __typename?: 'TransmissaoRecebimentoCnesDetailItem'
      nomeEstabelecimento?: Maybe<string>
      tipoFicha: string
      quantidade: number
    }>
  >
}

export type TransmissaoRecebimentoCnesQueryVariables = {
  input: TransmissaoRecebimentoCnesFiltro
}

export type TransmissaoRecebimentoCnesQuery = {
  __typename?: 'Query'
  transmissaoRecebimentoCnes: {
    __typename?: 'TransmissaoRecebimentoCnesPayload'
    content: Array<
      Maybe<{
        __typename?: 'TransmissaoRecebimentoCnesDto'
        numCnes: string
        mesRecebimento: number
        anoRecebimento: number
        fichasRecebidas: number
        estabelecimentoOrigem?: Maybe<string>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ExistemFichasParaProcessarQueryVariables = {}

export type ExistemFichasParaProcessarQuery = { __typename?: 'Query'; existemFichasParaProcessar: boolean }

export type TransmissaoQueryVariables = {}

export type TransmissaoQuery = {
  __typename?: 'Query'
  transmissao: {
    __typename?: 'Transmissao'
    id: string
    importacaoArquivo: {
      __typename?: 'ImportacaoArquivo'
      dataEvento?: Maybe<any>
      status: StatusImportacaoArquivo
      message?: Maybe<string>
    }
  }
}

export type AtivarTipoServicoMutationVariables = {
  unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput
}

export type AtivarTipoServicoMutation = {
  __typename?: 'Mutation'
  ativarTipoServico: { __typename?: 'RlUnidadeSaudeTipoServicoIds'; unidadeSaudeId: string; tipoServicoId: string }
}

export type DesativarTipoServicoMutationVariables = {
  unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput
}

export type DesativarTipoServicoMutation = {
  __typename?: 'Mutation'
  desativarTipoServico: { __typename?: 'RlUnidadeSaudeTipoServicoIds'; unidadeSaudeId: string; tipoServicoId: string }
}

export type UnidadeSaudeDetailQueryVariables = {
  id: Scalars['ID']
}

export type UnidadeSaudeDetailQuery = {
  __typename?: 'Query'
  unidadeSaude: {
    __typename?: 'UnidadeSaude'
    id: string
    nome?: Maybe<string>
    cnes: string
    cnpj?: Maybe<string>
    email?: Maybe<string>
    telefone1?: Maybe<string>
    telefone2?: Maybe<string>
    fax?: Maybe<string>
    tipoEstabelecimento: TipoEstabelecimentoEnum
    ativo?: Maybe<boolean>
    tipo: { __typename?: 'TipoUnidadeSaude'; id: string; descricao: string }
    subtipo?: Maybe<{
      __typename?: 'SubtipoUnidadeSaude'
      id: string
      codigoMs: string
      descricao?: Maybe<string>
      tipoUnidadeSaude: { __typename?: 'TipoUnidadeSaude'; id: string }
    }>
    complexidades?: Maybe<Array<Maybe<{ __typename?: 'Complexidade'; id: string; nome: string }>>>
    endereco?: Maybe<{
      __typename?: 'Endereco'
      cep?: Maybe<string>
      logradouro?: Maybe<string>
      numero?: Maybe<string>
      semNumero?: Maybe<boolean>
      complemento?: Maybe<string>
      pontoReferencia?: Maybe<string>
      bairro?: Maybe<string>
      municipio?: Maybe<{ __typename?: 'Municipio'; nome: string }>
      uf?: Maybe<{ __typename?: 'UF'; sigla: string }>
      tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; nome: string }>
    }>
    actions: {
      __typename?: 'UnidadeSaudeActions'
      permissaoAtivarInativarTipoServico: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
    }
  }
}

export type UnidadeSaudeTipoServicoQueryVariables = {
  input: UnidadeSaudeTipoServicoQueryInput
}

export type UnidadeSaudeTipoServicoQuery = {
  __typename?: 'Query'
  unidadeSaudeTiposServico: {
    __typename?: 'UnidadeSaudeTiposServicoPayload'
    content: Array<
      Maybe<{
        __typename?: 'UnidadeSaudeTipoServico'
        ativo: boolean
        tipoServico: { __typename?: 'TipoServico'; id: string; nome: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type UnidadeSaudeTableQueryVariables = {
  input: UnidadesSaudeQueryInput
}

export type UnidadeSaudeTableQuery = {
  __typename?: 'Query'
  unidadesSaude: {
    __typename?: 'UnidadeSaudePayload'
    content: Array<
      Maybe<{
        __typename?: 'UnidadeSaude'
        id: string
        cnes: string
        nome?: Maybe<string>
        telefone1?: Maybe<string>
        telefone2?: Maybe<string>
        ativo?: Maybe<boolean>
        endereco?: Maybe<{ __typename?: 'Endereco'; bairro?: Maybe<string> }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type NomesInstalacoesUnificacaoBaseQueryVariables = {}

export type NomesInstalacoesUnificacaoBaseQuery = {
  __typename?: 'Query'
  nomesInstalacoes?: Maybe<{
    __typename?: 'NomesInstalacoesUnificacaoDto'
    nomeInstalacaoCentral?: Maybe<string>
    nomesInstalacoesUnificadas?: Maybe<Array<Maybe<string>>>
  }>
}

export type UnificacaoBaseCadastrosDuplicadosQueryVariables = {
  input: CidadaoUnificacaoBaseInput
}

export type UnificacaoBaseCadastrosDuplicadosQuery = {
  __typename?: 'Query'
  cadastrosDuplicados: {
    __typename?: 'CidadaoUnificacaoBasePayload'
    content: Array<
      Maybe<{
        __typename?: 'CidadaoUnificacaoBase'
        id: any
        cpf?: Maybe<string>
        cns?: Maybe<string>
        cidadao: {
          __typename?: 'Cidadao'
          nome: string
          nomeSocial?: Maybe<string>
          nomeMae?: Maybe<string>
          dataNascimento?: Maybe<any>
          sexo: SexoEnum
          identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
          ativo?: Maybe<boolean>
          faleceu?: Maybe<boolean>
          unificado?: Maybe<boolean>
          unificacaoBase?: Maybe<string>
          localidadeNascimento?: Maybe<{
            __typename?: 'Municipio'
            id: string
            nome: string
            uf: { __typename?: 'UF'; id: string; sigla: string }
          }>
          cidadaoVinculacaoEquipe?: Maybe<{
            __typename?: 'CidadaoVinculacaoEquipe'
            id?: Maybe<string>
            unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
          }>
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type UnificaCadastrosMutationVariables = {
  input: UnificacaoCadastroInput
}

export type UnificaCadastrosMutation = { __typename?: 'Mutation'; unificaCadastros: boolean }

export type UnificacaoCidadaosListingQueryVariables = {
  input: CidadaosQueryInput
}

export type UnificacaoCidadaosListingQuery = {
  __typename?: 'Query'
  listagemCidadaos: {
    __typename?: 'CidadaosPayload'
    content: Array<
      Maybe<{
        __typename?: 'Cidadao'
        id: string
        nome: string
        nomeSocial?: Maybe<string>
        cpf?: Maybe<string>
        cns?: Maybe<string>
        nomeMae?: Maybe<string>
        dataNascimento?: Maybe<any>
        telefoneCelular?: Maybe<string>
        telefoneResidencial?: Maybe<string>
        sexo: SexoEnum
        identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
        dataAtualizado?: Maybe<any>
        ativo?: Maybe<boolean>
        unificado?: Maybe<boolean>
        unificacaoBase?: Maybe<string>
        faleceu?: Maybe<boolean>
        uuidUltimaFicha?: Maybe<string>
        prontuario?: Maybe<{ __typename?: 'Prontuario'; id: string }>
        localidadeNascimento?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
        cidadaoVinculacaoEquipe?: Maybe<{
          __typename?: 'CidadaoVinculacaoEquipe'
          id?: Maybe<string>
          dataAtualizacaoCadastro?: Maybe<any>
          unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
          equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
        }>
      }>
    >
  }
}

export type CancelarSolicitacaoEntradaVideochamadaMutationVariables = {
  input: CancelarSolicitacaoEntradaVideochamadaInput
}

export type CancelarSolicitacaoEntradaVideochamadaMutation = {
  __typename?: 'Mutation'
  cancelarSolicitacaoEntradaVideochamada: boolean
}

export type EncerrarMinhasVideochamadasMutationVariables = {}

export type EncerrarMinhasVideochamadasMutation = { __typename?: 'Mutation'; encerrarMinhasVideochamadas: boolean }

export type EncerrarVideochamadaMutationVariables = {
  videochamadaUuid: Scalars['String']
}

export type EncerrarVideochamadaMutation = { __typename?: 'Mutation'; encerrarVideochamada: boolean }

export type IniciarVideochamadaMutationVariables = {
  input: IniciarVideochamadaInput
}

export type IniciarVideochamadaMutation = { __typename?: 'Mutation'; iniciarVideochamada: string }

export type ResponderSolicitacaoEntradaVideochamadaMutationVariables = {
  input: ResponderSolicitacaoEntradaVideochamadaInput
}

export type ResponderSolicitacaoEntradaVideochamadaMutation = {
  __typename?: 'Mutation'
  responderSolicitacaoEntradaVideochamada: boolean
}

export type VideochamadaViewQueryVariables = {
  uuid: Scalars['String']
}

export type VideochamadaViewQuery = {
  __typename?: 'Query'
  videochamada?: Maybe<{
    __typename?: 'Videochamada'
    id: string
    dataInicio?: Maybe<any>
    dataFim?: Maybe<any>
    agendamento?: Maybe<{ __typename?: 'Agendado'; id: string; horarioInicial?: Maybe<any> }>
    owner?: Maybe<
      | {
          __typename?: 'Lotacao'
          id: string
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        }
      | {
          __typename?: 'Estagio'
          id: string
          cbo: { __typename?: 'Cbo'; id: string; nome: string }
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        }
      | {
          __typename?: 'AdministradorGeral'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        }
      | {
          __typename?: 'AdministradorMunicipal'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        }
      | {
          __typename?: 'GestorEstadual'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        }
      | {
          __typename?: 'GestorMunicipal'
          id: string
          profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        }
    >
  }>
}

export type SolicitarEntradaVideochamadaMutationVariables = {
  input: SolicitarEntradaVideochamadaInput
}

export type SolicitarEntradaVideochamadaMutation = {
  __typename?: 'Mutation'
  solicitarEntradaVideochamada: { __typename?: 'ParticipanteVideochamada'; id: string; nome: string; cpf: string }
}

export type ConfiguracaoVideochamadasQueryVariables = {}

export type ConfiguracaoVideochamadasQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    videochamadas: { __typename?: 'ConfiguracaoVideochamadas'; id: string; habilitado: boolean }
  }
}

export type ConfiguracoesIceServersQueryVariables = {
  input: IceServersConfigurationInput
}

export type ConfiguracoesIceServersQuery = {
  __typename?: 'Query'
  iceServersConfiguration: {
    __typename?: 'IceServersConfig'
    urls: Array<string>
    user?: Maybe<string>
    password?: Maybe<string>
  }
}

export type IsParticipantePermitidoVideochamadaQueryVariables = {
  input: VerificarParticipantePermitidoVideochamadaInput
}

export type IsParticipantePermitidoVideochamadaQuery = {
  __typename?: 'Query'
  isParticipantePermitidoVideochamada: boolean
}

export type NotificarConexaoVideochamadaFalhouMutationVariables = {
  videochamadaUuid: Scalars['String']
}

export type NotificarConexaoVideochamadaFalhouMutation = {
  __typename?: 'Mutation'
  notifyConexaoVideochamadaFalhou: boolean
}

export type NotificarEntradaParticipanteVideochamadaMutationVariables = {
  videochamadaUuid: Scalars['String']
  participanteId: Scalars['ID']
  nomeParticipante: Scalars['String']
}

export type NotificarEntradaParticipanteVideochamadaMutation = {
  __typename?: 'Mutation'
  notifyEntradaParticipanteVideochamada: boolean
}

export type NotificarSaidaDonoVideochamadaMutationVariables = {
  videochamadaUuid: Scalars['String']
}

export type NotificarSaidaDonoVideochamadaMutation = { __typename?: 'Mutation'; notifySaidaDonoVideochamada: boolean }

export type NotificarVideochamadaLotadaMutationVariables = {
  videochamadaUuid: Scalars['String']
  lotada: Scalars['Boolean']
}

export type NotificarVideochamadaLotadaMutation = { __typename?: 'Mutation'; notifyVideochamadaLotada: boolean }

export type StatusVideochamadaQueryVariables = {
  uuid: Scalars['String']
}

export type StatusVideochamadaQuery = {
  __typename?: 'Query'
  videochamada?: Maybe<{ __typename?: 'Videochamada'; id: string; status: StatusVideochamadaEnum }>
}

export type EquipesVinculadasTableQueryVariables = {
  input: EquipesQueryInput
}

export type EquipesVinculadasTableQuery = {
  __typename?: 'Query'
  equipesVinculadas: {
    __typename?: 'EquipePayload'
    content: Array<
      Maybe<{
        __typename?: 'Equipe'
        id: string
        nome: string
        ine: string
        area?: Maybe<string>
        ativo?: Maybe<boolean>
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type HasEquipesVinculadasQueryVariables = {
  equipePrincipalId: Scalars['ID']
}

export type HasEquipesVinculadasQuery = { __typename?: 'Query'; hasEquipesVinculadas: boolean }

export type FamiliasImovelQueryVariables = {
  input: Scalars['ID']
}

export type FamiliasImovelQuery = {
  __typename?: 'Query'
  imovel?: Maybe<{
    __typename?: 'ImovelDto'
    id: string
    familias: Array<{
      __typename?: 'Familia'
      id: string
      cnes: string
      ine: string
      numeroProntuarioFamiliar?: Maybe<string>
      rendaFamiliar?: Maybe<RendaFamiliar>
      resideDesde?: Maybe<any>
      cpfCnsResponsavel?: Maybe<string>
      isResponsavelCadastrado: boolean
      isResponsavelDeclarado: boolean
      isResponsavelVivo: boolean
      isResponsavelUnico: boolean
      isResponsavelAindaReside: boolean
      isFamiliaAindaReside: boolean
      isInformacaoSuficiente: boolean
      isDomicilioAtivo: boolean
      cidadaos: Array<{
        __typename?: 'CidadaoTerritorio'
        id: string
        nome?: Maybe<string>
        nomeSocial?: Maybe<string>
        sexo?: Maybe<SexoEnum>
        dataNascimento?: Maybe<any>
        cpf?: Maybe<string>
        cns?: Maybe<string>
        telefoneCelular?: Maybe<string>
        telefoneContato?: Maybe<string>
        isFaleceu?: Maybe<boolean>
        ultimaFichaCadastrada?: Maybe<{ __typename?: 'UltimaFichaCadastradaDto'; id: string; isProcessado: boolean }>
      }>
    }>
  }>
}

export type ImovelInformacoesCadastraisQueryVariables = {
  input: Scalars['ID']
}

export type ImovelInformacoesCadastraisQuery = {
  __typename?: 'Query'
  imovel?: Maybe<{
    __typename?: 'ImovelDto'
    id: string
    tipoImovel?: Maybe<TipoImovelEnum>
    telefoneContato?: Maybe<string>
    telefoneResidencial?: Maybe<string>
    dataUltimaVisita?: Maybe<any>
    microarea?: Maybe<string>
    nomeInstituicaoPermanencia?: Maybe<string>
    tipoEndereco?: Maybe<TipoEnderecoEnum>
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string; isAD: boolean }>
    profissional: { __typename?: 'Profissional'; id: string; nome: string; cns?: Maybe<string>; cpf: string }
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    ultimaVisitaComAnotacao?: Maybe<{
      __typename?: 'FatoVisitaDomiciliar'
      id: string
      anotacoes?: Maybe<string>
      dataVisita?: Maybe<any>
    }>
    condicoesMoradia: {
      __typename?: 'CondicoesMoradia'
      id: string
      tipoAbastecimentoAgua?: Maybe<TipoAbastecimentoAguaEnum>
      tipoSituacaoMoradia?: Maybe<TipoSituacaoMoradiaEnum>
      tipoTratamentoAgua?: Maybe<TipoTratamentoAguaEnum>
      tipoPosseTerra?: Maybe<TipoPosseTerraEnum>
      tipoMaterialParede?: Maybe<TipoMaterialParedeEnum>
      tipoLocalizacao?: Maybe<TipoLocalizacaoEnum>
      tipoEscoamentoSanitario?: Maybe<TipoEscoamentoSanitarioEnum>
      tipoDomicilio?: Maybe<TipoDomicilioEnum>
      tipoDestinoLixo?: Maybe<TipoDestinoLixoEnum>
      tipoAcessoDomicilio?: Maybe<TipoAcessoDomicilioEnum>
      isEnergiaEletricaDisponivel?: Maybe<boolean>
      numeroComodos?: Maybe<number>
    }
    animaisImovel?: Maybe<{
      __typename?: 'AnimaisImovel'
      id?: Maybe<any>
      possuiAnimais?: Maybe<boolean>
      possuiGato?: Maybe<boolean>
      possuiCachorro?: Maybe<boolean>
      possuiPassaro?: Maybe<boolean>
      possuiMacaco?: Maybe<boolean>
      possuiGalinha?: Maybe<boolean>
      possuiPorco?: Maybe<boolean>
      possuiRepteis?: Maybe<boolean>
      possuiOutros?: Maybe<boolean>
      quantidadeAnimais?: Maybe<number>
    }>
    ultimaFichaCadastrada?: Maybe<{ __typename?: 'UltimaFichaCadastradaDto'; id: string; isProcessado: boolean }>
    responsavelTecnico?: Maybe<{
      __typename?: 'ResponsavelTecnicoImovel'
      id: string
      nome: string
      contato?: Maybe<string>
      cns?: Maybe<string>
      cargo?: Maybe<string>
    }>
  }>
}

export type VisualizarImovelHeaderQueryVariables = {
  input: Scalars['ID']
}

export type VisualizarImovelHeaderQuery = {
  __typename?: 'Query'
  imovel?: Maybe<{
    __typename?: 'ImovelDto'
    id: string
    tipoImovel?: Maybe<TipoImovelEnum>
    logradouro?: Maybe<string>
    numero?: Maybe<string>
    semNumero?: Maybe<boolean>
    complemento?: Maybe<string>
    bairro?: Maybe<string>
    cep?: Maybe<string>
    microarea?: Maybe<string>
    telefoneResidencial?: Maybe<string>
    telefoneContato?: Maybe<string>
    dataUltimaAtualizacao?: Maybe<any>
    isCadastroCompleto?: Maybe<boolean>
    latitude?: Maybe<number>
    longitude?: Maybe<number>
    tipoEndereco?: Maybe<TipoEnderecoEnum>
    numeroResidenciaIndigena?: Maybe<string>
    condicoesMoradia: {
      __typename?: 'CondicoesMoradia'
      id: string
      tipoLocalizacao?: Maybe<TipoLocalizacaoEnum>
      tipoDomicilio?: Maybe<TipoDomicilioEnum>
    }
    tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string }>
    municipio?: Maybe<{
      __typename?: 'Municipio'
      id: string
      nome: string
      uf: { __typename?: 'UF'; id: string; sigla: string }
    }>
    aldeia?: Maybe<{ __typename?: 'Aldeia'; id: string; nome: string }>
    poloBase?: Maybe<{ __typename?: 'PoloBase'; id: string; nome: string }>
    dsei?: Maybe<{ __typename?: 'Dsei'; id: string; nome: string }>
  }>
}

export type ImovelUltimasVisitasQueryVariables = {
  input: VisitasQueryInput
  imovelId: Scalars['ID']
}

export type ImovelUltimasVisitasQuery = {
  __typename?: 'Query'
  visitas: {
    __typename?: 'VisitasQueryPayload'
    content: Array<{
      __typename?: 'FatoVisitaDomiciliar'
      id: string
      nuUuidFicha: string
      dataVisita?: Maybe<any>
      motivoVisita: Array<Maybe<string>>
      buscaAtiva: Array<Maybe<string>>
      acompanhamento: Array<Maybe<string>>
      controleAmbiental: Array<Maybe<string>>
      dimensaoProfissional?: Maybe<{ __typename?: 'DimensaoProfissional'; id: string; nome?: Maybe<string> }>
      dimensaoEquipe?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
      dimensaoCbo?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string; cbo: string }>
      dimensaoDesfechoVisita: {
        __typename?: 'DimensaoDesfechoVisita'
        id: string
        numeroIdentificador: string
        descricao: string
      }
      tipoOrigem?: Maybe<{ __typename?: 'DimensaoTipoOrigem'; id: string; nome: string }>
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
  imovel?: Maybe<{ __typename?: 'ImovelDto'; id: string; tipoImovel?: Maybe<TipoImovelEnum> }>
}

export type ImovelVisitaQueryVariables = {
  input: Scalars['ID']
}

export type ImovelVisitaQuery = {
  __typename?: 'Query'
  visita?: Maybe<{
    __typename?: 'FatoVisitaDomiciliar'
    id: string
    nuUuidFicha: string
    dataVisita?: Maybe<any>
    cpfCidadao?: Maybe<string>
    cnsCidadao?: Maybe<string>
    dataNascimentoCidadao?: Maybe<any>
    motivoVisita: Array<Maybe<string>>
    anotacoes?: Maybe<string>
    buscaAtiva: Array<Maybe<string>>
    acompanhamento: Array<Maybe<string>>
    controleAmbiental: Array<Maybe<string>>
    peso?: Maybe<number>
    altura?: Maybe<number>
    temperatura?: Maybe<string>
    medicaoGlicemia?: Maybe<string>
    medicaoPressaoArterial?: Maybe<string>
    stVisitaCompartilhada?: Maybe<boolean>
    latitude?: Maybe<number>
    longitude?: Maybe<number>
    dimensaoSexo?: Maybe<{ __typename?: 'DimensaoSexo'; id: string; descricao: string }>
    cidadaoPec?: Maybe<{
      __typename?: 'FatoCidadaoPec'
      id: string
      nome?: Maybe<string>
      nomeSocial?: Maybe<string>
      cpf?: Maybe<string>
      cns?: Maybe<string>
      dataNascimento?: Maybe<any>
      dimensaoSexo?: Maybe<{ __typename?: 'DimensaoSexo'; id: string; descricao: string }>
    }>
    dimensaoProfissional?: Maybe<{
      __typename?: 'DimensaoProfissional'
      id: string
      cns?: Maybe<string>
      nome?: Maybe<string>
    }>
    dimensaoUnidadeSaude?: Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome: string; cnes: string }>
    dimensaoEquipe?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
    dimensaoCbo?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string; cbo: string }>
    dimensaoTipoGlicemia?: Maybe<{
      __typename?: 'DimensaoTipoGlicemia'
      id: string
      descricao: string
      numeroIdentificador: string
    }>
    tipoOrigem?: Maybe<{ __typename?: 'DimensaoTipoOrigem'; id: string; nome: string }>
    turno?: Maybe<{ __typename?: 'DimensaoTurno'; id: string; descricao: string }>
    dimensaoDesfechoVisita: {
      __typename?: 'DimensaoDesfechoVisita'
      id: string
      numeroIdentificador: string
      descricao: string
    }
  }>
}

export type ImoveisPermissaoAtualizarQueryVariables = {
  input: TerritorioLogradouroQueryInput
}

export type ImoveisPermissaoAtualizarQuery = {
  __typename?: 'Query'
  imoveisPermissaoAtualizar: {
    __typename?: 'TotalImoveisPermissao'
    totalImoveisPermitidos: number
    totalImoveis: number
  }
}

export type AtualizarEnderecoLogradouroMutationVariables = {
  input: AtualizarEnderecoTerritorioInput
}

export type AtualizarEnderecoLogradouroMutation = { __typename?: 'Mutation'; atualizarEnderecoTerritorio: boolean }

export type ProcessarUltimaFichaCidadaosTerritorioMutationVariables = {
  input: Array<Scalars['ID']>
}

export type ProcessarUltimaFichaCidadaosTerritorioMutation = {
  __typename?: 'Mutation'
  processarCidadaosTerritorio: boolean
}

export type ProcessarUltimaFichaTerritorioMutationVariables = {
  input: Scalars['ID']
}

export type ProcessarUltimaFichaTerritorioMutation = { __typename?: 'Mutation'; processarTerritorio: boolean }

export type TerritorioMicroareaQueryVariables = {
  input: TerritorioMicroareaQueryInput
}

export type TerritorioMicroareaQuery = {
  __typename?: 'Query'
  territorioMicroarea: {
    __typename?: 'TerritorioMicroarea'
    id: string
    logradouros: {
      __typename?: 'LogradourosTerritorioQueryPayload'
      content: Array<{
        __typename?: 'LogradouroTerritorioDto'
        id: string
        logradouro?: Maybe<string>
        tipoLogradouroId?: Maybe<string>
        tipoLogradouroNome?: Maybe<string>
        tipoLogradouroDne?: Maybe<string>
        bairro?: Maybe<string>
        cep?: Maybe<string>
        tipoEndereco: TipoEnderecoEnum
        numeroImoveis: number
        numeroCidadaos: number
        municipio?: Maybe<{
          __typename?: 'Municipio'
          id: string
          ibge?: Maybe<string>
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
        aldeia?: Maybe<{
          __typename?: 'Aldeia'
          id: string
          nome: string
          poloBase: { __typename?: 'PoloBase' } & PoloBaseFragment
          municipio: Array<{ __typename?: 'Municipio' } & MunicipioFragment>
        }>
        poloBase?: Maybe<{ __typename?: 'PoloBase' } & PoloBaseFragment>
        dsei?: Maybe<{ __typename?: 'Dsei'; id: string; nome: string }>
        municipioAldeia?: Maybe<{
          __typename?: 'Municipio'
          id: string
          ibge?: Maybe<string>
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
      }>
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
    }
    indices: {
      __typename?: 'IndicesAcompanhamentoMicroarea'
      quantidadeImoveisAtualizados: any
      quantidadeImoveisCadastroCompleto: any
      quantidadeImoveisAvaliados: any
      quantidadeDomiciliosAvaliados: any
      quantidadeDomiciliosVisitados: any
    }
  }
}

export type VisualizacaoTerritorialQueryVariables = {
  input: TerritorioEquipeQueryInput
}

export type VisualizacaoTerritorialQuery = {
  __typename?: 'Query'
  territorioEquipe: {
    __typename?: 'TerritorioEquipeDto'
    microareas: Array<Maybe<string>>
    poloBase?: Maybe<{ __typename?: 'PoloBase'; id: string; nome: string }>
  }
}

export type VisualizarCidadaosImovelQueryVariables = {
  input: Scalars['ID']
}

export type VisualizarCidadaosImovelQuery = {
  __typename?: 'Query'
  imovel?: Maybe<{
    __typename?: 'ImovelDto'
    id: string
    uuidDomicilio: string
    dataUltimaVisita?: Maybe<any>
    telefoneResidencial?: Maybe<string>
    telefoneContato?: Maybe<string>
    tipoImovel?: Maybe<TipoImovelEnum>
    logradouro?: Maybe<string>
    numero?: Maybe<string>
    complemento?: Maybe<string>
    cep?: Maybe<string>
    bairro?: Maybe<string>
    numeroMoradores?: Maybe<number>
    dataUltimaAtualizacao?: Maybe<any>
    tipoEndereco?: Maybe<TipoEnderecoEnum>
    numeroResidenciaIndigena?: Maybe<string>
    ultimaFichaCadastrada?: Maybe<{ __typename?: 'UltimaFichaCadastradaDto'; id: string; isProcessado: boolean }>
    tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string }>
    familias: Array<{
      __typename?: 'Familia'
      id: string
      cnes: string
      ine: string
      numeroProntuarioFamiliar?: Maybe<string>
      cpfCnsResponsavel?: Maybe<string>
      isResponsavelCadastrado: boolean
      isResponsavelDeclarado: boolean
      isResponsavelVivo: boolean
      isResponsavelUnico: boolean
      isResponsavelAindaReside: boolean
      isFamiliaAindaReside: boolean
      isInformacaoSuficiente: boolean
      isDomicilioAtivo: boolean
      cidadaos: Array<{
        __typename?: 'CidadaoTerritorio'
        id: string
        uuidCidadao?: Maybe<string>
        nome?: Maybe<string>
        nomeSocial?: Maybe<string>
        sexo?: Maybe<SexoEnum>
        dataNascimento?: Maybe<any>
        cpf?: Maybe<string>
        cns?: Maybe<string>
        telefoneCelular?: Maybe<string>
        telefoneContato?: Maybe<string>
        isFaleceu?: Maybe<boolean>
        ultimaFichaCadastrada?: Maybe<{
          __typename?: 'UltimaFichaCadastradaDto'
          id: string
          uuidFichaOrigem?: Maybe<string>
          isProcessado: boolean
        }>
      }>
    }>
    condicoesMoradia: {
      __typename?: 'CondicoesMoradia'
      id: string
      tipoLocalizacao?: Maybe<TipoLocalizacaoEnum>
      tipoDomicilio?: Maybe<TipoDomicilioEnum>
    }
    responsavelTecnico?: Maybe<{
      __typename?: 'ResponsavelTecnicoImovel'
      id: string
      nome: string
      contato?: Maybe<string>
      cns?: Maybe<string>
      cargo?: Maybe<string>
    }>
    aldeia?: Maybe<{ __typename?: 'Aldeia'; id: string; nome: string }>
    poloBase?: Maybe<{ __typename?: 'PoloBase'; id: string; nome: string }>
    dsei?: Maybe<{ __typename?: 'Dsei'; id: string; nome: string }>
  }>
}

export type VisualizarImoveisQueryVariables = {
  input: TerritorioLogradouroQueryInput
}

export type VisualizarImoveisQuery = {
  __typename?: 'Query'
  territorioLogradouro: Array<{
    __typename?: 'ImovelDto'
    id: string
    uuidDomicilio: string
    numero?: Maybe<string>
    complemento?: Maybe<string>
    cep?: Maybe<string>
    tipoImovel?: Maybe<TipoImovelEnum>
    dataUltimaVisita?: Maybe<any>
    numeroMoradores?: Maybe<number>
    dataUltimaAtualizacao?: Maybe<any>
    isCadastroCompleto?: Maybe<boolean>
    descricaoTipoDomicilio?: Maybe<string>
    descricaoMaterialParede?: Maybe<string>
    numeroResidenciaIndigena?: Maybe<string>
    familias: Array<{
      __typename?: 'Familia'
      id: string
      responsavel?: Maybe<{
        __typename?: 'CidadaoTerritorio'
        id: string
        nome?: Maybe<string>
        nomeSocial?: Maybe<string>
      }>
      cidadaos: Array<{ __typename?: 'CidadaoTerritorio'; id: string }>
    }>
    responsavelTecnico?: Maybe<{ __typename?: 'ResponsavelTecnicoImovel'; id: string; nome: string }>
    ultimaFichaCadastrada?: Maybe<{ __typename?: 'UltimaFichaCadastradaDto'; id: string; isProcessado: boolean }>
    condicoesMoradia: { __typename?: 'CondicoesMoradia'; tipoDomicilio?: Maybe<TipoDomicilioEnum> }
  }>
}
