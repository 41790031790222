/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormFooter, RadioField, SubmitButton } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { useFlags } from 'config/useFlagsContext'
import { FormApi } from 'final-form'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Fragment, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { downloadAcompanhamentoCondicaoSaudeCSV, downloadRelatorio } from '../downloadRelatorio'

export interface RelatorioOperacionalAuthorization {
  hasCriancaPermission: boolean
  hasGestantePermission: boolean
  hasHipertensaoPermission: boolean
}

interface AcompanhamentoCondicaoSaudeRelatoriosProps {
  disableGerarRelatorio: boolean
  filter: AcompanhamentoCondicaoSaudeQueryInput
  canAcessarModalRelatorio: boolean
  permissions: RelatorioOperacionalAuthorization
  shouldDisableCsvButton: boolean
  mensagemTooltipCsv: string
  setShouldRefetch: (boolean) => void
}
export interface AcompanhamentoCondicaoSaudeRelatorioModel {
  tipoRelatorio: TipoRelatorio
}

export enum TipoRelatorio {
  CRIANCA = 'CRIANCA',
  GESTANTE = 'GESTANTE',
  RISCO_CARDIOVASCULAR = 'RISCO_CARDIOVASCULAR',
}

const tipoRelatorioOperacionalLabel: Record<TipoRelatorio, string> = {
  [TipoRelatorio.CRIANCA]: 'Relatório operacional de Crianças menores de 5 anos',
  [TipoRelatorio.GESTANTE]: 'Relatório operacional de Gestante/Puérpera',
  [TipoRelatorio.RISCO_CARDIOVASCULAR]: 'Relatório operacional de Risco cardiovascular',
}

export function AcompanhamentoCondicaoSaudeRelatorios(props: AcompanhamentoCondicaoSaudeRelatoriosProps) {
  const {
    disableGerarRelatorio,
    filter,
    canAcessarModalRelatorio,
    permissions,
    shouldDisableCsvButton,
    mensagemTooltipCsv,
    setShouldRefetch,
  } = props
  const path = metaPath<AcompanhamentoCondicaoSaudeRelatorioModel>()
  const [isOpen, setOpen] = useState(false)
  const alert = useAlert()
  const { analytics } = useFirebase()
  const { ACOMPANHAMENTO_CONDICAO_SAUDE_CSV } = useFlags()

  const afterSubmit = () => {
    setShouldRefetch(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleExportarCSVClick = () => {
    analytics.logEvent('exportar_csv_acompanhamento_condicao_saude')
    downloadAcompanhamentoCondicaoSaudeCSV(filter)
    afterSubmit()
  }

  const renderModal = (props: FormRenderProps<AcompanhamentoCondicaoSaudeRelatorioModel>) => {
    const handleCancelar = () => {
      setOpen(false)
      props.form.reset()
    }

    return (
      <Modal size='small' open={isOpen} onClose={handleCancelar} closeOnBackdropClick={false}>
        <ModalBody>
          <Heading level={1}>Gerar relatório operacional</Heading>
          <Alert
            type='info'
            styles={{
              wrapper: css`
                padding: 0.5rem;
              `,
              container: css`
                margin: 1rem 0%;
              `,
            }}
          >
            Serão apresentados apenas cidadãos que estejam vinculados a um responsável familiar e este vinculado a um
            domicílio do território. Caso algum relatório esteja desabilitado, verificar suas permissões.
          </Alert>
          <p>Selecione qual relatório deseja gerar:</p>
          <VFlow
            vSpacing={0.5}
            style={css`
              margin-top: 1.5rem;
            `}
          >
            <HFlow hSpacing={0.5} style={styles.radioContainer}>
              <RadioField
                name={path.tipoRelatorio}
                value={TipoRelatorio.CRIANCA}
                label='Relatório operacional de Crianças menores de 5 anos'
                disabled={!permissions.hasCriancaPermission}
              />
              <InfoIcon
                icon='infoCircleFilled'
                text='Fonte de informação: FCI, FCDT, FAE, FAI, FP, FAOI, FVDT, Atendimento PEC, Aplicativo e-SUS Território.'
              />
            </HFlow>
            <HFlow hSpacing={0.5} style={styles.radioContainer}>
              <RadioField
                name={path.tipoRelatorio}
                value={TipoRelatorio.GESTANTE}
                label='Relatório operacional de Gestante/Puérpera'
                disabled={!permissions.hasGestantePermission}
              />
              <InfoIcon
                icon='infoCircleFilled'
                text='Fonte de informação: FCI, FCDT, FAE, FAI, FAOI, FVDT, Atendimento PEC, Aplicativo e-SUS Território.'
              />
            </HFlow>
            <HFlow hSpacing={0.5} style={styles.radioContainer}>
              <RadioField
                name={path.tipoRelatorio}
                value={TipoRelatorio.RISCO_CARDIOVASCULAR}
                label='Relatório operacional de Risco cardiovascular'
                disabled={!permissions.hasHipertensaoPermission}
              />
              <InfoIcon
                icon='infoCircleFilled'
                text='Fonte de informação: FCI, FCDT, FAE, FAI, Atendimento PEC, Aplicativo e-SUS Território.'
              />
            </HFlow>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleCancelar}>Cancelar</Button>
            <Tooltip text={!props.values?.tipoRelatorio && 'Selecione o tipo de relatório'}>
              <SubmitButton kind='primary' disabled={!props.values?.tipoRelatorio} handleSubmit={props.handleSubmit}>
                Gerar relatório
              </SubmitButton>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const handleModalSubmit = (
    values: AcompanhamentoCondicaoSaudeRelatorioModel,
    formApi: FormApi<AcompanhamentoCondicaoSaudeRelatorioModel>
  ) => {
    analytics.logEvent('relatorio_ope_acomp_cond_saude', {
      relatorio: tipoRelatorioOperacionalLabel[values.tipoRelatorio],
    })
    return downloadRelatorio(values.tipoRelatorio, filter)
      .then(() => {
        alert('success', 'Relatório operacional gerado com sucesso.')
        setOpen(false)
        setTimeout(() => formApi.reset())
      })
      .catch((errorMessage) => {
        alert('warning', errorMessage)
        setOpen(false)
        setTimeout(() => formApi.reset())
      })
  }

  const hintBtnGerarRelatorio = canAcessarModalRelatorio
    ? disableGerarRelatorio && filter?.pageParams?.size > 500
      ? 'A busca de cidadãos retornou mais de 500 resultados. Refine melhor a busca para poder gerar relatórios operacionais.'
      : 'A busca de cidadãos não encontrou nenhum resultado com os filtros aplicados.'
    : 'Acesso não permitido.'

  const styles = {
    radioContainer: css`
      display: flex;
      align-items: center;
    `,
  }

  return (
    <Fragment>
      <FormFooter>
        <HFlow justifyContent='flex-end'>
          {ACOMPANHAMENTO_CONDICAO_SAUDE_CSV && (
            <Tooltip text={mensagemTooltipCsv}>
              <Button onClick={handleExportarCSVClick} disabled={shouldDisableCsvButton}>
                Exportar CSV
              </Button>
            </Tooltip>
          )}
          <Tooltip text={disableGerarRelatorio && hintBtnGerarRelatorio}>
            <Button kind='primary' disabled={disableGerarRelatorio} onClick={() => setOpen(true)}>
              <Icon icon='fileWithItemsOutline' />
              <span>Gerar relatório operacional</span>
            </Button>
          </Tooltip>
        </HFlow>
      </FormFooter>
      <Form<AcompanhamentoCondicaoSaudeRelatorioModel> render={renderModal} onSubmit={handleModalSubmit} />
    </Fragment>
  )
}
