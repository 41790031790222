/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Text, Theme, useTheme } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'
import { StreamPlayer } from 'components/stream-player'
import { ObjectFitProperty } from 'csstype'
import { useWidth } from 'hooks/useMeasure'
import { useEffect, useState } from 'react'

import { MicrophoneFeedback } from './MicrophoneFeedback'
import { ParticipanteVideochamadaIcon } from './ParticipanteVideochamadaIcon'
import { PictureInPicturePlaceholder } from './PictureInPicturePlaceholder'

export interface StreamPlayerVideochamadaProps {
  nomeParticipante: string
  stream: MediaStream
  corParticipante: ColorScale
  style?: ExternalStyles
  fit?: ObjectFitProperty
  showMicrophoneFeedback?: boolean
  muted?: boolean
  disablePictureInPicture?: boolean
  pipActive?: boolean
  setPipActive?(newVal: boolean): void
  minimizado?: boolean
  onVideoResize?(videoWidth: number, videoHeight: number): void
  setVideoWidth?(width: number): void
}

export function StreamPlayerVideochamada(props: StreamPlayerVideochamadaProps) {
  const {
    nomeParticipante,
    stream,
    corParticipante,
    style: externalStyles,
    fit = 'cover',
    showMicrophoneFeedback = true,
    muted = false,
    disablePictureInPicture = false,
    pipActive = false,
    setPipActive,
    minimizado = false,
    onVideoResize,
    setVideoWidth,
  } = props

  const theme = useTheme()
  const styles = createStyles(theme, fit)
  const [divVideoRef, widthDivVideo] = useWidth()

  const [videoEnabled, setVideoEnabled] = useState(false)

  useEffect(() => {
    if (setVideoWidth && widthDivVideo) {
      setVideoWidth(widthDivVideo)
    }
  }, [setVideoWidth, widthDivVideo])

  useEffect(() => {
    const handleChangeTracks = () => setVideoEnabled(stream.getVideoTracks().some((track) => track.enabled))

    if (stream) {
      handleChangeTracks()
      stream.addEventListener('addtrack', handleChangeTracks)
      stream.addEventListener('removetrack', handleChangeTracks)
    } else {
      setVideoEnabled(false)
    }

    return () => {
      stream?.removeEventListener('addtrack', handleChangeTracks)
      stream?.removeEventListener('removetrack', handleChangeTracks)
    }
  }, [setVideoEnabled, stream])

  return (
    <div ref={divVideoRef} css={[styles.cam, externalStyles, videoEnabled]}>
      {pipActive ? (
        <PictureInPicturePlaceholder
          setPipActive={setPipActive}
          style={styles.pipPlaceholder}
          minimizado={minimizado}
        />
      ) : (
        !videoEnabled && <ParticipanteVideochamadaIcon color={corParticipante} />
      )}
      <StreamPlayer
        stream={stream}
        pipActive={pipActive}
        setPipActive={setPipActive}
        autoPlay
        muted={muted}
        style={videoEnabled ? styles.video : styles.videoOff}
        playsInline
        disablePictureInPicture={disablePictureInPicture}
        onVideoResize={onVideoResize}
      />
      <Text component='p' style={styles.nome}>
        {nomeParticipante}
      </Text>
      {showMicrophoneFeedback && (
        <MicrophoneFeedback audioTrack={stream?.getAudioTracks()[0]} styles={styles.micFeedback} />
      )}
    </div>
  )
}
const createStyles = (theme: Theme, fit: ObjectFitProperty) => ({
  cam: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: ${theme.pallete.gray.c20};
  `,
  video: css`
    width: 100%;
    height: 100%;
    object-fit: ${fit};
  `,
  videoOff: css`
    visibility: hidden;
    z-index: 1;
    position: absolute;
  `,
  pipPlaceholder: css`
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
  `,
  nome: css`
    color: ${theme.pallete.gray.c100};
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    bottom: max(2%, 10px);
    left: 1rem;
    text-shadow: 0px 1px 2px rgba (0,  0,  0,  0.60),  0px 0px 2px rgba (0,  0,  0,  0.60);
    ${theme.breakpoints.down('sm')} {
      left: 0.5rem;
    }
  `,
  micFeedback: css`
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
  `,
})
