/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { PopperButton, PopperControls } from 'components/popper'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { AlertaSuporteDecisaoClinica } from 'hooks/sdc/model-sdc'
import { useAlertasSdc } from 'hooks/sdc/useAlertasSdc'
import { Fragment } from 'react'

import { ReactComponent as NotificationCircle } from '../../../images/novidades/icons/notification_circle.svg'
import { ReactComponent as BlueBellNotificationIcon } from '../../../images/suporte-decisao-clinica/blue-bell-notification-filled.svg'

export const SdcPopperButton = () => {
  const theme = useTheme()
  const styles = createStyles(theme)

  const { analytics } = useFirebase()
  const { alertasSdc, hasAlertaNaoLido, marcarTodosAlertasComoLidos } = useAlertasSdc()

  const handleOpen = () => {
    analytics.logEvent('abrir_modal_SDC')
  }

  const handleClose = () => {
    marcarTodosAlertasComoLidos()
    analytics.logEvent('fechar_modal_SDC')
  }

  const renderPopper = ({ close }: PopperControls) => (
    <Box style={styles.box}>
      <Fragment>
        <HFlow alignItems='center' justifyContent='space-between' style={styles.header}>
          <Heading level={3}>Suporte à decisão clínica</Heading>
          <Button size='small' skin='ghost' onClick={() => close()}>
            <Tooltip text='Fechar'>
              <Icon icon='timesDefault' />
            </Tooltip>
          </Button>
        </HFlow>
        <hr css={styles.divider} />
        <VFlow vSpacing={0} style={styles.alertsContainer}>
          {!alertasSdc || alertasSdc?.isEmpty() ? (
            <div css={styles.noAlertContainer}>
              <Text fontStyle='italic' css={styles.descriptionText}>
                Ainda não existem notificações para este atendimento
              </Text>
            </div>
          ) : (
            <Fragment>
              {alertasSdc?.map((alerta: AlertaSuporteDecisaoClinica) => (
                <div key={alerta.id} css={[styles.alert, alerta.visualizado ? styles.disabledAlert : null]}>
                  <HFlow hSpacing={0.5} alignItems='baseline'>
                    {!alerta.visualizado && <NotificationCircle css={styles.notificationCircle} />}
                    <VFlow vSpacing={0}>
                      <Text fontWeight='bold'>{alerta.titulo}</Text>
                      <Text>{alerta.subtitulo}</Text>
                      <Text fontStyle='italic' css={styles.descriptionText}>
                        {alerta.descricao}
                      </Text>
                    </VFlow>
                  </HFlow>
                </div>
              ))}
            </Fragment>
          )}
        </VFlow>
      </Fragment>
    </Box>
  )

  return (
    <Tooltip text={!hasAlertaNaoLido ? 'Suporte à decisão clínica' : 'Nova notificação do suporte à decisão clínica'}>
      <PopperButton
        skin='ghost'
        size='small'
        positioningStrategy='fixed'
        placement='bottom-end'
        z-index={1}
        renderPopper={renderPopper}
        onOpen={handleOpen}
        onClose={handleClose}
        styleCallback={(isOpen) => css(isOpen && styles.buttonActive)}
      >
        {!!hasAlertaNaoLido ? <BlueBellNotificationIcon /> : <Icon icon='bellOutline' />}
      </PopperButton>
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  box: css`
    width: 22rem;
    height: 100%;
    max-height: 19.5rem;
    box-shadow: ${theme.shadows.outer[160]};
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
  header: css`
    padding: 1rem 1rem 1rem 1.5rem;
  `,
  divider: css`
    margin: 0;
    background-color: ${theme.pallete.divider};
  `,
  alertsContainer: css`
    overflow-y: auto;
    overflow-wrap: break-word;

    ::-webkit-scrollbar {
      width: 0.375rem;
      height: 6.25rem;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.pallete.gray.c70};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
      background: ${theme.pallete.surface.main};
    }
  `,
  noAlertContainer: css`
    display: flex;
    text-align: center;
    padding: 1rem 1.5rem;
    color: ${theme.pallete.gray.c60};
  `,
  alert: css`
    padding: 0.5rem;
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  disabledAlert: css`
    background: ${theme.pallete.gray.c90};
  `,
  notificationCircle: css`
    width: 0.5rem;
    height: 0.5rem;
  `,
  descriptionText: css`
    color: ${theme.pallete.gray.c40};
  `,
  buttonActive: css`
    &,
    &:not(:disabled):hover {
      background-color: ${theme.pallete.gray.c90};
      box-shadow: ${theme.shadows.inner[10]};
    }
  `,
})
