import { isCiap } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { AcompanhamentoCondicaoSaudeQueryInput, FaixaEtariaInput, PageInfo } from 'graphql/types.generated'
import { isNumber, partition } from 'lodash'
import { idGrupoCondicao } from 'types/enums'

import {
  AcompanhamentoCondicaoSaudeFormModel,
  AgeRangeFilter,
  faixaEtariaRecord,
} from './model-acompanhamentoCondicaoSaude'

export default (
  model: AcompanhamentoCondicaoSaudeFormModel,
  pageInfo?: PageInfo
): AcompanhamentoCondicaoSaudeQueryInput => {
  const [ciaps, cids] = partition(model?.geral?.problemasCondicoes, (item) => isCiap(item))
  return {
    ciaps: ciaps.map((item) => item.codigo),
    cid10: cids.map((item) => item.codigo),
    grupoCondicao: model?.geral?.grupoCondicao?.map((item) => idGrupoCondicao[item.grupoCondicao]),
    somenteProblemasCondicoesAtivas: model?.geral?.ativoListaProblema ? model.geral.ativoListaProblema : false,
    unidadeResponsavelId: model?.unidadeResponsavelId,
    equipeResponsavelId: model.isCoordenador ? model?.equipeResponsavel?.id : model?.equipeResponsavelId,
    sexo: model?.geral?.sexo,
    identidadeGenero: model?.geral?.identidadeGenero,
    faixaEtaria: AgeRangeFilter.TODAS_FAIXAS !== model?.faixaEtariaFilter ? mountFaixaEtaria(model) : null,
    periodoUltimoAtendimento: model?.geral?.periodoUltimoAtendimento,
    microareas: model?.microareas,
    pageParams: {
      sort: ['cidadao', 'endereco', 'dataAtendimento'],
      size: pageInfo?.totalElements ?? model?.pageParams?.size,
      ...model.pageParams,
    },
  }
}

function mountFaixaEtaria(model: AcompanhamentoCondicaoSaudeFormModel): FaixaEtariaInput {
  const { faixaEtariaFilter, faixaEtaria } = model

  return faixaEtariaFilter !== AgeRangeFilter.OUTRA
    ? faixaEtariaRecord[faixaEtariaFilter]
    : {
        idadeMinima: faixaEtaria?.firstValue,
        idadeMaxima: faixaEtaria?.secondValue,
        tipoFaixaEtaria:
          isNumber(faixaEtaria?.firstValue) || isNumber(faixaEtaria?.secondValue) ? faixaEtaria?.tipoFaixaEtaria : null,
      }
}
