/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { HLabel } from 'components/HLabel'
import { IconLabel } from 'components/IconLabel'
import { SituacaoAgendadoEnum } from 'graphql/types.generated'
import { useMemo, useState } from 'react'
import { applyMask, masks } from 'util/mask'

import { EventoAgendaLotacao, situacaoAgendamentoRecord } from '../../model-agenda'
import { AcoesAgendamentoConsulta } from '../acoes-agendamento/AcoesAgendamentoConsulta'
import { AgendamentoMarker } from '../markers/AgendamentoMarker'
import { CompartilharVideochamadaDetalhesAgendamentoCompartilhadoModal } from './CompartilharVideochamadaDetalhesAgendamentoCompartilhadoModal'
import { ContainerAgendamentoConsulta } from './ContainerAgendamentoConsulta'
import { EventoAgendamentoProps } from './EventoAgendamento'
import { EventoAgendamentoPopperButton } from './EventoAgendamentoPopperButton'

export function EventoAgendamentoConsulta({
  event,
  getServerTimeNow,
  eventHasOverlap,
  isAgendaOnlineAtiva,
  lotacaoAgendadaId,
}: EventoAgendamentoProps) {
  const nomeCidadao = event.nomeCidadao?.titleCase()

  const theme = useTheme()
  const styles = createStyles(theme)

  const [isEnviarEmailModalOpen, setIsEnviarEmailOpen] = useState<boolean>(false)

  const emailDestinatario = useMemo(
    () =>
      event.lotacaoProfissionalAnfitriaoId === lotacaoAgendadaId
        ? event.emailProfissionalParticipante
        : event.emailProfissionalAnfitriao,
    [
      event.emailProfissionalAnfitriao,
      event.emailProfissionalParticipante,
      event.lotacaoProfissionalAnfitriaoId,
      lotacaoAgendadaId,
    ]
  )

  return (
    <ContainerAgendamentoConsulta status={event.situacao} isForaUbs={event.isForaUbs}>
      <CompartilharVideochamadaDetalhesAgendamentoCompartilhadoModal
        videochamadaUuid={event.videochamadaUuid}
        emailDestinatario={emailDestinatario}
        open={isEnviarEmailModalOpen}
        onClose={() => setIsEnviarEmailOpen(false)}
      />
      <EventoAgendamentoPopperButton
        event={event}
        isAgendaOnlineAtiva={isAgendaOnlineAtiva}
        style={styles.popperButton}
        closeOnBackdropClick={!isEnviarEmailModalOpen}
        onEnviarEmailButtonClick={() => setIsEnviarEmailOpen(true)}
      >
        {eventHasOverlap ? (
          <Ellipsis title={nomeCidadao}>
            <AgendamentoMarker
              origem={event.origem}
              situacaoSincronizacao={event.situacaoSincronizacao}
              isAgendaOnlineAtiva={isAgendaOnlineAtiva}
              isAgendamentoCompartilhado={!!event.nomeProfissionalParticipante}
              styles={css`
                margin-bottom: -0.2rem;
                margin-right: 0.5rem;
              `}
            />
            <Text style={styles.nomeCidadao} fontWeight='bold'>
              {nomeCidadao}
            </Text>
          </Ellipsis>
        ) : (
          <div css={styles.internalContainer}>
            <HFlow
              hSpacing={0.7}
              style={css`
                grid-template-columns: repeat(3, auto);
              `}
            >
              <HFlow
                hSpacing={0}
                style={css`
                  grid-template-columns: repeat(3, auto);
                `}
              >
                <AgendamentoMarker
                  origem={event.origem}
                  situacaoSincronizacao={event.situacaoSincronizacao}
                  isAgendaOnlineAtiva={isAgendaOnlineAtiva}
                  isAgendamentoCompartilhado={!!event.nomeProfissionalParticipante}
                  styles={css`
                    margin-right: 0.5rem;
                  `}
                />
                <Text style={styles.nomeCidadao} fontWeight='bold'>
                  {nomeCidadao}
                </Text>
                <Text>{getStatusText(event, getServerTimeNow)}</Text>
              </HFlow>
              <IconLabel
                icon='phoneFilled'
                color={event.telefoneCidadao ? theme.pallete.primary.main : theme.pallete.text.secondary}
                text={event.telefoneCidadao ? applyMask(event.telefoneCidadao, masks.telefone) : 'Não Informado'}
                hideTextBelow='lg'
              />
              {event.isForaUbs && <IconLabel icon='mapMarkerFilled' text={event.localAtendimento} hideTextBelow='xl' />}
            </HFlow>

            <HFlow hSpacing={0} css={styles.convidadoObservacoesContainer}>
              {event.nomeProfissionalParticipante && (
                <HLabel title='Convidado:' style={styles.nomeProfParticipante}>
                  {event.nomeProfissionalParticipante}
                </HLabel>
              )}
              {event.nomeProfissionalParticipante && event.observacoes && <Text>&nbsp;|&nbsp;</Text>}
              {event.observacoes && (
                <Ellipsis style={styles.observacoes}>
                  <Text fontWeight='bold'>Observações:&nbsp;</Text>
                  <Ellipsis>{event.observacoes}</Ellipsis>
                </Ellipsis>
              )}
            </HFlow>
          </div>
        )}
      </EventoAgendamentoPopperButton>
      <AcoesAgendamentoConsulta event={event} isDropdown={eventHasOverlap} />
    </ContainerAgendamentoConsulta>
  )
}

const getStatusText = ({ situacao, end, isForaUbs }: EventoAgendaLotacao, getServerTimeNow: () => Date): string => {
  if (situacao === SituacaoAgendadoEnum.AGENDADO) {
    if (isForaUbs) return '\xa0| Cidadão na lista de registro tardio'
    else return end < getServerTimeNow() ? '\xa0| Atendimento não realizado' : ''
  } else {
    return `\xa0| ${situacaoAgendamentoRecord[situacao].descricao.capitalize()}`
  }
}

const createStyles = (theme: Theme) => ({
  nomeCidadao: css`
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  internalContainer: css`
    padding: 0.25rem 0;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    span {
      white-space: nowrap;
    }
  `,
  popperButton: css`
    padding-left: 1rem;
    overflow: hidden;
    padding-right: 0.75rem;
  `,
  iconeAgendaOnline: css`
    fill: ${theme.pallete.primary.c50};
    height: 1rem;
    width: 1rem;
    align-self: center;
    margin-right: 0.5rem;
  `,
  nomeProfParticipante: css`
    max-width: 11rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  convidadoObservacoesContainer: css`
    max-width: 100%;
    width: 100%;
    display: flex;
  `,
  observacoes: css`
    display: flex;
    align-items: center;
  `,
})
