import { Button, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import React from 'react'
import { metaPath } from 'util/metaPath'
import { ResponsavelAcompanhamentoImovelFormModel } from 'view/cadastro-imovel/model-cadastroImovel'
import { validatorAlterarResponsabilidade } from 'view/cadastro-imovel/validator-cadastroImovel'
import { useSaudeIndigenaAuthorization } from 'view/cidadao/authorization/useSaudeIndigenaAuthorization'

interface AlterarResponsabilidadeModalProps {
  open: boolean
  municipioId: string
  onClose(): void
  onReponsavelChange(novoResponsavel: LotacaoAndEstagioSelectFieldModel): void
}

const meta = metaPath<ResponsavelAcompanhamentoImovelFormModel>()

export function AlterarResponsabilidadeModal(props: AlterarResponsabilidadeModalProps) {
  const { open, municipioId, onClose, onReponsavelChange } = props

  const { hasTerritorioIndigenaPermission } = useSaudeIndigenaAuthorization()

  const handleSubmit = (values: ResponsavelAcompanhamentoImovelFormModel) => {
    onReponsavelChange(values.lotacao)
    onClose()
  }

  if (!open) return null

  const renderForm = (formProps: FormRenderProps<ResponsavelAcompanhamentoImovelFormModel>) => {
    return (
      <Modal open={open} onClose={onClose} size='small' closeOnBackdropClick={false}>
        <ModalBody>
          <VFlow vSpacing={1}>
            <Heading level={1}> Alterar responsável </Heading>
            <LotacaoAndEstagioSelectField
              label='Profissional'
              name={meta.lotacao}
              required
              municipioId={municipioId}
              canWriteAtendimento={false}
              canWriteRegistroTardio={false}
              canWriteCadastroDomiciliarAndCadastroIndividual={!hasTerritorioIndigenaPermission}
              canWriteCidadaoTerritorioIndigena={hasTerritorioIndigenaPermission}
            />
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onClose}>Cancelar</Button>
            <Button kind='primary' onClick={formProps.handleSubmit}>
              Salvar
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<ResponsavelAcompanhamentoImovelFormModel>
      render={renderForm}
      validate={validatorAlterarResponsabilidade}
      onSubmit={handleSubmit}
      suppressNotificationError
    />
  )
}
