/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Icon, Text, VFlow } from 'bold-ui'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { HLabel } from 'components/HLabel'
import theme from 'config/theme'
import { Fragment } from 'react'

interface ResponsavelAcompanhamentoCardProps {
  responsavel: LotacaoAndEstagioSelectFieldModel
  setIsModalResponsavelOpen?: (aux: boolean) => void
  shouldRenderButton: boolean
}

export function ResponsavelAcompanhamentoCard({
  responsavel,
  setIsModalResponsavelOpen,
  shouldRenderButton,
}: ResponsavelAcompanhamentoCardProps) {
  return (
    <Fragment>
      <Grid
        style={css`
          margin-top: 1rem;
        `}
        wrap
      >
        <Cell size={12}>
          <Heading level={2}> Responsabilidade de acompanhamento</Heading>
        </Cell>
        <Cell size={8}>
          <HFlow
            style={css`
              border: 1px solid ${theme.pallete.divider};
              padding: 1rem;
            `}
            alignItems='center'
            justifyContent='space-between'
          >
            <VFlow vSpacing={0}>
              <Text fontWeight='bold'>{responsavel.profissional.nome} </Text>
              <HLabel title='CBO'> {responsavel.cbo.nome.titleCase()} </HLabel>
              <HLabel title='Equipe'>{responsavel.equipe?.nome ?? 'Sem equipe'} </HLabel>
              <HLabel title='Unidade de saúde'>{responsavel.unidadeSaude.nome} </HLabel>
            </VFlow>

            {shouldRenderButton && (
              <Button onClick={() => setIsModalResponsavelOpen(true)} size='small'>
                <HFlow hSpacing={0.5}>
                  <Icon icon='exchange' />
                  Alterar responsável
                </HFlow>
              </Button>
            )}
          </HFlow>
        </Cell>
      </Grid>
    </Fragment>
  )
}
