/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Theme, useTheme, VFlow } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { useFlags } from 'config/useFlagsContext'
import { useConfiguracaoRndsQuery } from 'graphql/hooks.generated'
import { useCabecalhoFixo } from 'hooks/useCabecalhoFixo'
import { forwardRef, Ref } from 'react'
import { SdcPopperButton } from 'view/atendimentos/components/SdcPopperButton'

import { ButtonAcessoRnds } from '../ButtonAcessoRnds'
import { AtendimentoHeaderCidadaoSection } from './AtendimentoHeaderCidadaoSection'
import { AtendimentoHeaderTabs } from './AtendimentoHeaderTabs'

export interface AtendimentoHeaderProps {
  cidadaoId: ID
  cnsCidadao?: string
  cnsProfissional?: string
  cnes?: string
  municipioId?: ID
  renderFolhaRosto?: boolean
  renderSoap?: boolean
  renderVacinacao?: boolean
  renderHistorico?: boolean
  renderCadastroCidadao?: boolean
  renderEscutaInicial?: boolean
  renderDiscussaoCaso?: boolean
  renderAgendamentos?: boolean
  labelEscutaPreAtendimento?: string
  dataAtendimento?: Instant
  showAlertasSdc?: boolean
}

export const AtendimentoHeader = forwardRef((props: AtendimentoHeaderProps, ref: Ref<HTMLElement>) => {
  const {
    cidadaoId,
    labelEscutaPreAtendimento,
    cnes,
    cnsProfissional,
    cnsCidadao,
    municipioId,
    dataAtendimento,
    renderFolhaRosto = true,
    renderSoap = true,
    renderVacinacao = true,
    renderHistorico = true,
    renderCadastroCidadao = true,
    renderAgendamentos = true,
    renderEscutaInicial = false,
    renderDiscussaoCaso = false,
    showAlertasSdc = false,
  } = props

  const theme = useTheme()
  const isFixo = useCabecalhoFixo()
  const classes = createStyles(theme, isFixo)

  const { data: configRnds } = useConfiguracaoRndsQuery()
  const { PILOTO_ALERTAS_SDC_1_ENABLED } = useFlags()

  return (
    <header ref={ref} css={classes.container}>
      <PageContainer fluid>
        <VFlow vSpacing={0.5}>
          <HFlow
            alignItems='flex-start'
            hSpacing={0.5}
            justifyContent='space-between'
            style={css`
              margin-top: 1rem;
            `}
          >
            <AtendimentoHeaderCidadaoSection cidadaoId={cidadaoId} dataAtendimento={dataAtendimento} />

            <ButtonAcessoRnds
              cnes={cnes}
              cnsCidadao={cnsCidadao}
              cnsProfissional={cnsProfissional}
              municipioId={municipioId}
              rndsAtiva={!!configRnds?.configuracaoCertificadoHabilitado?.rndsHabilitado}
              conexaoAtiva={configRnds?.conexao?.internet?.habilitado}
            />
          </HFlow>

          <HFlow justifyContent='space-between'>
            <AtendimentoHeaderTabs
              cidadaoId={cidadaoId}
              labelEscutaPreAtendimento={labelEscutaPreAtendimento}
              renderAgendamentos={renderAgendamentos}
              renderCadastroCidadao={renderCadastroCidadao}
              renderEscutaInicial={renderEscutaInicial}
              renderFolhaRosto={renderFolhaRosto}
              renderHistorico={renderHistorico}
              renderSoap={renderSoap}
              renderVacinacao={renderVacinacao}
              renderDiscussaoCaso={renderDiscussaoCaso}
            />
            {showAlertasSdc && PILOTO_ALERTAS_SDC_1_ENABLED && <SdcPopperButton />}
          </HFlow>
        </VFlow>
      </PageContainer>
    </header>
  )
})

const createStyles = (theme: Theme, isFixo: boolean) => ({
  container: css`
    border-bottom: 1px solid ${theme.pallete.divider};
    background-color: ${theme.pallete.surface.main};
    position: sticky;
    top: 0;
    z-index: 9;
    ${isFixo ? `box-shadow: 0 1px 5px 0 ${theme.pallete.divider}, 0 2px 1px -1px ${theme.pallete.divider};` : ''}
  `,
})
