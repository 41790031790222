import { Alert, VFlow } from 'bold-ui'
import { css } from 'emotion'
import { useAssinaturaContext } from 'hooks/assinatura-eletronica-context/useAssinaturaEletronicaContext'
import React from 'react'

import { IdadeOrigemAtendimento, InformacoesAdministrativas } from '../../../model/model-historico'
import { HistoricoAtendimentoObservacaoAlert } from './atendimento-observacao/HistoricoAtendimentoObservacaoAlert'
import HistoricoIdadeOrigemAtendimento from './HistoricoIdadeOrigemAtendimento'
import HistoricoInfoAdminPanel from './HistoricoInfoAdminPanel'

interface HistoricoGapPanelProps {
  idadeOrigemAtendimento?: IdadeOrigemAtendimento
  informacoesAdministrativas: InformacoesAdministrativas
  children: React.ReactNode
  isAtendObservacao?: boolean
  dataInicioCompartilhamento?: LocalDate
  tipoParticipacaoCidadao?: string
}

export default function HistoricoGapPanel({
  idadeOrigemAtendimento,
  children,
  informacoesAdministrativas,
  isAtendObservacao = false,
  dataInicioCompartilhamento,
  tipoParticipacaoCidadao,
}: HistoricoGapPanelProps) {
  const assinaturaContext = useAssinaturaContext()
  const classes = createStyles()

  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        {isAtendObservacao && <HistoricoAtendimentoObservacaoAlert />}
        {idadeOrigemAtendimento && (
          <HistoricoIdadeOrigemAtendimento
            isAtendObservacao={isAtendObservacao}
            idadeOrigemAtendimento={idadeOrigemAtendimento}
            isCompartilhamentoCuidado={informacoesAdministrativas.stCuidadoCompartilhado}
            dataInicioCompartilhamento={dataInicioCompartilhamento}
            tipoParticipacaoCidadao={tipoParticipacaoCidadao}
          />
        )}
        {assinaturaContext?.isAtendimentoAssinado && (
          <Alert inline type='info' styles={{ wrapper: classes.assinaturaAlert }}>
            Os dados exibidos correspondem ao atendimento e não ao documento assinado. Para visualizar o documento
            assinado, faça o download do arquivo.
          </Alert>
        )}
      </VFlow>
      <VFlow vSpacing={0}>
        <VFlow>{children}</VFlow>
        <HistoricoInfoAdminPanel content={informacoesAdministrativas} />
      </VFlow>
    </VFlow>
  )
}

const createStyles = () => ({
  assinaturaAlert: css`
    padding: 0.5rem;
    width: 80%;
  `,
})
