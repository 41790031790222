/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ButtonGroup, HeadingSection, HFlow, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import { formatISO } from 'date-fns'
import {
  useAcompanhamentoPuericulturaCardQuery,
  usePreNatalEmAtendimentoObservacaoQuery,
  usePuericulturaQuery,
} from 'graphql/hooks.generated'
import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment, useCallback } from 'react'
import { useField } from 'react-final-form'
import { isCidadaoPuericultura } from 'util/isCidadaoPuericultura'
import { MetaPath } from 'util/metaPath'
import { extractPreNatalFromAtendimentoProfissional } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { convertAcompanhamentoPuericulturaToModel } from '../../aside/acompanhamento-puericultura/convert'
import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import {
  informationPreNatalQuandoExisteDesfecho,
  informationPreNatalQuandoExistePuericultura,
  informationPuericulturaQuandoExisteDesfecho,
  informationPuericulturaQuandoExistePreNatal,
} from '../../avaliacao/components/problemas-condicoes/utils/messages'
import { hasProblemaCondicaoDePreNatal } from '../../avaliacao/components/problemas-condicoes/utils/verifications-problemasCondicoes'
import { IDADE_GESTACIONAL_MAXIMA_EM_DIAS, MetasPreNatal, TipoPreNatal } from '../../pre-natal/model-preNatal'
import { PreNatalButton } from '../../pre-natal/PreNatalButton'
import { PreNatalField } from '../../pre-natal/PreNatalField'
import { PreNatalView } from '../../pre-natal/PreNatalView'
import {
  informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao,
  informationPreNatalSuperiorA336Dias,
  resetPreNatalValuesToUndefined,
} from '../../pre-natal/util-preNatal'
import { grupoCboPuericultura } from '../acessos'
import { initialValuesPuericulturaModel, PuericulturaModel } from '../puericultura/model'
import { PuericulturaButton } from '../puericultura/PuericulturaButton'
import PuericulturaField from '../puericultura/PuericulturaField'
import { PuericulturaView } from '../puericultura/PuericulturaView'
import { SwitchPanelObjetivoForm } from './SwitchPanelObjetivoForm'

export enum FormAtivoObjetivoEnum {
  PUERICULTURA,
  PRE_NATAL,
}

export interface SwitchButtonObjetivoFormModel {
  formAtivo: FormAtivoObjetivoEnum
  puericultura: PuericulturaModel
}

export interface SwitchButtonObjetivoFormProps {
  name: MetaPath<SwitchButtonObjetivoFormModel>
  metaProblemasCondicoes: MetaPath<ProblemaCondicaoModel[]>
  metasPreNatal: Pick<MetasPreNatal, 'metaDum' | 'metaPreNatal' | 'metaAgendarProximasConsultas'>
  cidadao: CidadaoAtendimento
  prontuarioId: ID
  dataAtendimento: Instant
  tipoPreNatal: TipoPreNatal
  hasProblemaComCiapW78AtivoPersistido: boolean
}

export function SwitchButtonObjetivoForm(props: SwitchButtonObjetivoFormProps) {
  const {
    name,
    cidadao,
    prontuarioId,
    dataAtendimento,
    metasPreNatal: { metaPreNatal, metaDum, metaAgendarProximasConsultas },
    tipoPreNatal,
    metaProblemasCondicoes,
    hasProblemaComCiapW78AtivoPersistido,
  } = props

  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })

  const {
    atendimentoProfissional: { id: atendimentoProfissionalId, tipo: atendimentoProfissionalTipo },
    cidadao: { isGestante, dataInicioGestacao, idadeGestacional, idadeEmAnos },
    permissoes: { hasPermissionPreNatal },
    observacao: { isAtendimentoObservacao },
  } = useAtendimentoContext()

  const dataReferencia = formatISO(dataAtendimento, { representation: 'date' })

  const isPreNatalEmAtendimentoObservacao =
    isAtendimentoObservacao && atendimentoProfissionalTipo === TipoAtendimentoProfissional.PRE_NATAL

  const isPuericulturaEmAtendimentoObservacao =
    isAtendimentoObservacao && atendimentoProfissionalTipo === TipoAtendimentoProfissional.PUERICULTURA

  const {
    data: preNatalData,
    loading: isLoadingPreNatalEmAtendimentoObservacao,
  } = usePreNatalEmAtendimentoObservacaoQuery({
    variables: { id: atendimentoProfissionalId },
    skip: !isPreNatalEmAtendimentoObservacao,
  })

  const dadosPreNatal = isPreNatalEmAtendimentoObservacao
    ? extractPreNatalFromAtendimentoProfissional(preNatalData?.atendimentoIndividual)
    : null

  const {
    data: puericulturaData,
    loading: isLoadingAcompanhamentoPuericulturaCardQuery,
  } = useAcompanhamentoPuericulturaCardQuery({
    variables: { prontuarioId, dataReferencia },
    fetchPolicy: 'cache-first',
    skip: !isPuericulturaEmAtendimentoObservacao,
  })

  const acompanhamentoPuericultura = convertAcompanhamentoPuericulturaToModel(puericulturaData, cidadao.dataNascimento)

  const acessoPreNatal = isGestante && hasPermissionPreNatal
  const acessoCboPuericultura = hasCboAuth(grupoCboPuericultura)

  const acessoPuericultura = acessoCboPuericultura && isCidadaoPuericultura(idadeEmAnos)

  const {
    input: { value: puericultura, onChange: updatePuericultura },
  } = useField<PuericulturaModel>(name.puericultura.absolutePath())

  const { loading: isLoadingPuericulturaQuery } = usePuericulturaQuery({
    variables: { prontuarioId, dataReferencia },
    skip: !acessoPuericultura || (!!puericultura && puericultura?.dataNascimentoReferencia === cidadao.dataNascimento),
    onCompleted: (data) =>
      data && updatePuericultura(initialValuesPuericulturaModel(data, cidadao.dataNascimento, dataAtendimento)),
  })

  const {
    input: { value: formAtivo, onChange: updateFormAtivo, onBlur: onBlurFormAtivo },
  } = useField<FormAtivoObjetivoEnum>(name.formAtivo.absolutePath())

  const {
    input: { value: problemasCondicoes },
  } = useField<ProblemaCondicaoModel[]>(metaProblemasCondicoes.absolutePath(), { subscription: { value: true } })

  const {
    tools: { resetToUndefined: resetPreNatal },
  } = usePecField({ name: metaPreNatal.absolutePath(), subscription: { value: true } })

  const {
    tools: { resetToUndefined: resetDum },
  } = usePecField({ name: metaDum.absolutePath() })

  const {
    tools: { resetToUndefined: resetAgendarProximasConsultas },
  } = usePecField({ name: metaAgendarProximasConsultas.absolutePath() })

  const setFormAtivo = useCallback(
    (stateButton: FormAtivoObjetivoEnum) => {
      updateFormAtivo(stateButton)
      onBlurFormAtivo()
    },
    [onBlurFormAtivo, updateFormAtivo]
  )

  const setNullIfNewFormAtivoIsEqual = (newFormAtivo: FormAtivoObjetivoEnum) =>
    newFormAtivo === formAtivo ? null : newFormAtivo

  const handleClickPuericultura = (stateButton: FormAtivoObjetivoEnum) => {
    confirm({
      title: 'Deseja descartar os dados de Puericultura?',
      body: 'As alterações realizadas serão perdidas.',
      cancelLabel: 'Não, manter dados',
      confirmLabel: 'Sim, descartar',
      onConfirm: () => {
        setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
      },
    })()
  }

  const isBotaoPreNatalHabilitado = formAtivo === FormAtivoObjetivoEnum.PRE_NATAL
  const isBotaoPuericulturaHabilitado = formAtivo === FormAtivoObjetivoEnum.PUERICULTURA

  const handleDesabilitarPreNatal = (stateButton: FormAtivoObjetivoEnum) => {
    confirm({
      title: 'Deseja descartar os dados de Pré-natal?',
      body: `Os campos de Pré-natal serão desabilitados, os dados contidos nos mesmos serão perdidos e os problemas e/ou condições de gestação serão excluídos da Avaliação.`,
      cancelLabel: 'Não, manter dados',
      confirmLabel: 'Sim, descartar',
      onConfirm: () => {
        const isPrimeiroAtendimentoPreNatal = tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL

        setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
        resetPreNatalValuesToUndefined({
          resetDum,
          resetPreNatal,
          resetAgendarProximasConsultas,
          isPrimeiroAtendimentoPreNatal,
        })
      },
    })()
  }

  const handleHabilitarPreNatal = () => {
    if (idadeGestacional > IDADE_GESTACIONAL_MAXIMA_EM_DIAS) {
      informationPreNatalSuperiorA336Dias(dataInicioGestacao)
      return
    }
    switch (tipoPreNatal) {
      case TipoPreNatal.W78_RESOLVIDO_NA_AVALIACAO: {
        informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao()
        break
      }
      case TipoPreNatal.ENCERRAMENTO_GESTACAO: {
        informationPreNatalQuandoExisteDesfecho()
        break
      }
      default: {
        setFormAtivo(setNullIfNewFormAtivoIsEqual(FormAtivoObjetivoEnum.PRE_NATAL))
        break
      }
    }
  }

  const handleClick = (stateButton: FormAtivoObjetivoEnum) => () => {
    if (isBotaoPreNatalHabilitado) {
      if (stateButton === FormAtivoObjetivoEnum.PUERICULTURA) {
        return informationPuericulturaQuandoExistePreNatal()
      } else {
        return handleDesabilitarPreNatal(stateButton)
      }
    } else if (isBotaoPuericulturaHabilitado) {
      if (stateButton === FormAtivoObjetivoEnum.PRE_NATAL) {
        return informationPreNatalQuandoExistePuericultura()
      } else {
        return handleClickPuericultura(stateButton)
      }
    }
    if (stateButton === FormAtivoObjetivoEnum.PRE_NATAL) {
      return handleHabilitarPreNatal()
    } else if (stateButton === FormAtivoObjetivoEnum.PUERICULTURA)
      if (tipoPreNatal === TipoPreNatal.ENCERRAMENTO_GESTACAO) {
        return informationPuericulturaQuandoExisteDesfecho()
      } else if (hasProblemaCondicaoDePreNatal(problemasCondicoes, hasProblemaComCiapW78AtivoPersistido)) {
        return informationPuericulturaQuandoExistePreNatal()
      }
    return setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
  }

  if (!acessoPuericultura && !acessoPreNatal) {
    setFormAtivo(null) // resolve alguns problemas de troca de data nascimento
    return null
  }

  return (
    <Fragment>
      <HeadingSection level={5} title='Habilitar campos de' />
      <VFlow vSpacing={0.5}>
        <ButtonGroup>
          <HFlow hSpacing={1} justifyContent='flex-start'>
            {acessoPreNatal && (
              <PreNatalButton
                isSelecionado={isBotaoPreNatalHabilitado || isPreNatalEmAtendimentoObservacao}
                handleClick={handleClick(FormAtivoObjetivoEnum.PRE_NATAL)}
                disabled={isLoadingPreNatalEmAtendimentoObservacao || isPreNatalEmAtendimentoObservacao}
                tooltipText={
                  isPreNatalEmAtendimentoObservacao &&
                  'Não é possível editar registros de "Pré-natal" dentro de um atendimento de observação.'
                }
              />
            )}
            {acessoPuericultura && (
              <PuericulturaButton
                puericulturaAtivo={isBotaoPuericulturaHabilitado || isPuericulturaEmAtendimentoObservacao}
                handleClick={handleClick(FormAtivoObjetivoEnum.PUERICULTURA)}
                disabled={isLoadingAcompanhamentoPuericulturaCardQuery || isPuericulturaEmAtendimentoObservacao}
                tooltipText={
                  isPuericulturaEmAtendimentoObservacao &&
                  'Não é possível editar registros de "Puericultura" dentro de um atendimento de observação.'
                }
              />
            )}
          </HFlow>
        </ButtonGroup>

        <SwitchPanelObjetivoForm
          isLoading={isLoadingAcompanhamentoPuericulturaCardQuery}
          isReadOnly={isPuericulturaEmAtendimentoObservacao}
          hasAccess={isBotaoPuericulturaHabilitado && acessoPuericultura}
          components={{
            Field: (
              <PuericulturaField name={name.puericultura} cidadao={cidadao} loading={isLoadingPuericulturaQuery} />
            ),
            View: (
              <PuericulturaView
                cidadao={cidadao}
                dataAtendimento={dataAtendimento}
                value={acompanhamentoPuericultura}
              />
            ),
          }}
        />

        <SwitchPanelObjetivoForm
          isLoading={isLoadingPreNatalEmAtendimentoObservacao}
          isReadOnly={isPreNatalEmAtendimentoObservacao}
          hasAccess={isBotaoPreNatalHabilitado && acessoPreNatal}
          components={{
            Field: <PreNatalField name={metaPreNatal} tipoPreNatal={tipoPreNatal} metaDum={metaDum} />,
            View: <PreNatalView value={dadosPreNatal} />,
          }}
        />
      </VFlow>
    </Fragment>
  )
}
