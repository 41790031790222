/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, Icon } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { LoadingIndicator } from 'components/loading'
import { FormApi } from 'final-form'
import { useCidadaosCadastroImovelQuery } from 'graphql/hooks.generated'
import { useEffect, useMemo, useState } from 'react'
import Permissions from 'types/Permissions'
import { MetaArray } from 'util/metaPath'
import { v4 } from 'uuid'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { CADASTRAR_IMOVEL_PATH, EDITAR_IMOVEL_PATH } from '../constants-cadastroImovel'
import {
  convertResideDesdeMonthYearToResideDesde,
  convertResideDesdeToResideDesdeMonthYear,
} from '../converter-cadastroImovel'
import {
  CadastrarEditarFamiliaFormModel,
  CadastroImovelCacheState,
  CidadaoCadastroImovel,
  FamiliaFormModel,
  UpdateCadastroImovelCacheState,
} from '../model-cadastroImovel'
import {
  mudancaResponsavelFamiliar,
  possuiCidadaoPresenteOutrasFamilias,
  setCadastroFamiliaCacheState,
} from '../utils-cadastroImovel'
import { CadastrarEditarFamiliaModal } from './modal/CadastrarEditarFamiliaModal'
import { NucleoFamiliarWrapper } from './NucleoFamiliarWrapper'

interface FamiliaImovelSectionProps {
  name: MetaArray<FamiliaFormModel>
  cidadaoModal: CidadaoCadastroImovel
  cacheState: CadastroImovelCacheState
  shouldRenderCache: boolean
  dataColeta: string
  onChange: UpdateCadastroImovelCacheState
  onScrollAfterRedirect(): void
}

export const FamiliaImovelSection = ({
  name,
  cacheState,
  shouldRenderCache,
  cidadaoModal,
  dataColeta,
  onChange,
  onScrollAfterRedirect,
}: FamiliaImovelSectionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [familiaIdEdit, setFamiliaIdEdit] = useState<ID>(
    cacheState?.familiaCidadaoModalState?.cadastroFamiliaModal?.editionId ?? null
  )

  const handleRejection = useErrorHandler()

  const { hasAuthorization } = useSession()

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value },
  } = useEditableListField({
    name,
  })

  const handleEditFamilia = (familia: FamiliaFormModel) => handleRowChanged(familia)
  const handleRemoveFamilia = (familia: FamiliaFormModel) => removeItem(familia)

  const handleDeleteCadastroFamiliaCache = () =>
    onChange((previous) => setCadastroFamiliaCacheState(null, false, previous))

  const handleCadastroFamiliaSaveCache = (form: CadastrarEditarFamiliaFormModel) =>
    onChange((previous) => setCadastroFamiliaCacheState(form, true, previous, familiaIdEdit))

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setFamiliaIdEdit(null)
    handleDeleteCadastroFamiliaCache()
    onScrollAfterRedirect()
  }

  const handleCadastrarFamilia = (familia: CadastrarEditarFamiliaFormModel, formApi: FormApi) => {
    handleSubmit(
      {
        ...familia,
        _id: v4(),
        resideDesde: convertResideDesdeMonthYearToResideDesde(familia.resideDesdeMonthYear),
        isNovoCadastro: true,
        isResponsavelCadastrado: true,
        isResponsavelDeclarado: true,
        isMudouSe: false,
        cidadaos: [
          {
            _id: null,
            id: familia.responsavel.id,
            mudouSe: false,
            registroObito: null,
            isNovoCadastroNaFamilia: true,
          },
        ],
        responsavelId: familia.responsavel.id,
      },
      formApi
    )
      .then(() => onScrollAfterRedirect())
      .catch(handleRejection)
  }

  const handleEditarFamilia = (values: CadastrarEditarFamiliaFormModel) => {
    const familiaAtualizadaNovoResponsavel = mudancaResponsavelFamiliar(familia, values.responsavel)
    const { numeroMembros, numeroProntuarioFamiliar, rendaFamiliar, resideDesdeMonthYear } = values

    handleRowChanged({
      ...familiaAtualizadaNovoResponsavel,
      numeroMembros,
      numeroProntuarioFamiliar,
      rendaFamiliar,
      resideDesde: convertResideDesdeMonthYearToResideDesde(resideDesdeMonthYear),
    })
  }

  const cidadaosIds = useMemo(
    () => cacheState?.form?.familias?.flatMap((familia) => familia.cidadaos?.map((cidadao) => cidadao.id)),
    [cacheState]
  )

  const {
    data: { cidadaos },
    loading,
  } = useCidadaosCadastroImovelQuery({
    variables: {
      input: {
        ativo: true,
        idCidadaoList: cidadaosIds,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: cidadaosIds?.isEmpty(),
  })

  const cidadaoPresenteFamilias = (familias: Array<FamiliaFormModel>) => {
    return (cidadaoId: ID) => possuiCidadaoPresenteOutrasFamilias(familias || [], cidadaoId, familia?._id)
  }

  const cadastrarEEditar = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar)
  const imovelId = cacheState?.form?.id

  const cadastroImovelPath = useMemo(
    () => (imovelId ? EDITAR_IMOVEL_PATH.concat(`/${imovelId}`) : CADASTRAR_IMOVEL_PATH),
    [imovelId]
  )

  const familia: FamiliaFormModel = (value || []).find((familia) => familia._id === familiaIdEdit)
  const cidadaoResponsavel = cidadaos?.content?.find((cidadao) => cidadao.id === familia?.responsavelId)

  const familiaCidadaoModalState = cacheState?.familiaCidadaoModalState
  const renderCacheFamilia = shouldRenderCache && !!familiaCidadaoModalState?.cadastroFamiliaModal?.modalOpen

  const cadastrarEditarFamiliaInitialValues = useMemo(
    () =>
      renderCacheFamilia
        ? { ...familiaCidadaoModalState?.cadastroFamiliaModal?.form, responsavel: cidadaoModal }
        : setEditionInitialValues(familia, cidadaoResponsavel),
    [cidadaoModal, cidadaoResponsavel, familia, familiaCidadaoModalState, renderCacheFamilia]
  )

  useEffect(() => {
    if (renderCacheFamilia || familiaIdEdit !== null) {
      setIsModalOpen(true)
    }
  }, [renderCacheFamilia, familiaIdEdit])

  return (
    <Grid>
      <Cell size={3}>
        <Heading level={1}>Famílias</Heading>
      </Cell>
      <Cell size={8} />
      <Cell size={4}>
        <Button onClick={() => setIsModalOpen(true)} kind='primary' skin='outline' disabled={value?.length > 99}>
          <Icon icon='plus' /> Adicionar familia ao imóvel
        </Button>
      </Cell>
      <Cell size={9} />
      {loading ? (
        <Cell size={12}>
          <LoadingIndicator />
        </Cell>
      ) : (
        <Cell size={12}>
          {value &&
            value?.map((familia: FamiliaFormModel, index: number) => (
              <NucleoFamiliarWrapper
                key={familia._id}
                familia={familia}
                cidadaoModal={cidadaoModal}
                cidadaosImovel={cidadaos?.content}
                cacheState={cacheState}
                shouldRenderCache={shouldRenderCache}
                cadastroImovelPath={cadastroImovelPath}
                name={name.get(index).cidadaos}
                hasPermission={cadastrarEEditar}
                isCidadaoPresenteFamilias={cidadaoPresenteFamilias(value)}
                onEditarFamilia={handleEditFamilia}
                onRemoverFamilia={handleRemoveFamilia}
                onChange={onChange}
                onScrollAfterRedirect={onScrollAfterRedirect}
                onSetFamiliaIdEdit={setFamiliaIdEdit}
                dataColeta={dataColeta}
              />
            ))}
        </Cell>
      )}
      {isModalOpen && (
        <CadastrarEditarFamiliaModal
          open={isModalOpen}
          hasPermission={cadastrarEEditar}
          initialValues={cadastrarEditarFamiliaInitialValues}
          cidadaoCallBackUrl={cadastroImovelPath}
          imovelId={cacheState?.form?.id}
          dataColeta={dataColeta}
          isCidadaoPresenteFamilias={cidadaoPresenteFamilias(value)}
          onClose={handleCloseModal}
          onCadastrarFamilia={handleCadastrarFamilia}
          onEditFamilia={handleEditarFamilia}
          onCadastroFamiliaSaveCache={handleCadastroFamiliaSaveCache}
        />
      )}
    </Grid>
  )
}

const setEditionInitialValues = (
  familia: FamiliaFormModel,
  responsavel: CidadaoCadastroImovel
): CadastrarEditarFamiliaFormModel => {
  if (familia)
    return {
      ...familia,
      resideDesdeMonthYear: convertResideDesdeToResideDesdeMonthYear(familia.resideDesde),
      responsavel: responsavel,
    }
  return null
}
