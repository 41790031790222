import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import React, { Fragment } from 'react'

export function AtividadeColetivaRootView() {
  return (
    <Fragment>
      <Breadcrumb title='Atividade coletiva' />
      <PageHeaderSection title='Atividade coletiva' />

      <PageContent type='transparent'>Página inicial da atividade coletiva</PageContent>
    </Fragment>
  )
}
