/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, colors, Grid, Heading, HFlow, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useErrorHandler } from 'components/error'
import {
  CheckboxField,
  CnsField,
  DateField,
  EnderecoFieldGroup,
  Form,
  FormValueSpy,
  NumberField,
  RadioGroupField,
  SubmitButton,
  TelefoneField,
  TextField,
} from 'components/form'
import { EnderecoIndigenaFieldGroup } from 'components/form/field/enderecoindigena/EnderecoIndigenaFieldGroup'
import {
  TipoDomicilioSelectField,
  TipoImovelSelectField,
} from 'components/form/field/select/CondicoesMoradiaSelectFields'
import { TipoEnergiaEletricaSelectField } from 'components/form/field/select/CondicoesMoradiaSelectFields/TipoEnergiaEletricaSelectField'
import { SimNaoRadioGroupField } from 'components/form/field/SimNaoRadioGroupField'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { InfoIcon } from 'components/InfoIcon'
import { ACOMPANHAMENTO_TERRITORIO_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { confirm } from 'components/modals/confirm'
import { subMonths } from 'date-fns'
import { useCidadaoQuery, useSalvarImovelMutation } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import { Fragment, useMemo, useRef } from 'react'
import { FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { useSaudeIndigenaAuthorization } from 'view/cidadao/authorization/useSaudeIndigenaAuthorization'
import { getVisualizacaoImovelCallbackUrl } from 'view/visualizacao-imovel/utils-visualizacao-imovel'
import { useVisualizacaoCache } from 'view/visualizacaoTerritorial/hooks/useVisualizacaoCache'

import { createCadastroImovelCalculator } from '../calculator-cadastroImovel'
import { convertImovelFormModelToImovelInput } from '../converter-cadastroImovel'
import { useCadastroImovelCache } from '../hooks/useCadastroImovelCache'
import { ImovelFormModel, tipoEnderecoIndigena, tipoEnderecoOptions } from '../model-cadastroImovel'
import {
  isTipoImovelDomicilio,
  isTipoImovelResidencial,
  isTipoImovelResidencialExcetoDomicilio,
  simNaoEnumToBooleanWrapper,
} from '../utils-cadastroImovel'
import { validateFamilias, validateImovelForm } from '../validator-cadastroImovel'
import { AnimaisDomicilioFieldGroup } from './AnimaisDomicilioFieldGroup'
import { CondicoesDeMoradiaFieldGroup } from './CondicoesDeMoradiaFieldGroup'
import { FamiliaImovelSection } from './FamiliaImovelSection'
import { ResponsavelAcompanhamentoSection } from './ResponsavelAcompanhamentoSection'

interface ImovelFormProps {
  initialValues?: ImovelFormModel
}

interface SearchParams {
  cidadaoId: string
  redirect: string
  endereco: string
}

const meta = metaPath<ImovelFormModel>()

export function ImovelForm({ initialValues }: ImovelFormProps) {
  const history = useHistory()
  const decorators = useMemo(() => [createCadastroImovelCalculator(meta)], [])
  const { hasTerritorioIndigenaPermission } = useSaudeIndigenaAuthorization()

  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()

  const handleRejection = useErrorHandler()
  const alert = useAlert()
  const [salvarImovel] = useSalvarImovelMutation()

  const { acesso } = useAcessoLotacaoOrEstagio()

  const { cidadaoId, redirect, endereco } = useUrlQueryParams<SearchParams>({ parameterLimit: 5 })

  const { cacheState, shouldRenderCache, setCacheState, deleteCacheState } = useCadastroImovelCache(
    acesso.id,
    redirect,
    cidadaoId,
    endereco
  )
  const cachedForm = useRef(
    shouldRenderCache && endereco === 'true'
      ? {
          ...initialValues,
          tipoEndereco: cacheState?.form?.tipoEndereco,
          stMicroareaPoloBase: cacheState?.form?.stMicroareaPoloBase,
          endereco: cacheState?.form?.endereco,
          enderecoIndigena: cacheState?.form?.enderecoIndigena,
          microarea: cacheState?.form?.microarea === 'FA' ? null : cacheState?.form?.microarea,
          statusForaArea: cacheState?.form?.microarea === 'FA',
        }
      : cacheState?.form
  )

  const { visualizacaoCacheState, setVisualizacaoCacheState, deleteVisualizacaoCacheState } = useVisualizacaoCache(
    acesso?.id
  )

  const initialFormValues = useRef(shouldRenderCache ? cachedForm.current : initialValues)

  const familiaSectionRef = useRef<HTMLDivElement>()

  const cachedDirtyFields = useRef(shouldRenderCache ? cacheState?.dirtyFields : {})

  const { data: cidadaoData } = useCidadaoQuery({
    fetchPolicy: 'cache-first',
    variables: { id: cidadaoId },
    skip: !cidadaoId || !shouldRenderCache,
  })

  const visualizacaoCallbackUrl = useMemo(() => getVisualizacaoImovelCallbackUrl(initialValues.id, redirect), [
    initialValues.id,
    redirect,
  ])

  const handleCancel = (hasChanges: boolean) => {
    const visualizacaoAcompanhamentoUrl = initialValues.id
      ? visualizacaoCallbackUrl
      : `${ACOMPANHAMENTO_TERRITORIO_PATH}?redirect=true`

    if (hasChanges) {
      confirmCancelar(visualizacaoAcompanhamentoUrl)
    } else history.push(visualizacaoAcompanhamentoUrl)
  }

  const handleSubmitNoChanges = (formProps: FormRenderProps<ImovelFormModel>) => {
    confirm({
      title: 'Nenhuma alteração efetuada.',
      body:
        'Deseja atualizar o cadastro mesmo sem alterações nos dados? Isso ainda será contabilizado como produção de cadastro.',
      onCancel: () => history.push(visualizacaoCallbackUrl),
      onConfirm: () => {
        history.push(visualizacaoCallbackUrl)
        formProps.handleSubmit()
      },
      confirmLabel: 'Confirmar',
      cancelLabel: 'Sair sem salvar',
    })()
  }

  const confirmCancelar = (visualizacaoAcompanhamentoUrl: string) => {
    confirm({
      title: 'Deseja cancelar o cadastro?',
      body: 'As alterações realizadas serão perdidas.',
      onConfirm: () => {
        deleteCacheState()
        setTimeout(() => history.push(visualizacaoAcompanhamentoUrl))
      },
      confirmLabel: 'Sim',
      cancelLabel: 'Não',
    })()
  }

  const handleSubmit = (input: ImovelFormModel) => {
    const errorMessage = input?.familias ? validateFamilias(input.familias) : null

    const visualizacaoCallbackUrl =
      !input.id && !initialValues.id
        ? `${ACOMPANHAMENTO_TERRITORIO_PATH}?redirect=true`
        : getVisualizacaoImovelCallbackUrl(input.id ?? initialValues.id, redirect)

    if (errorMessage) {
      alert('danger', errorMessage)
    } else {
      return salvarImovel({
        variables: {
          input: convertImovelFormModelToImovelInput(input),
        },
      })
        .then(() => {
          updateCacheAcompanhamentoMudancaTerritorio(input)
          deleteCacheState()
          setTimeout(() => history.push(visualizacaoCallbackUrl))
          alert('success', 'Imóvel salvo com sucesso.')
        })
        .catch(handleRejection)
    }
  }

  const updateCacheAcompanhamentoMudancaTerritorio = (input: ImovelFormModel) => {
    const { cnes, ine } = visualizacaoCacheState?.visualizacaoFilter?.territorioEquipeFilter || {}
    const { unidadeSaude, equipe } = input.responsabilidade.lotacao

    const hasIneChanged = ine !== equipe?.ine && ine === '-' && equipe?.ine !== undefined
    const hasCnesChanged = cnes !== unidadeSaude?.cnes

    const hasMicroareaChanged = visualizacaoCacheState?.currentMicroareaTab !== input.microarea

    if (hasIneChanged || hasCnesChanged) {
      deleteVisualizacaoCacheState()
    } else if (hasMicroareaChanged) {
      setVisualizacaoCacheState((previous) => ({
        ...previous,
        currentMicroareaTab: input.statusForaArea ? 'FA' : input.microarea,
        isRedirect: true,
      }))
    }
  }

  const handleFormChange = (form: ImovelFormModel, dirtyFields: { [key: string]: boolean }) => {
    setCacheState((previous) => {
      return {
        familiaCidadaoModalState: previous?.familiaCidadaoModalState
          ? previous.familiaCidadaoModalState
          : {
              cadastroCidadaoModal: {
                modalOpen: false,
              },
              cadastroFamiliaModal: {
                modalOpen: false,
                editionId: null,
              },
              atualizacaoCidadao: false,
            },
        listaLogradouroState: previous?.listaLogradouroState
          ? previous.listaLogradouroState
          : {
              fillEnderecoFields: false,
            },
        form,
        dirtyFields,
      }
    })
  }

  const verifyFormChanges = (dirtyFields: { [key: string]: boolean }, values: ImovelFormModel) => {
    const combinedDirtyFields = { ...cachedDirtyFields.current, ...dirtyFields }

    return Object.keys(combinedDirtyFields).some(
      (property) =>
        property !== 'dataColeta' &&
        !((values.statusForaArea || values.stMicroareaPoloBase) && property === 'microarea')
    )
  }

  const renderForm = (formProps: FormRenderProps<ImovelFormModel>) => {
    const { values, dirtyFields } = formProps
    const isResidencialExcetoDomicilio = isTipoImovelResidencialExcetoDomicilio(values?.tipoImovel)
    const isResidencial = isTipoImovelResidencial(values?.tipoImovel)
    const isDomicilio = isTipoImovelDomicilio(values?.tipoImovel)
    const isEnderecoLogradouro = !tipoEnderecoIndigena.includes(values?.tipoEndereco)
    const isEnderecoIndigena = tipoEnderecoIndigena.includes(values?.tipoEndereco)
    const hasEnergiaEletrica = simNaoEnumToBooleanWrapper(values?.condicoesMoradia?.isEnergiaEletricaDisponivel)
    const disableOrigemEnergiaEletrica = !hasEnergiaEletrica || !isResidencial
    const hasChanges = verifyFormChanges(dirtyFields, values)

    return (
      <Fragment>
        <FormValueSpy<ImovelFormModel> onChange={(formValues) => handleFormChange(formValues, dirtyFields)} />
        <VFlow>
          <Grid>
            <Cell size={12}>
              <ResponsavelAcompanhamentoSection name={meta.responsabilidade} />
            </Cell>
            <Cell size={3}>
              <HFlow hSpacing={0.2}>
                <NumberField
                  name={meta.microarea}
                  label={
                    <HFlow hSpacing={0.5} alignItems='center'>
                      {!(values?.statusForaArea || values?.stMicroareaPoloBase) ? (
                        <RequiredLabel label='Microarea' />
                      ) : (
                        <Text>Microarea</Text>
                      )}
                      {isEnderecoIndigena && (
                        <InfoIcon
                          icon='infoCircleFilled'
                          text='A microárea representa uma subdivisão do Polo base. Se o imóvel não faz parte de microárea, selecione a opção "Polo base" '
                        />
                      )}
                    </HFlow>
                  }
                  maxLength={2}
                  disabled={values?.statusForaArea || values.stMicroareaPoloBase}
                />
              </HFlow>
            </Cell>
            <Cell
              size={2}
              style={css`
                margin-top: 1.8rem;
              `}
            >
              {isEnderecoIndigena ? (
                <CheckboxField name={meta.stMicroareaPoloBase} label='Polo base' required={!values?.microarea} />
              ) : (
                <CheckboxField name={meta.statusForaArea} label='Fora de área' required={!values?.microarea} />
              )}
            </Cell>
            <Cell size={3}>
              <DateField
                name={meta.dataColeta}
                label='Data de coleta'
                icon='calendarOutline'
                maxDate={serverTime}
                minDate={subMonths(serverTime, 12)}
              />
            </Cell>
            <Cell size={4} />
            <Cell
              size={3}
              style={css`
                margin-top: 3rem;
              `}
            >
              <Heading level={1}>Endereço</Heading>
            </Cell>
            <Cell size={9} />
            {hasTerritorioIndigenaPermission && (
              <Cell size={3}>
                <RadioGroupField
                  label='Tipo de endereço'
                  name={meta.tipoEndereco}
                  options={tipoEnderecoOptions}
                  required
                />
              </Cell>
            )}
            {isEnderecoLogradouro && (
              <Cell size={12}>
                <EnderecoFieldGroup
                  isCepRequired
                  name={meta.endereco}
                  formProps={formProps}
                  formModelValue={values}
                  renderTipoLogradouro
                />
              </Cell>
            )}
            {isEnderecoIndigena && (
              <Cell size={12}>
                <EnderecoIndigenaFieldGroup
                  name={meta.enderecoIndigena}
                  formProps={formProps}
                  formModelValue={values}
                />
              </Cell>
            )}
            <Cell
              size={12}
              style={css`
                margin-top: 3rem;
              `}
            >
              <Heading level={1}>Imóvel</Heading>
            </Cell>
            <Cell size={3}>
              <Tooltip
                placement='bottom'
                style={css`
                  margin-top: 0.5rem;
                `}
                text={
                  values?.possuiFamiliasSalvas
                    ? 'Não pode ser alterado enquanto houver famílias e moradores no domicílio.'
                    : ''
                }
              >
                <TipoImovelSelectField
                  name={meta.tipoImovel}
                  label='Tipo do imóvel'
                  disabled={values?.possuiFamiliasSalvas}
                  required
                />
              </Tooltip>
            </Cell>
            <Cell size={3}>
              <TipoDomicilioSelectField
                name={meta.condicoesMoradia.tipoDomicilio}
                label='Tipo do domicílio'
                disabled={!isDomicilio}
              />
            </Cell>
            <Cell size={2}>
              <NumberField
                name={meta.numeroMoradores}
                label='Número de moradores'
                min={1}
                max={9999}
                disabled={!isTipoImovelResidencial(values?.tipoImovel)}
              />
            </Cell>
            <Cell size={4} />
            <Cell
              size={3}
              style={css`
                margin-top: 3rem;
              `}
            >
              <Heading level={1}>Contato</Heading>
            </Cell>
            <Cell size={9} />
            <Cell size={3}>
              <TelefoneField name={meta.telefoneContato} label='Telefone de contato' autoComplete='none' />
            </Cell>
            <Cell size={3}>
              <TelefoneField
                name={meta.telefoneResidencial}
                label='Telefone residencial'
                disabled={!isDomicilio}
                autoComplete='none'
              />
            </Cell>
            <Cell size={10} />
            <Cell size={12}>
              <CondicoesDeMoradiaFieldGroup
                name={meta.condicoesMoradia}
                isResidencial={isResidencial}
                isResidencialExcetoDomicilio={isResidencialExcetoDomicilio}
              />
            </Cell>
            <Cell
              size={3}
              style={css`
                margin-right: -6rem;
              `}
            >
              <SimNaoRadioGroupField
                name={meta.condicoesMoradia.isEnergiaEletricaDisponivel}
                label='Possui energia elétrica?'
                disabled={!isResidencial}
              />
            </Cell>
            <Cell size={4}>
              <TipoEnergiaEletricaSelectField
                name={meta.condicoesMoradia.tipoOrigemEnergiaEletrica}
                label='Tipo de energia elétrica'
                disabled={disableOrigemEnergiaEletrica}
              />
            </Cell>
            <Cell size={5} />
            <Cell size={12}>
              <AnimaisDomicilioFieldGroup name={meta.animaisNoDomicilio} isDomicilio={isDomicilio} />
            </Cell>
            {isResidencialExcetoDomicilio && (
              <Fragment>
                <Cell
                  size={4}
                  style={css`
                    margin-top: 3rem;
                  `}
                >
                  <Heading level={1}>Instituição de permanência</Heading>
                </Cell>
                <Cell size={8} />
                <Cell size={6}>
                  <TextField
                    name={meta.instituicaoPermanencia.nomeInstituicaoPermanencia}
                    label='Nome da instituição de permanência'
                  />
                </Cell>
                <Cell size={6} />
                <Cell size={8}>
                  <HFlow
                    style={css`
                      margin: 0.5rem 0;
                    `}
                  >
                    <SimNaoRadioGroupField
                      name={meta.instituicaoPermanencia.possuiOutrosProfissionaisVinculados}
                      label='Existem outros profissionais de saúde vinculados à instituição (não inclui profissionais da rede pública de saúde)'
                    />
                  </HFlow>
                </Cell>
                <Cell size={4} />
                <Cell
                  size={12}
                  style={css`
                    border: 1px solid ${colors.gray.c60};
                    padding: 1rem;
                    margin-left: 0.9rem;
                  `}
                >
                  <Grid>
                    <Cell size={6}>
                      <Heading level={3}> Identificação do responsável técnico da instituição de permanência</Heading>
                    </Cell>
                    <Cell size={6} />
                    <Cell size={8}>
                      <TextField
                        name={meta.instituicaoPermanencia.responsavelTecnico.nome}
                        label='Nome do responsável'
                        required
                      />
                    </Cell>
                    <Cell size={4}>
                      <CnsField name={meta.instituicaoPermanencia.responsavelTecnico.cns} label='CNS do responsável' />
                    </Cell>
                    <Cell size={8}>
                      <TextField
                        name={meta.instituicaoPermanencia.responsavelTecnico.cargo}
                        label='Cargo na instituição'
                      />
                    </Cell>
                    <Cell size={4}>
                      <TelefoneField
                        name={meta.instituicaoPermanencia.responsavelTecnico.contato}
                        label='Telefone para contato'
                      />
                    </Cell>
                  </Grid>
                </Cell>
              </Fragment>
            )}
            {isDomicilio && (
              <Cell
                size={12}
                style={css`
                  margin-top: 3rem;
                `}
              >
                <div ref={familiaSectionRef}>
                  <FamiliaImovelSection
                    cacheState={cacheState}
                    shouldRenderCache={shouldRenderCache}
                    cidadaoModal={cidadaoData?.cidadao}
                    name={meta.familias}
                    dataColeta={values?.dataColeta}
                    onChange={setCacheState}
                    onScrollAfterRedirect={() => familiaSectionRef.current?.scrollIntoView({ behavior: 'smooth' })}
                  />
                </div>
              </Cell>
            )}
          </Grid>
          <HFlow
            justifyContent='flex-end'
            style={css`
              margin-top: 3rem;
            `}
          >
            <Fragment>
              <Button onClick={() => handleCancel(hasChanges)}>Cancelar</Button>
              <SubmitButton
                kind='primary'
                handleSubmit={
                  cacheState?.form?.id && !hasChanges ? () => handleSubmitNoChanges(formProps) : formProps.handleSubmit
                }
              >
                Salvar
              </SubmitButton>
            </Fragment>
          </HFlow>
        </VFlow>
      </Fragment>
    )
  }

  return (
    <Form<ImovelFormModel>
      onSubmit={handleSubmit}
      render={renderForm}
      initialValues={initialFormValues.current}
      validate={validateImovelForm(serverTime)}
      decorators={decorators}
    />
  )
}
