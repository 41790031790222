import { Alert, HFlow, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { ReactComponent as BuscaAtiva } from 'images/side-menu/busca-ativa.svg'
import { ReactComponent as VideochamadaIcon } from 'images/side-menu/videochamadas.svg'
import React from 'react'

import { Carousel, CarouselItem } from './carousel/Corousel'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelCincoTres() {
  const { CADASTRO_IMOVEL_ENABLED, PILOTO_ALERTAS_SDC_1_ENABLED, SAUDE_INDIGENA_ENABLED } = useFlags()

  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Novidades:
      </Text>
      <HFlow hSpacing={5}>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Busca ativa de vacinação
          </Text>
          {CADASTRO_IMOVEL_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Cadastro de Imóveis e Famílias
            </Text>
          )}
          {PILOTO_ALERTAS_SDC_1_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Suporte à Decisão Clínica
            </Text>
          )}
          <Text fontSize={0.875} component='li'>
            Videochamada do profissional convidado adaptada para telefone celular
          </Text>
        </VFlow>
      </HFlow>

      <NewsHeadingSection
        title='Busca ativa de vacinação'
        icon={<BuscaAtiva style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='busca-ativa-vacinacao-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Implementação do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Adultos
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Implementação do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Puérperas com Gestantes
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Unificação dos Grupos-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Gestantes e Puérperas
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão do filtro por{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Bairro, Tipo de Logradouro
            </Text>{' '}
            e{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Logradouro
            </Text>{' '}
            de residência do cidadão;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão da regra de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Identidade de gênero
            </Text>{' '}
            nos relatórios.
          </Text>
        </VFlow>
      </NewsHeadingSection>

      {CADASTRO_IMOVEL_ENABLED && (
        <NewsHeadingSection title='Cadastro de Imóveis e Famílias' id='cadastro-imoveis-familias-5-3'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              A versão 5.3 agora permite cadastrar imóveis e famílias de forma mais completa e clara, facilitando a
              gestão dos cidadãos moradores de cada domicílio adscrito.
            </Text>
            <Text fontSize={0.875} component='li'>
              A funcionalidade de Acompanhamento do Território, no menu lateral, foi evoluída para permitir cadastrar e
              atualizar os imóveis e famílias, condições de moradia e responsabilidades de acompanhamento, tudo isso em
              uma única interface moderna e intuitiva, sem a necessidade de gerar várias Fichas CDS manualmente. O
              sistema as gerará automaticamente, apenas para que a sua produção seja consolidada em relatórios e enviada
              ao Centralizar Nacional.
            </Text>
            <Text fontSize={0.875} component='li'>
              Também será possível visualizar e corrigir inconsistências de cadastro através das novas telas, sem a
              necessidade de se aguardar o processamento após o Envio de Lotes de Fichas.
            </Text>
            <NewsImg
              src='/static/novidades/5-3_cadastro_ imoveis_familias.png'
              alt='Imagem exibindo a lista de famílias no cadastro de imóveis'
            />
          </VFlow>
        </NewsHeadingSection>
      )}

      {PILOTO_ALERTAS_SDC_1_ENABLED && (
        <NewsHeadingSection title='Suporte à Decisão Clínica' id='suporte-decisao-clinica-5-3'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Agora o PEC conta com um sistema de Suporte à Decisão Clínica que objetiva notificar os profissionais
              durante os atendimentos dos cidadãos. Os alertas apresentados por esse sistema servem de apoio à tomada de
              decisão e não possuem caráter prescritivo por si só. No momento, foram implementados dois alertas:
            </Text>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>Classificação</TableHeader>
                  <TableHeader>Condições</TableHeader>
                  <TableHeader>Disparo</TableHeader>
                  <TableHeader>Periodicidade</TableHeader>
                  <TableHeader>Alerta</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Suspeita de dengue</TableCell>
                  <TableCell>
                    Registro no atendimento atual de febre (CID R50 ou CIAP A03) e cefaleia (CID R51 ou CIAP N01) ou
                    adinamia (CID R29) ou mialgias (CID M79 ou CIAP L19) ou artralgias (CID M25 ou CIAP L20) ou dor
                    retro-orbitária (CID H 57.1 ou CIAP F01)
                  </TableCell>
                  <TableCell>Após registrar avaliação</TableCell>
                  <TableCell>Todos os atendimentos em que as condições forem atingidas</TableCell>
                  <TableCell>Suspeita de dengue</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Suspeita de dengue</TableCell>
                  <TableCell>
                    Registro no atendimento atual de anorexia (CID F50 ou CIAP P86) e náuseas/vômitos (CID R11 ou CIAP
                    D09 ou D10) e exantema (CID B09 ou CIAP A76)
                  </TableCell>
                  <TableCell>Após registrar avaliação</TableCell>
                  <TableCell>Todos os atendimentos em que as condições forem atingidas</TableCell>
                  <TableCell>Suspeita de dengue</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Text fontSize={0.875} component='li'>
              Assim que uma condição for atingida o alerta será disparado e uma notificação deve ser gerada no botão de
              suporte à decisão clínica que fica na direita superior da tela.
            </Text>
            <Carousel>
              <CarouselItem
                image='/static/novidades/5_3-sdc-alerta.png'
                alt='Imagem do alerta disparado pelo Suporte à Decisão Clínica'
              />
              <CarouselItem
                image='/static/novidades/5_3-sdc-notificacao.png'
                alt='Imagem do botão de notificações do Suporte à Decisão Clínica'
              />
              <CarouselItem
                image='/static/novidades/5_3-sdc-alerta-dengue.png'
                alt='Imagem do alerta relacionado à suspeita de dengue'
              />
            </Carousel>
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='Videochamada do profissional convidado adaptada para telefone celular'
        icon={<VideochamadaIcon style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='videochamada-mobile-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Os componentes da videochamada foram adaptados para funcionar em telefones celular. O objetivo do módulo é
            possibilitar que o profissional convidado acesse a videochamada pelo telefone celular. Por outro lado, o
            profissional anfitrião ainda deve acessar a videochamada através do computador.
          </Text>
          <Text fontSize={0.875} component='li'>
            As funcionalidades da videochamada para telefone celular são:
          </Text>
          <ul>
            <Text fontSize={0.875} component='li'>
              Ativar e desativar camera;
            </Text>
            <Text fontSize={0.875} component='li'>
              Ativar e desativar microfone;
            </Text>
            <Text fontSize={0.875} component='li'>
              Chat.
            </Text>
          </ul>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_videochamada_mobile_sala_espera.png'
              alt='Imagem da sala de espera da videochamada adaptada para mobile'
            />
            <CarouselItem
              image='/static/novidades/5-3_videochamada_mobile_termos.png'
              alt='Imagem dos termos da videochamada adaptados para mobile'
            />
            <CarouselItem
              image='/static/novidades/5-3_videochamada_mobile_chamada.png'
              alt='Imagem da videochamada adaptada para mobile'
            />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      {SAUDE_INDIGENA_ENABLED && (
        <NewsHeadingSection title='Saúde indígena' id='saude-indigena-5-3'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Com objetivo de tornar o PEC cada vez mais acessível aos diversos cenários existentes da saúde pública, o
              sistema foi atualizado para abranger as especificidades do contexto da saúde indígena!
            </Text>
            <ul>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Cadastro do cidadão:
                </Text>{' '}
                Inclusão de novos campos, como "Nome tradicional", "Função social", "Aldeia de nascimento", entre
                outros. Além disso, o endereço também foi adaptado ao contexto, com campos para indicar DSEI, Polo base
                e aldeia;
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Busca do cidadão:
                </Text>{' '}
                Inclusão do campo "Etnia", com opção "Não indígena" e campos para buscar através do endereço de
                residência: "DSEI", "Polo Base" e "Aldeia";
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Cadastro de imóveis:
                </Text>{' '}
                Inclusão de novas opções nos campos que caracterizam os imóveis, como "tipo de domicílio", "material
                predominante na construção", "abastecimento de água" entre outros. O endereço também foi adaptado;
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Ficha de atendimento individual:
                </Text>{' '}
                Inclusão de opções de exames e condições mais avaliadas nos atendimentos.
              </Text>
            </ul>
            <Carousel>
              <CarouselItem image='/static/novidades/5-3_cadastro_si.png' alt='Imagem do cadastro do cidadão aldeado' />
              <CarouselItem image='/static/novidades/5-3_busca_si.png' alt='Imagem da busca do cidadão aldeado' />
              <CarouselItem image='/static/novidades/5-3_imovel_si.png' alt='Imagem do cadastro de imóvel SI' />
              <CarouselItem
                image='/static/novidades/5-3_imovel_si_condicoes.png'
                alt='Imagem do cadastro de imóvel SI - condições de moradia'
              />
            </Carousel>
          </VFlow>
        </NewsHeadingSection>
      )}
    </VFlow>
  )
}
