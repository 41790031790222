/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useFlags } from 'config/useFlagsContext'
import { formatISO } from 'date-fns'
import { useCidadaoInformacoesContatoQuery } from 'graphql/hooks.generated'
import { SexoEnum, TipoReceitaEnum } from 'graphql/types.generated'
import {
  DocumentoDigitalErrorCodeEnum,
  EmitirPrescricaoDigitalPopupInput,
  TipoDocumentoAssinaturaDigitalPopup,
} from 'hooks/assinatura-digital/model-assinaturaDigitalPopup'
import { useAssinaturaDigitalPopup } from 'hooks/assinatura-digital/useAssinaturaDigitalPopup'
import { useSessionHasConselhoClasse } from 'hooks/conselho-classe/useSessionHasConselhoClasse'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import { useCallback, useEffect, useState } from 'react'
import { useField } from 'react-final-form'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { MedicamentoFormModel } from '../../model'
import {
  convertMedicamentoToPrintDto,
  processTextoMedicamentos,
  resolveTipoReceita,
} from '../../utils/converter-prescricao'
import { isOnPrescricaoDigitalAtiva } from '../../utils/utils-prescricao'
import { ImpressaoMedicamentosInput } from '../impressao/ImpressaoMedicamentosInput'
import { GerarPrescricaoDigitalFieldArray } from './GerarPrescricaoDigitalFieldArray'
import { PrescricaoDigitalFormModel, PrescricaoDigitalIntegracaoModel } from './model-prescricaoDigital'

export interface PrescricaoDigitalViewProps {
  prescricaoDigital: PrescricaoDigitalFormModel
  updatePrescricaoDigital: (prescricao: PrescricaoDigitalFormModel) => void
  atendimentoId: ID
  municipioId: ID
  cidadaoId: ID
  cidadaoSexo: SexoEnum
  cidadaoDataNascimento: string
  onModalClose: () => void
}

export const GerarPrescricaoDigitalView = (props: PrescricaoDigitalViewProps) => {
  const {
    prescricaoDigital,
    updatePrescricaoDigital,
    atendimentoId,
    municipioId,
    cidadaoId,
    cidadaoSexo,
    cidadaoDataNascimento,
    onModalClose,
  } = props

  const {
    input: { value: medicamentosField, onChange: updateMedicamentosField },
  } = useField<MedicamentoFormModel[]>(meta.plano.prescricaoMedicamento.medicamentos.absolutePath(), {
    subscription: { value: true },
  })

  const { PRESCRICAO_CONTROLADA, PRESCRICAO_TURNO } = useFlags()
  const [isGerandoPrescricao, setIsGerandoPrescricao] = useState(false)
  const [statusMessage, setStatusMessage] = useState<PrescricaoDigitalIntegracaoModel>(null)
  const [medicamentosSelected, setMedicamentosSelected] = useState<MedicamentoFormModel[]>(() => {
    return medicamentosField.filter((medicamento) => {
      const isOnPrescDigitalAtiva = isOnPrescricaoDigitalAtiva(prescricaoDigital, medicamento)
      const tipoReceita = resolveTipoReceita(medicamento)

      return PRESCRICAO_CONTROLADA ? null : tipoReceita === TipoReceitaEnum.COMUM && !isOnPrescDigitalAtiva
    })
  })

  const [tipoReceitaSelected, setTipoReceitaSelected] = useState(PRESCRICAO_CONTROLADA ? null : TipoReceitaEnum.COMUM)

  const { getServerTimeNow } = useServerTime()
  const { analytics } = useFirebase()
  const hasConselhoClasse = useSessionHasConselhoClasse()

  const { data: infoCidadao } = useCidadaoInformacoesContatoQuery({
    variables: { id: cidadaoId },
  })

  const cidadaoEmail = infoCidadao?.contatoCidadao?.email
  const emitirInput: EmitirPrescricaoDigitalPopupInput = {
    atendimentoId: atendimentoId,
    cidadaoId: cidadaoId,
    emailCidadao: cidadaoEmail,
    tipoReceita: tipoReceitaSelected,
  }

  const { data, openPopup, closePopup } = useAssinaturaDigitalPopup<ImpressaoMedicamentosInput>(
    emitirInput,
    TipoDocumentoAssinaturaDigitalPopup.PRESCRICAO
  )

  if (!!data?.status && !isGerandoPrescricao) closePopup()

  const handleGerarPrescricaoDigital = async () => {
    analytics.logEvent('click_gerar_prescricao_digital')
    if (!hasConselhoClasse) {
      handleGerarPrescricaoFailure()
      return
    }

    setIsGerandoPrescricao(true)

    const input: ImpressaoMedicamentosInput = {
      atendimentoId: atendimentoId,
      cidadaoDataNascimento: Date.parse(cidadaoDataNascimento),
      cidadaoSexo: cidadaoSexo,
      localidadeId: municipioId,
      medicamentos: medicamentosSelected.map((item) => convertMedicamentoToPrintDto(item, PRESCRICAO_TURNO.valueOf())),
    }

    try {
      await openPopup(input)
    } catch {
      handleGerarPrescricaoFailure()
    }
  }

  const handleEmitirPrescricaoDigitalSuccess = useCallback(
    (codigoPrescricao: string) => {
      const medicamentosToPush = medicamentosSelected
        .filter((medicamento) => !isOnPrescricaoDigitalAtiva(prescricaoDigital, medicamento))
        .map((medicamento) => medicamento.id)
      if (medicamentosToPush.isNotEmpty() && !prescricaoDigital?.[codigoPrescricao]) {
        if (PRESCRICAO_CONTROLADA) {
          setTipoReceitaSelected(null)
        }
        updateMedicamentosField([
          ...medicamentosField.map((medicamento) => {
            if (medicamentosToPush.includes(medicamento.id)) {
              medicamento.codigoPrescricaoDigital = codigoPrescricao
            }
            return medicamento
          }),
        ])
        updatePrescricaoDigital({
          [codigoPrescricao]: {
            prescricaoInvalidada: false,
            emailEnvio: cidadaoEmail,
            dataAssinatura: formatISO(getServerTimeNow()),
            textoMedicamentos: processTextoMedicamentos(
              medicamentosField.filter((medicamento) => medicamentosToPush.includes(medicamento.id)),
              codigoPrescricao
            ),
          },
          ...prescricaoDigital,
        })

        setMedicamentosSelected((e) => (e.isNotEmpty() ? [] : e))
      }
    },
    [
      medicamentosSelected,
      prescricaoDigital,
      PRESCRICAO_CONTROLADA,
      updateMedicamentosField,
      medicamentosField,
      updatePrescricaoDigital,
      cidadaoEmail,
      getServerTimeNow,
    ]
  )

  const handleStatusChangeGeracaoPrescricao = ({ success, message, errorCode }: PrescricaoDigitalIntegracaoModel) => {
    setStatusMessage({
      success,
      message,
      errorCode,
    })

    setIsGerandoPrescricao(false)
  }

  const handleGerarPrescricaoFailure = useCallback(() => {
    if (!hasConselhoClasse) {
      handleStatusChangeGeracaoPrescricao({
        success: false,
        errorCode: DocumentoDigitalErrorCodeEnum.PROFISSIONAL_SEM_CONSELHO,
      })
    } else if (!!data?.error) {
      handleStatusChangeGeracaoPrescricao({
        success: false,
        errorCode: data.error,
      })
    } else {
      handleStatusChangeGeracaoPrescricao({
        success: false,
        message: 'Não foi possível gerar a prescrição digital no momento. Tente novamente mais tarde.',
      })
    }
  }, [data, hasConselhoClasse])

  useEffect(() => {
    switch (data?.status) {
      case 'canceled':
        handleStatusChangeGeracaoPrescricao({ success: false, message: 'Geração de prescrição digital cancelada.' })
        break
      case 'success':
        handleStatusChangeGeracaoPrescricao({ success: true, message: 'Prescrição digital gerada com sucesso.' })
        handleEmitirPrescricaoDigitalSuccess(data?.codigoDocumento)
        break
      case 'failure':
        handleStatusChangeGeracaoPrescricao({
          success: false,
          errorCode: data.error,
        })
        break
    }
  }, [data, handleEmitirPrescricaoDigitalSuccess])

  return (
    <GerarPrescricaoDigitalFieldArray
      isGerandoPrescricao={isGerandoPrescricao}
      prescricaoDigital={prescricaoDigital}
      medicamentos={medicamentosField}
      medicamentosSelected={medicamentosSelected}
      setMedicamentosSelected={setMedicamentosSelected}
      tipoReceitaSelected={tipoReceitaSelected}
      setTipoReceitaSelected={setTipoReceitaSelected}
      statusMessage={statusMessage}
      setStatusMessage={setStatusMessage}
      cidadaoId={cidadaoId}
      cidadaoEmail={cidadaoEmail}
      onModalClose={onModalClose}
      onSubmit={handleGerarPrescricaoDigital}
    />
  )
}
