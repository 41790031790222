/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { Fragment, useState } from 'react'
import { MetaPath } from 'util/metaPath'

import { ResponsavelAcompanhamentoImovelFormModel } from '../model-cadastroImovel'
import { AlterarResponsabilidadeModal } from './modal/AlterarResponsabilidadeModal'
import { ResponsavelAcompanhamentoCard } from './ResponsavelAcompanhamentoCard'

interface ResponsavelAcompanhamentoSectionProps {
  name: MetaPath<ResponsavelAcompanhamentoImovelFormModel>
}

export function ResponsavelAcompanhamentoSection({ name }: ResponsavelAcompanhamentoSectionProps) {
  const { acesso } = useAcessoLotacaoOrEstagio()
  const [isModalResponsavelOpen, setIsModalResponsavelOpen] = useState(false)

  const {
    input: {
      value: { lotacao },
      onChange,
    },
  } = usePecField({ name: name.absolutePath() })

  const handleAlterarResponsavel = (novoResponsavel: LotacaoAndEstagioSelectFieldModel) => {
    onChange({
      lotacao: novoResponsavel,
    })
  }

  return (
    <Fragment>
      <Grid
        style={css`
          margin-top: 1rem;
        `}
        wrap
      >
        <Cell size={12}>
          <ResponsavelAcompanhamentoCard
            responsavel={lotacao}
            setIsModalResponsavelOpen={setIsModalResponsavelOpen}
            shouldRenderButton
          />
        </Cell>
      </Grid>

      <AlterarResponsabilidadeModal
        open={isModalResponsavelOpen}
        onClose={() => setIsModalResponsavelOpen(false)}
        onReponsavelChange={handleAlterarResponsavel}
        municipioId={acesso.municipio.id}
      />
    </Fragment>
  )
}
