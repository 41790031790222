import { CidadaoTerritorio } from 'graphql/types.generated'
import { CidadaoCardModel } from 'view/cadastro-imovel/model-cadastroImovel'

export const convertCidadaoTerritorioToCidadaoCard = (cidadaoTerritorio: CidadaoTerritorio): CidadaoCardModel => {
  return {
    id: cidadaoTerritorio.id,
    nome: cidadaoTerritorio.nome,
    nomeSocial: cidadaoTerritorio.nomeSocial,
    dataNascimento: cidadaoTerritorio.dataNascimento,
    sexo: cidadaoTerritorio.sexo,
    cns: cidadaoTerritorio.cns,
    cpf: cidadaoTerritorio.cpf,
    isNovoCadastroNaFamilia: false,
    registroObito: null,
    mudouSe: null,
  }
}
